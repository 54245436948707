<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="create-customer-modal final-modal popup-modal-all-width-height">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">EDIT CUSTOMER</span>
                <i class="icon icon-close text-white pointer" @click="cancel"></i>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="v-modal-body">
                <div class="pt-3 pr-3">
                <div class="row first-middle-input">
                    <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12" style="text-align: center;">
                    <span class style="text-align:center;">
                        <img
                        v-if="customer.image"
                        :src="customer.image"
                        style="border-radius:100%;width:90px!important;height:90px!important;"
                        />
                        <img v-else :src="imgDataUrl" style="width:90px!important;height:90px!important;" />
                    </span>
                    <upload-picture @uploadImage="uploadImage"></upload-picture>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <div class="preview-elements pb-2">
                        <h6 style="font-weight: bold;color: #303031">Personal Information <sup>★</sup></h6>
                        <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input
                            class="form-check-input"
                            type="radio"
                            name="salutation"
                            value="Mr"
                            v-model="customer.salutation"
                            v-validate="'required'"
                            id="salutation_mr"
                            />
                            <span class="radio-icon"></span>
                            <span class="form-check-description">Mr.</span>
                        </label>
                        </div>
                        <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input
                            class="form-check-input"
                            type="radio"
                            name="salutation"
                            value="Mrs"
                            v-model="customer.salutation"
                            v-validate="'required'"
                            />
                            <span class="radio-icon"></span>
                            <span class="form-check-description">Mrs.</span>
                        </label>
                        </div>
                        <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input
                            class="form-check-input"
                            type="radio"
                            name="salutation"
                            value="Miss"
                            v-model="customer.salutation"
                            v-validate="'required'"
                            id="salutation_miss"
                            />
                            <span class="radio-icon"></span>
                            <span class="form-check-description">Miss</span>
                        </label>
                        </div>
                        <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input
                            class="form-check-input"
                            type="radio"
                            name="salutation"
                            value="Others"
                            v-model="customer.salutation"
                            v-validate="'required'"
                            id="salutation_others"
                            />
                            <span class="radio-icon"></span>
                            <span class="form-check-description">Others</span>
                        </label>
                        </div>
                        <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input
                            class="form-check-input"
                            type="radio"
                            name="salutation"
                            value="GenderNeutral"
                            v-model="customer.salutation"
                            v-validate="'required'"
                            id="salutation_neutral"
                            />
                            <span class="radio-icon"></span>
                            <span class="form-check-description">Gender Neutral</span>
                        </label>
                        </div>
                        <span
                        class="invalid-feedback-form text-danger"
                        v-show="errors.has('salutation')"
                        >Please Select Salutation</span>
                    </div>
                    <div class="row pt-4">
                        <div class="col-md-4 col-sm-4 col-lg-4">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            v-validate="'required'"
                            v-model="customer.first_name"
                            :maxlength="fnmax1"
                            name="first_name"
                            id="edit_first_name"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">
                            First Name
                            <sup>★</sup>
                            </label>
                            <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('first_name') && submitted"
                            >{{errors.first('first_name')}}</span>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >{{fnmax1 - customer.first_name.length}} / {{fnmax1}}</p>
                        </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            :maxlength="mnmax"
                            required
                            v-model="customer.middle_name"
                            id="edit_middle_name"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Middle Name</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >{{mnmax - customer.middle_name.length}} / {{mnmax}}</p>
                        </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            :maxlength="lnmax"
                            required
                            v-model="customer.last_name"
                            id="edit_last_name"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Last Name</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >{{lnmax - customer.last_name.length}} / {{lnmax}}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-lg-12">
                    <h6
                        style="font-weight: bold;color: #303031;font-size: 1.4rem!important;"
                    >Communication Information</h6>
                    <div class="row pt-3">
                        <div class="col-lg-12">
                        <label for="inputSattus" class="col-form-label text-secondary">
                            Phone
                            Number
                            <sup>★</sup>
                        </label>
                        <vue-tel-input
                            @input="phone_number_exists=false"
                            v-model="customer.phone_number"
                            v-validate="'required'"
                            name="phone_number"
                            placeholder="Please Enter Your Phone Number"
                            @onInput="onInput"
                            id="edit_phone_number"
                            :valid-characters-only="true"
                        ></vue-tel-input>
                        <span
                            class="invalid-feedback-form text-danger fs-13"
                            v-show="errors.has('phone_number') && submitted "
                        >{{errors.first('phone_number')}}</span>
                        <span
                            class="invalid-feedback-form text-danger fs-13"
                            v-show="!validPhone && submitted "
                        >Phone Number Should be Valid.</span>
                        <div
                            v-show="phone_number_exists && submitted && customer.phone_number.trim()!=''"
                            class="text-danger fs-13"
                        >Customer Phone Number Already Exists</div>
                        </div>
                    </div>
                    <div class="form-group contactGroup" style="padding-top: 10px;">
                        <label
                        for="inputSattus"
                        class="col-form-label text-secondary fs-14"
                        style="font-size:14px!important;"
                        >
                        Email
                        </label>
                        <div class="input-group email-group">
                        <input
                            @input="email_exists=false"
                            class="inputContact"
                            type="text"
                            v-validate="{regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/}"
                            name="email"
                            placeholder="Please enter your Email ID"
                            v-model="customer.email"
                            :maxlength="eimax"
                            style="padding-top: 8px !important"
                            id="edit_email_id"
                        />
                        </div>
                        <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('email') && submitted"
                        >{{errors.first('email')}}</span>
                        <div
                        v-show="email_exists && submitted  && customer.email.trim()!=''"
                        class="text-danger fs-13"
                        >Customer Email Already Exists</div>
                        <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                        >{{eimax - customer.email.length}} / {{eimax}}</p>
                    </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center pointer" @click="showHideCompanyAddress">
                          <div class="">
                              <h6
                                style="font-weight: bold;color: #00448b;font-size: 1.4rem!important;white-space: nowrap;"
                              >
                                Company Address
                              </h6>
                          </div>
                          <hr class="ml-3 addon-border w-100" style="bottom: -5px;">
                          <div class="ml-0 pl-2" style="width: auto;">
                              <span class="collapse-addon">
                                  <i v-if="is_company_address == true" class="icon-chevron-double-up text-secondary icon-style-bg-white "></i>
                                  <i v-else class="icon-chevron-double-down text-secondary icon-style-bg-white"></i>
                              </span>
                              <!-- <span @click="expandAddons()" class="collapse-addon">
                                  <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showAddons == true}" class="w-30 rotateIcon">
                              </span> -->
                          </div>
                      </div>
                      <div v-if="is_company_address == true" class="row">
                        <div class="col-lg-12 pt-2">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            :maxlength="otcompanyname"
                            v-model="customer.company_name"
                            required
                            id="edit_company_address_name"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Company Name</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otcompanyname - customer.company_name.length}} / {{otcompanyname}}</p>
                        </div>
                        </div>
                    </div>
                    <div v-if="is_company_address == true" class="row pt-1">
                        <div class="col-lg-12">
                        <div class="form-group form-bottom flex-nowrap">
                            <div class="group-setup">
                            <input
                                :maxlength="castreet"
                                v-model="customer.company_address.street"
                                class="digi-form"
                                type="text"
                                name="street"
                                id="edit_company_address_street"
                                required
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Address 1</label>
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                            >{{castreet - customer.company_address.street.length}} / {{castreet}}</p>
                            </div>
                        </div>
                        <div class="form-group form-bottom flex-nowrap">
                            <div class="group-setup">
                            <input
                                :maxlength="castreetnew"
                                v-model="customer.company_address.street2"
                                class="digi-form"
                                type="text"
                                name="streetnew"
                                id="edit_company_address_street_new"
                                required
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Address 2</label>
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                            >{{castreetnew - customer.company_address.street2.length}} / {{castreetnew}}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-if="is_company_address == true" class="row pt-1">
                        <div class="col-lg-12">
                        <div class="form-group form-bottom row flex-nowrap">
                            <div class="col-md-4">
                            <div class="group-setup">
                                <input
                                :maxlength="catownship"
                                v-model="customer.company_address.township"
                                class="digi-form"
                                type="text"
                                name="state"
                                id="edit_company_address_township"
                                required
                                />
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Township</label>
                                <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                                >{{catownship - customer.company_address.township.length}} / {{catownship}}</p>
                            </div>
                            </div>
                            <div class="col-lg-4">
                            <div class="group-setup">
                                <input
                                :maxlength="cacity"
                                v-model="customer.company_address.city"
                                class="digi-form"
                                type="text"
                                name="city"
                                required
                                id="edit_company_address_city"
                                />
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">City</label>
                                <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                                >{{cacity - customer.company_address.city.length}} / {{cacity}}</p>
                            </div>
                            </div>
                            <div class="col-lg-4">
                            <div class="group-setup">
                                <input
                                :maxlength="castate"
                                v-model="customer.company_address.state"
                                class="digi-form"
                                type="text"
                                name="state"
                                onkeypress="return (event.charCode > 64 &&
                                                            event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                                required
                                id="edit_company_address_state"
                                />
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">State</label>
                                <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                                >{{castate - customer.company_address.state.length}} / {{castate}}</p>
                            </div>
                            </div>
                        </div>
                        <div class="form-group form-top row flex-nowrap">
                            <div class="col-lg-4">
                            <div class="group-setup">
                                <input
                                :maxlength="cazipcode"
                                v-model="customer.company_address.zipcode"
                                class="digi-form"
                                type="text"
                                name="c_zipcode"
                                required
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                id="edit_company_address_zip_code"
                                />
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Zip Code</label>
                                <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                                >{{cazipcode - customer.company_address.zipcode.length}} / {{cazipcode}}</p>
                            </div>
                            </div>
                            <div class="col-lg-8 selectlabels">
                            <float-label label="Country">
                                <select
                                v-model="customer.company_address.country"
                                class="form-control"
                                placeholder="Select Country"
                                name="country"
                                style="max-width: 100% !important;"
                                id="edit_company_address_country"
                                >
                                <option disabled selected hidden style="display:none;">Select Country</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Åland Island">Åland Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia, Plurinational State of">
                                    Bolivia, Plurinational State
                                    of
                                </option>
                                <option value="Bonaire, Sint Eustatius and Saba">
                                    Bonaire, Sint Eustatius and
                                    Saba
                                </option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">
                                    British Indian Ocean
                                    Territory
                                </option>
                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Congo, the Democratic Republic of the">
                                    Congo, the Democratic
                                    Republic of the
                                </option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaçao">Curaçao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard Island and McDonald Islands">
                                    Heard Island and McDonald
                                    Islands
                                </option>
                                <option
                                    value="Holy See (Vatican City State)"
                                >Holy See (Vatican City State)</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea, Democratic People's Republic of">
                                    Korea, Democratic
                                    People's Republic of
                                </option>
                                <option value="Korea, Republic of">Korea, Republic of</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">
                                    Lao People's Democratic
                                    Republic
                                </option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macao">Macao</option>
                                <option value="Macedonia, the Former Yugoslav Republic of">
                                    Macedonia, the
                                    Former Yugoslav Republic of
                                </option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia, Federated States of">
                                    Micronesia, Federated States
                                    of
                                </option>
                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestine, State of">Palestine, State of</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Réunion">Réunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation">Russian Federation</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Barthélemy">Saint Barthélemy</option>
                                <option value="Saint Helena, Ascension and Tristan da Cunha">
                                    Saint Helena,
                                    Ascension and Tristan da Cunha
                                </option>
                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                <option value="Saint Vincent and the Grenadines">
                                    Saint Vincent and the
                                    Grenadines
                                </option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia and the South Sandwich Islands">
                                    South Georgia
                                    and the South Sandwich Islands
                                </option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">
                                    United States Minor
                                    Outlying Islands
                                </option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela, Bolivarian Republic of">
                                    Venezuela, Bolivarian
                                    Republic of
                                </option>
                                <option value="Viet Nam">Viet Nam</option>
                                <option value="Virgin Islands, British">Virgin Islands, British</option>
                                <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                                <option value="Wallis and Futuna">Wallis and Futuna</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </float-label>
                            <span
                                class="text-danger fs-13"
                                v-show="errors.has('country') && ifSubmitted"
                            >Country is Required</span>
                            </div>
                        </div>
                        <div v-if="is_company_address == true" class="row">
                            <div class="col-lg-12 pt-2">
                            <div class="form-group contactGroup" style="margin-top: -10px;">
                                <input
                                class="inputContact"
                                type="text"
                                :maxlength="comapnaytaxcode"
                                v-model="customer.company_tax_code"
                                required
                                id="edit_company_address_tax_code"
                                />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">Company Tax Code</label>
                            </div>
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                style="margin-bottom: 0px!important;"
                            >{{comapnaytaxcode - customer.company_tax_code.length}} / {{comapnaytaxcode}}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row pt-1">
                    <div class="col-lg-12">
                    <h6 class="heading-details">Customer Address</h6>
                    <div class>
                        <div class="form-group form-bottom">
                        <div class="group-setup">
                            <input
                            :maxlength="otstreet"
                            v-model="customer.personal_address.street"
                            class="digi-form"
                            type="text"
                            name="street"
                            required
                            id="edit_customer_address_street"
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Address 1</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otstreet - customer.personal_address.street.length}} / {{otstreet}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="pt-3">
                        <div class="form-group form-bottom">
                        <div class="group-setup">
                            <input
                            :maxlength="otstreetnew"
                            v-model="customer.personal_address.street2"
                            class="digi-form"
                            type="text"
                            name="streetnew"
                            required
                            id="edit_customer_address_streetnew"
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Address 2</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otstreetnew - customer.personal_address.street2.length}} / {{otstreetnew}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="form-group form-bottom row flex-nowrap mt-7">
                        <div class="col-lg-4">
                        <div class="group-setup">
                            <input
                            :maxlength="ottownship"
                            v-model="customer.personal_address.township"
                            class="digi-form"
                            type="text"
                            name="state"
                            required
                            id="edit_customer_address_township"
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Township</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{ottownship - customer.personal_address.township.length}} / {{ottownship}}</p>
                        </div>
                        </div>
                        <div class="col-lg-4">
                        <div class="group-setup">
                            <input
                            :maxlength="otcity"
                            v-model="customer.personal_address.city"
                            class="digi-form"
                            type="text"
                            name="city"
                            id="edit_customer_address_city"
                            required
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">City</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otcity - customer.personal_address.city.length}} / {{otcity}}</p>
                        </div>
                        </div>
                        <div class="col-lg-4">
                        <div class="group-setup">
                            <input
                            :maxlength="otstate"
                            v-model="customer.personal_address.state"
                            class="digi-form"
                            type="text"
                            name="state"
                            onkeypress="return (event.charCode > 64 &&
                                                event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                            id="edit_customer_address_state"
                            required
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">State</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otstate - customer.personal_address.state.length}} / {{otstate}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="form-group form-top row flex-nowrap mt-3">
                        <div class="col-lg-4">
                        <div class="group-setup">
                            <input
                            :maxlength="otzipcode"
                            v-model="customer.personal_address.zipcode"
                            class="digi-form"
                            type="text"
                            name="zipcode"
                            required
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            id="edit_customer_address_zip_code"
                            />
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Zip Code</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{otzipcode - customer.personal_address.zipcode.length}} / {{otzipcode}}</p>
                        </div>
                        </div>
                        <div class="col-lg-8 selectlabels mt-1">
                        <float-label label="Country">
                            <select
                            class="form-control"
                            v-model="customer.personal_address.country"
                            placeholder="Select Country"
                            name="country"
                            style="max-width: 100% !important;"
                            id="edit_customer_address_country"
                            >
                            <option disabled selected hidden style="display:none;">Select Country</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Island">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia, Plurinational State of">
                                Bolivia, Plurinational State
                                of
                            </option>
                            <option value="Bonaire, Sint Eustatius and Saba">
                                Bonaire, Sint Eustatius and
                                Saba
                            </option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">
                                British Indian Ocean
                                Territory
                            </option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, the Democratic Republic of the">
                                Congo, the Democratic
                                Republic of the
                            </option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaçao">Curaçao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and McDonald Islands">
                                Heard Island and McDonald
                                Islands
                            </option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">
                                Korea, Democratic
                                People's Republic of
                            </option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">
                                Lao People's Democratic
                                Republic
                            </option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, the Former Yugoslav Republic of">
                                Macedonia, the
                                Former Yugoslav Republic of
                            </option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">
                                Micronesia, Federated States
                                of
                            </option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestine, State of">Palestine, State of</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Réunion">Réunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Barthélemy">Saint Barthélemy</option>
                            <option value="Saint Helena, Ascension and Tristan da Cunha">
                                Saint Helena,
                                Ascension and Tristan da Cunha
                            </option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and the Grenadines">
                                Saint Vincent and the
                                Grenadines
                            </option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich Islands">
                                South Georgia
                                and the South Sandwich Islands
                            </option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">
                                United States Minor
                                Outlying Islands
                            </option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela, Bolivarian Republic of">
                                Venezuela, Bolivarian
                                Republic of
                            </option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </float-label>
                        <span
                            class="text-danger fs-13"
                            v-show="errors.has('country') && ifSubmitted"
                        >Country is Required</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt-2">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            v-model="customer.tax_code"
                            :maxlength="customertaxcode"
                            id="edit_customer_address_tax_code"
                            required
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Customer Tax Code</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{customertaxcode - customer.tax_code.length}} / {{customertaxcode}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt-2">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            :maxlength="refferedby"
                            id="edit_customer_address_reffered_by"
                            v-model="customer.referred_by"
                            required
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Referred by</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{refferedby - customer.referred_by.length}} / {{refferedby}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt-2">
                        <div class="form-group contactGroup" style="margin-top: -10px;">
                            <input
                            class="inputContact"
                            type="text"
                            :maxlength="notesmax"
                            v-model="customer.notes"
                            id="edit_customer_address_notes"
                            required
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Notes</label>
                            <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                            >{{notesmax - customer.notes.length}} / {{notesmax}}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-lg-12">
                    <h6
                        style="font-weight: bold;color: #303031;    font-size: 1.4rem!important;"
                    >Likes and Dislikes</h6>
                    <div class="row">
                        <div class="col-lg-12 pt-2">
                        <label
                            for="inputSattus"
                            class="col-form-label text-secondary fs-14"
                            style="font-size:14px!important;"
                        >Likes</label>
                        <v-select
                            multiple
                            v-model="customer.liked_products"
                            placeholder="Search and Select Product"
                            :options="products"
                            label="product_name"
                            id="edit_customer_address_likes"
                            class="likes-vselect"
                            @search="fetchProductsForCustomer"
                        />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pt-2">
                        <label
                            for="inputSattus"
                            class="col-form-label text-secondary fs-14"
                            style="font-size:14px!important;"
                        >Dislikes</label>
                        <v-select
                            multiple
                            v-model="customer.disliked_products"
                            placeholder="Search and Select Product"
                            id="edit_customer_address_dislikes"
                            :options="productsdislike"
                            label="product_name"
                            @search="fetchProductsDislikeForCustomer"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div class="pt-5 align-items-center" id="deposit_available_edit">
                    <span class="font-weight-bold pr-5">Deposit Available?</span>
                    <el-switch
                    v-model="customer.is_deposit_available"
                    @change="depositTrue()"
                    active-text="Yes"
                    inactive-text="No"
                    style="line-height: 16px !important;"
                    ></el-switch>
                </div>
                <div v-if="customer.is_deposit_available" class="mt-3">
                    <div class="form-group form-bottom row flex-nowrap">
                    <div class="col-lg-4">
                        <div class="group-setup">
                          <input
                              v-model="customer.balance"
                              class="digi-form modal-bg"
                              type="text"
                              name="amount"
                              v-validate="'required'"
                              :maxlength="balance"
                              required
                              autocomplete="off"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              id="deposit_available_amount"
                          />
                          <span class="highlight digi-highlight"></span>
                          <span class="bar digi-bar"></span>
                          <label class="digi-label">
                              Amount
                              <sup>★</sup>
                          </label>
                          <span
                              class="invalid-feedback-form text-danger"
                              v-show="errors.has('amount') && submitted"
                              style="display: contents;"
                          >Amount is Required</span>

                          <p
                              class="pull-right text-right text-grey font-600 fs-12 pt-1"
                              style="margin-bottom: 0px!important;"
                          >{{balance - customer.balance.length}} / {{balance}}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup">
                        <input
                            v-model="customer.deposit.issued_by"
                            class="digi-form modal-bg"
                            type="text"
                            name="issuedBy"
                            :maxlength="issuedBy"
                            required
                            autocomplete="off"
                            v-validate="{required:true,regex:/^[a-zA-Z ]+$/}"
                            id="deposit_available_issued"
                        />
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">
                            Issued by
                            <sup>★</sup>
                        </label>
                        <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('issuedBy') && submitted"
                            style="display: contents;"
                        >{{errors.first("issuedBy") }}</span>
                        <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                        >{{issuedBy - customer.deposit.issued_by.length}} / {{issuedBy}}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup" style="margin-top: -21px;">
                          <label class="fs-13 text-secondary" style="dispaly: block;">Date Of Issue<sup>★</sup></label>
                        <date-picker
                            v-model="customer.deposit.date_of_issue"
                            v-if="convertUTC == false"
                            @confirm="changeToUtc()"
                            v-validate="'required'"
                            format="MM/DD/YYYY"
                            :first-day-of-week="7"
                            :show-second="false"
                            :lang="lang"
                            :clearable="true"
                            confirm
                            :not-after="new Date()"
                            confirm-text="APPLY"
                            type="date"
                            name="issuedDate"
                            class="modal-datepicker"
                            id="deposit_available_date"
                        ></date-picker>
                        <date-picker
                            v-else
                            v-model="customer.deposit.date_of_issue"
                            v-validate="'required'"
                            format="MM/DD/YYYY"
                            :first-day-of-week="7"
                            :show-second="false"
                            :lang="lang"
                            :clearable="true"
                            confirm
                            :not-after="new Date()"
                            confirm-text="APPLY"
                            type="date"
                            name="issuedDate"
                            :value-type="valueType.value2date"
                            class="modal-datepicker"
                        ></date-picker>
                        <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('issuedDate') && submitted"
                            style="display: block;"
                        >
                            Date of Issue is Required
                            <sup>★</sup>
                        </span>
                        </div>
                    </div>
                    </div>
                    <div class="form-group form-bottom row flex-nowrap">
                    <div class="col-lg-4">
                        <div class="group-setup">
                        <input
                            v-model="customer.deposit.bank_name"
                            class="digi-form modal-bg"
                            type="text"
                            name="bankName"
                            :maxlength="bankName"
                            required
                            autocomplete="off"
                            v-validate="{required:true,regex:/^[a-zA-Z ]+$/}"
                            id="deposit_available_bank_name"
                        />
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">
                            Bank Name
                            <sup>★</sup>
                        </label>
                        <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('bankName') && submitted"
                            style="display: contents;"
                        >{{errors.first("bankName") }}</span>
                        <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                        >{{bankName - customer.deposit.bank_name.length}} / {{bankName}}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup">
                        <input
                            v-model="customer.deposit.cheque_number"
                            class="digi-form modal-bg"
                            type="text"
                            name="transferNo"
                            :maxlength="transferNo"
                            required
                            autocomplete="off"
                            v-validate="'required|alpha_num'"
                            id="deposit_available_cheque"
                        />
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">
                            Cheque/Transfer No
                            <sup>★</sup>
                        </label>
                        <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('transferNo') && submitted"
                            style="display: contents;"
                        >{{errors.first("transferNo") }}</span>
                        <p
                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            style="margin-bottom: 0px!important;"
                        >{{transferNo - customer.deposit.cheque_number.length}} / {{transferNo}}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup" style="margin-bottom: 0px!important;">
                        <input
                            v-model="customer.deposit.deposit_notes"
                            class="digi-form modal-bg"
                            type="text"
                            name="depositNotes"
                            :maxlength="depositNotes"
                            required
                            autocomplete="off"
                            id="deposit_available_deposit"
                        />
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">Deposit Notes</label>
                        </div>
                        <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                        style="margin-bottom: 0px!important;"
                        >{{depositNotes - customer.deposit.deposit_notes.length}} / {{depositNotes}}</p>
                    </div>
                    </div>
                </div>
                <div class="row mt-5 mb-2">
                    <div class="col-lg-12 ml-2" id="opt_promotions">
                    <el-checkbox v-model="customer.promotions">Opt for Promotional Emails and Offers Email</el-checkbox>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="v-modal-dialog-actions modal-bg text-center pt-5 pb-5">
                <button
                    class="btn btn-link btn-smm"
                    style="text-transform: uppercase !important;font-size: 12px !important;"
                    @click="cancel" :disabled="edit_customer_api_inprogress"
                >CANCEL</button>
                <button
                    type="submit"
                    class="btn btn-secondary btn-smm ml-5"
                    :disabled="edit_customer_api_inprogress"
                    @click="save"
                >SAVE
                  <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_customer_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
    </modal>
</template>
<script>
import UploadPicture from './UploadPicture'
import customerServices from './mixins/customer'
import { EventBus } from './eventBus/nav-bar-event'
import productServices from './mixins/products'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ['modal_name'],
  components: { UploadPicture, HalfCircleSpinner },
  mixins: [customerServices, productServices],
  data() {
    const value2date = dateRange => {
      const date = new Date(dateRange)
      return dateRange
        ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    const date2value = date => {
      return date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    return {
      valueType: {
        value2date,
        date2value
      },
      convertUTC: false,
      edit_customer_api_inprogress: false,
      phone_number_exists: false,
      email_exists: false,
      validPhone: true,
      submitted: false,
      products: [],
      productsdislike: [],
      imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg', // the datebase64 url of created image,
      fnmax1: 20,
      mnmax: 20,
      lnmax: 20,
      eimax: 230,
      castreet: 42,
      castreetnew: 42,
      cacity: 21,
      catownship: 21,
      cazipcode: 12,
      comapnaytaxcode: 20,
      otcompanyname: 42,
      otstreet: 42,
      otstreetnew: 42,
      otcity: 21,
      ottownship: 21,
      otstate: 30,
      otzipcode: 12,
      customertaxcode: 20,
      castate: 30,
      refferedby: 30,
      notesmax: 250,
      issuedBy: 10,
      balance: 10,
      bankName: 10,
      transferNo: 10,
      depositNotes: 20,
      lang: {
        type: 'en',
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days'
        ],
        placeholder: {
          date: 'Date of Issue *',
          dateRange: 'Select Date Range'
        }
      },
      customer: {
        _id: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        salutation: '',
        email: '',
        phone_number: '',
        promotions: false,
        image: '',
        liked_products: [],
        disliked_products: [],
        company_name: '',
        tax_code: '',
        referred_by: '',
        notes: '',
        personal_address: {
          street: '',
          street2: '',
          city: '',
          country: '',
          zipcode: '',
          state: '',
          township: ''
        },
        company_address: {
          street: '',
          street2: '',
          city: '',
          country: '',
          zipcode: '',
          state: '',
          township: ''
        },
        company_tax_code: '',
        is_deposit_available: false,
        balance: '',
        deposit: {
          issued_by: '',
          date_of_issue: '',
          bank_name: '',
          cheque_number: '',
          deposit_notes: ''
        }
      },
      is_company_address: false
    }
  },
  methods: {
    showHideCompanyAddress(){
          this.is_company_address = !this.is_company_address
      },
    changeToUtc() {
      this.convertUTC = true
    },
    onInput({ number, isValid, country }) {
      this.validPhone = isValid
    },
    depositTrue() {
      this.customer.deposit = {
        issued_by: '',
        date_of_issue: '',
        bank_name: '',
        cheque_number: '',
        deposit_notes: ''
      }
    },
    async fetchProductsForCustomer(searchdata) {
      try {
        let params = { searchdata }
        let res = await this.getProductsForCustomer(params)
        this.products = res.response.all_products
      } catch (reason) {}
    },
    async fetchProductsDislikeForCustomer(searchdata) {
      try {
        let params = { searchdata }
        let res = await this.getProductsForCustomer(params)
        this.productsdislike = res.response.all_products
      } catch (reason) {}
    },
    cancel() {
      this.$emit('close', false)
    },
    async save() {
      this.submitted = true
      this.edit_customer_api_inprogress = true
      let result = await this.$validator.validate()
      if (result && this.validPhone) {
        this.updateCustomer()
        this.changeToUtc()
      } else {
        this.edit_customer_api_inprogress = false
      }
    },
    uploadImage(imgData) {
      this.customer.image = imgData
    },
    async updateCustomer() {
      try {
      this.edit_customer_api_inprogress = true
        if (typeof this.customer.phone_number == 'object') {
          this.customer.phone_number = this.customer.phone_number['e164']
        }
        if (this.customer.is_deposit_available == false) {
          this.customer.deposit = {}
        }
        var day = 60 * 60 * 24 * 1000;
        let details = {
          first_name: this.customer.first_name,
          last_name: this.customer.last_name,
          middle_name: this.customer.middle_name,
          salutation: this.customer.salutation,
          email: this.customer.email,
          phone_number: this.customer.phone_number,
          promotions: this.customer.promotions,
          _id: this.customer._id,
          image: this.customer.image,
          liked_products: this.customer.liked_products,
          disliked_products: this.customer.disliked_products,
          referred_by: this.customer.referred_by,
          tax_code: this.customer.tax_code,
          company_tax_code: this.customer.company_tax_code,
          company_name: this.customer.company_name,
          notes: this.customer.notes,
          company_address: this.customer.company_address,
          personal_address: this.customer.personal_address,
          balance: this.customer.balance,
          issued_by: this.customer.deposit.issued_by,
          date_of_issue: new Date(new Date(new Date(this.customer.deposit.date_of_issue).setHours(0,0,0,0)).getTime()+day),
          bank_name: this.customer.deposit.bank_name,
          cheque_number: this.customer.deposit.cheque_number,
          deposit_notes: this.customer.deposit.deposit_notes,
          is_deposit_available: this.customer.is_deposit_available
        }
        // console.log(details.date_of_issue)
        let res = await this.customerUpdate(details)
        this.phone_number_exists = false
        this.email_exists = false
        this.$swal({
          type: 'success',
          title: 'Updated',
          text: 'Successfully Updated Customer',
          showConfirmButton: false,
          timer: 2000
        })
        this.$emit('close', true)
        this.edit_customer_api_inprogress = false
      } catch (error) {
        if (error.response && error.response == 'duplicate') {
          if (error.value == 'phone_number') this.phone_number_exists = true
          else this.email_exists = true
        } else {
          this.$swal('Error', error.reason, 'warning')
        }
      }
      this.edit_customer_api_inprogress = false
    }
  },
  created() {
    const dict = {
      custom: {
        first_name: {
          required: () => 'First Name is Required'
        },
        phone_number: {
          required: () => 'Phone Number is Required'
        },
        email: {
          // required: () => 'Email ID is Required',
          regex: () => 'Enter Valid Email ID'
        },
        issuedBy: {
          required: () => 'Issued by is Required',
          regex: () => 'Enter Valid Name'
        },
        bankName: {
          required: () => 'Bank Name is Required',
          regex: () => 'Enter Valid Bank Name'
        },
        transferNo: {
          required: () => 'Cheque/Transfer No. is Required',
          alpha_num: () => 'Enter Valid Cheque/Transfer No.'
        }
      }
    }
    this.$validator.localize('en', dict)
  },
  mounted() {
    EventBus.$on('editCustomerApi', id => {
      this.$http
        .post('/customers/get_customer_by_id', { _id: id })
        .then(res => {
          if (res.data.status_id == 1) {
            this.customer = res.data.response
          }
        })
        .then(res => {
          this.fetchProductsForCustomer()
          this.fetchProductsDislikeForCustomer()
        })
    })
  },
  watch: {
    'customer.deposit.date_of_issue'(newValue, oldValue) {
      this.convertUTC = true
      if (newValue) {
        this.customer.deposit.date_of_issue = newValue
      }
    }
  }
}
</script>
<style>
.final-modal .inputContact {
  border-bottom: solid 1px #a9aaad !important;
}
.final-modal .digi-form {
  border-bottom: solid 1px #a9aaad !important;
  background: transparent;
}
.final-modal .form-control {
  border-bottom: 1px solid #a9aaad !important;
}
.final-modal .vue-tel-input {
  border: 1px solid transparent !important;
  border-bottom: 1px solid #a9aaad !important;
}
.final-modal .vue-tel-input input {
  background-color: transparent;
}
.final-modal .v-select .dropdown-toggle {
  border-bottom: solid 1px #a9aaad !important;
}
.v-modal-layout {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.heading-details {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: 0.7px;
  text-align: left;
  color: #00448b;
  padding-top: 15px;
}

.group-setup {
  position: relative;
  margin-bottom: 20px;
}

.select-map-span {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 10px;
}

.heading-details-black {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: #303031;
  padding-top: 20px;
  margin-bottom: 4px;
}
.edit-customer-modal .dropdown.v-select input {
  position: relative !important;
}
.v-select.likes-vselect.searchable .dropdown-toggle {
  display: flex !important;
}
.final-modal .inputContact {
  border-bottom: solid 1px #a9aaad !important;
  background-color: transparent;
}
.final-modal .digi-form.modal-bg {
  border-bottom: solid 1px #a9aaad !important;
  background-color: transparent;
}
.final-modal .form-control {
  border-bottom: 1px solid #a9aaad !important;
}
.final-modal .vue-tel-input {
  border: 1px solid transparent !important;
  border-bottom: 1px solid #a9aaad !important;
  background-color: transparent;
}
.final-modal .vue-tel-input input {
  background-color: transparent;
}
.final-modal .v-select .dropdown-toggle {
  border-bottom: solid 1px #a9aaad !important;
}

</style>
