<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-500">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">ADD NEW ISP PROVIDER</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
            	<div class="general-section" style="padding: 15px;">
                <div class="v-modal-body pt-5">
                    <div class="" >
                        <div class="row first-middle-input">                    
                            <div class="col-md-12">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="ispname"
                                        autofocus
                                        required
                                        v-model.trim="name"                                        
                                        :maxlength="ispProviderName"
                                        data-vv-scope="isp"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    ISP Provider Name
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('isp.ispname')"
                                        style="display:inline;"
                                    >{{ errors.first('isp.ispname') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{ispProviderName - name.length}} / {{ispProviderName}}</p>
                                </div>
                            </div>                   
                        </div> 
                        <div class="row first-middle-input pb-5" style="margin-top: -15px !important;">                    
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="uploadspeed"
                                        v-on:keypress="restrictMinus($event)"
                                        autofocus
                                        required                                        
                                        v-model.trim="upload_speed"                                        
                                        data-vv-scope="isp"
                                        autocomplete="off"
                                        :maxlength="uploadspeed"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Upload Speed
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('isp.uploadspeed')"
                                        style="display:inline;"
                                    >{{ errors.first('isp.uploadspeed') }}</span> 
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{uploadspeed - upload_speed.length}} / {{uploadspeed}}</p>                                   
                                </div>
                            </div> 
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="downloadspeed"
                                        autofocus
                                        required
                                        v-on:keypress="restrictMinus($event)"
                                        v-model.trim="download_speed"                                        
                                        data-vv-scope="isp"
                                        autocomplete="off"
                                        :maxlength="downloadspeed"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Download Speed
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('isp.downloadspeed')"
                                        style="display:inline;"
                                    >{{ errors.first('isp.downloadspeed') }}</span>  
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{downloadspeed - download_speed.length}} / {{downloadspeed}}</p>                                   
                                </div>
                            </div>                    
                        </div> 
                        <span>Manage Telephone No.</span> 
                        <div class="row">                            
                            <div class="col-md-6 d-flex pt-3 pb-3" v-for="(phone,index) in phoneNumber" :key="index">
                                <div class="">                           
                                    <vue-tel-input v-validate="'required'" data-vv-as="Phone Number" data-vv-scope="isp" :name="'phoneNumber'+index.toString()" v-model="phoneNumber[index]" placeholder="Telephone No.*" :preferredCountries="['th', 'in', 'us']" :defaultCountry="'th'" @onInput="onInput"></vue-tel-input>
                                    <span class="invalid-feedback-form text-danger" v-if="phoneNumber[index].length < 1" v-show="errors.has('isp.phoneNumber'+index.toString()) && isSubmitted">{{errors.first('isp.phoneNumber'+index.toString())}}</span>
                                    <span class="invalid-feedback-form text-danger fs-13" v-if="!validPhone && phoneNumber[index].length > 1">Phone Number Should be Valid.</span>                            
                                </div>                        
                                <span class="pt-3 pl-2 pointer">
                                    <i class="icon icon-minus-circle" v-if="index !=0" @click="removeTelephoneNumber(index)" style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 3px;color:red;"></i>
                                    <i class="icon icon-plus-circle" v-if="index ==0" @click="addTelephoneNumber()" style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 3px;color: #00448b;"></i>                            
                                </span>                                                
                            </div>                    
                        </div>
                        <span>Manage Emails</span> 
                        <div class="row first-middle-input" style="margin-top: -15px !important;">                            
                            <div class="col-md-6 d-flex" v-for="(email_id,index) in email" :key="index">                                 
                                <div class="form-group">
                                    <input
                                        class="inputContact"
                                        v-model="email[index]"                                        
                                        type="text"
                                        :name="'ispProvideremail'+index.toString()"                                        
                                        required                            
                                        :maxlength="ispProvideremail"
                                        placeholder="Email *"
                                        data-vv-as="Email ID"
                                        data-vv-scope="isp"
                                        autocomplete="off"
                                        style="width: 260px !important;"
                                        v-validate="{required:true,regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/}"
                                    />
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('isp.ispProvideremail'+index.toString()) && isSubmitted" style="display:contents;">{{ errors.first('isp.ispProvideremail'+index.toString())}}</span>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ispProvideremail - email[index].length}} / {{ispProvideremail}}</p>
                                </div>
                                <span class="pt-10 pl-2 pointer">
                                    <i class="icon icon-minus-circle" v-if="index !=0" @click="removeEmail(index)" style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 3px;color:red;"></i>
                                    <i class="icon icon-plus-circle" v-if="index ==0" @click="addEmail()" style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 3px;color: #00448b;"></i>                            
                                </span>
                            </div>
                        </div>         
                    </div>
                    <!-- <div class="v-modal-footer text-center pt-5">
                        <a class="btn btn-link btn-smm" @click="cancel">CANCEL</a>
                        <button
                            class="btn btn-secondary btn-smm text-uppercase"
                            @click="save"
                        >SAVE</button>
                    </div> -->
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
					<button class="btn btn-link btn-smm" @click="cancel" :disabled="create_isp_provider_api_inprogress">CANCEL</button>
                    <button
                        class="btn btn-secondary btn-smm text-uppercase ml-5" :disabled="create_isp_provider_api_inprogress"
                        @click="save"
                    >SAVE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="create_isp_provider_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
				</div>
            </div>
        </modal>
    </div>
</template>
<script>
import NetworkSettings from './mixins/networksettings'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    data(){
        return{
            ispProviderName:30,
            ispProvideremail:230,
            downloadspeed:10,
            uploadspeed:10,
            email:['',''],
            name:'', 
            validPhone:true,
            isSubmitted:false,                           
            phoneNumber:['',''],
            download_speed:'',
            upload_speed:'',
            create_isp_provider_api_inprogress: false
        }
    },
    props:['modal_name'],
    mixins: [NetworkSettings],
    components: {
        HalfCircleSpinner
    },
    methods:{
        restrictMinus: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode != null) {
                if (charCode == 45)
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        cancel(){
            this.$modal.hide(this.modal_name)
            this.name = ''
            this.phoneNumber = ['','']
            this.email = ['','']
            this.isSubmitted = false
            this.validPhone = true
            this.upload_speed = ''
            this.download_speed = ''          
        },
        save(){            
            this.isSubmitted = true
            this.$validator.validateAll('isp').then(result => {
                if (result && this.validPhone) {
                    this.isSubmitted = false
                    this.saveWifiNetwork()
                }
            })
        },
        onInput({ number, isValid, country }) {                        
            this.validPhone = isValid
        },
        addTelephoneNumber(){                        
            this.phoneNumber.push('')
        },
        removeTelephoneNumber(index){            
            this.phoneNumber.splice(index, 1);
        },
        addEmail(){
            this.email.push('')
        },
        removeEmail(index){
            this.email.splice(index, 1);
        },
        async saveWifiNetwork() {
            this.create_isp_provider_api_inprogress = true;
            let name = this.name
            let phone_number = [...new Set(this.phoneNumber)]
            let email = [...new Set(this.email)]
            let upload_speed = this.upload_speed
            let download_speed = this.download_speed
            let type = 'isp_settings'
            let isp = {
                name,
                phone_number,
                email,
                type,
                upload_speed,
                download_speed
            }            
            try {                               
                let response = await this.createSetting(isp)
                this.$emit('saveisp')
                this.$swal({
                    title: 'ISP Provider Added Successfully',
                    text: '',
                    type: 'success'
                })                
                this.cancel()                
                this.create_isp_provider_api_inprogress = false;
            } catch (reason) {               
                // TODO: Handle Error
                this.$swal('Warning', reason, 'warning')
                this.create_isp_provider_api_inprogress = false;
            }
        }
    },
    created() {
        const dict = {
            custom: {
                ispname: {
                    required: () => 'ISP Provider Name is Required'
                },
                phoneNumber:{
                    required: () => "Telephone Number is Required"
                }, 
                ispProvideremail: {
					required: () => "Email ID is Required",
                    regex: () =>"Enter Valid Email ID"
                },
                downloadspeed: {
                    required: () => "Download Speed is Required"
                },
                uploadspeed:{
                    required: () => "Upload Speed is Required"
                }            
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>