<template>
    <div class="page-layout blank contacts-manage">
        <div class="pb-10">
            <h2 class="ml-0 mb-1 mt-3">
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center mr-1">
                        <router-link :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/previous.svg" class="mr-2">
                            </el-tooltip>
                        </router-link>
                        <router-link :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward" placement="top">
                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/next.svg">
                            </el-tooltip>
                        </router-link>
                    </div>
                    <div>
                        <h6 class="font-weight-bold text-center pb-2 pt-1">EDIT PRICING</h6>
                    </div>
                    <div class="col-md-1">

                    </div>
                </div>
            </h2>
            <div style="border-radius: 6px;padding: 0px !important;background: #00448b !important;">
                <h6 class="font-weight-bold pb-2 pt-1 p-3 text-white">Product Price Information</h6>
            </div>
            <div class="row pl-5 pr-5">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product<sup>★</sup></label>
                    <multiselect
                        disabled
                        v-model="create.product_id"
                        class="digi-multiselect"
                        id="ajax"
                        label="name"
                        placeholder="Select Product"
                        :options="allproducts"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingproduct"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="40"
                        :max-height="200"
                        :show-no-results="true"
                        :hide-selected="false"
                        name="product"
                        v-validate="'required'"
                        @search-change="loadProductSearch"
                        @close="getVariantDetails()"
                    >
                    <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                            <span>{{ option.name }}</span>
                            <span class="custom__remove" @click="remove(option)">❌</span>
                        </span>
                    </template>
                    <template slot="afterList">
                        <div v-observe-visibility="reachedEndOfListProduct" />
                    </template>
                    </multiselect>
                    <span class="invalid-feedback-form" v-show="errors.has('product')" style="display:inline;">Please Select Product</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="group-setup">
                        <span class="fs-13 text-secondary font-weight-bold">No. of Variants</span>
                        <el-tooltip content="Autofilled" placement="top">
                            <i class="icon icon-check-circle fs-14 text-success" style="position:absolute;right:0;margin-top:28px;margin-right:5px"></i>
                        </el-tooltip>
                        <input class="inputContact pt-3" type="text" v-model="variantCount" readonly />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                    </div>
                </div>
            </div>
            <div v-if="variants.length && !ajaxcall">
                <div class="mt-5" v-for="(variant,vindex) in variants" :key="vindex">
                    <div class="infoHeader mt-2" style="background: #00448b !important;">
                        <div class="descriptionheader pr-3">
                            <div class="description-text-header-left pl-2">
                                <h5 class="font-weight-bold text-white whiteSpace">{{variant.variant_name}}</h5>
                            </div>
                            <div class="description-text-header-right">
                                <span class="collapse-addon" @click="showVariant(variant.id)">
                                    <img src="https://cdn.digicollect.com/cdn/scmnew/images/main-collapse.svg" :class="{'iconRotate':!variant_ids.includes(variant.id)}" style="width:23px !important;bottom:8px !important;right: 8px !important;" class="rotateIcon">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="variant_ids.includes(variant.id)">
                        <div class="row pl-5 pr-5">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group contactGroup">
                                    <input class="inputContact" v-model="variant.variant_price" required autocomplete="off" type="text" placeholder="Cost Price" readonly />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact" style="top:15px !important;">Cost Price<sup>★</sup></label>
                                </div>
                            </div>
                        </div>
                        <div class="m-5" style="border-radius: 6px;padding: 0px !important;background: #ffea92 !important;">
                            <h6 class="font-weight-bold pb-2 pt-1 p-3 text-center">Order Type Details</h6>
                        </div>
                        <div class="product-table m-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>ORDER TYPE</th>
                                        <th>SELLING PRICE</th>
                                        <th>GROSS MARGIN (%)
                                            <el-popover placement="right-end" width="400" trigger="hover" popper-class="combo-popover">
                                                <div class="d-flex">
                                                    <div>
                                                        <div class="mt-2 ml-2">Gross Margin (%) = </div>
                                                    </div>
                                                    <div>
                                                        <div> Selling Price - Cost Price</div>
                                                        <div class="text-center borderTop">Selling price</div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2 ml-2"> * 100</div>
                                                    </div>
                                                </div>
                                                <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/white-info.svg"></el-button>
                                            </el-popover>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(y,index) in variant.yield_manager_details" :key="index">
                                        <td class="elSelectRight">
                                            <el-select v-model="y.order_type" placeholder="Select">
                                                <el-option
                                                v-for="item in allOrderTypes"
                                                :key="item.order_type"
                                                :label="item.order_type"
                                                :value="item.order_type" class="bgTransparent">
                                                </el-option>
                                            </el-select>
                                        </td>
                                        <td>
                                            <div class="input-group payment-currency">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                </div>
                                                <input v-model.number="y.price" @input="calculateGrossMargin(variant.variant_price,vindex,y.price,index)" :maxlength="12" style="padding: 10px !important;"  type="text" class="form-control" v-on:keypress="isNumber($event)" placeholder="Enter Cost Price">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="input-group payment">
                                                <el-tooltip content="Autofilled" placement="top">
                                                    <i class="icon icon-check-circle fs-14 text-success mt-1px" style="position: absolute;right: 22px;z-index: 9999;"></i>
                                                </el-tooltip>
                                                <input v-model.number="y.gross_margin" style="padding: 10px !important;" type="number" readonly class="form-control">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <span class="d-flex" style="width:30px !important;">
                                            <span v-if="index > 0"
                                                class="pointer pt-6 d-block ml-2 v-hidden"
                                                @click="variant.yield_manager_details.splice(index,1)">
                                                <i class="icon icon-minus-circle text-danger"></i>
                                            </span>
                                            <span v-if="index+1==variant.yield_manager_details.length"
                                                class="pointer pt-6 d-block ml-2 v-hidden"
                                                @click="variant.yield_manager_details.push(JSON.parse(JSON.stringify(addYieldManager)))">
                                                <i class="icon icon-plus-circle text-danger"></i>
                                            </span>
                                        </span>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="variants.length == 0 && !ajaxcall" class="mt-10">
                <h6 class="font-weight-bold text-center pb-2 pt-1">Please Select Product</h6>
            </div>
            <div class="mt-3 row justify-content-center">
                <button class="btn btn-danger btn-smm" @click="cancel()">CANCEL</button>
                <button class="btn btn-success btn-smm ml-2 text-white" style="text-transform: uppercase;" @click="saveDetails()">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
import YieldManager from './mixins/yieldmanager';
export default {
    mixins: [YieldManager],
    data(){
        return{
            variantCount:0,
            create :{
                product_id:'',
            },
            variants:[],
            isLoadingproduct:false,
            scrollableProduct:false,
            count:0,
            allproducts:[],
            temp_products:[],
            isVisible:false,
            search_key:'',
            allOrderTypes:[],
            variant_ids:[],
            ajaxcall:false
        }
    },
    methods:{
        isNumber: function(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        async loadProductSearch(search_key){
            this.isLoadingproduct = true
            let response = await this.$http.post('products/get_products_for_dropdown', {
                search_key: search_key,
                skip: 0,
                limit: 10
            })
            if(response.data.status_id == 1){
                this.allproducts = response.data.products
                this.count = this.temp_products.length
                this.isLoadingproduct = false
                this.scrollableProduct = false
            }
        },
        async reachedEndOfListProduct(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableProduct == false) {
                this.isLoadingproduct = true
                let response = await this.$http.post('products/get_products_for_dropdown', {
                    search_key: this.search_key,
                    skip: this.count,
                    limit: 10
                })
                if(response.data.status_id == 1){
                    this.temp_products = response.data.products.length     
                    if (response.data.skip == this.count) {
                        if (this.temp_products > 0) {
                            let tempFiles = response.data.products
                            tempFiles.forEach(el => {
                                this.allproducts.push(el)
                            })
                            this.count += response.data.products.length
                            this.isLoadingproduct = false
                        } else {
                            this.scrollableProduct = true
                            this.isLoadingproduct = false
                        }
                    }
                        this.isLoadingproduct = false
                    }
                }
            }
        },
        getVariantDetails(){
            if(this.create.product_id != ''){
                this.variant_ids = []
                this.variants = []
                this.getAllVariantDetails()
            }else{
                this.variants = []
            }
        },
        async getAllVariantDetails(){
            try {
                this.ajaxcall = true
                let params ={
                    _id:this.$route.query.id
                }
                let res = await this.getAllVariantDetailsAPI(params)
                if(res.status_id == 1){
                    this.create.product_id = {name:res.response.product_name,product_id:res.response.product_id}
                    this.variants = res.response.variants
                    this.variantCount = res.response.variants_count
                    this.ajaxcall = false
                    this.variants.forEach((v,i)=>{
                        this.variant_ids.push(v.id)
                    })
                }
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
                this.cancel()
            }
        },
        async getOrderTypes(){
            try {
                let params = {
                    search_key:this.search_key
                }
                let res = await this.$http.post('order_type/get_all_order_types_for_dropdown',params)
                this.allOrderTypes = res.data.response.order_types
            } catch (reason) {
                
            }
        },
        calculateGrossMargin(variant,vindex,price,index){
            if(price == ''){
                price = 0
                let value = price - variant/price*100
                this.variants[vindex].yield_manager_details[index].gross_margin = (Math.round(value * 100) / 100)
            }else{
                let value = price - variant/price*100
                this.variants[vindex].yield_manager_details[index].gross_margin = (Math.round(value * 100) / 100) 
            }
        },
        showVariant(id){
            if(this.variant_ids.find(x => x === id)){
                this.variant_ids = this.variant_ids.filter(function(x) {
                    if (x !== id) {
                        return x;
                    }
                });
            }else{
                this.variant_ids.push(id)
            }
        },
        cancel(){
            if(this.$route.params.view){
                this.$router.push({
                    name: 'ViewPricing',
                    query: { id: this.$route.query.id },
                })
            }else{
                this.$router.push({
                        name: 'YieldManager',
                        params: {
                        yieldType: 'pricing'
                    }
                })
            }
            
        },
        saveDetails(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    const result = [];
                    const map = new Map();
                    for (const item of this.variants) {
                        if(!map.has(item.id)){
                            map.set(item.id, true);    // set any value to Map
                            result.push({
                                variant_id: item.id,
                                yield_manager_details: item.yield_manager_details,
                            });
                        }
                    }
                    let params ={
                        product_id:this.create.product_id.product_id,
                        variants:result
                    }
                    this.confirmSave(params)
                }
            })
        },
        async confirmSave(params){
            try {
                let res = await this.addPricingApi(params)
                this.$swal({
                    title: res.response,
                    text: '',
                    type: 'success'
                })
                this.cancel()
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
            }
        }
    },
    mounted(){
        this.getAllVariantDetails()
        this.getOrderTypes()
    }
}
</script>
<style scoped>
.whiteSpace{
    white-space: nowrap !important;
}
.product-table table thead{
	background: #00448b !important;
}
.product-table table tbody tr{
	border-bottom:1px dashed #707070 !important;
}
.product-table table thead th{
	color: #fff !important;
}
.payment .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.payment .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.payment-currency .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.payment-currency .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment-currency.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment-currency.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.borderTop{
    border-top: 1px solid #fff;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
</style>