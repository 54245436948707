<template>
    <div class="page-layout blank billing-mamage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 resetAlign d-flex">
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-left"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <date-picker v-if="convertUTC == false" v-model="dateRange" @confirm="get_order_history()"
                            format="MM/DD/YYYY hh:mm A" :first-day-of-week="7" range :show-second="false"
                            :shortcuts="shortcuts" :lang="lang" :clearable="true" confirm :not-after="new Date()"
                            confirm-text="APPLY" type="datetime" class="ml-1 receipt-datepicker" style="display: inline-block !important;
    width: 332px !important;">
                        </date-picker>
                        <date-picker v-else v-model="dateRange" @confirm="get_order_history()"
                            format="MM/DD/YYYY hh:mm A" :first-day-of-week="7" range :show-second="false"
                            :shortcuts="shortcuts" :lang="lang" :clearable="true" confirm :not-after="new Date()"
                            confirm-text="APPLY" :value-type="valueType" type="datetime" class="ml-1 receipt-datepicker" style="display: inline-block !important;
    width: 332px !important;">
                        </date-picker>
                        <a class="btn btn-secondary btn-smm text-uppercase ml-2 hidden-md-down"
                            @click="resetDate">Reset</a>
                    </div>
                    <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <flat-pickr v-model="enddate" class="form-control" placeholder="Select End Date " name="date">
            </flat-pickr>
          </div> -->
                    <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
          </div> -->
                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                        <div class="breadcome-heading">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input type="text" class="form-control textremove holder" :maxlength="searchcustomer"
                                    v-model="searchdata" placeholder="Search..." @input="get_order_history('search')">
                                <!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel"
                  aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <!-- <el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
              <a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)"
                style="margin-top: 2px;padding-top: 2px;">
                <i class="icons icon-arrow-left"></i>
              </a>
            </el-tooltip> -->
                        <!-- <router-link :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
              <el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
              </el-tooltip>
            </router-link>
            <router-link :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
              <el-tooltip class="item pull-right" effect="dark" content="Click to Go Back" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
            </router-link> -->
                        <div v-if="!showIllustrator && total_orders" class="pull-right">
                            <span class="page-info px-2 hidden-md-down"> {{start}} - {{end}} of {{total_orders}}
                            </span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button :disabled="count<=0" :class="{'cursorNotAllowed':ajaxCallInProgress}"
                                    v-on:click="get_order_history('prev')" type="button"
                                    class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button :disabled="end == total_orders" :class="{'cursorNotAllowed':ajaxCallInProgress}"
                                    type="button" v-on:click="get_order_history('next')"
                                    class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </h2>
            <div v-if="showIllustrator" class="row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_receipts.svg"
                            style="margin-top:20px;width:18%;">
                        <p class="text-center text-secondary fs-28 font-600 pt-5">No Receipts </p>
                    </div>
                </div>
            </div>
            <div v-if="!showIllustrator">
                <div class="category-manage-info"> RECEIPTS </div>
                <div class="page-layout carded left-sidebar mt-2"
                    style="overflow: auto !important;padding-bottom: 5px;">
                    <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
                        <div class="page-content">
                            <div class="demo-content">
                                <table class="table table-scroll table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span v-on:click="setPasskey('customer_name')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span
                                                        v-if="passed_key.key=='customer_name' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'customer_name'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'customer_name'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                CUSTOMER NAME</th>
                                            <th>
                                                <span v-on:click="setPasskey('customer_company')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span
                                                        v-if="passed_key.key=='customer_company' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'customer_company'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'customer_company'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                COMPANY NAME</th>
                                            <th>
                                                <span v-on:click="setPasskey('receipt_id')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span v-if="passed_key.key=='receipt_id' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'receipt_id'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'receipt_id'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                RECEIPT ID</th>
                                            <th class="hidden-md-down">
                                                <span v-on:click="setPasskey('order_id')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span v-if="passed_key.key=='order_id' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'order_id'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'order_id'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                Order NO</th>
                                            <th class="hidden-md-down">
                                                <span v-on:click="setPasskey('created_time')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span v-if="passed_key.key=='created_time' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'created_time'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'created_time'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                CREATED AT
                                            </th>
                                            <th class="">
                                                <span v-on:click="setPasskey('grand_total')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span v-if="passed_key.key=='grand_total' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'grand_total'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'grand_total'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                ORDER VALUE
                                            </th>
                                            <!-- <th>CUSTOMER EMAIL</th> -->
                                            <th class="hidden-sm-down">
                                                <span v-on:click="setPasskey('employee_name')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span
                                                        v-if="passed_key.key=='employee_name' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'employee_name'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'employee_name'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                Closed By</th>
                                            <th class="">
                                                <span v-on:click="setPasskey('customer_tax_code')" class="cursor-th">
                                                    <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                                                    <span
                                                        v-if="passed_key.key=='customer_tax_code' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'customer_tax_code'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'customer_tax_code'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                </span>
                                                TAX CODE</th>
                                            <th>
                                                <i class="icon-arrow-up" style="visibility: hidden;"></i>Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-if="ajaxCallInProgress"> -->
                                        <tr v-if="ajaxCallInProgress">
                                            <td colspan="7" class="text-center justify-content-center">
                                                <div class="digi-table-loader">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-else-if="!details.length" style="height:100%;">
                                            <td colspan="7" class="text-center justify-content-center"
                                                style="font-size: 14px;">
                                                <strong v-if="searchdata" style="display:block;">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg"><br>
                                                    <p class="text-secondary font-20"
                                                        style="font-size: 22px; font-weight: 600;">No Search Results
                                                        Found</p>
                                                </strong>
                                                <strong v-else>No Receipts</strong>
                                            </td>
                                        </tr>
                                        <tr v-else v-for="(order,index) in details" :key="index">
                                            <td style="white-space: nowrap;width: 150px;text-overflow: ellipsis;overflow: hidden;display: block;"
                                                :title="order.customer_name">
                                                {{order.customer_name}}
                                            </td>
                                            <td style="width: 210px;text-overflow: ellipsis;overflow: hidden;display: block;"
                                                :title="order.customer_company">
                                                {{order.customer_company || '-'}}
                                            </td>
                                            <td class="hidden-sm-down"
                                                style="width: 150px;text-overflow: ellipsis;overflow: hidden;display: block;text-transform:capitalize;"
                                                :title="order.receipt_id">
                                                {{order.receipt_id}}
                                            </td>
                                            <td class="hidden-md-down">
                                                {{order.order_id}}
                                            </td>
                                            <td class="hidden-md-down">
                                                <!-- {{order.created_time}} -->
                                                {{order.created_time.slice(3,5) + '/' + order.created_time.slice(0,2) + '/' + order.created_time.slice(6,)}}
                                            </td>
                                            <td style="width: 12px;">
                                                {{order.grand_total}}
                                            </td>
                                            <td class="hidden-sm-down"
                                                style="white-space: nowrap;width: 150px;text-overflow: ellipsis;overflow: hidden;display: block;text-transform:capitalize;"
                                                :title="order.employee_name">
                                                {{order.employee_name}}
                                            </td>
                                            <td style="white-space: nowrap;width: 150px;text-overflow: ellipsis;overflow: hidden;display: block;"
                                                :title="order.customer_tax_code">
                                                {{order.customer_tax_code || '-'}}
                                            </td>
                                            <td>
                                                <el-tooltip class="item" effect="dark" content="Generate Invoice"
                                                    placement="top">
                                                    <span
                                                        @click="generateInvoice(order._id, order.customer_id, order.outlet_id)"
                                                        style="margin-right: 15px;">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/TaxInvoice.svg">
                                                    </span>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="View Receipts"
                                                    placement="top">
                                                    <span @click="get_order_details_receipt(order._id)"
                                                        style="margin-left: 7px;">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/eye-bill.svg"
                                                            style="width: 20px;">
                                                    </span>
                                                </el-tooltip>
                                                <!-- <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip> -->
                                            </td>
                                            <!-- <td></td>
                      <td></td> -->
                                        </tr>
                                    </tbody>
                                    <!-- <tfoot style="background-color: #e6e6e6;">
                    <tr>
                      <th style="width:1%;"></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total:</th>
                      <th>$ 170.24</th>
                      <th></th>
                    </tr>
                  </tfoot> -->
                                </table>
                            </div>
                        </div>
                        <div class="filter-list" :class="{'right-sidebar': toggleSideBar}" ref="content">
                            <div class="list-group-item subheader">
                                <span class="font-600">
                                    Paid Receipt No. : {{receipt_id}}
                                </span>
                                <div class="pull-right"
                                    style="margin-left: auto; cursor: pointer; display: inline-flex;">
                                    <!-- <a class="btn btn-secondary btn-smm mr-3 d-none d-md-block">Download </a>  -->

                                    <!-- <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" v-on:click="toggleBtn1()">
                    <i class="icon-email"></i>
                  </span>
                  <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="createPDF()">
                    <i class="icon-download"></i>
                  </span>
                  <span class="pt-1 item" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="toggleBtn()">
                    <i class="icon-close-circle-outline"></i>
                  </span> -->

                                    <div class="pull-right"
                                        style="margin-left: auto; cursor: pointer; display: inline-flex;">
                                        <!-- <a class="btn btn-secondary btn-smm mr-3 d-none d-md-block">Download </a>  -->

                                        <!-- <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="myFunction()">
                      <i class="icon-email"></i>
                    </span>
                    <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="createPDF()">
                      <i class="icon-download"></i>
                    </span> -->
                                        <span class="pt-1 item" tabindex="0"
                                            style="cursor: pointer;outline-width: 0px!important;"
                                            v-on:click="toggleBtn1()">
                                            <i class="icon-close-circle-outline"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <table class="table">
                                <tr style="height:20px!important;">
                                    <td rowspan="3" style="padding: 4px 20px!important;">
                                        <img :src="image" style="width:74px;height:70px;border-radius: 50%;" />
                                    </td>
                                    <td class="pull-right" style="padding: 4px!important;">
                                        {{outlet_name}}
                                    </td>
                                </tr>
                                <tr style="height:20px!important;">
                                    <td style="padding: 4px!important;" class="pull-right">{{outlet_email_address}}</td>
                                </tr>
                                <tr style="height:20px!important;">
                                    <td style="padding: 4px!important;" class="pull-right">{{outlet_phone_number}}</td>
                                </tr>
                            </table>
                            <hr style="border-top: 0.5px solid #94bef0;">
                            <div class="row pl-2 pr-3">
                                <div class="col-md-6">
                                    <div class="title font-weight-bold text-secondary">DigiPOS</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="title font-weight-bold pull-right">
                                        <!-- {{closed_time}} -->
                                        <div v-if="closed_time != null">
                                            {{closed_time.slice(3,5) + '/' + closed_time.slice(0,2) + '/' + closed_time.slice(6,) || '-'}}
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style="border-top: 1.5px solid #94bef0;">
                            <div class="outlet-address pt-3">
                                Outlet Address : {{outlet_address}}
                            </div>
                            <!-- <hr style="border-top: 0.5px solid #94bef0;"> -->
                            <div class="content" style="padding-left:5px;padding-right:5px;" v-if="showBilling">
                                <!-- <div class="info-line mb-2 text-center pt-4">
                  <div class="info mb-1">Paid Receipt No : {{receipt_id}}</div>
                  <div class="info mb-1">POS ID : ABD1123445667</div>
                  <div class="info mb-1">Tax Code : {{outlet_tax_code}}</div>
                  <div class="info mb-1">Order Type : {{order_type}}</div>
                  <div v-if="order_type == 'Dine In'" class="info mb-1">Table Number : {{table_id}}</div>
                </div>
                <hr style="border-top: 1.5px solid #94bef0;">
                <div class="info-line mb-2 mt-2 text-center">
                  <div class="info">Served By {{served_by}}</div>
                  <div class="info mb-1">Generated By {{outlet_name}} in {{outlet_location}}</div>
                </div> -->
                                <hr style="border-top: 0.5px solid #94bef0;">
                                <div class="content" v-if="showBilling">
                                    <div class="info-line mb-2 text-center pt-4">
                                        <div class="info mb-1 font-600">Paid Receipt No : {{receipt_id}}</div>
                                        <div class="info mb-1 font-600">POS ID : {{pos_id}}</div>
                                        <div class="info mb-1 font-600">Tax Code : {{outlet_tax_code}}</div>
                                        <div class="info mb-1 font-600">Order Type : {{order_type}}</div>
                                        <div v-if="order_type == 'Dine In'" class="info mb-1 font-600">Table Number :
                                            {{table_id}}</div>
                                    </div>
                                    <hr style="border-top: 1.5px solid #94bef0;">
                                    <div class="info-line mb-2 mt-2 text-center">
                                        <div class="info server-by font-600">Served by <span
                                                class="text-capitalize">{{served_by}}</span></div>
                                        <div class="info mb-1 server-by font-600">Generated by <span
                                                class="text-capitalize">{{outlet_name}}</span> in <span
                                                class="text-capitalize">{{outlet_location}}</span></div>
                                    </div>
                                    <div class="">
                                        <table v-if="!isPopUp" class="table tablebill">
                                            <thead style="background-color:#00448b!important;">
                                                <tr style="border-bottom: dashed 1px #b8b9bb;height: 30px;">
                                                    <th class="thbill" style="width: 35%;color:#fff!important;">Items
                                                    </th>
                                                    <th class="thbill" style="width: 27%;color:#fff!important;">Qty</th>
                                                    <!-- <th class="thbill" style="width: 15%;">Rate</th>
                          <th class="thbill" style="width: 15%;">Discount</th> -->
                                                    <th class="thbill" style="width: 15%;color:#fff!important;">Total
                                                        Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody style="cursor:default!important;">
                                                <template v-for="(item,index) in item_details">
                                                    <tr :key="index">
                                                        <td v-if="item.hasVariant"
                                                            class="tdcart deepSkyBlue pointer td-truncate"
                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                            <img v-if="item.variant_type == 'Non-Veg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.variant_type == 'Veg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.variant_type == 'Egg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.variant_type == 'Pescatarian'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg"
                                                                style="padding-right: 5px;width: 20px;">
                                                            {{item.product_name}}/{{item.variant_name}}<br> Rate:
                                                            {{item.price}}
                                                        </td>
                                                        <td v-else class="tdcart deepSkyBlue pointer td-truncate"
                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                            <img v-if="item.product_type == 'Non-Veg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.product_type == 'Veg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.product_type == 'Egg'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                style="padding-right: 5px;">
                                                            <img v-else-if="item.product_type == 'Pescatarian'"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg"
                                                                style="padding-right: 5px;width: 20px;">

                                                            {{item.product_name}}<br> Rate: {{item.price}}</td>
                                                        <td class="tdcart"
                                                            style="padding: 0.6rem 0.8rem !important;width: 15%;">
                                                            {{item.quantity}}<br> Discount: {{item.discount}}</td>
                                                        <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.price}}</td> -->
                                                        <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.discount.amount}}</td> -->
                                                        <td class="tdcart"
                                                            style="padding: 0.6rem 0.8rem !important;width: 25%;">
                                                            {{item.total}}</td>
                                                    </tr>
                                                    <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                                        <td colspan="3" style="padding: 0px 5px !important">
                                                            <table class="w-100">
                                                                <tbody>
                                                                    <tr>
                                                                        <td colspan="3" class="fs-14 py-1" style="padding:5px !important">{{item.variant_name}}</td>
                                                                    </tr>
                                                                    <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 40%;">
                                                                            <div class="text-blue font-600 fs-14 py-1">
                                                                                <span>{{modifier.m_name}}</span>
                                                                            </div>
                                                                            <div class="fs-14">
                                                                                <span>{{modifier.m_item_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;vertical-align:bottom;">
                                                                                <span>{{modifier.quantity}}</span>
                                                                        </td>
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;vertical-align:bottom;">
                                                                                <span>{{modifier.amount}}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'">
                                                                    <tr>
                                                                        <td colspan="3" class="fs-14 py-1" style="padding:0.3rem 0.8rem !important">
                                                                            <div class="text-blue font-600 fs-14">
                                                                                <span>{{modifier.modifier_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 40%;">
                                                                            {{modifier_item.m_item_name}}
                                                                        </td>
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;vertical-align:bottom;">
                                                                                <span>{{modifier_item.quantity}}</span>
                                                                        </td>
                                                                        <td class="tdcart deepSkyBlue pointer td-truncate"
                                                                            style="padding: 0.6rem 0.8rem !important;width: 30%;vertical-align:bottom;">
                                                                                <span>{{modifier_item.amount}}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr v-for="(item,categoryindex) in category_summary"
                                                    :key="categoryindex">
                                                    <td class="tdbill" colspan="2"
                                                        style="border-top: 2px dotted #94bef0;text-align: right;text-transform: capitalize!important;line-height:0.25 !important;">
                                                        {{item.name}}
                                                    </td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{item.value}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;line-height:0.25 !important;">
                                                        Sub-Total Without Discount</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{sub_total_without_discount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill"
                                                        style="text-align: right;line-height:0.25 !important;"
                                                        colspan="2">
                                                        Item Discount
                                                    </td>
                                                    <td class="tdbill"
                                                        style="padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        ({{item_level_discount}})</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill"
                                                        style="text-align: right;line-height:0.25 !important;"
                                                        colspan="2">
                                                        Order Discount
                                                    </td>
                                                    <td class="tdbill"
                                                        style="padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        ({{order_discount.amount}})</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;line-height:0.25 !important;">
                                                        Sub-Total With Discount</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{sub_total_with_discount}}</td>
                                                </tr>
                                                <tr v-for="(charge ,chargeindex) in other_charges" :key="chargeindex">
                                                    <td class="tdbill"
                                                        style="text-align: right;line-height:0.25 !important;"
                                                        colspan="2">
                                                        {{charge.name}} <span
                                                            v-if="charge.type == 'PERCENTAGE'">({{charge.tax_rate}}%)</span>
                                                        <span v-if="charge.discount_included">
                                                            <el-tooltip class="item" effect="dark"
                                                                content="Discount not applicable for this charge"
                                                                placement="top">
                                                                <img
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                            </el-tooltip>
                                                        </span>
                                                    </td>
                                                    <td class="tdbill"
                                                        style="padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{charge.value}}</td>
                                                </tr>
                                                <tr v-if="orders.loyalty_applied">
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">
                                                        Reward Points</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{orders.loyalty_amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">
                                                        Total Amount</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{total_amount}}</td>
                                                </tr>
                                                <tr v-for="(tax, taxindex) in order_tax" :key="taxindex">
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">
                                                        {{tax.name}}({{tax.rate}}%)</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{tax.value}}</td>
                                                </tr>
                                                <tr v-if="is_round_off_required">
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">
                                                        Gross Total</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{gross_total}}</td>
                                                </tr>
                                                <tr v-if="is_round_off_required">
                                                    <td class="tdbill" colspan="2"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">
                                                        Round Off</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">
                                                        {{round_off}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr style="">
                                                    <th class="tdbill" colspan="2"
                                                        style="line-height:0.25 !important;text-align: right;padding-right: 28px !important;border-top-left-radius: 6px;border-bottom-left-radius: 6px;">
                                                        Loyalty Points Earned</th>
                                                    <th class="tdbill"
                                                        style="line-height:0.25 !important;padding-left: 28px !important;border-top-right-radius: 6px;border-bottom-right-radius: 6px;padding: 1.6rem 0.9rem !important;">
                                                        {{orders.loyalty_points_earned}}</th>
                                                </tr>
                                            </tfoot>
                                            <tfoot>
                                                <tr style="">
                                                    <th class="tfbill text-secondary" colspan="2"
                                                        style="line-height:0.25 !important;background-color: #f5a623;text-align: right;color:#303031!important;padding-right: 28px !important;border-top-left-radius: 6px;border-bottom-left-radius: 6px;">
                                                        Grand Total</th>
                                                    <!-- <td></td> -->
                                                    <th class="tfbill"
                                                        style="line-height:0.25 !important;background-color: #f5a623;color:#303031!important;padding-left: 28px !important;border-top-right-radius: 6px;border-bottom-right-radius: 6px;padding: 1.6rem 0.9rem !important;">
                                                        {{grand_total}}</th>
                                                </tr>
                                            </tfoot>
                                            <tfoot v-if="is_deposit_used">
                                                <tr>
                                                    <td class="tdbill" colspan="2" style="text-align: right;">Deposit
                                                        Used</td>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;">
                                                        {{customer_deposit_used}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <table v-else class="table tablebill popup">
                                            <thead>
                                                <tr style="border-bottom: dashed 1px #b8b9bb;">
                                                    <th class="thbill" style="width: 30%;">Items</th>
                                                    <th class="thbill" style="width: 15%;">Qty</th>
                                                    <!-- <th class="thbill" style="width: 15%;">Rate</th>
                          <th class="thbill" style="width: 15%;">Discount</th> -->
                                                    <th class="thbill" style="width: 25%;">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(vendor, vindex) in vendor_item_details" :key="vindex">
                                                <tr style="height: 13px;background-color: #f6f6f6;">
                                                    <td colspan="" class="text-center"
                                                        style="padding: 0.6rem 1.2rem!important;">
                                                        <strong>
                                                            {{vendor.vendor_name}}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr v-for="(item, viindex) in vendor.items" :key="viindex">
                                                    <td v-if="item.hasVariant"
                                                        class="tdcart deepSkyBlue pointer td-truncate"
                                                        style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                        {{item.product_name}}/{{item.variant_name}}<br>Rate
                                                        :{{item.price}}
                                                    </td>
                                                    <td v-else class="tdcart deepSkyBlue pointer td-truncate"
                                                        style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                        {{item.product_name}}<br>Rate :{{item.price}}</td>
                                                    <td class="tdcart"
                                                        style="padding: 0.6rem 0.8rem !important;width: 15%;">
                                                        {{item.quantity}}
                                                        <br>Discount :{{item.discount}}
                                                    </td>
                                                    <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.price}}</td> -->
                                                    <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.discount.amount}}</td> -->
                                                    <td class="tdcart"
                                                        style="padding: 0.6rem 0.8rem !important;width: 25%;">
                                                        {{item.total}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;;text-align: right;"
                                                        colspan="3">
                                                        Food Summary
                                                    </td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{vendor.food_summary}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3" style="text-align: right;">Beverage
                                                        Summary</td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor.beverage_summary}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3" style="text-align: right;">Additional
                                                        Charges</td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor.vendor_additional_charges}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" style="text-align: right;" colspan="3">
                                                        Item Discount
                                                    </td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor.vendor_discount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">
                                                        Subtotal</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{vendor.vendor_sub_total}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr style="height: 40px!important;background-color:rgb(246, 246, 246) !important;"
                                                    class="TaxDiscountTr">
                                                    <td colspan="6"
                                                        style="text-align:left;border-top: 1px dotted rgba(13, 132, 242, 0.55);">
                                                        <strong>Aggregate Order Summary</strong></td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill"
                                                        style="border-top: 2px dotted #94bef0;;text-align: right;"
                                                        colspan="3">
                                                        Vendor Food Summary
                                                    </td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{vendor_food_summary}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3" style="text-align: right;">Vendor
                                                        Beverage Summary</td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor_beverages_summary}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3" style="text-align: right;">Vendor
                                                        Additional Charges</td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor_additional_charges}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" style="text-align: right;" colspan="3">
                                                        Vendor Item Discount
                                                    </td>
                                                    <td></td>
                                                    <td class="tdbill">{{vendor_discount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">
                                                        Subtotal</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{vendor_sub_total}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">Order
                                                        Additional Charges</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">$01</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" style="text-align: right;" colspan="3">
                                                        Order Discount
                                                    </td>
                                                    <td></td>
                                                    <td class="tdbill">$24</td>
                                                </tr>
                                                <tr v-for="(charges, chindex) in other_charges" :key="chindex">
                                                    <td class="tdbill" style="text-align: right;" colspan="3">
                                                        {{charges.name}}<span
                                                            v-if="charges.type == 'PERCENTAGE'">({{charges.tax_rate}}%)</span>
                                                        <span v-if="charges.discount_included">
                                                            <el-tooltip class="item" effect="dark"
                                                                content="Discount not applicable for this charge"
                                                                placement="top">
                                                                <img
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                            </el-tooltip>
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                    <!-- {{charges}} -->
                                                    <td class="tdbill">{{charges.value}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">Total
                                                        Amount</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{total_amount}}</td>
                                                </tr>
                                                <tr v-for="(tax, tindex) in order_tax" :key="tindex">
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">
                                                        {{tax.name}}</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{tax.value}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">Gross
                                                        Total</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{gross_total}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tdbill" colspan="3"
                                                        style="text-align: right;border-top: 2px dotted #94bef0;;">Round
                                                        Off</td>
                                                    <td style="border-top: 2px dotted #94bef0;;"></td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;;">
                                                        {{round_off }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr style="">
                                                    <th class="tdbill" colspan="2"
                                                        style="line-height:0.25 !important;text-align: right;padding-right: 28px !important;border-top-left-radius: 6px;border-bottom-left-radius: 6px;">
                                                        Loyalty Points Earned</th>
                                                    <th class="tdbill"
                                                        style="line-height:0.25 !important;padding-left: 28px !important;border-top-right-radius: 6px;border-bottom-right-radius: 6px;padding: 1.6rem 0.9rem !important;">
                                                        {{orders.loyalty_points_earned}}</th>
                                                </tr>
                                            </tfoot>
                                            <tfoot v-if="is_deposit_used">
                                                <tr>
                                                    <td class="tdbill" colspan="2" style="text-align: right;">Deposit
                                                        Used</td>
                                                    <td class="tdbill" style="border-top: 2px dotted #94bef0;">
                                                        {{customer_deposit_used}}</td>
                                                </tr>
                                            </tfoot>
                                            <tfoot>
                                                <tr style="background-color: #f5a623;">
                                                    <th class="tfbill text-secondary" colspan="3"
                                                        style="text-align: right;color:#303031!important;">Grand Total
                                                    </th>
                                                    <td></td>
                                                    <th class="tfbill" style="color:#303031!important;">{{grand_total}}
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <span class="details-heading">Customer Details</span>
                                    <div class="digiblock-section" style="background: transparent;">
                                        <div class="section-container a-Zm">
                                            <div class="sections-div transdiv">
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Customer Name</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det textCaps">
                                                            {{customer_name || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Company Name</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det"
                                                            v-bind:title="customer_company_name">
                                                            {{customer_company_name || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Company Address</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det"
                                                            v-if="customer_company_address.street != '' || customer_company_address.street2 != '' || customer_company_address.township != '' || customer_company_address.city != '' || customer_company_address.state != '' || customer_company_address.country != ''">
                                                            {{customer_company_address.street}}<span
                                                                v-if="customer_company_address.street != '' && customer_company_address.street2 != ''">,</span>{{customer_company_address.street2}}<span
                                                                v-if="customer_company_address.township != ''">,</span>{{customer_company_address.township}}<span
                                                                v-if="customer_company_address.city != ''">,</span>{{customer_company_address.city}}<span
                                                                v-if="customer_company_address.state != ''">,</span>{{customer_company_address.state}}<span
                                                                v-if="customer_company_address.country != ''">,</span>{{customer_company_address.country}}
                                                        </span>
                                                        <span v-else class="right-div bill-det">
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Email</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det" v-bind:title="customer_email">
                                                            {{customer_email || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Phone</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            {{customer_phone_number || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Tax Code</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            {{customer_tax_code || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="is_deposit_used">
                                                    <span class="details-heading">Deposit Details</span>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Issued By</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{deposit_information.issued_by || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Date</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{deposit_information.date_of_issue_formatted || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Bank Name</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{deposit_information.bank_name || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Cheque/Transfer No
                                                        </div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{deposit_information.cheque_number || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Deposit Notes</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{deposit_information.deposit_notes || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="details-heading">Payment Details</span>
                                    <div class="digiblock-section" style="background: transparent;">
                                        <div class="section-container a-Zm">
                                            <div class="sections-div transdiv">
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Payment Status</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            PAID
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content" v-if="payments.length == 1">
                                                    <div class="content-left specbilldetail">Payment Method</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            {{payments[0]['mode'] || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span v-if="payments.length > 1" class="details-heading">Split Details</span>
                                    <div v-if="payments.length > 1" class="digiblock-section"
                                        style="background: transparent;">
                                        <div v-for="(payment,pindex) in payments" :key="pindex"
                                            class="section-container a-Zm">
                                            <div class="sections-div transdiv">
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Customer Name</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            {{payment.customer_name || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Payment Method</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det"
                                                            v-if="payment.mode == 'OTHERS'">
                                                            {{payment.payment_method || '-'}}
                                                        </span>
                                                        <span class="right-div bill-det"
                                                            v-else-if="payment.mode == 'CARD'">
                                                            {{payment.card_type || '-'}}
                                                        </span>
                                                        <span class="right-div bill-det" v-else>
                                                            {{payment.mode || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="div-content">
                                                    <div class="content-left specbilldetail">Amount</div>
                                                    <div class="content-right text-right">
                                                        <span class="right-div bill-det">
                                                            {{payment.amount || '-'}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <div class="div-content" style="border-top: 2px dotted #c0c4cc;">
                          <div class="content-left specbilldetail">Customer Name</div>
                          <div class="content-right text-right">
                            <span class="right-div bill-det">
                              John Dou
                            </span>
                          </div>
                        </div>
                        <div class="div-content">
                          <div class="content-left specbilldetail">Payment Method</div>
                          <div class="content-right text-right">
                            <span class="right-div bill-det">
                              {{reciept.payment_mode}}
                            </span>
                          </div>
                        </div>
                        <div class="div-content">
                          <div class="content-left specbilldetail">Amount</div>
                          <div class="content-right text-right">
                            <span class="right-div bill-det">
                              $120
                            </span>
                          </div>
                        </div> -->
                                                <div class="" v-if="payment.mode == 'CARD'">
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Name On Card</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{payment.name_on_card || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Card Provider</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{payment.card_provider || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="div-content">
                                                        <div class="content-left specbilldetail">Transaction ID</div>
                                                        <div class="content-right text-right">
                                                            <span class="right-div bill-det">
                                                                {{payment.transaction_id || '-'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mt-2" style="border-top: dashed 1px #94bef0;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <generate-invoice modal_name="generate-invoice" @closeinvoice="closeinvoice" :orderId="selectedOrderId"
            :customerId="selectedOrderCustomerId" :outletId="outletId"></generate-invoice>
    </div>
</template>
<style>
    .btn-pay {
        width: 100% !important;
        background-color: #00448b !important;
        color: #fff !important;
        font-weight: 600 !important;
    }

    .billing-mamage .content-right .right-div.bill-det {
        color: #303031 !important;
    }

    .cursor-th {
        cursor: pointer;
    }

    .hide-arrow {
        visibility: hidden;
    }

    th:hover .sorting-icon {
        visibility: visible !important;
        cursor: pointer;
    }

    .btn-icon:disabled {
        background: transparent !important;
    }

    td.icon-one {
        display: none !important;
    }

    i.s-4.payselected,
    [class^='icon-'].s-4.payselected {
        position: absolute;
        top: -1rem;
        right: -0.8rem;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 18px !important;
        width: 22px !important;
        height: 22px !important;
        line-height: 22px !important;
    }

    .table.table-scroll tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .flex-adons {
        display: inline-flex;
        padding-right: 6px;
        font-weight: 600;
    }

    .page-content {
        background-color: transparent !important;
    }

    .payselected.online {
        background: #06bb00;
        color: #fff;
    }

    .payselected.online:before {
        content: '\ea7b';
    }

    .card-title.paytype {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: 0.6px;
        color: #b5b5b5;
        background: transparent;
    }

    .card-body.pay-body {
        padding: 1rem !important;
        border-left: 6px solid #a1caff00 !important;
        margin-bottom: 15px !important;
        border-radius: 0px;
    }

    .table.tablebill th.thbill {
        color: #303031;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px !important;
        padding: 5px 8px !important;
        text-transform: none !important;
        border-bottom: 0px solid transparent !important;
    }

    .table.tablebill td.tdbill {
        color: #4e5764;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px;
    }

    .table.tablebill tfoot th.tfbill {
        padding: 10px 8px !important;
    }

    /* .table.tablebill tfoot tr {
  border-top: dotted 0.5px #b8b9bb;
  border-bottom: solid 0.5px #94bef0;
} */
    .table.tablebill tbody tr {
        height: auto !important;
    }

    .table.table-scroll tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .page-content {
        background-color: transparent !important;
    }

    a.add-customer-heading {
        font-weight: 600;
        line-height: 1.21;
        letter-spacing: 0.6px;
        color: #00448b !important;
    }

    .el-select {
        display: inline-block;
        position: relative;
        width: 100% !important;
    }

    .companyTimelineTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        cursor: pointer;
        font-weight: 500;
        border-radius: 20px 0 0 20px !important;
    }

    .companyRelationTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        border-left: none !important;
        cursor: pointer;
        /* border-radius: 0 20px 20px 0 !important; */
    }

    .companyGalleryTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        cursor: pointer;
        border-left: none !important;
        border-radius: 0 20px 20px 0 !important;
        margin-left: -3px;
    }

    .activeClass {
        background: #00448b !important;
        color: #fff !important;
        font-weight: bold;
    }

    .billing-mamage .avatar {
        background: #00448b;
    }

    .billing-mamage .avatar {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        line-height: 4rem;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 16px !important;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: #c3c3ba;
    }

    .receipt-name {
        color: #00448b !important;
        font-weight: 600 !important;
    }

    .outlet-address {
        padding-left: 12px;
        padding-right: 12px;
        font-weight: 600;
        text-transform: capitalize !important;
    }

    .taxes-heading {
        font-size: 14px;
        font-weight: bold;
        color: #00448b;
        padding-left: 14px;
    }

    .v-modal-layout {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .list-group-item.subheader {
        border-bottom: 1px solid #dee8f2;
        min-height: 4.8rem;
        height: 4.8rem;
        font-weight: 500;
        font-size: 13px;
        line-height: 44px;
        background: #cddbeb !important;
    }

    .filter-list {
        position: fixed;
        right: 0;
        width: 425px;
        margin-right: -425px;
        bottom: 0;
        top: 60px;
        z-index: 101;
        transition: all 0.4s ease;
    }

    .filter-list.right-sidebar {
        margin-right: 0;
        background: #cddbeb !important;
        top: 58px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
        max-height: 100%;
        overflow: auto;
        padding-bottom: 20px;
        overflow-x: hidden;
    }

    .tfoot-total-order {
        border-top: solid 0.5px #94bef0;
        border-bottom: solid 0.5px #94bef0;
    }

    .table.tablesubtotal tr {
        height: 10px !important;
    }

    .table.tablesubtotal th {
        padding: 4px !important;
    }

    .tablesubtotaltd,
    .tablesubtotalth {
        text-align: right !important;
    }

    .table.tablecart th.thcart {
        color: #303031;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px !important;
        padding: 5px 8px !important;
        text-transform: none !important;
        border-bottom: 0px solid transparent !important;
    }

    .table.tablecart td.tdcart {
        color: #4e5764;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px;
        padding: 5px 8px !important;
    }

    .table.tablecart tfoot th.tfcart {
        padding: 10px 8px !important;
    }

    .table.tablecart tfoot tr {
        border-top: dotted 0.5px #b8b9bb;
        border-bottom: solid 0.5px #94bef0;
    }

    .table.tablecart tbody tr {
        height: auto !important;
    }

    .border-bottom-dotted {
        border-bottom: solid 1px #b8b9bb;
        border-bottom-style: dashed;
    }

    .billing-mamage .right-sidebar .table td {
        color: #4e5764 !important;
        font-weight: 600;
    }

    /* .billing-mamage .table th {
  padding: 1.4rem 1.2rem !important;
} */
    .billing-mamage .table td {
        padding: 1.6rem 2.8rem !important;
        cursor: pointer !important;
        font-weight: 600;
    }

    /* .billing-mamage .table thead th,
.billing-mamage .table tbody td {
  border-bottom: 1px solid transparent !important;
} */
    /* .category-manage-info {
        top: 58px;
    }

    @media (max-width: 575px) {
        .category-manage-info {
            top: 85px !important;
        }
    } */

    .cursorNotAllowed {
        pointer-events: none !important;
    }
</style>

<script>
    //import flatPickr from 'vue-flatpickr-component'
    //import 'flatpickr/dist/flatpickr.css'
    // import html2canvas from "html2canvas"
    import DatePicker from 'vue2-datepicker'
    import myUpload from 'vue-image-crop-upload'
    import VueHtmlToPaper from 'vue-html-to-paper'
    import jsPDF from 'jspdf'

    import Printre from './Printre'
    import CreateProduct from './CreateProduct'
    import CheckoutPopup from './CheckoutPopup'

    import orderServices from './mixins/orders'
    import {
        EventBus
    } from './eventBus/nav-bar-event'

    import GenerateInvoice from './GenerateInvoice'

    export default {
        props: {
            msg: String
        },
        data() {
            const value2date = (dateRange) => {
                const date = new Date(dateRange)
                return dateRange ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
            }
            const date2value = (date) => {
                return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
            }
            return {
                valueType: {
                    value2date,
                    date2value
                },
                orders: {},
                searchcustomer: 100,
                convertUTC: false,
                ajaxCallInProgress: true,
                toggleSideBar: false,
                dialogImageUrl: '',
                dialogVisible: false,
                isCompanyTimeline: true,
                isCompanyRelationship: false,
                isCompanygallery: false,
                show: false,
                startdate: '',
                enddate: '',
                selectedOrder: null,
                details: [],
                searchdata: '',
                reciept: {},
                showBilling: false,
                output: null,
                sub_total: '',
                sub_total_without_discount: '',
                sub_total_with_discount: '',
                startDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                ),
                endDate: new Date(),
                dateRange: [],
                currency: '',
                grand_total: '',
                vendor_additional_charges: '',
                vendor_beverages_summary: '',
                vendor_food_summary: '',
                vendor_sub_total: '',
                order_discount: {},
                other_charges: [],
                order_tax: [],
                vendor_discount: '',
                vendor_item_details: [],
                receipt_id: null,
                round_off: '',
                isPopUp: '',
                food_summary: '',
                beverages_summary: '',
                item_details: '',
                gross_total: '',
                total_amount: '',
                additional_charges: '',
                discount: '',
                created_time: '',
                served_by: '',
                generated_by: '',
                item_level_discount: '',
                is_round_off_required: false,
                selectedOrderId: null,
                selectedOrderCustomerId: null,
                sub_total: null,
                lang: {
                    type: 'en',
                    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    months: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec'
                    ],
                    pickers: [
                        'next 7 days',
                        'next 30 days',
                        'previous 7 days',
                        'previous 30 days'
                    ],
                    placeholder: {
                        date: 'Select Date',
                        dateRange: 'Select Date Range'
                    }
                },
                confirmText: {
                    type: String,
                    default: 'APPLY'
                },
                shortcuts: [{
                        text: 'Today',
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate()
                            )
                            this.endDate = new Date()
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: 'This Week',
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - new Date().getDay()
                            )
                            this.endDate = new Date()
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: 'This Month',
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                            )
                            this.endDate = new Date()
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: 'This Year',
                        onClick: () => {
                            this.startDate = new Date(new Date().getFullYear(), 0, 1)
                            this.endDate = new Date()
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    }
                ],
                payments: [],
                customer_email: null,
                customer_name: null,
                customer_phone_number: null,
                customer_company_name: null,
                customer_company_address: null,
                customer_tax_code: null,
                order_type: '',
                outlet_tax_code: '',
                outlet_address: '',
                table_id: '',
                outlet_name: '',
                outlet_phone_number: '',
                outlet_email_address: '',
                outlet_location: '',
                pos_id: '',
                showIllustrator: false,
                count: 0,
                limit: 10,
                skip: 0,
                start: 1,
                end: 0,
                total_orders: 0,
                image: '',
                closed_time: null,
                passed_key: {
                    key: 'created_time',
                    value: 1
                },
                outletId: null,
                category_summary: [],
                customer_deposit_used: '',
                is_deposit_used: '',
                deposit_information: {
                    issued_by: '',
                    date_of_issue_formatted: '',
                    bank_name: '',
                    cheque_number: '',
                    deposit_notes: ''
                }
            }
        },

        mixins: [orderServices],
        components: {
            CreateProduct,
            CheckoutPopup,
            Printre,
            GenerateInvoice
            //flatPickr
        },
        methods: {
            generateInvoice(orderId, customerId, outletId) {
                this.selectedOrderId = orderId
                this.selectedOrderCustomerId = customerId
                this.outletId = outletId
                this.$modal.show('generate-invoice')
            },
            closeinvoice() {
                this.$modal.hide('generate-invoice')
                this.get_order_history()
            },
            myFunction: function () {
                window.open('/printreceipt.html', '_blank')
            },
            createPDF() {
                const doc = new jsPDF()
                /** WITHOUT CSS */
                const contentHtml = this.$refs.content.innerHTML
                doc.fromHTML(contentHtml, 15, 15, {
                    width: 170
                })
                doc.save('sample.pdf')
            },
            print() {
                // Pass the element id here
                this.$htmlToPaper('printMe', () => {
                    this.$log.debug('Printing done or got cancelled!')
                })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            },
            createemployee() {
                this.$modal.show('bill-details')
            },
            hideemployee() {
                this.$modal.hide('bill-details')
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url
                this.dialogVisible = true
            },
            toggleShow() {
                this.show = !this.show
            },
            showBillDetail() {
                this.$modal.show('bill-details')
            },
            hideBillDetail() {
                this.$modal.hide('bill-details')
            },
            companyTimeline() {
                this.isCompanyTimeline = true
                this.isCompanyRelationship = false
                this.isCompanygallery = false
            },
            relationshipPage() {
                this.isCompanyRelationship = true
                this.isCompanyTimeline = false
                this.isCompanygallery = false
            },
            galleryPage() {
                this.isCompanyRelationship = false
                this.isCompanyTimeline = false
                this.isCompanygallery = true
            },
            toggleBtn() {
                this.toggleSideBar = !this.toggleSideBar
            },
            toggleBtn1() {
                this.toggleSideBar = false
                this.$router.push({
                    name: 'BillingManage',
                    params: {}
                })
            },
            // showPayBill() {
            //   this.$modal.show("pay-bill");
            // },
            // hidePayBill() {
            //   this.$modal.hide("pay-bill");
            // },
            selectOrder(order) {
                this.selectedOrder = order
            },
            async get_order_history(param = '') {
                // this.convertUTC = true;
                try {
                    if (param == 'prev') this.count--
                    else if (param == 'next') this.count++
                    else if (param == 'init') {
                        this.count = 0
                    } else if (param == 'search' || param == '') {
                        this.count = 0
                    }
                    this.ajaxCallInProgress = true
                    let params = {
                        searchdata: this.searchdata,
                        dateRange: this.dateRange,
                        status: 'paid',
                        skip: this.count * this.limit,
                        limit: this.limit,
                        sort_key: this.passed_key.key,
                        sort_value: this.passed_key.value
                    }
                    let res = await this.orderHistory(params)
                    this.details = res.response.order_history
                    this.total_orders = res.response.total_orders
                    this.start = this.count * this.limit + 1
                    let tmp = this.count * this.limit + this.limit
                    this.end = tmp < this.total_orders ? tmp : this.total_orders
                    this.ajaxCallInProgress = false
                    if (
                        this.details.length == 0 &&
                        !this.searchdata &&
                        !this.ajaxCallInProgress
                    ) {
                        this.showIllustrator = true
                    } else {
                        this.showIllustrator = false
                    }
                } catch (reason) {
                    // TODO: Swal Error Handling
                    this.$log.debug(reason)
                }
            },
            async get_order_details_receipt(orderId) {
                try {
                    // let order = this.details[orderIndex]
                    let params = {
                        order_id: orderId
                    }
                    let res = await this.orderHistoryDetails(params)
                    this.orders = res.response
                    this.showBilling = true
                    this.toggleSideBar = !this.toggleSideBar

                    this.isPopUp = res.isPopup
                    if (this.isPopUp) {
                        this.vendor_additional_charges =
                            res.response.vendor_additional_charges
                        this.vendor_beverages_summary = res.response.vendor_beverages_summary
                        this.vendor_food_summary = res.response.vendor_food_summary
                        this.vendor_discount = res.response.vendor_discount
                        this.vendor_item_details = res.response.vendor_item_details
                        this.vendor_sub_total = res.response.vendor_sub_total
                    } else {
                        this.item_details = res.response.item_details
                        this.food_summary = res.response.food_summary
                        this.beverages_summary = res.response.beverages_summary
                        this.discount = res.response.order_discount.amount
                        this.sub_total = res.response.sub_total
                    }
                    this.customer_name = res.response.payments[0].customer_name
                    this.customer_email = res.response.payments[0].customer_email
                    this.customer_company_name = res.response.payments[0].customer_company
                    this.customer_company_address = res.response.company_address[0]
                    this.customer_tax_code = res.response.payments[0].customer_tax_code
                    this.created_time = res.response.created_time
                    this.customer_phone_number = res.response.payments[0].customer_phone_number

                    this.order_tax = res.response.order_tax
                    this.other_charges = res.response.other_charges
                    this.order_discount = res.response.order_discount
                    this.currency = res.response.currency
                    this.grand_total = res.response.grand_total
                    this.round_off = res.response.round_off
                    this.gross_total = res.response.gross_total
                    this.total_amount = res.response.total_amount
                    this.payments = res.response.payments
                    this.generated_by = res.response.generated_by
                    this.served_by = res.response.served_by
                    this.receipt_id = res.response.receipt_id
                    this.item_level_discount = res.response.item_level_discount
                    this.order_type = res.response.order_type
                    this.table_id = res.response.table_number
                    this.outlet_tax_code = res.response.outlet_tax_id
                    this.outlet_address = res.response.outlet_address
                    this.outlet_phone_number = res.response.outlet_phone_number
                    this.outlet_email_address = res.response.outlet_email_address
                    this.outlet_name = res.response.outlet_name
                    this.outlet_location = res.response.outlet_location
                    this.pos_id = res.response.pos_id
                    this.is_round_off_required = res.response.is_round_off_required
                    this.closed_time = res.response.closed_time
                    this.image = res.response.profile_pic
                    this.category_summary = res.response.category_summary
                    this.customer_deposit_used = res.response.customer_deposit_used
                    this.is_deposit_used = res.response.is_deposit_used
                    this.deposit_information = res.response.deposit_information
                    this.sub_total_without_discount = res.response.sub_total_without_discount
                    this.sub_total_with_discount = res.response.sub_total_with_discount
                    this.$router.push({
                        name: "BillingManage",
                        params: {
                            id: orderId
                        }
                    })
                } catch (error) {
                    // TODO: SWAL Error Handling
                }
            },
            resetDate() {
                let startDate = new Date();
                startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0], this.loggedInUser
                    .business_day_start_time.split(":")[1]);
                let endDate = new Date();
                this.dateRange = [startDate, endDate];
                this.get_order_history();
                this.convertUTC = false;
            },
            toggleBodyClass(order_id) {
                this.$router.push({
                    name: 'Printre',
                    params: {
                        order_id: order_id
                    }
                })
                window.open('printre', '_blank')
            },
            setPasskey: function (newKey) {
                this.$set(this.passed_key, 'key', newKey)
                var value = this.passed_key.value == -1 ? 1 : -1
                this.$set(this.passed_key, 'value', value)
                if (this.count == 0) this.get_order_history()
                else this.count = 0
            },
            BillingManage() {
                this.get_order_history()
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser
            },
        },
        mounted() {
            setTimeout(() => {
                let startDate = new Date();
                startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0]);
                // startDate.setMinutes(this.loggedInUser.business_day_start_time.split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]);
                startDate.setMinutes(this.loggedInUser.business_day_start_time.split(":")[1]);
                    console.log(this.loggedInUser.business_day_start_time.split(":")[0])
                    console.log(this.loggedInUser.business_day_start_time.split(":")[1])
                    console.log(startDate)
                let endDate = new Date();
                this.dateRange = [startDate, endDate];
                EventBus.$emit('customerdetailsLoad', false)
                EventBus.$emit('settingsLoad', false)
                EventBus.$emit('reportsLoad', false)
                EventBus.$on('BillingManage', value => {
                    this.get_order_history()
                })
                this.get_order_history();
                if (this.$route.params.id) {
                    this.get_order_details_receipt(this.$route.params.id);
                    // this.toggleSideBar = true;
                }
            }, 2000);

        },
        created() {
            // this.get_order_history();
            // this.onDateChange();
        },

        // watch: {
        //   dateRange() {
        //     this.get_order_history();
        //   }
        // }
    }
</script>