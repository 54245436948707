import Swal from 'sweetalert2'
export default {
     data(){
          return{

          }
     },
     components:{
          Swal
     },
     methods:{
          async managePrinters(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.post('/outlet/manage_printers', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          async PrinterDropdown(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.post('/scm/category_filter', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          // async UpdatePrinter(params) {
          //      return new Promise(async (resolve, reject) => {
          //           try {
          //                const response = await this.$http.post('/outlet/printer/configuration/update', params)
          //                if(response.data.status_id == 1) {
          //                     resolve(response.data)
          //                } else {
          //                     reject(response.data.reason)
          //                }
          //           } catch(error) {
          //                this.warning_msg = error.response.data.reason
          //                this.$refs.warning_modal.open();
          //                setTimeout(() => {
          //                this.$refs.warning_modal.close();
          //                }, 3000);
          //           }
          //      })
          // }
          async UpdatePrinter(params){
               return new Promise(async (resolve,reject) => {
                   try{
                       let response = await this.$http.post('/outlet/printer/configuration/update', params)
                       if(response.data.status_id == 1){
                           resolve(response.data)
                       }
                       else{
                           reject(response.data.reason)
                         //   this.modal_msg = response.data.reason
                           if(response.data.reason == 'Invalid Type: no_of_copies'){
                              this.modal_msg = 'Please Enter No Of Copies'
                           }   else {
                              this.modal_msg = 'Please Enter All The Fields'
                           }
                           this.$refs.warning_modal.open();
                           setTimeout(() => {
                               this.$refs.warning_modal.close();
                           }, 3000);
                       }
                   }
                   catch(error){
                    //    this.modal_msg = error
                         reject(error.response.data.reason)
                       this.modal_msg = 'Please Enter All The Fields'
                       this.$refs.warning_modal.open();
                       setTimeout(() => {
                           this.$refs.warning_modal.close();
                       }, 3000);
                   }
               })
           },
     }
}