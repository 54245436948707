<template>
    <div class="mt-10 w-100">
        <div class="m-input-icon m-input-icon--left m-input-icon--right ml-4" style="width:50%">
            <input
                v-if="loggedInUser.role != 'default_order_receiver'"
                type="text"
                class="form-control m-input m-input--pill"
                placeholder="Find Order by Table Number"
                autocomplete="off"
                v-model="searchdata"
                @input="getTablesAPI()"
            />
            <span class="m-input-icon__icon m-input-icon__icon--left">
                <span>
                    <i class="icon icon-magnify"></i>
                </span>
            </span>
            <span class="m-input-icon__icon m-input-icon__icon--right">
                <span>
                    <i
                        class="icon icon-close-circle"
                        style="padding-top: 1px;!important;font-size:20px !important;color:rgba(0,0,0,0.54) !important;cursor:pointer !important;"
                        v-if="searchdata.length"
                        @click="searchdata='';getTablesAPI()"
                    ></i>
                </span>
            </span>
        </div>
        <div class="d-flex justify-content-between mt-4 ml-2 mr-8">
            <div class="col-md-6 col-lg-6 col-sm-12 mt-2">
                <a class="select-seats">
                    <div class="tableAvailable"></div>
                    Available - {{ availablity_count }}
                </a>
                <a class="select-seats">
                    <div class="tableOccupied"></div>
                    Occupied - {{ occupied_count }}
                </a>
                <a class="select-seats">
                    <div class="inprogress-select"></div>
                    Inprogress - {{ inprogress_count }}
                </a>
                <a class="select-seats">
                    <div class="assingedtoothers-select"></div>
                    Assigned to Others
                </a>
                <!-- <a class="select-seats">
                    <div class="itemsReady"></div>
                    Items Ready to Deliver
                </a> -->
                <span class>
                    <i class="text-white icon-check s-4 selected-card-legend"></i>
                </span>
                <span class="defaultBlue fs-14 pl-2" style="font-weight:600;">Delivered</span>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 text-right mt-2" v-if="loggedInUser.is_cashier && loggedInUser.cashier_session">
                <span class="bg-secondary p-2 text-white font-weight-bold" style="border-radius:15px;">Open Bills : {{ bills.open }}</span>
                <span class="bg-secondary p-2 text-white font-weight-bold ml-2" style="border-radius:15px;white-space:nowrap">Closed Bills : {{ bills.closed }}</span>
            </div>
        </div>
        <div v-if="showIllustrator" class="row mt-5">
            <div class="col-lg-12 text-center">
                <div class="noordersnew">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="height: 80%;" /><br>
                    <span class="font-weight-bold" style="font-size:24px !important">No Search Result Found</span>
                </div>
            </div>
        </div>
        <div v-if="!showIllustrator">
            <div class="row pt-10" v-if="ajaxCallinprogress">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-15" style="display: flex;-webkit-flex-wrap: wrap !important;">
                <template v-for="(t, index) in tables">
                    <span :key="index" :class="{occupiedTable:t.orders.length > 0 && t.isInprogress == false,availableTable: t.orders.length < 1,disableTable: t.orders.length < 1,inprogressOrder: t.isInprogress,mergeTableDisable:(t.orders.length && t.orders[0].tables[0] != t.table_id) || t.isInprogress, 'gray-and-disabled':t.can_gray_out}" @click="showPrimaryOrder(t['orders'][0]['order_id'],t.orders[0].tables[0],t.table_id,t.isInprogress, t.can_gray_out, t.has_manager_created_order)">
                        <i v-if="t.orders.length && t.orders[0]['is_delivered'] && t.orders[0].tables[0] == t.table_id" class="text-white icon-check s-4 selected-card"></i>
                        {{ t.table_id }}
                        <span v-if="t['orders'].length && t['orders'][0]['items_prepared'] && t.orders[0].tables[0] == t.table_id" class="badge badge-itemsReadyTable">{{ t['orders'][0]['items_prepared'] }}</span>
                        <span v-if="t.orders.length && t.orders[0].tables[0] != t.table_id" class="merge-Table">
                            Merged With
                            {{ t.orders[0].tables[0] }}
                        </span>
                        <span v-else-if="t.orders.length && t.orders[0].tables[0] == t.table_id && t.orders[0].tables.length != 1">+ {{ t.orders[0].tables.length - 1 }} More</span>
                        <span class="no-of-pax" v-if="t.orders.length && t.orders[0].tables[0] == t.table_id && !t.isInprogress && t.orders.length && t.orders[0].no_of_pax">No of GUESTS : {{ t.orders[0].no_of_pax }}</span>
                    </span>
                </template>
            </div>
        </div>
        <table-selection-popup-message v-if="table_selection_popup_message" @hideMessagePopupModal="hideMessagePopupModal" modal_name="table-selection-popup-message"></table-selection-popup-message>
    </div>
</template>
<script>
import DisplayOrderDetail from './DisplayOrderDetail'
import { EventBus } from './eventBus/nav-bar-event.js'
import ManageOrderService from './mixins/manage_order.js'
const TableSelectionPopupMessage = () => import("./TableSelectionPopupMessage")
export default {
    data() {
        return {
            tables: [],
            details: [],
            availablity_count: 0,
            occupied_count: 0,
            ajaxCallinprogress: false,
            inprogress_count: 0,
            showIllustrator: false,
            searchdata:'',
            table_selection_popup_message: false
        }
    },
    methods: {
        hideMessagePopupModal() {
            this.table_selection_popup_message = false;
            setTimeout(() => {
                this.$modal.hide('table-selection-popup-message')
            }, 500);
        },
        getTablesAPI(){
            this.tables = []
            this.getTables()
        },
        showPrimaryOrder(orderId, tableId, table_id, isInprogress, can_gray_out, has_manager_created_order) {
            if(!can_gray_out) {
                if(has_manager_created_order) {
                    this.table_selection_popup_message = true;
                    setTimeout(() => {
                        this.$modal.show('table-selection-popup-message')
                    }, 500);
                } else {
                    this.$router.push({
                        name: 'ViewOrder',
                        params: {
                            id: orderId,
                            dineIn:true
                        }
                    })
                }
            }
            else {
                console.log('gray out')
            }
        },
        confirmInprogress(orderId) {
            this.$swal({
                title: 'Order Inprogress',
                text: 'Order is in Progress. Do You Wish To Continue ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED',
                cancelButtonText: 'DISCARD',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.$router.push({
                        name: 'TakeOrderNew',
                        params: { orderId, inProgressOrder: true }
                    })
                } else {
                    this.$http.post('/orders/discard', {orderId: orderId}).then(res => {
                        if (res.data.status_id == 1) {
                            this.$toasted.global.success('Order Discarded Successfully')
                            this.$emit('discard', true)
                            this.getTables()
                        }
                    })
                }
            })
        }
    },
    components: {
        DisplayOrderDetail,
        TableSelectionPopupMessage
    },
    mixins: [ManageOrderService],
    computed: {
        bills() {
            return this.$store.state.bills
        },
        loggedInUser() {
            return this.$store.state.loggedInUser
        }
    },
    mounted() {
        EventBus.$on('CloseOrderDetail', modal_name => {
            this.$modal.hide('order-detail')
        })
        EventBus.$on('ManageOrderTable', details => {
            this.tables = details.tables
            if (this.tables.length == 0 && !this.ajaxCallinprogress) {
                this.showIllustrator = true
            } else {
                this.showIllustrator = false
            }
            this.availablity_count = details.availablity_count
            this.occupied_count = details.occupied_count
            this.inprogress_count = details.inprogress_count
        })
        EventBus.$on('PaymentComplete', () => {
            this.getTables()
        })
        EventBus.$on('UpdateOrderType', () => {
            this.getTables()
            this.$modal.hide('order-detail')
        })
        if (this.$route.params.order_id && this.$route.params.order_modal) {
            let orderId = this.$route.params.order_id
            this.showPrimaryOrder(orderId)
            setTimeout(() => {
                this.$route.params.order_modal = false
            }, 5000)
        }
    },
    created() {
        this.getTables()
    },
    destroyed() {
        EventBus.$off('PaymentComplete')
        EventBus.$off('ManageOrderTable')
        EventBus.$off('CloseOrderDetail')
    }
}
</script>
<style>
.no-of-pax {
    font-size: 9px !important;
    text-align: center;
}
.tableAvailable {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #f9a00b;
    margin-top: 3px !important;
    margin-right: 8px !important;
}

.tableOccupied {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #00448b;
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.inprogress-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.itemsReady {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #ff3b3b;
    margin-top: 3px;
    margin-right: 8px;
}
.inprogressOrder {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 37px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
}
.deliveredLegend {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #46d830;
}

.availableTable {
    width: 105px;
    cursor: pointer;
    height: 100px;
    font-weight: 600;
    padding-top: 27px !important;
    padding: 10px;
    text-align: center;
    display: inline-grid;
    border-radius: 7px;
    margin-left: 18px;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184);
    cursor: pointer;
}

.occupiedTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 27px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
}

.takeAway {
    cursor: pointer;
    width: 105px;
    height: 100px;
    padding-top: 19px !important;
    padding: 13px;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
        to bottom,
        #1dc75d,
        #1ed262 51%,
        #17e264,
        #9fffac
    );
}

.deliviriesTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    padding-top: 16px !important;
    padding: 2px;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
        to bottom,
        #1dc75d,
        #1ed262 51%,
        #17e264,
        #9fffac
    );
}

.badge-itemsReadyTable {
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: -21px;
    margin-left: 32px;
    display: -webkit-box;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 11px !important;
    border-radius: 100px;
    background-color: #ff3b3b !important;
    padding-left: 6px !important;
    margin-right: 8px !important;
    line-height: 10px;
}
.select-seats {
    display: inline-flex;
    padding-right: 20px;
    cursor: pointer;
    color: #00448b !important;
    font-weight: 600;
    white-space: nowrap;
}
.email {
    left: 15px;
}

.phoneCompany {
    left: 10px;
}

.orderNotification {
    width: 17px;
    height: 17px;
    display: -webkit-box;
    position: relative;
    left: 52px;
    bottom: 52px;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 11px !important;
    border-radius: 100px;
    background-color: #ff3b3b !important;
}

.order-table-view .selected-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px !important;
    border-radius: 100px;
    margin-top: -20px;
    background-color: #4caf50 !important;
    margin-left: 32px;
    z-index: 9;
}

.selected-card-legend.s-4 {
    position: relative;
    width: 12px !important;
    height: 12px !important;
    line-height: 20px !important;
    justify-content: center;
    font-size: 14px !important;
    border-radius: 100px;
    background-color: #4caf50 !important;
}
.mergeTableDisable {
    opacity: 0.6;
    cursor: not-allowed !important;
    pointer-events: none !important;
}
.disableTable {
    pointer-events: none !important;
    opacity: 0.5 !important;
}
.noordersnew{
    margin-bottom: 20px;
}
.assingedtoothers-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #797777;
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.gray-and-disabled {
    background-color:#797777 !important;
    cursor: not-allowed !important;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: none !important;
    background-image: none !important;
}
</style>
