<template>
  <div class="page-layout blank">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="list-title d-flex" style="font-weight: 500;">
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <a class="btn btn-secondary btn-smm" @click="showProductType()">+ Add New Product Type</a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  :maxlength="searchcustomer"
                  type="text"
                  class="form-control textremove holder"
                  id="inputWarning2"
                  aria-describedby="inputWarning2Status"
                  placeholder="Search..."
                  v-model="searchdata"
                  @input="getAllProductTypes('searchdata')"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="totalProductType">
              <span
                class="page-info px-2 hidden-md-down"
              >{{start}} - {{end}} of {{totalProductType}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :disabled="count<=0"
                  :class="{'disabled':ajaxCallInProgress}"
                  v-on:click="getAllProductTypes('prev')"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :disabled="end >= totalProductType"
                  :class="{'disabled':ajaxCallInProgress}"
                  type="button"
                  v-on:click="getAllProductTypes('next')"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
            <p class="text-center text-secondary fs-28 font-600 pt-5">No Product Type Yet!!!</p>
          </div>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info">PRODUCT TYPES</div>
        <div
          class="page-layout carded left-sidebar"
          style="background-color: #00448b!important;padding: 10px!important;"
        >
          <div class="page-content-wrapper">
            <div class="page-content">
              <div class="demo-content">
                <table class="table table-scroll">
                  <thead>
                    <tr>
                      <th style="width: 1% !important;flex: 0 0 70px!important;"></th>
                      <th>
                        <span v-on:click="setPasskey('name')" class="cursor-th">
                          <span
                            v-if="passed_key.key=='name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          PRODUCT TYPE NAME
                        </span>
                      </th>
                      <th class="hidden-sm-down">DESCRIPTION</th>
                      <th>NUMBER OF PRODUCTS</th>
                      <th>STATUS</th>
                      <th>Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ajaxCallInProgress">
                      <td colspan="6" class="text-center justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="allProductType.length==0 && !ajaxCallInProgress"
                      style="background-color: transparent !important;border-bottom: none !important;"
                    >
                      <td v-if="searchdata" colspan="6" class="text-center justify-content-center">
                        <strong style="display:block;padding-top:38px;">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </strong>
                      </td>
                    </tr>
                    <tr v-for="productTye in allProductType" :key="productTye.product_type_id">
                      <td style="flex: 0 !important;padding: 0px 2px 0px 22px!important;">
                        <el-popover
                          placement="right"
                          class="productAvatar pointer"
                          width="124"
                          trigger="hover"
                        >
                          <div v-if="productTye.image_url" class="img-icon">
                            <img :src="productTye.image_url" style="border-radius:18px;" />
                          </div>
                          <div v-else>
                            <avatar
                              :username="productTye.name.replace(/ .*/,'')"
                              :src="productTye.image_url"
                              :size="100"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </div>
                          <a
                            slot="reference"
                            class="lightBlue moreMembers"
                            style="text-decoration: none!important;"
                          >
                            <avatar
                              :username="productTye.name.replace(/ .*/,'')"
                              :src="productTye.image_url"
                              :size="40"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </a>
                        </el-popover>
                      </td>
                      <td
                        class="font-weight-bold"
                        style="width: 180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding: 0px 0px 0px 36px !important;"
                        :title="productTye.name"
                      >{{productTye.name}}</td>
                      <td
                        class="hidden-sm-down"
                        style="width: 180px;padding-right: 0px!important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 18px!important;"
                        :title="productTye.description"
                      >{{productTye.description || '-'}}</td>
                      <td
                        class="text-secondary font-weight-bold fs-14"
                        style="padding: 0px 0px 0px 30px !important;"
                      >
                        <el-tooltip content="View Products" placement="top">
                          <div
                            class="chip chipproducts"
                            @click="openProductInfo(productTye.product_type_id)"
                            :class="{'clickDisabled':productTye.number_of_products == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ productTye.number_of_products || 0 }}</div>
                            <span v-if="productTye.number_of_products  > 1">Products</span>
                            <span v-else>Product</span>
                          </div>
                        </el-tooltip>

                        <!-- <el-tooltip content="View Products" placement="top">
                                                    <span style="padding: 0px 50px!important;cursor:pointer;" 
                                                    :class="{'clickDisabled':productTye.number_of_products == 0}" 
                                                    @click="openProductInfo(productTye.product_type_id)">{{productTye.number_of_products}}</span>
                        </el-tooltip>-->
                      </td>
                      <td style="0px 10px !important">
                        <el-switch
                          v-model="productTye.status"
                          @change="productTypeStatus(productTye.status,productTye.product_type_id)"
                        ></el-switch>
                      </td>
                      <td>
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="showEditProductType(productTye.product_type_id)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button
                            @click="confirmDelete(productTye)"
                            type="button"
                            class="btn btn-icon actionicons"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-product-type
        v-if="create"
        modal_product_type="create-product-type"
        @saving="getAllProductTypes"
        @cancelpt="cancelpt"
      ></create-product-type>
      <edit-product-type
        v-if="edit"
        modal_edit_product_type="edit-product-type"
        @savingEdited="getAllProductTypes"
        @editCancelpt="editCancelpt"
      ></edit-product-type>
      <product-information
        modal_product_information="product-information"
        :productById="productById"
        :source="'fromproductType'"
      ></product-information>
    </div>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
import { EventBus } from './eventBus/nav-bar-event.js'
import CreateProductType from './CreateProductType'
import EditProductType from './EditProductType'
import ProductTypeService from './mixins/productTye'
import ProductInformation from './ProductInformation'
export default {
  mixins: [ProductTypeService],
  data() {
    return {
      status_product_type: '',
      searchcustomer: 100,
      allProductType: [],
      searchdata: '',
      ajaxCallInProgress: false,
      productById: '',
      source: '',
      create: false,
      edit: false
    }
  },
  components: {
    CreateProductType,
    EditProductType,
    Avatar,
    ProductInformation
  },
  methods: {
    showProductType() {
      this.create = true
      setTimeout(() => {
        this.$modal.show('create-product-type')
      }, 100)
    },
    cancelpt() {
      this.create = false
    },
    cancelProductType() {
      this.$modal.hide('create-product-type')
    },
    showEditProductType(productTypeById) {
      this.edit = true
      setTimeout(() => {
        EventBus.$emit('detailsProductTypeById', productTypeById)
        this.showEditProductTypeModal()
      }, 500);
    },
    showEditProductTypeModal() {
      this.$modal.show('edit-product-type')
    },
    editCancelpt() {
      this.edit = false
    },
    cancelEditProductType() {
      this.$modal.hide('edit-product-type')
    },
    productTypeStatus(status, product_type_id) {
      this.$http
        .post('/products/edit_product_type_status', {
          product_type_id: product_type_id,
          status: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(
              'Product Type Status Updated Successfully'
            )
          } else {
            this.$toasted.global.error('Cannot Update Product Type Status')
          }
        })
    },
    openProductInfo(productById) {
      this.productById = productById
      EventBus.$emit('productTypeProductDetails', productById)
      this.$modal.show('product-information')
    }
  },
  created() {
    this.getAllProductTypes('init')
  }
}
</script>
<style scoped>
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.avatar {
  background: #00448b;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
.table-inventery-manage tbody:nth-child(odd) {
  background-color: #f5f6f9 !important;
}
.table.table-inventery-manage td {
  padding: 0.2rem 1rem !important;
}
.page-layout.carded {
  overflow: hidden !important;
}
@media (max-width: 1200px) {
  .table.table-inventery-manage th {
    padding: 1.4rem 5px !important;
  }
  .table.table-inventery-manage td {
    padding: 0px 5px 0px 25px !important;
  }
}
.btn-icon:disabled {
  background: transparent !important;
}
.img-icon img {
  width: 100px !important;
}
.clickDisabled {
  pointer-events: none !important;
}
</style>