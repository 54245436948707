<template>
    <div class="page-layout blank">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div  class="list-title d-flex" style="font-weight: 500;">
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-left"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-right"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <button v-if="isOrderTypeActive" class="btn btn-secondary btn-smm" @click="showOrderType()">+ Add New Order Type</button>
                            <button v-if="isPricingPage" class="btn btn-secondary btn-smm" @click="addNewPricing()">+ Add New Pricing</button>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center" style="margin:0 auto;">
                        <div class style="justify-content: space-between !important">
                            <div class="list-title pointer" style="font-weight: 500;">
                                <a class="orderTypeTab" :class="{ activeOrder : isOrderTypeActive}" @click="OrderTypePage()">Order Type</a>
                                <a class="pricingTab" :class="{activeOrder: isPricingPage}" @click="PricingPage()">Pricing</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" v-if="isOrderTypeActive">
                        <div class="breadcome-heading d-xl-inline">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input autocomplete="off" type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search..." @input="getAllOrderTypeAPI('search_key')" v-model="search_key" />
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="pull-right mr-1" v-if="total">
                            <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button :disabled="count<=0" v-on:click="getAllOrderTypeAPI('prev')" :class="{'disabled':ajaxCallInProgress}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button :disabled="end >= total" v-on:click="getAllOrderTypeAPI('next')" :class="{'disabled':ajaxCallInProgress}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" v-if="isPricingPage">
                        <div class="breadcome-heading d-xl-inline">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input autocomplete="off" type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search..." @input="getAllProductsForYieldAPI('searchdata')" v-model="searchdata" />
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="pull-right mr-1" v-if="product_count">
                            <span class="page-info px-2 hidden-md-down">{{startProduct}} - {{endProduct}} of {{product_count}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button :disabled="count<=0" v-on:click="getAllProductsForYieldAPI('prev')" :class="{'disabled':ajaxCallInProgressProduct}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button :disabled="endProduct >= product_count" v-on:click="getAllProductsForYieldAPI('next')" :class="{'disabled':ajaxCallInProgressProduct}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </h2>
            <div v-if="isOrderTypeActive">
                <div v-if="showIllustrator" class="row no-orders-row">
                    <div class="col-lg-12 text-center">
                        <div class="noorders">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                            <p class="text-center text-secondary fs-28 font-600 pt-5">No Order Type Yet!!!</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="category-manage-info" style="top:70px !important">ORDER TYPE</div>
                    <div class="page-layout carded left-sidebar mt-5" style="background-color: #00448b!important;padding: 10px!important;">
                        <div class="page-content-wrapper">
                            <div class="page-content">
                                <div class="demo-content">
                                    <table class="table table-customer-manage table-scroll">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span v-on:click="setPasskey('order_type')" class="cursor-th" >
                                                        <span v-if="passed_key.key=='order_type' && passed_key.value== -1" :class="{'hide-arrow': passed_key.key != 'order_type'}" class="sorting-icon">
                                                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else :class="{'hide-arrow': passed_key.key != 'order_type'}" class="sorting-icon">
                                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                        </span>Order Type
                                                    </span>
                                                </th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Availability</th>
                                                <th class="text-center">Description</th>
                                                <th class="text-center">Admin</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                                <td colspan="5" class="text-center justify-content-center">
                                                    <div class="digi-table-loader">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="allOrderTypes.length == 0 && !ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                                <td v-if="search_key" colspan="5" class="text-center justify-content-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                                                        <br />
                                                        <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Order Type Found</p>
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(order,index) in allOrderTypes" :key="index">
                                                <td class="text-secondary pointer" style="cursor:pointer !important;max-width: 310px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"  :title="order.order_type" @click="viewOrderType(order._id)" >{{order.order_type}}</td>
                                                <td>
                                                    <el-switch v-model="order.status" @change="updateStatus(order._id,order.status)"></el-switch>
                                                </td>
                                                <td class="d-flex">
                                                    <div v-for="(w,i) in order.timings" :key="i" class="d-flex" style="white-space: nowrap;">
                                                        <span v-if="i != 0">,</span> {{w}}
                                                    </div>
                                                </td>
                                                <td style="cursor:pointer !important;max-width: 310px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"  :title="order.description">{{order.description || '-'}}</td>
                                                <td>
                                                    <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                        <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;"  @click="editType(order._id)">
                                                            <i class="icon icon-pencil"></i>
                                                        </button>
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                        <button @click="deleteOrder(order)" type="button" class="btn btn-icon actionicons ml-3" style="padding: 0.4rem .2rem;">
                                                            <i class="icon icon-delete"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isPricingPage">
                <div v-if="showIllustratorProduct" class="row no-orders-row">
                    <div class="col-lg-12 text-center">
                        <div class="noorders">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                            <p class="text-center text-secondary fs-28 font-600 pt-5">No Order Type Yet!!!</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="category-manage-info" style="top:70px !important">PRICING</div>
                    <div class="page-layout carded left-sidebar mt-5" style="background-color: #00448b!important;padding: 10px!important;">
                        <div class="page-content-wrapper">
                            <div class="page-content">
                                <div class="demo-content">
                                    <table class="table table-customer-manage table-scroll">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span v-on:click="setPasskeyNew('name')" class="cursor-th">
                                                        <span v-if="passed_key.key=='name' && passed_key.value== -1" :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                        </span>
                                                        Product
                                                    </span>
                                                </th>
                                                <th class="text-center">No OF ORDER TYPES</th>
                                                <th class="text-center">price</th>
                                                <th class="text-center">Admin</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="ajaxCallInProgressProduct">
                                            <tr style="border-bottom:none !important;">
                                                <td colspan="4" class="text-center justify-content-center">
                                                    <div class="digi-table-loader">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else-if="!ajaxCallInProgressProduct && allProducts.length == 0">
                                            <tr style="border-bottom:none !important;">
                                                <td v-if="searchdata" colspan="4" class="text-center justify-content-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                                                        <br />
                                                        <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Product Found</p>
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <template v-for="product in allProducts">
                                                <tr :key="product._id">
                                                    <td>
                                                        <span @click.stop="product.show = !product.show">
                                                            <i v-if="product.variant && product.show" class="icon-arrow-down-drop icon-down"></i>
                                                            <i v-if="product.variant && !product.show" class="icon-menu-right text-secondary icon-right"></i>
                                                        </span>
                                                        <span :class="{'ml-6':!product.variant}" class="text-secondary pointer" @click="viewProduct(product._id)" style="max-width: 180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" :title="product.name">{{product.name}}</span>
                                                    </td>
                                                    <td>{{product.order_type_count || '-'}}</td>
                                                    <td>{{product.price || '-'}}</td>
                                                    <td>
                                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                            <button @click="editProduct(product._id)" class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;">
                                                                <i class="icon icon-pencil"></i>
                                                            </button>
                                                        </el-tooltip>
                                                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                            <button type="button" @click="deleteproduct(product)" class="btn btn-icon actionicons ml-3" style="padding: 0.4rem .2rem;">
                                                                <i class="icon icon-delete"></i>
                                                            </button>
                                                        </el-tooltip>
                                                    </td>
                                                </tr>
                                                <template v-for="variant in product.variants" >
                                                    <tr :key="variant.id" v-show="product.show" class="variantBackgroundTable">
                                                        <td>
                                                            <span class="pl-6"><i></i></span>
                                                            <span style="max-width: 180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" :title="variant.variant_name">
                                                                {{variant.variant_name}}
                                                            </span>
                                                        </td>
                                                        <td>{{variant.order_type_count}}</td>
                                                        <td>{{variant.variant_price}}</td>
                                                        <td></td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-order-type modal_name="order-type" @saved="getAllOrderTypeAPI()"></add-order-type>
        <view-order-type modal_name="view-order-type"></view-order-type>
        <edit-order-type modal_name="order-type-edit" @saved="getAllOrderTypeAPI()"></edit-order-type>
    </div>
</template>
<script>
const AddOrderType = () => import('./AddOrderType');
const ViewOrderType = () => import('./ViewOrderType');
import YieldManager from './mixins/yieldmanager'
import { EventBus } from './eventBus/nav-bar-event';
import products from './mixins/products';
const EditOrderType = () => import('./EditOrderType')
export default {
    components:{
        AddOrderType,
        ViewOrderType,
        EditOrderType
    },
    mixins: [YieldManager],
    data(){
        return{
            search:'',
            searchyield: 100,
            isOrderTypeActive:true,
            isPricingPage:false,
            allOrderTypes:[],
            skip:0,
            limit:10,
            search_key:'',
            passed_key:{
                key: 'order_type',
                value: 1
            },
            total:0,
            start:1,
            end:10,
            ajaxCallInProgress:false,
            count:0,
            showIllustrator:false,
            show:false,
            searchdata:'',
            ajaxCallInProgressProduct:false,
            product_count:0,
            allProducts:[],
            startProduct:1,
            endProduct:10,
            showIllustratorProduct:false
        }
    },
    methods:{
        showOrderType(){
            this.$modal.show('order-type')
        },
        viewOrderType(id){
            let params = {
              _id:id
            }
            EventBus.$emit('showOrderType',params)
            this.$modal.show('view-order-type')
        },
        editType(id){
            let params = {
              _id:id
            }
            EventBus.$emit('editOrderType',params)
            this.$modal.show('order-type-edit')
        },
        OrderTypePage(){
            this.isOrderTypeActive = true
            this.isPricingPage = false
            this.count = 0
            this.getAllOrderTypeAPI()
        },
        PricingPage(){
            this.isPricingPage = true
            this.isOrderTypeActive = false
            this.count = 0
            this.getAllProductsForYieldAPI()
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllOrderTypeAPI()
        },
        async getAllOrderTypeAPI(param){
            try {
                if (param == 'prev') this.count--
                else if (param == 'next') this.count++
                else if (param == 'init') {
                    this.count = 0
                } else if (param == 'search_key') {
                    this.count = 0
                }
                this.ajaxCallInProgress = true
                this.allOrderTypes = []
                let params = {
                    search_key:this.search_key,
                    skip:this.count * this.limit,
                    limit:this.limit,
                    sort_key:this.passed_key.key,
                    sort_value:this.passed_key.value
                }
                let res = await this.getAllOrderType(params)
                this.ajaxCallInProgress = false
                this.allOrderTypes = res.response.order_types
                this.total = res.response.total_count
                this.start = this.count * this.limit + 1
                this.end =this.count * this.limit + this.limit <
                            this.total ?
                            this.count * this.limit + this.limit :
                            this.total
                if (this.allOrderTypes.length == 0 && !this.ajaxCallInProgress && !this.search) {
                    this.showIllustrator = true
                } else {
                    this.showIllustrator = false
                }
                

            } catch (reason) {
                
            }
        },
        deleteOrder(order){
            this.$swal({
                title: 'Are you sure?',
                text:
                    'Are you sure To delete the Order Type ' +
                    order.order_type +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.confirmDelete(order)
                }
            })
        },
        async confirmDelete(order){
            try {
                let params ={
                    _id:order._id
                } 
                let res = await this.deleteOrderType(params)
                this.getAllOrderTypeAPI()
                this.$swal({
                    title: res.message,
                    text: '',
                    type: 'success'
                })
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
            }
        },
        async updateStatus(id,status){
            try {
                let params = {
                    _id:id,
                    status:status
                }
                let res = await this.UpdateOrderTypeStatus(params)
                this.$toasted.global.success(res.message)
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        addNewPricing(){
            this.$router.push({ name: 'AddPricing' })
        },
        setPasskeyNew: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllProductsForYieldAPI()
        },
        async getAllProductsForYieldAPI(param){
            try {
                if (param == 'prev') this.count--
                else if (param == 'next') this.count++
                else if (param == 'init') {
                    this.count = 0
                } else if (param == 'searchdata') {
                    this.count = 0
                }
                this.ajaxCallInProgressProduct = true
                this.allOrderTypes = []
                let params = {
                    searchdata:this.searchdata,
                    skip:this.count * this.limit,
                    limit:this.limit,
                    sort_key:this.passed_key.key,
                    sort_value:this.passed_key.value
                }
                let res = await this.getAllProductsForYield(params)
                this.ajaxCallInProgressProduct = false
                this.allProducts = res.response.result
                this.product_count = res.response.product_count
                this.startProduct = this.count * this.limit + 1
                this.endProduct =this.count * this.limit + this.limit <
                            this.product_count ?
                            this.count * this.limit + this.limit :
                            this.product_count
                if (this.allProducts.length == 0 && !this.ajaxCallInProgressProduct && !this.searchdata) {
                    this.showIllustratorProduct = true
                } else {
                    this.showIllustratorProduct = false
                }
            } catch (reason) {
                
            }
        },
        deleteproduct(product) {
			this.$swal({
				title: 'Are you sure?',
				text: "Are you sure To delete the Product " + product.name + " ?",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#00448b',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes'
			}).then((result) => {
				if (result.value) {
					this.confirmDeleteProduct(product)
				}
			})
        },
        async confirmDeleteProduct(product){
            try {
                let params ={
                    _id:product._id
                }
                let res = await this.deleteProductByID(params)
                this.$swal(({
                    text: product.name + " Product has been deleted.",
                    type:'success',
                    showConfirmButton:false,
                    timer:1000
                }))
                this.getAllProductsForYieldAPI()
            } catch (reason) {
                
            }
        },
        editProduct(id){
            this.$router.push({
                name: 'EditPricing',
                query: { id: id },
            })
        },
        viewProduct(id){
            this.$router.push({
                name: 'ViewPricing',
                query: { id: id },
            })
        }
    },
    mounted(){
        this.count = 0
        if(this.$route.params.yieldType == 'pricing'){
            this.isOrderTypeActive = false
            this.isPricingPage = true
            this.getAllProductsForYieldAPI()
        }else{
            this.getAllOrderTypeAPI()
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 1112px) and (min-width: 992px) {
    .orderTypeTab {
        padding: 5px 10px 5px 10px !important;
    }
    .pricingTab {
        padding: 5px 20px 5px 20px !important;
    }
}
.orderTypeTab {
    border: 1px solid #00448b;
    height: 34px !important;
    color: #00448b !important;
    background: #fff !important;
    line-height: 32px !important;
    text-transform: none !important;
    padding: 5px 15px 5px 15px;
    font-size: 14px !important;
    cursor: pointer;
    font-weight: 500;
    border-radius: 20px 0 0 20px !important;
    font-weight: 600;
}
.pricingTab {
    border: 1px solid #00448b;
    height: 34px !important;
    color: #00448b !important;
    background: #fff !important;
    line-height: 32px !important;
    text-transform: none !important;
    padding: 5px 34px 5px 34px;
    font-size: 14px !important;
    font-weight: 500;
    border-left: none !important;
    cursor: pointer;
    margin-left: -5px !important;
    border-radius: 0 20px 20px 0 !important;
    font-weight: 600;
}
.activeOrder {
    background: #00448b !important;
    color: #fff !important;
    font-weight: bold;
}
.icon-arrow-down-drop.icon-down,
.icon-menu-right.icon-right {
    color: #00448b !important;
    cursor: pointer;
}
.variantBackgroundTable{
    background: #c8e0f8 !important;
}
</style>