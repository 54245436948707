<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="280"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 27em !important;">
                
                <div  class="mt-4 v-modal-body" style="font-size: 16px">
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-if="payment_status == 'in_progress'">
                        <div class="text-center fs-16 fw-800 text-blue">Payment In Progress!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_progress.svg" style="width:30%; margin-top:20px"/>
                        <div class="text-center fs-16  text-black mt-3">Please do not close or leave this page</div>
                    </div>
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-else-if="payment_status == 'success'">
                        <div class="text-center fs-16 fw-800 text-green">Thank you!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_success.svg" style="width:30%; margin-top:20px" />
                        <div class="text-center fs-16 text-black mt-3">Successful payment.  <br>Have a great day!</div>
                    </div>
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-else-if="payment_status == 'failure'">
                        <div class="text-center fs-16 fw-800 text-red">Payment Declined!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_declined.svg" style="width:30%; margin-top:20px" />
                        <div class="text-center fs-16 text-black mt-3">Please try again!</div>
                    </div>
                </div>
                <div class="payment-footer" v-if="payment_status == 'in_progress'">
                    <div class="text-center fs-16 fw-800 text-white">Please wait...</div>
                </div>
                <div class="payment-footer" v-else-if="payment_status == 'success'">
                    <div class="text-center fs-16 fw-800 text-white">Payment Done.</div>
                </div>
                <div class="payment-footer" v-else-if="payment_status == 'failure'" style="background-image: linear-gradient(to right, #828282, #131f2b 101%)!important;">
                    <div class="text-center fs-16 fw-800 text-white">Payment failed.</div>
                </div>
            </div>  
        </modal>
    </div>
</template>

<script>
import { EventBus } from './eventBus/nav-bar-event.js';
import Swal from 'sweetalert2';

export default {
    props: ["modal_name", "order_id", "order_type"],
    mixins: [],
    data() {
        return {
            payment_status: 'in_progress',
        }
    },
    methods: {
        redirectToPage(data) {
            setTimeout(() => {
                if(data == 'success') {
                    this.$emit("hidePayModal");
                    this.$router.replace({
                        name: 'MyOrder',
                        params: {
                            id: this.order_id,
                            orderType: this.order_type
                        }
                    })
                } else if(data == 'failure') {
                    this.$emit("hidePayModal");
                    this.$router.go(-1);
                }
            }, 5000);
        }
    }, 
    mounted() {
        EventBus.$on("payment_status_success_failure", (value) => {
            console.log(value)
            this.payment_status = value
            this.redirectToPage(this.payment_status);
            this.$forceUpdate();
        });
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    watch: {
    },
    beforeDestroy() {
        // EventBus.$off('payment_status');
    }
}
</script>
<style>
.payment-footer{
    background-image: linear-gradient(to right, #037ae0, #00448b 101%)!important;
    color: #fff;
    width: 100%;
    text-align: -webkit-center;
    display:flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: center !important;
    padding: 15px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.new-modal-small.v--modal-overlay .v--modal-box.v--modal {
    height: 350px !important;
}
</style>