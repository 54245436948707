<template>
  <div style="width: 99%;">
    <div class="page-layout blank text-center" v-if="LoadingPage" style="top:50px;">
      <div class="digi-table-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="page-layout blank" v-else>
      <div class="pb-10">
        <h2 class="headerblock">
          <div class="d-flex ml-2 mr-2 justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap">
              <router-link
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip
                  class="item pull-right"
                  effect="dark"
                  content="Click to Go Back"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item pull-right"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <div class>
                <date-picker
                  v-if="convertUTC == false"
                  v-model="dateRange"
                  @confirm="onDateChange()"
                  format="MM/DD/YYYY hh:mm A"
                  :first-day-of-week="7"
                  range
                  class="date-width"
                  :show-second="false"
                  :shortcuts="shortcuts"
                  :lang="lang"
                  :clearable="true"
                  confirm
                  :not-after="new Date()"
                  confirm-text="APPLY"
                  type="datetime"
                ></date-picker>
                <date-picker
                  v-else
                  class="date-width"
                  v-model="dateRange"
                  @confirm="onDateChange()"
                  format="MM/DD/YYYY hh:mm A"
                  :first-day-of-week="7"
                  range
                  :show-second="false"
                  :shortcuts="shortcuts"
                  :lang="lang"
                  :clearable="true"
                  confirm
                  :not-after="new Date()"
                  confirm-text="APPLY"
                  type="datetime"
                ></date-picker>
                <!-- <date-picker
                  v-else
                  v-model="dateRange"
                  @confirm="onDateChange()"
                  format="DD-MM-YYYY hh:mm A"
                  :first-day-of-week="7"
                  range
                  :show-second="false"
                  :shortcuts="shortcuts"
                  :lang="lang"
                  :clearable="true"
                  confirm
                  :not-after="new Date()"
                  confirm-text="APPLY"
                  type="datetime"
                  :value-type="valueType"
                ></date-picker>-->
              </div>
              <div class="ml-4" v-if="!loggedInUser.outlet">
                <div class style="padding-top: 4px;">
                  <v-select
                    label="name"
                    class="clearNone"
                    v-model="selected"
                    :clearSearchOnSelect="true"
                    :selected="selected"
                    :filterable="false"
                    :options="options"
                    @search="onSearch"
                    :clearable="false"
                  ></v-select>
                </div>
              </div>
              <div class="ml-4 resetMargin">
                <button type="submit" @click="reset()" class="btn btn-secondary btn-smm">RESET</button>
              </div>
            </div>
            <!-- <div>
              <span
                @click="downloadPdf"
                class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2"
              >
                <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg" />
                <span class="text-white font-weight-bold ml-1">Pdf</span>
              </span>
            </div>-->
          </div>
          <!-- <div class="d-flex">
            <div>
              <button class="btn btn-checkout" @click="$router.go(-1)"> Back
                <i class="icon-arrow-left"></i>
              </button>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-3 resetAlign">
              <div class style="padding-top: 4px;">
                <v-select label="name" v-model="selected" :clearSearchOnSelect="true" :selected="selected"
                  :filterable="false" :options="options" @search="onSearch">
                </v-select>
              </div>
            </div>
            <div style="right: 0;position: absolute;width: 270px;" class="pointer">
              <date-picker v-model="dateRange" @confirm="onDateChange()" format='DD-MM-YYYY'
              :first-day-of-week="7" range :shortcuts="shortcuts" :lang="lang"
              :clearable="true" confirm confirm-text="APPLY"></date-picker>
            </div>
          </div>-->
        </h2>
        <div class="canvas_div_pdf">
          <div class="row">
            <div class="col-lg-12">
              <div class="reports-label-top">SUMMARY BREAKDOWN</div>
              <!-- <h6 class="defaultBlue font-weight-bold d-block text-center"></h6> -->
              <div class="summaryBreakdown mt-4">
                <table class="table table-reports table-fixed">
                  <thead>
                    <tr>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'name' && sort.value == 1"
                          :class="{'hide-arrow': sort.key != 'name'}"
                          @click="updateSort('name')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateSort('name')"
                          :class="{'hide-arrow': sort.key != 'name'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        ENTITY
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'customers_count' && sort.value == 1"
                          @click="updateSort('customers_count')"
                          :class="{'hide-arrow': sort.key != 'customers_count'}"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          v-else
                          @click="updateSort('customers_count')"
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          :class="{'hide-arrow': sort.key != 'customers_count'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        CUSTOMERS COUNT
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'total_orders' && sort.value == 1"
                          @click="updateSort('total_orders')"
                          :class="{'hide-arrow': sort.key != 'total_orders'}"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          v-else
                          @click="updateSort('total_orders')"
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          :class="{'hide-arrow': sort.key != 'total_orders'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        TOTAL ORDERS
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'gross_income' && sort.value == 1"
                          @click="updateSort('gross_income')"
                          :class="{'hide-arrow': sort.key != 'gross_income'}"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateSort('gross_income')"
                          :class="{'hide-arrow': sort.key != 'gross_income'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        GROSS INCOME
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'avg_val_all_transactions' && sort.value == 1"
                          @click="updateSort('avg_val_all_transactions')"
                          :class="{'hide-arrow': sort.key != 'avg_val_all_transactions'}"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          v-else
                          @click="updateSort('avg_val_all_transactions')"
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          :class="{'hide-arrow': sort.key != 'avg_val_all_transactions'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        AVG VALUE OF ALL TRANSACTIONS
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="sort.key == 'avg_revenue_per_customer' && sort.value == 1"
                          @click="updateSort('avg_revenue_per_customer')"
                          :class="{'hide-arrow': sort.key != 'avg_revenue_per_customer'}"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          v-else
                          @click="updateSort('avg_revenue_per_customer')"
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          :class="{'hide-arrow': sort.key != 'avg_revenue_per_customer'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        AVERAGE REVENUE PER CUSTOMER
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="summary._id" v-for="summary in summary_breakdown">
                      <td style="padding: 0 27px!important;">{{summary.name}}</td>
                      <td style="padding: 0 32px!important;">{{summary.customers_count}}</td>
                      <td style="padding: 0 27px!important;">{{summary.total_orders}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.gross_income}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.avg_val_all_transactions}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.avg_revenue_per_customer}}</td>
                    </tr>
                  </tbody>
                  <tfoot class="tableTotal">
                    <tr
                      style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;"
                    >
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >TOTAL</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{summary_breakdown_total.customers_count}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{summary_breakdown_total.total_orders}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{summary_breakdown_total.gross_income}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{summary_breakdown_total.avg_val_all_transactions}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{summary_breakdown_total.avg_revenue_per_customer}}</td>
                    </tr>
                  </tfoot>
                </table>
                <!-- <h6 v-if="!reportData.top_selling_items.length" class="d-flex justify-content-center fs-16" style="position:relative;top:40%;">
                  NO DATA
                </h6>-->
              </div>
            </div>
          </div>
          <div class="row mt-9">
            <div class="col-lg-12">
              <div
                class="reports-label-top"
                style="width: 295px!important;"
              >TOTAL SALES SUMMARY BREAKDOWN ({{currency.symbol}})</div>

              <div class="summaryBreakdown mt-4">
                <table class="table table-reports table-fixed">
                  <thead>
                    <tr>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'name' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'name'}"
                          @click="updateTotalSalesSort('name')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('name')"
                          :class="{'hide-arrow': total_sales_sort.key != 'name'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        ENTITY
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'sub_total' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'sub_total'}"
                          @click="updateTotalSalesSort('sub_total')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('sub_total')"
                          :class="{'hide-arrow': total_sales_sort.key != 'sub_total'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        SUBTOTAL
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'discount_price' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'discount_price'}"
                          @click="updateTotalSalesSort('discount_price')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('discount_price')"
                          :class="{'hide-arrow': total_sales_sort.key != 'discount_price'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        DISCOUNT
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'other_charges' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'other_charges'}"
                          @click="updateTotalSalesSort('other_charges')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('other_charges')"
                          :class="{'hide-arrow': total_sales_sort.key != 'other_charges'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        OTHER CHARGES
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'taxes' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'taxes'}"
                          @click="updateTotalSalesSort('taxes')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('taxes')"
                          :class="{'hide-arrow': total_sales_sort.key != 'taxes'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        TAXES
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="total_sales_sort.key == 'total_sales' && total_sales_sort.value == 1"
                          :class="{'hide-arrow': total_sales_sort.key != 'total_sales'}"
                          @click="updateTotalSalesSort('total_sales')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateTotalSalesSort('total_sales')"
                          :class="{'hide-arrow': total_sales_sort.key != 'total_sales'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        TOTAL SALES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="summary._id" v-for="summary in total_sales_summary">
                      <td style="padding: 0 27px!important;">{{summary.name}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.sub_total}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.discount_price}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.other_charges}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.taxes}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.total_sales}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr
                      style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;"
                    >
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >TOTAL</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{total_sales_summary_total.sub_total}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{total_sales_summary_total.discount_price}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{total_sales_summary_total.other_charges}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{total_sales_summary_total.taxes}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{total_sales_summary_total.total_sales}}</td>
                    </tr>
                  </tfoot>
                </table>
                <!-- <h6 v-if="!reportData.top_selling_items.length" class="d-flex justify-content-center fs-16" style="position:relative;top:40%;">
                  NO DATA
                </h6>-->
              </div>
            </div>
          </div>
          <div class="row mt-10 mx-1">
            <div class="col-md-6 pl-0 screenPadding">
              <div
                class="reports-label-top"
                style="width: 280px!important;"
              >CLOSED TRANSACTIONS SUMMARY ({{currency.symbol}})</div>
              <div class="summaryBreakdown mt-4">
                <table class="table table-reports table-fixed">
                  <thead>
                    <tr>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="closed_bills_sort.key == 'name' && closed_bills_sort.value == 1"
                          :class="{'hide-arrow': closed_bills_sort.key != 'name'}"
                          @click="updateClosedSort('name')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateClosedSort('name')"
                          :class="{'hide-arrow': closed_bills_sort.key != 'name'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        ENTITY
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="closed_bills_sort.key == 'all_transactions' && closed_bills_sort.value == 1"
                          :class="{'hide-arrow': closed_bills_sort.key != 'all_transactions'}"
                          @click="updateClosedSort('all_transactions')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateClosedSort('all_transactions')"
                          :class="{'hide-arrow': closed_bills_sort.key != 'all_transactions'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        ALL TRANSACTIONS
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="closed_bills_sort.key == 'dine_in' && closed_bills_sort.value == 1"
                          :class="{'hide-arrow': closed_bills_sort.key != 'dine_in'}"
                          @click="updateClosedSort('dine_in')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateClosedSort('dine_in')"
                          :class="{'hide-arrow': closed_bills_sort.key != 'dine_in'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        DINE-IN
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="closed_bills_sort.key == 'take_away' && closed_bills_sort.value == 1"
                          :class="{'hide-arrow': closed_bills_sort.key != 'take_away'}"
                          @click="updateClosedSort('take_away')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateClosedSort('take_away')"
                          :class="{'hide-arrow': closed_bills_sort.key != 'take_away'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        TAKE-AWAY
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="summary._id" v-for="summary in closed_bills_summary">
                      <td style="padding: 0 27px!important;">{{summary.name}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.all_transactions}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.dine_in}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.take_away}}</td>
                    </tr>
                    <!-- <tr>
                      <td colspan="4" style="font-size:14px;color: #303031;font-weight:600;">
                        <strong>NO CLOSED TRANSACTIONS SUMMARY</strong>
                      </td>
                    </tr>-->
                  </tbody>
                  <tfoot>
                    <tr
                      style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;"
                    >
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >TOTAL</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{closed_bills_summary_total.all_transactions}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{closed_bills_summary_total.dine_in}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{closed_bills_summary_total.take_away}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div class="col-md-6 pr-0 screenMargin">
              <div
                class="reports-label-top"
                style="width: 320px!important;"
              >CANCELLED BILLS TRANSACTIONS SUMMARY ({{currency.symbol}})</div>
              <div class="summaryBreakdown mt-4">
                <table class="table table-reports table-fixed">
                  <thead>
                    <tr>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="cancelled_bills_sort.key == 'name' && cancelled_bills_sort.value == 1"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'name'}"
                          @click="updateCancelledSort('name')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateCancelledSort('name')"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'name'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        ENTITY
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="cancelled_bills_sort.key == 'cancelled_bills' && cancelled_bills_sort.value == 1"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'cancelled_bills'}"
                          @click="updateCancelledSort('cancelled_bills')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateCancelledSort('cancelled_bills')"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'cancelled_bills'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        CANCELLED BILLS
                      </th>
                      <th>
                        <span
                          class="sorting-icon"
                          v-if="cancelled_bills_sort.key == 'void_bills' && cancelled_bills_sort.value == 1"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'void_bills'}"
                          @click="updateCancelledSort('void_bills')"
                        >
                          <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                        </span>
                        <span
                          style="font-size: 16px !important;"
                          class="sorting-icon"
                          v-else
                          @click="updateCancelledSort('void_bills')"
                          :class="{'hide-arrow': cancelled_bills_sort.key != 'void_bills'}"
                        >
                          <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                        </span>
                        VOID BILLS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="summary._id" v-for="summary in cancelled_bills_summary">
                      <td style="padding: 0 27px!important;">{{summary.name}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.cancelled_bills}}</td>
                      <td
                        style="padding: 0 27px!important;"
                      >{{summary.void_bills}}</td>
                    </tr>
                    <!-- <tr>
                      <td colspan="3" style="font-size:14px;color: #303031;font-weight:600;">
                        <strong>NO CANCELLED BILLS TRANSACTIONS SUMMARY</strong>
                      </td>
                    </tr>-->
                  </tbody>
                  <tfoot>
                    <tr
                      style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;"
                    >
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >TOTAL</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{cancelled_bills_summary_total.cancelled_bills}}</td>
                      <td
                        style="padding: 0 23px!important;"
                        class="font-weight-bold text-black"
                      >{{cancelled_bills_summary_total.void_bills}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import { EventBus } from '../eventBus/nav-bar-event.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
export default {
  data() {
    const value2date = dateRange => {
      const date = new Date(dateRange)
      return dateRange
        ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    const date2value = date => {
      return date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    return {
      valueType: {
        value2date,
        date2value
      },
      mobile: false,
      convertUTC: false,
      LoadingPage: true,
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear() + 1, 0, 1),
      dateRange: '',
      lang: {
        type: 'en',
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days'
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      confirmText: {
        type: String,
        default: 'APPLY'
      },
      shortcuts: [
        {
          text: 'Today',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Week',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - new Date().getDay()
            )
            this.endDate = new Date()

            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Month',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            )
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Year',
          onClick: () => {
            this.startDate = new Date(new Date().getFullYear(), 0, 1)
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        }
      ],
      summary_breakdown: [],
      summary_breakdown_total: [],
      sort: {
        key: 'name',
        value: 1
      },
      cancelled_bills_sort: {
        key: 'name',
        value: 1
      },
      closed_bills_sort: {
        key: 'name',
        value: 1
      },
      total_sales_sort: {
        key: 'name',
        value: 1
      },
      closed_bills_summary: [],
      closed_bills_summary_total: [],
      cancelled_bills_summary: [],
      cancelled_bills_summary_total: [],
      total_sales_summary: [],
      total_sales_summary_total: [],
      currency: {},
      selected: {
        _id: null,
        name: 'All Outlets'
      },
      options: []
    }
  },
  methods: {
    downloadPdf() {
      var HTML_Width = $('.canvas_div_pdf').width()
      var HTML_Height = $('.canvas_div_pdf').height()
      var top_left_margin = 15
      var PDF_Width = HTML_Width + top_left_margin * 2
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2
      var canvas_image_width = HTML_Width
      var canvas_image_height = HTML_Height

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1

      html2canvas($('.canvas_div_pdf')[0], { allowTaint: true }).then(function(
        canvas
      ) {
        canvas.getContext('2d')

        console.log(canvas.height + '  ' + canvas.width)

        var imgData = canvas.toDataURL('image/jpeg', 1.0)
        var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        )

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height)
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          )
        }
        pdf.save('Summary Breakdown.pdf')
      })
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search(loading, search, vm) {
      this.$http
        .post('/outlet/get', {
          search_key: search
        })
        .then(res => {
          vm.options = res.data.outlets
          loading(false)
        })
    },
    reset() {
      let startDate = new Date()
      startDate.setHours(
        this.loggedInUser.business_day_start_time.split(':')[0],
        this.loggedInUser.business_day_start_time.split(':')[1]
      )
      let endDate = new Date()
      this.dateRange = [startDate, endDate]
      this.selected = { _id: null, name: 'All Outlets' }
      this.onDateChange()
      this.convertUTC = false
    },
    onDateChange() {
      this.convertUTC = true
      // this.endDate(23, 59, 59);
      this.endDate
      // this.dateRange[1].setHours(23, 59, 59);
      this.dateRange[1]
      if (!this.dateRange[0] && !this.dateRange[1]) {
        this.startDate = new Date()
        // this.startDate.setHours(0, 0, 0)
        this.startDate
        this.endDate = new Date()
        this.dateRange = [this.startDate, this.endDate]
      }
      this.getSummaryBreakDown()
      this.getClosedBillsSummary()
      this.getCancelledBillsSummary()
      this.getTotalSalesSummary()
    },
    updateSort(name) {
      this.sort.key = name
      this.sort.value = this.sort.value == 1 ? -1 : 1
      this.getSummaryBreakDown()
    },
    updateCancelledSort(name) {
      this.cancelled_bills_sort.key = name
      this.cancelled_bills_sort.value =
        this.cancelled_bills_sort.value == 1 ? -1 : 1
      this.getCancelledBillsSummary()
    },
    updateClosedSort(name) {
      this.closed_bills_sort.key = name
      this.closed_bills_sort.value = this.closed_bills_sort.value == 1 ? -1 : 1
      this.getClosedBillsSummary()
    },
    updateTotalSalesSort(name) {
      this.total_sales_sort.key = name
      this.total_sales_sort.value = this.total_sales_sort.value == 1 ? -1 : 1
      this.getTotalSalesSummary()
    },
    getSummaryBreakDown() {
      var details = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        sort: this.sort,
        outlet_id: this.selected._id
      }
      this.$http.post('/reports/summary_breakdown', details).then(response => {
        this.summary_breakdown = response.data.summary_report
        this.summary_breakdown_total = response.data.total_summary
        this.currency = response.data.currency
      })
    },
    getClosedBillsSummary() {
      var details = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        sort: this.closed_bills_sort,
        outlet_id: this.selected._id
      }
      this.$http
        .post('/reports/summary_breakdown/closed_bills', details)
        .then(response => {
          this.closed_bills_summary = response.data.summary_report
          this.closed_bills_summary_total = response.data.total_summary
        })
    },
    getCancelledBillsSummary() {
      var details = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        sort: this.cancelled_bills_sort,
        outlet_id: this.selected._id
      }
      this.$http
        .post('/reports/summary_breakdown/cancelled_bills', details)
        .then(response => {
          this.cancelled_bills_summary = response.data.summary_report
          this.cancelled_bills_summary_total = response.data.total_summary
        })
    },
    getTotalSalesSummary() {
      var details = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        sort: this.total_sales_sort,
        outlet_id: this.selected._id
      }
      this.$http
        .post('/reports/summary_breakdown/total_sales', details)
        .then(response => {
          this.total_sales_summary = response.data.summary_report
          this.total_sales_summary_total = response.data.total_summary
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  components: {
    DatePicker
  },
  mounted() {
    setTimeout(() => {
      let startDate = new Date()
      startDate.setHours(
        this.loggedInUser.business_day_start_time.split(':')[0],
        this.loggedInUser.business_day_start_time.split(':')[1]
      )
      console.log(startDate)
      let endDate = new Date()
      this.dateRange = [startDate, endDate]
      this.getSummaryBreakDown()
      this.getClosedBillsSummary()
      this.getCancelledBillsSummary()
      this.getTotalSalesSummary()
      setTimeout(() => {
        this.LoadingPage = false
      }, 2000)
      EventBus.$emit('customerdetailsLoad', false)
      EventBus.$emit('settingsLoad', false)
      EventBus.$emit('reportsLoad', true)
    }, 2000)
  },
  created() {
    // let startDate = new Date()
    // startDate.setHours(0, 0, 0)
    // let endDate = new Date()
    // this.dateRange = [startDate, endDate];
    if (this.$route.query.mobile == 'true') {
      this.mobile = true
      EventBus.$emit('mobile', true)
    }
    this.$http.post('/outlet/get', {}).then(res => {
      this.selected = {
        _id: null,
        name: 'All Outlets'
      }
      this.options = [
        {
          _id: null,
          name: 'All Outlets'
        }
      ].concat(res.data.outlets)
    })
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  },
  watch: {
    selected: function(newVal, oldVal) {
      if (newVal._id != oldVal._id) {
        this.onDateChange()
      }
    }
  }
}
</script>
<style scoped>
.summaryBreakdown {
  border: solid 1px #94bef0;
  border-radius: 10px;
}
.table.table-reports {
  border-radius: 7.5px;
  background-color: #dee8f2;
}
.table.table-reports tbody tr {
  border-bottom: 1px solid #a8adb4 !important;
}
.reports-label-top {
  font-size: 12px;
  color: rgb(48, 48, 49);
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  /* border: 2px solid rgb(13, 132, 242); */
  border: 2px solid #00448b;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  position: absolute;
  z-index: 9;
  text-transform: uppercase;
}
.btn-checkout {
  border-radius: 23px;
  background-image: linear-gradient(to bottom, #ffb333, #e29005);
  color: #303031;
  padding: 0px 10px;
  margin: 0px;
  font-size: 14px !important;
  font-weight: 600;
  height: 3.4rem;
  line-height: 2.6rem;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-checkout i {
  color: #303031;
  font-size: 16px !important;
  font-weight: 600;
  padding-left: 16px;
}
.borderTableRadius {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.table-fixed tbody {
  display: block;
  width: 100%;
  height: 380px;
  max-height: 390px;
  overflow-y: auto;
}
.table-fixed thead,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: flex;
  flex: 1 1 0%;
}
.table-fixed tbody tr,
.table-fixed tbody td {
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  font-weight: 600;
}
.table-fixed thead th {
  align-items: center;
}
.table-fixed tfoot {
  line-height: 52px;
}
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.summaryBreakdown {
  overflow-x: auto;
  background-color: #00448b;
  padding: 10px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #94bef0;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 9px solid #00448b;
}
.summaryBreakdown > .summaryBreakdown > .summaryBreakdown {
  min-height: 39px;
  max-height: 39px;
}
@media screen and (max-width: 767px) {
  .screenMargin {
    margin-top: 12px;
    margin-right: 14px;
    padding-left: 14px;
  }
  .screenPadding {
    padding-left: 10px !important;
  }
}
</style>
