<template>
  <div class="page-layout simple right-sidebar inner-sidebar">
      <div style="height:100%;">
            <div class="content-section">
                <div class="fuse-sidebar sidebar left-positioned open locked-open preview">
                    <div class="preview-elements details-block">
                        <div class="content">
                            <div class="pl-2 mt-2 pb-3 row no-gutters align-items-center justify-content-between" style="border-bottom:solid 1px #00448b;">
                                <div class="col">
                                    <div class="row no-gutters align-items-center">
                                        <span class="fs-14 fw-normal defaultBlue">Order Type:</span>
                                    </div>
                                </div>
                                <div class="fs-14 font-weight-bold pr-5">
                                  <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                          <input class="form-check-input" type="radio" v-model="orderType" name="inlineRadioOptions" checked="isTableView" id="inlineRadio1" :value=1>
                                          <span class="radio-icon"></span>
                                          <span class="form-check-description">Take-Away</span>
                                      </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                          <input class="form-check-input" type="radio" v-model="orderType" name="inlineRadioOptions" id="inlineRadio1" :value=2>
                                          <span class="radio-icon"></span>
                                          <span class="form-check-description">Dine-in</span>
                                      </label>
                                  </div>
                                </div>
                            </div>
                            <div class="description pl-2">
                                <div class="description-text d-inline-block mt-2 pointer" style="width: 60%;">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" style="height: 12px;margin-bottom:3px;" class="pr-2">
                                    <span v-if="selectedCustomer.first_name" class="defaultBlue font-weight-bold">{{selectedCustomer.first_name}}</span>
                                    <span v-else class="defaultBlue font-weight-bold" @click="searchCustomer=true">Add Customer</span>
                                    <el-tooltip class="item" effect="dark" content="Delete" placement="top" v-if="selectedCustomer">
                                        <button type="button" class="btn btn-icon actionicons d-inline-block pull-right mr-0 mb-1" style="padding: 0rem 0rem;" @click="selectedCustomer=''">
                                            <i class="icon icon-delete"></i>
                                        </button>
                                    </el-tooltip>
                                </div>
                                <div class="btn-group toggle-source-preview d-inline-block" role="group" style="width: 40%;border-left: solid 1px #94bef0;" v-if="showSelectTable">
                                    <button id="btnGroupVerticalDrop1" type="button" class="btn btn-select-table dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="tableselectionclick()">
                                        <span v-if="selectedTable && selectedTable.length != 0">
                                            <span v-if="selectedTable.length > 1 ">
                                                {{selectedTable.length}} Tables
                                            </span>
                                            <span v-else>
                                                {{selectedTable.length}} Table
                                            </span>
                                        </span>
                                        <span v-else>
                                            Select Table
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <hr class="border-dotted" style="margin-top: 0rem;">
                            <div class="selectCustomer" v-show="searchCustomer">
                               <div @click="searchCustomer=false" class="d-flex pull-right pointer">
                                 <i class="icon icon-close lightBlue"></i>
                               </div>
                                <div class="p-4">
                                    <v-select v-model="selectedCustomer" :close-on-select="true" class="user-select" @search="getSearchCustomer" @search:blur="getSearchCustomer" placeholder="Search By Name, Number, Email" label="first_name" :options="customers">
                                        <span slot="no-options">
                                            No Matches Found. Please Create New Account
                                            <span class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="showCreateCustomer()">here</span>
                                        </span>
                                    </v-select>
                                </div>
                             </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table tablecart">
                                        <thead>
                                            <tr class="border-dotted">
                                              <th class="thcart">Items</th>
                                              <th class="thcart">Qty</th>
                                              <th class="thcart">Tax</th>
                                              <th class="thcart">Price</th>
                                              <th class="thcart"></th>
                                            </tr>
                                        </thead>
                                        <template  v-for="(product, index) in orderProducts">
                                            <tbody :key="product._id" v-if="product.variants">
                                                <tr class="border-dotted" v-for="(variant, variantIndex) in product.variants" :key="variantIndex">
                                                    <td class="tdcart deepSkyBlue pointer fs-14">{{product.name}}/{{variant.name}}</td>
                                                    <td class="tdcart fs-14">{{variant.quantity}}</td>
                                                    <td class="tdcart fs-14">$1</td>
                                                    <td class="tdcart fs-14">${{variant.price * variant.quantity}}</td>
                                                    <td class="tdcart">
                                                      <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                        <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;" @click="removeOrderVariant(index, variantIndex)">
                                                            <i class="icon icon-delete"></i>
                                                        </button>
                                                      </el-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody :key="product._id" v-else>
                                                <tr class="border-dotted">
                                                    <td class="tdcart deepSkyBlue pointer fs-14">{{product.name}}</td>
                                                    <td class="tdcart fs-14">{{product.quantity}}</td>
                                                    <td class="tdcart fs-14">$1</td>
                                                    <td class="tdcart fs-14">${{product.price*product.quantity}}</td>
                                                    <td class="tdcart">
                                                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                        <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;" @click="removeOrderProduct(index)">
                                                            <i class="icon icon-delete"></i>
                                                        </button>
                                                        </el-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                        <tfoot>
                                            <tr class="pr-4">
                                                <th class="tfcart">ADD</th>
                                                <th class="tfcart lightBlue pointer">
                                                    <span class="note-add" :class="{deepSkyBlue: addCharges}" @click="additionalCharges();">Additional Charges</span>
                                                </th>
                                                <th class="tfcart">
                                                    <span class="note-add" :class="{deepSkyBlue: addNotediv}" @click="addNote();">Note</span>
                                                </th>
                                                <th class="tfcart">
                                                    <span class="note-add" :class="{deepSkyBlue: addDiscountdiv}" @click="addDiscount();">Discount</span>
                                                </th>
                                                <th class="tfcart"></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="shownote pt-4 p-3" v-show="addNotediv">
                                <textarea class="form-control note-textarea" rows="2" style="height: 80px!important;"></textarea>
                                <div class="text-center pt-4 pb-2">
                                    <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideNotediv();order.note=''">CANCEL</a>
                                    <button type="submit" class="btn btn-secondary btn-smm" @click="hideNotediv()">SAVE</button>
                                </div>
                            </div>
                            <div class="showdiscount pt-4 pr-2" v-show="addDiscountdiv">
                                <div class="row">
                                    <div class="col-md-4">
                                        <span class="pull-right pointer">
                                            <a class="DollarTab" @click="discountIsPercentage=false" :class="{ activeClassDiscount : !discountIsPercentage }">$</a>
                                            <a class="PerTab" @click="discountIsPercentage=true" :class="{activeClassDiscount: discountIsPercentage }">%</a>
                                        </span>
                                    </div>
                                    <div class="col-md-8">
                                        <input class="form-control discount-input" v-model.number="orderDiscount" style="padding-right: 47px !important;">
                                        <span class="form-control-feedback currencySelect" v-if="!discountIsPercentage">$</span>
                                        <span class="form-control-feedback currencySelect" v-if="discountIsPercentage">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="showdiscount pt-4 pr-2" v-show="addCharges">
                                <div class="row">
                                    <div class="col-md-4">
                                        <span class="pull-right pointer">
                                            <a class="DollarTab" @click="chargesIsPercentage=false" :class="{ activeClassDiscount : !chargesIsPercentage }">$</a>
                                            <a class="PerTab" @click="chargesIsPercentage=true" :class="{activeClassDiscount: chargesIsPercentage }">%</a>
                                        </span>
                                    </div>
                                    <div class="col-md-8">
                                        <input class="form-control discount-input" v-model.number="orderCharges" style="padding-right: 47px !important;">
                                        <span class="form-control-feedback currencySelect" v-if="!chargesIsPercentage">$</span>
                                        <span class="form-control-feedback currencySelect" v-if="chargesIsPercentage">%</span>
                                    </div>
                                </div>
                            </div>
                            <table class="table tablesubtotal">
                                <tfoot class="tfoot-total-order">
                                    <!-- <tr>
                                        <th class="tfcart text-secondary tablesubtotaltd">Additional Charges</th>
                                        <th class="tfcart tablesubtotaltd">${{orderCharges}}</th>
                                    </tr>
                                      <tr>
                                        <th class="tfcart text-secondary tablesubtotaltd">Discount</th>
                                        <th class="tfcart tablesubtotaltd">${{orderDiscount}}</th>
                                    </tr> -->
                                    <tr>
                                        <th class="tfcart text-secondary tablesubtotaltd">Total</th>
                                        <th class="tfcart tablesubtotaltd">${{orderTotal}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="pt-3">
                                <!-- <a class="text-secondary">
                                    <span>Save For Later</span>
                                </a> -->
                                <a class="text-secondary pl-6">
                                    <span>Discard</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row pl-2 pr-4">
                        <div class="col-md-6">
                            <button class="btn btn-place-order mt-3 text-center" @click="showPlaceOrder()">Place Order</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-pay mt-3 mr-3 text-center" @click="showPayBill()">Pay</button>
                        </div>
                    </div>
                </div>
                <div class="center-section pt-5" v-if="isTableNonSelection">
                    <div class="row pb-4 pr-2">
                        <div class="col-md-3 pl-7">
                            <label class="label-order pl-2">Select Category</label><br>
                            <v-select v-model="selectedCategory" @search="getSearchCategory"
                            :options="categories" label="name" class="v-select-category">
                            </v-select>
                            <a v-for="category in categories" style="display: none;" href="#" :ref="'category_'+category.name.replace(/\s/g, '')"
                            :key="category._id" @click="changeSelectCategory(category.name)" v-scroll-to= "{ el: '#'+category.name.replace(/\s/g, ''), container: '#ScrollContainer', offset: -200, duration: 800,}"></a>
                        </div>
                        <div class="col-md-9">
                            <label class="label-order pl-1 hidden-sm-down">Find Product By Name, Barcode </label>
                            <div class="m-input-icon m-input-icon--left m-input-icon--right">
                                <input type="text" class="form-control m-input m-input--pill" v-model="searchProduct" placeholder="Start Typing To See Product" autocomplete="off">
                                <span class="m-input-icon__icon m-input-icon__icon--left"><span><i class="icon icon-magnify"></i></span></span>
                                <span class="m-input-icon__icon m-input-icon__icon--right pb-3" @click="searchProduct=null"><span><i class="icon icon-close-circle"></i></span></span>
                            </div>
                        </div>
                    </div>
                    <div class="pb-10">
                        <div class="RUCsGf pl-4 pt-1">
                            <template v-for="category in categories">
                                <div style="width:100%" v-if="category.products && category.products.length" :key="category._id">
                                    <p class="heading-category" :id="category.name.replace(/\s/g, '')">{{ category.name }}</p>
                                    <masonry :cols="{default: 4, 1920: 5, 1800: 5, 1600: 4, 1400: 4, 1300: 4, 1280: 4, 1180: 3, 992: 3, 768: 2, 400: 1}" :gutter="{default: '10px'}">
                                        <template v-for="product in category.products">
                                            <div class="Ccie2c a-HZnjzd-xb-QBLLGd" :key="product._id">
                                                <div class="L202Xe">
                                                    <div class="media media-item u-shadow-v11 rounded g-mb-10 pointer">
                                                        <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3 pointer" @click="showQuantityOrder(product._id)">
                                                            <img class="g-width-40 g-height-40" v-if="product.image" :src="product.image" alt="Image Description">
                                                            <img class="g-width-40 g-height-40" v-else src="https://cdn.digicollect.com/cdn/posv2/img/burger.png" alt="Image Description">
                                                        </div>
                                                        <div class="media-body g-pa-20">
                                                            <div class="row no-gutters align-items-center justify-content-between" >
                                                                <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" @click="showQuantityOrder(product._id)">{{ product.name }}</a>
                                                                <i class="icon icon-information lightBlue iconInfo pointer" @click="showProductInfo(product._id)"></i>
                                                            </div>
                                                            <!-- <span v-if="!product.variant" class="g-font-size-13 g-color-gray-dark-v4 g-mr-15 pointer" @click="showQuantityOrder(product._id)">Quantity - {{ product.quantity }}</span> -->
                                                            <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15 pointer" @click="showQuantityOrder(product._id)">Quantity - {{ product.quantity }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </masonry>
                                </div>
                                <!-- <div style="width:100%" v-else :key="category._id">
                                    <p class="heading-category" :id="category.name">{{ category.name }}</p>
                                    <masonry :cols="{default: 4, 1920: 5, 1800: 5, 1600: 4, 1400: 4, 1300: 4, 1280: 4, 1180: 3, 992: 3, 768: 2, 400: 1}" :gutter="{default: '10px'}">
                                        <p>No Products For this Category</p>
                                    </masonry>
                                </div> -->
                            </template>
                            <!-- <div v-if="!totalProducts">
                              fdscsd
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="center-section p-5" v-if="isTableSelection">
                    <table-selection @updateSelectedTable="updateSelectedTable" :tables="tables" :selectedTable="selectedTable"></table-selection>
				</div>
            </div>
      </div>
      <div>
        <quantity-order
            @addProductVariant="addProductVariant"
            @updateSelectedVariant="updateSelectedVariant"
            @updateSelectedQuantity="updateSelectedQuantity"
            modal_name="quantity_order"
            :variant="selectedVariant"
            :product="selectedProduct"
            :quantity="selectedQuantity">
        </quantity-order>
      </div>
      <div>
          <products-variants modal_name="product_info"></products-variants>
      </div>
      <div>
          <pay-bill :for_approvals="false" modal_name="pay-bill"></pay-bill>
      </div>
      <div>
          <place-order modal_name="place-order"></place-order>
      </div>
      <div>
            <create-customer :customer="customer" modal_name="customer-create" :bool="customerBool" @createCustomer="createCustomer" @resetCustomer="resetCustomer"></create-customer>
       </div>
  </div>
</template>
<script>
// import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

import QuantityOrder from "./QuantityOrder"
import ProductsVariants from "./ProductsVariants"
import PayBill from './PayBill'
import PlaceOrder from './PlaceOrder'
import CreateCustomer from './CreateCustomer'
import TableSelection from "./TableSelection"

import CustomerServices from './mixins/customer'
import OrderServices from './mixins/orders'
import { EventBus } from './eventBus/nav-bar-event'

export default {
    mixins: [OrderServices, CustomerServices],
    data() {
        return {
            isTableSelection: false,
            isTableNonSelection:true,
            discountIsPercentage: true,
            chargesIsPercentage: true,
            search_customers: [],
            addNotediv: false,
            addDiscountdiv: false,
            searchCustomer:false,
            addCharges: false,
            selectedCategory: "All",
            searchProduct: '',
            totalProducts: 0,
            categories: [],
            orderType: 2, // 1: TakeAway 2: DineIn
            selectedCustomer: {},
            selectedProduct: {},
            selectedVariant: {},
            selectedQuantity: 0,
            selectedTable: [],
            tables:[],
            orderProducts: [],
            productTotal: 0,
            orderCharges: 0,
            orderDiscount: 0,
        }
    },
    components: {
        QuantityOrder,
        ProductsVariants,
        TableSelection,
        PayBill,
        PlaceOrder,
        VueScrollTo,
        CreateCustomer
    },
    methods: {
        resetCustomer(){
          this.customer = {
            first_name: '',
            last_name: '',
            middle_name: '',
            salutation: '',
            email: '',
            phone_number: '',
            promotions: false,
            image: '',
            liked_products: [],
            disliked_products: [],
            company_name: '',
            tax_code: '',
            referred_by: '',
            notes: ''
          };
        },
        removeOrderProduct(index){
            this.productTotal -= this.orderProducts[index].quantity * this.orderProducts[index].price;
            this.orderProducts.splice(index, 1);
        },
        removeOrderVariant(index, variantIndex){
            if (this.orderProducts[index].variants.length === 1){
                this.productTotal -= this.orderProducts[index].variants[0].quantity * this.orderProducts[index].variants[0].price;
                this.orderProducts.splice(index, 1);
            } else {
                this.productTotal -= this.orderProducts[index].variants[variantIndex].quantity * this.orderProducts[index].variants[variantIndex].price;
                this.orderProducts[index].variants.splice(variantIndex, 1);
            }
        },
        updateSelectedQuantity(quantity){
            this.selectedQuantity = quantity;
        },
        updateSelectedVariant(variant) {
            this.selectedVariant = variant;
        },
        showCreateCustomer() {
            this.searchCustomer = false;
            this.$modal.show('customer-create');
        },
        showQuantityOrder(id){
            this.$http
                .get("/orders/get_product_variants", {
                    params: { id }
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.selectedProduct = res.data.message;
                        this.selectedQuantity = 1;
                        this.selectedVariant = {};
                        this.$modal.show('quantity_order');
                    } else {
                        this.modal_msg = res.data.reason;
                        this.$refs.warning_modal.open();
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason;
                    this.$refs.warning_modal.open();
                });
        },
        showProductInfo(product_id){
            EventBus.$emit('ProductVariant', product_id);
            this.$modal.show('product_info');
        },
        activeWizard() {
            this.isActiveWizard = true;
            this.isCompletedWizard = false;
            this.isClosedWizard = false;
        },
        completedWizard() {
            this.isActiveWizard = false;
            this.isCompletedWizard = true;
            this.isClosedWizard = false;
        },
        closedWizard() {
            this.isActiveWizard = false;
            this.isCompletedWizard = false;
            this.isClosedWizard = true;
        },
        addNote() {
            this.addCharges = false;
            this.addDiscountdiv = false;
            this.addNotediv = true;
        },
        addDiscount() {
            this.addCharges = false;
            this.addDiscountdiv = true;
            this.addNotediv = false;
        },
        additionalCharges() {
            this.addCharges = true;
            this.addDiscountdiv = false;
            this.addNotediv = false;
        },
        hideNotediv() {
            this.addNotediv = false;
        },
        tableselectionclick() {
          this.isTableNonSelection = false;
          this.isTableSelection = true;
        },
        showPayBill() {
            this.$modal.show('pay-bill');
        },
        showPlaceOrder() {
            this.$modal.show('place-order');
        },
        getCategoryProducts() {
            this.$http
                .get("/orders/get_items_for_order", {
                    params: { search: this.searchProduct }
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.categories = res.data.message;
                        this.categories = this.categories.filter(value => {
                            return value.name && value.products.length
                        })
                        this.totalCategories = res.data.message.length;
                    } else {
                        this.modal_msg = res.data.reason;
                        this.$refs.warning_modal.open();
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason;
                    this.$refs.warning_modal.open();
                });
        },
        changeSelectCategory(category) {
            this.selectedCategory = category;
        },
        getSearchCustomer(search='') {
            this.selectedCustomer = '';
            this.getCustomerWith(
                ['first_name', 'last_name'],
                {term: search, fields: ['first_name', 'last_name']},
                {by: 'first_name', order: 1}, 0, 5
            );
        },
        getSearchCategory(search='') {
            this.selectedCategory = '';
        },
        updateSelectedTable(selectedTable) {
            this.selectedTable = selectedTable;
        },
        getTables(){
            this.$http
                .get("/orders/tables")
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.tables = res.data.message;
                    } else {
                        this.modal_msg = res.data.reason;
                        this.$refs.warning_modal.open();
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason;
                    this.$refs.warning_modal.open();
                });
        },
        addProductVariant(data){
            if(Object.keys(data.variant).length === 0){
                let existFlag = false;
                this.orderProducts.every(product => {
                    if(product._id == data.product._id) {
                        product.quantity += data.quantity;
                        this.productTotal += data.quantity*product.price;
                        existFlag = true;
                        return false;
                    }
                    return true;
                })
                if(!existFlag){
                    this.orderProducts.push({
                        _id: data.product._id,
                        name: data.product.name,
                        price: data.product.price,
                        quantity: data.quantity,
                    });
                    this.productTotal += data.quantity * data.product.price;
                }
            } else {
                let existFlag = false;
                this.orderProducts.every(product => {
                    if(product._id == data.product._id) {
                        let variantExistFlag = false;
                        product.variants.every(variant => {
                            if (variant.name == data.variant.variant_name){
                                variant.quantity += data.quantity;
                                this.productTotal += data.quantity * variant.price;
                                variantExistFlag = true;
                                return false;
                            }
                            return true;
                        })
                        if (!variantExistFlag){
                            product.variants.push({
                                name: data.variant.variant_name,
                                price: data.variant.variant_price,
                                quantity: data.quantity,
                            })
                            this.productTotal += data.quantity * data.variant.variant_price;
                        }
                        existFlag = true;
                        return false;
                    }
                    return true;
                })
                if(!existFlag){
                    this.orderProducts.push({
                        _id: data.product._id,
                        name: data.product.name,
                        variants: [
                            {
                                name: data.variant.variant_name,
                                price: data.variant.variant_price,
                                quantity: data.quantity,
                            }
                        ]
                    });
                    this.productTotal += data.quantity * data.variant.variant_price;
                }
            }
        }
    },
    created() {
        EventBus.$on('closeQuantityOrder', (modal_name) => {
            this.$modal.hide('quantity_order');
        });
        EventBus.$on('closeProductInfo', (modal_name) => {
            this.$modal.hide('product_info');
        });
        EventBus.$on('closeTableSelect', table => {
            this.isTableSelection = false;
            this.isTableNonSelection = true;
        });
        EventBus.$on('ClosePayBill', modal_name => {
          this.$modal.hide('pay-bill');
        });
        EventBus.$on('ClosePlaceOrder', modal_name => {
          this.$modal.hide('place-order');
        });
        EventBus.$on('createdCustomerId', newId => {
          this.getCustomer(newId);
        });
        this.getCategoryProducts();
        this.getSearchCustomer();
        this.getTables();
    },
    watch: {
        searchProduct(newValue, oldValue) {
            this.getCategoryProducts();
        },
        selectedCategory(newValue, oldValue) {
            if (newValue && newValue.name) {
                let name = newValue.name.replace(/\s/g, "")
                this.$refs['category_' + name][0].click();
            }
        },
        selectedCustomer(newCustomer, oldCustomer) {
            this.searchCustomer = false;
        },
        orderType(newValue, oldValue) {
            if(newValue == 1) {
                this.isTableNonSelection = true;
                this.isTableSelection = false;
            }
        },
        singleCustomer(newCustomer) {
            this.selectedCustomer = newCustomer;
        }
    },
    computed: {
        showSelectTable() {
            return this.orderType == 2
        },
        orderTotal() {
            let orderTotal = 0;
            orderTotal += this.productTotal;
            if(this.chargesIsPercentage){
                orderTotal += (orderTotal/100) * this.orderCharges;
            }else {
                orderTotal += this.orderCharges;
            }
            if(this.discountIsPercentage){
                orderTotal -= (orderTotal/100) * this.orderDiscount;
            }else {
                orderTotal -= this.orderDiscount;
            }
            if(orderTotal < 0){
                return 0;
            }
            return orderTotal;
        }
    }
}
</script>
<style>
.v-select-category{
    border: solid 1px #d5d5d5!important;
    border-radius: 15px;
    background-color: #f2f2f2;
}
.v-select.v-select-category .dropdown-toggle{
    border: none!important;
    border-bottom: 0px!important;
}
.v-select.v-select-category .form-control {
    padding: 5px 10px !important;
}
.v-select.single.v-select-category .selected-tag {
    padding: 3px 5px 2px 7px!important;
}
@media (min-width: 992px) {
  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 0) {
  .g-pa-20 {
    padding: 10px 10px 11px 15px !important;
  }
  .g-mb-10 {
    margin-bottom: 0.71429rem !important;
  }
  .g-mr-15 {
    margin-right: 15px !important;
  }
  .g-pr-15 {
    padding-right: 15px !important;
  }
  .g-pl-15 {
    padding-left: 15px !important;
  }
  .g-mt-3 {
    margin-top: 0px !important;
  }
  .g-mb-3 {
    margin-bottom: 0.21429rem !important;
  }
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.list-unstyled.itemslist {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.u-shadow-v11 {
  box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #cdd2da;
  background-color: #f9fbff;
}
.rounded {
  border-radius: 4px !important;
}
.media.media-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 1;
}
.media.media-item .media-body {
  -ms-flex: 1;
  flex: 1;
}
.media.media-item.unavaliable-item {
  opacity: 0.5;
}
.g-height-40 {
  height: 40px;
}

.g-width-40 {
  width: 40px !important;
}
.g-color-main {
  color: #00448b !important;
}
.g-font-weight-600 {
  font-weight: 600 !important;
}
.g-color-gray-dark-v4 {
  color: #4e5764 !important;
  line-height: 2.25;
  letter-spacing: 0.3px;
}
.g-font-size-13 {
  font-size: 13px !important;
}
.u-link-v5 {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
}
.itemimg {
  border-right: solid 0.5px #cdd2da;
}

.RUCsGf {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  margin: -5px;
  overflow: hidden;
}
/* .a-HZnjzd-xb-QBLLGd {
        flex: 1 1 150px;
        min-width: 150px;
        max-width: 250px;
    } */
.Ccie2c {
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin-right: 10px;
}
.a-dkl3Ye .L202Xe {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.iconInfo{
    font-size: 17px;
    position: relative;
    bottom: 2px;
}
.heading-category {
  font-weight: 600 !important;
  color: #303031 !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  font-size: 14px;
}
.statusdot {
  display: block;
  position: absolute;
  right: 5px;
  top: -4px;
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff3b3b;
}
.header-search {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  position: relative;
  min-height: 96px;
  background-image: none;
  z-index: 49;
  padding: 16px !important;
}
.form-control.note-textarea {
  border-radius: 7px !important;
  border: solid 1px #cdd2da !important;
}
.note-add {
  cursor: pointer;
  color: #94bef0 !important;
}
.showdiscount,
.shownote {
  border-bottom: solid 1px #94bef0 !important;
  padding-bottom: 10px !important;
}
.DollarTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: #fff;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.PerTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: #fff;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.form-control.discount-input {
  border-radius: 100px !important;
  border: solid 1px #cdd2da !important;
  padding: 7px 10px !important;
}
.header-content {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex: 1 0 auto;
}
.dropdown-category {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center;
  align-items: center;
  order: 1;
}
.header-board-name {
  font-size: 16px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1 0 auto;
  order: 2;
  margin-bottom: 0 !important;
}
.btn.btn-order {
  border: solid 0.5px #cdd2da !important;
  border-radius: 50px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f2f2f2;
  font-size: 12px !important;
  font-weight: bold;
  color: #9e9e9e;
  height: 32px;
  min-width: 13rem !important;
}
.btn.btn-order.dropdown-toggle::after {
  margin-left: 6.255em;
}
.form-control.input-order {
  border-radius: 50px !important;
  border: solid 0.5px #d5d5d5 !important;
  background-image: linear-gradient(
    to bottom,
    #efefef,
    #ffffff 25%,
    #ffffff 48%,
    #ffffff 78%,
    #efefef
  ) !important;
}
.label-order {
  font-weight: 600;
  color: #9e9e9e;
  line-height: 1.79;
  letter-spacing: 0.3px;
}
.unavaliable-order {
  text-align: center;
  display: block;
  position: absolute;
  background: rgba(175, 175, 175, 0.59);
  color: #e82828;
  width: 100%;
  top: 26px;
}
.unavaliable-order h6 {
  line-height: 1.79;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 auto;
}
.list-card-labels {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 6px;
}
.list-card-labels .list-card-label {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 32px;
  height: 6px;
  border-radius: 6px;
  margin: 0 6px 6px 0;
}
.btn-smm-new {
  height: 34px;
  padding: 0 18px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 34px;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
.userdetailsblock {
  width: 250px;
  background-color: #f5f6f9;
  max-width: 260px;
}
/* New Box CSS */
.page-layout.simple {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow-x: hidden;
  /* overflow-y: auto; */
  height: 100%;
}
.content-section {
  display: flex;
  min-height: 100%;
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  position: relative;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 300px;
  min-width: 350px;
  max-width: 350px;
  z-index: 1000;
}
.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .sidebar.locked-open {
  /* padding-left: 10px; */
  box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.16);
  border-left: solid 1px #cdd2da;
  background-color: #ffffff;
}
.page-layout.simple.right-sidebar.inner-sidebar .content-section .sidebar {
  order: 2;
}
.fuse-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
  order: 1;
}
.page-layout.simple.left-sidebar.inner-sidebar .content-section .center-section,
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 992px) {
  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.list-unstyled.itemslist {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.u-shadow-v11 {
  box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #cdd2da;
  background-color: #f9fbff;
}
.rounded {
  border-radius: 4px !important;
}
.media.media-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 1;
}
.media.media-item .media-body {
  -ms-flex: 1;
  flex: 1;
}
.media.media-item.unavaliable-item {
  opacity: 0.5;
}
.g-height-40 {
  height: 40px;
}

.g-width-40 {
  width: 40px !important;
}
.g-color-main {
  color: #00448b !important;
}
.g-font-weight-600 {
  font-weight: 600 !important;
}
.g-color-gray-dark-v4 {
  color: #4e5764 !important;
  line-height: 2.25;
  letter-spacing: 0.3px;
}
.g-font-size-13 {
  font-size: 13px !important;
}
.u-link-v5 {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
}
.itemimg {
  border-right: solid 0.5px #cdd2da;
}

.RUCsGf {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  margin: -5px;
  overflow: hidden;
}
/* .a-HZnjzd-xb-QBLLGd {
        flex: 1 1 150px;
        min-width: 150px;
        max-width: 250px;
    } */
.Ccie2c {
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin-right: 10px;
}
.a-dkl3Ye .L202Xe {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.L202Xe {
  background-color: #fff;
  margin: 7px;
  overflow: hidden;
  position: relative;
}
.preview {
  width: 100px;
  margin: 0 1px 8px 0;
  z-index: 0 !important;
}
.description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.toggle-source-preview {
  cursor: pointer;
  font-size: 12px;
  padding-left: 16px;
  color: #00448b;
}
.btn-select-table {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.21 !important;
  letter-spacing: 0.6px !important;
  color: #00448b !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
}
.btn-pay {
  width: 100% !important;
  background-color: #00448b !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 2.5px;
  font-size: 14px !important;
  text-transform: capitalize !important;
}
.btn-place-order {
  width: 100% !important;
  background-color: #f5a623 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 2.5px;
  font-size: 14px !important;
  text-transform: capitalize !important;
}
.btn-pay .badge {
  float: right !important;
  top: 7px !important;
  font-weight: 600;
}
.tfoot-total-order {
  border-top: solid 0px #94bef0;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablesubtotal tr {
  height: 10px !important;
}
.table.tablesubtotal th {
  padding: 4px !important;
}
.tablesubtotaltd,
.tablesubtotalth {
  text-align: right !important;
}
.table.tablecart th.thcart {
  color: #303031;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px !important;
  padding: 5px 8px !important;
  text-transform: none !important;
  border-bottom: 0px solid transparent !important;
}
.table.tablecart td.tdcart {
  color: #4e5764;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px;
  padding: 5px 8px !important;
}
.table.tablecart tfoot th.tfcart {
  padding: 10px 8px !important;
}
.table.tablecart tfoot tr {
  border-top: dotted 0.5px #b8b9bb;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablecart tbody tr {
  height: auto !important;
}
a.add-customer-heading {
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #00448b !important;
  text-decoration: none !important;
}

.searchProducts {
  width: 100%;
  height: 34px;
  border-radius: 100px;
  border: solid 1px #d5d5d5 !important;
  padding-left: 35px;
  padding-right: 35px;
  background-image: linear-gradient(
    to bottom,
    #efefef,
    #ffffff 25%,
    #ffffff 48%,
    #ffffff 78%,
    #efefef
  );
}
.searchIcon {
  color: #cfcfcf !important;
  position: absolute;
  pointer-events: cursor;
  top: 22px;
  font-size: 19px;
  left: 15px;
  display: inline-block;
}
.closeIcon {
  color: #cdd2da !important;
  position: absolute;
  pointer-events: cursor;
  top: 22px;
  font-size: 19px;
  right: 10px;
  display: inline-block;
}

.border-dotted {
  border-bottom: solid 1px #b8b9bb;
  border-bottom-style: dashed;
}
.makeOrder {
  position: relative;
  bottom: 9px;
}
.deepSkyBlue {
  font-weight: 600;
  color: #00448b !important;
}
.noteTextArea {
  width: 100%;
  height: 100px !important;
  border: solid 1px #cdd2da;
  border-radius: 5px !important;
}
.card-title.paytype {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #b5b5b5;
  background: transparent;
}
.card-body.pay-body {
  padding: 1rem !important;
  border-left: 6px solid #a1caff00 !important;
  margin-bottom: 15px !important;
  border-radius: 0px;
}
.payselected {
  position: absolute;
  top: -1rem;
  right: -0.8rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
.payselected.online {
  background: #06bb00;
  color: #fff;
}
.payselected.online:before {
  content: "\ea7b";
}
.tableDisplay{
    float: right;
    text-align: right;
    padding-right: 65px;
}
.orderTabs{
    padding-right: 20px;
    float: right;
}

@media all and (max-width: 950px){
      .sidebar.locked-open{
          display: none;
      }
}
@media all and (max-width: 972px){
    .manageSearch{
        display: block;
        width: 100%;
    }
}
.activeClassDiscount {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.currencySelect{
  margin-right: 17px;
  border-left: 1px solid rgb(238, 238, 238) !important;
  font-size: 14px !important;
}
.btn-select-table.dropdown-toggle::after{
  display: none;
}
.selectCustomer{
    width: 100%;
    height: 60px;
    box-shadow: -2.5px 0.5px 3px 0 rgba(0, 0, 0, 0.16);
    z-index: 9;
    position: absolute;
    background-color: #ffff !important;
}
 .lightBlue:hover{
    color: #00448b !important;
  }
</style>
