<template>
    <div class="page-layout blank report-manage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="d-flex ml-2 mr-2 justify-content-between align-items-center flex-wrap">
                    <div class="d-flex flex-wrap">
                        <router-link :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-right" effect="dark" content="Click to Go Back"
                                placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-left"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <router-link :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward"
                                placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <div >
                            <date-picker v-if="convertUTC == false" v-model="dateRange" @confirm="onDateChange()"
                                format="MM/DD/YYYY hh:mm A" :first-day-of-week="7" range class="date-width"
                                :show-second="false" :shortcuts="shortcuts" :lang="lang" :clearable="true" confirm
                                :not-after="new Date()" confirm-text="APPLY" type="datetime"></date-picker>
                            <date-picker v-else v-model="dateRange" class="date-width" @confirm="onDateChange()"
                                format="MM/DD/YYYY hh:mm A" :first-day-of-week="7" range :show-second="false"
                                :shortcuts="shortcuts" :lang="lang" :clearable="true" confirm :not-after="new Date()"
                                confirm-text="APPLY" type="datetime"></date-picker>
                        </div>
                        <div class="ml-4" v-if="!loggedInUser.outlet">
                            <div class style="padding-top: 4px;">
                                <v-select label="name" class="clearNone" v-model="selected" :clearSearchOnSelect="true"
                                    :selected="selected" :filterable="false" :options="options" @search="onSearch"
                                    :clearable="false"></v-select>
                            </div>
                        </div>
                        <div class="ml-4 resetMargin">
                            <button type="submit" @click="reset" class="btn btn-secondary btn-smm">RESET</button>
                        </div>
                        <div v-if="show_download_report" class="ml-3">
                            <button @click="downloadReport" :disabled="is_email_report_api_call" class="btn btn-success btn-smm text-white">
                                Download Report
                            </button>
                        </div>
                    </div>
                    <!-- <div>
            <button type="button" @click="generatePDF" class="btn btn-danger btn-print">
              <img
                src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg"
                style="height: 13px;margin-right: 5px;margin-top: -3px;"
              /> Download PDF
            </button>
          </div> -->
                </div>
            </h2>

            <div class="blockdiv" style="display: none;"></div>
            <div class="loaderdiv" style="display: none;">
                Downloading PDF Please Wait...
                <div class="text-center mt-7 mb-4">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
            <div class="w-100 h-100 canvas_div_pdf report_mamane_new_design">
                <div class="print-dash page1">
                    <div class="row no-gutters pt-3">
                        <div
                            class="digi-grid digi-grid-width-small-1-2 digi-grid-width-large-1-3 digi-grid-width-xlarge-1-5 digi-text-center">
                            <div class="digi-row-first">
                                <div class="digi-card dashboard-digi digi-card-hover digi-card-overlay">
                                    <div class="digi-card-content" style="height: 140px;">
                                        <div
                                            class="widget-content pt-2 pb-6 d-flex flex-column align-items-center justify-content-center">
                                            <div class="title text-dashboard">{{report_counts.total_no_of_orders}}</div>
                                            <div class="sub-title h6 text-muted dashboard-text">TOTAL ORDERS</div>
                                        </div>
                                    </div>
                                    <div class="digi-card-overlay-content"
                                        style="background-image: linear-gradient(to bottom, #ffc462, #f5a623);">
                                        <div class="digi-clearfix digi-card-overlay-header">
                                            <h3>Count</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="digi-row-first">
                                <div class="digi-card dashboard-digi digi-card-hover digi-card-overlay">
                                    <div class="digi-card-content" style="height: 140px;">
                                        <div
                                            class="widget-content pt-2 pb-6 d-flex flex-column align-items-center justify-content-center">
                                            <div class="title text-dashboard">{{report_counts.gross_income}}</div>
                                            <div class="sub-title h6 text-muted dashboard-text">GROSS INCOME</div>
                                        </div>
                                    </div>
                                    <div class="digi-card-overlay-content"
                                        style="background-image: linear-gradient(to bottom, #10c469, #00d871);">
                                        <div class="digi-clearfix digi-card-overlay-header">
                                            <h3>Currency</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="digi-row-first">
                                <div class="digi-card dashboard-digi digi-card-hover digi-card-overlay">
                                    <div class="digi-card-content" style="height: 140px;">
                                        <div
                                            class="widget-content pt-2 pb-6 d-flex flex-column align-items-center justify-content-center">
                                            <div class="title text-dashboard">{{report_counts.avg_value_of_order}}</div>
                                            <div class="sub-title h6 text-muted dashboard-text">AVG VALUE OF ALL
                                                TRANSACTIONS</div>
                                        </div>
                                    </div>
                                    <div class="digi-card-overlay-content"
                                        style="background-image: linear-gradient(to top, rgb(13, 191, 218), rgb(43, 217, 246));">
                                        <div class="digi-clearfix digi-card-overlay-header">
                                            <h3>Currency</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="digi-row-first">
                                <div class="digi-card dashboard-digi digi-card-hover digi-card-overlay">
                                    <div class="digi-card-content" style="height: 140px;">
                                        <div
                                            class="widget-content pt-2 pb-6 d-flex flex-column align-items-center justify-content-center">
                                            <div class="title text-dashboard">{{report_counts.customers_count}}</div>
                                            <div class="sub-title h6 text-muted dashboard-text">CUSTOMERS COUNT</div>
                                        </div>
                                    </div>
                                    <div class="digi-card-overlay-content"
                                        style="background-image: linear-gradient(to top, rgb(13, 132, 242), rgb(78, 161, 255));">
                                        <div class="digi-clearfix digi-card-overlay-header">
                                            <h3>Count</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="digi-row-first">
                                <div class="digi-card dashboard-digi digi-card-hover digi-card-overlay">
                                    <div class="digi-card-content" style="height: 140px;">
                                        <div
                                            class="widget-content pt-2 pb-6 d-flex flex-column align-items-center justify-content-center">
                                            <div class="title text-dashboard">{{report_counts.avg_revenue_per_customer}}</div>
                                            <div class="sub-title h6 text-muted dashboard-text">AVG REVENUE PER CUSTOMER
                                            </div>
                                        </div>
                                    </div>
                                    <div class="digi-card-overlay-content"
                                        style="background-image: linear-gradient(rgb(255, 100, 162), rgb(246, 70, 132));">
                                        <div class="digi-clearfix digi-card-overlay-header">
                                            <h3>Currency</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-5-cols">
              <div class="widget widget3 card  text-auto br-8">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between" style="width: 185px;height: 48px;">
                  <div class="col">
                    <div class="row no-gutters align-items-center">
                      <span class="heading-card-report">TOTAL ORDERS</span>
                    </div>
                  </div>
                </div>
                <div class="widget-content pl-4 pr-4 pt-5">
                  <span class="progressImages pull-right">
                    <img src="https://cdn.digicollect.com/cdn/posv2/bag.svg" class="progressIcon">
                  </span>
                  <span class="h5">25</span>
                </div>
              </div>
            </div>
            <div class="col-lg-5-cols">
              <div class="widget widget3 card  text-auto br-8">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between" style="width: 185px;height: 48px;">
                  <div class="col">
                    <div class="row no-gutters align-items-center">
                      <span class="heading-card-report">GROSS INCOME </span>
                    </div>
                  </div>
                </div>
                <div class="widget-content pl-4 pr-4 pt-5">
                  <span class="progressImages pull-right">
                    <img src="https://cdn.digicollect.com/cdn/posv2/bag.svg" class="progressIcon">
                  </span>
                  <span class="h5">$10,000</span>
                </div>
              </div>
            </div>
            <div class="col-lg-5-cols">
              <div class="widget widget3 card  text-auto br-8">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between pt-4" style="width: 185px;height: 48px;">
                  <div class="col">
                    <div class="row no-gutters align-items-center">
                      <span class="heading-card-report">AVG VALUE OF ALL TRANSACTIONS </span>
                    </div>
                  </div>
                </div>
                <div class="widget-content pl-4 pr-4 pt-5">
                  <span class="progressImages pull-right">
                    <img src="https://cdn.digicollect.com/cdn/posv2/bag.svg" class="progressIcon">
                  </span>
                  <span class="h5">$10,000</span>
                </div>
              </div>
            </div>
            <div class="col-lg-5-cols">
              <div class="widget widget3 card  text-auto br-8">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between" style="width: 185px;height: 48px;">
                  <div class="col">
                    <div class="row no-gutters align-items-center">
                      <span class="heading-card-report">CUSTOMERS COUNT</span>
                    </div>
                  </div>
                </div>
                <div class="widget-content pl-4 pr-4 pt-5">
                  <span class="h5">25</span>
                  <div class="progressImages pull-right">
                    <img src="https://cdn.digicollect.com/cdn/posv2/gross-profit.svg" class="progressIcon">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5-cols">
              <div class="widget widget3 card  text-auto br-8">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between pt-4" style="width: 185px;height: 48px;">
                  <div class="col">
                    <div class="row no-gutters align-items-center">
                      <span class="heading-card-report">AVG REVENUE PER CUSTOMER</span>
                    </div>
                  </div>
                </div>
                <div class="widget-content pl-4 pr-4 pt-5">
                  <span class="h5">$200</span>
                  <span class="progressImages pull-right">
                    <img src="https://cdn.digicollect.com/cdn/posv2/customer.svg" class="progressIcon">
                  </span>
                </div>
              </div>
            </div>-->
                    </div>
                    <div class="d-flex mx-3 flexWrap">
                        <div class="col-lg-12 pl-0 pr-0 totalSales pb-5 total-sales-div-top">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">TOTAL SALES</h6>
                            <!-- <div class="d-flex justify-content-between align-items-center" v-if="salesLoading == true">
                <div class="digi-table-loader mt-16" style="left:45%">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>-->
                            <div class="d-flex flex-wrap">
                                <span v-if="paymentSummary.cash + paymentSummary.card + paymentSummary.others <= 0"
                                    class="font-weight-bold text-center col-lg-7 pt-21"
                                    style=" box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;height:200px;">NO DATA</span>
                                <div v-else class="col-lg-7 col-md-7 col-sm-12">
                                    <div id="chartSales"></div>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-12 pl-8"
                                    style="-webkit-box-shadow: -3px 0px 0px 0px rgba(0, 0, 0, 0.13);box-shadow: -3px 0px 0px 0px rgba(0, 0, 0, 0.13)">
                                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                                        <div class="text-white font-weight-bold">Subtotal</div>
                                        <div class="pull-right d-block pr-8">
                                            <span>{{total_sales_report.sub_total}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Order Discount</div>
                                        <div class="pull-right d-block pr-8">
                                            <span>{{total_sales_report.discount_amount}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div :key="name" class="justify-content-between align-items-center mt-3"
                                        v-for="(name,value) in total_sales_report.other_charges_sum">
                                        <div class="text-white font-weight-bold">{{value}}</div>
                                        <div class="pull-right d-block pr-8 alignContent">
                                            <span>{{name}}</span>
                                        </div>
                                        <div class="bottomSubtotal"></div>
                                    </div>
                                    <div class="justify-content-between align-items-center">
                                        <div :key="name" v-for="(name,value) in total_sales_report.taxes">
                                            <div class="text-white font-weight-bold" style="padding-top: 10px;">
                                                {{value}}</div>
                                            <div class="pull-right d-block pr-8 alignContent">
                                                <span>{{name}}</span>
                                            </div>
                                            <div class="bottomSubtotal"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center flex-wrap mt-3">
                                        <div class="text-white font-weight-bold">Additional Charges</div>
                                        <div class="pull-right d-block pr-8">
                                            <span>{{total_sales_report.additional_charges || 0}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Round Off</div>
                                        <div class="pull-right d-block pr-8">
                                            <span>{{total_sales_report.total_round_off}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Total Sales</div>
                                        <div class="pull-right d-block pr-8">
                                            <span>{{total_sales_report.total_sales}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-3 mx-3 flexWrap">
                        <div class="col-lg-12 pr-0 totalSales marginSize pb-5">
                            <h6 class="text-white font-weight-bold m-7 d-block text-center">CLOSED TRANSACTIONS</h6>
                            <div class="d-flex flex-wrap">
                                <!-- <div class="digi-table-loader mt-16" style="left:22%" v-if="closed_transactionLoading == true">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>-->
                                <div v-if="reportData.close_bills.total.count + reportData.close_bills.dine_in.count + reportData.close_bills.take_away.count <= 0"
                                    class="font-weight-bold text-center col-lg-7 col-md-7 col-sm-12 pt-18"
                                    style=" box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;">NO DATA</div>
                                <div v-else class="col-lg-7 col-md-7 col-sm-12 pieBoxshadow mb-4">
                                    <div style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;">
                                        <div id="chartBills"></div>
                                        <div id="amountBills"></div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-12 pl-8">
                                    <table>
                                        <thead>
                                            <th></th>
                                            <th class="text-white fs-16">Count</th>
                                            <th class="text-white fs-16">Value ({{reportData.currency.symbol || ''}})
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="width:147px"
                                                    class="text-truncate text-white font-weight-bold">All Transactions
                                                </td>
                                                <td style="width:108px;">{{reportData.close_bills.total.count}}</td>
                                                <td>{{reportData.close_bills.total.value}}
                                                </td>
                                            </tr>
                                            <div class="bottomSubtotal" style="margin-top:0 !important;"></div>
                                            <tr>
                                                <td style="width:147px " class="text-white font-weight-bold">Dine-In
                                                </td>
                                                <td style="width:108px;">{{reportData.close_bills.dine_in.count}}</td>
                                                <td>{{reportData.close_bills.dine_in.value}}
                                                </td>
                                            </tr>
                                            <div class="bottomSubtotal" style="margin-top:0 !important;"></div>
                                            <tr>
                                                <td style="width:147px" class="text-white font-weight-bold">Take-Away
                                                </td>
                                                <td style="width:108px;">{{reportData.close_bills.take_away.count}}</td>
                                                <td>{{reportData.close_bills.take_away.value}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mx-1 mt-3">
            <div class="col-lg-12 pl-0 pr-0 totalSales pb-5">
              <h6 class="text-secondary font-weight-bold m-5 d-block text-center">CANCELLED BILLS</h6>
              <div class="d-flex pb-4 flex-wrap">
                <span
                  v-if="cancelled_bills_empty"
                  class="col-lg-4 col-md-4 col-sm-12 font-weight-bold text-center pt-18"
                  style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;height:210px;"
                >NO DATA</span>
                <div v-if="!cancelled_bills_empty" id="cancelBills" class="col-lg-4 col-md-4 col-sm-12"></div>
                <div
                  class="col-lg-4 col-md-4 col-sm-12 pl-5"
                  style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;"
                >
                  <h6 class="text-secondary font-weight-bold">COUNT</h6>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Cancelled Bills</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.cancelled_bills}} Bills</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Void Bills</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}} Bills</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 pl-5 currencyTop">
                  <h6 class="text-secondary font-weight-bold">CURRENCY</h6>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Cancelled Bills</div>
                    <div class="pull-right d-block pr-10">
                      <span> {{formatPrice(reportData.cancel_bills.currency.cancelled_bills)}}</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Void Bills</div>
                    <div class="pull-right d-block pr-10">
                      <span> {{formatPrice(reportData.cancel_bills.currency.void_bills)}}</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                </div>
              </div>
            </div>
          </div>-->
                </div>
                <div class="print-dash page2">

                    <div class="row mx-3 mt-3">
                        <div class="col-lg-12 pl-0 pr-0 totalSales pb-5">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">SALES BREAKDOWN</h6>
                            <div class="d-flex pb-4 flex-wrap">
                                <span v-if="reportData.category_sales.length == 0"
                                    class="col-lg-6 col-md-6 col-sm-12 font-weight-bold text-center pt-18"
                                    style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;height:210px;">NO DATA</span>
                                <div v-else class="col-lg-6 col-md-6 col-sm-12"
                                    style="box-shadow:rgba(0, 0, 0, 0.13) 5px 0 0 0;">
                                    <div id="salesBreakdown"></div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 pl-5">
                                    <!-- <h6 class="text-secondary font-weight-bold">COUNT</h6> -->
                                    <div v-for="category in reportData.category_sales" :key="category._id">
                                        <div class="d-flex justify-content-between align-items-center mt-3">
                                            <div class="text-white font-weight-bold"
                                                style="text-transform: capitalize;">{{ category.name }}</div>
                                            <div class="pull-right d-block pr-10">
                                                <span class="whiteSpace">{{ category.total }}</span>
                                            </div>
                                        </div>
                                        <div class="bottomSubtotal"></div>
                                    </div>
                                    <!-- <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Beverage Sales</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Cigar Sales</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Clothing Sales</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span>
                    </div>
                  </div>
                  <div class="bottomSubtotal"></div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="text-secondary font-weight-bold">Total Sales</div>
                    <div class="pull-right d-block pr-10">
                      <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span>
                    </div>
                  </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mr-3 ml-3 mt-3 pr-5 flexWrap">
                        <div class="col-lg-6 pl-0 pr-0 totalSales pb-5">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">CANCELLED BILLS</h6>
                            <div class="d-flex pb-4 flex-wrap">
                                <span
                                    v-if="reportData.cancel_bills.count.cancelled + reportData.cancel_bills.count.void <= 0"
                                    class="col-lg-6 col-md-6 col-sm-12 font-weight-bold text-center pt-18"
                                    style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;height:210px;">NO DATA</span>
                                <div v-else class="col-lg-6 col-md-6 col-sm-12"
                                    style="box-shadow:rgba(0, 0, 0, 0.13) 5px 0 0 0;">
                                    <div id="newCancelledBills"></div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 pl-5">
                                    <h6 class="text-white font-weight-bold">COUNT</h6>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Cancelled Bills</div>
                                        <div class="pull-right d-block pr-10">
                                            <span class="whiteSpace">{{reportData.cancel_bills.count.cancelled}}
                                                Bills</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Void Bills</div>
                                        <div class="pull-right d-block pr-10">
                                            <span class="whiteSpace">{{reportData.cancel_bills.count.void}} Bills</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">CURRENCY
                                            ({{reportData.currency.symbol || ''}})</div>
                                        <div class="pull-right d-block pr-10">
                                            <!-- <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span> -->
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Cancelled Bills</div>
                                        <div class="pull-right d-block pr-10">
                                            <span
                                                class="whiteSpace">{{reportData.cancel_bills.currency.cancelled}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Void Bills</div>
                                        <div class="pull-right d-block pr-10">
                                            <span
                                                class="whiteSpace">{{reportData.cancel_bills.currency.void}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pr-0 totalSales ml-5 pb-5">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">CANCELLED ITEMS</h6>
                            <div class="d-flex pb-4 flex-wrap">
                                <span
                                    v-if="reportData.cancel_items.count.cancelled + reportData.cancel_items.count.void <= 0"
                                    class="col-lg-6 col-md-6 col-sm-12 font-weight-bold text-center pt-18"
                                    style="box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;height:210px;">NO DATA</span>
                                <div v-else class="col-lg-6 col-md-6 col-sm-12"
                                    style="box-shadow:rgba(0, 0, 0, 0.13) 5px 0 0 0;">
                                    <div id="CancelledItemsNew"></div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 pl-5">
                                    <h6 class="text-white font-weight-bold">COUNT</h6>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Cancelled Items</div>
                                        <div class="pull-right d-block pr-10">
                                            <span class="whiteSpace">{{reportData.cancel_items.count.cancelled}}
                                                Items</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Void Items</div>
                                        <div class="pull-right d-block pr-10">
                                            <span class="whiteSpace">{{reportData.cancel_items.count.void}} Items</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">CURRENCY
                                            ({{reportData.currency.symbol || ''}})</div>
                                        <div class="pull-right d-block pr-10">
                                            <!-- <span class="whiteSpace">{{reportData.cancel_bills.count.void_bills}}</span> -->
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Cancelled Items</div>
                                        <div class="pull-right d-block pr-10">
                                            <span
                                                class="whiteSpace">{{reportData.cancel_items.currency.cancelled}}</span>
                                        </div>
                                    </div>
                                    <div class="bottomSubtotal"></div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="text-white font-weight-bold">Void Items</div>
                                        <div class="pull-right d-block pr-10">
                                            <span
                                                class="whiteSpace">{{reportData.cancel_items.currency.void}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row dashboard mx-3 mt-3">
                        <div class="col-lg-12 pl-0 pr-0">
                            <div class="personalInformation">
                                <h6 class="headerInformation font-weight-bold text-white d-block text-center">
                                    TRANSACTION BY
                                    DATE
                                </h6>
                                <div class="row">
                                    <div class="col-lg-12 p-8"
                                        v-show="reportData.orders_report.length || reportData.order_summary_data.length || reportData.top_selling_items.length">
                                        <div id="chartdiv" style="width: 100%; height: 320px;"></div>
                                    </div>
                                    <div class="col-lg-12 p-8 text-center"
                                        v-show="!reportData.orders_report.length &&!reportData.order_summary_data.length &&!reportData.top_selling_items.length">
                                        <span
                                            style="font-size:14px;color: #fff;font-weight:600;width: 100%; padding: 10px 60px;display:block;">No
                                            Data</span>
                                    </div>
                                    <!-- <div class="col-lg-6 p-10">
                    <div class="totalAmount">
                      Total Amount : $2020
                    </div>
                    <div class="totalOrders p-8">
                      <span class="defaultBlue font-weight-bold">Total Orders</span>
                      <div class="row mt-6">
                        <div class="col">
                          <div class="text-muted">Number Of Orders</div>
                          <div class="font-weight-bold">05</div>
                        </div>
                        <div class="col">
                          <div class="text-muted">Tax Amount</div>
                          <div class="font-weight-bold">$20</div>
                        </div>
                      </div>
                      <div class="row mt-6">
                        <div class="col">
                          <div class="text-muted">Net Amount</div>
                          <div class="font-weight-bold">$2000</div>
                        </div>
                      </div>
                    </div>
                  </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="print-dash page3">

                    <div class="d-flex mx-3 mt-3 pr-5 flexWrap">
                        <div class="col-lg-7 pr-0 totalSales">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">TRANSACTIONS BY TIME</h6>
                            <h6 v-if="isTransactionDataEmpty"
                                class="d-flex justify-content-center fs-14 font-weight-bold"
                                style="position:relative;max-height: 210px;">NO DATA</h6>
                            <div v-show="!isTransactionDataEmpty" id="chartSalesTime"></div>
                        </div>
                        <div class="col-lg-5 pr-0 totalSales ml-5">
                            <h6 class="text-white font-weight-bold m-5 d-block text-center">TRANSACTIONS BY DAYS</h6>
                            <h6 v-if="isTransactionDataEmpty"
                                class="d-flex justify-content-center fs-14 font-weight-bold"
                                style="position:relative;max-height: 210px;">NO DATA</h6>
                            <div v-show="!isTransactionDataEmpty" id="chartSalesDays"></div>
                        </div>
                    </div>
                    <div class="d-flex mt-8 mx-3 flexWrap"
                        style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);background-color: #005dae;border-radius: 7.5px;">
                        <div class="col-md-8 pl-0 col-lg-8 col-sm-12 topSellAlign"
                            style="padding-left: 8px!important;padding-top: 8px;padding-right: 8px;">
                            <div class="reports-label-top" style="margin-top: -25px;">TOP SELLING PRODUCTS</div>
                            <!-- <h6 class="defaultBlue font-weight-bold d-block text-center">TOP SELLING PRODUCTS</h6> -->
                            <div class="sellingProducts">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>PRODUCT NAME</th>
                                            <!-- <th>VARIANTS</th> -->
                                            <th>SOLD COUNT</th>
                                            <th>REVENUE GENERATED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="top_selling_item in reportData.top_selling_items"
                                            :key="top_selling_item._id">
                                            <td style="padding: 0 12px!important;">{{top_selling_item.product_name}}
                                            </td>
                                            <!-- <td style="padding: 0 12px!important;">
                                                {{top_selling_item.variant_name || '-'}}</td> -->
                                            <td style="padding: 0 12px!important;">
                                                {{top_selling_item.item_count}}</td>
                                            <td style="padding: 0 12px!important;">
                                                {{top_selling_item.revenue_generated || 0}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 v-if="!reportData.top_selling_items.length"
                                    class="d-flex justify-content-center fs-16"
                                    style="position:relative;top:40%;font-weight:600;">NO DATA</h6>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12 pr-0 screenLeft top-right-div" v-show="topSellingMenu">
                            <div class="allProducts">
                                <h6 class="pl-5 pt-5 font-weight-bold">ALL PRODUCTS</h6>
                                <div class="productBottom mb-2"></div>
                                <span class="pl-5 fs-16 font-weight-bold d-block">
                                    {{topSellingMenu.all_products.sold_items}}/{{topSellingMenu.all_products.total_products}}
                                    Items
                                </span>
                                <span class="pl-5 fs-16 font-weight-bold d-block mt-2">
                                    {{topSellingMenu.all_products.percentage}}%
                                    of Menu
                                </span>
                            </div>
                            <div class="topCategory mt-2">
                                <h6 class="pl-5 pt-5 font-weight-bold">TOP CATEGORY SALE</h6>
                                <div class="productBottom mb-2"></div>
                                <span
                                    class="pl-5 fs-16 font-weight-bold d-block">{{topSellingMenu.top_category_sale.name}}</span>
                                <span class="pl-5 fs-16 font-weight-bold d-block mt-2">
                                    {{topSellingMenu.top_category_sale.percentage}}%
                                    of Categories
                                </span>
                            </div>
                            <div class="topProduct mt-2">
                                <h6 class="pl-5 pt-5 font-weight-bold">TOP PRODUCT SALE</h6>
                                <div class="productBottom mb-2"></div>
                                <span
                                    class="pl-5 fs-16 font-weight-bold d-block">{{topSellingMenu.top_selling_product.product_name}}</span>
                                <span class="pl-5 fs-16 font-weight-bold d-block mt-2">
                                    {{topSellingMenu.top_selling_product.percentage}}%
                                    of Products
                                </span>
                            </div>
                            <div class="topVariant mt-2">
                                <h6 class="pl-5 pt-5 font-weight-bold">TOP VARIANT SALE</h6>
                                <div class="productBottom mb-2"></div>
                                <span
                                    class="pl-5 fs-16 font-weight-bold d-block">{{topSellingMenu.top_selling_variant.name}}</span>
                                <span class="pl-5 fs-16 font-weight-bold d-block mt-2">
                                    {{topSellingMenu.top_selling_variant.percentage}}%
                                    of Variants
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-8 flexWrap screenAlign blockAlign" style="
                  background-color: #005dae;
                  border-radius: 7px;margin-right: 7px;margin-left: 7px;">
                        <div class="col-lg-12">
                            <div class="customerProduct">
                                <div class="d-flex">
                                    <h6 class="text-white font-weight-bold m-5 d-block text-center col-md-8">CUSTOMERS
                                    </h6>
                                    <h6 class="text-white font-weight-bold m-5 d-block text-center col-md-3">PAYMENT
                                        SUMMARY</h6>
                                </div>
                                <div class="d-flex flexWrap blockAlign">
                                    <div class="allCustomers col-md-4 col-lg-4 col-sm-12 blockContent">
                                        <h6 class="pl-5 pt-5 font-weight-bold text-white">ALL CUSTOMERS</h6>
                                        <div class="tableBottom mb-2"></div>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            Total :
                                            {{customerReport.all_customers.total_customers}}
                                        </span>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            Average / Day :
                                            {{customerReport.all_customers.average_customer_per_day}}
                                            <span
                                                v-if="customerReport.all_customers.average_customer_per_day === 1">Customer</span>
                                            <span v-else>Customers</span>
                                        </span>
                                    </div>
                                    <div class="allCustomers ml-4 col-md-4 col-lg-4 col-sm-12 blockContent">
                                        <h6 class="pl-5 pt-5 font-weight-bold text-white">AVERAGE SPENDING
                                            ({{reportData.currency.symbol || ''}})</h6>
                                        <div class="tableBottom mb-2"></div>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            {{customerReport.average_spending.average_spending_per_customer}}
                                            /
                                            Customer
                                        </span>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            Average / Table :
                                            {{customerReport.average_spending.average_spending_per_table}}
                                        </span>
                                    </div>

                                    <div class="col-md-4 col-lg-4 col-sm-12 pl-0 paymentAlign paymentContent">
                                        <!-- <span class="defaultBlue font-weight-bold">PAYMENT SUMMARY</span>
                    <div class="sellingProducts">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>PAYMENT METHOD</th>
                            <th>PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="payment in reportData.payment_summary" :key="payment._id">
                            <td style="padding: 0 12px!important;">{{payment._id}}</td>
                            <td style="padding: 0 12px!important;">{{formatPrice(payment.amount)}}</td>
                          </tr>
                          <tr v-if="!reportData.payment_summary.length" class="text-center">
                            <td colspan="2" style="font-size:14px;color: #303031;font-weight:600;">No Payment Summary</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>-->
                                        <!-- <h6 class="text-white font-weight-bold m-5 d-block text-center">PAYMENT SUMMARY</h6> -->
                                        <div class="allPayment ml-4 pr-4 pl-2">
                                            <h6 class="pl-5 pt-5 font-weight-bold text-white">PAYMENT TYPE
                                                ({{reportData.currency.symbol || ''}})</h6>
                                            <div class="tableBottom mb-2"></div>
                                            <span class="pl-5 fs-16 d-block mb-5 text-white font-600">Cash :
                                                {{paymentSummary.cash}}</span>
                                            <span class="pl-5 fs-16 d-block mb-5 text-white font-600">Card :
                                                {{paymentSummary.card}}</span>
                                            <span class="pl-5 fs-16 d-block mb-5 text-white font-600">Others :
                                                {{paymentSummary.others}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-8 screenAlign" style="
              background-color: #005dae;
              border-radius: 7px;margin-right: 7px;margin-left: 7px;">
                        <div class="col-lg-12 customerMargin">
                            <div class="customerProduct">
                                <h6 class="text-white font-weight-bold m-5 d-block text-center">TABLES</h6>
                                <div class="d-flex flexWrap">
                                    <div class="allCustomers col-md-4 col-lg-4 col-sm-12 blockContent">
                                        <h6 class="pl-5 pt-5 font-weight-bold text-white">TABLE USAGE</h6>
                                        <div class="tableBottom mb-2"></div>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            {{tablesReport.table_usage.table_per_day}}
                                            <span v-if="tablesReport.table_usage.table_per_day === 1">Table</span>
                                            <span v-else>Tables</span>
                                            / Day
                                        </span>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            MAX Usage :
                                            {{tablesReport.table_usage.max_table_per_day}}
                                            <span v-if="tablesReport.table_usage.max_table_per_day === 1">Table</span>
                                            <span v-else>Tables</span>
                                            / Day
                                        </span>
                                    </div>
                                    <div class="allCustomers ml-4 col-md-4 col-lg-4 col-sm-12 blockContent">
                                        <h6 class="pl-5 pt-5 font-weight-bold text-white">AVERAGE TIME PER TABLE</h6>
                                        <div class="tableBottom mb-2"></div>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            {{tablesReport.average_time.average_time_per_table}}
                                            <span
                                                v-if="tablesReport.average_time.average_time_per_table === 1">Hour</span>
                                            <span v-else>Hours</span>
                                            / Table
                                        </span>
                                        <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                            MAX Hour :
                                            {{tablesReport.average_time.max_hour_per_table}}
                                            <span v-if="tablesReport.average_time.max_hour_per_table === 1">Hour</span>
                                            <span v-else>Hours</span>
                                            / Table
                                        </span>
                                    </div>
                                    <div class="col-md-4 col-lg-4 col-sm-12 pl-0 paymentAlign paymentContent">
                                        <div class="customerProduct">
                                            <div class="allPayment ml-4 pr-4 pl-2">
                                                <h6 class="pl-5 pt-5 font-weight-bold text-white">AVERAGE ITEMS PER
                                                    TABLE</h6>
                                                <div class="tableBottom mb-2"></div>
                                                <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                                    {{tablesReport.average_items_per_table.average_items_per_table}}
                                                    <span
                                                        v-if="tablesReport.average_items_per_table.average_items_per_table === 1">Item</span>
                                                    <span v-else>Items</span>
                                                    / Table
                                                </span>
                                                <span class="pl-5 fs-16 d-block mt-5 text-white font-600">
                                                    MAX Items :
                                                    {{tablesReport.average_items_per_table.max_item_per_table}}
                                                    <span
                                                        v-if="tablesReport.average_items_per_table.max_item_per_table === 1">Item</span>
                                                    <span v-else>Items</span>
                                                    / Table
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="allCustomers ml-4 col-md-5 col-lg-3 col-sm-12 TableblockContent">
                    <h6 class="pl-5 pt-5 font-weight-bold text-secondary">AVERAGE ORDERS</h6>
                    <div class="tableBottom mb-2"></div>
                    <span class="pl-5 fs-16 d-block mt-5">
                      {{tablesReport.average_orders_per_table.average_orders_per_table}}
                      <span v-if="tablesReport.average_orders_per_table.average_orders_per_table === 1">
                        Order
                      </span>
                      <span v-else>
                        Orders
                      </span>
                      / Table
                    </span>
                    <span class="pl-5 fs-16 d-block mt-5">
                      MAX Order :
                      {{tablesReport.average_orders_per_table.max_order_per_table}}
                      <span v-if="tablesReport.average_orders_per_table.max_order_per_table === 1">
                        Order
                      </span>
                      <span v-else>
                        Orders
                      </span>
                      / Table
                    </span>
                  </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import DatePicker from "vue2-datepicker";
    import vSelect from "vue-select";
    import jsPDF from 'jspdf';
    import html2canvas from "html2canvas";
    import {
        HalfCircleSpinner
    } from 'epic-spinners'
    export default {
        data() {
            const value2date = (dateRange) => {
                const date = new Date(dateRange)
                return dateRange ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
            }
            const date2value = (date) => {
                return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
            }
            return {
                valueType: {
                    value2date,
                    date2value
                },
                convertUTC: false,
                mobile: false,
                options: [],
                selected: {
                    _id: null,
                    name: "All Outlets"
                },
                isTransactionDataEmpty: true,
                reportData: {
                    order_summary_data: [],
                    orders_report: [],
                    payment_summary: [],
                    top_selling_items: [],
                    outlets: [],
                    currency: {
                        name: null,
                        symbol: null
                    },
                    category_sales: [],
                    close_bills: {
                        take_away: {
                            value: 0,
                            count: 0
                        },
                        dine_in: {
                            value: 0,
                            count: 0
                        },
                        total: {
                            value: 0,
                            count: 0
                        }
                    },
                    cancel_bills: {
                        count: {
                            cancelled: 0,
                            void: 0
                        },
                        currency: {
                            cancelled: 0,
                            void: 0
                        }
                    },
                    cancel_items: {
                        count: {
                            cancelled: 0,
                            void: 0
                        },
                        currency: {
                            cancelled: 0,
                            void: 0
                        }
                    }
                },
                report_counts: {
                    total_no_of_orders: 0,
                    gross_income: 0,
                    avg_value_of_order: 0,
                    customers_count: 0,
                    avg_revenue_per_customer: 0
                },
                chartData: [],
                startDate: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                ),
                endDate: new Date(),
                dateRange: [],
                lang: {
                    type: "en",
                    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    months: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    pickers: [
                        "next 7 days",
                        "next 30 days",
                        "previous 7 days",
                        "previous 30 days"
                    ],
                    placeholder: {
                        date: "Select Date",
                        dateRange: "Select Date Range"
                    }
                },
                confirmText: {
                    type: String,
                    default: "APPLY"
                },
                shortcuts: [{
                        text: "Today",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate()
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: "This Week",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - new Date().getDay()
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: "This Month",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    },
                    {
                        text: "This Year",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                0,
                                1
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time
                                    .split(":")[0], this.loggedInUser.business_day_start_time.split(":")[1]
                                    ), this.endDate
                            ]
                        }
                    }
                ],
                dataProviderTransactionTime: [],
                dataProviderTransactionDays: [],
                topSellingMenu: {
                    all_products: {
                        sold_items: 0,
                        total_products: 0,
                        percentage: 0
                    },
                    top_category_sale: {
                        name: "",
                        percentage: 0
                    },
                    top_selling_product: {
                        product_name: "",
                        percentage: 0
                    },
                    top_selling_variant: {
                        name: "",
                        percentage: 0
                    }
                },
                tablesReport: {
                    table_usage: {
                        table_per_day: 0,
                        max_table_per_day: 0
                    },
                    average_time: {
                        average_time_per_table: 0,
                        max_hour_per_table: 0
                    },
                    average_items_per_table: {
                        average_items_per_table: 0,
                        max_item_per_table: 0
                    },
                    average_orders_per_table: {
                        average_orders_per_table: 0,
                        max_order_per_table: 0
                    }
                },
                customerReport: {
                    all_customers: {
                        total_customers: 0,
                        average_customer_per_day: 0
                    },
                    average_spending: {
                        average_spending_per_customer: 0,
                        average_spending_per_table: 0
                    }
                },
                paymentSummary: {
                    cash: 0,
                    card: 0,
                    others: 0
                },
                transactionByDays: [],
                transactionByTime: [],
                closed_transaction_empty: false,
                cancelled_bills_empty: false,
                total_sales_report: {
                    sub_total: 0,
                    total_sales: 0,
                    discount_amount: 0,
                    service_charge: 0,
                    total_round_off: 0,
                    VAT: 0
                },
                salesLoading: false,
                closed_transactionLoading: false,
                cancel_billsLoading: false,
                pdf: '',
                page_section: '',
                HTML_Width: '',
                HTML_Height: '',
                top_left_margin: '',
                PDF_Width: '',
                PDF_Height: '',
                canvas_image_width: '',
                canvas_image_height: '',
                email_report: null,
                show_download_report: false,
                is_email_report_api_call: false
            };

        },
        components: {
            DatePicker,
            vSelect,
            HalfCircleSpinner
        },

        methods: {
            calculatePDF_height_width(selector, index) {
                var page_section = $(selector).eq(index);
                var HTML_Width = page_section.width();
                var HTML_Height = page_section.height();
                var top_left_margin = 15;
                var PDF_Width = HTML_Width + (top_left_margin * 2);
                var PDF_Height = (PDF_Width * 1.2) + (top_left_margin * 2);
                var canvas_image_width = HTML_Width;
                var canvas_image_height = HTML_Height;
                //this.pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
                return {
                    page_section,
                    HTML_Width,
                    HTML_Height,
                    top_left_margin,
                    PDF_Width,
                    PDF_Height,
                    canvas_image_width,
                    canvas_image_height
                }
            },
            generatePDF() {
                var vueThis = this;
                vueThis.pdf = "";
                $(".blockdiv").show();
                $(".loaderdiv").show();
                $('.print-dash').css("background-color", "#00448b")
                html2canvas($(".print-dash:eq(0)")[0], {
                    allowTaint: true,
                    useCORS: true
                }).then(function (canvas) {
                    let resp = vueThis.calculatePDF_height_width(".print-dash", 0);
                    var imgData = canvas.toDataURL("image/jpeg", 1.0);
                    // vueThis.pdf.addPage(imgData.PDF_Width, imgData.PDF_Height);
                    vueThis.pdf = new jsPDF('p', 'pt', [resp.PDF_Width, resp.PDF_Height]);
                    vueThis.pdf.addImage(imgData, 'JPG', resp.top_left_margin, resp.top_left_margin, resp
                        .PDF_Width, resp.HTML_Height);
                });

                html2canvas($(".print-dash:eq(1)")[0], {
                    allowTaint: true,
                    useCORS: true
                }).then(function (canvas) {
                    let resp = vueThis.calculatePDF_height_width(".print-dash", 1);
                    var imgData = canvas.toDataURL("image/jpeg", 1.0);
                    vueThis.pdf.addPage(resp.PDF_Width, resp.PDF_Height);
                    vueThis.pdf.addImage(imgData, 'JPG', resp.top_left_margin, resp.top_left_margin, resp
                        .PDF_Width, resp.HTML_Height);
                });

                html2canvas($(".print-dash:eq(2)")[0], {
                    allowTaint: true,
                    useCORS: true
                }).then(function (canvas) {
                    let resp = vueThis.calculatePDF_height_width(".print-dash", 2);
                    var imgData = canvas.toDataURL("image/jpeg", 1.0);
                    vueThis.pdf.addPage(resp.PDF_Width, resp.PDF_Height);
                    vueThis.pdf.addImage(imgData, 'JPG', resp.top_left_margin, resp.top_left_margin, resp
                        .PDF_Width, resp.HTML_Height);
                    //console.log((vueThis.page_section.length-1)+"==="+index);
                    setTimeout(function () {

                        //Save PDF Doc
                        vueThis.pdf.save("Dashboard-Aggregate.pdf");

                        //Generate BLOB object
                        //var blob = vueThis.pdf.output("blob");

                        //Getting URL of blob object
                        //var blobURL = URL.createObjectURL(blob);

                        //Showing PDF generated in iFrame element
                        //var iframe = document.getElementById('sample-pdf');
                        //iframe.src = blobURL;

                        //Setting download link
                        //var downloadLink = document.getElementById('pdf-download-link');
                        //downloadLink.href = blobURL;
                        //$(".pdf-download-link").show();

                        //$("#sample-pdf").slideDown();
                        //$("#downloadbtn").show();
                        //$("#genmsg").hide();
                        $(".blockdiv").hide();
                        $(".loaderdiv").hide();
                        $('.print-dash').css("background-color", "transparent")
                    }, 0);
                });
            },
            // downloadPdf() {

            //     var HTML_Width = $(".canvas_div_pdf").width();
            //     var HTML_Height = $(".canvas_div_pdf").height();
            //     var top_left_margin = 15;
            //     var PDF_Width = HTML_Width+(top_left_margin*2);
            //     var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
            //     var canvas_image_width = HTML_Width;
            //     var canvas_image_height = HTML_Height;

            //     var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;


            //   html2canvas($(".canvas_div_pdf")[0],{allowTaint:true, useCORS : true}).then(function(canvas) {

            //     canvas.getContext('2d');

            //     console.log(canvas.height+"  "+canvas.width);

            //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
            //     var pdf = new jsPDF('p', 'pt', 'a4'  [PDF_Width, PDF_Height]);
            //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

            //     for (var i = 1; i <= totalPDFPages; i++) {
            //       pdf.addPage(PDF_Width, PDF_Height);
            //       pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
            //     }
            //     pdf.save("report.pdf");
            //   });
            // },

            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(",", ".");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            reset() {
                let startDate = new Date();
                startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0], this.loggedInUser
                    .business_day_start_time.split(":")[1]);
                let endDate = new Date();
                this.dateRange = [startDate, endDate];
                this.selected = {
                    '_id': null,
                    'name': 'All Outlets'
                };
                this.onDateChange();
                this.convertUTC = false;
            },
            /**
             * This method or function is used to call all the methods for fetching the data
             * when date change detected from the date picker
             */
            onDateChange() {
                this.convertUTC = true;
                this.closed_transaction_empty = false;
                this.cancelled_bills_empty = false;
                this.endDate;
                this.dateRange[1];
                if (!this.dateRange[0] && !this.dateRange[1]) {
                    this.startDate = new Date();
                    this.startDate;
                    this.endDate = new Date();
                    this.dateRange = [this.startDate, this.endDate];
                }
                this.get_form_data();
                this.getTopSellingMenuData();
                this.getTablesReport();
                this.getPaymentSummary();
                this.getCustomerReport();
                this.getTransactionReport();
                this.getTotalSalesReport();
            },
            onSearch(search, loading) {
                loading(true);
                this.search(loading, search, this);
            },
            search(loading, search, vm) {
                this.$http
                    .post("/outlet/get", {
                        search_key: search
                    })
                    .then(res => {
                        vm.options = res.data.outlets;
                        loading(false);
                    });
            },
            /**
             * This method or function is used to fetch the data for chart
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            get_form_data() {
                this.closed_transactionLoading = true;
                const details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http.post("reports/", details).then(res => {
                    if (res.data.status_id === 1) {
                        this.resetChartData();
                        this.reportData = res.data;
                        if (this.reportData.orders_report.length) {
                            this.report_counts[
                                "total_no_of_orders"
                            ] = this.reportData.orders_report[0].total_no_of_orders;
                            this.report_counts[
                                "gross_income"
                            ] = this.reportData.orders_report[0].gross_income
                            this.report_counts[
                                "avg_value_of_order"
                            ] = this.reportData.orders_report[0].avg_value_of_order
                        }
                        if (this.reportData.customers_reports.length) {
                            this.report_counts[
                                "customers_count"
                            ] = this.reportData.customers_reports[0].customers_count;
                            this.report_counts[
                                "avg_revenue_per_customer"
                            ] = this.reportData.customers_reports[0].avg_revenue_per_customer
                        }
                        if (this.reportData.order_summary_data.length) {
                            this.chartData = this.reportData.order_summary_data;
                            for (let index in this.chartData) {
                                this.chartData[index]["date"] = new Date(
                                    this.chartData[index]._id.substring(0, 4),
                                    Number(
                                        this.chartData[index]._id.substring(5, 7)
                                    ) - 1,
                                    this.chartData[index]._id.substring(8, 10)
                                );

                                delete this.chartData[index]._id;
                            }

                            function predicateBy(prop) {
                                return function (a, b) {
                                    if (a[prop] > b[prop]) {
                                        return 1;
                                    } else if (a[prop] < b[prop]) {
                                        return -1;
                                    }
                                    return 0;
                                };
                            }
                            this.chartData.sort(predicateBy("date"));
                        }
                        if (this.reportData.close_bills.total.count === 0) {
                            this.closed_transaction_empty = true;
                        }
                        if (this.reportData.cancel_bills.bills_count === 0) {
                            this.cancelled_bills_empty = true;
                        }
                    }
                    this.closed_transactionLoading = false;
                }).then(res => {
                    this.amcharts();
                    this.loadPieCharts();
                    this.loadAmountCharts();
                    this.loadTotalSalesChart();
                    this.loadSalesBreakdown();
                    this.loadCancelledBills();
                    this.loadCancelledItems();
                });
            },
            loadTotalSalesChart() {
                am4core.useTheme(am4themes_dark);
                am4core.useTheme(am4themes_animated);
                // Create chart instance
                const chart = am4core.create("chartSales", am4charts.RadarChart);
                let total =
                    this.paymentSummary.others_without_currency || 0 +
                    this.paymentSummary.card_without_currency || 0 +
                    this.paymentSummary.cash_without_currency || 0;
                    console.log(total)
                // Add data
                chart.data = [{
                        // category: "Others" + ' ' + ':' + ' ' + ((parseFloat((this.paymentSummary.others_without_currency || 0) / total) *
                        //     100).toFixed(2) + "%"),
                         category: "Others" + ' ' + ':' + ' ' + ((parseFloat((this.paymentSummary.others_without_currency) || 0)).toFixed(2)),
                        value: ((this.paymentSummary.others_without_currency || 0) / total) * 100,
                        full: 100,
                        //  "config": {
                        //     "fill": "#FFF"
                        //   }
                    },
                    {
                        // category: "Card" + ' ' + ':' + ' ' + ((parseFloat((this.paymentSummary.card_without_currency || 0) / total) * 100)
                        //     .toFixed(2) + "%"),
                        category: "Card" + ' ' + ':' + ' ' + ((parseFloat(this.paymentSummary.card_without_currency || 0)).toFixed(2)),
                        value: (this.paymentSummary.card_without_currency || 0 / total) * 100,
                        full: 100,
                        // "config": {
                        //     "fill": "#FFF"
                        //   }
                    },
                    {
                        // category: "Cash" + ' ' + ':' + ' ' + ((parseFloat((this.paymentSummary.cash_without_currency || 0) / total) * 100)
                        //     .toFixed(2) + "%"),
                        category: "Cash" + ' ' + ':' + ' ' + ((parseFloat(this.paymentSummary.cash_without_currency || 0)).toFixed(2)),
                        value: (this.paymentSummary.cash_without_currency || 0 / total) * 100,
                        full: 100,
                        // "config": {
                        //     "fill": "#FFF"
                        //   }
                    }
                ];

                // Make chart not full circle
                chart.startAngle = -90;
                chart.endAngle = 180;
                chart.innerRadius = am4core.percent(20);
                chart.colors.list = [
                    am4core.color("#24c8f0"),
                    am4core.color("#ffa000"),
                    am4core.color("#2ebd61"),
                ];
                // Set number format
                chart.numberFormatter.numberFormat = "#.#'%'";

                // Create axes
                const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "category";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.grid.template.strokeOpacity = 0;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";
                categoryAxis.renderer.labels.template.fontWeight = 500;
                categoryAxis.renderer.labels.template.fontSize = 13;
                categoryAxis.renderer.labels.template.adapter.add("fill", function (
                    fill,
                    target
                ) {
                    return target.dataItem.index >= 0 ?
                        chart.colors.getIndex(target.dataItem.index) :
                        fill;
                });
                categoryAxis.renderer.minGridDistance = 10;

                const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.grid.template.strokeOpacity = 0;
                valueAxis.min = 0;
                valueAxis.max = 100;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.labels.template.fontSize = 13;

                // Create series
                const series1 = chart.series.push(new am4charts.RadarColumnSeries());
                series1.dataFields.valueX = "full";
                series1.dataFields.categoryY = "category";
                series1.clustered = false;
                series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
                    "alternativeBackground"
                );
                series1.columns.template.fillOpacity = 0.08;
                series1.columns.template.cornerRadiusTopLeft = 20;
                series1.columns.template.strokeWidth = 0;
                series1.columns.template.radarColumn.cornerRadius = 20;

                const series2 = chart.series.push(new am4charts.RadarColumnSeries());
                series2.dataFields.valueX = "value";
                series2.dataFields.categoryY = "category";
                series2.clustered = false;
                series2.columns.template.strokeWidth = 0;
                series2.columns.template.tooltipText =
                    // "{category}: [bold]{value}[/]";
                    "[bold]{category}";
                series2.columns.template.radarColumn.cornerRadius = 20;

                series2.columns.template.adapter.add("fill", function (
                    fill,
                    target
                ) {
                    return chart.colors.getIndex(target.dataItem.index);
                });

                // Add cursor
                chart.cursor = new am4charts.RadarCursor();
                am4core.options.autoSetClassName = true;
            },
            loadPieCharts() {
                // Closed Chart
                var chart = AmCharts.makeChart("chartBills", {
                    type: "pie",
                    theme: "black",
                    radius: 60,
                    fontSize: 12,
                    startDuration: 0,
                    autoResize: true,
                    labelRadius: '2',
                    marginRight: 90,
                    marginTop: 90,
                    marginLeft: 10,
                    fontFamily: "'Lato', 'Helvetica Neue', sans-serif",
                    color: "#fff",
                    dataProvider: [{
                            "dine-in": "Take-Away-Count",
                            litres: this.reportData.close_bills.take_away.count,
                            color: "#fff"
                        },
                        {
                            "dine-in": "Dine-In-Count",
                            litres: this.reportData.close_bills.dine_in.count,
                            color: "#fff"
                        }
                    ],
                    guides: [{
                        fillAlpha: 0.1,
                        value: 0,
                        toValue: -3,
                        lineColor: "#fff",
                        lineAlpha: 2,
                        fillAlpha: 0.2,
                        fillColor: "#fff",
                        inside: true
                    }],
                    valueField: "litres",
                    titleField: "dine-in",
                    balloon: {
                        fixedPosition: false
                    },
                    colors: ["#2ebd61", "#f5a623"],
                    export: {
                        enabled: false
                    }
                });
                //Cancel Bills
                var chart = AmCharts.makeChart("cancelBills", {
                    type: "pie",
                    theme: "black",
                    radius: 60,
                    fontSize: 12,
                    startDuration: 0,
                    autoResize: true,
                    labelRadius: '2',
                    marginRight: 90,
                    marginTop: 90,
                    marginLeft: 10,
                    fontFamily: "'Lato', 'Helvetica Neue', sans-serif",
                    color: "#fff",
                    dataProvider: [{
                            "dine-in": "Take-Away",
                            litres: this.reportData.cancel_bills.take_away_count,
                            color: "#fff"
                        },
                        {
                            "dine-in": "Dine-In",
                            litres: this.reportData.cancel_bills.dine_in_count,
                            color: "#fff"
                        }
                    ],
                    valueField: "litres",
                    titleField: "dine-in",
                    balloon: {
                        fixedPosition: true
                    },
                    "legend": {
                        color: "#fff"
                    },
                    colors: ["#25b2ce", "#fbbf5b"],
                    export: {
                        enabled: false
                    }
                });
            },
            loadSalesBreakdown() {
                var chart = AmCharts.makeChart("salesBreakdown", {
                    type: "pie",
                    theme: "black",
                    radius: 80,
                    fontSize: 12,
                    startDuration: 0,
                    autoResize: true,
                    labelRadius: '2',
                    marginRight: 90,
                    marginTop: 20,
                    marginLeft: 10,
                    color: "#fff",
                    fontFamily: "'Lato', 'Helvetica Neue', sans-serif",
                    "legend": {
                        "position": "bottom",
                        "marginRight": 100,
                        "autoMargins": false,
                        "markerType": "circle",
                        "markerLabelGap": 20,
                        "fill": "#fff",
                        color: "#fff"
                    },
                    dataProvider: this.reportData.category_sales,
                    guides: [{
                        fillAlpha: 0.1,
                        value: 0,
                        toValue: -3,
                        lineColor: "#fff",
                        lineAlpha: 2,
                        fillAlpha: 0.2,
                        fillColor: "#fff",
                        inside: true
                    }],
                    valueField: "total_without_currency",
                    titleField: "name",
                    balloon: {
                        fixedPosition: true
                    },
                    colors: ["#39d06e", "#ff5b41", "#ffd5eb", "#fcbb50"],
                    export: {
                        enabled: false
                    }
                });
            },
            loadCancelledBills() {
                let vuethis = this
                am4core.ready(function () {
                    // Themes begin
                    am4core.useTheme(am4themes_dark);
                    am4core.useTheme(am4themes_animated);
                    // Themes end
                    // Create chart instance
                    var chart = am4core.create("newCancelledBills", am4charts.XYChart);
                    // chart.scrollbarX = new am4core.Scrollbar();
                    // Add data
                    chart.colors.list = [
                        am4core.color("#b8dcfd"),
                        am4core.color("#ec3710"),
                    ];
                    chart.data = [{
                        "bills": "Bills Cancelled",
                        "total": vuethis.reportData.cancel_bills.currency.cancelled,
                    }, {
                        "bills": "Bills Voided",
                        "total": vuethis.reportData.cancel_bills.currency.void,
                    }];
                    let label = chart.createChild(am4core.Label);
                    // Create axes
                    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "bills";
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.minGridDistance = 30;
                    categoryAxis.renderer.labels.template.horizontalCenter = "right";
                    categoryAxis.renderer.labels.template.verticalCenter = "middle";
                    categoryAxis.renderer.labels.template.rotation = 270;
                    categoryAxis.tooltip.disabled = true;
                    categoryAxis.renderer.minHeight = 110;
                    categoryAxis.renderer.labels.template.fontSize = 13;
                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.minWidth = 50;
                    // Create series
                    var series = chart.series.push(new am4charts.ColumnSeries());
                    series.sequencedInterpolation = true;
                    series.dataFields.valueY = "total";
                    series.dataFields.categoryX = "bills";
                    valueAxis.title.text = "Currency" + "(" + vuethis.reportData.currency.symbol + ")";
                    valueAxis.renderer.labels.template.fontSize = 13;
                    //series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
                    series.columns.template.strokeWidth = 0;
                    series.tooltip.pointerOrientation = "vertical";
                    series.columns.template.column.cornerRadiusTopLeft = 10;
                    series.columns.template.column.cornerRadiusTopRight = 10;
                    series.columns.template.column.fillOpacity = 1;
                    // on hover, make corner radiuses bigger
                    // var hoverState = series.columns.template.column.states.create("hover");
                    // hoverState.properties.cornerRadiusTopLeft = 0;
                    // hoverState.properties.cornerRadiusTopRight = 0;
                    // hoverState.properties.fillOpacity = 1;
                    series.columns.template.adapter.add("fill", function (fill, target) {
                        return chart.colors.getIndex(target.dataItem.index);
                    });

                    // Cursor
                    chart.cursor = new am4charts.XYCursor();
                    //chart.legend = new am4charts.Legend();

                });
            },

            loadCancelledItems() {
                let vuethis = this
                am4core.ready(function () {
                    // Themes begin
                    am4core.useTheme(am4themes_dark);
                    am4core.useTheme(am4themes_animated);
                    // Themes end
                    // Create chart instance
                    var chart = am4core.create("CancelledItemsNew", am4charts.XYChart);
                    // chart.scrollbarX = new am4core.Scrollbar();
                    // Add data
                    chart.colors.list = [
                        am4core.color("#3e9f5b"),
                        am4core.color("#f6a929")
                    ];
                    chart.data = [{
                        "bills": "Items Cancelled",
                        "total": vuethis.reportData.cancel_items.currency.cancelled,
                    }, {
                        "bills": "Items Voided",
                        "total": vuethis.reportData.cancel_items.currency.void,
                    }];
                    // Create axes
                    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "bills";
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.minGridDistance = 30;
                    categoryAxis.renderer.labels.template.horizontalCenter = "right";
                    categoryAxis.renderer.labels.template.verticalCenter = "middle";
                    categoryAxis.renderer.labels.template.rotation = 270;
                    categoryAxis.renderer.labels.template.fontSize = 13;
                    categoryAxis.tooltip.disabled = true;
                    categoryAxis.renderer.minHeight = 110;
                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.minWidth = 50;
                    valueAxis.renderer.labels.template.fontSize = 13;
                    // Create series
                    var series = chart.series.push(new am4charts.ColumnSeries());
                    series.sequencedInterpolation = true;
                    series.dataFields.valueY = "total";
                    series.dataFields.categoryX = "bills";
                    valueAxis.title.text = "Currency" + "(" + vuethis.reportData.currency.symbol + ")";
                    series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
                    series.columns.template.strokeWidth = 0;
                    series.tooltip.pointerOrientation = "vertical";
                    series.columns.template.column.cornerRadiusTopLeft = 10;
                    series.columns.template.column.cornerRadiusTopRight = 10;
                    series.columns.template.column.fillOpacity = 1;
                    // on hover, make corner radiuses bigger
                    // var hoverState = series.columns.template.column.states.create("hover");
                    // hoverState.properties.cornerRadiusTopLeft = 0;
                    // hoverState.properties.cornerRadiusTopRight = 0;
                    // hoverState.properties.fillOpacity = 1;

                    series.columns.template.adapter.add("fill", function (fill, target) {
                        return chart.colors.getIndex(target.dataItem.index);
                    });

                    // Cursor
                    chart.cursor = new am4charts.XYCursor();
                    //chart.legend = new am4charts.Legend();

                });
            },
            loadAmountCharts() {
                // Closed Chart
                var chart = AmCharts.makeChart("amountBills", {
                    type: "pie",
                    theme: "black",
                    radius: 60,
                    fontSize: 12,
                    startDuration: 0,
                    autoResize: true,
                    labelRadius: 3,
                    marginRight: 30,
                    marginLeft: 0,
                    fontFamily: "'Lato', 'Helvetica Neue', sans-serif",
                    color: "#fff",
                    dataProvider: [{
                            "dine-in": "Take-Away-Value",
                            litres: this.reportData.close_bills.take_away.value,
                            color: "#fff"
                        },
                        {
                            "dine-in": "Dine-In-Value",
                            litres: this.reportData.close_bills.dine_in.value,
                            color: "#fff"
                        }
                    ],
                    guides: [{
                        fillAlpha: 0.1,
                        value: 0,
                        toValue: -3,
                        lineColor: "#fff",
                        lineAlpha: 2,
                        fillAlpha: 0.2,
                        fillColor: "#fff",
                        inside: true
                    }],
                    valueField: "litres",
                    titleField: "dine-in",
                    balloon: {
                        fixedPosition: false
                    },
                    colors: ["#b26bde", "#00A2C1"],
                    export: {
                        enabled: false
                    }
                });
            },
            amcharts() {
                const chart = AmCharts.makeChart("chartdiv", {
                    type: "stock",
                    theme: "black",
                    color: "#fff",
                    dataSets: [{
                        color: "#2ebd61",
                        fieldMappings: [{
                                fromField: "count",
                                toField: "count"
                            },
                            {
                                fromField: "count",
                                toField: "count"
                            }
                        ],
                        dataProvider: this.chartData,
                        categoryField: "date"
                    }],
                    panels: [{
                        title: "Value",
                        stockGraphs: [{
                            id: "g1",
                            valueField: "count",
                            title: "Transactions",
                            bullet: "round"
                        }],
                        stockLegend: {
                            valueTextRegular: " ",
                            markerType: "round",
                            position: "bottom",
                            marginBottom: "20px",
                            color: "#303031",
                            "fillColors": "#303031",
                        }
                    }],
                    // legend: {
                    //     useGraphSettings: false,
                    //     position: "bottom",
                    //     color: "#fff"
                    // },
                    valueAxes: {
                        gridThickness: 0,
                        color: "#fff"
                    },

                    chartScrollbarSettings: {
                        graph: "g1",
                        color: "#303031"
                    },

                    chartCursorSettings: {
                        valueBalloonsEnabled: false,
                        graphBulletSize: 1,
                        valueLineBalloonEnabled: true,
                        valueLineEnabled: false,
                        valueLineAlpha: 0.5
                    },

                    periodSelector: {
                        position: "bottom",
                        periods: [{
                            period: "DD",
                            count: 1,
                            label: "Today"
                        }]
                    },

                    panelsSettings: {
                        usePrefixes: true
                    },
                    export: {
                        enabled: false
                    },
                    // "legend": {
                    //   "useGraphSettings": true,
                    //   color: "#fff"
                    // },
                    "legendSettings": {
                        "useGraphSettings": true,
                        color: "#fff"
                    },
                });
            },
            resetChartData() {
                this.reportData = {
                    order_summary_data: [],
                    orders_report: [],
                    payment_summary: [],
                    top_selling_items: []
                };
                this.report_counts = {
                    total_no_of_orders: 0,
                    gross_income: 0,
                    avg_value_of_order: 0,
                    customers_count: 0,
                    avg_revenue_per_customer: 0
                };
                this.chartData = [];
                // this.selected = {'_id': null, 'name': 'All Outlets'};
            },
            /**
             * This method or function is used to fetch the top selling data details
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getTopSellingMenuData() {
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http
                    .post("reports/top_selling_menu", details)
                    .then(response => {
                        // if (this.selected._id == response.data.outlet_id) {
                        this.topSellingMenu = response.data;
                        // }
                    });
            },
            /**
             * This method or function is used to fetch the tables report
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getTablesReport() {
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http.post("reports/tables_report", details).then(response => {
                    // if(this.selected._id == response.data.outlet_id) {
                    this.tablesReport = response.data;
                    this.show_download_report = true;
                    // }
                });
            },
            getReportEmail() {
                this.is_email_report_api_call = true;
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http.post("reports/sales_report/email", details).then(response => {
                    // if(this.selected._id == response.data.outlet_id) {
                    this.email_report = response.data;
                    this.$toasted.global.success(this.email_report.message)
                    if(this.email_report.upload_url) {
                        const link = document.createElement('a')
                        link.href = this.email_report.upload_url
                        // link.download = 'image file name here'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                    this.is_email_report_api_call = false;
                    // }
                });
            },
            downloadReport() {
                this.getReportEmail();
            },
            /**
             * This method or function is used to fetch the payment summary details
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getPaymentSummary() {
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http
                    .post("reports/payment_summary", details)
                    .then(response => {
                        this.paymentSummary = response.data;
                    });
            },
            /**
             * This method or function is used to fetch the customer report details
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getCustomerReport() {
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http
                    .post("reports/customer_report", details)
                    .then(response => {
                        // if(this.selected._id == response.data.outlet_id) {
                        this.customerReport = response.data;
                        // }
                    });
            },
            /**
             * This method or function is used to fetch the transaction report details
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getTransactionReport() {
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http
                    .post("reports/transaction_report", details)
                    .then(response => {
                        if (response.data.status_id === 1) {
                            this.dataProviderTransactionDays =
                                response.data.transaction_by_days;
                            this.dataProviderTransactionTime =
                                response.data.transaction_by_time;
                            // Chart Sales Days
                            var chart = AmCharts.makeChart("chartSalesDays", {
                                theme: "black",
                                type: "serial",
                                startDuration: 0,
                                dataProvider: this.dataProviderTransactionDays,
                                color: "#fff",
                                valueAxes: [{
                                    position: "left",
                                    gridThickness: 0,
                                    color: "#fff"
                                }],
                                graphs: [{
                                    balloonText: "[[category]]: <b>[[value]]</b>",
                                    fillColorsField: "color",
                                    fillAlphas: 1,
                                    lineAlpha: 0.1,
                                    type: "column",
                                    valueField: "orders",
                                    legendColor: "#3a94f1",
                                    fillColors: "#3a94f1"
                                }],
                                legend: {
                                    labelText: "Transactions",
                                    markerType: "circle",
                                    position: "bottom",
                                    useGraphSettings: false,
                                    valueWidth: 70,
                                    color: "#fff"
                                },
                                depth3D: 20,
                                angle: 30,
                                chartCursor: {
                                    categoryBalloonEnabled: false,
                                    cursorAlpha: 0,
                                    zoomable: false
                                },
                                categoryField: "days",
                                categoryAxis: {
                                    gridPosition: "start",
                                    labelRotation: 90,
                                    gridColor: "#FFFFFF",
                                    color: "#fff"
                                },
                                export: {
                                    enabled: false
                                },
                                grid: {
                                    disabled: true
                                }
                            });
                            // Chart Sales Time
                            var chart = AmCharts.makeChart("chartSalesTime", {
                                theme: "black",
                                type: "serial",
                                startDuration: 0,
                                dataProvider: this.dataProviderTransactionTime,
                                color: "#fff",
                                valueAxes: [{
                                    position: "left",
                                    gridThickness: 0,
                                    color: "#fff"
                                }],
                                graphs: [{
                                    balloonText: "[[category]]: <b>[[value]]</b>",
                                    fillColorsField: "color",
                                    fillAlphas: 1,
                                    lineAlpha: 0.1,
                                    type: "column",
                                    valueField: "orders",
                                    legendColor: "#fbbf5b",
                                    fillColors: "#fbbf5b"
                                }],
                                legend: {
                                    labelText: "Transactions",
                                    markerType: "circle",
                                    position: "bottom",
                                    useGraphSettings: false,
                                    valueWidth: 70,
                                    color: "#fff"
                                },
                                depth3D: 20,
                                angle: 30,
                                chartCursor: {
                                    categoryBalloonEnabled: false,
                                    cursorAlpha: 0,
                                    zoomable: false
                                },
                                categoryField: "hours",
                                categoryAxis: {
                                    gridPosition: "start",
                                    labelRotation: 90,
                                    gridColor: "#FFFFFF",
                                    color: "#fff"
                                },
                                export: {
                                    enabled: false
                                },
                                grid: {
                                    disabled: true
                                }
                            });
                            setTimeout(() => {
                                this.isTransactionDataEmpty = false;
                            }, 1000);
                        } else {
                            this.isTransactionDataEmpty = true;
                        }
                    });
            },
            /**
             * This method or function is used to fetch the Total sales report details
             * @param {start_date} - start_date is the String
             * @param {end_date} - end_date is the String
             * @param {outlet_id} - outlet_id is the String
             * @returns {response} - gets the Result
             */
            getTotalSalesReport() {
                this.salesLoading = true;
                let details = {
                    start_date: this.dateRange[0],
                    end_date: this.dateRange[1],
                    outlet_id: this.selected._id
                };
                this.$http
                    .post("reports/total_sales_details", details)
                    .then(response => {
                        // if(this.selected._id == response.data.outlet_id) {
                        this.total_sales_report = response.data;
                        // }
                        this.salesLoading = false;
                    });
            }
        },
        mounted() {
            setTimeout(() => {
                let startDate = new Date();
                startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0], this.loggedInUser
                    .business_day_start_time.split(":")[1]);
                console.log(startDate);
                let endDate = new Date();
                this.dateRange = [startDate, endDate];
                this.get_form_data();
                this.getTopSellingMenuData();
                this.getTablesReport();
                this.getPaymentSummary();
                this.getCustomerReport();
                this.getTransactionReport();
                EventBus.$emit("reportsLoad", true);
                EventBus.$emit("customerdetailsLoad", false);
                EventBus.$emit("settingsLoad", false);
                // //Solid Guage Chart
                // am4core.ready(function() {
                //     // Themes begin
                //     am4core.useTheme(am4themes_frozen);
                //     am4core.useTheme(am4themes_animated);
                //     // Themes end
                // }); // end am4core.ready()
            }, 2000);
            setTimeout(() => {
                this.getTotalSalesReport();
            }, 5000);
            //  $('#print-btn').click(()=>{
            //   var pdf = new jsPDF('p','pt','a4');
            //   pdf.addHTML(document.body,function() {
            //       pdf.save('web.pdf');
            //   });
            // })

        },

        created() {

            // startDate.setHours(this.loggedInUser.business_day_start_time);
            // startDate

            this.$http.post("/outlet/get", {}).then(res => {
                this.selected = {
                    _id: null,
                    name: "All Outlets"
                };
                this.options = [{
                    _id: null,
                    name: "All Outlets"
                }].concat(res.data.outlets);
            });
            if (this.$route.query.mobile === "true") {
                this.mobile = true;
                EventBus.$emit("mobile", true);
            }
        },
        watch: {
            selected: function (newVal, oldVal) {
                if (newVal._id !== oldVal._id) {
                    this.onDateChange();
                }
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        }
    };

</script>
<style>
    .half-circle-spinner {
        margin: 0 auto;
        text-align: center;
    }

    .blockdiv {
        z-index: 1000;
        border: none;
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgb(0, 0, 0);
        opacity: 0.6;
        cursor: wait;
        position: fixed;
    }

    .loaderdiv {
        z-index: 1011;
        position: fixed;
        padding: 20px;
        margin: 0px;
        width: 30%;
        top: 35%;
        left: 35%;
        text-align: center;
        color: #00448b;
        border: none;
        background-color: #fff;
        cursor: wait;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
    }

    .btn-print {
        height: 32px;
        padding: 0 12px;
        -webkit-border-radius: 6px !important;
        -moz-border-radius: 6px !important;
        border-radius: 6px !important;
        font-size: 13px;
        line-height: 32px;
        text-transform: none;
        font-weight: 600;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    input[type="button"] {
        cursor: pointer !important;
        border-radius: 18px !important;
        padding: 0 18px;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
    }

    .canvas_div_pdf.report_mamane_new_design {
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-color: #00448b;
        border-radius: 7.5px;
        padding-bottom: 18px;
    }

    text tspan {
        fill: white !important;
    }

    .top-right-div {
        padding: 8px;
        padding-right: 8px !important;
    }

    .title.text-dashboard {
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303031;
        padding-top: 13px;
        border-bottom: 1px solid rgba(195, 195, 195, 0.3);
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }

    .dashboard-text {
        font-size: 15px;
        font-weight: 600;
        color: #00448b !important;
        padding-top: 15px;
        text-align: center;
        letter-spacing: 0;
    }

    .headerInformation {
        width: 100%;
        height: 51px;
        color: #fff;
        padding: 17px;
        border-radius: 15px 15px 15px 15px;
    }

    .personalInformation {
        width: 100%;
        height: auto;
        border-radius: 7px;
        margin-top: 10px;
        background-color: #005dae;
    }

    .amcharts-chart-div a {
        display: none !important;
    }

    #salesBreakdown {
        width: 100%;
        height: 600px;
    }

    #salesBreakdown .amChartsLegend.amcharts-legend-div g text {
        color: #fff !important;
        fill: #fff !important;
    }

    #chartSalesDays .amChartsLegend.amcharts-legend-div g text {
        color: #fff !important;
        fill: #fff !important;
    }

    #newCancelledBills {
        width: 100%;
        height: 400px;
    }

    #CancelledItemsNew {
        width: 100%;
        height: 400px;
    }

    .sellingProducts {
        margin-top: 0;
        border-radius: 8px;
        border: solid 1px #cdd2da;
        background-color: #dee8f2;
        height: 591px;
        max-height: 591px;
    }

    .sellingProducts .table tbody tr {
        border-bottom: 1px solid #a8adb4 !important;
        font-weight: 600;
    }

    .totalSales {
        margin-top: 10px;
        border-radius: 8px;
        background-color: #005dae;
        color: #fff;
        font-weight: 600;
    }

    .digi-grid {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
    }

    .digi-grid:after,
    .digi-grid:before {
        content: "";
        display: block;
        overflow: hidden;
    }

    .digi-grid:after {
        clear: both;
    }

    .digi-grid>* {
        -ms-flex: none;
        -webkit-flex: none;
        flex: none;
        margin: 0;
        float: left;
    }

    .digi-grid>*> :last-child {
        margin-bottom: 0;
    }

    .digi-grid {
        margin-left: -13px;
    }

    .digi-grid>* {
        padding-left: 25px;
    }

    @media (min-width: 1220px) {
        .digi-grid {
            margin-left: -13px;
        }

        .digi-grid>* {
            padding-left: 25px;
        }
    }

    .digi-grid-collapse>* {
        padding-left: 0;
    }

    .digi-grid-small>* {
        padding-left: 10px;
    }

    .digi-grid-medium>* {
        padding-left: 25px;
    }

    @media (min-width: 960px) {
        .digi-grid-large>* {
            padding-left: 35px;
        }
    }

    @media (min-width: 1220px) {
        .digi-grid-large>* {
            padding-left: 50px;
        }
    }

    .digi-grid-divider>* {
        padding-left: 25px;
        padding-right: 25px;
    }

    @media (min-width: 768px) {}

    @media (min-width: 960px) {}

    @media (min-width: 1220px) {
        .digi-grid-divider>* {
            padding-left: 35px;
            padding-right: 35px;
        }
    }

    .digi-grid-match>* {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .digi-grid-match>*>* {
        -ms-flex: none;
        -webkit-flex: none;
        flex: none;
        box-sizing: border-box;
        width: 100%;
    }

    [class*="digi-grid-width"]>* {
        box-sizing: border-box;
        width: 100%;
    }

    .digi-grid-width-1-2>* {
        width: 50%;
    }

    .digi-grid-width-1-3>* {
        width: 33.333%;
    }

    .digi-grid-width-1-4>* {
        width: 25%;
    }

    .digi-grid-width-1-5>* {
        width: 20%;
    }

    .digi-grid-width-1-6>* {
        width: 16.666%;
    }

    .digi-grid-width-1-10>* {
        width: 10%;
    }

    .digi-grid-width-auto>* {
        width: auto;
    }

    @media (min-width: 480px) {
        .digi-grid-width-small-1-1>* {
            width: 100%;
        }

        .digi-grid-width-small-1-2>* {
            width: 50%;
        }

        .digi-grid-width-small-1-3>* {
            width: 33.333%;
        }

        .digi-grid-width-small-1-4>* {
            width: 25%;
        }

        .digi-grid-width-small-1-5>* {
            width: 20%;
        }

        .digi-grid-width-small-1-6>* {
            width: 16.666%;
        }

        .digi-grid-width-small-1-10>* {
            width: 10%;
        }
    }

    @media (min-width: 768px) {
        .digi-grid-width-medium-1-1>* {
            width: 100%;
        }

        .digi-grid-width-medium-1-2>* {
            width: 50%;
        }

        .digi-grid-width-medium-1-3>* {
            width: 33.333%;
        }

        .digi-grid-width-medium-1-4>* {
            width: 25%;
        }

        .digi-grid-width-medium-1-5>* {
            width: 20%;
        }

        .digi-grid-width-medium-1-6>* {
            width: 16.666%;
        }

        .digi-grid-width-medium-1-10>* {
            width: 10%;
        }
    }

    @media (min-width: 960px) {
        .digi-grid-width-large-1-1>* {
            width: 100%;
        }

        .digi-grid-width-large-1-2>* {
            width: 50%;
        }

        .digi-grid-width-large-1-3>* {
            width: 33.333%;
        }

        .digi-grid-width-large-1-4>* {
            width: 25%;
        }

        .digi-grid-width-large-1-5>* {
            width: 20%;
        }

        .digi-grid-width-large-1-6>* {
            width: 16.666%;
        }

        .digi-grid-width-large-1-10>* {
            width: 10%;
        }
    }

    @media (min-width: 1220px) {
        .digi-grid-width-xlarge-1-1>* {
            width: 100%;
        }

        .digi-grid-width-xlarge-1-2>* {
            width: 50%;
        }

        .digi-grid-width-xlarge-1-3>* {
            width: 33.333%;
        }

        .digi-grid-width-xlarge-1-4>* {
            width: 25%;
        }

        .digi-grid-width-xlarge-1-5>* {
            width: 20%;
        }

        .digi-grid-width-xlarge-1-6>* {
            width: 16.666%;
        }

        .digi-grid-width-xlarge-1-10>* {
            width: 10%;
        }
    }

    [class*="digi-width"] {
        box-sizing: border-box;
        width: 100%;
    }

    @media (min-width: 480px) {}

    @media (min-width: 768px) {}

    @media (min-width: 960px) {}

    @media (min-width: 1220px) {}

    @media (min-width: 768px) {

        [class*="digi-push-"],
        [class*="digi-pull-"] {
            position: relative;
        }
    }

    .digi-panel> :not(.digi-panel-title):last-child {
        margin-bottom: 0;
    }

    @media (min-width: 1220px) {}

    @media (min-width: 768px) {}

    .digi-block> :last-child {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {}

    @media (min-width: 960px) {}

    .digi-article> :last-child {
        margin-bottom: 0;
    }

    .digi-article-title a {
        color: inherit;
        text-decoration: none;
    }

    .digi-comment-body> :last-child {
        margin-bottom: 0;
    }

    .digi-comment-list .digi-comment+ul {
        margin: 25px 0 0 0;
        list-style: none;
    }

    .digi-comment-list .digi-comment+ul>li:nth-child(n + 2),
    .digi-comment-list>li:nth-child(n + 2) {
        margin-top: 25px;
    }

    @media (min-width: 768px) {
        .digi-comment-list .digi-comment+ul {
            padding-left: 100px;
        }
    }

    [data-digi-cover] {
        position: relative;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .digi-card .digi-card-toolbar .uk-tab li>a {
        padding: 10px 8px !important;
    }

    .digi-card {
        background-color: #dee8f2;
        position: relative;
        -webkit-appearance: none;
        border: solid 1px #cdd2da;
        border-radius: 10px;
    }

    .digi-card .digi-card-head-text span:not(.listNavSelector) {
        display: block;
        font: 400 12px/18px "Lato", "Helvetica Neue", sans-serif;
        margin-top: -2px;
    }

    .digi-card .digi-card-head-subtext span {
        font-size: 26px;
    }

    .digi-card .digi-card-head iframe {
        height: 160px;
    }

    .digi-card .digi-card-content {
        padding: 6px 10px 10px;
    }

    .digi-card.mdToolbar_fixed>.digi-card-content {
        overflow-y: scroll;
        /* -webkit-box-sizing: border-box;
    box-sizing: border-box; */
        position: absolute;
        width: 100%;
        top: 48px;
        bottom: 0;
    }

    .digi-card.digi-card-overlay {
        overflow: hidden;
        padding-bottom: 54px;
        margin-top: 3px;
        margin-bottom: 12px;
    }

    .digi-card.digi-card-overlay .digi-card-content {
        height: 214px;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        /* box-sizing: border-box; */
    }

    /* .digi-card.digi-card-overlay .digi-card-content.no_truncate{
  position:relative
}

.digi-card.digi-card-overlay .digi-card-content.no_truncate:after{
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  height:8px;
  background-image:-webkit-gradient(linear,left bottom,left top,color-stop(50%,#fff),to(rgba(255,255,255,0)));
  background-image:linear-gradient(to top,#fff 50%,rgba(255,255,255,0) 100%);
  display:block;
  content:'';
  z-index:10
}

.digi-card.digi-card-overlay .digi-card-content>pre{
  margin-top:0;
  max-height:110px
}

.digi-card.digi-card-overlay .digi-card-content>pre>code{
  overflow:hidden
} */

    .digi-card.digi-card-overlay .digi-card-overlay-content {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0 24px;
        margin-top: -54px;
        text-align: center;
        bottom: 0;
        background: #fff;
        z-index: 10;
        -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 0 0 10px 10px;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-content p {
        margin: 0;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-content p+* {
        margin-top: 16px;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-content p+p {
        margin-top: 4px;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header {
        padding: 12px 0;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header:after,
    .digi-card.digi-card-overlay .digi-card-overlay-header:before {
        content: " ";
        display: table;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header:after {
        clear: both;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header:after,
    .digi-card.digi-card-overlay .digi-card-overlay-header:before {
        content: " ";
        display: table;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header:after {
        clear: both;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header h3,
    .digi-card.digi-card-overlay .digi-card-overlay-header h4 {
        margin: 0;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-weight: 600;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header h3 {
        font: 600 15px/30px "Lato", "Helvetica Neue", sans-serif;
        color: #fff;
    }

    .digi-card.digi-card-overlay .digi-card-overlay-header h4 {
        font: 500 14px/30px "Lato", "Helvetica Neue", sans-serif;
    }

    .digi-card.digi-card-overlay-active .digi-card-overlay-content {
        top: -1px;
        margin-top: 0;
    }

    .digi-card.digi-card-hover {
        -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
        /* will-change: box-shadow; */
    }

    /* .digi-card.digi-card-hover:hover{
        -webkit-box-shadow:0 5px 3px rgba(0,0,0,0.2),0 1px 1px rgba(0,0,0,0.2);
        box-shadow:0 5px 3px rgba(0,0,0,0.2),0 1px 1px rgba(0,0,0,0.2)
    } */

    @media only screen and (max-width: 767px) {}

    .digi-card-horizontal .digi-card-content {
        margin-left: 200px;
    }

    @media only screen and (max-width: 767px) {
        .digi-card-horizontal .digi-card-content {
            margin-left: 140px;
        }
    }

    .digi-card-list-wrapper .digi-card-list>ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .digi-card-list-wrapper .digi-card-list>ul>li {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .digi-card-list-wrapper .digi-card-list>ul>li {
        min-height: 34px;
        padding: 8px 16px;
        font-size: 13px;
        -webkit-transition: background 150ms, padding 0.2s;
        transition: background 150ms, padding 0.2s;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .digi-card-list-wrapper .digi-card-list>ul>li:after,
    .digi-card-list-wrapper .digi-card-list>ul>li:before {
        content: " ";
        display: table;
    }

    .digi-card-list-wrapper .digi-card-list>ul>li:after {
        clear: both;
    }

    .digi-card-list-wrapper .digi-card-list>ul>li:after,
    .digi-card-list-wrapper .digi-card-list>ul>li:before {
        content: " ";
        display: table;
    }

    .digi-card-list-wrapper .digi-card-list>ul>li:after {
        clear: both;
    }

    @media only screen and (max-width: 767px) {}

    .digi-card-list-wrapper .digi-card-list .digi-card-list-item-sender>span {
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    @media only screen and (max-width: 1219px) {}

    .digi-card-list-wrapper .digi-card-list .digi-card-list-item-subject>span {
        line-height: 34px;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    @media only screen and (max-width: 1219px) {}

    @media only screen and (max-width: 479px) {}

    /* .widget-list-rounded .widget-list-item:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    } */

    .widget-list-item>div:first-child {
        padding-left: 5px;
    }

    .widget-list-item .widget-list-media.icon i {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
    }

    .widget-list-item>div:last-child {
        padding-right: 8px;
    }

    .widget-list-item .widget-list-action+div,
    .widget-list-item .widget-list-content+div,
    .widget-list-item .widget-list-media+div {
        padding-left: 0;
    }

    #chartSales {
        width: 90%;
        height: 250px;
    }

    .bottomSubtotal {
        width: 45px;
        height: 5px;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #f39b08, #cb8817);
        margin-left: 0;
        margin-top: 10px;
    }

    #chartBills {
        height: 230px;
        position: relative;
        left: 5px;
        margin-left: 54px;
        overflow: visible !important;
    }

    #amountBills {
        position: relative;
        height: 200px;
        left: 5px;
    }

    /* .pieBoxshadow{

} */

    #cancelBills {
        height: 225px;
        box-shadow: rgba(0, 0, 0, 0.13) 5px 0 0 0;
    }

    #cancelBills .amcharts-main-div {
        position: relative;
        bottom: 46px;
    }

    #chartBills .amcharts-main-div {
        position: relative;
        bottom: 46px;
    }

    #amountBills .amcharts-main-div {
        position: relative;
        bottom: 20px;
        top: 10px;
    }

    #chartSalesTime {
        height: 500px;
    }

    #chartSalesDays {
        height: 500px;
    }

    .allProducts {
        height: 140px;
        border-radius: 7px;
        background-color: #00448b;
        color: #fff;
    }

    .screenAlign.blockAlign {
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-color: #005dae;
        border-radius: 7px;
    }

    .topCategory {
        height: 140px;
        border-radius: 7px;
        background-color: #00448b;
        color: #fff;
    }

    /* box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16); */
    .topProduct {
        height: 140px;
        border-radius: 8px;
        color: #fff;
        border-radius: 7px;
        background-color: #00448b;
    }

    .topVariant {
        height: 147px;
        border-radius: 8px;
        color: #fff;
        border-radius: 7px;
        background-color: #00448b;
    }

    .productBottom {
        width: 183px;
        height: 1px;
        margin-left: 18px;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, #94bef0, #f5f6f9);
    }

    .tableBottom {
        /* width: 183px; */
        height: 1px;
        margin-left: 18px;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, #94bef0, #f5f6f9);
    }



    .allCustomers {
        height: 200px;
        margin-bottom: 19px;
        border-radius: 7px;
        background-color: #005dae;
    }

    .allPayment {
        height: 200px;
        margin-bottom: 19px;
        border-radius: 6px;
        background-color: #005dae;
    }

    @media (max-width: 1092px) {
        /* .flexWrap {
        flex-wrap: wrap;
        padding-right: 0 !important;
    } */

        .marginSize {
            margin-left: 0 !important;
        }

        .paymentAlign {
            margin-top: 10px !important;
            /* margin-right: 16px !important; */
        }
    }

    .btn-checkout {
        border-radius: 23px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031;
        padding: 0 10px;
        margin: 0;
        font-size: 14px !important;
        font-weight: 600;
        height: 3.4rem;
        line-height: 2.6rem;
        text-transform: capitalize;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
            0 0 0 0 rgba(0, 0, 0, 0.12) !important;
    }

    .btn-checkout i {
        color: #303031;
        font-size: 16px !important;
        font-weight: 600;
        padding-left: 16px;
    }

    .alignContent {
        position: relative;
        bottom: 19px;
    }

    @media screen and (max-width: 617px) {
        .currencyTop {
            margin-top: 19px;
            padding-top: 19px;
            border-top: solid 1px #cdd2da;
        }

        .screenLeft {
            padding-left: 4px !important;
            margin-right: 13px;
        }

        .screenAlign {
            padding-left: 8px !important;
            margin-right: 4px;
        }
    }

    @media only screen and (max-width: 991px) {
        .customerMargin {
            padding-left: 0;
        }
    }

    @media only screen and (max-width: 1090px) {
        .paymentAlign {
            padding-left: 0;
            padding-top: 10px;
        }
    }

    @media only screen and (max-width: 767px) and (min-width: 617px) {
        .paymentAlign {
            /* padding-right: 14px !important; */
        }
    }

    @media only screen and (max-width: 617px) {
        .topSellAlign {
            padding-right: 0 !important;
        }

        .resetAlign {
            padding-top: 15px !important;
        }
    }

    @media only screen and (max-width: 1120px) and (min-width: 990px) {
        .reportAlign {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }

    @media only screen and (max-width: 1090px) and (min-width: 768px) {
        .blockContent {
            max-width: 47.333%;
        }

        .paymentContent {
            margin-top: 0 !important;
            padding-top: 0 !important;
        }

        .TableblockContent {
            flex: 0 0 30.333%;
            max-width: 30.333%;
        }
    }

    @media only screen and (max-width: 1090px) and (min-width: 991px) {
        .blockAlign {
            flex-wrap: nowrap !important;
        }
    }

    /* .v-select.single .selected-tag{
  text-overflow: ellipsis;
  overflow: hidden;
} */
    .whiteSpace {
        white-space: nowrap !important;
    }

</style>
