<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="280"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 27em !important;">
                
                <div  class="mt-4 v-modal-body" style="font-size: 16px">
                    <!-- {{countdown}} -->
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-if="payment_status == 'in_progress'">
                        <div class="text-center fs-16 fw-800 text-blue">Payment In Progress!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_progress.svg" style="width:30%; margin-top:20px"/>
                        <div class="text-center fs-16  text-black mt-3">Please do not close or leave this page</div>
                    </div>
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-else-if="payment_status == 'success'">
                        <div class="text-center fs-16 fw-800 text-green">Thank you!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_success.svg" style="width:30%; margin-top:20px" />
                        <div class="text-center fs-16 text-black mt-3">Successful payment.  <br>Have a great day!</div>
                    </div>
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-else-if="payment_status == 'failure'">
                        <div class="text-center fs-16 fw-800 text-red">Payment Declined!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_declined.svg" style="width:30%; margin-top:20px" />
                        <div class="text-center fs-16 text-black mt-3">Please try again!</div>
                    </div>
                </div>
                <div class="payment-footer" v-if="payment_status == 'in_progress'">
                    <div class="text-center fs-16 fw-800 text-white">Please wait...</div>
                </div>
                <div class="payment-footer" v-else-if="payment_status == 'success'">
                    <div class="text-center fs-16 fw-800 text-white">Payment Done.</div>
                </div>
                <div class="payment-footer" v-else-if="payment_status == 'failure'" style="background-image: linear-gradient(to right, #828282, #131f2b 101%)!important;">
                    <div class="text-center fs-16 fw-800 text-white">Payment failed.</div>
                </div>
            </div>  
        </modal>
    </div>
</template>

<script>
import { EventBus } from './eventBus/nav-bar-event.js';
import Swal from 'sweetalert2';
import printers from "./mixins/printers"
import paymentDeviceAPI from "./mixins/paymentDeviceAPI"

export default {
    props: ["modal_name","fastPay", "stored_transaction_ref_id"],
    mixins: [printers, paymentDeviceAPI],
    data() {
        return {
            payment_status: 'in_progress',
            process_response: false,
            is_submit:false,
            show_countdown: false,
            countdown: 10,
            invalid_transaction_id_count: 0
        }
    },
    methods: {
        countDownTimer() {
            this.show_countdown = true
            if (this.countdown > 0) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else if (this.countdown === 0) {
                // this.clearOtpInputs();
                this.show_countdown = false
            }
        },
        async checkLogic() {
            // this.stored_transaction_ref_id
            try {
                let response = await this.checkOnlinePaymentStatus(this.stored_transaction_ref_id);
                console.log(response);
                this.payment_status = 'success'
                this.$emit('payment_success_print_receipt_ticket', {status:'success'})
                setTimeout(() => {
                    this.paymentStatusUpdateCheck("paymentModal");
                }, 3000);
            }
            catch(err) {
                console.log(err)
                if(err == 'Invalid transaction ID.') {
                    // this.invalid_transaction_id_count += 1;
                    // if(this.invalid_transaction_id_count >=2) {
                        // this.payment_status = 'failure'
                        // setTimeout(() => {
                        //     this.paymentStatusUpdateCheck("paymentModal");
                        // }, 3000);
                        this.countdown = 10
                        setTimeout(() => {
                            this.countDownTimer()
                        }, 500);
                    // } else {
                        // this.countdown = 30
                        // setTimeout(() => {
                        //     this.countDownTimer()
                        // }, 500);
                    // }
                } else {
                    this.payment_status = 'failure'
                    setTimeout(() => {
                        this.paymentStatusUpdateCheck("paymentModal");
                    }, 3000);
                }
            }
            // console.log('Checking Logic')
        },
        // async paymentUnSubscribe(transaction_ref_id) {
        //     let topic = transaction_ref_id
        //     let params = {
        //         "to": `/topics/${topic}`,
        //         "registration_tokens": [this.fcmDetails.fcm_token]
        //     }
        //     fetch('https://iid.googleapis.com/iid/v1:batchRemove',{
        //         method: 'POST',
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': 'key='+this.fcmDetails.fcm_server_key
        //         }),
        //         body: JSON.stringify(params)
        //     }).then(response => {
        //         if (response.status < 200 || response.status >= 400) {
        //         throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
        //         }
        //         console.log('UnSubscribed to "'+topic+'"');
        //     }).catch(error => {
        //         console.error(error);
        //     })
        // },
        paymentStatusUpdateCheck(value) {
            if(this.payment_status == 'success'){
                    this.$modal.hide(value)
                    if(this.$route.path == '/manageorder'){ 
                        //console.log("manage order");
                    } else {
                        this.$router.push({name: 'ManageOrder'})
                        this.$nextTick(() => {
                            document.getElementById('bigcontainer_scroll').scrollTop = 0
                            window.scrollTo(0,0);
                        })  
                    }
                    EventBus.$emit('PaymentComplete', true)
                } else if(this.payment_status == 'failure'){
                    if(this.$route.path == '/manageorder'){ 
                        this.$modal.show('method_payment_new')   
                        this.$modal.hide('paymentModal')
                    } else {
                        this.$modal.hide('paymentModal')
                    } 
                    EventBus.$emit("status",this.payment_status)
                }
        },
        movePage(ticket_data) {
            this.printTicket(ticket_data)
        },
    }, 
    mounted() {
        
        setTimeout(() => {
            EventBus.$on("payment_in_progress", (value) => {
                this.payment_status = value;
                this.countDownTimer();
            });
            
        },1000);
        setTimeout(() => {
            EventBus.$on("payment_success_failure", (value) => {
                this.payment_status = value.status 
                this.$emit('payment_success_print_receipt_ticket', value)
            });
        },1000);
        setTimeout(() => {
            EventBus.$on("close_modal", (value) => {
                this.paymentStatusUpdateCheck(value);
            });   
            
        },5000);
        
    },
    watch: {
        countdown: function(val) {
            if (val == 0) {
                this.checkLogic();
            }
        },
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
    },
}
</script>
<style>
.payment-footer{
    background-image: linear-gradient(to right, #037ae0, #00448b 101%)!important;
    color: #fff;
    width: 100%;
    text-align: -webkit-center;
    display:flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: center !important;
    padding: 15px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.new-modal-small.v--modal-overlay .v--modal-box.v--modal {
    height: 350px !important;
}
</style>