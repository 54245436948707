<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="payBill final-modal increase-width-popups-variants-addons popup-modal-all-width-height" :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">ORDER SUMMARY</span>
                    <span @click="cancePaybill()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;background: #eff1f4;">
                    <div class="p-0">
                        <div class="px-3">
                            <div class="row pb-3">
                                <div class="col-lg-12" style="z-index:99999;">
                                    <!-- <div class="row no-gutters align-items-center justify-content-between mt-2" style="display: -webkit-box;" v-if="summary.type">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Order Type</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold textCaps col-5 text-truncate">{{summary.type}}</div>
                                        <div class="col-1">
                                            <div class="pl-1 borderBottom ml-2" >
                                                <el-dropdown trigger="click" v-if="!for_approvals">
                                                    <span class="el-dropdown-link pointer" @click="loadOrderTypes(summary)">
                                                        <i class="icon icon-pencil text-secondary fs-16"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item @click.native="changeOrderType(types,summary)" v-for="(types,index) in order_types" :key="index">{{types.order_type}}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row no-gutters align-items-center justify-content-between mt-2" style="display:-webkit-box">
                                        <div class="col-6">
                                            <div class=" no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Customer Name</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold textCaps col-5 text-truncate" v-if="IsCustVs" :title="summary.customer_name">{{summary.customer_name}}</div>
                                        <div class="fs-14 font-weight-bold textCaps col-md-5" v-if="IsActiveVs && !inProgress">
                                            <v-select class="vSelectWidth" :close-on-select="true" @search="getSearchCustomer" @search:blur="getSearchCustomer" :options="customers" label="first_name" v-bind:class="{ Vdisabled: activeDisable }" v-model="selectedCustomer" @input="updateOrderCustomer"></v-select>
                                        </div>
                                        <div class="col-1">
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top" v-if="!inProgress && summary.payment_status != 'PAID' && !for_approvals">
                                                <button class="btn btn-icon companyactions actionicons" style="padding: 0 1rem!important;" v-bind:class="{ editVselect: iseditVselect }" @click="removeDisable()">
                                                    <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Cancel" placement="top" v-if="!inProgress">
                                                <button class="btn btn-icon companyactions actionicons" style="padding: 0 1rem!important;" v-bind:class="{ cancelVselect: iscancelVselect }" @click="addDisable()">
                                                    <i class="icon icon-close"></i>
                                                </button>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="row no-gutters align-items-center justify-content-between mt-2">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Company Name</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold col-6 text-truncate" v-if="summary.payments">{{summary.payments[0].customer_company || '-'}}</div>
                                    </div>
                                    <div class="row no-gutters align-items-center justify-content-between mt-2" style="display:-webkit-box">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Tax Code</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold text-truncate" v-bind:class="{'col-5' :!inProgress  , 'col-6' : inProgress }" v-if="summary.payments" :title="summary.payments[0].customer_tax_code">{{summary.payments[0].customer_tax_code || '-'}}</div>
                                        <div class="col-1 popover-tax-code" v-if="!inProgress">
                                            <el-button slot="reference" class="el-button-edit" v-if="summary.payment_status != 'PAID' && !for_approvals" @click="taxPopover = true">
                                                <button class="btn btn-icon companyactions actionicons" style="padding: 0 1rem!important;">
                                                    <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-button>
                                            <el-popover placement="bottom" title="Tax Code" v-model="taxPopover" width="300" height="158" trigger="click" style="height:158px!important;" popper-class="tax-code-height">
                                                <span class="pull-right" style="margin: 0 auto;margin-top: -30px;" @click="taxPopover = false">
                                                    <i class="icon icon-close" style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                </span>
                                                <div class="additionalCharges pl-2">
                                                    <input type="text" class="enterCharges" v-model="summary.payments[0].customer_tax_code" />
                                                    <button @click="editTaxCode" type="submit" class="applyCharges font-600">APPLY</button>
                                                </div>
                                                <!-- <p class="text-center" style="padding-top: 40px;">Or</p>
                                                <div class="row">
                                                    <a class="btn btn-secondary btn-smm text-center font-600" style="margin: 0 auto;">Scan Tax Code</a>
                                                </div> -->
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div v-if="summary.tables && summary.tables.length" class="row no-gutters align-items-center justify-content-between mt-2 flex-nowrap" style="display:-webkit-box">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Table Number</span>
                                            </div>
                                        </div>
                                        <div v-if="summary.tables.length && summary.tables[0].name" class="fs-14 font-weight-bold col-6">{{summary.tables[0].name}}</div>
                                        <div v-else class="fs-14 font-weight-bold col-6 no-gutters d-flex">
                                            <div v-bind:class="{'col-10' :!inProgress  , 'col-12' : inProgress }">{{summary.tables[0]}}</div>
                                            <div class="col-2" v-if="!inProgress">
                                                <el-button v-if="summary.tables[0] && !inProgress && summary.payment_status != 'PAID' && !for_approvals" slot="reference" class="el-button-edit">
                                                    <button class="btn btn-icon companyactions actionicons" style="padding: 0 1rem!important;" @click="tableSelection()">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters align-items-center justify-content-between mt-2" style="display: -webkit-box;" v-if="!summary.order_id">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Order Number</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold col-6">{{summary.order_number}}</div>
                                    </div>
                                    <div class="row no-gutters align-items-center justify-content-between mt-2" style="display: -webkit-box;" v-if="summary.order_id">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Order ID</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold col-6">{{summary.order_id}}</div>
                                    </div>
                                    <div class="row no-gutters align-items-center justify-content-between mt-2" style="display: -webkit-box;" v-if="summary.order_time">
                                        <div class="col-6">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal font-600">Order Time</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold col-6">{{summary.order_time}}</div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-5">
                                    <div class="text-center">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qr-code.svg" class="qr-logo" />
                                        <qrcode-vue :value="value" :size="size" background="#cddbeb" level="H" foreground="#00448b"></qrcode-vue>
                                    </div>
                                </div> -->
                            </div>
                            <h6 class="d-inline-block pr-2 pt-4 defaultBlue" style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;">Order Summary</h6>
                            <table v-if="!isPopUp" class="table tablecart border-bottom-dotted">
                                <thead>
                                    <tr style="border-bottom: solid 1px #b8b9bb;border-bottom-style: dashed;">
                                        <th class="thcart" style="font-size: 14px;padding: 0.4rem 1.2rem!important; width: 30%;">Items</th>
                                        <th class="text-center thcart" style="font-size: 14px;padding: 0.4rem 1.2rem!important; width: 10%;">Qty</th>
                                        <th class="thcart" style="font-size: 14px;padding: 0.4rem 1.2rem!important; width: 20%;">Rate</th>
                                        <th class="thcart" style="font-size: 14px;padding: 0.4rem 1.2rem!important; width: 20%;">Discount</th>
                                        <th class="thcart" style="font-size: 14px;padding: 0.4rem 1.2rem!important; width: 10%;">Price</th>
                                        <th class="thcart" style="width: 10%;"></th>
                                    </tr>
                                </thead>
                                <tbody class="border-dotted" v-if="this.$route.name == 'TakeOrderNew'">
                                    <template v-for="course in summary.courses">
                                        <!-- <tr :key="course.id">
                                        </tr> -->
                                        <template v-for="item in course.items">
                                            <tr :key="item.id" style="height: 29px;" class="subtotal">
                                                <td class="text-secondary textCaps" v-if="item.variant_id" style="padding: 0.4rem 1.2rem !important;word-break: break-word !important; width: 30%;">
                                                    <el-tooltip v-if="item.variant_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.variant_type == 'Veg'" content="Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;width:17px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.variant_type == 'Egg'" content="Egg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;width:17px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.variant_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                        <img style="width:15px;padding-right: 5px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                    </el-tooltip>
                                                    <span>
                                                        <span v-if="item.variant_name != ''">{{item.product_name}}</span>
                                                        <span v-else>{{item.product_name}}</span>
                                                        <span v-if="item.is_combo">
                                                        <el-popover placement="right-end" width="200" trigger="hover" popper-class="combo-popover">
                                                            <div v-for="(ic,index) in item.combo_details" :key="index">
                                                            <span>{{ic.name}} X {{ic.quantity}}</span>
                                                            </div>
                                                            <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                                        </el-popover>
                                                        </span>
                                                    </span>
                                                    <div v-if="item.discount.reason != ''" class="text-secondary font-italic fs-13">
                                                        {{item.discount.reason}}
                                                    </div>
                                                </td>
                                                <td class="text-secondary" v-else style="padding: 0.4rem 1.2rem !important; width: 30%;">
                                                    <el-tooltip v-if="item.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;width:17px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;width:17px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;width:17px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="item.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                        <img style="width:15px;padding-right: 5px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                    </el-tooltip>
                                                    <span>{{item.product_name}}
                                                        <span v-if="item.is_combo">
                                                            <el-popover placement="right-end" width="200" trigger="hover" popper-class="combo-popover">
                                                                <div v-for="(ic,index) in item.combo_details" :key="index">
                                                                <span>{{ic.name}} X {{ic.quantity}}</span>
                                                                </div>
                                                                <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                                            </el-popover>
                                                        </span>
                                                    </span>
                                                    <div v-if="item.discount.reason != ''" class="text-secondary font-italic fs-13">
                                                        {{item.discount.reason}}
                                                    </div>
                                                </td>
                                                <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;">{{item.quantity}}</td>
                                                <td style="padding: 0.4rem 1.2rem!important; width: 20%;">{{item.product_price}}</td>
                                                <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 20%;">
                                                    <div v-if="!(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) || item.discount.approval_message != ''">
                                                        {{item.discount.amount}}
                                                        <i class="icon-minus-circle text-danger pointer ml-2" v-if="item.discount.show_edit == false" @click="withdrawItem(summary._id, item._id, 'item')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                        <span class="ml-2" v-if="item.discount.approval_message && item.discount.approval_message != ''">
                                                            <el-tooltip class="item" effect="dark" :content="item.discount.approval_message" placement="top">
                                                                <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                            </el-tooltip>
                                                        </span>
                                                        <button v-if="(loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker') && item.discount.show_edit && summary.payment_status != 'PAID' && !for_approvals && loggedInUser.access_change_discount == true" class="btn btn-icon companyactions actionicons mb-2 ml-2" style="padding: 0rem 0.3rem;" @click="showItemDiscount(item)">
                                                            <i class="icon icon-pencil"></i>
                                                        </button>
                                                    </div>
                                                    <span v-else>-</span>
                                                </td>
                                                <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{item.product_price_exclusive}}</td>
                                                <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;">
                                                    <el-dropdown v-if="(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) && loggedInUser.role == 'default_manager' ||  loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount == true" trigger="click">
                                                        <span class="el-dropdown-link pointer ml-1">
                                                            <i class="icon icon-dots-vertical text-secondary" :disabled="item.is_promotional_product"></i>
                                                        </span>
                                                        <el-dropdown-menu>
                                                            <el-dropdown-item>
                                                                <div @click="openPopover(item, itemIndex)">
                                                                    Add Item Discount
                                                                </div>
                                                            </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </el-dropdown>
                                                    <div class="summary-el-popover">
                                                        <el-popover placement="bottom"
                                                            width="300" trigger="click" v-model="item.popover">
                                                            <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="item_discount_loading">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                            <div v-else>
                                                                <span class="defaultBlue mt-1 pl-2">
                                                                    Item Discount:
                                                                </span>
                                                                <div class="pull-right pr-2" style="margin: 0 auto;">
                                                                    <span @click="closePopOver(item)">
                                                                        <i class="icon icon-close"
                                                                            style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                                    <span class="fs-14 text-capitalize" style="color: #000;">
                                                                        {{item.product_name}}
                                                                    </span>
                                                                    <span class="fs-14" style="color: #000;">
                                                                        {{item.total.substring(0,1)+''+(parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)))}}
                                                                    </span>
                                                                </div>
                                                                <div class="px-2" v-if="item.discount">
                                                                    <div class="d-flex align-items-center mt-2">
                                                                        <input type="number" class="enterCharges percent-input-field w-100"
                                                                            v-model="item.discount.value" @input="itemDiscountChange(item)" :id="'itemDiscount'+item.product_id" placeholder="Enter Discount"
                                                                            v-on:keypress="isNumber($event)">
                                                                        <span class="percent-input-field-box text-white">
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div class="d-flex align-items-center mt-2">
                                                                        <span class="amount-input-field-box text-white">
                                                                            {{summary.currency.symbol}}
                                                                        </span>
                                                                        <input type="number" class="enterCharges amount-input-field w-100"
                                                                            @input="itemDiscountChangeAmount(item)" v-model="item.discount.discounted_amount" placeholder="Discounted Amount"
                                                                            v-on:keypress="isNumber($event)">
                                                                    </div>
                                                                    <textarea v-model="item.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                                    <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                        @click="applyItemDiscount(item.product_id,item._id,item)">APPLY</button>
                                                                </div>
                                                            </div>
                                                        </el-popover>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="item.addons.length > 0" :key="item._id+'idd5'">
                                                <td colspan="6" style="width: 100%;padding: 0 !important;background-color: #fff;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);">
                                                    <table class="w-100">
                                                        <tbody>
                                                            <tr v-for="(addon, index) in item.addons" :key="index" class="w-100">
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem !important; width: 30%;">
                                                                    <div class="text-blue font-600 fs-14" v-if="index == 0">
                                                                        <span>Size :</span>
                                                                        <span>Small</span>
                                                                    </div>
                                                                    <div class="text-green font-600" v-if="index == 0">Modifiers :</div>
                                                                    <div class="d-flex">
                                                                        <div class="showType" :class="{'veg':addon.type_name == 'Veg','nonveg':addon.type_name == 'Non-Veg','egg':addon.type_name == 'Egg','pescatarian':addon.type_name == 'Pescatarian'}">
                                                                            <el-tooltip :content="addon.type_name" placement="top">
                                                                                <div></div>
                                                                            </el-tooltip>
                                                                        </div>
                                                                        <span>
                                                                            {{addon.name}}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td style="padding: 0.4rem 1.2rem!important;border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;vertical-align:bottom;">
                                                                    <div>
                                                                        {{addon.quantity}}
                                                                    </div>
                                                                </td>
                                                                <td class="text-center" style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;">
                                                                    <div>
                                                                        {{addon.price}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem !important; width: 20%;">
                                                                    <div>
                                                                        
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                    <div>
                                                                        {{addon.amount}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                    <div>
                                                                        
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr> -->
                                            <tr :key="item._id+'idd9'">
                                                <td colspan="6" style="padding: 0.4rem 1.2rem!important;">
                                                    <div class="text-blue font-600 fs-14">
                                                        <span>{{item.variant_name || ""}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                                <td colspan="6" style="padding: 0 !important;border-radius: 6px; width: 100%;">
                                                    <table class="w-100">
                                                        <tbody style="background-color: #fff">
                                                            <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                                <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 30%;">
                                                                        <span class="font-weight-bold"
                                                                        >{{modifier.m_item_name}}</span>
                                                                    </td>
                                                                    <td class="text-center" style="padding: 0.4rem 1.2rem!important;border-radius: 0px; width: 10%;vertical-align:bottom;">
                                                                        <div v-if="modifier.has_price">
                                                                            {{modifier.quantity}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;vertical-align:bottom;">
                                                                        <div v-if="modifier.has_price">
                                                                            {{modifier.price}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;">
                                                                        <div>
                                                                            -
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;vertical-align:bottom;">
                                                                        <div v-if="modifier.has_price">
                                                                            {{modifier.amount}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                        <div>
                                                                            
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="6" style="10px 0 0 0"></td>
                                                            </tr>
                                                        </tbody>
                                                        <template v-if="item.modifiers.hasOwnProperty('for_primary')">
                                                            <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'" style="background-color: #fff">
                                                                <tr>
                                                                    <td colspan="6" style="border-radius: 0px;padding: 0.4rem 1.2rem !important;">
                                                                        <div class="text-blue font-600 fs-14">
                                                                            <span>{{modifier.modifier_name}}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 30%;">
                                                                        <div class="d-flex ml-0 pl-0">
                                                                            <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center" style="padding: 0.4rem 1.2rem!important;border-radius: 0px; width: 10%;vertical-align:bottom;">
                                                                        <div v-if="modifier_item.has_price">
                                                                            {{modifier_item.quantity}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;vertical-align:bottom;">
                                                                        <div v-if="modifier_item.has_price">
                                                                            {{modifier_item.price}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;">
                                                                        <div>
                                                                            -
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;vertical-align:bottom;">
                                                                        <div v-if="modifier_item.has_price">
                                                                            {{modifier_item.amount}}
                                                                        </div>
                                                                    </td>
                                                                    <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                        <div>
                                                                            
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="item.modifiers.length > 0" :key="item._id+'idd6'">
                                                <td colspan="6" style=" width: 100%;background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.4rem 1.2rem !important;">
                                                    <div>
                                                        <span class="text-green font-600">Modifiers :</span><br>
                                                        <div class="flex-adons pt-1" v-for="(modifier, modifier_index) in item.modifiers" :key="modifier_index+'modifier_index'" >
                                                            <span class="fw-bold text-capitalize" style="color: #000 !important;">{{modifier.modifier_name}}</span>
                                                            <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                                                <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 text-capitalize"
                                                                    >
                                                                    {{modifier.modifier_list[modifier_item_index]}}
                                                                    <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                                                    </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr> -->
                                        </template>
                                    </template>
                                    <tr style="height: 29px;" class="subtotal" v-for="(category, index) in summary.categories" :key="index" :class="{'borderTop':index == 0}">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;text-transform: capitalize!important; width: 40%;">{{category.name}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{category.total}}</td>
                                        <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;" v-if="index == 0">
                                            <el-dropdown trigger="click" v-if="(parseInt(summary.discount_amount.substring(1)) <= 0) && summary.discount.approval_message == '' && loggedInUser.access_change_discount == true && summary.discount.show_edit == true && summary.payment_status != 'PAID'">
                                                <span class="el-dropdown-link pointer ml-1">
                                                    <i class="icon icon-dots-vertical text-secondary"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                        <div >
                                                            <el-dropdown-item>    
                                                                <div @click="openOrderDiscount(summary)" >
                                                                    Add Order Discount
                                                                </div>
                                                            </el-dropdown-item>
                                                        </div>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                            <div class="summary-el-popover">
                                                <el-popover placement="bottom" v-model="order_charges_discount" width="300" trigger="click">
                                                    <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="order_discount_loading">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                    <div v-else>
                                                        <span class="defaultBlue mt-1 pl-2">
                                                            Order Discount:
                                                        </span>
                                                        <div class="pull-right pr-2" style="margin: 0 auto;">
                                                            <span @click="closeDiscount(summary)">
                                                                <i class="icon icon-close"
                                                                    style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                            <span class="fs-14 text-uppercase" style="color: #000;">
                                                                {{summary.order_id}}
                                                            </span>
                                                            <span class="fs-14" v-if="summary.double_discount == true" style="color: #000;">
                                                                {{summary.sub_total}}
                                                            </span>
                                                            <span class="fs-14" v-else style="color: #000;">
                                                                {{summary.sub_total_without_discount_items}}
                                                            </span>
                                                        </div>
                                                        <div class="px-2" v-if="summary.discount">
                                                            <div class="d-flex align-items-center mt-2">
                                                                <input type="number" class="enterCharges percent-input-field w-100"
                                                                    v-model="summary.discount.value" @input="orderDiscountChange(summary)" placeholder="Enter Discount"
                                                                    v-on:keypress="isNumber($event)">
                                                                <span class="percent-input-field-box text-white">
                                                                    %
                                                                </span>
                                                            </div>
                                                            <div class="d-flex align-items-center mt-2">
                                                                <span class="amount-input-field-box text-white">
                                                                    {{summary.currency.symbol}}
                                                                </span>
                                                                <input type="number" class="enterCharges amount-input-field w-100"
                                                                    v-model="summary_discounted_amount" @input="orderDiscountChangeAmount(summary)" placeholder="Discounted Amount"
                                                                    v-on:keypress="isNumber($event)">
                                                            </div>
                                                            <textarea v-model="summary.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                            <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                @click="updateDiscount">APPLY</button>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;" v-else></td>
                                    </tr>
                                    <tr v-if="summary.sub_total_without_discount" class="subtotal" style="height: 29px;border-top: 1px dotted #00448b87;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total Without Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;" class="whiteSpace">{{summary.sub_total_without_discount}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="!(parseInt(summary.items_discount_total.replace('(','').replace(')','').slice(1,)) <= 0)" class="subtotal" style="height: 29px;border-top: 1px dotted #00448b87;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Item Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.items_discount_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="editOrder">
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 40%;">Item Discount</td>
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;">( {{summary.items_discount_total}} )</td>
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;" ></td>
                                    </tr>
                                    <tr class="subtotal" v-if="summary.discount.value > 0 || summary.discount.approval_message != ''" style="height: 29px;">
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 10%;"></td>
                                        <td colspan="2" style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 40%;">Order Discount
                                            <span v-if="summary.discount && summary.discount.type == 'PERCENT'">
                                                (%)
                                            </span>
                                            <i class="icon-minus-circle text-danger pointer ml-2" v-if="summary.discount.show_edit == false" @click="withdrawItem(summary._id, '', 'order')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                            <span class="ml-2" v-if="(summary.discount.approval_message && summary.discount.approval_message != '')">
                                                <el-tooltip class="item" effect="dark" :content="summary.discount.approval_message" placement="top">
                                                    <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                </el-tooltip>
                                            </span>
                                            <el-button slot="reference" class="el-button-edit ml-2" v-if="loggedInUser.role == 'default_manager' || (summary.discount.show_edit == true && summary.payment_status != 'PAID' && loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount)"
                                                @click="order_charges_discount=true">
                                                <button class="btn btn-icon companyactions actionicons">
                                                    <i class="icon icon-pencil" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;bottom: 2px;position: relative;"></i>
                                                </button>
                                            </el-button>
                                            <div v-if="summary.discount.reason != ''" class="text-secondary font-italic fs-13">
                                                {{summary.discount.reason}}
                                            </div> 
                                        </td>
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 10%;">
                                            <span style="width: max-content;">( {{summary.discount_amount}} )</span>
                                        </td>
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;" ></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" :class="{'pointerEventsNone':summary.payment_status == 'PAID'}">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Promotion/Entertainment</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">({{summary.promotion_value}} )</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;" class="d-flex">
                                            <button class="btn btn-icon companyactions actionicons" v-if="!for_approvals" style="padding: 0.4rem 1.3rem;" @click="showpromotions()">
                                                <i class="icon icon-pencil"></i>
                                            </button>
                                            <button v-if="summary.promotion_applied" class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 1.3rem;" @click="removepromotion()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg" style="width:17px;">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="summary.sub_total_with_discount" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total With Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.sub_total_with_discount}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="!summary.sub_total_without_discount && !summary.sub_total_with_discount" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.sub_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-for="(other_charges,index) in summary.other_charges" :key="other_charges.code" :class="{'borderTop':index == 0}">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;"> {{other_charges.name}} <span v-if="other_charges.selected_type == 'PERCENTAGE'">(%)</span>
                                            <span v-if="other_charges.type == 'CUSTOM'">
                                                <el-tooltip class="item" effect="dark" content="Discount not applicable for this charge" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                </el-tooltip>
                                            </span>
                                        </td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{other_charges.value}}</td>
                                        <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;" v-if="other_charges.type == 'CUSTOM' && summary.payment_status != 'PAID'">
                                            <el-popover placement="bottom" v-model="other_charges.showtake" width="300" trigger="click">
                                                <div class="row">
                                                    <span class="defaultBlue pl-3">
                                                        Select:
                                                    </span>
                                                    <span class="preview-elements pl-2">
                                                        <el-radio v-model="other_charges.selected_type" label="AMOUNT">Price</el-radio>
                                                        <el-radio v-model="other_charges.selected_type" label="PERCENTAGE">Percentage</el-radio>
                                                    </span>
                                                    <div class="pull-right" style="margin: 0 auto;">
                                                        <span @click="closeOtherCharges(index)">
                                                        <i class="icon icon-close" style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                        </span>
                                                    </div>
                                                    <div class="additionalCharges pl-2">
                                                        <input type="number" class="enterCharges" v-model="other_charge_amount" v-on:keypress="isNumber($event)" @input="checkNumber(index)" />
                                                        <button type="submit" class="applyCharges" @click="updateOtherCharge(index)">APPLY</button>
                                                    </div>
                                                </div>
                                                <el-button slot="reference" class="el-button-edit" v-if="!for_approvals" @click="showOtherChargeTake(index)">
                                                    <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 1.3rem;">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-button>
                                            </el-popover>
                                        </td>
                                        <td v-else style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 34px;" v-if="summary.loyalty_applied" :class="{'pointerEventsNone':summary.payment_status == 'PAID'}">
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;" class="text-center"></td>
                                        <td class="whiteSpace" style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 20%;">Reward Points</td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 20%;" class="text-center"></td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;">{{summary.loyalty_amount}}</td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;">
                                            <span class="pl-3" @click="loyaltyremove()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="summary.total_amount" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;"> Total Amount</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.total_amount}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-for="tax in summary.tax" :key="tax.code">
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">{{tax.name}}({{tax.rate}}%)</td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{tax.value}}</td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_round_off_required">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Gross Total</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.gross_total}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_round_off_required && !summary.is_credit_card_used">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Round Off</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.round_off.amount}}</td>
                                        <td style="border-top:1px dotted #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">
                                        </td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31); width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;background-color: rgb(242, 156, 31); width: 40%;" class="total-checkout-img">Grand Total</td>
                                        <td style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;font-weight:600;background-color: rgb(242, 156, 31); width: 10%;" class="value-checkout-img">{{summary.grand_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 0px 8px 8px 0px; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.upcharge_for_credit_card_usage" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-bold" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Payment Type :
                                            <span class="fs-13" style="color: #4e5764; font-weight: 500;">
                                                Credit Card
                                            </span>
                                        </td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; white-space: nowrap; width: 10%;">
                                            <el-switch class="approvalswitch" :disabled="summary.payment_status == 'PAID'" @change="isCreditCardUsed($event)" v-model="summary.is_credit_card_used"></el-switch>
                                        </td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-bold" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Credit Card Charges</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.credit_card_charges_amount}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-normal" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Round Off</td>
                                        <td class="fw-normal" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">({{summary.round_off.amount}})</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31); width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;color: #000 !important;font-size:16px;background-color: rgb(242, 156, 31); width: 40%;" class="total-checkout-img fw-bold">Grand Total with CC Charges</td>
                                        <td style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;font-weight:600;background-color: rgb(242, 156, 31); width: 10%;" class="value-checkout-img">{{summary.grand_total_with_credit_card_charges}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 0px 8px 8px 0px; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_deposit_used == true">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Deposit Amount</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">({{summary.customer_deposit_used}})</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">
                                            <span class="pl-3" @click="useDeposit({order_id:summary._id, amount:0})">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_deposit_used == true">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color:#00448b;border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 40%;">Amount Due</td>
                                        <td style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 10%;">{{summary.grand_total_after_deposit}}</td>
                                        <td style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 10%;border-radius: 0px 8px 8px 0px;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.loyalty_points_earned > 0">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Loyalty Points Earned</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.loyalty_points_earned}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                </tbody>
                                <tbody class="border-dotted" v-else>
                                    <template v-for="item in summary.items">
                                        <tr :key="item.id" style="height: 29px;" class="subtotal">
                                            <td class="text-secondary textCaps" v-if="item.variant_id" style="padding: 0.4rem 1.2rem !important;word-break: break-word !important; width: 30%;">
                                                <el-tooltip v-if="item.variant_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.variant_type == 'Veg'" content="Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;width:17px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.variant_type == 'Egg'" content="Egg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;width:17px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.variant_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                    <img style="width:15px;padding-right: 5px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                </el-tooltip>
                                                <span>
                                                    <span v-if="item.variant_name != ''">{{item.product_name}}</span>
                                                    <span v-else>{{item.product_name}}</span>
                                                    <span v-if="item.is_combo">
                                                    <el-popover placement="right-end" width="200" trigger="hover" popper-class="combo-popover">
                                                        <div v-for="(ic,index) in item.combo_details" :key="index">
                                                            <span>{{ic.name}} X {{ic.quantity}}</span>
                                                        </div>
                                                        <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                                    </el-popover>
                                                    </span>
                                                </span>
                                                <div v-if="item.discount.reason != ''" class="text-secondary font-italic fs-13" style="text-transform: lowercase !important; font-size: 13px !important;">
                                                    {{item.discount.reason}}
                                                </div>
                                            </td>
                                            <td class="text-secondary" v-else style="padding: 0.4rem 1.2rem !important; width: 30%;">
                                                <el-tooltip v-if="item.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;width:17px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;width:17px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;width:17px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="item.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                    <img style="width:15px;padding-right: 5px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                </el-tooltip>
                                                <span>{{item.product_name}}
                                                    <span v-if="item.is_combo">
                                                    <el-popover placement="right-end" width="200" trigger="hover" popper-class="combo-popover">
                                                        <div v-for="(ic,index) in item.combo_details" :key="index">
                                                            <span>{{ic.name}} X {{ic.quantity}}</span>
                                                        </div>
                                                        <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                                    </el-popover>
                                                    </span>
                                                </span>
                                                <div v-if="item.discount.reason != ''" class="text-secondary font-italic fs-13" style="text-transform: lowercase !important; font-size: 13px !important;">
                                                    {{item.discount.reason}}
                                                </div>
                                            </td>
                                            <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;">{{item.quantity}}</td>
                                            <td style="padding: 0.4rem 1.2rem!important; width: 20%;">{{item.product_price}}</td>
                                            <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 20%;">
                                                <div v-if="!(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) || item.discount.approval_message != ''">
                                                    {{item.discount.amount}}
                                                    <i class="icon-minus-circle text-danger pointer ml-2" v-if="item.discount.show_edit == false" @click="withdrawItem(summary._id, item._id, 'item')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                    <span class="ml-2" v-if="item.discount.approval_message && item.discount.approval_message != ''">
                                                        <el-tooltip class="item" effect="dark" :content="item.discount.approval_message" placement="top">
                                                            <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                        </el-tooltip>
                                                    </span>
                                                    <button v-if="(loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker') && item.discount.show_edit && summary.payment_status != 'PAID' && !for_approvals && loggedInUser.access_change_discount == true" class="btn btn-icon companyactions actionicons mb-2 ml-2" style="padding: 0rem 0.3rem;" @click="showItemDiscount(item)">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </div>
                                                <span v-else>-</span>
                                            </td>
                                            <td style="padding: 0.4rem 1.2rem!important; width: 10%;">
                                                {{item.product_price_exclusive}}
                                            </td>
                                            <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;">
                                                <el-dropdown v-if="(parseInt(item.discount.amount.replace('(','').replace(')','').slice(1,)) <= 0) && loggedInUser.role == 'default_manager' ||  loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount == true" trigger="click">
                                                    <span class="el-dropdown-link pointer ml-1">
                                                        <i class="icon icon-dots-vertical text-secondary" :disabled="item.is_promotional_product"></i>
                                                    </span>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item>
                                                            <div @click="openPopover(item, itemIndex)">
                                                                Add Item Discount
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                                <div class="summary-el-popover">
                                                    <el-popover placement="bottom"
                                                        width="300" trigger="click" v-model="item.popover">
                                                        <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="item_discount_loading">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                        <div v-else>
                                                            <span class="defaultBlue mt-1 pl-2">
                                                                Item Discount:
                                                            </span>
                                                            <div class="pull-right pr-2" style="margin: 0 auto;">
                                                                <span @click="closePopOver(item)">
                                                                    <i class="icon icon-close"
                                                                        style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                                </span>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                                <span class="fs-14 text-capitalize" style="color: #000;">
                                                                    {{item.product_name}}
                                                                </span>
                                                                <span class="fs-14" style="color: #000;">
                                                                    {{item.total.substring(0,1)+''+(parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)))}}
                                                                </span>
                                                            </div>
                                                            <div class="px-2" v-if="item.discount">
                                                                <div class="d-flex align-items-center mt-2">
                                                                    <input type="number" class="enterCharges percent-input-field w-100"
                                                                        v-model="item.discount.value" @input="itemDiscountChange(item)" :id="'itemDiscount'+item.product_id" placeholder="Enter Discount"
                                                                        v-on:keypress="isNumber($event)">
                                                                    <span class="percent-input-field-box text-white">
                                                                        %
                                                                    </span>
                                                                </div>
                                                                <div class="d-flex align-items-center mt-2">
                                                                    <span class="amount-input-field-box text-white">
                                                                        {{summary.currency.symbol}}
                                                                    </span>
                                                                    <input type="number" class="enterCharges amount-input-field w-100"
                                                                        @input="itemDiscountChangeAmount(item)" v-model="item.discount.discounted_amount" placeholder="Discounted Amount"
                                                                        v-on:keypress="isNumber($event)">
                                                                </div>
                                                                <textarea v-model="item.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                                <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                    @click="applyItemDiscount(item.product_id,item._id,item)">APPLY</button>
                                                            </div>
                                                        </div>
                                                    </el-popover>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                            <td colspan="6" style="padding: 0 !important;border-radius: 6px; width: 100%;">
                                                <table class="w-100">
                                                    <tbody style="background-color: #fff">
                                                        <tr v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                            <td colspan="6" style="padding: 0.4rem 1.2rem!important;">
                                                                <div class="text-blue font-600 fs-14">
                                                                    <span>{{item.variant_name}}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                            <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 30%;">
                                                                    <span class="font-weight-bold"
                                                                    >{{modifier.m_item_name}}</span>
                                                                </td>
                                                                <td class="text-center" style="padding: 0.4rem 1.2rem!important;border-radius: 0px; width: 10%;vertical-align:bottom;">
                                                                    <div v-if="modifier.has_price">
                                                                        {{modifier.quantity}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;vertical-align:bottom;">
                                                                    <div v-if="modifier.has_price">
                                                                        {{modifier.price}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;">
                                                                    <div>
                                                                        -
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;vertical-align:bottom;">
                                                                    <div v-if="modifier.has_price">
                                                                        {{modifier.amount}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                    <div>
                                                                        
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="6" style="10px 0 0 0"></td>
                                                        </tr>
                                                    </tbody>
                                                    <template v-if="item.modifiers.hasOwnProperty('for_primary')">
                                                        <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'" style="background-color: #fff">
                                                            <tr>
                                                                <td colspan="6" style="border-radius: 0px;padding: 0.4rem 1.2rem !important;">
                                                                    <div class="text-blue font-600 fs-14">
                                                                        <span>{{modifier.modifier_name}}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 30%;">
                                                                    <div class="d-flex ml-0 pl-0">
                                                                        <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                    </div>
                                                                </td>
                                                                <td class="text-center" style="padding: 0.4rem 1.2rem!important;border-radius: 0px; width: 10%;vertical-align:bottom;">
                                                                    <div v-if="modifier_item.has_price">
                                                                        {{modifier_item.quantity}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;vertical-align:bottom;">
                                                                    <div v-if="modifier_item.has_price">
                                                                        {{modifier_item.price}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 20%;">
                                                                    <div>
                                                                        -
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;vertical-align:bottom;">
                                                                    <div v-if="modifier_item.has_price">
                                                                        {{modifier_item.amount}}
                                                                    </div>
                                                                </td>
                                                                <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 10%;">
                                                                    <div>
                                                                        
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </td>
                                        </tr>
                                        <!-- <tr v-if="item.modifiers.length > 0" :key="item._id+'idd'">
                                            <td colspan="6" style="padding-left: 0 !important; padding-right: 0 !important; width: 100%;" >
                                                <div style="background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.4rem 1.2rem!important;">
                                                    <span class="text-green font-600">Modifiers :</span><br>
                                                    <div class="flex-adons pt-1" v-for="(modifier, modifier_index) in item.modifiers" :key="modifier_index+'modifier_index'" >
                                                        <span class="fw-bold text-capitalize" style="color: #000 !important;">{{modifier.modifier_name}}</span>
                                                        <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                                            <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 text-capitalize"
                                                                >
                                                                {{modifier.modifier_list[modifier_item_index]}}
                                                                <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                                                </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> -->
                                    </template>
                                    <tr style="height: 29px;" class="subtotal" v-for="(category, index) in summary.categories" :key="index" :class="{'borderTop':index == 0}">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;text-transform: capitalize!important; width: 40%;">{{category.name}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{category.total}}</td>
                                        <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;" v-if="index == 0">
                                            <el-dropdown trigger="click" v-if="(parseInt(summary.discount_amount.substring(1)) <= 0) && summary.discount.approval_message == '' && loggedInUser.access_change_discount == true && summary.discount.show_edit == true && summary.payment_status != 'PAID'">
                                                <span class="el-dropdown-link pointer ml-1">
                                                    <i class="icon icon-dots-vertical text-secondary"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                        <div >
                                                            <el-dropdown-item>    
                                                                <div @click="openOrderDiscount(summary)" >
                                                                    Add Order Discount
                                                                </div>
                                                            </el-dropdown-item>
                                                        </div>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                            <div class="summary-el-popover">
                                                <el-popover placement="bottom" v-model="order_charges_discount" width="300" trigger="click">
                                                    <div class="digi-table-loader d-flex align-items-center justify-content-center" style="margin: 0 auto" v-if="order_discount_loading">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                    <div v-else>
                                                        <span class="defaultBlue mt-1 pl-2">
                                                            Order Discount:
                                                        </span>
                                                        <div class="pull-right pr-2" style="margin: 0 auto;">
                                                            <span @click="closeDiscount(summary)">
                                                                <i class="icon icon-close"
                                                                    style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                            </span>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between mt-3 px-2">
                                                            <span class="fs-14 text-uppercase" style="color: #000;">
                                                                {{summary.order_id}}
                                                            </span>
                                                            <span class="fs-14" v-if="summary.double_discount == true" style="color: #000;">
                                                                {{summary.sub_total}}
                                                            </span>
                                                            <span class="fs-14" v-else style="color: #000;">
                                                                {{summary.sub_total_without_discount_items}}
                                                            </span>
                                                        </div>
                                                        <div class="px-2" v-if="summary.discount">
                                                            <div class="d-flex align-items-center mt-2">
                                                                <input type="number" class="enterCharges percent-input-field w-100"
                                                                    v-model="summary.discount.value" @input="orderDiscountChange(summary)" placeholder="Enter Discount"
                                                                    v-on:keypress="isNumber($event)">
                                                                <span class="percent-input-field-box text-white">
                                                                    %
                                                                </span>
                                                            </div>
                                                            <div class="d-flex align-items-center mt-2">
                                                                <span class="amount-input-field-box text-white">
                                                                    {{summary.currency.symbol}}
                                                                </span>
                                                                <input type="number" class="enterCharges amount-input-field w-100"
                                                                    v-model="summary_discounted_amount" @input="orderDiscountChangeAmount(summary)" placeholder="Discounted Amount"
                                                                    v-on:keypress="isNumber($event)">
                                                            </div>
                                                            <textarea v-model="summary.discount.reason" class="w-100 my-2" style="padding: 4.2px 7.8px 34.3px 7.8px;border-radius: 4px;border: solid 0.5px #b5b4b4;background-color: #fff;"></textarea>
                                                            <button type="submit" class="applyCharges position-relative" style="border-radius: 20px !important; float: right;"
                                                                @click="updateDiscount">APPLY</button>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;" v-else></td>
                                    </tr>
                                    <tr v-if="summary.sub_total_without_discount" class="subtotal" style="height: 29px;border-top: 1px dotted #00448b87;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total Without Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.sub_total_without_discount}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="!(parseInt(summary.items_discount_total.replace('(','').replace(')','').slice(1,)) <= 0)" class="subtotal" style="height: 29px;border-top: 1px dotted #00448b87;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Item Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.items_discount_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="editOrder">
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 40%;">Item Discount</td>
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;">( {{summary.items_discount_total}} )</td>
                                        <td style="border-top: 1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" v-if="summary.discount.value > 0 || summary.discount.approval_message != ''" style="height: 29px;">
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 10%;"></td>
                                        <td colspan="2" style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 40%;">Order Discount
                                            <span v-if="summary.discount && summary.discount.type == 'PERCENT'">
                                                (%)
                                            </span>
                                            <i class="icon-minus-circle text-danger pointer ml-2" v-if="summary.discount.show_edit == false" @click="withdrawItem(summary._id, '', 'order')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                            <span class="ml-2" v-if="(summary.discount.approval_message && summary.discount.approval_message != '')">
                                                <el-tooltip class="item" effect="dark" :content="summary.discount.approval_message" placement="top">
                                                    <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                </el-tooltip>
                                            </span>
                                            <el-button slot="reference" class="el-button-edit ml-2" v-if="loggedInUser.role == 'default_manager' || (summary.discount.show_edit == true && summary.payment_status != 'PAID' && loggedInUser.role == 'default_order_taker' && loggedInUser.access_change_discount)"
                                                @click="order_charges_discount=true">
                                                <button class="btn btn-icon companyactions actionicons">
                                                    <i class="icon icon-pencil" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;bottom: 2px;position: relative;"></i>
                                                </button>
                                            </el-button>
                                            <div v-if="summary.discount.reason != ''" class="text-secondary font-italic fs-13">
                                                {{summary.discount.reason}}
                                            </div> 
                                        </td>
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important;width: 10%;">
                                            <span style="width: max-content;">( {{summary.discount_amount}} )</span>
                                        </td>
                                        <td style="border-bottom:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 10%;" ></td>
                                    </tr>
                                    <tr v-if="summary.sub_total_with_discount" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total With Discount</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.sub_total_with_discount}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="!summary.sub_total_without_discount && !summary.sub_total_with_discount" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Sub-Total</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.sub_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">Promotion/Entertainment</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;" class="whiteSpace">({{summary.promotion_value}} )</td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">
                                            <button v-if="summary.payment_status != 'PAID' && !for_approvals" class="btn btn-icon companyactions actionicons mb-2 ml-2" style="padding: 0.4rem 1.3rem;" @click="showpromotions()">
                                            <i class="icon icon-pencil"></i>
                                            </button>
                                            <button v-if="summary.promotion_applied" class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 1.3rem;" @click="removepromotion()">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg" style="width:17px;">
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-for="(other_charges,index) in summary.other_charges" :key="other_charges.code" :class="{'borderTop':index == 0}">
                                        <td style="padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td class="text-center" style="padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important; width: 40%;">{{other_charges.name}}<span v-if="other_charges.selected_type == 'PERCENTAGE'">(%)</span>
                                            <span v-if="other_charges.type == 'CUSTOM'">
                                                <el-tooltip class="item" effect="dark" content="Discount not applicable for this charge" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                </el-tooltip>
                                            </span>
                                        </td>
                                        <td style="padding: 0.4rem 1.2rem!important; width: 10%;">{{other_charges.value}}</td>
                                        <td class="position-relative" style="padding: 0.4rem 1.2rem!important; width: 10%;" v-if="other_charges.type == 'CUSTOM' && summary.payment_status != 'PAID'">
                                            <el-popover placement="bottom" v-model="other_charges.show" width="300" trigger="click">
                                                <div class="row">
                                                    <span class="defaultBlue pl-3">
                                                        Select:
                                                    </span>
                                                    <span class="preview-elements pl-2">
                                                        <el-radio v-model="other_charges.selected_type" label="AMOUNT">Price</el-radio>
                                                        <el-radio v-model="other_charges.selected_type" label="PERCENTAGE">Percentage</el-radio>
                                                    </span>
                                                    <div class="pull-right" style="margin: 0 auto;">
                                                        <span @click="closeOtherCharges(index)">
                                                        <i class="icon icon-close" style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"></i>
                                                        </span>
                                                    </div>
                                                    <div class="additionalCharges pl-2">
                                                        <input type="number" class="enterCharges" v-model="other_charge_amount" v-on:keypress="isNumber($event)" @input="checkNumber(index)" />
                                                        <button type="submit" class="applyCharges" @click="updateOtherCharge(index)">APPLY</button>
                                                    </div>
                                                </div>
                                                <el-button slot="reference" class="el-button-edit" v-if="!for_approvals"  @click="showOtherCharge(index)">
                                                    <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 1.3rem;">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-button>
                                            </el-popover>
                                        </td>
                                        <td v-else style="padding: 0.4rem 1.2rem!important; width: 10%;" ></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 34px;" v-if="summary.loyalty_applied" :class="{'pointerEventsNone':summary.payment_status == 'PAID'}">
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="whiteSpace" style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 40%;">Reward Points</td>
                                        <!-- <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important;" class="text-center"></td> -->
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;">{{summary.loyalty_amount}}</td>
                                        <td style="border-top:1px dotted #00448b87;padding: 0.4rem 1.2rem !important; width: 10%;">
                                            <span class="pl-3" @click="loyaltyremove()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="summary.total_amount" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Total Amount</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.total_amount}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-for="tax in summary.tax" :key="tax.code">
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">{{tax.name}}({{tax.rate}}%)</td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{tax.value}}</td>
                                        <td style="border-top: 1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_round_off_required">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Gross Total</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.gross_total}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_round_off_required && !summary.is_credit_card_used">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Round Off</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.round_off.amount}}</td>
                                        <td style="border-top:1px dotted #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="!inProgress && summary.payment_status != 'PAID' && summary.status=='delivered' && summary.grand_total_after_deposit_unformatted >= 0">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Total Amount Paid(Credits)</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.amount_paid}}</td>
                                        <td style="border-top:1px dotted #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31); width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;background-color: rgb(242, 156, 31); width: 40%;" class="total-checkout-img">Grand Total</td>
                                        <td style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;font-weight:600;background-color: rgb(242, 156, 31); width: 10%;" class="value-checkout-img">{{summary.grand_total}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 0px 8px 8px 0px; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.upcharge_for_credit_card_usage" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-bold" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Payment Type :
                                            <span class="fs-13" style="color: #4e5764; font-weight: 500;">
                                                Credit Card
                                            </span>
                                        </td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; white-space: nowrap; width: 10%;">
                                            <el-switch class="approvalswitch" :disabled="summary.payment_status == 'PAID'" @change="isCreditCardUsed($event)" v-model="summary.is_credit_card_used"></el-switch>
                                        </td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-bold" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Credit Card Charges</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.credit_card_charges_amount}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" class="fw-normal" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; color: #4e5764; width: 40%;">Round Off</td>
                                        <td class="fw-normal" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">({{summary.round_off.amount}})</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                    <tr v-if="summary.is_credit_card_used" class="subtotal" style="height: 29px;">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31); width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="padding: 0.4rem 1.2rem!important;color: #000 !important;font-size:16px;background-color: rgb(242, 156, 31); width: 40%;" class="total-checkout-img fw-bold">Grand Total with CC Charges</td>
                                        <td style="padding: 0.4rem 1.2rem!important;color: #303031 !important;font-size:15px;font-weight:600;background-color: rgb(242, 156, 31); width: 10%;" class="value-checkout-img">{{summary.grand_total_with_credit_card_charges}}</td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color: rgb(242, 156, 31);border-radius: 0px 8px 8px 0px; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_deposit_used == true">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Deposit Amount</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">({{summary.customer_deposit_used}})</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">
                                        <span class="pl-3" @click="useDeposit({order_id:summary._id, amount:0})">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minusRed.svg">
                                        </span>
                                        </td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;" v-if="summary.is_deposit_used == true">
                                        <td style="padding: 0.4rem 1.2rem!important;background-color:#00448b;border-radius: 8px 0px 0px 8px; width: 30%;"></td>
                                        <td style="padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 40%;">Amount Due</td>
                                        <td style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b; width: 10%;">{{summary.grand_total_after_deposit}}</td>
                                        <td style="color:#fff!important;padding: 0.4rem 1.2rem!important;background-color:#00448b;border-radius: 0px 8px 8px 0px; width: 10%;"></td>
                                    </tr>
                                    <tr class="subtotal" style="height: 29px;">
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 30%;"></td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;" class="text-center"></td>
                                        <td colspan="2" style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 40%;">Loyalty Points Earned</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;">{{summary.loyalty_points_earned}}</td>
                                        <td style="border-top:1px solid #00448b40;padding: 0.4rem 1.2rem!important; width: 10%;"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="deposit-details pt-2 mt-4 pb-2" v-if="summary.is_deposit_used == true">
                                <span class="text-center text-white font-weight-bold d-block">Deposit Info</span>
                                <hr style="border:0.7px solid rgb(132, 136, 140);" class="ml-3 mr-3">
                                <div class="m-4 p-2 text-white font-weight-bold">
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Issued by</div>
                                            <div class="content-right w-150">
                                                <span class="right-div whiteSpace">{{summary.deposit_information.issued_by}}</span>
                                            </div>
                                        </div>
                                        <div class="div-content">
                                            <div class="content-left specmeter left-flex-summary">Date of Issue</div>
                                            <div class="content-right">
                                                <span class="right-div">{{summary.deposit_information.date_of_issue_formatted}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Bank</div>
                                            <div class="content-right">
                                                <span class="right-div">{{summary.deposit_information.bank_name}}</span>
                                            </div>
                                        </div>
                                        <div class="div-content">
                                            <div class="content-left specmeter left-flex-summary">Cheque/Transfer no</div>
                                            <div class="content-right">
                                                <span class="right-div">{{summary.deposit_information.cheque_number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Deposit Note</div>
                                            <div class="content-right">
                                                <span class="right-div">{{summary.deposit_information.deposit_notes}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card deposit-card mt-2" v-if="edit_deposit">
                                <div class="card-body">
                                    <p class="text-secondary font-600">
                                    Use Deposit - Balance : {{summary.customer_balance}}
                                        <span class="pull-right" style="margin-top: -2px;cursor:pointer;" @click="closeDeposit()">
                                            <el-tooltip class="item" effect="dark" content="Close" placement="top-start">
                                                <i class="icon icon-close"></i>
                                            </el-tooltip>
                                        </span>
                                    </p>
                                    <p class="text-grey font-600">Enter Amount to be Used ($)</p>
                                    <div class="additionalCharges">
                                        <input type="number" min="0" class="enterCharges" v-on:keypress="isNumber($event)" v-model.number="edit_customer_deposit_used">
                                        <button type="submit" class="applyCharges font-600" @click="useDeposit({order_id:summary._id, amount:edit_customer_deposit_used})">SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center pt-3">
                                <a v-if="summary.is_deposit_used == false && summary.is_deposit_available == true && summary.payment_status == 'UNPAID'" class="btn btn-secondary-custom" @click="openDeposit()">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/money-bag-with-dollar-symbol.svg" style="padding-right: 10px;width: 24px;"/>
                                    <span>Use</span> Deposit
                                    <el-tooltip class="item" effect="dark" content="Deposit Amount From Wallet" placement="top-start">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/deposit-info.svg" class="pl-1">
                                    </el-tooltip>
                                </a>
                                <a v-if="summary.payment_status == 'UNPAID' && summary.is_deposit_used" class="btn btn-secondary-custom" @click="openDeposit()">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/money-bag-with-dollar-symbol.svg" style="padding-right: 10px;width: 24px;"/>
                                    <span>Edit </span> Deposit
                                    <el-tooltip class="item" effect="dark" content="Deposit Amount From Wallet" placement="top-start">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/deposit-info.svg" class="pl-1">
                                    </el-tooltip>
                                </a>
                                <el-popover placement="top-start" width="400" v-model="showLoyalty" v-if="summary.is_loyalty_applicable">
                                    <div class="d-flex justify-content-between" style="background:#00448b !important;margin: -14px !important;border-top-left-radius: 10px!important;padding:10px !important;border-top-right-radius: 10px !important;">
                                        <div>
                                            <div class="font-weight-bold text-white">Total Loyalty points : {{loyaltyDataFromApi.total_loyalty_points}}</div>
                                            <div class="font-weight-bold text-white pt-2">Redeemable Amount: {{loyaltyDataFromApi.max_redeemable_amount}}</div>
                                        </div>
                                        <span @click="showLoyalty = false">
                                            <i class="icon icon-close text-white"></i>
                                        </span>
                                    </div>
                                    <el-radio-group v-model="summary.loyalty_type" class="mt-7" @change="loyaltyTypeChange()">
                                        <el-radio label="amount">Amount</el-radio>
                                        <el-radio label="points">Points</el-radio>
                                    </el-radio-group>
                                    <div class="mt-2">
                                        <span class="text-secondary font-weight-bold">Enter Redeem <span style="text-transform:capitalize;">{{summary.loyalty_type}}</span> :</span>
                                    </div>
                                    <div class="d-flex applyinput mt-3 mb-5">
                                        <input type="text" placeholder="" v-on:keypress="isNumber($event)" v-model.number="loyaltyValue" @input="calculateLoyalty()">
                                        <button class="btn btn-apply" :disabled="loyaltyValue.length == 0" @click="loyaltyapply">Apply</button>
                                    </div>
                                    <div v-if="summary.loyalty_type == 'amount'"  style="background:#1b9e4c !important;padding:10px !important;border-radius:12px !important;">
                                        <div class="font-weight-bold text-white">Points to be Deducted: {{pointsdeducted}}</div>
                                        <div class="font-weight-bold text-white pt-2">Net Points: {{netpoints}}</div>
                                    </div>
                                    <div v-if="summary.loyalty_type == 'points'" style="background:#1b9e4c !important;padding:10px !important;border-radius:12px !important;">
                                        <div class="font-weight-bold text-white">Amount to be Deducted: {{amountdeducted}}</div>
                                        <div class="font-weight-bold text-white pt-2">Net Amount: {{netamount}}</div>
                                    </div>
                                    <el-button slot="reference" class="btncustom">
                                        <button class="btn btn-secondary-custom-loyalty" style="width: auto !important">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/loyaltyimage.svg" style="width:17px;">
                                            <span style="padding-left: 8px;" v-if="!summary.loyalty_applied">Use Loyalty Points</span>
                                            <span style="padding-left: 8px;" v-if="summary.loyalty_applied">Edit Loyalty Points</span>
                                        </button>
                                    </el-button>
                                </el-popover>
                            </div>
                            <checkout-popup v-if="isPopUp" :isSummary="true" :order="summary" :showRoundOffBox="true" :editOrder="editOrder" :oldOrder="null"></checkout-popup>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <button v-if="!inProgress" class="btn btn-link btn-smm" :disabled="pay_and_close_api_inprogress || pay_api_inprogress || close_order_api_inprogress || print_invoice_api_inprogress" style="text-transform: uppercase !important;font-size: 12px !important;" @click="cancePaybill()">CANCEL</button>
                    <button type="submit" v-else class="btn btn-secondary btn-smm" @click="close()">CLOSE</button>
                    <button type="submit" v-if="summary.status != 'inactive' && !for_approvals" class="btn btn-secondary btn-smm mr-4" :disabled="pay_and_close_api_inprogress || pay_api_inprogress || close_order_api_inprogress || print_invoice_api_inprogress" @click="generateInvoice()">PRINT INVOICE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="print_invoice_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" class="btn btn-secondary btn-smm" v-if="summary.status == 'inactive' && !inProgress && $route.path != '/approvalstatus'" :disabled="pay_and_close_api_inprogress || pay_api_inprogress || close_order_api_inprogress || print_invoice_api_inprogress" @click="prePayBill()">PAY
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="pay_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" class="btn btn-secondary btn-smm" v-else-if="!inProgress && summary.payment_status != 'PAID' && summary.status=='delivered' && summary.grand_total_after_deposit_unformatted >= 0" :disabled="pay_and_close_api_inprogress || pay_api_inprogress || close_order_api_inprogress || print_invoice_api_inprogress" @click="payBillManage()">PAY AND CLOSE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="pay_and_close_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" v-else-if="!inProgress && summary.payment_status=='PAID' && summary.status=='delivered'" :disabled="pay_and_close_api_inprogress || pay_api_inprogress || close_order_api_inprogress || print_invoice_api_inprogress" class="btn btn-secondary btn-smm" @click="closeOrder()">CLOSE ORDER
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="close_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                </div>
            </div>
        </modal>
        <payment-method :temp_amount_paid="temp_amount_paid" :ticket_printing="ticket_printing" modal_name="payment_method" :summary="summary" :is_credit_card_used="summary.is_credit_card_used" :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" @close="closePaymentMethod" :fastPay="false" v-if="showpay"></payment-method>
        <sweet-modal ref="success_modal" icon="success">{{modal_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{modal_msg}}</sweet-modal>
        <table-selection-modal :orderedTable="summary.tables" :order_id="summary._id" :editOrder="update_order" modal_name="table_modal" @updateSummary="updateSummary" :editTableFromSummary="editTableFromSummary" :isDelivered="isDelivered"></table-selection-modal>
        <promotions-active modal_name="active-promotion" :source="'paybill'" :storeorderId="storeorderId" @promotionapplied="promotionshowbill()" @promotionshowbill="promotionshowbill()"></promotions-active>
        <buy-offer modal_name="offer-buy" :source="'paybill'" :storeorderId="storeorderId" @updateapplypaybill="promotionshowbill()"></buy-offer>
        <get-offer modal_name="offer-get" :source="'paybill'" :storeorderId="storeorderId" @checkoutupdatepay="promotionshowbill()"></get-offer>
        <in-progress modal_name="in-progress" message="Printing Is In Progress"></in-progress>
        <order-type @updateData="updateDataFromOrderType" @cancelOrderType="cancelOrderType" modal_name="order_type" :what_order_type_from_checkout="what_order_type_from_checkout" :order_type_id="order_type_id" :order_id="order_id" v-if="show_order_type"></order-type>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { SweetModal } from 'sweet-modal-vue'
import Swal from 'sweetalert2'
import CheckoutPopup from './CheckoutPopup'
import PaymentMethod from './PaymentMethod'
import ModalCheckoutPopup from './ModalCheckoutPopup'
import TableSelectionModal from './TableSelectionModal'

import CustomerServices from './mixins/customer'
import { EventBus } from './eventBus/nav-bar-event'
import ManageOrderService from './mixins/manage_order'
import printers from './mixins/printers'
import orderServices from './mixins/orders'
const PromotionsActive = () => import('./ActivePromotions')
const BuyOffer = () => import('./OfferApplicableProducts')
const GetOffer = () => import('./OfferApplicableGet')
const InProgress = () => import('./InProgress')
const OrderType = () => import('./OrderTypeData')
import { HalfCircleSpinner } from "epic-spinners";
export default {
    props: [
        'modal_name',
        'summary',
        'isPopUp',
        'editOrder',
        'isDelivered',
        'inProgress',
        'editTableFromSummary',
        'storeorderId',
        'sourcemanage',
        'ticket_printing',
        'show_edit',
        'approval_message',
        'for_approvals'
    ],
    data() {
        return {
            safariTrue:false,
            showpay:false,
            activeDisable: true,
            iseditVselect: false,
            iscancelVselect: true,
            edit_customer_deposit_used: 0,
            IsCustVs: true,
            edit_deposit:false,
            IsActiveVs: false,
            payment_mode: 'Card',
            editRoundoff: true,
            round_off: 0.0,
            modal_msg: '',
            value: 'https://example.com',
            size: 150,
            roundOff: 0,
            order_charges_popover: false,
            order_charges_discount: false,
            order_charges_discount_take:false,
            order_roundoff: false,
            selectedCustomer: this.summary.customer_name,
            taxPopover: false,
            grand_total_amount: 0,
            grand_total_value: 0,
            update_order: false,
            customers: [],
            customerTaxCode: null,
            other_charge_amount: 0.00,
            showLoyalty:false,
            loyaltyDataFromApi:[],
            loyaltyValue:0,
            pointsdeducted:0,
            netpoints:0,
            amountdeducted:0,
            netamount:0,
            source:'',
            temp_amount_paid: 0,
            temp_cash_received: null,
            show_order_type: false,
            order_type_id: null,
            order_id: null,
            order_types: [],
            what_order_type_from_checkout: '',
            summary_discounted_amount: 0,
            order_discount_loading: true,
            item_discount_loading: true,
            pay_and_close_api_inprogress: false,
            pay_api_inprogress: false,
            close_order_api_inprogress: false,
            print_invoice_api_inprogress: false
        }
    },
    components: {
        CheckoutPopup,
        SweetModal,
        ModalCheckoutPopup,
        QrcodeVue,
        PaymentMethod,
        TableSelectionModal,
        PromotionsActive,
        BuyOffer,
        GetOffer,
        InProgress,
        Swal,
        OrderType,
        HalfCircleSpinner
    },
    mixins: [ManageOrderService, CustomerServices, orderServices, printers],
    methods: {
        /**
        * This method or function is used to show the dsicount popup
        */
        openPopover(item, item_index){
            item.popover = true
            item.discount.discounted_amount = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
            this.$forceUpdate();
        },
        /**
        * This method or function is used to open the order discount popup
        * @param {summary} - summary is the Object
        * will calculate the summary total order discount
        */
        openOrderDiscount(summary) {
            this.order_charges_discount = true;
            if(summary.double_discount == true) {
                this.summary_discounted_amount = parseFloat(((summary.sub_total.substring(1)) * (summary.discount.value / 100)).toFixed(2));
            } else {
                this.summary_discounted_amount = parseFloat(((summary.sub_total_without_discount_items.substring(1)) * (summary.discount.value / 100)).toFixed(2));
            }
        },
        /**
        * This method or function is used to change the order discount
        * @param {summary} - summary is the Object
        * will calculate the summary total order discount
        */
        orderDiscountChange(summary) {
            if(summary.discount.value > 100) {
                this.$toasted.global.error("Discount Percent Must not be greater than 100%");
                summary.discount.value = 100;
            }
            if(summary.double_discount == true) {
                this.summary_discounted_amount = parseFloat(((summary.sub_total.substring(1)) * (summary.discount.value / 100)).toFixed(2));
            } else {
                this.summary_discounted_amount = parseFloat(((summary.sub_total_without_discount_items.substring(1)) * (summary.discount.value / 100)).toFixed(2));
            }
        },
        /**
        * This method or function is used to change the item discount
        * @param {summary} - summary is the Object
        * will calculate the summary total item discount
        */
        itemDiscountChange(item) {
            if(item.discount.value > 100) {
                this.$toasted.global.error("Discount Percent Must not be greater than 100%");
                item.discount.value = 100;
            }
            item.discount.discounted_amount = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
            this.$forceUpdate();
        },
        /**
        * This method or function is used to change the order discount in percentage
        * @param {summary} - summary is the Object
        * will calculate the summary total order discount
        */
        orderDiscountChangeAmount(order) {
            if(order.double_discount == true) {
                if(this.summary_discounted_amount > order.sub_total.substring(1)) {
                    this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                    this.summary_discounted_amount = order.sub_total.substring(1);
                }
                order.discount.value = parseFloat(((this.summary_discounted_amount/order.sub_total.substring(1)) * 100).toFixed(2));
            } else {
                if(this.summary_discounted_amount > order.sub_total_without_discount_items.substring(1)) {
                    this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                    this.summary_discounted_amount = order.sub_total_without_discount_items.substring(1);
                }
                order.discount.value = parseFloat(((this.summary_discounted_amount/order.sub_total_without_discount_items.substring(1)) * 100).toFixed(2));
            }
        },
        /**
        * This method or function is used to change the item discount amount
        * @param {item} - item is the Object
        * will calculate the summary total item discount
        */
        itemDiscountChangeAmount(item) {
            if(item.discount.discounted_amount > parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated))) {
                this.$toasted.global.error("Discount amount cannot be greater than the total amount");
                item.discount.discounted_amount = parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated));
            }
            item.discount.value = parseFloat(((item.discount.discounted_amount/parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated))) * 100).toFixed(2));
            this.$forceUpdate();
        },
        /**
        * This method or function is used to check if the credit card is used or not for payment
        * @param {order_id} - order_id is the String
        * @param {is_credit_card_used} - is_credit_card_used is the Boolean
        * @returns {response} - Get the result
        */
        async isCreditCardUsed(value) {
            try {
                let params = {
                    order_id: this.summary._id,
                    is_credit_card_used: this.summary.is_credit_card_used
                };
                let response = await this.creditCardUsageActive(params);
                this.modal_msg = response.message;
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.$emit("updateCheckout", true);
                }, 2000);
            }
            catch (error) {
                this.modal_msg = error;
                this.$refs.warning_modal.open();
                setTimeout(() => this.$refs.warning_modal.close(), 2000);
            }
        },
        showItemDiscount(item) {
            item.popover = true;
            this.$forceUpdate();
        },
        /**
        * This method or function is used to apply the item discount to the order
        * @param {order_item_id} - order_item_id is the String
        * @param {discount} - discount is the Object that contains type and amount/value
        * @param {reason} - reason is the String
        * @returns {response} - Get the result
        */
        async applyItemDiscount(item_id, order_item_id, item) {
            if (!item.discount.value) {
                this.$toasted.global.error("Please Enter Value");
                return;
            }
            try {
                let params = {
                    order_item_id: order_item_id,
                    discount: {
                        // type: item.discount.type,
                        type: 'PERCENT',
                        value: item.discount.value,
                        // amount: parseFloat($("#itemDiscount" + item_id).val())
                        amount: 0
                    },
                    reason: item.discount.reason
                };
                if(this.$route.path.startsWith('/takeordernew')){
                    params.update = false;
                    params.summary = false;
                } else {
                    params.update = true;
                    params.summary = true;
                }
                let res = await this.orderItemDiscount(params);
                this.$emit("updateCheckout", true);
                this.$toasted.global.success(
                    'Successfully Updated Order'
                );
                this.closePopOver(item);
            } catch (reason) {
                // this.$toasted.global.error(reason);
                this.modal_msg = reason
                this.$refs.warning_modal.open()
                setTimeout(() => {
                    this.$refs.warning_modal.close()
                }, 2000);
            }
        },
        closePopOver(item) {
            // item.discount.value = 0;
            item.popover = false;
            this.$forceUpdate();
        },
        /**
        * This method or function is used to update order type
        * @param {this.summary._id} - this.summary._id is the String
        * @returns {response} - Get the result
        */
        updateDataFromOrderType() {
            this.show_order_type = false,
            setTimeout(() => {
                this.$modal.hide("order_type")
                this.updateScreen(this.summary._id)
                this.$emit('updateCheckout', true)
            }, 500);
        },
        cancelOrderType(){
            this.show_order_type = false,
            setTimeout(() => {
                this.$modal.hide("order_type")
            }, 500);
        },
        // goToCheckoutScreen(data) {
        //     data.customerId = data.customer_id
        //     delete data.customer_id
        //     this.$router.replace(`/takeordernew?order_id=${data._id}&order_type=${data.order_type}`)
        //     localStorage.setItem('order_data_for_checkout', JSON.stringify(data))
        // },
        /**
        * This method or function is used to fetch order types
        * @param {search_key} - search_key is the String
        * @param {sort_key} - sort_key is the String
        * @param {sort_value} - sort_value is the Integer
        * @param {required_id} - required_id is the String
        * @param {existing_type} - existing_type is the String
        * @returns {response} - Get the result
        */
        async loadOrderTypes(order){
            console.log(order)
            let params = {
                search_key: "",
                sort_key: "order_type",
                sort_value: 1,
                required_id: true,
                existing_type: order.order_type
            }
            let response = await this.orderTypeDropdowns(params)
            this.order_types = response.response.order_types
            console.log(response)
        },
        /**
        * This method or function is used to change the order type for a order
        * @param {order_id} - order_id is the String
        * @param {order_type} - order_type is the String
        * @param {tables} - tables is the Array 
        * @param {delivery_company} - delivery_company is the String
        * @param {no_of_pax} - no_of_pax is the Integer
        * @returns {response} - Get the result
        */
        async changeOrderType(value,order){
            // order['tables'] = []
            // order.tables.push(order.table_number)
            this.order_id = order._id
            this.what_order_type_from_checkout = value.order_type
            if(value.order_type != 'Take Away'){
                this.order_type_id = {
                    value: value,
                    order: order
                }
                this.show_order_type = true
                setTimeout(() => {
                    this.$modal.show("order_type")
                }, 500);
            }
            else{
                let params = {
                    order_id: order._id,
                    order_type: value.order_type,
                    tables: [order.table_number],
                    delivery_company: "",
                    no_of_pax: order.no_of_pax
                }
                let response = await this.updateOrderTypeDetails(params)
                this.modal_msg = "Order Type Updated Successfully"
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.updateScreen(this.summary._id)
                    this.$emit('updateCheckout', true)
                    this.loadOrderTypes(order)
                }, 3000);
            }
        },
        /**
        * This method or function is used to withdraW the item discount request
        * @param {order_id} - order_id is the String
        * @param {order_item_id} - order_item_id is the String
        * @param {action} - action is the String
        * @returns {response} - Get the result
        */
        async withdrawItem(order_id, order_item_id, type) {
            console.log()
            try {
                let params = {
                    order_id: order_id,
                };
                if(type == 'item') {
                    params.order_item_id = order_item_id
                    params.action = 'order_item_discount'
                } else if(type == 'order') {
                    params.action = 'order_discount'
                }
                let response = await this.withdrawItemAndOrder(params);
                this.modal_msg = response.message;
                this.$refs.success_modal.open();
                    setTimeout(() => {this.$refs.success_modal.close()
                    this.updateScreen(this.summary._id)
                    this.$emit('updateCheckout', true)
                }, 2000);
            }
            catch(err) {
                this.modal_msg = err;
                this.$refs.warning_modal.open();
                setTimeout(() => this.$refs.warning_modal.close(), 2000);
            }
        },
        /**
        * This method or function is used to fetch the receipt details
        * @param {params} - params is the Object
        * @returns {response} - Get the result
        */
        async getReceiptDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/get_details_for_receipt', params)
                try {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err) {
                    this.$toasted.global.error(err.response.reason)
                }
            })
        },
        /**
        * This method or function is used to generate the invoice
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        async generateInvoice(){
            this.print_invoice_api_inprogress = true;
            let response = await this.getReceiptDetails({order_id : this.summary._id})
            console.log(response)
            // let category_data = []
            // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
            // let invoice_data = JSON.parse(sessionStorage.getItem("invoice_printer"))
            // if(data){
            //     data.forEach(y => {
            //         y.printers.forEach(x => {
            //             if(y._id == x.category_id){
            //                 category_data.push({category_id: x.category_id,printer_id:invoice_data.printer_id})
            //             }
            //         })
            //     })
            // }
            this.store_invoice_data = response.response
            this.printInvoice(response.response)
            this.print_invoice_api_inprogress = false;
        },
        showOtherCharge(index) {
            this.summary.other_charges[index].show = true
            if (this.summary.other_charges[index].selected_type == 'PERCENTAGE') {
                this.other_charge_amount = this.summary.other_charges[index].tax_rate
            } else {
                let value = this.summary.other_charges[index].value
                this.other_charge_amount = parseFloat(value.substring(1).replace(',', ''))
            }
        },
        showOtherChargeTake(index){
            this.summary.other_charges[index].showtake = true
            if (this.summary.other_charges[index].selected_type == 'PERCENTAGE') {
                this.other_charge_amount = this.summary.other_charges[index].tax_rate
            } else {
                let value = this.summary.other_charges[index].value
                this.other_charge_amount = parseFloat(value.substring(1).replace(',', ''))
            }
        },
        checkNumber(index){
            this.summary.other_charges.forEach((charge,chargeindex)=>{
                if(chargeindex == index){
                    if(charge.selected_type == 'AMOUNT'){
                        if(this.other_charge_amount.length > 8){
                        this.other_charge_amount = this.other_charge_amount.slice(NaN,8)
                        }
                    }else if(charge.selected_type == 'PERCENTAGE'){
                        if(charge.name !='Tips'){
                            if(this.other_charge_amount > 100){
                                this.$toasted.global.error(
                                    "Discount Percent Must be Between 0 and 100%"
                                );
                                this.other_charge_amount = 100;
                            }
                        }
                    }
                }
            })
        },
        openDeposit() {
            this.edit_deposit = true;
        },
        closeDeposit() {
            this.edit_deposit = false;
        },
        /**
        * This method or function is used to use the deposit amount in the order
        * @param {params} - params is the Object
        * @returns {response} - Get the result
        */
        async useDeposit(params) {
            return new Promise(async(resolve, reject) => {
                if (this.edit_customer_deposit_used > this.summary.grand_total_unformatted) {
                    this.$toasted.global.error('Deposit Amount should be less than or equal to Grand Total')
                }
                else{
                    let response = await this.$http.post('/orders/customers/deposit',params)
                    if (response.data.status_id == 1) {
                        this.updateSummary();
                        this.edit_deposit = false;
                        this.$toasted.global.success(response.data.message)
                        resolve(response.data)
                    } else {
                        this.$toasted.global.error(response.data.reason)
                        reject(response.data)
                    }
                }
            })
        },
        updateSummary() {
            this.$emit('updateCheckout', true)
        },
        closeDiscount(summary){
            // if(!this.order_discount_status){
            //     this.summary.discount.value = 0
            // }
            if (summary.discount_amount.substring(1) == 0) {
                summary.discount.value = 0
            }
            this.order_charges_discount = false
            this.$forceUpdate();
        },
        closeDiscountTakeorder(){
            // if(!this.order_discount_status){
            //     this.summary.discount.value = 0
            // }
            this.order_charges_discount_take = false
            this.$forceUpdate();
        },
        closeAdditionalCharges(){
            if(!this.order_charges_status){
                this.order.additional_charges.value = 0
            }
            this.order_charges_popover = false
        },
        /**
        * This method or function is used to update the other charges for the order
        * @param {order_id} - order_id is the String
        * @param {name} - name is the String
        * @param {value} - value is the Integer/Float
        * @param {type} - type is the String
        * @param {summary} - summary is the Boolean
        * @returns {response} - Get the result
        */
        async updateOtherCharge(index){
            try {
                let params = {
                    order_id : this.summary._id,
                    name:this.summary.other_charges[index].name,
                    value:parseFloat(this.other_charge_amount),
                    type:this.summary.other_charges[index].selected_type,
                    summary:true
                };
                if(this.editOrder) params.update = true
                else if (this.inProgress) params.summary = true
                else if (this.isDelivered) params.is_delivered = true
                let res = await this.orderOtherCharges(params);
                this.$toasted.global.success(res.message);
                this.$emit('updateCheckout', true)
                this.summary.other_charges[index].show = false
                this.summary.other_charges[index].showtake = false

            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        closeOtherCharges(index) {
            //  this.$emit('updateCheckout', true)
            this.summary.other_charges[index].show = false
            this.summary.other_charges[index].showtake = false
        },
        /**
        * This method or function is used to search the cusotmers
        * @param {sort} - sort is the Object
        * @param {skip} - skip is the Integer
        * @param {limit} - limit is the Integer
        * @param {properties} - properties is the Array
        * @param {search} - search is the Object
        * @returns {response} - Get the result
        */
        async getSearchCustomer(search = '') {
            try {
                let params = {
                properties: ['first_name', 'last_name'],
                search: { term: search, fields: ['first_name', 'last_name'] },
                sort: { by: 'first_name', order: 1 },
                skip: 0,
                limit: 5
                }
                let res = await this.customerGetCustomerWith(params)
                this.customers = res.response
            } catch (reason) {
                // TODO: Handle Error
            }
        },
        closePaymentMethod() {
            this.showpay = false
            this.pay_and_close_api_inprogress = false;
            this.$modal.show(this.modal_name)
            this.$modal.hide('payment_method')
        },
        removeDisable() {
            this.activeDisable = false
            this.iscancelVselect = false
            this.iseditVselect = true
            this.IsCustVs = false
            this.IsActiveVs = true
        },
        addDisable() {
            this.activeDisable = true
            this.iscancelVselect = true
            this.iseditVselect = false
            this.selectedCustomer = this.summary.customer_name
            // this.summary.customer_name = this.selectedCustomer.first_name
            // this.summary.customer_id =  this.selectedCustomer._id
        },
        showRoundOff() {
            this.round_off = this.summary.round_off
            this.editRoundoff = !this.editRoundoff
        },
        CloseRoundOff() {
            this.editRoundoff = !this.editRoundoff
            this.summary.round_off = this.round_off
        },
        rightEditRoundoff() {
            this.editRoundoff = !this.editRoundoff
        },
        /**
        * This method or function is used to update the order discount
        * @param {orderId} - orderId is the String
        * @param {type} - type is the String
        * @param {value} - value is the Integer
        * @param {reason} - properties is the String
        * @returns {response} - Get the result
        */
        updateDiscount() {
            let data = {
                orderId: this.summary._id,
                // type: this.summary.discount.type,
                type: 'PERCENT',
                value: parseFloat(this.summary.discount.value),
                reason: this.summary.discount.reason,
            }
            if(this.sourcemanage == 'viewOrder'){
                data.summary = true
            }
            if (this.editOrder) data.update = true
            else if (this.inProgress) data.summary = true
            else if (this.isDelivered) data.isDelivered = true
            this.discount(data)
        },
        /**
        * This method or function is used to call the discount api
        * @returns {response} - Get the result
        */
        async discount(data) {
            try {
                await this.orderDiscount(data);
                this.order_charges_discount = false
                this.order_charges_discount_take = false
                this.$toasted.global.success('Order Discount Applied Successfully')
                this.updateScreen(this.summary._id)
                this.$emit('updateCheckout', true)
            }
            catch(reason){
                this.modal_msg = reason
                this.$refs.warning_modal.open()
                setTimeout(() => {
                    this.$refs.warning_modal.close()
                }, 2000);
            }
        },
        /**
        * This method or function is used to pay the amount of the order
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        prePayBill() {
            this.showpay = true
            this.$modal.hide(this.modal_name)
            this.pay_api_inprogress = true;
            this.$http.post('orders/grand_total', { order_id: this.summary._id }).then(response => {
                this.grand_total_value = response.data.value.toFixed(2)
                this.grand_total_amount = response.data.amount
                this.summary.grand_total_after_deposit_unformatted = response.data.value
                this.$modal.show('payment_method')
                this.pay_api_inprogress = false;
            }).catch((err)=>{
                this.pay_api_inprogress = false;
            })
        },
        tableSelection() {
            // this.close()
            this.$modal.show('table_modal')
            // this.inProgress = false
        },
        /**
        * This method or function is used to call the grand total api and show the payment method popup or pay for the order
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        payBillManage() {
            this.showpay = true
            this.$modal.hide(this.modal_name)
            this.pay_and_close_api_inprogress = true;
            this.$http.post('orders/grand_total', { order_id: this.summary._id }).then(response => {
                this.grand_total_value = response.data.value.toFixed(2)
                this.grand_total_amount = response.data.amount
                this.temp_amount_paid = response.data.amount_paid
                this.summary.grand_total_after_deposit_unformatted = response.data.value
                // let category_data = []
                // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                // let receipt_data = JSON.parse(sessionStorage.getItem("receipt_printer"))
                // if(data){
                //     data.forEach(y => {
                //         y.printers.forEach(x => {
                //             if(y._id == x.category_id){
                //                 category_data.push({category_id: x.category_id,printer_id:receipt_data.printer_id})
                //             }
                //         })
                //     })
                // }
                if(response.data.value < 0){
                    Swal.fire({
                        title: 'Alert',
                        text: response.data.amount + ' ' + "is total change remaining from order credits, You will not be able to close this order until grand total is greater than or equal to zero",
                        imageUrl: 'https://cdn.digicollect.com/cdn/cloud/images/delete_icon.svg',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:'OK'
                    }).then(async (result) => {
                        if (result.value) {
                            // this.temp_cash_received = Math.abs(response.data.value)
                            // let payment = [
                            //     {
                            //         customer: {
                            //             _id: this.summary.customer_id,
                            //             full_name: this.summary.customer_name,
                            //             last_name: ""
                            //         },
                            //         type: "AMOUNT",
                            //         value: 0.0,
                            //         amount: parseFloat(0.0),
                            //         share_total: parseFloat(this.summary.grand_total_after_deposit_unformatted),
                            //         percent_value: 0,
                            //         mode: this.summary.payments[0].mode,
                            //         paid_status: false,
                            //         customer_id: this.summary.customer_id,
                            //         name_on_card: this.summary.payments[0].name_on_card || '',
                            //         card_provider: this.summary.payments[0].card_provider || '',
                            //         transaction_id: this.summary.payments[0].transaction_id || '',
                            //         bank_name:this.summary.payments[0].bank_name || '',
                            //         approval_code:this.summary.payments[0].approval_code || '',
                            //         card_number:this.summary.payments[0].card_number || '',
                            //         card_type:this.summary.payments[0].card_type || 'CREDIT CARD',
                            //         reference_id:this.summary.payments[0].reference_id || '',
                            //         cash_received:0,
                            //         cash_returned: this.temp_cash_received,
                            //         payment_method:this.summary.payments[0].payment_method
                            //     }
                            // ];
                            // this.$http(this.$store.getters.loggedInUser.cashier_session.cash_register_details.server_url).then(res => {
                            //     if (res.data.status_id) {
                            //         this.$http.post('/orders/payment/split', {
                            //             orderId: this.summary._id,
                            //             fastPay: false,
                            //             note: this.summary.note,
                            //             splitDetails: payment,
                            //             cashReturned: this.temp_cash_received,
                            //             cashReceived: 0,
                            //             amount_paid: this.temp_amount_paid,
                            //             receiptEmail: this.summary.customer_email,
                            //             printer_details: category_data
                            //         }).then(res => {
                            //             if (res.data.status_id == 1) {
                            //                 if (!res.data.prompt_user_to_print) {
                            //                     setTimeout(() => {
                            //                         this.$router.push({name: 'ManageOrder'})
                            //                         this.cancePaybill()
                            //                     }, 1000)
                            //                 } else {
                            //                     setTimeout(() => {
                            //                         this.$router.push({name: 'ManageOrder'})
                            //                         let ticket_data = []
                            //                         if(data && res.data.response.hasOwnProperty('ticket_details') && res.data.response.ticket_details.length){
                            //                             res.data.response.ticket_details.forEach(z => {
                            //                                 ticket_data.push(z)
                            //                             })
                            //                         }
                            //                         this.confirmPrint(res.data.receipt_details,ticket_data)
                            //                         this.cancePaybill()
                            //                     }, 1000)
                            //                 }
                            //             } else {
                            //             }
                            //         })
                            //         .catch(err => {
                            //         })
                            //     } else {
                            //         this.$toasted.global.success(res.data.reason);
                            //     }
                            // })
                        }
                        else{
                            // this.$emit("updateOrderItems")
                            // this.cancePaybill()
                        }
                    })
                }
                else if(response.data.value > 0){
                    this.$modal.show('payment_method')
                }
                else if(response.data.value == 0){
                    // if(data){
                    //     data.forEach(y => {
                    //         y.printers.forEach(x => {
                    //             if(y._id == x.category_id){
                    //                 category_data.push({category_id: x.category_id,printer_id:receipt_data.printer_id})
                    //             }
                    //         })
                    //     })
                    // }
                    let payment = [
                        {
                            customer: {
                                _id: this.summary.customer_id,
                                full_name: this.summary.customer_name,
                                last_name: ""
                            },
                            type: "AMOUNT",
                            value: 0.0,
                            amount: parseFloat(0.0),
                            share_total: parseFloat(this.summary.grand_total_after_deposit_unformatted),
                            percent_value: 0,
                            mode: this.summary.payments[0].mode,
                            paid_status: false,
                            customer_id: this.summary.customer_id,
                            name_on_card: this.summary.payments[0].name_on_card || '',
                            card_provider: this.summary.payments[0].card_provider || '',
                            transaction_id: this.summary.payments[0].transaction_id || '',
                            bank_name:this.summary.payments[0].bank_name || '',
                            approval_code:this.summary.payments[0].approval_code || '',
                            card_number:this.summary.payments[0].card_number || '',
                            card_type:this.summary.payments[0].card_type || 'CREDIT CARD',
                            reference_id:this.summary.payments[0].reference_id || '',
                            cash_received:0,
                            cash_returned: 0,
                            payment_method:this.summary.payments[0].payment_method
                        }
                    ];
                    if(this.$store.getters.loggedInUser.cashier_session.cash_register_details.server_url == '') {
                        this.$http.post('/orders/payment/split', {
                            orderId: this.summary._id,
                            fastPay: false,
                            note: this.summary.note,
                            splitDetails: payment,
                            cashReturned: 0,
                            cashReceived: 0,
                            amount_paid: this.temp_amount_paid,
                            receiptEmail: this.summary.customer_email,
                            // printer_details: category_data
                        }).then(res => {
                            if (res.data.status_id == 1) {
                                if (!res.data.prompt_user_to_print) {
                                    setTimeout(() => {
                                        this.$router.push({name: 'ManageOrder'})
                                        this.cancePaybill()
                                    }, 1000)
                                } else {
                                    setTimeout(() => {
                                        this.$router.push({name: 'ManageOrder'})
                                        let ticket_data = []
                                        if(res.data.response.hasOwnProperty('ticket_details') && res.data.response.ticket_details.length){
                                            res.data.response.ticket_details.forEach(z => {
                                                ticket_data.push(z)
                                            })
                                        }
                                        this.confirmPrint(res.data.receipt_details,ticket_data)
                                        EventBus.$emit('updateBills', true)
                                        this.cancePaybill()
                                    }, 1000)
                                }
                            } else {
                                this.$toasted.global.success(res.data.reason);
                                this.pay_and_close_api_inprogress = false;
                            }
                        })
                        .catch(err => {
                            this.$toasted.global.success(err.response.data.reason);
                            this.pay_and_close_api_inprogress = false;
                        })
                    } else {
                        this.$http(this.$store.getters.loggedInUser.cashier_session.cash_register_details.server_url).then(res => {
                            if (res.data.status_id) {
                                this.$http.post('/orders/payment/split', {
                                    orderId: this.summary._id,
                                    fastPay: false,
                                    note: this.summary.note,
                                    splitDetails: payment,
                                    cashReturned: 0,
                                    cashReceived: 0,
                                    amount_paid: this.temp_amount_paid,
                                    receiptEmail: this.summary.customer_email,
                                    // printer_details: category_data
                                }).then(res => {
                                    if (res.data.status_id == 1) {
                                        if (!res.data.prompt_user_to_print) {
                                            setTimeout(() => {
                                                this.$router.push({name: 'ManageOrder'})
                                                this.cancePaybill()
                                            }, 1000)
                                        } else {
                                            setTimeout(() => {
                                                this.$router.push({name: 'ManageOrder'})
                                                let ticket_data = []
                                                if(res.data.response.hasOwnProperty('ticket_details') && res.data.response.ticket_details.length){
                                                    res.data.response.ticket_details.forEach(z => {
                                                        ticket_data.push(z)
                                                    })
                                                }
                                                this.confirmPrint(res.data.receipt_details,ticket_data)
                                                EventBus.$emit('updateBills', true)
                                                this.cancePaybill()
                                            }, 1000)
                                        }
                                    } else {
                                        this.$toasted.global.success(res.data.reason);
                                        this.pay_and_close_api_inprogress = false;
                                    }
                                })
                                .catch(err => {
                                    this.$toasted.global.success(err.response.data.reason);
                                    this.pay_and_close_api_inprogress = false;
                                })
                            } else {
                                this.$toasted.global.success(res.data.reason);
                                this.pay_and_close_api_inprogress = false;
                            }
                        })
                    }
                }
            })
        },
        /**
        * This method or function is used to call the print method for printing the receipt/token
        * @param {ticket_data} - ticket_data is the Array
        * @returns {response} - Get the result
        */
        confirmPrint(receipt_data,ticket_data) {
            if(ticket_data && ticket_data.length){
                if(ticket_data.length == 1){
                    this.store_ticket_data = ticket_data[0]
                    if(!this.ticket_printing){
                        this.printTicket(ticket_data[0])
                    }
                }
                else if(ticket_data.length > 1){
                    ticket_data.forEach(x => {
                        this.store_ticket_data = x
                        if(!this.ticket_printing){
                            this.printTicket(this.store_ticket_data)
                        }
                    })
                }
            }
            if(this.loggedInUser.print_receipt_by_default) {
                this.store_receipt_data = receipt_data
                this.printReceipt(receipt_data)
                if(this.loggedInUser.enable_double_receipts) {
                    setTimeout(() => {
                        this.printReceipt(receipt_data)
                    }, 5000);
                }
            } else {
                Swal({
                    title: 'Print Receipts',
                    text: 'Do You Want to Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.store_receipt_data = receipt_data
                        this.printReceipt(receipt_data)
                        if(this.loggedInUser.enable_double_receipts) {
                            setTimeout(() => {
                                this.printReceipt(receipt_data)
                            }, 5000);
                        }
                    } else {
                        this.$modal.hide(this.modal_name)
                    }
                })
            }
        },
        cancePaybill(){
            this.$modal.hide(this.modal_name)
            this.$emit('update_cust_approval');
            if(this.$route.name == 'ViewOrder'){
                this.$emit('update_cust');
            }
        },
        close() {
            this.$emit('close', true)
            this.$emit('updateCustomerDetails',this.summary.customer_name);
            this.$emit('update_cust');
        },
        /**
        * This method or function is used to close the order
        * @param {orderId} - orderId is the String
        * @returns {response} - Get the result
        */
        closeOrder() {
            this.close_order_api_inprogress = true;
            this.$http.post('/orders/close', {orderId: this.summary._id}).then(res => {
                if (res.data.status_id == 1) {
                    this.modal_msg = 'Order Closed Successfully'
                    this.$refs.success_modal.open()
                    EventBus.$emit('loadOrders', true)
                    setTimeout(() => {
                        this.$refs.success_modal.close()
                        this.$emit('PaymentComplete')
                        this.close()
                    }, 2000)
                    this.close_order_api_inprogress = false;
                }
                else{
                    this.modal_msg = res.data.reason;
                    this.$refs.warning_modal.open();
                    this.close_order_api_inprogress = false;
                 }
            }).catch((err)=>{
                this.close_order_api_inprogress = false;
            })
        },
        /**
        * This method or function is used to apply the roundoff value
        * @param {orderId} - orderId is the String
        * @param {roundOff} - roundOff is the Integer/Float
        * @returns {response} - Get the result
        */
        applyRoundOff() {
            let data = {
                roundOff: parseFloat(this.roundOff),
                orderId: this.summary._id
            }
            if (this.isDelivered) data.isDelivered = true
            if (this.editOrder) data.update = true
            this.$http.post('/orders/round_off', data).then(res => {
                if (res.data.status_id == 1) {
                    this.$emit('updateCheckout', true)
                    this.order_roundoff = false
                    this.$toasted.global.success('Round Off Applied Successfully')
                } else this.$toasted.global.error(res.data.reason)
            })
        },
        /**
        * This method or function is used to edit the tax code and call the save api
        * @param {customer_id} - customer_id is the String
        * @param {order_id} - order_id is the String
        * @param {tax_code} - tax_code is the String
        * @returns {response} - Get the result
        */
        async editTaxCode() {
            try {
                let params = {
                    customer_id: this.summary.payments[0].customer_id,
                    tax_code: this.summary.payments[0].customer_tax_code,
                    order_id: this.summary._id
                }
                await this.orderUpdateCustomerTaxCode(params)
                this.taxPopover = false
                // this.customerTaxCode = null
                this.$emit('updateCheckout', true)
                this.updateScreen(params.order_id)
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        /**
        * This method or function is used to update the order status
        * @param {in_progress} - in_progress is the Boolean
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        updateScreen(order_id) {
            this.$http.post('/orders/summary', {order_id: order_id,in_progress: true}).then(res => {
                if (res.data.status_id == 1) {
                    this.summary = res.data.order
                    console.log(this.summary)
                    if (this.summary.status == 'inactive') {
                        this.update_order = false
                    } else {
                        this.update_order = true
                    }
                }
            })
        },
        /**
        * This method or function is used to update the customer for the order
        * @param {customer_id} - customer_id is the String
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        updateOrderCustomer() {
            if (this.selectedCustomer._id) {
                this.$http.post('orders/update_customer_in_an_order', {customer_id: this.selectedCustomer._id,order_id: this.summary._id}).then(res => {
                    if (res.data.status_id == 1) {
                        this.$toasted.global.success(res.data.response.success_message)
                        this.$emit('updateCheckout', true)
                        setTimeout(() => {
                            this.IsActiveVs = false;
                            this.IsCustVs = true;
                            this.activeDisable = true
                            this.iscancelVselect = true
                            this.iseditVselect = false
                            this.getloyaltyDetails()
                            if(this.$route.name == 'ManageOrder'){
                                this.$emit('new_update_cust',this.summary);
                            }
                        }, 1000);
                    } else {
                    this.$toasted.global.error(res.data.reason)
                    }
                })
            }
        },
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        /**
        * This method or function is used to remove the applied loyality points
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        async loyaltyremove(){
            try {
                let params ={
                    order_id:this.summary._id
                }
                let res = await this.removeLoyaltyApi(params)
                this.$toasted.global.success(res.message);
                this.$emit("updateCheckout", true);
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        loyaltyTypeChange(){
            this.loyaltyValue = 0
            this.pointsdeducted = 0
            this.netpoints = 0
            this.amountdeducted = 0
            this.netamount = 0
        },
        calculateLoyalty(){
            if(this.summary.loyalty_type == 'amount'){
                this.pointsdeducted = (this.loyaltyValue/this.loyaltyDataFromApi.redemption_rate).toFixed(2)
                this.netpoints = (this.loyaltyDataFromApi.total_loyalty_points - this.pointsdeducted).toFixed(2)
            }else if(this.summary.loyalty_type == 'points'){
                this.amountdeducted = (this.loyaltyValue * this.loyaltyDataFromApi.redemption_rate).toFixed(2)
                this.netamount = (this.loyaltyDataFromApi.max_redeemable_amount - this.amountdeducted).toFixed(2)
            }
        },
        /**
        * This method or function is used to get the loyality details
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        async getloyaltyDetails(){
            try {
                let params = {
                    order_id:this.storeorderId || this.summary._id
                }
                let res = await this.loyaltyDetailsApi(params)
                this.loyaltyDataFromApi = res
            } catch (reason) {
                // this.$toasted.global.error(reason);
            }
        },
        /**
        * This method or function is used to apply the loyality points to the order
        * @param {value} - value is the Integer
        * @param {order_id} - order_id is the String
        * @param {redeem_type} - redeem_type is the String
        * @returns {response} - Get the result
        */
        async loyaltyapply(){
            try {
                let params = {
                    order_id:this.summary._id,
                    value:this.loyaltyValue,
                    redeem_type:this.summary.loyalty_type
                }
                let res = await this.applyLoyaltyApi(params)
                this.showLoyalty = false
                this.loyaltyValue = res.loyalty_amount
                this.summary.loyalty_type = res.loyalty_type
                this.$toasted.global.success(res.message);
                this.$emit("updateCheckout", true);
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        /**
        * This method or function is used to show the promotions
        * @param {customer_id} - customer_id is the String
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        showpromotions(){
            this.$modal.hide(this.modal_name)
            this.storeorderId = this.summary._id
            let params ={
                customer_id:this.summary.customer_id,
                order_id:this.summary._id
            }
            if(this.summary.hasOwnProperty('goto')){
                if(this.summary.goto == 'select_buy_products'){
                    let params={
                        order_id:this.storeorderId
                    }
                    EventBus.$emit('detailproduct',params)
                    this.$modal.show('offer-buy')
                }else if(this.summary.goto == 'select_get_products'){
                    EventBus.$emit('detailproductget')
                    this.$modal.show('offer-get')
                }else if(this.summary.goto == 'couponsListing'){
                    EventBus.$emit('showactive',params)
                    this.$modal.show('active-promotion')
                }
            }else{
                EventBus.$emit('showactive',params)
                this.$modal.show('active-promotion')
            }
        },
        promotionapply(){
            this.$emit("updateCheckout", true);
        },
        promotionshowbill(){
            this.$modal.show(this.modal_name)
            this.promotionapply()
        },
        /**
        * This method or function is used to remove the promotions
        * @param {order_id} - order_id is the String
        * @returns {response} - Get the result
        */
        async removepromotion(){
            try {
                let params={
                    order_id:this.summary._id
                }
                let res = await this.$http.post('/promotions/remove_promotion',params)
                if(res.data.status_id == 1){
                    this.$emit("updateCheckout", true);
                    this.$toasted.global.success(res.data.message);
                }else{
                    this.$toasted.global.error(res.reason);
                }
            } catch (reason) {

            }
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    mounted() {
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.getSearchCustomer()
        EventBus.$on('CloseOrder', result => {
            if (result.data.status_id == 1) {
                this.modal_msg = 'Order Closed Successfully'
                this.$refs.success_modal.open()
                setTimeout(() => {
                    this.$refs.success_modal.close()
                }, 2000)
                this.close()
            }
        })
        EventBus.$on('show order summary', () => {
            this.$modal.show(this.modal_name)
        })
        EventBus.$on('payment bill', () => {
            this.showpay = true
            this.$modal.show('payment_method')
        })
        if (this.summary.status == 'inactive') {
            this.update_order = false
        } else {
            this.update_order = true
        }
        EventBus.$on('summaryChanged', (summary) => {
            this.edit_customer_deposit_used = summary.customer_deposit_used_unformatted;
            if(this.edit_customer_deposit_used == 0) {
                this.edit_customer_deposit_used = Math.min(summary.customer_balance_unformatted, summary.grand_total_unformatted)
            }
            this.order_discount_loading = true;
            this.item_discount_loading = true;
            setTimeout(() => {
                console.log(this.summary)
                this.summary.items.forEach((item, i_index)=>{
                    item.discount['discounted_amount'] = parseFloat((parseFloat((parseFloat(item.discount.amount.substring(1,)) + item.total_unformated)) * (item.discount.value / 100)).toFixed(2));
                })
                if(this.summary.double_discount == true) {
                    this.summary_discounted_amount = parseFloat(((this.summary.sub_total.substring(1)) * (this.summary.discount.value / 100)).toFixed(2));
                } else {
                    this.summary_discounted_amount = parseFloat(((this.summary.sub_total_without_discount_items.substring(1)) * (this.summary.discount.value / 100)).toFixed(2));
                }
                this.order_discount_loading = false;
                this.item_discount_loading = false;
            }, 3000);
        })
        this.getloyaltyDetails()
    },
    watch: {
        "summary.discount.value"(newValue, oldValue) {
            if (newValue > 100 && this.summary.discount.type == "PERCENT") {
                this.$toasted.global.error(
                    "Cannot Apply Discount, Discount Percent Must be Between 0 and 100%"
                );
                this.summary.discount.value = 100;
            }else if(newValue.length > 8 && this.summary.discount.type == "AMOUNT"){
            this.summary.discount.value = newValue.slice(NaN,8)
            }
        },
        "summary.discount.type"(newValue, oldValue) {
            if (newValue == "PERCENT" && this.summary.discount.value > 100) {
                this.$toasted.global.error(
                    "Cannot Apply Discount, Discount Percent Must be Between 0 and 100%"
                );
                this.summary.discount.value = 100;
            }else if(newValue.length > 8 && this.summary.discount.type == "AMOUNT"){
            this.summary.discount.value = newValue.slice(NaN,8)
            }
        },
    },
    destroyed() {
        EventBus.$off('payment bill')
        EventBus.$off('show order summary')
        EventBus.$off('CloseOrder')
        EventBus.$off('summaryChanged')
    },
    created() {
        if (this.summary.status == 'inactive') {
            this.update_order = false
        } else {
            this.update_order = true
        }
    }
}
</script>
<style>
.w-150{
    width: 150px;
}
.content-left.specmeter{
    color: #fff !important;
    flex: 0 0 130px !important;
}
.left-flex-summary.content-left.specmeter{
    color: #fff !important;
    flex: 0 0 170px !important;
}
.content-right{
    color: #fff !important;
}
.content-right .right-div{
    color: #fff !important;
}
.div-content{
    flex: 0 0 293px;
}
.card.deposit-card{
    width: 300px!important;
    margin: 0 auto!important;
    border-radius: 12px;
    margin-top: 12px!important;
    position: absolute;
    right: 27%;
    bottom: 70px;
    z-index: 1;
}
.card.deposit-card .card-body{
    padding: 1rem!important;
    border-left: 0px solid #A1CAFF!important;
    margin-bottom: 5px!important;
    border-radius: 0px!important;
}
.card.deposit-card i{
    font-size: 17px!important;
    color: #00448b!important;
}
.deposit-details{
    border-radius: 8px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #005dae, #004fe3);
}
.Vdisabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    border-color: #fff;
}
.Vdisabled .dropdown-toggle .vs__actions .clear{
    display:none!important;
}
.border-dotted {
    font-weight: 600;
}
/* .payBill .v-modal-layout {
    max-height: 400px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
} */
.tax-code-height {
    height: 158px !important;
}
.dropdown.v-select.single.searchable.Vdisabled {
    font-weight: 600 !important;
}

.v-select.Vdisabled .dropdown-toggle {
    border: 0px solid rgba(60, 60, 60, 0.26) !important;
    border-bottom: solid 0px #cdd2da !important;
}

.v-select.single.Vdisabled .dropdown-toggle {
    background-image: none !important;
    padding-right: 0px;
    text-align: right !important;
    display: block;
}

.v-select.single.Vdisabled .dropdown-toggle .selected-tag {
    font-weight: 600 !important;
}

.cancelVselect {
    display: none !important;
}

.editVselect {
    display: none !important;
}

.el-button.el-button-edit {
    border: 0px solid #dcdfe6 !important;
    padding: 0px !important;
    background-color: transparent;
}

.total-checkout-img {
    font-weight: bold !important;
    letter-spacing: 0.4px !important;
    color: #00448b !important;
}

.qr-logo {
    position: absolute;
    margin-top: 35px;
    margin-left: -25px;
}

.roundoffInput {
    text-align: right;
    border: 1px solid #eee !important;
    border-radius: 10px;
}

.roundoffInput:disabled {
    border: 1px solid #fff !important;
}

.right-close {
    display: flex;
}

.w-57 {
    width: 57% !important;
}

.additionalCharges {
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.enterCharges {
    float: left;
    border: solid 1px #cdd2da !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    padding: 5px;
    width: 75%;
    height: 27px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    outline: none;
}

.applyCharges {
    position: absolute;
    width: 65px;
    outline: 0 !important;
    height: 27px;
    background: #f5a623;
    text-align: center;
    color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    font-size: 12px !important;
}

.payTotal.table td {
    padding: 0.4rem 1rem;
}

.payBill .tfoot-total-order {
    border-top: solid 0px #94bef0;
    border-bottom: solid 0.5px #94bef0;
}

.payBill .table.tablesubtotal tr {
    height: 10px !important;
}

.payBill .table.tablesubtotal th {
    padding: 4px !important;
}

.payBill .tablesubtotaltd,
.payBill .tablesubtotalth {
    text-align: right !important;
}

.payBill .table.tablecart th.thcart {
    color: #303031;
    line-height: 1.25;
    letter-spacing: 0.3px;
    font-size: 14px !important;
    padding: 5px 1px !important;
    text-transform: none !important;
    border-bottom: 0px solid transparent !important;
}

.payBill .table.tablecart td.tdcart {
    color: #4e5764;
    line-height: 1.25;
    letter-spacing: 0.3px;
    font-size: 12px;
    padding: 2px 1px !important;
}

.payBill .table.tablecart tfoot th.tfcart {
    padding: 10px 8px !important;
}

.payBill .table.tablecart tfoot tr {
    border-top: dotted 0.5px #b8b9bb;
    border-bottom: solid 0.5px #94bef0;
}

.payBill .table.tablecart tbody tr {
    height: auto !important;
}
.vSelectWidth.v-select.single .selected-tag{
    width: 100px !important;
}
.borderTop{
    border-top: 1px dotted #00448b87 !important;
}
.btn-secondary-custom{
    margin-top: 10px;
    background-color: #00448b;
    color: #fff !important;
    text-transform: none !important;
    font-weight: 600;
    border-radius: 6px;
    padding: 0 20px;
    width: 160px;
}
.btn-secondary-custom-loyalty{
    margin-top: 10px;
    background-color: #f2880d;
    color: #303031 !important;
    text-transform: none !important;
    font-weight: 600;
    border-radius: 6px;
    padding: 0 20px;
    width: 160px;
}
.el-button.el-button-edit {
    border: 0px solid #dcdfe6 !important;
    padding: 0px !important;
    background-color: transparent;
}
.el-button.btncustom{
    background-color: transparent;
}
.applyinput input{
    width: 300px !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    border: 1px solid rgba(0,0,0,0.16) !important;
    font-weight: 600;
}
.applyinput input ::placeholder{
    font-weight: 500;
    color: #303031 !important;
}
.btn-apply{
    background: #f5a623 !important;
    color: #303031;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.pointerEventsNone{
    pointer-events: none !important;
}
.percent-input-field,
.amount-input-field {
    padding: 4px 10.9px 4px 10.9px;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1px #abaaac; 
    box-shadow: none;
    background-color: #fff;
}
.percent-input-field-box,
.amount-input-field-box {
    padding: 4px 5.3px 4px 5px;
    background-color: #656565;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 27px;
    height: 27px;
    text-align: center;
}
.amount-input-field {
    border-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.amount-input-field-box {
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
</style>
