<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">Edit {{editName}}</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-3">
                        <div class="row first-middle-input">                    
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="wifiName"
                                        autofocus
                                        required
                                        v-model.trim="edit_wifi.name"
                                        :maxlength="wifiName"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Enter Wifi Network Name
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('wifiName')"
                                        style="display:inline;"
                                    >{{ errors.first('wifiName') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{wifiName - edit_wifi.name.length}} / {{wifiName}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="wifiPassword"
                                        autofocus
                                        required
                                        v-model.trim="edit_wifi.password"
                                        :maxlength="wifiPassword"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Enter Wifi Password
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('wifiPassword')"
                                        style="display:inline;"
                                    >{{ errors.first('wifiPassword') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{wifiPassword - edit_wifi.password.length}} / {{wifiPassword}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="v-modal-footer text-center pt-5">
                        <button class="btn btn-link btn-smm" @click="cancel" :disabled="edit_wifi_network_api_inprogress">CANCEL</button>
                        <button
                            class="btn btn-secondary btn-smm text-uppercase ml-5" :disabled="edit_wifi_network_api_inprogress"
                            @click="save"
                        >SAVE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_wifi_network_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import NetworkSettings from './mixins/networksettings'
import { EventBus } from './eventBus/nav-bar-event.js'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    data(){
       return {
            wifiName:30,
            wifiPassword:30,            
            edit_wifi:{
                name:'',
                password:'',
                type:''
            },
            editName:'',
            edit_wifi_network_api_inprogress: false
       } 
    },
    props:['modal_name'],
    mixins: [NetworkSettings],
    components: {
        HalfCircleSpinner
    },
    methods:{
        cancel(){
            this.$modal.hide(this.modal_name)
            this.edit_wifi = {
                name:'',
                password:'',
                type:''
            }
        },
        async save() {
            try {
                this.edit_wifi_network_api_inprogress = true;
                const result = await this.$validator.validateAll()
                if (result) {   
                    let name = this.edit_wifi.name
                    let password = this.edit_wifi.password
                    let _id = this.edit_wifi._id
                    let type = this.edit_wifi.type
                    let editwifi = {
                        name,
                        password,
                        _id,
                        type
                    }             
                    let response = await this.updateSetting(editwifi)
                    this.$emit('editwifi')
                    this.$swal({
                        title: 'Wifi Network Updated Successfully',
                        text: '',
                        type: 'success'
                    })                
                    this.cancel()
                    this.edit_wifi_network_api_inprogress = false;
                } else {
                    this.edit_wifi_network_api_inprogress = false;
                }
            } catch (reason) {               
                // TODO: Handle Error
                this.$swal('Warning', reason, 'warning')
                this.edit_wifi_network_api_inprogress = false;
            }
        },       
    },
    created() {
        EventBus.$on('getWifiDetailsByID',wifiId=>{
            this.$http.get('/outlet/get_network/' +wifiId).then(response => {
                if (response.data.status_id == 1) {
                    this.edit_wifi = response.data.response
                    this.editName = response.data.response.name
                }
            })
        })
        const dict = {
            custom: {
                wifiName: {
                    required: () => 'Wifi Name is Required'
                },
                wifiPassword: {
                    required: () => 'Wifi Password is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    },
    destroyed() {
      EventBus.$off('getWifiDetailsByID')
    }
}
</script>