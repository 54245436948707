<template>
	<div>
		<modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
			<div class="v-modal-content">
				<div class="v-modal-header">
					<span class="v-modal-dialog-title v-modal-title">View Printer</span>
				</div>
				<div class="v-modal-body pl-5 pr-0 pt-0">
					<div class="v-modal-layout" style="padding-right: 18px;max-height: 430px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="row pb-2" style="border-bottom:1px solid #707070 !important">
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-8">
                                <span class="text-secondary font-weight-bold">Printer Name</span>
                                <div class="font-600 mt-2">{{printerDetails.name || '-'}}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-8">
                                <span class="text-secondary font-weight-bold">Server URL</span>
                                <div class="font-600 mt-2">{{printerDetails.protocol}} {{printerDetails.ip_address || '-'}}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-8">
                                <span class="text-secondary font-weight-bold">Printer Make</span>
                                <div class="font-600 mt-2">{{printerDetails.printer_make || '-'}}</div>
                            </div>
                        </div>
                        <div class="row pb-2" style="border-bottom:1px solid #707070 !important">
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                                <span class="text-secondary font-weight-bold">Printer Model No</span>
                                <div class="font-600 mt-2">{{printerDetails.printer_model_no || '-'}}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                                <span class="text-secondary font-weight-bold">Printer Type</span>
                                <div class="font-600 mt-2">{{printerDetails.protocol}} {{printerDetails.printer_type || '-'}}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                                <span class="text-secondary font-weight-bold">Print Functions</span>
                                <div class="font-600 mt-2">
                                    <span v-if="printerDetails.printer_functions && printerDetails.printer_functions.receipts">Receipts</span>
                                    <span v-if="printerDetails.printer_functions &&  printerDetails.printer_functions.invoices">,</span>
                                    <span v-if="printerDetails.printer_functions && printerDetails.printer_functions.invoices">Invoices</span>
                                    <span v-if="printerDetails.printer_functions && printerDetails.printer_functions.tickets">,</span>
                                    <span v-if="printerDetails.printer_functions && printerDetails.printer_functions.tickets">Tickets</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                                <div class="text-secondary font-weight-bold">Categories</div>
                                <div class="d-flex">
                                    <span v-for="(category,index) in printerDetails.category_ids" :key="index">{{category.name}}
                                        <span v-if="index != printerDetails.category_ids.length - 1"> ,</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-table mt-3">
							<table class="table">
								<thead>
									<tr>
										<th>Category Name</th>
										<th>No of Copies for Tickets</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(category,index) in printerDetails.no_of_copies_details" :key="index">
										<td>{{category.name}}</td>
										<td>{{category.no_of_copies}}</td>
									</tr>
								</tbody>
							</table>
						</div> -->
					</div>
				</div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
					<a class="btn btn-link btn-smm" @click="close()">CANCEL</a>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event'
export default {
    props: ['modal_name'],
    data(){
        return{
            printerDetails:''
        }
    },
    methods:{
        close(){
            this.$modal.hide(this.modal_name)
        },
        getPrinterById(printer_id) {
            this.$http.post('outlet/get_printer_by_id', {printer_id: printer_id})
            .then(res => {
                if (res.data.status_id == 1) {
                    this.printerDetails = res.data.response
                }
            })
        },
    },
    mounted(){
        EventBus.$on('viewPrinterById', printer_id => {
            this.getPrinterById(printer_id)
        })
    }
}
</script>
<style scoped>
.font-600{
    font-weight: 600 !important;
}
.product-table table thead{
	background: #00448b !important;
}
.product-table table tbody{
	border:1px solid #cdcdcd !important;
    background:#fff !important;
}
.product-table table thead th{
	color: #fff !important;
}
</style>