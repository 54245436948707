<template>
    <div id="forbidden-screen">
        <div class="center-screen">
            <div class="image-forbidden">
                <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/403-new.svg" alt="DigiCollect">
                <p class="text-center no-access pt-2">We're sorry, you don't have access to the page you requested. Please go back to the 
                    <router-link to="/home" class="home-link">
                        <span class="ml-1">Homepage</span>
                    </router-link>
                </p>
            </div>
        </div>
    </div>
    <!-- <div class="one">
        <div class="center-screen">
            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="20"
                :dots-num="3"
                :color="'#00448b'"
            />
        </div>
    </div> -->
</template>
<style>
.one {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 99999;
}
.one .center-screen {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.hollow-dots-spinner {
    text-align: center-screen;
    margin: 0 auto;
}

#forbidden-screen {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 99999;
}
#forbidden-screen .center-screen {
    display: block;
    width: 100%;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
}
#forbidden-screen .image-forbidden {
    width: 650px;
    margin: 0 auto;
}
#forbidden-screen  .no-access{
    color: #939495;
    font-size: 14px;
    font-weight: 500;
}
@media (max-width: 768px) {
    #forbidden-screen .image-forbidden {
        width: 380px;
    }
}
/* #forbidden-screen .image-forbidden img {
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
} */
#forbidden-screen .image-forbidden img {
    width: 100%;
}
#forbidden-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
    text-align: center-screen;
}

#forbidden-screen .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
}

#forbidden-screen .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#forbidden-screen .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
}

#forbidden-screen .spinner-wrapper .spinner .inner .left,
#forbidden-screen .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}

#forbidden-screen .spinner-wrapper .spinner .inner .left .half-circle,
#forbidden-screen .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #005dae;
    border-bottom-color: transparent;
    border-radius: 50%;
}

#forbidden-screen .spinner-wrapper .spinner .inner .left {
    left: 0;
}

#forbidden-screen .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#forbidden-screen .spinner-wrapper .spinner .inner .right {
    right: 0;
}

#forbidden-screen .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
.home-link{
    text-decoration: none!important;
    font-weight: 600;
    cursor: pointer;
}
@keyframes outer-rotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }
    100% {
        transform: rotate(360deg) scale(0.5);
    }
}

@keyframes left-wobble {
    0%, 100% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

@keyframes right-wobble {
    0%, 100% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
}

@keyframes sporadic-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}



#forbidden-screen .spinner-wrapper p {
    font-size: 24px;
    padding-top: 20px;
    color: #00448b;
    display: inline-block;
}
#forbidden-screen .spinner-wrapper div.wave {
    display: inline-block;
    padding-left: 5px;
}
.wave .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    margin-right: 1px;
    background: #00448b;
    animation: wave 1.3s linear infinite;
}

.wave .dot:last-of-type{
    margin-right:0
}
.wave .dot:nth-child(2){
    animation-delay:-1.1s
}
.wave .dot:nth-child(3){
    animation-delay:-.9s
}
@keyframes wave{
    0%,100%,60%{
        transform:initial
    }
    30%{
        transform:translateY(-6px)
    }
}
</style>

<script>
  export default {
    methods: {
      getOut() {
        window.location.href = "https://admin.digicollect.com";
      }
    }
  };
</script>
