import global from "../global.js"
export default {
    data() {
        return {
            form: {
                login_id: "",
                password: ""
            },
            confirmPassword: "",
            errorMessage: "",
            show: true,
            signedIn: false,
            showPassword: false,
            showNewPassword: false,
            showConfirmNewPassword: false,
            activediv: "login",
            countdown: 60,
            otpInvalid: false,
            otpValue: "",
            callInProgress: false,
            otpVerifying: false,
        }
    },
    methods:{
        // SignUp/Register APIS
        async checkEmailPhoneExistsOrNot(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/registration/login_id/exists", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async register(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/registration/", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async acceptTwoFactor(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/registration/set_user_settings", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async verifyUserFromLink(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.ADMIN_API + `/registration/verify?user_id=${params.user_id}&secret_key=${params.secret_key}&link_for=${params.link_for} `);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        // Login APIS
        async checkLogin(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async resendVerify(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/registration/resend/link", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async loginUsingPassOtp(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async sendLoginOtp(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/otp", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async verifyOtpNew(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/otp/verify", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        // Forget Password
        async getForgetPassOtp(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/otp", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },
        async passwordReset(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/reset", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },

        // Password Change
        async authPasswordChange(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.ADMIN_API + "/auth/password/change", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(err){
                    reject(err.response.data.reason)
                }
            })
        },

        // Integration Methods

        registerUser() {
            // this.$router.push("/register");
            window.open(global.AUTH_URL+'/register', '_blank');
        },
        switchView(view) {
            EventBus.$emit("viewChanged", view);
        },
        forgotPassword() {
            this.form.login_id = "";
            this.form.password = "";
            this.errorMessage = "";
            this.activediv = "forgot password";
        },
        forgotPasswordCancel() {
            // window.location.reload()
            this.form.login_id = "";
            this.form.password = "";
            this.errorMessage = "";
            this.enter_email_screen = true;
            this.sign_in_using_otp = false;
            this.two_factor_authentication = false
            this.two_factor_auth_pass_screen_active = true;
            this.forgot_pass_otp_success = false;
            this.enter_pass_screen = false;
            this.login_btn_disable = false;
            this.user_not_verified = false;
            this.activediv = "login";
        },
        otpEntered(value) {
            this.errorMessage = "";
            this.otp_error_text = '';
            this.otpInvalid = false;
            this.otpValue = value;
            this.otp_signin_btn = true;
        },
        resetSuccessfull() {
            this.resend_success_full_btn_clicked = true;
            if (this.$route.query.redirect_to) {
                window.location.href = this.$route.query.redirect_to;
            } else {
                window.location.href = global.POS_URL;
            }
        },
        countDownTimer() {
            this.show_countdown = true
            if (this.countdown > 0 && (this.activediv === "verify otp" || this.sign_in_using_otp || !this.two_factor_auth_pass_screen_active) && !this.otpVerifying) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else if (this.countdown === 0 && !this.otpVerifying) {
                // this.clearOtpInputs();
                this.show_countdown = false
            }
        },
        otpChangeDetected(val) {
            console.log(val);
            if(val.length<6) {
                this.otp_signin_btn = false;
            }
        }
    },
    watch: {
        activediv: function(newVal,oldVal) {
            if (newVal) {
                this.errorMessage = "";
            }
            if (newVal == "verify otp") {
                this.countDownTimer();
            } else {
                this.countdown = 60;
            }
        },
        countdown: function(val) {
            if (val == 0) {
                this.$refs.otpInput.clearInput();
                this.otpValue = "";
                this.otp_signin_btn = false;
            }
        },
        confirmPassword: function(val) {
            if (val) {
                if (!(this.form.password == val)) {
                    this.errorMessage = "Passwords do not match";
                } else {
                    this.errorMessage = "";
                }
            }
        },
        "form.password": function(newVal, oldVal) {
            if (newVal) {
                this.errorMessage = "";
                this.password_error_text = "";
            }
        },
        "form.login_id": function(newVal, oldVal) {
            if (newVal) {
                this.is_email_phone_valid_text = "";
            }
        },
        // account_type: function() {
        //     if (this.errorMessage) {
        //         this.errorMessage = "";
        //     }
        // }
    }
}
