<template>
	<div>
		<modal :name="modal_brand" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-430">
			<div class="v-modal-content">
				<div class="v-modal-header">
					<span class="v-modal-dialog-title v-modal-title">Edit Printer</span>
				</div>
            	<div class="general-section" style="padding: 15px 0px;">
				<div class="pl-5 pr-2 pt-0">
					<div class="">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12">
								<div class="form-group mt-0 contactGroup">
									<input autocomplete="off" @input="nameExists=false" required v-model="printer.name" class="inputContact" v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}" type="text" name="name" autofocus :maxlength="printername"/>
									<span class="highlightContact"></span>
									<span class="barContact"></span>
									<label class="labelContact">Printer Name<sup>★</sup></label>
									<span class="invalid-feedback-form" style="display:contents;" v-show="errors.has('name')">{{ errors.first('name') }}</span>
									<div v-show="nameExists" class="text-danger fs-13">{{errorMsg}}</div>
									<p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{printername - printer.name.length}} / 30</p>
								</div>
							</div>
						</div>
						<div class="row align-items-center" style="margin-top: -30px !important;">
							<div class="col-lg-3 col-md-3 col-sm-3">
								<v-select v-model="printer.protocol" class="filterName" label="name" placeholder="Printer Type" :filterable="true" :options="['http://','https://']"></v-select>
							</div>
							<div class="col-lg-9 col-md-9 col-sm-9">
								<div class="form-group mt-0 contactGroup">
									<input required v-model="printer.ip_address" @input="markFalse()" class="inputContact" v-validate="'required'" type="text" name="server_url" :maxlength="printerurl" />
									<span class="highlightContact"></span>
									<span class="barContact"></span>
									<label class="labelContact">Printer URL<sup>★</sup></label>
									<span class="invalid-feedback-form text-danger fs-13" style="display:contents;" v-show="errors.has('server_url')">Printer URL is Required</span>
									<p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{printerurl - printer.ip_address.length}} / 200</p>
									<div v-show="!isIpValid" class="text-danger fs-13">Invalid Ip Address</div>
									<div v-show="ipExists" class="text-danger fs-13">{{errorMsg}}</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6 col-md-6 col-sm-6">
								<span class="fs-14" style="color:#00448b;">Printer Make<sup>★</sup></span>
								<multiselect style="background-color:#cddbeb !important" @open="getMakeDetails()" @close="updatemakeModel(printer.printer_make)" v-validate="'required'" class="digi-multiselect" label="printer_make" name="printer_make" v-model="printer.printer_make"  :options="makeArray" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Printer Make">
								</multiselect>
								<span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('printer_make')">Printer Make is Required</span>
							</div>
							<div class="col-lg-6 col-md-6 col-sm-6">
								<span class="fs-14" style="color:#00448b;">Printer Model No<sup>★</sup></span>
								<multiselect style="background-color:#cddbeb !important" v-validate="'required'" @open="getModeDetails(printer.printer_make)" class="digi-multiselect" label="model" name="printer_model_no" v-model="printer.printer_model_no" @close="updateType(printer.printer_model_no)" :options="makeModel" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Printer Model No.">
								</multiselect>
								<span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('printer_model_no')">Printer Model No is Required</span>
							</div>
						</div>
						<div class="row mt-6">
							<div class="col-lg-3 col-md-3 col-sm-3">
								<span class="text-secondary font-weight-bold whiteSpace">Printer Type ?*</span>
							</div>
							<div class="col-lg-9 col-md-9 col-sm-9">
								<el-radio-group v-model="printer.printer_type"  v-validate="'required'" name="printer_type" style="pointer-events:none !important;">
									<el-radio label="Thermal">Thermal</el-radio>
									<el-radio label="Dot_Matrix">Dot-Matrix</el-radio>
								</el-radio-group>
								<span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('printer_type')">Printer Type is Required</span>
							</div>
						</div>
						<!-- <div class="row mt-6">
							<div class="col-lg-3 col-md-3 col-sm-3">
								<span class="text-secondary font-weight-bold whiteSpace">Print Functions ?*</span>
							</div>
							<div class="col-lg-9 col-md-9 col-sm-9">
								<div>
									<el-checkbox class="" v-model="printer.printer_functions.receipts" @change="checkupdateAll()">
										<span>
											Receipts
											<el-tooltip class="item" effect="dark" content="Only Closed order receipts can be Reprinted" placement="top">
												<i class="icon icon-information" style="cursor: auto !important;width: 15px !important;height: 15px !important;font-size: 16px !important;color: #00448b;bottom: 0px;position: relative;"></i>
											</el-tooltip>
										</span>
									</el-checkbox>
									<el-checkbox class="" v-model="printer.printer_functions.invoices" @change="checkupdateAll()">Invoices</el-checkbox>
									<el-checkbox class="" v-model="printer.printer_functions.tickets" @change="checkupdateAll()">
										<span>
											Tickets
											<el-tooltip class="item" effect="dark" content="On Click of Tickets Category will be shown" placement="top">
												<i class="icon icon-information" style="cursor: auto !important;width: 15px !important;height: 15px !important;font-size: 16px !important;color: #00448b;bottom: 0px;position: relative;"></i>
											</el-tooltip>
										</span>
									</el-checkbox>
									<el-checkbox class="" v-model="checkedAll" @change="updateAll()">All</el-checkbox>
								</div>
								<span class="invalid-feedback-form" v-if="printFunction">Please Select Any 1 Print Functions</span>
							</div>
						</div> -->
						<!-- <div class="row mt-3" v-if="printer.printer_functions.tickets">
							<div class="col-md-6">
								<span class="text-secondary font-weight-bold whiteSpace">Categories</span>
								<el-checkbox class="ml-3 mr-3" v-model="printer.all" @change="selectAllCategory()"></el-checkbox>
								<span class="font-weight-bold whiteSpace mr-3">Select All Category</span>
								<el-tooltip class="item" effect="dark" content="Select All Category" placement="top">
									<i class="icon icon-information" style="cursor: auto !important;width: 15px !important;height: 15px !important;font-size: 16px !important;color: #00448b;bottom: 0px;position: relative;"></i>
								</el-tooltip>
							</div>
						</div>
						<div class="row mt-3" v-if="printer.printer_functions.tickets">
							<div class="col-lg-12 col-md-12 col-sm-12">
								<multiselect
									:disabled="printer.all"
									v-model="temp_category"
									class="digi-multiselect category-multiple-addon"
									id="ajax1"
									label="name"
									:options="categories"
									:multiple="true"
									:searchable="true"
									:internal-search="true"
									:loading="isLoading"
									:clear-on-select="false"
									:close-on-select="false"
									:max-height="200"
									:show-no-results="true"
									:hide-selected="false"
									:limit="3"
									track-by="category_id"
									@search-change="loadCategorySearch"
									:limit-text="limitText"
									:blockKeys="['Delete']"
									placeholder="Select Category"
									@remove="removeCategory"
									@select="updatecategory"
									v-validate="'required'"
									name="category"
								>
									<template slot="tag" slot-scope="{ option }">
										<span class="custom__tag">
											<span>{{ option.name }}</span>
											<span class="custom__remove" @click="removeCategory(option)">
												<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
											</span>
										</span>
									</template>
									<template slot="afterList">
										<div v-observe-visibility="reachedEndOfList" />
									</template>
									<span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
								</multiselect>
								<span class="invalid-feedback-form" v-show="errors.has('category')" style="display:inline;">Category is Required</span>
							</div>
						</div> -->
						<!-- <div class="product-table mt-3" v-if="printer.no_of_copies_details.length">
							<table class="table">
								<thead>
									<tr>
										<th>Category Name</th>
										<th>No of Copies for Tickets</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(category,index) in printer.no_of_copies_details" :key="index">
										<td>{{category.name}}</td>
										<td>
											<input :maxlength="2" v-model.number="category.no_of_copies" type="text" :name="'copy'+index" v-validate="'required'" v-on:keypress="isNumber($event)" style="background:transparent !important;border-bottom:1px solid #cdcdcd !important;"><br>
											<span class="invalid-feedback-form" v-show="errors.has('copy'+index)" style="display:inline;">Required</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div> -->
					</div>
				</div>
				</div>
				<div class="v-modal-dialog-actions modal-bg text-center pt-5">
					<button class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1" :disabled="edit_printer_api_inprogress"	@click="cancelPrinter()">CANCEL</button>
					<button class="btn btn-secondary btn-smm text-uppercase ml-5" :disabled="edit_printer_api_inprogress" @click="savePrinter()">SAVE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_printer_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
					</button>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import VModal from 'vue-js-modal'
import { EventBus } from './eventBus/nav-bar-event'
import { HalfCircleSpinner } from "epic-spinners";
export default {
	props: ['modal_brand','outlet'],
	data() {
		return {
			printFunction:false,
			skip:0,
			limit:10,
			isVisible:false,
			isLoading:false,
			count:0,
			scrollableCategory:false,
			checkedAll:false,
			temp_category:[],
			temp_categories:[],
			categories:[],
			printername: 30,
			printerurl: 200,
			printer: {
				all:false,
				printer_id:null,
				name: '',
				protocol: 'https://',
				ip_address: '',
				outlet_id: this.$route.query.outletId,
				printer_make:'',
				printer_model_no:'',
				printer_type:'',
				printer_functions:{
					receipts:false,
					invoices:false,
					tickets:false
				},
				category_ids:[],
				no_of_copies_details:[]
			},
			isIpValid: true,
			ipExists: false,
			nameExists: false,
			errorMsg: '',
			makeArray:[],
			makeModel:[],
			edit_printer_api_inprogress: false
		}
	},
	components:{
		HalfCircleSpinner
	},
	methods: {
		isNumber: function(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
		updateType(value){
            if(value != ''){
                this.printer.printer_type = value.type
            }
        },
		updatemakeModel(list){
			if(list != ''){
				this.printer.printer_model_no = ''
				this.makeModel = list.printer_details
			}
		},
		getModeDetails(list){
			this.makeArray.forEach(el=>{
				if(el.printer_make == list.printer_make){
					this.makeModel = el.printer_details
				}
			})
		},
		cancelPrinter() {
			this.isLoading = false
			this.scrollableCategory = false
			this.isVisible = false
			this.printFunction = false
			this.temp_categories = []
			this.temp_category = []
			this.categories = []
			this.printer = {
				all:false,
				printer_id:null,
				name: '',
				protocol: 'https://',
				ip_address: '',
				printer_make:'',
				printer_model_no:'',
				printer_type:'',
				printer_functions:{
					receipts:false,
					invoices:false,
					tickets:false
				},
				category_ids:[],
				no_of_copies_details:[]
			}
			this.ipExists = false
			this.nameExists = false
			this.isIpValid = true
			this.$emit('updatePrinter', true)
			this.$modal.hide(this.modal_brand)
		},
		// checkPrintFunction(){
		// 	if(!this.printer.printer_functions.invoices && !this.printer.printer_functions.receipts && !this.printer.printer_functions.tickets){
		// 		this.printFunction = true
		// 	}else{
		// 		this.printFunction = false
		// 	}
		// },
		savePrinter() {
			// this.checkPrintFunction()
			this.$validator.validateAll().then(result => {
				if (result) {
					// let regexIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
					// if (!this.printer.ip_address.match(regexIp)) {
					// 	this.isIpValid = false
					// 	return
					// }
					// const result = []
					// const map = new Map();
					// for (const item of this.printer.no_of_copies_details) {
					// 	if(!map.has(item.category_id)){
					// 		map.set(item.category_id, true);    // set any value to Map
					// 		result.push({
					// 			category_id:item.category_id,
					// 			no_of_copies:item.no_of_copies
					// 		})
					// 	}
					// }
					this.edit_printer_api_inprogress = true;
					console.log(this.printer)
					// console.log(this.printer.printer_id)
					let params = {
						printer_id:this.printer._id,
						// all:this.printer.all,
						name:this.printer.name,
						protocol: this.printer.protocol,
						ip_address: this.printer.ip_address,
						printer_make:this.printer.printer_make.printer_make || '',
						printer_model_no:this.printer.printer_model_no.model || '',
						printer_type:this.printer.printer_type,
						outlet_id: this.outlet._id
						// printer_functions:{
						// 	receipts:this.printer.printer_functions.receipts,
						// 	invoices:this.printer.printer_functions.invoices,
						// 	tickets:this.printer.printer_functions.tickets
						// },
						// category_ids:[...new Set(this.temp_category.map(x =>x.category_id))],
						// no_of_copies_details:result
					}
					this.$http.post('outlet/update_printer', params).then(res => {
						if (res.data.status_id == 1) {
							this.$swal('Updated', 'Successfully Updated Printer', 'success')
							this.cancelPrinter()
						} else if (res.data.ipExists) {
							this.ipExists = true
							this.errorMsg = res.data.message
						} else {
							this.nameExists = true
							this.errorMsg = res.data.message
						}
						this.edit_printer_api_inprogress = false;
					}).catch((err) => {
						this.edit_printer_api_inprogress = false;
					})
				}
			})
		},
		markFalse() {
			this.ipExists = false
			this.isIpValid = true
		},
		async getMakeDetails(){
			try {
				this.makeArray = []
				let res = await this.$http.get('/printer/get_printer_make_details')
				this.makeArray =  res.data.message
			} catch (reason) {

			}
		},
		updateAll(){
			if(this.checkedAll){
				this.printer.printer_functions.invoices = true
				this.printer.printer_functions.receipts = true
				// this.printer.printer_functions.tickets = true
			}else{
				this.printer.printer_functions.invoices = false
				this.printer.printer_functions.receipts = false
				// this.printer.printer_functions.tickets = false
				this.count = 0
				this.temp_category = []
				// this.printer.no_of_copies_details = []
			}
		},
		checkupdateAll(){
			// if(!this.printer.printer_functions.tickets){
			// 	this.count = 0
			// 	this.temp_category = []
			// 	this.printer.no_of_copies_details = []
			// }
			// if(this.printer.printer_functions.invoices && this.printer.printer_functions.receipts && this.printer.printer_functions.tickets){
			// 	this.checkedAll = true
			// }else{
			// 	this.checkedAll = false
			// }
		},
		// async selectAllCategory(){
		// 	if(this.printer.all){
		// 		let response = await this.$http.post('/scm/category_filter', {
		// 			search_key: this.search_key,
		// 			limit: 100,
		// 			skip: 0
		// 		})
		// 		if (response.data.status_id == 1) {
		// 			this.categories = response.data.response
		// 			this.temp_categories = response.data.response
		// 			this.count = this.temp_categories.length
		// 			this.isLoading = false
		// 			this.printer.no_of_copies_details = []
		// 			this.categories.forEach((category,index)=>{
		// 				this.temp_category.push(category)
		// 				category['no_of_copies'] = 1
		// 				this.printer.no_of_copies_details.push(category)
		// 			})
		// 			const result1 = [];
		// 			const map1 = new Map();
		// 			for (const item of this.temp_category) {
		// 				if(!map1.has(item.category_id)){
		// 					map1.set(item.category_id, true);    // set any value to Map
		// 					result1.push({
		// 						category_id:item.category_id,
		// 						name:item.name
		// 					})
		// 				}
		// 			}
		// 			this.temp_category = result1
		// 			const result = [];
		// 			const map = new Map();
		// 			for (const item of this.printer.no_of_copies_details) {
		// 				if(!map.has(item.category_id)){
		// 					map.set(item.category_id, true);    // set any value to Map
		// 					result.push({
		// 						category_id:item.category_id,
		// 						name:item.name,
		// 						no_of_copies:item.no_of_copies
		// 					})
		// 				}
		// 			}
		// 			this.printer.no_of_copies_details = result
		// 		}
		// 	}else{
		// 		this.count = 0
		// 		this.temp_category = []
		// 		this.printer.no_of_copies_details = []
		// 	}

		// },
		// removeCategory(option) {
		// 	this.temp_category.forEach((category, index) => {
		// 		if (this.temp_category[index].category_id == option.category_id) {
		// 			this.temp_category.splice(index, 1)
		// 		}
		// 	})
		// 	this.printer.no_of_copies_details.forEach((category, index) => {
		// 		if (this.printer.no_of_copies_details[index].category_id == option.category_id) {
		// 			this.printer.no_of_copies_details.splice(index, 1)
		// 		}
		// 	})
		// },
		// updatecategory(option){
		// 	option['no_of_copies'] = 1
		// 	this.printer.no_of_copies_details.push(option)
		// 	const result = [];
		// 	const map = new Map();
		// 	for (const item of this.printer.no_of_copies_details) {
        //         if(!map.has(item.category_id)){
        //             map.set(item.category_id, true);    // set any value to Map
		// 			result.push({
		// 				category_id:item.category_id,
		// 				name:item.name,
		// 				no_of_copies:item.no_of_copies
		// 			})
		// 		}
		// 	}
		// 	this.printer.no_of_copies_details = result
		// },
		// Category Start
		// async loadCategorySearch(search_key) {
		// 	this.isLoading = true
		// 	let response = await this.$http.post('/scm/category_filter', {
		// 		search_key: search_key,
		// 		skip: 0,
		// 		limit: 10
		// 	})
		// 	this.categories = response.data.response
		// 	this.count = this.temp_categories.length
		// 	this.isLoading = false
		// 	this.scrollableCategory = false
		// },
		// async reachedEndOfList(reached) {
		// 	this.isVisible = reached
		// 	if (reached) {
		// 		if (this.scrollableCategory == false) {
		// 			this.isLoading = true
		// 			let response = await this.$http.post('/scm/category_filter', {
		// 				search_key: this.search_key,
		// 				skip: this.count,
		// 				limit: 10
		// 			})
		// 			this.temp_categories = response.data.response.length
		// 			if (response.data.skip == this.count) {
		// 				if (this.temp_categories > 0) {
		// 					let tempFiles = response.data.response
		// 					tempFiles.forEach(el => {
		// 						this.categories.push(el)
		// 					})
		// 					this.count += response.data.response.length
		// 					this.isLoading = false
		// 				} else {
		// 					this.scrollableCategory = true
		// 					this.isLoading = false
		// 				}
		// 			}
		// 			this.isLoading = false
		// 		}
		// 	}
		// },
		// async getCategory() {
		// 	let response = await this.$http.post('/scm/category_filter', {
		// 		search_key: this.search_key,
		// 		limit: 100,
		// 		skip: 0
		// 	})
		// 	this.count = 0
		// 	if (response.data.status_id == 1) {
		// 		this.temp_categories = []
		// 		this.categories = []
		// 		this.isLoading = false
		// 	}
		// },
		limitText(count) {
			return `and ${count} Categories`
        },
        getPrinterById(printer_id) {
            this.$http.post('outlet/get_printer_by_id', {printer_id: printer_id})
            .then(res => {
                if (res.data.status_id == 1) {
                    this.printer = res.data.response
                    this.printer.printer_make = {printer_make:this.printer.printer_make}
					this.printer.printer_model_no = {model:this.printer.printer_model_no}
					this.temp_category = this.printer.category_ids
					// if(this.printer.printer_functions.invoices && this.printer.printer_functions.receipts && this.printer.printer_functions.tickets){
					// 	this.checkedAll = true
					// }else{
					// 	this.checkedAll = false
					// }
                }
            })
        },
	},
	created() {
		const dict = {
			custom: {
				name: {
					required: () => 'Printer Name is Required',
					regex: () => 'Enter Valid Printer Name'
				},
				server_url: {
					required: () => 'IP Address is Required'
				}
			}
		}
		this.$validator.localize('en', dict)
	},
	mounted(){
		EventBus.$on('editPrinter', printer_id => {
            this.getPrinterById(printer_id)
        })
	}
}
</script>
<style scoped>
input:required {
  box-shadow: none !important;
}
.whiteSpace{
	white-space: nowrap !important;
}
.product-table table thead{
	background: #00448b !important;
}
.product-table table tbody{
	border:1px solid #cdcdcd !important;
	background:#fff !important;
}
.product-table table thead th{
	color: #fff !important;
}
.category-multiple-addon .custom__tag {
    background: #00448b;
    color: #fff;
}
.category-multiple-addon .multiselect__strong {
    line-height: 26px!important;
}
</style>
