
import Swal from 'sweetalert2'
export default {
    data(){
        return{
            show_progress: false,
            printer: null,
            epsonPOSDevice: null,
            isRegisteredPrinterConnected: false,
            printer_port: 8008,
            printer_ip_address: "",
            print_success: "",
            object_created: "",
            store_ticket_data: null,
            store_receipt_data: null,
            store_invoice_data: null,
            customer_copy: false,
            show_item_deleted: false,
            show_order_deleted: false,
            drawer_open: false
        }
    },
    components:{
        Swal
    },
    methods:{
        initiatePrinter() {
            console.log("Init Printer",location.protocol);
            if (location.protocol == 'https://' || location.protocol == 'https:') {
                // page is secure
                this.printer_port = 8043;
            }
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    if (res.data.status_id == 1) {
                        this.$store.commit("setCashRegister",res.data.response.cash_register_details);
                        this.printer_ip_address = res.data.response.cash_register_details.printer_server_url
                        console.log(this.printer_ip_address)
                        this.epsonPOSDevice = new window.epson.ePOSDevice();
                        this.epsonPOSDevice.connect(this.printer_ip_address, this.printer_port, this.cbConnect, true);
                    } else {
                        this.$toasted.global.error(res.data.reason);
                    }
                });
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        //Printing
        cbConnect(data) {
            console.log(data);
            if (data == 'OK' || data == 'SSL_CONNECT_OK') {
                this.epsonPOSDevice.createDevice('local_printer', this.epsonPOSDevice.DEVICE_TYPE_PRINTER,{'crypto': false, 'buffer': false}, this.cbCreateDevicePrinter);
            } else {
                console.log(data);
            }
        },
        cbCreateDevicePrinter(devobj, retcode) {
            console.log(devobj,retcode)
            if (retcode == 'OK') {
                this.printer = devobj;
                this.printer.timeout = 150000;
                this.printer.onreceive = function (res) {
                    console.log(res) //alert(res.success);
                    setTimeout(() => {
                        this.print_success = "Printed Successfully"
                        this.object_created = "Printed Successfully"
                    }, 100);
                };
                // this.printer.onreceive.then((response) => {
                //     console.log(response);
                //     setTimeout(() => {
                //         this.print_success = "Printed Successfully"
                //         this.object_created = "Printed Successfully"
                //     }, 100);
                // }).catch((error) => {
                //     console.log(error);
                //     console.log(error.response);
                // });
                this.printer.oncoveropen = function () { //alert('coveropen');
                    console.log("Printer Cover Open");
                }
            } else {
                console.log(retcode);
                this.isRegisteredPrinterConnected = false;
            }
        },
        //invoice
        printInvoice(order) {
            order = this.store_invoice_data
            // this.$modal.show("in-progress")
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintingInvoice()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText(order.outlet_details.outlet_name + '\n');
            print.addText(order.outlet_details.website + '\n');
            print.addText(order.outlet_details.outlet_address.street + '\n');
            print.addText(order.outlet_details.outlet_address.city + ',' + order.outlet_details.outlet_address.zipcode + '\n');
            print.addText(order.outlet_details.outlet_address.country + '\n');
            print.addText('Tel :' +' '+order.outlet_details.phone_number + '\n');
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                      ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Invoice No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.invoice_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.tax_code + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Table Number :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.table_number + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_details.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.other_details.ordered_by + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Bill Generated By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.other_details.generated_by + '\n');

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Qty' + '  ');

            // print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Items' + '                           ');

            // print.addTextAlign(print.ALIGN_CENTER);

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            for(let i = 0 ; i<order.ordered_items.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addText(' '+order.ordered_items[i].quantity+' '.repeat(5 - order.ordered_items[i].quantity.toString().length));

                print.addTextFont(print.FONT_A);
                if(order.ordered_items[i].product_name.length == 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name+ '**');
                    } else {
                        print.addText(order.ordered_items[i].product_name+'  ');
                    }
                }
                else if(order.ordered_items[i].product_name.length < 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name +'**'+' '.repeat(30 - (order.ordered_items[i].product_name.length + 2))+'  ');
                    } else {
                        print.addText(order.ordered_items[i].product_name+' '.repeat(30 - order.ordered_items[i].product_name.length)+'  ');
                    }
                } else if(order.ordered_items[i].product_name.length > 30) {
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30) +'**');
                    } else {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30)+'  ');
                    }
                }

                // print.addTextAlign(print.ALIGN_CENTER);

                print.addTextSize(1,1);
                print.addText(order.ordered_items[i].product_price_exclusive + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.ordered_items[i].hasOwnProperty('modifiers')){
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary') || order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        print.addTextSize(1,1);
                        print.addText('      '+'Modifiers :' + '\n');
                        print.addTextStyle(false, false, false, print.COLOR_NONE);
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary')){
                        if(order.ordered_items[i].modifiers.for_secondary.length > 0) {
                            // print.addText('  '+`${order.ordered_items[i].modifiers.variant_name}` + '\n');
                            // print.addTextStyle(false, false, false, print.COLOR_NONE);
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_secondary.length;j++){
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                // print.addTextPosition(6);

                                print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_secondary[j].quantity.toString().length));

                                print.addTextSize(1,1);
                                if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length == 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+'  ');
                                }
                                else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length < 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length)+'  ');
                                } else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length > 30) {
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.substring(0, 30)+'  ');
                                }

                                // print.addTextAlign(print.ALIGN_RIGHT);
                                print.addTextSize(1,1);
                                print.addText(order.ordered_items[i].modifiers.for_secondary[j].amount + '\n');
                            }
                        }
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        if(order.ordered_items[i].modifiers.for_primary.length > 0) {
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_primary.length;j++){
                                for(let k = 0; k<order.ordered_items[i].modifiers.for_primary[j].modifier_items.length;k++){
                                    print.addTextSize(1,1);
                                    print.addTextFont(print.FONT_A);
                                    // print.addTextPosition(6);

                                    print.addText(' '+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity.toString().length));

                                    print.addTextSize(1,1);
                                    if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length == 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+'  ');
                                    }
                                    else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length < 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length)+'  ');
                                    } else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length > 30) {
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.substring(0, 30)+'  ');
                                    }

                                    // print.addTextAlign(print.ALIGN_RIGHT);
                                    print.addTextSize(1,1);
                                    print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                                }
                            }
                        }
                    }
                }

                // if(order.ordered_items[i].addons.length){
                //     print.addTextSize(1,1);
                //     print.addText('      '+'Add-On :' + '\n');
                //     print.addTextStyle(false, false, false, print.COLOR_NONE);
                //     for(let j =0 ; j<order.ordered_items[i].addons.length;j++){
                //         // print.addTextAlign(print.ALIGN_LEFT);
                //         print.addTextSize(1,1);
                //         print.addTextFont(print.FONT_A);
                //         // print.addTextPosition(6);

                //         print.addText(' '+order.ordered_items[i].addons[j].quantity+' '.repeat(5 - order.ordered_items[i].addons[j].quantity.toString().length));

                //         print.addTextSize(1,1);
                //         if(order.ordered_items[i].addons[j].name.length == 30){
                //             print.addText(order.ordered_items[i].addons[j].name+'  ');
                //         }
                //         else if(order.ordered_items[i].addons[j].name.length < 30){
                //             print.addText(order.ordered_items[i].addons[j].name+' '.repeat(30 - order.ordered_items[i].addons[j].name.length)+'  ');
                //         } else if(order.ordered_items[i].addons[j].name.length > 30) {
                //             print.addText(order.ordered_items[i].addons[j].name.substring(0, 30)+'  ');
                //         }

                //         // print.addTextAlign(print.ALIGN_RIGHT);
                //         print.addTextSize(1,1);
                //         print.addText(order.ordered_items[i].addons[j].price + '\n');
                //     }
                // }
                // if(order.ordered_items[i].hasOwnProperty('modifiers')) {
                //     if(order.ordered_items[i].modifiers.length){
                //         print.addTextSize(1,1);
                //         print.addText('      '+'Modifiers :' + '\n');
                //         print.addTextStyle(false, false, false, print.COLOR_NONE);
                //         for(let j =0 ; j<order.ordered_items[i].modifiers.length;j++){

                //             // print.addTextSize(1,1);
                //             // print.addText(' ' + '\n');

                //             for(let k = 0; k<order.ordered_items[i].modifiers[j].modifier_list.length;k++){
                //                 print.addTextAlign(print.ALIGN_LEFT);
                //                 print.addTextSize(1,1);
                //                 print.addTextFont(print.FONT_A);
                //                 print.addTextPosition(5);
                //                 // if(order.ordered_items[i].modifiers[j].modifier_name.length == 30){
                //                     print.addText(order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]);
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length > 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length < 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //             }

                //             // print.addTextAlign(print.ALIGN_RIGHT);

                //             // print.addTextSize(1,1);
                //             print.addText('\n');
                //         }
                //     }
                // }
                if(order.ordered_items[i].hasOwnProperty('discount')) {
                    // print.addTextSize(1,1);
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addText('      '+'Discount:'+'                       ');

                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText(order.ordered_items[i].discount + '\n');
                }
            }
            if(order.category_summary.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Total Amount'+'                         ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.total_amount + '\n');

            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            // var address = 'http://192.168.29.40/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
            // //Create an ePOS-Print object
            // var epos = new epson.ePOSPrint(address);
            print.send();
            print.open();
        },
        //receipt
        printReceipt(order) {
            this.drawer_open = true
            order = this.store_receipt_data
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintingReceipt()
                return;
            }
            console.log(order)
            console.log(this.store_receipt_data)
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText(order.outlet_details.outlet_name + '\n');
            print.addText(order.outlet_details.website + '\n');
            print.addText(order.outlet_details.outlet_address.street + '\n');
            print.addText(order.outlet_details.outlet_address.city + ',' + order.outlet_details.outlet_address.zipcode + '\n');
            print.addText(order.outlet_details.outlet_address.country + '\n');
            print.addText('Tel :' +' '+order.outlet_details.phone_number + '\n');
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                      ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Paid Receipt No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.receipt_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.tax_code + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.other_details.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Table Number :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.table_number + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_details.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.other_details.ordered_by + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Bill Generated By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.other_details.generated_by + '\n');

            print.addText(' '+'-----------------------------------------------'+'\n')
            // print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Qty' + '  ');

            // print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Items' + '                           ');

            // print.addTextAlign(print.ALIGN_CENTER);

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            for(let i = 0 ; i<order.ordered_items.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addText(' '+order.ordered_items[i].quantity+' '.repeat(5 - order.ordered_items[i].quantity.toString().length));

                print.addTextFont(print.FONT_A);
                if(order.ordered_items[i].product_name.length == 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name+ '**');
                    } else {
                        print.addText(order.ordered_items[i].product_name+'  ');
                    }
                }
                else if(order.ordered_items[i].product_name.length < 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name +'**'+' '.repeat(30 - (order.ordered_items[i].product_name.length + 2))+'  ');
                    } else {
                        print.addText(order.ordered_items[i].product_name+' '.repeat(30 - order.ordered_items[i].product_name.length)+'  ');
                    }
                } else if(order.ordered_items[i].product_name.length > 30) {
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30) +'**');
                    } else {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30)+'  ');
                    }
                }

                // print.addTextAlign(print.ALIGN_CENTER);

                print.addTextSize(1,1);
                print.addText(order.ordered_items[i].product_price_exclusive + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.ordered_items[i].hasOwnProperty('modifiers')){
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary') || order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        print.addTextSize(1,1);
                        print.addText('      '+'Modifiers :' + '\n');
                        print.addTextStyle(false, false, false, print.COLOR_NONE);
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary')){
                        if(order.ordered_items[i].modifiers.for_secondary.length > 0) {
                            // print.addText('  '+`${order.ordered_items[i].modifiers.variant_name}` + '\n');
                            // print.addTextStyle(false, false, false, print.COLOR_NONE);
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_secondary.length;j++){
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                // print.addTextPosition(6);

                                print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_secondary[j].quantity.toString().length));

                                print.addTextSize(1,1);
                                if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length == 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+'  ');
                                }
                                else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length < 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length)+'  ');
                                } else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length > 30) {
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.substring(0, 30)+'  ');
                                }

                                // print.addTextAlign(print.ALIGN_RIGHT);
                                print.addTextSize(1,1);
                                print.addText(order.ordered_items[i].modifiers.for_secondary[j].amount + '\n');
                            }
                        }
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        if(order.ordered_items[i].modifiers.for_primary.length > 0) {
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_primary.length;j++){
                                for(let k = 0; k<order.ordered_items[i].modifiers.for_primary[j].modifier_items.length;k++){
                                    print.addTextSize(1,1);
                                    print.addTextFont(print.FONT_A);
                                    // print.addTextPosition(6);

                                    print.addText(' '+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity.toString().length));

                                    print.addTextSize(1,1);
                                    if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length == 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+'  ');
                                    }
                                    else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length < 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length)+'  ');
                                    } else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length > 30) {
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.substring(0, 30)+'  ');
                                    }

                                    // print.addTextAlign(print.ALIGN_RIGHT);
                                    print.addTextSize(1,1);
                                    print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                                }
                            }
                        }
                    }
                }

                // if(order.ordered_items[i].addons.length){
                //     print.addTextSize(1,1);
                //     print.addText('      '+'Add-On :' + '\n');
                //     print.addTextStyle(false, false, false, print.COLOR_NONE);
                //     for(let j =0 ; j<order.ordered_items[i].addons.length;j++){
                //         // print.addTextAlign(print.ALIGN_LEFT);
                //         print.addTextSize(1,1);
                //         print.addTextFont(print.FONT_A);
                //         // print.addTextPosition(6);

                //         print.addText(' '+order.ordered_items[i].addons[j].quantity+' '.repeat(5 - order.ordered_items[i].addons[j].quantity.toString().length));

                //         print.addTextSize(1,1);
                //         if(order.ordered_items[i].addons[j].name.length == 30){
                //             print.addText(order.ordered_items[i].addons[j].name+'  ');
                //         }
                //         else if(order.ordered_items[i].addons[j].name.length < 30){
                //             print.addText(order.ordered_items[i].addons[j].name+' '.repeat(30 - order.ordered_items[i].addons[j].name.length)+'  ');
                //         } else if(order.ordered_items[i].addons[j].name.length > 30) {
                //             print.addText(order.ordered_items[i].addons[j].name.substring(0, 30)+'  ');
                //         }

                //         // print.addTextAlign(print.ALIGN_RIGHT);
                //         print.addTextSize(1,1);
                //         print.addText(order.ordered_items[i].addons[j].price + '\n');
                //     }
                // }
                // if(order.ordered_items[i].hasOwnProperty('modifiers')) {
                //     if(order.ordered_items[i].modifiers.length){
                //         print.addTextSize(1,1);
                //         print.addText('      '+'Modifiers :' + '\n');
                //         print.addTextStyle(false, false, false, print.COLOR_NONE);
                //         for(let j =0 ; j<order.ordered_items[i].modifiers.length;j++){

                //             // print.addTextSize(1,1);
                //             // print.addText(' ' + '\n');

                //             for(let k = 0; k<order.ordered_items[i].modifiers[j].modifier_list.length;k++){
                //                 print.addTextAlign(print.ALIGN_LEFT);
                //                 print.addTextSize(1,1);
                //                 print.addTextFont(print.FONT_A);
                //                 print.addTextPosition(5);
                //                 // if(order.ordered_items[i].modifiers[j].modifier_name.length == 30){
                //                     print.addText(order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]);
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length > 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length < 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //             }

                //             // print.addTextAlign(print.ALIGN_RIGHT);

                //             // print.addTextSize(1,1);
                //             print.addText('\n');
                //         }
                //     }
                // }
                if(order.ordered_items[i].hasOwnProperty('discount')) {
                    // print.addTextSize(1,1);
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addText('      '+'Discount:'+'                       ');

                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText(order.ordered_items[i].discount + '\n');
                }
            }
            if(order.category_summary.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Total Amount'+'                         ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.total_amount + '\n');

            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            // if(order.payments.length){
            //     print.addText(' '+'-----------------------------------------------'+'\n')
            //     for(let i =0 ; i<order.payments.length;i++){
            //         if(order.payments[i].mode == "CARD"){
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"CARD TYPE" +' '.repeat(15));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].card_type + '\n');
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"TRANSACTION ID" +' '.repeat(10));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].transaction_id + '\n');
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"AMOUNT" +' '.repeat(18));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].value + '\n');
            //         }
            //         else if(order.payments[i].mode == "CASH"){
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addText('  '+'Cash Tendered'+'                     ');
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addText(order.currency + order.cash_received + '\n');
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addText('  '+'Cash Returned'+'                     ');
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addText(order.currency + order.cash_returned + '\n');
            //         }
            //         else{
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"PAYMENT METHOD" +' '.repeat(11));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].payment_method + '\n');
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"REFERENCE ID" +' '.repeat(13));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].reference_id + '\n');
            //             print.addTextAlign(print.ALIGN_LEFT);
            //             print.addTextSize(1,1);
            //             print.addTextFont(print.FONT_A);
            //             print.addText('  '+"AMOUNT" +' '.repeat(19));
            //             print.addTextAlign(print.ALIGN_RIGHT);
            //             print.addTextSize(1,1);
            //             print.addText(order.payments[i].value + '\n');
            //         }
            //     }
            // }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            if(this.ticket_printing && this.drawer_open){
                print.addPulse(print.DRAWER_1, print.PULSE_100);
                this.drawer_open = false
            }
            print.send();
            if(this.ticket_printing){
                setTimeout(() => {
                    this.customerCopyReceipt()
                }, 2000);
            }
        },
        //ticket
        printTicket(order) {
            order = this.store_ticket_data
            console.log(order)
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintingTicket()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 15, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                     ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.date + '\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Detail :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_detail + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.placed_by + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Category :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.category_name + '\n');

            if(this.show_item_deleted == true){
                print.addText(' '+'---------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_CENTER);
                print.addText('Items Deleted' + '\n');
            }
            if(this.show_order_deleted == true){
                print.addText(' '+'---------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_CENTER);
                print.addText('Order Deleted' + '\n');
            }
            if(this.show_order_deleted == false && this.show_item_deleted == false){
                print.addText(' '+'---------------------------------------------'+'\n')
            }
            if(this.show_order_deleted == true || this.show_item_deleted == true){
                print.addText(''+'---------------------------------------------'+'\n')
            }
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addTextPosition(12);
            print.addText(' '+'Items' + '                                  ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(' '+'Qty'+ '   ' + '\n');
            print.addText(' '+'---------------------------------------------'+'\n')
            for(let i =0 ; i<order.ordered_items.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addTextFont(print.FONT_A);
                print.addTextPosition(12);
                if(order.ordered_items[i].product_name.length == 30){
                    print.addText(' '+order.ordered_items[i].product_name+ '           ');
                }
                // else if(order.ordered_items[i].product_name.length > 24){
                //     print.addText(order.ordered_items[i].product_name.replace(/([^\n]{1,24})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].product_name.length));
                // }
                else if(order.ordered_items[i].product_name.length < 30){
                    print.addText(' '+order.ordered_items[i].product_name+' '.repeat(41 - order.ordered_items[i].product_name.length));
                } else if(order.ordered_items[i].product_name.length > 30) {
                    print.addText(order.ordered_items[i].product_name.substring(0, 30)+'           ');
                }

                // print.addTextAlign(print.ALIGN_RIGHT);

                print.addTextSize(1,1);
                print.addText(order.ordered_items[i].quantity + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.ordered_items[i].hasOwnProperty('modifiers')){
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary')){
                        if(order.ordered_items[i].modifiers.for_secondary.length > 0) {
                            print.addText('  '+`${order.ordered_items[i].variant_name}` + '\n');
                            print.addTextStyle(false, false, false, print.COLOR_NONE);
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_secondary.length;j++){
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                print.addTextPosition(12);
                                // print.addTextStyle(false, false, em, COLOR_1);
                                if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length == 30){
                                    print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].m_item_name+ '           ');
                                }
                                else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length < 30){
                                    print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].m_item_name+' '.repeat(41 - order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length));
                                } else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length > 30) {
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.substring(0, 30)+'           ');
                                }

                                // print.addTextAlign(print.ALIGN_RIGHT);

                                print.addTextSize(1,1);
                                print.addText(order.ordered_items[i].modifiers.for_secondary[j].quantity + '\n');

                                // print.addTextSize(1,1);
                                // print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                            }
                        }
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        if(order.ordered_items[i].modifiers.for_primary.length > 0) {
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_primary.length;j++){
                                for(let k = 0; k<order.ordered_items[i].modifiers.for_primary[j].modifier_items.length;k++){
                                    print.addTextSize(1,1);
                                    print.addTextFont(print.FONT_A);
                                    print.addTextPosition(12);
                                    // print.addTextStyle(false, false, em, COLOR_1);
                                    // if((order.ordered_items[i].modifiers.for_primary[j].modifier_name.length + order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length) == 30){
                                        print.addText(' '+order.ordered_items[i].modifiers.for_primary[j].modifier_name +':'+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+ '           ');
                                    // }
                                    // else if((order.ordered_items[i].modifiers.for_primary[j].modifier_name.length + order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length) < 30){
                                    //     print.addText(' '+(order.ordered_items[i].modifiers.for_primary[j].modifier_name +':'+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name)+' '.repeat(41 - (order.ordered_items[i].modifiers.for_primary[j].modifier_name.length + order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length)));
                                    // } else if((order.ordered_items[i].modifiers.for_primary[j].modifier_name.length + order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length) > 30) {
                                    //     print.addText((order.ordered_items[i].modifiers.for_primary[j].modifier_name +':'+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name).substring(0, 30)+'           ');
                                    // }

                                    // print.addTextAlign(print.ALIGN_RIGHT);

                                    // print.addTextSize(1,1);
                                    // print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k] + '\n');

                                    // print.addTextSize(1,1);
                                    // print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                                }
                            }
                        }
                    }
                }
                // if(order.ordered_items[i].addons.length){
                    // print.addText('  '+'Add-On :' + '\n');
                    // print.addTextStyle(false, false, false, print.COLOR_NONE);
                //     for(let j =0 ; j<order.ordered_items[i].addons.length;j++){
                //         // print.addTextAlign(print.ALIGN_LEFT);
                //         print.addTextSize(1,1);
                //         print.addTextFont(print.FONT_A);
                //         print.addTextPosition(12);
                //         // print.addTextStyle(false, false, em, COLOR_1);
                //         if(order.ordered_items[i].addons[j].name.length == 30){
                //             print.addText(' '+order.ordered_items[i].addons[j].name+ '           ');
                //         }
                //         else if(order.ordered_items[i].addons[j].name.length < 30){
                //             print.addText(' '+order.ordered_items[i].addons[j].name+' '.repeat(41 - order.ordered_items[i].addons[j].name.length));
                //         } else if(order.ordered_items[i].addons[j].name.length > 30) {
                //             print.addText(order.ordered_items[i].addons[j].name.substring(0, 30)+'           ');
                //         }

                //         // print.addTextAlign(print.ALIGN_RIGHT);

                //         print.addTextSize(1,1);
                //         print.addText(order.ordered_items[i].addons[j].quantity + '\n');

                //         // print.addTextSize(1,1);
                //         // print.addText(order.ordered_items[i].addons[j].price + '\n');
                //     }
                // }
                // if(order.ordered_items[i].hasOwnProperty('modifiers')) {
                //     if(order.ordered_items[i].modifiers.length){
                //         print.addText('  '+'Modifiers :' + '\n');
                //         print.addTextStyle(false, false, false, print.COLOR_NONE);
                //         for(let j =0 ; j<order.ordered_items[i].modifiers.length;j++){
                //             for(let k = 0; k<order.ordered_items[i].modifiers[j].modifier_list.length;k++){
                //                 // print.addTextAlign(print.ALIGN_LEFT);
                //                 print.addTextSize(1,1);
                //                 print.addTextFont(print.FONT_A);
                //                 print.addTextPosition(12);
                //                 // print.addTextStyle(false, false, em, COLOR_1);
                //                 // if(order.ordered_items[i].modifiers[j].modifier_name.length == 30){
                //                     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]);
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length > 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length < 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //             }

                //             // print.addTextAlign(print.ALIGN_RIGHT);

                //             // print.addTextSize(1,1);
                //             print.addText('\n');
                //         }
                //     }
                // }
                if(order.ordered_items[i].item_note){
                    // print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextStyle(false, false, false, print.COLOR_NONE);
                    print.addText('  '+'Note :' + '\n');
                    print.addTextStyle(false, false, false, print.COLOR_NONE);
                    // print.addTextAlign(print.ALIGN_RIGHT);
                    // print.addTextStyle(false, false, em, COLOR_1);
                    print.addText('  '+ order.ordered_items[i].item_note || "-" + '\n');
                    // print.addText('\n');
                }
                print.addText('\n');
            }
            print.addText(' '+'---------------------------------------------'+'\n')
            if(order.order_note){
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                print.addText('  '+'Order Note -' + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                // print.addTextStyle(false, false, em, COLOR_1);
                print.addText('  '+order.order_note || "-" + '\n');
                print.addText('\n');
                print.addText('\n');
            }
            print.addText('\n');
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            print.send();
            this.show_item_deleted = false
            this.show_order_deleted = false
            if(this.CheckoutShow){
                this.$emit("close", true);
                this.placing_order_in_progress = false
            }
            else{
                this.$router.push({
                    name: 'ManageOrder',
                    params: {
                        orderType: order.order_type
                    }
                })
            }
        },
        //reprint receipt
        rePrintReceipt(order) {
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryRePrintingReceipt()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText(order.outlet_name + '\n');
            print.addText(order.outlet_email_address + '\n');
            print.addText(order.outlet_address + '\n');
            print.addText(order.outlet_location + '\n');
            print.addText('Tel :' +' '+order.outlet_phone_number + '\n');
            print.addText('------------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                       ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_time + '\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Paid Receipt No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.receipt_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText('null' + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Table Number :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.table_number + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText('null' + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Bill Generated By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.generated_by + '\n');

            print.addText(' '+'-----------------------------------------------'+'\n')
            // print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Items' + '                   ');

            // print.addTextAlign(print.ALIGN_CENTER);

            print.addTextSize(1,1);
            print.addText('Qty' + '       ');

            // print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            for(let i =0 ; i<order.item_details.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addTextFont(print.FONT_A);
                if(order.item_details[i].product_name.length == 24){
                    if(order.item_details[i].item_status == 'refund') {
                        print.addText(' '+order.item_details[i].product_name+ '**' + ' ');
                    } else {
                        print.addText(' '+order.item_details[i].product_name+ '');
                    }
                }
                else if(order.item_details[i].product_name.length > 24){
                    if(order.item_details[i].item_status == 'refund') {
                        print.addText(' '+order.item_details[i].product_name.replace(/([^\n]{1,24})\s/, '$1\n')+' '.repeat(41 - order.item_details[i].product_name.length) + '**');
                    } else {
                        print.addText(' '+order.item_details[i].product_name.replace(/([^\n]{1,24})\s/, '$1\n')+' '.repeat(41 - order.item_details[i].product_name.length));
                    }
                }
                else if(order.item_details[i].product_name.length < 24){
                    if(order.item_details[i].item_status == 'refund') {
                        print.addText(' '+order.item_details[i].product_name+' '.repeat(25 - order.item_details[i].product_name.length)+'**');
                    } else {
                        print.addText(' '+order.item_details[i].product_name+' '.repeat(25 - order.item_details[i].product_name.length));
                    }
                }

                // print.addTextAlign(print.ALIGN_CENTER);

                print.addTextSize(1,1);
                print.addText(order.item_details[i].quantity+' '.repeat(9 - order.item_details[i].quantity.toString().length));

                // print.addTextAlign(print.ALIGN_RIGHT);

                print.addTextSize(1,1);
                print.addText(order.item_details[i].price + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.item_details[i].addons.length){
                    print.addText(' '+'Add-On :' + '\n');
                    print.addTextStyle(false, false, false, print.COLOR_NONE);
                    for(let j =0 ; j<order.item_details[i].addons.length;j++){
                        // print.addTextAlign(print.ALIGN_LEFT);
                        print.addTextSize(1,1);
                        print.addTextFont(print.FONT_A);
                        print.addTextPosition(12);
                        // print.addTextStyle(false, false, em, COLOR_1);
                        if(order.item_details[i].addons[j].name.length == 24){
                            print.addText(' '+order.item_details[i].addons[j].name+ ' ');
                        }
                        else if(order.item_details[i].addons[j].name.length > 24){
                            print.addText(' '+order.item_details[i].addons[j].name.replace(/([^\n]{1,24})\s/, '$1\n')+' '.repeat(41 - order.item_details[i].addons[j].name.length));
                        }
                        else if(order.item_details[i].addons[j].name.length < 24){
                            print.addText(' '+order.item_details[i].addons[j].name+' '.repeat(25 - order.item_details[i].addons[j].name.length));
                        }

                        // print.addTextAlign(print.ALIGN_RIGHT);

                        print.addTextSize(1,1);
                        print.addText(order.item_details[i].addons[j].quantity+' '.repeat(9 - order.item_details[i].addons[j].quantity.toString().length));

                        print.addTextSize(1,1);
                        print.addText(order.item_details[i].addons[j].price + '\n');
                    }
                }
                if(order.item_details[i].hasOwnProperty('modifiers')) {
                    if(order.item_details[i].modifiers.length){
                        print.addText('  '+'Modifiers :' + '\n');
                        print.addTextStyle(false, false, false, print.COLOR_NONE);
                        for(let j =0 ; j<order.item_details[i].modifiers.length;j++){
                            for(let k = 0; k<order.item_details[i].modifiers[j].modifier_list.length;k++){
                                // print.addTextAlign(print.ALIGN_LEFT);
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                print.addTextPosition(12);
                                // print.addTextStyle(false, false, em, COLOR_1);
                                if(order.item_details[i].product_name.length == 30){
                                    print.addText(' '+order.item_details[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.item_details[i].modifiers[j].modifier_list[k]);
                                }
                                else if(order.item_details[i].product_name.length > 30){
                                    print.addText(' '+order.item_details[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.item_details[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.item_details[i].modifiers[j].modifier_name.length));
                                }
                                else if(order.item_details[i].product_name.length < 30){
                                    print.addText(' '+order.item_details[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.item_details[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.item_details[i].modifiers[j].modifier_name.length));
                                }
                            }

                            // print.addTextAlign(print.ALIGN_RIGHT);

                            print.addTextSize(1,1);
                            print.addText('  ' + '\n');

                            print.addTextSize(1,1);
                            print.addText(' - ' + '\n');
                        }
                    }
                }
            }
            if(order.category_summary.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    console.log(order.category_summary[i].name)
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ '');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText(order.category_summary[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Sub Total Without Discount'+'        ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.sub_total + '\n');
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText(order.other_charges[i].value + '\n');
                }
            }
            if(order.order_tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.order_tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.order_tax[i].name + '(' + order.order_tax[i].rate + ')' +' '.repeat(31 - order.order_tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText(order.order_tax[i].value + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Total Amount'+'                      ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.total_amount + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Round Off'+'                         ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.round_off + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                       ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');

            if(order.refund_amount) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                      ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'             ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            // if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
            //     if(order.outlet_details.outlet_tagline.length <= 37) {
            //         print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
            //     } else if(order.outlet_details.outlet_tagline.length > 37) {
            //         for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
            //             print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
            //         }
            //     }
            // }
            print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            print.send();
        },
        //retry ticket
        retryPrintingTicket(){
            Swal({
                title: 'Retry Printing Ticket',
                text: 'Do You Want to Print Ticket ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printTicket(this.store_ticket_data)
                } else {
                    if(this.$route.path == '/takeordernew' && this.store_ticket_data){
                        if(this.CheckoutShow){
                            this.$emit("close", true);
                            this.placing_order_in_progress = false
                        }
                        else{
                            this.$router.push({
                                name: 'ManageOrder',
                                params: {
                                    orderType: this.order.type
                                }
                            })
                        }
                    }
                }
            })
        },
        //retry receipt
        retryPrintingReceipt(){
            Swal({
                title: 'Retry Printing Receipt',
                text: 'Do You Want to Print Receipt ?',
                type: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                denyButtonText: `Choose Alternate Printer`,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printReceipt(this.store_receipt_data)
                } else {
                    if(this.$route.path == '/takeordernew' && this.store_receipt_data){
                        if(this.CheckoutShow){
                            this.$emit("close", true);
                            this.placing_order_in_progress = false
                        }
                        else{
                            this.$router.push({
                                name: 'ManageOrder',
                                params: {
                                    orderType: this.order.type
                                }
                            })
                        }
                    }
                    this.$modal.hide(this.modal_name)
                }
            })
        },
        retryRePrintingReceipt(){
            Swal({
                title: 'Retry Printing Receipt',
                text: 'Do You Want to Print Receipt ?',
                type: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                denyButtonText: `Choose Alternate Printer`,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printReceipt(this.store_receipt_data)
                } else {
                    if(this.$route.path == '/takeordernew' && this.store_receipt_data){
                        if(this.CheckoutShow){
                            this.$emit("close", true);
                            this.placing_order_in_progress = false
                        }
                        else{
                            this.$router.push({
                                name: 'ManageOrder',
                                params: {
                                    orderType: this.order.type
                                }
                            })
                        }
                    }
                    this.$modal.hide(this.modal_name)
                }
            })
        },
        //retry invoice
        retryPrintingInvoice(){
            Swal({
                title: 'Retry Printing Invoice',
                text: 'Do You Want to Print Invoice ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printInvoice(this.store_invoice_data)
                } else {

                }
            })
        },
        //print without customer items
        printWithoutCustomerItems(order) {
            order = this.store_printing_invoice
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintWithoutCustomerItems()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                       ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('Full Tax Invoice' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Invoice No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.invoice_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Telephone :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_phone || '-' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_tax_code || "-" + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Address :' + ' ');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+order.customer_personal_address + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_id + ' ');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.placed_by_name + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Items' + '                         ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.category_summary.length){
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            if(order.hasOwnProperty('total_amount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Total Amount'+'                         ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.total_amount + '\n');
            }
            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            // var address = 'http://192.168.29.40/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
            // //Create an ePOS-Print object
            // var epos = new epson.ePOSPrint(address);
            print.send();
            print.open();
        },
        //print without company items
        printWithoutCompanyItems(order) {
            order = this.store_printing_invoice
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintWithoutCompanyItems()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                       ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('Full Tax Invoice' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Invoice No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.invoice_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_company_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_company_tax_code || "-" + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Address :' + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+order.customer_company_address + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.placed_by_name + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Items' + '                         ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.category_summary.length){
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            if(order.hasOwnProperty('total_amount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Total Amount'+'                         ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.total_amount + '\n');
            }
            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            // var address = 'http://192.168.29.40/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
            // //Create an ePOS-Print object
            // var epos = new epson.ePOSPrint(address);
            print.send();
            print.open();
        },
        //print with customer items
        printWithCustomerItems(order) {
            order = this.store_printing_invoice
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintWithCustomerItems()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                       ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('Full Tax Invoice' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Invoice No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.invoice_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Telephone :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_phone || '-' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_tax_code || "-" + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Customer Address :' + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+order.customer_personal_address + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.placed_by_name + '\n');

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Qty' + '  ');

            // print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Items' + '                           ');

            // print.addTextAlign(print.ALIGN_CENTER);

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            for(let i = 0 ; i<order.ordered_items.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addText(' '+order.ordered_items[i].quantity+' '.repeat(5 - order.ordered_items[i].quantity.toString().length));

                print.addTextFont(print.FONT_A);
                if(order.ordered_items[i].product_name.length == 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name+ '**');
                    } else {
                        print.addText(order.ordered_items[i].product_name+'  ');
                    }
                }
                else if(order.ordered_items[i].product_name.length < 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name +'**'+' '.repeat(30 - (order.ordered_items[i].product_name.length + 2))+'  ');
                    } else {
                        print.addText(order.ordered_items[i].product_name+' '.repeat(30 - order.ordered_items[i].product_name.length)+'  ');
                    }
                } else if(order.ordered_items[i].product_name.length > 30) {
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30) +'**');
                    } else {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30)+'  ');
                    }
                }

                // print.addTextAlign(print.ALIGN_CENTER);

                print.addTextSize(1,1);
                print.addText(order.ordered_items[i].product_price_exclusive + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.ordered_items[i].hasOwnProperty('modifiers')){
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary') || order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        print.addTextSize(1,1);
                        print.addText('      '+'Modifiers :' + '\n');
                        print.addTextStyle(false, false, false, print.COLOR_NONE);
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary')){
                        if(order.ordered_items[i].modifiers.for_secondary.length > 0) {
                            // print.addText('  '+`${order.ordered_items[i].modifiers.variant_name}` + '\n');
                            // print.addTextStyle(false, false, false, print.COLOR_NONE);
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_secondary.length;j++){
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                // print.addTextPosition(6);

                                print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_secondary[j].quantity.toString().length));

                                print.addTextSize(1,1);
                                if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length == 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+'  ');
                                }
                                else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length < 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length)+'  ');
                                } else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length > 30) {
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.substring(0, 30)+'  ');
                                }

                                // print.addTextAlign(print.ALIGN_RIGHT);
                                print.addTextSize(1,1);
                                print.addText(order.ordered_items[i].modifiers.for_secondary[j].amount + '\n');
                            }
                        }
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        if(order.ordered_items[i].modifiers.for_primary.length > 0) {
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_primary.length;j++){
                                for(let k = 0; k<order.ordered_items[i].modifiers.for_primary[j].modifier_items.length;k++){
                                    print.addTextSize(1,1);
                                    print.addTextFont(print.FONT_A);
                                    // print.addTextPosition(6);

                                    print.addText(' '+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity.toString().length));

                                    print.addTextSize(1,1);
                                    if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length == 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+'  ');
                                    }
                                    else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length < 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length)+'  ');
                                    } else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length > 30) {
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.substring(0, 30)+'  ');
                                    }

                                    // print.addTextAlign(print.ALIGN_RIGHT);
                                    print.addTextSize(1,1);
                                    print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                                }
                            }
                        }
                    }
                }

                // if(order.ordered_items[i].addons.length){
                //     print.addTextSize(1,1);
                //     print.addText('      '+'Add-On :' + '\n');
                //     print.addTextStyle(false, false, false, print.COLOR_NONE);
                //     for(let j =0 ; j<order.ordered_items[i].addons.length;j++){
                //         // print.addTextAlign(print.ALIGN_LEFT);
                //         print.addTextSize(1,1);
                //         print.addTextFont(print.FONT_A);
                //         // print.addTextPosition(6);

                //         print.addText(' '+order.ordered_items[i].addons[j].quantity+' '.repeat(5 - order.ordered_items[i].addons[j].quantity.toString().length));

                //         print.addTextSize(1,1);
                //         if(order.ordered_items[i].addons[j].name.length == 30){
                //             print.addText(order.ordered_items[i].addons[j].name+'  ');
                //         }
                //         else if(order.ordered_items[i].addons[j].name.length < 30){
                //             print.addText(order.ordered_items[i].addons[j].name+' '.repeat(30 - order.ordered_items[i].addons[j].name.length)+'  ');
                //         } else if(order.ordered_items[i].addons[j].name.length > 30) {
                //             print.addText(order.ordered_items[i].addons[j].name.substring(0, 30)+'  ');
                //         }

                //         // print.addTextAlign(print.ALIGN_RIGHT);
                //         print.addTextSize(1,1);
                //         print.addText(order.ordered_items[i].addons[j].price + '\n');
                //     }
                // }
                // if(order.ordered_items[i].hasOwnProperty('modifiers')) {
                //     if(order.ordered_items[i].modifiers.length){
                //         print.addTextSize(1,1);
                //         print.addText('      '+'Modifiers :' + '\n');
                //         print.addTextStyle(false, false, false, print.COLOR_NONE);
                //         for(let j =0 ; j<order.ordered_items[i].modifiers.length;j++){

                //             // print.addTextSize(1,1);
                //             // print.addText(' ' + '\n');

                //             for(let k = 0; k<order.ordered_items[i].modifiers[j].modifier_list.length;k++){
                //                 print.addTextAlign(print.ALIGN_LEFT);
                //                 print.addTextSize(1,1);
                //                 print.addTextFont(print.FONT_A);
                //                 print.addTextPosition(5);
                //                 // if(order.ordered_items[i].modifiers[j].modifier_name.length == 30){
                //                     print.addText(order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]);
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length > 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length < 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //             }

                //             // print.addTextAlign(print.ALIGN_RIGHT);

                //             // print.addTextSize(1,1);
                //             print.addText('\n');
                //         }
                //     }
                // }
                if(order.ordered_items[i].hasOwnProperty('discount')) {
                    // print.addTextSize(1,1);
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addText('      '+'Discount:'+'                       ');

                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText(order.ordered_items[i].discount + '\n');
                }
            }
            if(order.category_summary.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            if(order.hasOwnProperty('total_amount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Total Amount'+'                         ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.total_amount + '\n');
            }
            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            // var address = 'http://192.168.29.40/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
            // //Create an ePOS-Print object
            // var epos = new epson.ePOSPrint(address);
            print.send();
            print.open();
        },
        //print with company items
        printWithCompanyItems(order) {
            order = this.store_printing_invoice
            let print = this.printer;
            if (!print) {
                this.$toasted.global.error("Not Connected To Any Printer")
                this.retryPrintWithCompanyItems()
                return;
            }
            print.addLayout(print.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextSmooth(true);
            print.addText('\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addTextSize(1,1);
            print.addText(' '+'DigiPOS' + '                       ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.created_date + '\n');
            print.addText('\n');
            print.addTextAlign(print.ALIGN_CENTER);
            print.addText('Full Tax Invoice' + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Invoice No. :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText(order.invoice_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Name :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_company_name + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Tax Code :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.customer_company_tax_code || "-" + '\n');
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Company Address :' + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+order.customer_company_address + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Type :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_type + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'POS ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.pos_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order ID :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.order_id + '\n');

            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Order Placed By :' + ' ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.placed_by_name + '\n');

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextSize(1,1);
            print.addText(' '+'Qty' + '  ');

            // print.addTextAlign(print.ALIGN_RIGHT);

            print.addTextSize(1,1);
            print.addText('Items' + '                           ');

            // print.addTextAlign(print.ALIGN_CENTER);

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addTextSize(1,1);
            print.addText('Amount' + '\n');
            print.addText(' '+'-----------------------------------------------'+'\n')
            for(let i = 0 ; i<order.ordered_items.length;i++){
                // print.addTextAlign(print.ALIGN_LEFT);
                print.addTextSize(1,1);
                print.addText(' '+order.ordered_items[i].quantity+' '.repeat(5 - order.ordered_items[i].quantity.toString().length));

                print.addTextFont(print.FONT_A);
                if(order.ordered_items[i].product_name.length == 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name+ '**');
                    } else {
                        print.addText(order.ordered_items[i].product_name+'  ');
                    }
                }
                else if(order.ordered_items[i].product_name.length < 30){
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name +'**'+' '.repeat(30 - (order.ordered_items[i].product_name.length + 2))+'  ');
                    } else {
                        print.addText(order.ordered_items[i].product_name+' '.repeat(30 - order.ordered_items[i].product_name.length)+'  ');
                    }
                } else if(order.ordered_items[i].product_name.length > 30) {
                    if(order.ordered_items[i].item_status == 'refund') {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30) +'**');
                    } else {
                        print.addText(order.ordered_items[i].product_name.substring(0, 30)+'  ');
                    }
                }

                // print.addTextAlign(print.ALIGN_CENTER);

                print.addTextSize(1,1);
                print.addText(order.ordered_items[i].product_price_exclusive + '\n');
                print.addTextStyle(false, false, false, print.COLOR_NONE);
                if(order.ordered_items[i].hasOwnProperty('modifiers')){
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary') || order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        print.addTextSize(1,1);
                        print.addText('      '+'Modifiers :' + '\n');
                        print.addTextStyle(false, false, false, print.COLOR_NONE);
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_secondary')){
                        if(order.ordered_items[i].modifiers.for_secondary.length > 0) {
                            // print.addText('  '+`${order.ordered_items[i].modifiers.variant_name}` + '\n');
                            // print.addTextStyle(false, false, false, print.COLOR_NONE);
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_secondary.length;j++){
                                print.addTextSize(1,1);
                                print.addTextFont(print.FONT_A);
                                // print.addTextPosition(6);

                                print.addText(' '+order.ordered_items[i].modifiers.for_secondary[j].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_secondary[j].quantity.toString().length));

                                print.addTextSize(1,1);
                                if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length == 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+'  ');
                                }
                                else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length < 30){
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length)+'  ');
                                } else if(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.length > 30) {
                                    print.addText(order.ordered_items[i].modifiers.for_secondary[j].m_item_name.substring(0, 30)+'  ');
                                }

                                // print.addTextAlign(print.ALIGN_RIGHT);
                                print.addTextSize(1,1);
                                print.addText(order.ordered_items[i].modifiers.for_secondary[j].amount + '\n');
                            }
                        }
                    }
                    if(order.ordered_items[i].modifiers.hasOwnProperty('for_primary')){
                        if(order.ordered_items[i].modifiers.for_primary.length > 0) {
                            for(let j =0 ; j<order.ordered_items[i].modifiers.for_primary.length;j++){
                                for(let k = 0; k<order.ordered_items[i].modifiers.for_primary[j].modifier_items.length;k++){
                                    print.addTextSize(1,1);
                                    print.addTextFont(print.FONT_A);
                                    // print.addTextPosition(6);

                                    print.addText(' '+order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity+' '.repeat(5 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].quantity.toString().length));

                                    print.addTextSize(1,1);
                                    if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length == 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+'  ');
                                    }
                                    else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length < 30){
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name+' '.repeat(30 - order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length)+'  ');
                                    } else if(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.length > 30) {
                                        print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].m_item_name.substring(0, 30)+'  ');
                                    }

                                    // print.addTextAlign(print.ALIGN_RIGHT);
                                    print.addTextSize(1,1);
                                    print.addText(order.ordered_items[i].modifiers.for_primary[j].modifier_items[k].amount + '\n');
                                }
                            }
                        }
                    }
                }

                // if(order.ordered_items[i].addons.length){
                //     print.addTextSize(1,1);
                //     print.addText('      '+'Add-On :' + '\n');
                //     print.addTextStyle(false, false, false, print.COLOR_NONE);
                //     for(let j =0 ; j<order.ordered_items[i].addons.length;j++){
                //         // print.addTextAlign(print.ALIGN_LEFT);
                //         print.addTextSize(1,1);
                //         print.addTextFont(print.FONT_A);
                //         // print.addTextPosition(6);

                //         print.addText(' '+order.ordered_items[i].addons[j].quantity+' '.repeat(5 - order.ordered_items[i].addons[j].quantity.toString().length));

                //         print.addTextSize(1,1);
                //         if(order.ordered_items[i].addons[j].name.length == 30){
                //             print.addText(order.ordered_items[i].addons[j].name+'  ');
                //         }
                //         else if(order.ordered_items[i].addons[j].name.length < 30){
                //             print.addText(order.ordered_items[i].addons[j].name+' '.repeat(30 - order.ordered_items[i].addons[j].name.length)+'  ');
                //         } else if(order.ordered_items[i].addons[j].name.length > 30) {
                //             print.addText(order.ordered_items[i].addons[j].name.substring(0, 30)+'  ');
                //         }

                //         // print.addTextAlign(print.ALIGN_RIGHT);
                //         print.addTextSize(1,1);
                //         print.addText(order.ordered_items[i].addons[j].price + '\n');
                //     }
                // }
                // if(order.ordered_items[i].hasOwnProperty('modifiers')) {
                //     if(order.ordered_items[i].modifiers.length){
                //         print.addTextSize(1,1);
                //         print.addText('      '+'Modifiers :' + '\n');
                //         print.addTextStyle(false, false, false, print.COLOR_NONE);
                //         for(let j =0 ; j<order.ordered_items[i].modifiers.length;j++){

                //             // print.addTextSize(1,1);
                //             // print.addText(' ' + '\n');

                //             for(let k = 0; k<order.ordered_items[i].modifiers[j].modifier_list.length;k++){
                //                 print.addTextAlign(print.ALIGN_LEFT);
                //                 print.addTextSize(1,1);
                //                 print.addTextFont(print.FONT_A);
                //                 print.addTextPosition(5);
                //                 // if(order.ordered_items[i].modifiers[j].modifier_name.length == 30){
                //                     print.addText(order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]);
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length > 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name + ' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k].replace(/([^\n]{1,30})\s/, '$1\n')+' '.repeat(41 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //                 // else if(order.ordered_items[i].modifiers[j].modifier_name.length < 30){
                //                 //     print.addText(' '+order.ordered_items[i].modifiers[j].modifier_name+' '+'-' + ' ' +order.ordered_items[i].modifiers[j].modifier_list[k]+' '.repeat(20 - order.ordered_items[i].modifiers[j].modifier_name.length));
                //                 // }
                //             }

                //             // print.addTextAlign(print.ALIGN_RIGHT);

                //             // print.addTextSize(1,1);
                //             print.addText('\n');
                //         }
                //     }
                // }
                if(order.ordered_items[i].hasOwnProperty('discount')) {
                    // print.addTextSize(1,1);
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addText('      '+'Discount:'+'                       ');

                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText(order.ordered_items[i].discount + '\n');
                }
            }
            if(order.category_summary.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.category_summary.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    if(order.category_summary[i].name.length == 29){
                        print.addText(' '+order.category_summary[i].name+ ' ');
                    }
                    else if(order.category_summary[i].name.length > 29){
                        print.addText(' '+order.category_summary[i].name.replace(/([^\n]{1,29})\s/, '$1\n')+' '.repeat(41 - order.category_summary[i].name.length));
                    }
                    else if(order.category_summary[i].name.length < 29){
                        print.addText(' '+order.category_summary[i].name+' '.repeat(34 - order.category_summary[i].name.length));
                    }

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.category_summary[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('sub_total_without_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total Without Discount'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_without_discount + '\n');
            }
            if(order.hasOwnProperty('item_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Item Discount'+'                        ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.item_discount + '\n');
            }
            if(order.hasOwnProperty('order_discount')) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Order Discount'+'                       ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.order_discount + '\n');
            }
            if(order.hasOwnProperty('sub_total_with_discount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Sub Total With Discount'+'              ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.sub_total_with_discount + '\n');
            }
            if(order.other_charges.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.other_charges.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.other_charges[i].name+' '.repeat(34 - order.other_charges[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.other_charges[i].value + '\n');
                }
            }

            if(order.hasOwnProperty('total_amount')) {
                print.addText(' '+'-----------------------------------------------'+'\n')
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Total Amount'+'                         ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.total_amount + '\n');
            }
            if(order.tax.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.tax.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.tax[i].name + '(' + order.tax[i].rate + ')' +' '.repeat(31 - order.tax[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.tax[i].value + '\n');
                }
            }
            if(order.hasOwnProperty('refund_amount')) {
                if(parseFloat(order.refund_amount.substring(1)) > 0 ) {
                print.addTextAlign(print.ALIGN_LEFT);
                    print.addText(' '+'Total Refund'+'                     ');
    
                    print.addTextAlign(print.ALIGN_RIGHT);
                    print.addText('(' +order.refund_amount + ')' + + '\n');
                }
            }

            print.addText(' '+'-----------------------------------------------'+'\n')
            print.addTextAlign(print.ALIGN_LEFT);
            print.addText(' '+'Grand Total'+'                          ');

            print.addTextAlign(print.ALIGN_RIGHT);
            print.addText(order.grand_total + '\n');
            if(order.cash_details.length){
                print.addText(' '+'-----------------------------------------------'+'\n')
                for(let i =0 ; i<order.cash_details.length;i++){
                    print.addTextAlign(print.ALIGN_LEFT);
                    print.addTextSize(1,1);
                    print.addTextFont(print.FONT_A);
                    print.addText(' '+order.cash_details[i].name + ' '.repeat(34 - order.cash_details[i].name.length));

                    print.addTextAlign(print.ALIGN_RIGHT);

                    print.addTextSize(1,1);
                    print.addText('   '+order.cash_details[i].value + '\n');
                }
            }
            print.addText(' '+'-----------------------------------------------'+'\n')
            if(order.hasOwnProperty('loyalty_points_earned')){
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'Loyalty Points Earned'+'           ');

                print.addTextAlign(print.ALIGN_RIGHT);
                print.addText(order.loyalty_points_earned + '\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            if(order.is_refund_added) {
                print.addTextAlign(print.ALIGN_LEFT);
                print.addText(' '+'** Sign indicates refunded item'+'\n');
                print.addText(' '+'(Refunded items not included in grand total)'+'\n');
                print.addText(' '+'-----------------------------------------------'+'\n')
            }
            print.addText('\n');

            print.addTextAlign(print.ALIGN_LEFT);

            print.addTextAlign(print.ALIGN_CENTER);
            print.addTextStyle(false, false, true, print.COLOR_1);
            print.addTextStyle(false, false, false, print.COLOR_1);

            print.addTextSize(1,1);
            print.addText(`Thank You - Check Closed\n`);
            print.addText(`2013-2022 DigiCollect\u00a9 - All Rights Rsvd\n`);
            if(order.outlet_details.hasOwnProperty('outlet_tagline')) {
                if(order.outlet_details.outlet_tagline.length <= 37) {
                    print.addText(`${order.outlet_details.outlet_tagline.length}\n`);
                } else if(order.outlet_details.outlet_tagline.length > 37) {
                    for(let i = 0; i < order.outlet_details.outlet_tagline.length; i += 37) {
                        print.addText(`${order.outlet_details.outlet_tagline.substring(i, i + 37)}\n`);
                    }
                }
            }
            // print.addText(`For Every Good Meal You Eat it's from\n`+`DigiEat\n`);
            // print.addText(`Tasty, Delicious, Spicy`);
            print.addTextDouble(false, false);
            print.addFeedLine(3);
            print.addCut(print.CUT_FEED);
            // var address = 'http://192.168.29.40/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
            // //Create an ePOS-Print object
            // var epos = new epson.ePOSPrint(address);
            print.send();
            print.open();
        },
        //retry customer without items
        retryPrintWithoutCustomerItems(){
            Swal({
                title: 'Retry Printing Invoice',
                text: 'Do You Want to Print Invoice ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printWithoutCustomerItems(this.store_printing_invoice)
                } else {

                }
            })
        },
        //retry company without items
        retryPrintWithoutCompanyItems(){
            Swal({
                title: 'Retry Printing Invoice',
                text: 'Do You Want to Print Invoice ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printWithoutCompanyItems(this.store_printing_invoice)
                } else {

                }
            })
        },
        //retry customer with items
        retryPrintWithCustomerItems(){
            Swal({
                title: 'Retry Printing Invoice',
                text: 'Do You Want to Print Invoice ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printWithCustomerItems(this.store_printing_invoice)
                } else {

                }
            })
        },
        //retry company with items
        retryPrintWithCompanyItems(){
            Swal({
                title: 'Retry Printing Invoice',
                text: 'Do You Want to Print Invoice ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#ff0000f2',
                confirmButtonText: 'Retry',
                cancelButtonText: 'Continue Without Printing',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.printWithCompanyItems(this.store_printing_invoice)
                } else {

                }
            })
        },
        customerCopyReceipt(){
            if(this.customer_copy == false){
                Swal({
                    title: 'Print Receipts',
                    text: 'Do You Want to Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.customer_copy = true
                        this.printReceipt(this.store_receipt_data)
                    } else {
                        this.customer_copy = false
                    }
                })
            }
        },
    },
    computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser
        }
    },
    mounted(){
        this.initiatePrinter()
        setInterval(() => {
            if(this.object_created){
                this.$modal.hide("in-progress")
            }
        }, 1000);
    }
}