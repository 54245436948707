<template>
  <div>
    <!-- <modal :name="modal_name" transition="nice-modal-fade" class="unhold-modal"> -->
    <modal :name="modal_name" transition="nice-modal-fade" :class="{'unhold-modal': details.order_web_details.status== 'hold'}" class="final-modal display-order-detail">
      <div class="v-modal-content" v-if="details && details.order_web_details">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">{{details.order_web_details.order_id}}</span>
          <span class="pull-right" @click="hideOrderDetails()" style="cursor:pointer;">
            <i class="icon icon-close" style="font-size: 18px!important; color: #00448b;"></i>
          </span>

        </div>
        <div class="v-modal-body" style="padding: 0px!important;">
          <div class="v-modal-layout" style="padding: 15px 0px;overflow-y: auto !important;overflow-x: hidden !important;max-height:446px;">
            <div class="row pl-3 pr-5">
              <div v-if="details.order_web_details.status == 'hold'" class="div-modal-unhold">
                <button @click="holdOrder(details.order_web_details._id,details.order_web_details.status)" class="btn btn-checkout"> UnHold </button>
              </div>
              <div class="col-md-7" style="border-right: solid 2px #cdd2da;padding-right: 0px;padding-left: 0px;">
                <h6 class="d-inline-block pl-2 pr-2" style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;">Order Details</h6>
                <div class="icons-display-order-details pull-right" style="display:inline;right: 18px;position: absolute;">
                  <el-tooltip content="Order Summary" placement="top">
                    <span  class="order-details-icons mr-2">
                      <img src="https://cdn.digicollect.com/cdn/posv2/manage_order_icons/order_summary.svg" style="width: 24px;margin-top: -3px;" @click="inProgressSummary(details.order_web_details._id)">
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Add Item" placement="top" v-if="details.order_web_details.payment_status == 'UNPAID'">
                    <span class="order-details-icons mr-2">
                        <img src="https://cdn.digicollect.com/cdn/posv2/manage_order_icons/add.svg" style="width: 20px;margin-top: -3px;" @click="addMoreItems(details.order_web_details._id)">
                      <!-- <i class="icon icon-plus lightBlue" style="font-size:20px;width:20px;height:20px;line-height:20px;" @click="addMoreItems(details.order_web_details._id)"></i> -->
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Onhold" placement="top">
                    <span  class="order-details-icons mr-2">
                      <img src="https://cdn.digicollect.com/cdn/posv2/manage_order_icons/hold.svg" style="width: 24px; margin-top: -3px;" @click="holdOrder(details.order_web_details._id,details.order_web_details.status)" />
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Move Item" placement="top" v-if="details.order_web_details.payment_status == 'UNPAID'">
                    <span class="order-details-icons mr-2" @click="showMoveItems(details.order_web_details._id)">
                      <img src="https://cdn.digicollect.com/cdn/posv2/manage_order_icons/move_items.svg" style="width: 24px; margin-top: -3px;" />
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Delete" placement="top">
                    <span class="order-details-icons" v-if="loggedInUser.actions.orders.mark_as_delivered&&loggedInUser.actions.orders.mark_as_prepared">
                      <img src="https://cdn.digicollect.com/cdn/posv2/manage_order_icons/delete.svg" style="width: 22px; margin-top: -3px;"  @click="DeleteOrderModal(details.order_web_details._id)"/>
                      <!-- <i class="icon icon-delete lightBlue" style="font-size:18px;width:18px;height:18px;line-height:18px;" @click="DeleteOrderModal(details.order_web_details._id)"></i> -->
                    </span>
                  </el-tooltip>
                </div>
                <div class="contact-item row no-gutters align-items-center multiple-heading px-3 py-sm-4">
                  <div class="col-4 multiple-heading-div pl-2">Items</div>
                  <div class="col multiple-heading-div pl-9">Qty</div>
                  <div class="col multiple-heading-div pr-1">Prepared?</div>
                  <div class="col multiple-heading-div">Delivered?</div>
                </div>
                <div class="display-order-modal" style="margin-left: 6px;margin-right: 6px;">
                  <table v-if="!details.isPopup" class="table">
                    <tbody v-if="details.item_details.length <= 0">
                       <tr style="height: 40px;border-bottom: 1px solid #dee8f2;">
                         <td class="text-center">
                           <strong>No Products</strong>
                         </td>
                       </tr>
                    </tbody>
                    <tbody v-for="(vendor_item,index) in details.item_details" :key="index" v-else>
                      <tr style="height: 50px;">
                        <td :title="vendor_item.product_name" style="width: 147px;padding: 0.9rem 1.2rem!important;font-weight:500;color:#00448b;">
                          <span v-if="vendor_item.hasVariant" class="d-flex">
                             <div class="showType" :class="{'veg':vendor_item.variant_type == 'Veg','nonveg':vendor_item.variant_type == 'Non-Veg','egg':vendor_item.variant_type == 'Egg','pescatarian':vendor_item.variant_type == 'Pescatarian'}">
                                  <div></div>
                              </div>
                          <strong style="word-break: break-word;" class="d-block" >{{vendor_item.product_name}}/{{vendor_item.variant_name}}</strong>
                          </span>
                          <span v-else class="d-flex">
                              <div class="showType" :class="{'veg':vendor_item.product_type == 'Veg','nonveg':vendor_item.product_type == 'Non-Veg','egg':vendor_item.product_type == 'Egg','pescatarian':vendor_item.product_type == 'Pescatarian'}">
                                  <div></div>
                              </div>
                          <strong style="word-break: break-word;" class="d-block" >
                            {{vendor_item.product_name}}</strong>
                          </span>
                        </td>
                        <td style="padding: 0.9rem 1rem!important;font-weight:600;">{{vendor_item.selected_quantity}}</td>
                        <!-- Prepared  -->
                        <td class="tdcart text-center" style="padding: 0 7px!important;    width: 22%;">
                          <el-checkbox :disabled="vendor_item.isHold||vendor_item.isDelivered||vendor_item.unAvailable||!loggedInUser.actions.orders.mark_as_prepared" class="orderPrepared" style="left: 31px;" v-model="vendor_item.isPrepared" @change="ChangeOrderStatusWhenPrepared(vendor_item.item_id,'prepared',vendor_item.isPrepared, details.order_web_details._id)"></el-checkbox>
                        </td>
                        <!-- Prepared Ends -->
                        <!-- Delivered -->
                        <td class="tdcart text-center" v-if="loggedInUser.actions.orders.mark_as_delivered" style="padding: 7px 14px!important;">
                          <el-checkbox :disabled="!vendor_item.isPrepared" class="orderPrepared orderDelivered pl-4" style="padding-top:1px;" :class="{ orderDelivered: !vendor_item.isHold }" v-model="vendor_item.isDelivered" @change="ChangeOrderStatusWhenDelivered(vendor_item.item_id,'delivered',vendor_item.isDelivered,details.order_web_details._id)"></el-checkbox>
                          <el-dropdown style="float: right;">
                            <span class="el-dropdown-link pointer">
                              <i class="icon icon-dots-vertical iconHorizontal" style="color:#00448b!important;"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="waiterDropdown">
                              <span @click="ChangeAvailableStatus(vendor_item.item_id, vendor_item.unAvailable,details.order_web_details._id)" v-if="!vendor_item.isPrepared&&loggedInUser.actions.orders.mark_as_prepared">
                                <el-dropdown-item v-if="vendor_item.unAvailable">Available</el-dropdown-item>
                                <el-dropdown-item v-else>Unavailable</el-dropdown-item>
                              </span>
                              <span @click="DeleteModal(vendor_item.item_id,vendor_item.selected_quantity,vendor_item.product_name,details.order_web_details._id,vendor_item.variant_name)" v-if="loggedInUser.actions.orders.mark_as_delivered">
                                <el-dropdown-item>Delete</el-dropdown-item>
                              </span>
                              <span @click="orderHold(vendor_item.item_id, vendor_item.isHold,details.order_web_details._id)" v-if="!vendor_item.unAvailable&&!vendor_item.isPrepared&&loggedInUser.actions.orders.mark_as_delivered">
                                <el-dropdown-item v-if="!vendor_item.isHold">Hold</el-dropdown-item>
                                <el-dropdown-item v-if="vendor_item.isHold">Unhold</el-dropdown-item>
                              </span>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </td>
                        <!-- Delivered Ends -->
                      </tr>
                      <tr v-if="vendor_item.addons.length > 0">
                        <td colspan="5" style="background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.5rem 1.2rem!important;">
                          <span class="text-green font-600">Add-On :</span><br>

                          <div class="flex-adons pt-1"  v-for="(addon, addon_index) in  vendor_item.addons" :key="addon_index" >
                             <el-tooltip class="item" effect="dark" :content="addon.type" placement="top">
                              <div class="showType" :class="{'veg':addon.type == 'Veg','nonveg':addon.type == 'Non-Veg','egg':addon.type == 'Egg','pescatarian':addon.type == 'Pescatarian'}">
                                  <div></div>
                              </div>
                             </el-tooltip>
                            <span>
                              {{addon.name}}
                              <span class="text-green">X {{addon.quantity}}</span>
                              <span class="text-green" v-if="addon_index != vendor_item.addons.length - 1">,</span>
                              <!-- <span v-if="vendor_item.addons.length > 1 || vendor_item.addons[-1]['quantity'] != vendor_item.addons[vendor_item.addons.length - 1]['quantity']">,</span> -->
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="vendor_item.item_note" class="font-weight-bold" style="height: 44px;">
                        <td colspan="5" style="padding:0px 12px!important;" class="vendorNote"> Note:<br>
                        {{vendor_item.item_note}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-if="details.isPopup" class="table">
                    <tbody v-for="(vendor,index) in details.vendor_order_details" :key="index">
                      <tr style="height:20px;">
                        <td style="background-color: #f6f6f6;padding: 7px 12px!important;" colspan="4">
                          <strong>{{vendor.vendor_name}}</strong>
                        </td>
                      </tr>
                      <tr v-for="(vendor_item,index) in vendor.item_details" :key="index" style="height: 20px;">
                        <td :title="vendor_item.product_name" style="font-weight:500;padding: 0.9rem 1.2rem;width: 161px;color:#00448b;">
                          {{vendor_item.product_name}}
                          <div v-if="vendor_item.item_note" class="font-weight-bold vendorNote">
                            Note:<br>
                            {{vendor_item.item_note}}
                          </div>
                        </td>
                        <td style="padding: 0.9rem 1.2rem;font-weight:500;">{{vendor_item.selected_quantity}}</td>
                        <td style="text-align: right;padding: 0px 2rem 0px 0rem;">
                          <el-checkbox v-if="!vendor_item.isHold" :disabled="vendor_item.isDelivered" class="orderPrepared" style="left: 19px;" v-model="vendor_item.isPrepared" @change="ChangeOrderStatusWhenPrepared(vendor_item.item_id,'prepared',vendor_item.isPrepared,details.order_web_details._id)"></el-checkbox>
                        </td>
                        <td style="padding: 0px 2rem 0px 0rem;text-align: right;">
                          <el-checkbox :disabled="!vendor_item.isPrepared" class="orderPrepared orderDelivered pl-4" :class="{ orderDelivered: !vendor_item.isHold }" v-model="vendor_item.isDelivered" @change="ChangeOrderStatusWhenDelivered(vendor_item.item_id,'delivered',vendor_item.isDelivered,details.order_web_details._id)"></el-checkbox>
                          <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                              <i class="icon icon-dots-vertical lightBlue moreInfoOrder pointer"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="waiterDropdown">
                              <span @click="DeleteModal(vendor_item.item_id,vendor_item.selected_quantity,vendor_item.product_name,details.order_web_details._id)" v-if="!loggedInUser.actions.orders.is_chef">
                                <el-dropdown-item>Delete</el-dropdown-item>
                              </span>
                              <span @click="orderHold(vendor_item.item_id, vendor_item.isHold,details.order_web_details._id)" v-if="!loggedInUser.actions.orders.is_chef ">
                                <el-dropdown-item v-if="!vendor_item.isHold">Hold</el-dropdown-item>
                                <el-dropdown-item v-if="vendor_item.isHold">Unhold</el-dropdown-item>
                              </span>
                              <span @click="ChangeAvailableStatus(vendor_item.item_id, vendor_item.unAvailable,details.order_web_details._id)" v-if="loggedInUser.actions.orders.is_chef && vendor_item.status == 'preparing'">
                                <el-dropdown-item v-if="!vendor_item.unAvailable">Unavailable</el-dropdown-item>
                                <el-dropdown-item v-if="vendor_item.unAvailable">Available</el-dropdown-item>
                              </span>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5">
                <div class="text-center">
                  <div class="pr-3 row no-gutters align-items-center justify-content-between mt-1">
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <span class="fs-14 fw-normal">Sequence No.</span>
                      </div>
                    </div>
                    <div class="fs-13 font-weight-bold">{{details.order_web_details.order_number}}</div>
                  </div>
                  <div class="pr-3 row no-gutters align-items-center justify-content-between mt-1">
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <span class="fs-14 fw-normal">Order Time</span>
                      </div>
                    </div>
                    <div class="fs-14 font-weight-bold">{{details.order_web_details.created_time}}</div>
                  </div>
                  <div class="pr-3 row no-gutters align-items-center justify-content-between mt-1">
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <span class="fs-13 fw-normal">Order ID</span>
                      </div>
                    </div>
                    <div class="fs-14 font-weight-bold">{{details.order_web_details.order_id}}</div>
                  </div>
                  <div class="pr-3 row no-gutters align-items-center justify-content-between mt-1">
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <span class="fs-14 fw-normal">Customer Name</span>
                      </div>
                    </div>
                    <div class="fs-13 font-weight-bold text-truncate" style="max-width:100px;" :title="details.order_web_details.customer_name">{{details.order_web_details.customer_name}}</div>
                  </div>
                  <div class="pr-3 row no-gutters align-items-center justify-content-between mt-1">
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <span class="fs-14 fw-normal">Table Number</span>
                      </div>
                    </div>
                    <div class="fs-13 font-weight-bold">{{details.order_web_details.tables}}</div>
                  </div>
                </div>
                <div class="font-weight-bold pt-4 fs-14">Notes</div>
                <div class="pt-3 fs-14" style="text-transform: capitalize;word-break: break-word !important;">{{details.order_web_details.note}}</div>
                <div class="pt-4 row no-gutters align-items-center justify-content-between" v-if="loggedInUser.actions.orders.view_wizard">
                  <div class="stepwizard">
                    <div class="stepwizard-row setup-panel">
                      <div class="stepwizard-step">
                        <div class="wizardSteps pointer" @click="activeWizard()" :class="{ activeWizard: isActiveWizard == true}">
                          <a :class="{ completedCircle: isActiveWizard == true}" class="btn-circle">1</a>
                        </div>
                      </div>
                      <div class="stepwizard-step">
                        <div class="wizardSteps pointer" @click="completedWizard()" :class="{ completedWizard: details.order_web_details.status == 'prepared' || details.order_web_details.status == 'delivered'}">
                          <a :class="{ completedCircle: details.order_web_details.status == 'prepared'}" class="btn-circle">2</a>
                        </div>
                      </div>
                      <div class="stepwizard-step">
                        <div class="wizardSteps pointer" @click="deliveredWizard()" :class="{ closedWizard: details.order_web_details.status == 'delivered'}">
                          <a :class="{ completedCircle: details.order_web_details.status == 'delivered'}" class="btn-circle">3</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="{ activeCircle: isActiveWizard == true}" class="pl-7 pt-2">Active</div>
                  <div :class="{ defaultBlue: isCompletedWizard == true}" class="pt-2 pl-1">Prepared</div>
                  <div :class="{ deliveredCircle: isDeliveredWizard == true}" class="pt-2 pr-2">Delivered</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-modal-footer text-center pb-3">
             <!-- manager-login -->
            <!-- <div class="pl-4 pt-3 pb-3" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared && details.item_details.length > 0">
              <label class="font-600 pt-1" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared">Prepared All</label>
              <el-switch class="ml-3" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared"
                v-model="details.is_delivered" @change="prepare_and_deliver(details.order_web_details.status,details.order_web_details._id)">
              </el-switch>
            </div> -->
            <div class="pl-4 pt-1 pb-1 mb-2" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared && details.item_details.length > 0" style="background-color: #bccce0;">
              <label class="font-600 pt-1" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared">Prepared All</label>
              <el-switch class="ml-3 pr-5" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared"
                v-model="details.is_prepared" @change="prepare_all_manager(details.order_web_details.status,details.order_web_details._id)" :disabled="details.is_delivered">
              </el-switch>
              <label class="font-600 pt-1" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared">Delivered All</label>
              <el-switch class="ml-3" v-if="loggedInUser.actions.orders.mark_as_delivered && loggedInUser.actions.orders.mark_as_prepared"
                v-model="details.is_delivered" @change="deliver_all_manager(details.order_web_details.status,details.order_web_details._id)" :disabled="!details.is_prepared">
              </el-switch>
            </div>
            <!-- manager-login -->
            <!-- waiter-login -->
            <div class="pl-4 pt-1 pb-1 mb-2" v-if="loggedInUser.actions.orders.mark_as_delivered && !loggedInUser.actions.orders.mark_as_prepared" style="background-color: #bccce0;">
              <label class="font-600 pt-1" v-if="loggedInUser.actions.orders.mark_as_delivered && !loggedInUser.actions.orders.mark_as_prepared">All Active Items Delivered</label>
              <el-switch class="ml-3" v-if="loggedInUser.actions.orders.mark_as_delivered && !loggedInUser.actions.orders.mark_as_prepared"
                v-model="details.is_delivered" @change="deliver_waiter(details.order_web_details.status,details.order_web_details._id)">
              </el-switch>
            </div>
            <!-- waiter-login -->
            <a class="btn btn-link btn-smm" style="text-transform: upper\case !important;font-size: 12px !important;" @click="hideOrderDetails()">CANCEL</a>
            <button v-if="details.order_web_details.status == 'delivered'" type="submit" class="btn btn-secondary btn-smm" @click="isCashierActive()">SUBMIT</button>
          </div>
        </div>
      </div>
    </modal>
    <pay-bill :for_approvals="false" modal_name="pay_summary_bill" @updateCustomerDetails="updateCustomerDetails" @PaymentComplete="paymentComplete" :isPopUp="details.isPopup" :summary="order" :isDelivered="true" :editOrder="false" @close="closePayBill" @updateCheckout="updateSummary" :inProgress="inProgress"></pay-bill>
    <delete-item :isOrderActive="true" :delete_quantity="delete_quantity" :item_id="item_id" :product_name="product_name" :view_type="view_type" :type="type" :product_quantity="delete_quantity" :order_id="order_id" :DeleteOrderDiv="DeleteOrderDiv" :temp_order_id="temp_order_id" modal_name="delete-item-modal"></delete-item>
    <move-items :dineIn="dineInView" @hideMoveItems="hideMoveItems" v-if="moveItemsModal == true" :move_orders="order" :created_time="start_time" modal_name="move-items"></move-items>
    <sweet-modal ref="success_modal" icon="success">
      {{ modal_msg }}
    </sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">
      {{ modal_msg }}
    </sweet-modal>
  </div>
</template>
<style>
.snackbar {
  font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;
  font-size: 14px;
  color: #fff;
  text-align: left;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
}
.snackbar[class*='bottom'] {
  z-index: 999999 !important;
  background-color: #00448b !important;
  width: 50%;
  top: 10.5rem;
  bottom: inherit;
}
/* .display-order-detail .display-order-modal .table tbody tr{
  border-bottom: 1px solid #a8adb4!important;
} */
.display-order-detail .display-order-modal .stepwizard-row:before{
  background-color: #a8adb4!important;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #00448b !important;
}
.order-details-icons {
  cursor: pointer;
}
.div-modal-unhold {
  width: 100%;
  text-align: center;
  height: 50px;
  background-color: #424141;
  opacity: 1;
  top: 58%;
  z-index: 999 !important;
  pointer-events: all !important;
  position: absolute;
}
.div-modal-unhold .btn-checkout {
  border-radius: 23px;
  top: 8px;
  background-image: linear-gradient(to bottom, #ffb333, #e29005);
  color: #303031;
  padding: 0px 40px;
  margin: 0px;
  font-size: 14px !important;
  font-weight: 600;
  height: 3.4rem;
  line-height: 2.6rem;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.unhold-modal.v--modal-overlay .v-modal-body {
  opacity: 0.8;
  pointer-events: none;
}
.unhold-modal.v--modal-overlay .v-modal-footer {
  opacity: 0.5;
  pointer-events: none;
}
.flex-adons{
    display: inline-flex;
    padding-right: 6px;
    font-weight: 600;
}
</style>
<script>
import PayBill from './PayBill'
import { SweetModal } from 'sweet-modal-vue'
import { EventBus } from './eventBus/nav-bar-event.js'
import ManageOrderService from './mixins/manage_order.js'
import DeleteItem from './DeleteItem'
const MoveItems = () => import('./MoveItems.vue')
export default {
  props: ['modal_name', 'type', 'view_type'],
  data() {
    return {
      isOrderActive:true,
      isPrepared: false,
      isDelivered: false,
      isActiveWizard: true,
      isCompletedWizard: false,
      isDeliveredWizard: false,
      details: [],
      isHold: false,
      order_id: '',
      order: {},
      modal_msg: '',
      item_id: '',
      delete_quantity: '',
      product_name: '',
      order_id: '',
      inProgress:false,
      temp_order_id:'',
      DeleteOrderDiv:false,
      moveItemsModal: false,
      start_time:'',
      selectedOrderId: null,
      dineInView: false
    }
  },
  components: {
    PayBill,
    SweetModal,
    DeleteItem,
    MoveItems
  },
  methods: {
    // prepared all
    async prepare_all_manager(status, order_id) {
      if (status == 'preparing') {
        let response = await this.getPreparedManager({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      else {
        let response = await  this.getUnpreparedManager({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      this.get_all_orders_dine_in(order_id);
    },
    async getPreparedManager(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/prepared',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
      async getUnpreparedManager(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/unprepared',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },

      // Deliver all

    async deliver_all_manager(status, order_id) {
      if (status == 'delivered') {
        let response = await this.getUndeliverMAnager({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      else {
        let response = await  this.getDeliverManager({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      this.get_all_orders_dine_in(order_id);
    },
    async getDeliverManager(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/delivered',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
      async getUndeliverMAnager(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/undelivered',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },

      // prepared all and delivered all

    async prepare_and_deliver(status, order_id) {
      if (status == 'delivered') {
        let response = await this.getUndeliveredUnprepared({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      else {
        let response = await  this.getPrepareDelivered({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      this.get_all_orders_dine_in(order_id);
    },
    async getPrepareDelivered(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/prepared_and_delivered',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
      async getUndeliveredUnprepared(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/undelivered_and_unprepared',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
      async deliver_waiter(status, order_id) {
      if (status == 'delivered') {
        let response = await this.getDeliveredWaiter({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      else {
        let response = await  this.getUndeliveredWaiter({'order_id':order_id});
        this.$toasted.global.success(response.message);
      }
      this.get_all_orders_dine_in(order_id);
    },
    async getDeliveredWaiter(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/delivered',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
      async getUndeliveredWaiter(params) {
        return new Promise(async(resolve, reject) => {
            let response = await this.$http.post(
                '/orders/items/undelivered',
                params
            )
            if (response.data.status_id == 1) {
                resolve(response.data)
            } else {
                reject(response.data)
            }
        })
      },
    hideMoveItems(){
      this.moveItemsModal = false;
      this.dineInView = false;
      this.$modal.hide('move-items');
    },
    showMoveItems(order_id){
      this.start_time = this.details.order_web_details.created_time
      if (order_id) {
        this.selectedOrderId = order_id
      }
      this.$http.post("/orders/summary", {order_id: this.selectedOrderId, in_progress:true})
        .then(res => {
          if (res.data.status_id == 1) {
            this.order = res.data.order;
            this.moveItemsModal = true;
            this.dineInView = true;
            setTimeout(() => {
              this.$modal.show("move-items");
            }, 300);
          }
        })
    },
    closePayBill() {
      this.$modal.hide('pay_summary_bill')
      this.$modal.show(this.modal_name)
    },
    hideOrderDetails() {
      EventBus.$emit('CloseOrderDetail', 'pay_summary_bill')
      this.$modal.hide(this.modal_name)
    },
    updateSummary() {
      this.showPayBill()
    },
    updateCustomerDetails(customer_name){
      this.details.order_web_details.customer_name = customer_name;
    },
    isCashierActive() {
      this.$http.get('/cashier/is_cashier_session_active').then(res => {
        this.modal_msg = res.data.reason
        if (res.data.status_id == 0) {
          this.modal_msg = res.data.reason
          this.$refs.warning_modal.open()
          setTimeout(() => {
            this.$refs.warning_modal.close()
          }, 2000)
        }
        if (res.data.status_id == 1) {
          this.$store.commit(
            'setCashRegister',
            res.data.response.cash_register_details
          )
          this.showPayBill()
        } else {
          this.modal_msg = res.data.reason
          this.$refs.warning_modal.open()
          setTimeout(() => {
            this.$refs.warning_modal.close()
          }, 2000)
        }
      })
    },
    showPayBill() {
      this.$http
        .post('/orders/summary', {
          order_id: this.details.order_web_details._id,
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$modal.hide(this.modal_name)
            this.order = res.data.order
            EventBus.$emit('summaryChanged', res.data.order)
          }
        }).then(res => {
          this.$modal.show('pay_summary_bill');
        })
    },
    activeWizard() {
      this.isActiveWizard = true
      this.isCompletedWizard = false
      this.isDeliveredWizard = false
    },
    completedWizard() {
      this.isActiveWizard = true
      this.isCompletedWizard = true
      this.isDeliveredWizard = false
    },
    deliveredWizard() {
      this.isActiveWizard = true
      this.isCompletedWizard = true
      this.isDeliveredWizard = true
    },
    orderHold(item_id, isHold, order_id) {
      this.isHold = !isHold
      this.ChangeOrderStatustoHold(item_id, this.isHold, order_id)
      this.get_all_orders_dine_in(order_id)
    },
    addMoreItems(orderId) {
      this.$router.push({
        name: 'TakeOrderNew',
        params: { orderId, editOrder: true },
      })
    },
    DeleteModal(item_id, quantity, product_name, order_id,variant_name="") {
      this.item_id = item_id
      this.delete_quantity = quantity
      if(variant_name){
        this.product_name = product_name + '/' + variant_name;
      }
      else{
        this.product_name = product_name
      }
      // this.product_name = product_name
      this.order_id = order_id
      this.temp_order_id = ''
      this.DeleteOrderDiv = false
      this.$modal.show('delete-item-modal')
    },
    DeleteOrderModal(order_id,itemdetails,temp_order_id){
        this.item_id = "";
        this.delete_quantity = "";
        this.product_name = "";
        this.order_id = order_id
        this.temp_order_id = temp_order_id
        this.DeleteOrderDiv = true
        this.$modal.show("delete-item-modal");
    },
    inProgressSummary(order_id) {
      this.$http
        .post('/orders/summary', {
          order_id: order_id,
          in_progress: true,
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.order = res.data.order
            this.inProgress = true
            this.isDelivered = false
          }
        }).then(res => {
          this.$modal.show('pay_summary_bill');
        })
    },
    paymentComplete(){

      setTimeout(() => {
            this.$modal.hide(this.modal_name)
          }, 1000)
        this.getTables()
        this.get_all_orders(false)
      EventBus.$emit('CloseOrderDetail', 'pay_summary_bill')
    },
    hideOrderDetailsNew(){
      this.$modal.hide(this.modal_name)
    }

  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    }
  },
  mounted() {
    EventBus.$on('TableView', details => {
      this.details = details
      if (this.details.isPopup) {
        for (var vendor_index in this.details.vendor_order_details) {
          for (var item_index in this.details.vendor_order_details[vendor_index]
            .item_details) {
            if (
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].item_status == 'Prepared'
            ) {
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isPrepared = true
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isHold = false
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].unAvailable = false
            } else if (
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].item_status == 'Delivered'
            ) {
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isPrepared = true
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isDelivered = true
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isHold = false
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].unAvailable = false
            } else if (
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].item_status == 'Hold'
            ) {
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isPrepared = false
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isDelivered = false
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].isHold = true
              this.details.vendor_order_details[vendor_index].item_details[
                item_index
              ].unAvailable = false
            }
          }
        }
      } else {
        for (var item_index in details.item_details) {
          if (details.item_details[item_index].item_status == 'Prepared') {
            details.item_details[item_index].isPrepared = true
            details.item_details[item_index].isHold = false
            details.item_details[item_index].unAvailable = false
          } else if (
            details.item_details[item_index].item_status == 'Delivered'
          ) {
            details.item_details[item_index].isPrepared = true
            details.item_details[item_index].isDelivered = true
            details.item_details[item_index].isHold = false
            details.item_details[item_index].unAvailable = false
          } else if (details.item_details[item_index].item_status == 'Hold') {
            details.item_details[item_index].isPrepared = false
            details.item_details[item_index].isDelivered = false
            details.item_details[item_index].isHold = true
            details.item_details[item_index].unAvailable = false
          } else if (
            details.item_details[item_index].item_status == 'Unavailable'
          ) {
            details.item_details[item_index].isDelivered = false
            details.item_details[item_index].isPrepared = false
            details.item_details[item_index].isHold = false
            details.item_details[item_index].unAvailable = true
          } else {
            details.item_details[item_index].isDelivered = false
            details.item_details[item_index].isPrepared = false
            details.item_details[item_index].isHold = false
            details.item_details[item_index].unAvailable = false
          }
        }
      }
    })
    EventBus.$on('CloseModal', value => {
      this.$modal.hide(value)
    })
    EventBus.$on('PaymentComplete', value => {
      // this.$modal.hide('order-detail')
      this.hideOrderDetailsNew()
    })
  },
  mixins: [ManageOrderService],
  destroyed() {
    EventBus.$off('TableView')
    EventBus.$off('CloseModal')
    EventBus.$off('PaymentComplete')
  },
}
</script>
