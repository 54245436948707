<template>
  <div>
    <modal :name="modal_brand" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">Add New Payment Mode</span>
        </div>
        <div class="v-modal-body">
          <div class="v-modal-layout">
            <form>
              <div class="form-group mt-0 contactGroup">
                <input
                  class="inputContact"
                  @input="makeFalse"
                  v-model.trim="payment_method.name"
                  type="text"
                  name="name"
                  v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                  autofocus
                  :maxlength="paymentmode"
                />
                <span class="highlightContact"></span>
                <span class="barContact"></span>
                <label class="labelContact">
                  Other Payment Mode Name
                  <sup>★</sup>
                </label>
                <span
                  class="invalid-feedback-form"
                  v-show="errors.has('name')"
                  style="display:contents;"
                >{{ errors.first('name') }}</span>
                <p
                  class="pull-right text-right text-grey font-600 fs-12 pt-1"
                >{{paymentmode - payment_method.name.length}} / 30</p>
                <div
                  v-show="exists"
                  class="text-danger fs-13"
                  v-if="payment_method.name.length>0"
                >Payment Mode Already Exists</div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-3">
                  <label class="text-secondary pr-2">Is Other Payment Modes Available ?</label>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        checked
                        type="radio"
                        v-model="payment_method.status"
                        class="form-check-input"
                        name="optionsRadios"
                        value="true"
                        id="optionsRadios2"
                      />
                      <span class="radio-icon"></span>
                      <span>Yes</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        v-model="payment_method.status"
                        class="form-check-input"
                        name="optionsRadios"
                        value="false"
                        id="optionsRadios2"
                      />
                      <span class="radio-icon"></span>
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div class="v-modal-footer text-center pt-5">
              <button :disabled="add_new_payment_api_inprogress"
                class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                @click="closeAddNewPayment()"
              >CANCEL</button>
              <button class="btn btn-secondary btn-smm text-uppercase ml-5" @click="savePayment()" :disabled="add_new_payment_api_inprogress">SAVE
                  <half-circle-spinner :animation-duration="1000" :size="20" v-if="add_new_payment_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import VModal from 'vue-js-modal'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ['modal_brand'],
  data() {
    return {
      paymentmode: 30,
      payment_method: {
        name: '',
        status: true,
        outlet_id: this.$route.query.outletId
      },
      exists: false,
      add_new_payment_api_inprogress: false
    }
  },
  components: {
    VModal,
    HalfCircleSpinner
  },
  methods: {
    addNewPayment() {
      this.$modal.show('add-payment-method')
    },
    closeAddNewPayment() {
      ;(this.payment_method = {
        name: '',
        status: true,
        outlet_id: this.$route.query.outletId
      }),
        (this.exists = false)
      this.$modal.hide('add-payment-method')
    },
    savePayment() {
      this.$validator.validateAll().then(result => {
        this.add_new_payment_api_inprogress = true;
        this.$http
          .post('/outlet/create_payment_method', this.payment_method)
          .then(res => {
            if (res.data.status_id == 1) {
              this.$swal(
                'Created',
                'Payment Mode Created Successfully',
                'success'
              )
              this.$emit('PaymentSaved', true)
              this.closeAddNewPayment()
            } else {
              this.exists = true
            }
            this.add_new_payment_api_inprogress = false;
          }).catch((err) => {
            this.add_new_payment_api_inprogress = false;
          })
      })
    },
    makeFalse() {
      this.exists = false
    }
  },
  created() {
    const dict = {
      custom: {
        name: {
          required: () => 'Payment Mode is Required',
          regex: () => 'Enter Valid Payment Mode'
        }
      }
    }
    this.$validator.localize('en', dict)
  }
}
</script>
