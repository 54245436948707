<template>
    <div class="page-layout blank contacts-manage">
        <div class="pb-10">
            <h2 class="ml-0 mb-1 mt-1">
                <div class="d-flex justify-content-between">
                    <div class="breadcrumbnew">
                        <a>Products</a>
                        <a>List View</a>
                        <span>Edit Product</span>
                    </div>
                     <div class="d-flex align-items-center mr-1">
                        <router-link :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/previous.svg" class="mr-2">
                            </el-tooltip>
                        </router-link>
                        <router-link :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward"
                                placement="top">
                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/next.svg">
                            </el-tooltip>
                        </router-link>
                     </div>
                </div>
            </h2>
            <div class="br-15">
                <div class="page-layout left-sidebar">
                    <div class="page-content-wrapper" style="border-radius: 15px;padding: 0px !important;">
                        <div class="page-content py-4 px-1 br-15 p-4" style="background-color: #dfe8f4 !important;">
                            <h6 class="text-secondary font-weight-bold text-center pb-2 pt-1">EDIT PRODUCT</h6>
                            <div class="general-section pt-2 pb-6 pl-8 pr-8 mt-4">
                                <div class="infoHeader mt-2">
                                    <div class="descriptionheader">
                                        <div class="description-text-header-left">
                                            <h5 class="font-weight-bold text-black whiteSpace">General  Information</h5>
                                        </div>
                                        <div class="description-text-header-right">
                                            <span class="collapse-addon" @click="showGeneral()">
                                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/main-collapse.svg" :class="{'iconRotate':showGInfo == true}" style="width:23px !important;bottom:-11px !important;" class="rotateIcon">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showGInfo == true">
                                    <div class="row mt-1">
                                        <div class="col-lg-2 col-md-2 col-sm-2" style="text-align: start;">
                                            <span class="ml-3" style="text-align:center;">
                                                <img v-if="create.image" :src="create.image" style="width:90px!important;height:90px!important;"/>
                                                <img v-else :src="imgDataUrl" style="width: 90px!important;height:90px!important;"/>
                                            </span>
							                <upload-picture @uploadImage="uploadImage" class="pl-0 pr-0"></upload-picture>
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-8">
                                            <div class="mt-5 mb-3">
                                                <span class="font-weight-bold">Product Status? : *</span>
                                                <el-switch v-model="create.status" active-text="Yes" inactive-text="No" class="pl-8"></el-switch>
                                            </div>
                                            <div class="form-group contactGroup">
                                                <input class="inputContact" :maxlength="pName" autocomplete="off"  v-validate="'required'" v-model="create.name" type="text" name="product_name" placeholder="Enter Product Name"/>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Product Name<sup>★</sup></label>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <span class="invalid-feedback-form text-danger mt-0 text-left d-block" v-show="errors.has('product_name')">{{ errors.first("product_name") }}</span>
                                                </div>
                                                <div>
                                                    <span class="text-right d-block">{{ pName - create.name.length}}/{{pName}}</span>
                                                </div>    
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <div class="text-center">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qr-code.svg" class="qr-logo" />
                                                <qrcode-vue :value="value" :size="size" background="#cddbeb" level="H" foreground="#00448b" style="margin-top: 18px;"></qrcode-vue>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="text-secondary mr-5">Inventory Type</div>
                                        <div class="mt-2">
                                            <el-radio v-model="create.product_is" label="MENU" id="menu_item">Product / Finished Goods</el-radio>
                                            <el-radio v-model="create.product_is" label="INGREDIENT" id="ingredients">Item / Raw Material </el-radio>
                                            <el-radio v-model="create.product_is" label="BOTH" id="both_prod_type">Both</el-radio>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group contactGroup">
                                                <input class="inputContact" :maxlength="pDesc" autocomplete="off"  v-model="create.description" type="text"  placeholder="Enter Description"/>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Description</label>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <span class="invalid-feedback-form text-danger mt-0 text-left d-block"></span>
                                                </div>
                                                <div>
                                                    <span class="text-right d-block">{{ pDesc - create.description.length}}/{{pDesc}}</span>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: 0px;">
												<label class="text-secondary" style="font-size: 14px;margin-bottom: 0px;">Category<sup>★</sup></label>
													<multiselect
														v-model="temp_category_id"
														class="digi-multiselect"
														id="ajax"
														label="category_name"
														placeholder="Search & Select Category"
														:options="allCategories"
														:multiple="false"
														:searchable="true"
														:loading="isLoading"
														:internal-search="false"
														:clear-on-select="false"
														:close-on-select="true"
														:max-height="200"
														:show-no-results="true"
														:hide-selected="false"
														@search-change="loadCategorySearch"
														@close="checksubcategory"
														open-direction="top"
														v-validate="'required'"
														name="category"
													>
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.category_name }}</span>
                                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
													<template slot="afterList">
															<div v-observe-visibility="reachedEndOfList" />
													</template>
													<span slot="noResult" class="font-weight-bold text-center d-block">No Results Found.</span>
													</multiselect>
													<span class="invalid-feedback-form" v-show="errors.has('category')" style="display:inline;">Please Select Category</span>
											</div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: 0px;">
												<label class="text-secondary"  style="font-size: 14px;margin-bottom: 0px;">Sub Category<sup>★</sup></label>                                            
                                                <multiselect class="digi-multiselect" v-if="temp_category_id == ''" :options="optionsEmpty" placeholder="Search & Select Sub Category"> 		
                                                    <template slot="noOptions">Please Select Category</template>
                                                </multiselect>
												<multiselect v-else
                                                    v-model="temp_sub_category_id"
                                                    class="digi-multiselect"													
                                                    id="ajax1"
                                                    label="sub_category_name"
                                                    placeholder="Search & Select Sub Category"
                                                    :options="allSubCategories"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :loading="isLoadingSubCategory"
                                                    :internal-search="false"
                                                    :clear-on-select="false"				
                                                    :close-on-select="true"
                                                    open-direction="top"
                                                    :options-limit="40"
                                                    :max-height="200"
                                                    :show-no-results="true"
                                                    :hide-selected="false"
                                                    @search-change="loadSubCategorySearch"
                                                    v-validate="'required'"
                                                    name="subcategory"
                                                    @close="checkProductType"
                                                >
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.sub_category_name}}</span>
                                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="afterList">
                                                        <div v-observe-visibility="reachedEndOfListSubcategory" v-if="allSubCategories.length >= 10" />
                                                    </template>
                                                    <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found.</span>
                                                </multiselect>
											    <span class="invalid-feedback-form" v-show="errors.has('subcategory')" style="display:inline;">Please Select Sub Category</span>
										    </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: 0px;">
												<label class="text-secondary"  style="font-size: 14px;margin-bottom: 0px;">Product Type</label>                                            
                                                <multiselect class="digi-multiselect" v-if="temp_sub_category_id == ''" :options="optionsEmpty" placeholder="Search & Select Product Type"> 		
                                                    <template slot="noOptions">Please Select Sub Category</template>
                                                </multiselect>
												<multiselect v-else
                                                    v-model="temp_product_type_id"
                                                    class="digi-multiselect"													
                                                    id="ajax1"
                                                    label="name"
                                                    placeholder="Search & Select Product Type"
                                                    :options="allProductType"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :loading="isLoadingProductType"
                                                    :internal-search="false"
                                                    :clear-on-select="false"				
                                                    :close-on-select="true"
                                                    open-direction="top"
                                                    :options-limit="40"
                                                    :max-height="200"
                                                    :show-no-results="true"
                                                    :hide-selected="false"
                                                    @search-change="loadProductTypeSearch"
                                                    @close="updateProductType"
                                                >
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.name}}</span>
                                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="afterList">
                                                        <div v-observe-visibility="reachedEndOfListProductType" v-if="allProductType.length >= 10" />
                                                    </template>
                                                    <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found.</span>
                                                </multiselect>
										    </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: 0px;">
												<label class="text-secondary" style="font-size: 14px;margin-bottom: 0px;">Brand</label>
                                                <multiselect
                                                    v-model="temp_brand"
                                                    class="digi-multiselect"
                                                    id="ajax6"
                                                    label="name"
                                                    placeholder="Search & Select Brand"
                                                    :options="allBrands"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :loading="isLoadingBrand"
                                                    :internal-search="false"
                                                    :clear-on-select="false"
                                                    :close-on-select="true"
                                                    :max-height="200"
                                                    :show-no-results="true"
                                                    :hide-selected="false"
                                                    @search-change="loadBrandSearch"
                                                    open-direction="top"
                                                    @close="updateBrand"
                                                    >
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.name }}</span>
                                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="afterList">
                                                            <div v-observe-visibility="reachedEndOfListBrand" />
                                                    </template>
                                                    <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found.</span>
                                                </multiselect>
											</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="general-section pt-2 pb-6 pl-8 pr-8 mt-4" v-for="(variant,index) in create.variants" :key="index">
                                <div class="infoHeader mt-2" style="background: #00448b !important;">
                                    <div class="descriptionheader">
                                        <div class="description-text-header-left pl-2">
                                            <h5 class="font-weight-bold text-white whiteSpace">Variant {{index+1}}</h5>
                                        </div>
                                        <div class="description-text-header-right">
                                            <span class="collapse-addon" @click="showVariant(index)">
                                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/main-collapse.svg" :class="{'iconRotate':showVariants.includes('variant'+index)}" style="width:23px !important;bottom:-11px !important;" class="rotateIcon">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showVariants.includes('variant'+index)">
                                    <div class="font-weight-bold text-black whiteSpace fs-16 mt-5">Primary Information :</div>
                                    <div class="d-flex mt-3 align-items-center">
                                        <span class="text-black fs-15 mr-5 whiteSpace">Class : </span>
                                        <div class="mt-3">
                                            <el-radio-group
                                                v-model="variant.type"
                                                v-for="(type,tindex) in getallTypes"
                                                :key="tindex"
                                                v-validate="'required'"
                                                :name="'variantType' + index"
                                            >
                                                <el-radio :label="type" :id="type">{{type}}</el-radio>
                                            </el-radio-group><br>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variantType' + index)" style="display: inline !important">Variant Type is Required</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" v-validate="'required'" :name="'variant_name' + index" :maxlength="pVname" type="text" v-model="variant.variant_name" placeholder="Enter Variant Name" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Variant Name<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variant_name' + index)" style="display: inline !important">Variant Name is Required</span>
                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{pVname - variant.variant_name.length}} / {{pVname}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" v-validate="'required'"  v-on:keypress="isNumber($event)" :name="'variant_quantity' + index" type="text" v-model.number="variant.variant_quantity" placeholder="Enter Variant Quantity" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Quantity<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variant_quantity' + index)" style="display: inline !important">Variant Quantity is Required</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" v-validate="'required'"  v-on:keypress="isNumber($event)" :name="'measure' + index"  type="text" v-model.number="variant.measure" placeholder="Enter Size or Measure" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Size or Measure<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('measure' + index)" style="display: inline !important">Size or Measure is Required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: 0px;">
                                                <label class="text-secondary" style="font-size: 14px;margin-bottom: 0px;">Unit of Measurement *</label>
                                                <multiselect
                                                    :key="index"
                                                    v-model="variant.uom"
                                                    class="digi-multiselect"
                                                    id="ajax10"
                                                    label="name"
                                                    placeholder="Search & Select Unit of Measurement"
                                                    :options="measurements"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :loading="loadingmeasure"
                                                    :internal-search="true"
                                                    :clear-on-select="false"
                                                    :close-on-select="true"
                                                    :max-height="200"
                                                    :show-no-results="true"
                                                    :hide-selected="false"
                                                    @search-change="loadMeasurementSearch"
                                                    open-direction="top"
                                                    :name="'vuom'+index"
                                                    v-validate="'required'"
                                                    data-vv-as="Unit of Measurement"
                                                    >
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.name }}</span>
                                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="afterList">
                                                            <div v-observe-visibility="reachedEndOfListMeasurement" />
                                                    </template>
                                                    <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found.</span>
                                                </multiselect>
                                                <span class="invalid-feedback-form text-danger mt-0 text-left d-block" v-if="variant.uom == '' && submitted">Unit of Measurement is Required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="font-weight-bold text-black whiteSpace fs-16 mt-5">Secondary Information :</div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" :maxlength="pVCode" v-validate="'required'" :name="'product_code' + index" type="text" v-model="variant.product_code" placeholder="Enter Product Code" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Product Code<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_code' + index)" style="display: inline !important">Product Code is Required</span>
                                                <span class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ pVCode - variant.product_code.length}}/{{pVCode}}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" :maxlength="pVId" v-validate="'required'"  :name="'product_id' + index"  type="text" v-model="variant.product_id" placeholder="Enter Product ID" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Product ID<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_id' + index)" style="display: inline !important">Product ID is Required</span>
                                                <span class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ pVId - variant.product_id.length}}/{{pVId}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup">
                                                <input :key="index" class="inputContact" :maxlength="pVSKU" v-validate="'required'" :name="'stock_keeping_unit' + index" type="text" v-model="variant.stock_keeping_unit" placeholder="Enter Stock Keeping Unit (SKU)" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Stock Keeping Unit (SKU)<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('stock_keeping_unit' + index)" style="display: inline !important">Stock Keeping Unit (SKU) is Required</span>
                                                <span class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ pVSKU - variant.stock_keeping_unit.length}}/{{pVSKU}}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group contactGroup" style="margin-top: -14px !important;">
												<label class="text-secondary" style="font-size: 14px;margin-bottom: 0px;">Sale Location *</label>
                                                <multiselect class="digi-multiselect multiple" id="ajax20" label="name"
                                                    placeholder="Select Outlet" :options="outlets" v-model="variant.outlet_id" open-direction="bottom"
                                                    :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="true" :close-on-select="false"
                                                    :options-limit="40" :max-height="200" :show-no-results="true" 
                                                    :hide-selected="false" @remove="removeOutlet(option,index)"
                                                    @search-change="SearchOutlets"
                                                    v-validate="'required'"
                                                    :name="'outlet_id'+index"
                                                    track-by="_id"
                                                    >
                                                    <template slot="tag" slot-scope="{ option }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.name }}</span>
                                                            <span class="custom__remove" @click="removeOutlet(option,index)">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                                            </span>
                                                        </span>
                                                    </template>
                                                </multiselect>
                                                <span class="invalid-feedback-form text-danger mt-0 text-left d-block" v-if="variant.outlet_id && variant.outlet_id.length == 0 && submitted">Outlet is Required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <span class="text-black font-weight-bold" v-if="variant.other_info && variant.other_info.length">Other Information :</span>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6" v-for="(info,oindex) in variant.other_info" :key="oindex">
                                                <div class="form-group contactGroup" v-if="info.field_type == 'Input Field'">
                                                    <input class="inputContact" autocomplete="off" v-model="info.value" type="text" :placeholder="info.field_name"/>
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                    <label class="labelContact">{{info.field_name}}</label>
                                                </div>
                                                <div class="form-group contactGroup" style="margin-top: 0px;" v-if="info.field_type == 'Selection'">
												    <label class="text-secondary" style="font-size: 14px;margin-bottom: 0px;">{{info.field_name}}</label>
                                                    <multiselect
                                                    v-model="info.value"
                                                    class="digi-multiselect"
                                                    id="ajaxnew"
                                                    :placeholder="info.field_name"
                                                    :options="info.options"
                                                    :multiple="false"
                                                    :searchable="true"
                                                    :loading="loadingmeasure"
                                                    :internal-search="true"
                                                    :clear-on-select="false"
                                                    :close-on-select="true"
                                                    :max-height="200"
                                                    :show-no-results="true"
                                                    :hide-selected="false"
                                                    open-direction="top"
                                                    >
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <span class="text-black font-weight-bold">Yield Manager :</span>
                                        <div class="pt-3">
                                            <span class="text-secondary">Cost Price</span>
                                        </div>
                                        <div style="border:1px solid #969696;width:50%;padding:4px;margin-top: 5px;border-radius:8px;">
                                            <span class="dollar">$</span>
                                            <input type="text" v-validate="'required'" :name="'variant_price'+index" @input="calculateYield(index)" :maxlength="12" placeholder="Enter Cost Price" v-on:keypress="isNumber($event)" v-model.number="variant.variant_price">
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variant_price' + index)" style="display: inline !important">Variant Price is Required</span>
                                    </div>
                                    <div class="mt-4">
                                        <span class="fw-400">Order Type Details</span>
                                        <div class="table-row new-table">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>ORDER TYPE</th>
                                                        <th>Selling Price</th>
                                                        <th>Gross Margin (%)
                                                            <el-popover placement="right-end" width="400" trigger="hover" popper-class="combo-popover">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <div class="mt-2 ml-2">Gross Margin (%) = </div>
                                                                    </div>
                                                                    <div>
                                                                        <div> Selling Price - Cost Price</div>
                                                                        <div class="text-center borderTop">Selling price</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="mt-2 ml-2"> * 100</div>
                                                                    </div>
                                                                </div>
                                                                <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/white-info.svg"></el-button>
                                                            </el-popover>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(y,yindex) in variant.yield_manager_details" :key="yindex">
                                                        <td class="elSelectRight vABaseLine">
                                                            <el-select v-model="y.order_type" placeholder="Select" :name="'order_type'+yindex" v-validate="'required'">
                                                                <el-option
                                                                v-for="item in allOrderTypes"
                                                                :key="item.order_type"
                                                                :label="item.order_type"
                                                                :value="item.order_type" class="bgTransparent">
                                                                </el-option>
                                                            </el-select><br>
                                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('order_type' + index)" style="display: inline !important">Please Select Order Type</span>
                                                        </td>
                                                        <td class="vABaseLine">
                                                            <div class="input-group payment-currency">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                                </div>
                                                                <input :maxlength="12" style="padding: 10px !important;" :name="'price'+yindex" v-validate="'required'" v-model.number="y.price" @input="calculateGross(variant.variant_price,index,y.price,yindex)" type="text" class="form-control" v-on:keypress="isNumber($event)" placeholder="Enter Cost Price">
                                                            </div>
                                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('price' + index)" style="display: inline !important">Selling Price is Required</span>
                                                        </td>
                                                        <td class="vABaseLine">
                                                            <div class="input-group payment">
                                                                <el-tooltip content="Autofilled" placement="top">
                                                                    <i class="icon icon-check-circle fs-14 text-success mt-1px" style="position: absolute;right: 22px;z-index: 9999;"></i>
                                                                </el-tooltip>
                                                                <input style="padding: 10px !important;" type="number" readonly class="form-control" v-model.number="y.gross_margin">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" id="basic-addon1">%</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <span class="d-flex" style="width:20px !important;background:#fff">
                                                            <span v-if="yindex > 0"
                                                                class="pointer pt-6 d-block ml-2 v-hidden"
                                                                @click="variant.yield_manager_details.splice(yindex,1)">
                                                                <i class="icon icon-minus-circle text-danger"></i>
                                                            </span>
                                                            <span v-if="yindex+1==variant.yield_manager_details.length"
                                                                class="pointer pt-6 d-block ml-2 v-hidden"
                                                                @click="variant.yield_manager_details.push(JSON.parse(JSON.stringify(addYieldManager)))">
                                                                <i class="icon icon-plus-circle text-danger"></i>
                                                            </span>
                                                        </span>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end mt-4">
                                        <button class="btn btn-secondary btn-snm btn-new" v-if="index+1==create.variants.length" @click="create.variants.push(JSON.parse(JSON.stringify(addNewvariant)));otherInfoPush(index+1)">+ Add Variant</button>
                                        <button class="btn btn-danger btn-snm btn-new ml-5" v-if="index != 0" @click="create.variants.splice(index,1)">
                                            <i class="icon icon-delete"></i>
                                            Delete Variant
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 row justify-content-center">
                                <button class="btn btn-danger btn-smm" @click="cancel()">CANCEL</button>
                                <button class="btn btn-success btn-smm ml-2 text-white" style="text-transform: uppercase;" @click="save()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import UploadPicture from './UploadPicture';
import { SweetModal } from 'sweet-modal-vue';
import ProductNew from './mixins/product';
export default {
    mixins:[ProductNew],
    data(){
        return{
            submitted:false,
            getallTypes:['Veg','Non-Veg','Egg','Pescatarian','N/A'],
            other_info:[],
            imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg', // the datebase64 url of created image,
            showGInfo:true,
            value: 'https://example.com',
            size: 100,
            pName: 100,
            pDesc: 1000,
            pVname: 100,
            pVCode:20,
            pVId:20,
            pVSKU:20,
            temp_category_id:'',
            temp_sub_category_id:'',
            temp_product_type_id:'',
            temp_brand:'',
            isLoading:false,
            optionsEmpty:[],
            allCategories:[],
            temp_allCategories:[],
            allSubCategories:[],
            temp_allSubCategories:[],
            allProductType:[],
            temp_allProductType:[],
            allBrands:[],
            temp_allBrands:[],
            skip:0,
            limit:0,
            count:0,
            scrollable:false,
            searchdata:'',
            search:'',
            search_key:'',
            fieldsNotfilledFalse:false,
            create:{
                name:'',
                status:true,
                description:'',
                variant:true,
                tags:[],
                image:'',
                category_id:'',
                product_is:'MENU',
                sub_category_id:'',
                gallery:'',
                brand:'',
                product_type_id:'',
                variants:[
                    {
                        variant_name:'',
                        variant_price:null,
                        variant_quantity:'',
                        type:'Veg',
                        status:true,
                        product_code:'',
                        product_id:'',
                        stock_keeping_unit:'',
                        measure:'',
                        uom:'',
                        sold:null,
                        wastage:null,
                        void:null,
                        starting_stock:null,
                        stock_in:null,
                        free_of_charge:null,
                        transfer_in:null,
                        transfer_out:null,
                        stock_count:null,
                        reorder_level:null,
                        outlet_id:[],
                        yield_manager_details:[
                            {
                                order_type:'',
                                price:null,
                                gross_margin:null
                            }
                        ],
                        other_info:[],
                        other_information: {}
                    },
                ],
            },
            isLoadingSubCategory:false,
            scrollableSubCategory:false,
            isLoadingProductType:false,
            scrollableProductType:false,
            isLoadingBrand:false,
            scrollableBrand:false,
            showVariants:['variant0'],
            addNewvariant:{
                variant_name:'',
                variant_price:null,
                variant_quantity:null,
                type:'Veg',
                status:true,
                product_code:'',
                product_id:'',
                stock_keeping_unit:'',
                measure:'',
                uom:'',
                sold:null,
                wastage:null,
                void:null,
                starting_stock:null,
                stock_in:null,
                free_of_charge:null,
                transfer_in:null,
                transfer_out:null,
                stock_count:null,
                reorder_level:null,
                outlet_id:[],
                yield_manager_details:[
                    {
                        order_type:'',
                        price:null,
                        gross_margin:null
                    }
                ],
                other_info:[],
                other_information: {}
            },
            loadingmeasure:false,
            scrollableMeasure:false,
            measurements:[],
            temp_measurements:[],
            outlets:[],
            allOrderTypes:[],
            addYieldManager:{
                order_type:'',
                price:null,
                gross_margin:null
            }
        }
    },
    components: {
        UploadPicture,
        QrcodeVue,
        SweetModal
	},
    methods:{
        handelBrand(){
            const result = [];
            const map = new Map();
            for (const item of this.allBrands) {
                if(!map.has(item._id)){
                    map.set(item._id, true);    // set any value to Map
                    result.push({
                        _id: item._id,
                        name: item.name,
                    })
                }
            }
            this.allBrands = result
        },
        cancel(){
            this.$router.push({ name: 'InventoryManage' })
            this.clearData()
        },
        clearData(){
            this.create = {
                name:'',
                status:true,
                description:'',
                variant:true,
                tags:[],
                image:'',
                category_id:'',
                product_is:'MENU',
                sub_category_id:'',
                gallery:'',
                brand:'',
                product_type_id:'',
                variants:[
                    {
                        variant_name:'',
                        variant_price:null,
                        variant_quantity:'',
                        type:'Veg',
                        status:true,
                        product_code:'',
                        product_id:'',
                        stock_keeping_unit:'',
                        measure:'',
                        uom:'',
                        sold:null,
                        wastage:null,
                        void:null,
                        starting_stock:null,
                        stock_in:null,
                        free_of_charge:null,
                        transfer_in:null,
                        transfer_out:null,
                        stock_count:null,
                        reorder_level:null,
                        outlet_id:[],
                        yield_manager_details:[
                            {
                                order_type:'',
                                price:null,
                                gross_margin:null
                            }
                        ],
                    },
                ],
            }
        },
        isNumber: function(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        showGeneral(){
            if(!this.showGInfo){
                this.showGInfo = true;
            }
            else{
                this.showGInfo = false;
            }
        },
        uploadImage(imgData) {
			this.create.image = imgData
        },
        async loadCategorySearch(searchdata) {
			this.isLoading = true			
			let response = await this.$http.post('/categories/get_all_categories_for_sub_category', {
				searchdata: searchdata,	
				skip: 0,			
				limit:10
			})
			this.allCategories = response.data.response.category_type_list
			this.count = this.allCategories.length
			this.isLoading = false
			this.scrollable = false
		},
		async reachedEndOfList(reached) {
			this.isVisible = reached
			if (reached) {
				if (this.scrollable == false) {
					this.isLoading = true					
					let response = await this.$http.post('/categories/get_all_categories_for_sub_category', {
						searchdata: this.searchdata,	
						skip: this.count,			
						limit:10
					})
					this.temp_allCategories = response.data.response.category_type_list.length;
					if (response.data.response.skip == this.count) {
						if (this.temp_allCategories > 0) {
							let tempFiles = response.data.response.category_type_list;	
							tempFiles.forEach(el => {
								this.allCategories.push(el);
							});
							this.count += response.data.response.category_type_list.length;	
							this.isLoading = false
						} else {
							this.scrollable = true
							this.isLoading = false
						}
					}
					this.isLoading = false
				}
			}
		},
		async loadSubCategorySearch(searchdata) {
			this.isLoadingSubCategory = true	
			let response = await this.$http.post('/categories/get_sub_category_for_category', {
				searchdata: searchdata,	
				skip: 0,			
				limit:10,
				category_id: this.create.category_id,
                is_product:true
			})
			this.allSubCategories = response.data.response.sub_category_list
			this.count = this.allSubCategories.length
			this.isLoadingSubCategory = false
			this.scrollableSubCategory = false
        },
        async reachedEndOfListSubcategory(reached) {			
			this.isVisible = reached
			if (reached) {
				if (this.scrollableSubCategory == false) {
					this.isLoadingSubCategory = true					
					let response = await this.$http.post('/categories/get_sub_category_for_category', {
						searchdata: this.searchdata,	
						skip: this.count,			
						limit:10,
						category_id: this.create.category_id,
                        is_product:true
					})
                    this.temp_allSubCategories = response.data.response.sub_category_list.length;
					if (response.data.response.skip == this.count) {
						if (this.temp_allSubCategories > 0) {
							let tempFiles = response.data.response.sub_category_list;	
							tempFiles.forEach(el => {
								this.allSubCategories.push(el);
							});
							this.count += response.data.response.sub_category_list.length;	
							this.isLoadingSubCategory = false
						} else {
							this.scrollableSubCategory = true
							this.isLoadingSubCategory = false
						}
					}
					this.isLoadingSubCategory = false
				}
			}
        },
        async loadProductTypeSearch(searchdata){
            this.isLoadingProductType = true	
			let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
				search: searchdata,	
				skip: 0,			
				limit:10,
				sub_category_id: this.create.sub_category_id
			})
			this.allProductType = response.data.product_types
			this.count = this.allProductType.length
			this.isLoadingProductType = false
			this.scrollableProductType = false
        },
        async reachedEndOfListProductType(reached){
            this.isVisible = reached
			if (reached) {
				if (this.scrollableProductType == false) {
					this.isLoadingProductType = true					
					let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
						search: this.searchdata,	
						skip: this.count,			
						limit:10,
						sub_category_id: this.create.sub_category_id
					})
                    this.temp_allProductType = response.data.product_types.length;
					if (response.data.response.skip == this.count) {
						if (this.temp_allProductType > 0) {
							let tempFiles = response.data.product_types;	
							tempFiles.forEach(el => {
								this.allProductType.push(el);
							});
							this.count += response.data.product_types.length;	
							this.isLoadingProductType = false
						} else {
							this.scrollableProductType = true
							this.isLoadingProductType = false
						}
					}
					this.isLoadingProductType = false
				}
			}
        },
        async loadCategories(searchdata) {
			this.isLoading = true
			let response = await this.$http.post('/categories/get_all_categories_for_sub_category', {
				searchdata: searchdata,	
				skip: 0,			
				limit:10
            })			
			this.count = 0
			this.allCategories = response.data.response.category_type_list
			this.temp_allCategories= response.data.response.category_type_list
			this.isLoading = false
		},
		async loadSubCategories(searchdata) {
			this.isLoadingSubCategory = true
			let response = await this.$http.post('/categories/get_sub_category_for_category', {
				searchdata: searchdata,	
				skip: 0,			
				limit:10,
				category_id: this.create.category_id,
                is_product:true
			})
			this.count = 0
			this.allSubCategories = response.data.response.sub_category_list
			this.temp_allSubCategories = response.data.response.sub_category_list
			this.isLoadingSubCategory = false
        },
        async loadProductType(searchdata) {
			this.isLoadingProductType = true
			let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
				search: searchdata,	
				skip: 0,			
				limit:10,
				sub_category_id: this.create.sub_category_id
			})
            this.count = 0
			this.allProductType = response.data.product_types
			this.temp_allProductType = response.data.product_types
			this.isLoadingProductType = false
		},
        checksubcategory(){
            this.create.category_id = this.temp_category_id.category_id
            this.create.sub_category_id = null
            this.create.product_type_id = null
            this.temp_sub_category_id = null
            this.temp_product_type_id = null
			this.allSubCategories = []
            this.temp_allSubCategories = []	
            this.allProductType = []
            this.temp_allProductType = []
			setTimeout(() => {
				this.loadSubCategories()
			}, 100);
        },
        checkProductType(){
            this.create.sub_category_id = this.temp_sub_category_id.sub_category_id
            this.create.product_type_id = null 
            this.temp_product_type_id = null
            this.allProductType = []
            this.temp_allProductType = []
            setTimeout(() => {
				this.loadProductType()
			}, 100);
        },
        updateProductType(){
            if(this.temp_product_type_id){
                this.create.product_type_id = this.temp_product_type_id._id
            }
        },
        async loadBrands(search){
            this.isLoadingBrand = true
			let response = await this.$http.post('/products/get_brands_for_products', {
				search: search,	
				skip: 0,			
				limit:10,
			})
            this.count = 0
			this.allBrands = response.data.response.result
			this.temp_allBrands = response.data.response.result
            this.isLoadingBrand = false
            this.handelBrand()
        },
        async loadBrandSearch(search){
            this.isLoadingBrand = true			
			let response = await this.$http.post('/products/get_brands_for_products', {
				search: search,	
				skip: 0,			
				limit:10
			})
			this.allBrands = response.data.response.result
			this.count = this.allBrands.length
			this.isLoadingBrand = false
            this.scrollableBrand = false
            this.handelBrand()
        },
        async reachedEndOfListBrand(reached){
            this.isVisible = reached
			if (reached) {
				if (this.scrollableBrand == false) {
					this.isLoadingBrand = true					
					let response = await this.$http.post('/products/get_brands_for_products', {
						searchdata: this.searchdata,	
						skip: this.count,			
						limit:10
					})
                    this.temp_allBrands = response.data.response.result.length;
					if (response.data.response.skip == this.count) {
						if (this.temp_allBrands > 0) {
							let tempFiles = response.data.response.result;	
							tempFiles.forEach(el => {
								this.allBrands.push(el);
							});
							this.count += response.data.response.result.length;	
							this.isLoadingBrand = false
						} else {
							this.scrollableBrand = true
							this.isLoadingBrand = false
						}
					}
					this.isLoadingBrand = false
				}
            }
            this.handelBrand()
        },
        updateBrand(){
            if(this.temp_brand){
                this.create.brand = this.temp_brand._id
            }
        },
        showVariant(index){
            if (!this.showVariants.includes('variant' + index)) {
                this.showVariants.push('variant' + index);
            } else {
                this.showVariants = this.showVariants.filter(x => {
                    if (x !== 'variant' + index) {
                        return x;
                    }
                })
            }
        },
        async loadMeasurementSearch(search_key){
            this.loadingmeasure = true	
			let response = await this.$http.post('/scm/get_unit_of_measurements', {
				search_key: search_key,	
				skip: 0,			
				limit:10,
			})
			this.measurements = response.data.unit_of_measurements
			this.count = this.measurements.length
			this.loadingmeasure = false
			this.scrollableMeasure = false
        },
        async reachedEndOfListMeasurement(reached){
            this.isVisible = reached
			if (reached) {
				if (this.scrollableMeasure == false) {
					this.loadingmeasure = true					
					let response = await this.$http.post('/scm/get_unit_of_measurements', {
						search_key: this.search_key,	
						skip: this.count,			
						limit:10
					})
                    this.temp_measurements = response.data.unit_of_measurements.length;
					if (response.data.skip == this.count) {
						if (this.temp_measurements > 0) {
							let tempFiles = response.data.unit_of_measurements;	
							tempFiles.forEach(el => {
								this.measurements.push(el);
							});
							this.count += response.data.unit_of_measurements.length;	
							this.loadingmeasure = false
						} else {
							this.scrollableMeasure = true
							this.loadingmeasure = false
						}
					}
					this.loadingmeasure = false
				}
			}
        },
        async loadMeasurements(search_key){
            this.loadingmeasure = true
			let response = await this.$http.post('/scm/get_unit_of_measurements', {
				search_key: search_key,	
				skip: 0,			
				limit:10,
			})
            this.count = 0
			this.measurements = response.data.unit_of_measurements
			this.temp_measurements = response.data.unit_of_measurements
			this.loadingmeasure = false
        },
        async getOutlets(){
            let res = await this.$http.post('/outlet/get_all_outlets',{search_key:this.search_key})
            this.outlets = res.data.outlets
        },
        async SearchOutlets(search_key){
            let res = await this.$http.post('/outlet/get_all_outlets',{search_key:search_key})
            this.outlets = res.data.outlets
        },
        removeOutlet(option,index){
            this.create.variants[index].outlet_id.forEach((outlet,oindex)=>{
                if (outlet._id == option._id) {
                    this.create.variants[index].outlet_id.splice(oindex, 1)
                }
            })
            
        },
        save(){
            this.submitted = true
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.submitted = false
                    this.checkDuplication()
                }
            })
            
        },
        checkDuplication(){
            if (this.checkDuplicateInObject('variant_name', this.create.variants)) {
                this.$swal({
                    title: 'Duplicate Variant Name Found',
                    text: '',
                    type: 'warning'
                })
                return
            }
            else if(this.checkDuplicateString('product_id',this.create.variants)){					
                this.$swal({
                    title: 'Duplicate Product ID Found',
                    text: '',
                    type: 'warning'
                })
                return
            }
            else if(this.checkDuplicateString('stock_keeping_unit',this.create.variants)){					
                this.$swal({
                    title: 'Duplicate SKU Found',
                    text: '',
                    type: 'warning'
                })
                return
            }
            this.create.variants.forEach((el,index)=>{
                el.other_info.forEach((info,iindex)=>{
                    this.handelOtherInfo(info.field_name,info.value,index)
                })
            })
            this.confirmSave()
        },
        checkDuplicateInObject(propertyName, inputArray) {
			var seenDuplicate = false,
				testObject = {};

			inputArray.map(function (item) {
				var itemPropertyName = item[propertyName].toUpperCase();
				if (itemPropertyName in testObject) {
					testObject[itemPropertyName].duplicate = true;
					item.duplicate = true;
					seenDuplicate = true;
				}
				else {
					testObject[itemPropertyName] = item;
					delete item.duplicate;
				}
			});
			return seenDuplicate;
		},
		checkDuplicateString(propertyName, inputArray){			
			var seenDuplicate = false,
				testObject = {};
			
			inputArray.map(function (item) {
				var itemPropertyName = item[propertyName]
				if (itemPropertyName in testObject) {
					testObject[itemPropertyName].duplicate = true;
					item.duplicate = true;
					seenDuplicate = true;
				}
				else {
					testObject[itemPropertyName] = item;
					delete item.duplicate;
				}
			});
			return seenDuplicate;
        },
        confirmSave(){
           const result = [];
            const map = new Map();
            for (const item of this.create.variants) {
                if(!map.has(item.variant_name)){
                    map.set(item.variant_name, true);    // set any value to Map
                    result.push({
                        id:item.id,
                        variant_name: item.variant_name,
                        variant_price: item.variant_price,
                        variant_quantity:item.variant_quantity,
                        type:item.type,
                        status:item.status,
                        product_code:item.product_code,
                        product_id:item.product_id,
                        stock_keeping_unit:item.stock_keeping_unit,
                        measure:item.measure,
                        uom:item.uom._id || '',
                        sold:item.sold,
                        wastage:item.wastage,
                        void:item.void,
                        starting_stock:item.starting_stock,
                        stock_in:item.stock_in,
                        free_of_charge:item.free_of_charge,
                        transfer_in:item.transfer_in,
                        transfer_out:item.transfer_out,
                        stock_count:item.stock_count,
                        reorder_level:item.reorder_level,
                        outlet_id:[...new Set(item.outlet_id.map(x =>x._id))],
                        yield_manager_details:item.yield_manager_details,
                        other_information:item.other_information
                    });
                }
            }
            this.create.variants = result
            this.createProduct()
        },
        async createProduct(){
            try {
                let params = this.create
                delete params.currency
                let res = await this.editProductAPI(params)
                this.$swal({
                    title: 'Product Updated Successfully',
                    text: '',
                    type: 'success'
                })
                this.cancel()
            } catch (reason) {
                if(reason.response == 'duplicate'){
                    if (reason.value == 'product_id'){
                        this.$swal({
                            title: "Product Id Already Exists",
                            text: '',
                            type: 'warning'
                        })
					}						
					else if (reason.value == 'sku_unit'){
                        this.$swal({
                            title: "Stock Keeping Unit Already Exists",
                            text: '',
                            type: 'warning'
                        })
					}						
					else if(reason.value == 'product_name'){
                        this.$swal({
                            title: "Product Name Already Exists",
                            text: '',
                            type: 'warning'
                        })
					}						
                }else{
                    this.$swal({
                        title: reason.response || "Cannot Update Product",
                        text: '',
                        type: 'warning'
                    })
                }
            }
        },
        async getOrderTypes(){
            try {
                let params = {
                    search_key:this.search_key
                }
                let res = await this.$http.post('order_type/get_all_order_types_for_dropdown',params)
                this.allOrderTypes = res.data.response.order_types
            } catch (reason) {
                
            }
        },
        calculateGross(costprice,index,sellingprice,yindex){
            if(costprice == null || ''){
                costprice = 0
            }
            if(sellingprice == null || ''){
                sellingprice = 0
            }
            let value = sellingprice - costprice/sellingprice*100
            this.create.variants[index].yield_manager_details[yindex].gross_margin = (Math.round(value * 100) / 100)
        },
        calculateYield(index){
            let costprice = this.create.variants[index].variant_price
            if(costprice == '' || null) costprice = 0
            this.create.variants[index].yield_manager_details.forEach((el,eindex)=>{
                if(el.price == null || ''){
                    el.price = 0
                }
                let value = el.price - costprice/el.price*100
                if(value == '-Infinity') value = 0
                this.create.variants[index].yield_manager_details[eindex].gross_margin = (Math.round(value * 100) / 100)
            })
        },
        async getDetails(){
            try {
                let params = {
                    _id:this.$route.query.id,
                    edit:true
                }
                let res = await this.getProductDetailsById(params)
                if(res.status_id == 1){
                    this.create = res.response.result
                    this.temp_category_id = this.create.category_id
                    this.temp_sub_category_id = this.create.sub_category_id
                    this.temp_product_type_id = this.create.product_type_id
                    this.temp_brand = this.create.brand
                    this.create.category_id = this.create.category_id.category_id
                    this.create.sub_category_id = this.create.sub_category_id.sub_category_id
                    if(this.create.product_type_id){
                        this.create.product_type_id = this.create.product_type_id._id
                    }
                    if(this.create.brand){
                        this.create.brand = this.create.brand._id
                    }
                    this.create.variants.forEach((el,index)=>{
                        el['other_info'] = this.other_info
                        el['other_information'] = el.other_information || {}
                        this.addValueFromResponse()
                    })
                    var newArray = {}
                    this.other_info.forEach((el,index)=>{
                        newArray[el.field_name] = ''
                    })
                    this.create.variants.forEach((el,index)=>{
                        el.other_information = newArray
                    })
                }
            } catch (reason) {
                this.$swal({
                    title: 'Something Went Wrong Please try Again!',
                    text: '',
                    type: 'warning'
                })
                this.cancel()
            }
        },
        addValueFromResponse(){
            this.create.variants.forEach((v,vi)=>{
                if(v.hasOwnProperty('other_info')){
                    v.other_info.forEach((o,oi)=>{
                        if(v.other_information.hasOwnProperty(o.field_name)){
                            o.value = v.other_information[o.field_name]
                        }
                    })
                }
            })
        },
        async getAllCustomFieldsApi(){
            try {
                let params = {}
                let res = await this.getAllCustomFields(params)
                this.other_info = res.field_details
                const result = [];
                const map = new Map();
                for (const item of this.other_info) {
                    if(!map.has(item._id)){
                        map.set(item._id, true);    // set any value to Map
                        result.push({
                            _id:item._id,
                            data_type:item.data_type,
                            field_name:item.field_name,
                            field_type:item.field_type,
                            options:item.options,
                            visibility:item.visibility,
                            value:''
                        })
                    }
                }
                var newArray = {}
                this.other_info.forEach((el,index)=>{
                    newArray[el.field_name] = ''
                })
                this.create.variants.forEach((el,i)=>{
                    el['other_info'] = result
                    el['other_information'] = newArray
                })

                this.other_info = result
            } catch (reason) {
                
            }
        },
        otherInfoPush(index){
            var newArray = {}
            this.other_info.forEach((el,index)=>{
                newArray[el.field_name] = ''
            })
            this.create.variants.forEach((el,index)=>{
                el.other_information = newArray
            })
            this.create.variants.forEach((el,i)=>{
                if(i == index){
                    el.other_info = this.other_info
                }
            })
        },
        handelOtherInfo(name,val,index){
            this.create.variants[index].other_information[name] = val
        },
    },
    created() {
		const dict = {  
			custom: {
				product_name: {
					required: () => "Product Name is Required"
                },
                variant_name:{
                    required:() => "Variant Name is Required",
                },
                variant_quantity:{
                    required:() => "Variant Quantity is Required"
                },
                measure:{
                    required:() => "Measure or Size is Required"
                },

            }
        }
        this.$validator.localize("en", dict);
    },
    mounted(){
        this.getDetails()
        this.loadBrands()
        this.loadMeasurements()
        this.getOutlets()
        this.getOrderTypes()
        this.getAllCustomFieldsApi()
    }
}
</script>
<style scoped>
.breadcrumbnew {
    font-size: 0px;
}
.breadcrumbnew > * {
    font-size: 14px;
    color: #fff !important;
    background: #29c2e0;
    display: inline-block;
    padding: 0 8px 0 30px;
    margin: 10px 10px 10px 0;
    height: 36px;
    line-height: 36px;
    position: relative;
    font-weight: 500;
    text-decoration: none;
}
.breadcrumbnew > span {
    background: #00448b;
    color: #fff;
}
.breadcrumbnew > span:after {
    border-color: transparent transparent transparent #29c2e0;
}

/* Left inset arrow */
.breadcrumbnew > :before {
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 0 18px 18px;
    border-color: transparent transparent transparent #fff;
    z-index: 1;
}
/* Right arrow tip */
.breadcrumbnew > :after {
    position: absolute;
    top: 0;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 0 18px 18px;
    border-color: transparent transparent transparent #29c2e0;
    z-index: 2;
}
.breadcrumbnew > span:after {
    position: absolute;
    top: 0;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 0 18px 18px;
    border-color: transparent transparent transparent #00448b;
    z-index: 2;
}
/* The first item has no inset arrow */
.breadcrumbnew :first-child {
    padding-left: 20px;
}
.breadcrumbnew :first-child:before {
    border-width: 18px 0px 19px 10px;
}
.infoHeader {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 40px;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
    line-height: 40px;
}

.infoHeader>.descriptionheader {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid transparent;
}

.infoHeader>.descriptionheader .description-text-header-left h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #00448b;
}

.infoHeader>.descriptionheader .description-text-header-right {
    font-size: 13px;
    margin-left: auto;
    padding-left: 12px;
    color: #00448b;
    font-weight: 500;
}

.infoHeader>.descriptionheader .description-text-header-right {
    display: block;
    border-bottom: 1px solid #b2b2b3;
    width: 100%;
    margin-left: 10px;
}

.infoHeader>.descriptionheader .description-text-header-right i {
    color: #00448b;
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
}

.infoHeader>.descriptionheader .description-text-header-right img {
    float: right;
    color: #00448b;
    width: 28px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: -15px;
}

.infoHeader>.descriptionheader .description-text-header-left h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #00448b;
}

.infoHeader>.descriptionheader .description-text-header-right-small {
    font-size: 13px;
    margin-left: auto;
    padding-left: 12px;
    color: #00448b;
    font-weight: 500;
}

.infoHeader>.descriptionheader .description-text-header-right-small {
    display: block;
    border-bottom: 1px solid #b2b2b3;
    width: 100%;
    margin-left: 10px;
}

.infoHeader>.descriptionheader .description-text-header-right-small i {
    color: #00448b;
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
}

.infoHeader>.descriptionheader .description-text-header-right-small img {
    float: right;
    color: #00448b;
    width: 28px;
    cursor: pointer;
    position: absolute;
    right: 3px;
    bottom: -15px;
}
.whiteSpace{
    white-space: nowrap;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.inputContact{
    background-color: transparent !important;
    border-bottom: solid 1px #a7a7a7 !important;
}
.qr-logo {
    position: absolute;
    margin-top: 28px;
    margin-left: -18px;
    width: 40px;
}
.general-section{
    background: #fff;
    border-radius: 10px;
}
.btn-new{
    border-radius: 8px !important;
}
.dollar{
    background-color:#e9ecef;
    padding: 7px 13px;
    color: #495057;
    right: 1%;
    position: relative;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
.new-table thead{
    background: #00448b !important;
}
.new-table thead th{
    color: #fff !important;
    border: 1px solid #dddddd;
}
.new-table tbody tr td{
    background-color:#d6e2ef;  
}
.bgTransparent{
    background: transparent;
}
.payment .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.payment .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.payment-currency .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.payment-currency .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment-currency.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment-currency.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.borderTop{
    border-top: 1px solid #fff;
}
.vABaseLine{
    vertical-align: baseline !important;
}
</style>