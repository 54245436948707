<template>
    <div class="page-layout simple registerblock right-sidebar inner-sidebar">
        <div class="pb-10">
            <h2 class="headerblock pl-3 pr-3">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex">
                        <router-link
                            :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                              <button class="btn btn-light-grey btn-fab-dc mr-3">
                                <i class="icons icon-arrow-left"></i>
                              </button>
                            </el-tooltip>
                        </router-link>
                        <router-link
                            :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3">
                                  <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <a href="javascript:;" class="btn btn-secondary btn-smm ml-2">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg">
                            Filter
                        </a>
                        <!-- <date-picker
                          class="ml-3 calendarAuditCountWidth"
                          v-model="dateRangeAuditInfo"
                          @confirm="onDateChange()"
                          format="DD-MM-YYYY hh:mm A"
                          :first-day-of-week="7"
                          range
                          :show-second="false"
                          :shortcuts="shortcuts"
                          :lang="lang"
                          :clearable="true"
                          confirm
                          :not-after="new Date()"
                          confirm-text="APPLY"
                          type="datetime"
                        ></date-picker> -->
                        <date-picker v-model="dateRangeAuditInfo" class="ml-3 calendarAuditCountWidth" placeholder="Select Date Range" range lang="en" type="datetime" format="DD/MM/YYYY" confirm confirm-text="APPLY"></date-picker>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-end">
                        <div class="d-flex align-items-center">
                            <span class="page-info px-2 hidden-md-down"> {{getSkipCount}} -{{total>skip+limit?skip+limit:total}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-left" @click="previousPage()"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-right" @click="nextPage()"></i>
                                </button>
                            </el-tooltip>
                        </div>
                        <div class="breadcome-heading d-none d-xl-inline">
                          <div class="form-group formsearch has-warning has-feedback">
                            <input type="text" v-model="search_product" @input="searchProduct();" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                            <!-- <span class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" ></span> -->
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                          </div>
                        </div>
                        <div class="d-flex ml-3 mt-1">
                          <el-dropdown @click="handleClick" trigger="click">
                            <span class="pointer exportButton pl-2 d-flex align-items-center">
                              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg">
                              <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>PDF</el-dropdown-item>
                              <el-dropdown-item>XLSX</el-dropdown-item>
                              <el-dropdown-item>CSV</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/printer.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                          </span>
                        </div>
                    </div>
                </div>
            </h2>
            <div class="account-type-block">
                <div id="mail" style="">
                    <div class="content-section registerblock-section" style="">
                        <div class="center-section" style="background-color: #dee8f2;overflow-y: auto;margin: 10px;border-radius: 15px;overflow-x: hidden;">
                          <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 2px solid #00448b;border-radius: 18px;background-color: #fff;top: -15px;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;">
                              AUDIT - PRODUCT INFO
                          </div>
                            <div class="crmaccounttypecard crmaccounttype-over-card infoproduct">
                                <div class="crmaccounttypecard-body mb-0">
                                  <div class="mat-table-scm mb-0">
                                      <div class="mat-header-row-scm">
                                          <div class="mat-header-cell-scm mat-header-image"></div>
                                          <div class="mat-header-cell-scm mat-header-first">
                                                <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                              PRODUCT NAME
                                          </div>
                                          <div class="mat-header-cell-scm pl-2">CATEGORY</div>
                                          <div class="mat-header-cell-scm pl-2" v-if="!variantCountInfo">SUB CATEGORY</div>
                                          <div class="mat-header-cell-scm pl-2" v-if="!variantCountInfo">PRODUCT TYPE</div>
                                          <div class="mat-header-cell-scm mat-header-cell-scm-class pl-2" :class="{removewidth:variantCountInfo==true}">CLASS</div>
                                          <div class="mat-header-cell-scm pl-2" v-if="stockCountInfo">TOTAL STOCK<br>COUNT</div>
                                          <div class="mat-header-cell-scm pl-2"  v-if="variantCountInfo">TOTAL VARIANT<br>COUNT</div>
                                          <div class="mat-header-cell-scm pl-2" v-if="productInfo">RECIPE/<br>VARIANT</div>
                                      </div>
                                        <div class="mat-row-scm text-center" style="justify-content: center;" v-if="ajaxCallinprogress">
                                          <div class="digi-table-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                        <div class="mat-row-scm text-center text-secondary" v-if="!ajaxCallinprogress && !products.length && search_product.length == ''" style="justify-content: center;">
                                            No Products
                                        </div>
                                        <div class="mat-row-scm text-center text-secondary d-block" v-if="!ajaxCallinprogress && !products.length && search_product.length" style="justify-content: center;">
                                           <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" class="pl-20" style="padding-top: 20px;"><br>
                                           <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Search Results Found</p>
                                        </div>
                                        <div v-for="(product,index) in products" :key="index" v-else>
                                            <label class="w-100" style="padding-left:0 !important">
                                                <div :class="{producthightlight:selectedproduct == product._id && product.variant == false}" class="mat-row-scm" @click="getProductInfo(product._id)">
                                                    <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                        <input type="checkbox" class="form-check-input d-none" :value="product._id" :id="product._id" v-model="checkedVariants" @click="showVariant($event)" style="margin-bottom: 0px;">
                                                        <span class="icon icon-menu-down mt-1 text-secondary" v-if="checkedVariants.includes(product._id) && product.variant == true" style="width: 20px;"></span>
                                                        <span class="icon icon-menu-right mt-1 text-secondary" v-else v-show="product.variant == true" style="width: 20px;"></span>
                                                        <div class="avatar" :class="{addspace:!product.variant}" v-if="product.image != ''">
                                                            <img class="avatar" :src="product.image">
                                                        </div>
                                                        <avatar
                                                            v-else
                                                            :username="product.name.replace(/ .*/,'')"
                                                            :src="product.image"
                                                            :size="40"
                                                            :rounded="true"
                                                            color="#fff"
                                                            :class="{addspace:!product.variant}"
                                                        ></avatar>
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-first">
                                                        <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                        {{product.name}}
                                                    </div>
                                                    <div class="mat-cell-scm uk-text-truncate pl-2">{{product.category || "-"}}</div>
                                                    <div class="mat-cell-scm uk-text-truncate pl-2" v-if="!variantCountInfo">{{product.sub_category || '-'}}</div>
                                                    <div class="mat-cell-scm pl-2" v-if="!variantCountInfo">
                                                        {{product.product_type_id || '-'}}
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-class pl-2" :class="{removewidth:variantCountInfo==true}">
                                                        Product
                                                    </div>
                                                    <div class="mat-cell-scm pl-2"  v-if="stockCountInfo">
                                                        {{ product.stock_count_cost || "-" }}
                                                    </div>
                                                    <div class="mat-cell-scm pl-2"  v-if="variantCountInfo">
                                                        <span>{{ product.stock_count_cost || "-" }}</span>
                                                    </div>
                                                    <div class="mat-cell-scm pl-2" v-if="productInfo">
                                                        <div class="pointer">
                                                            <button class="btn btn-icon actionicons" v-if="product.variants.length>0" style="border-radius: 0px !important;">
                                                                <el-tooltip class="item" effect="dark" content="Show Variants" placement="top">
                                                                    <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg" style="">
                                                                </el-tooltip>
                                                            </button>
                                                            <button class="btn btn-icon actionicons" v-if="product.variants.length==0" style="border-radius: 0px !important;">
                                                                <el-tooltip class="item" effect="dark" content="No Variants" placement="top">
                                                                    <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg" style="">
                                                                </el-tooltip>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                            <!-- Variants -->
                                            <div class="mat-row-scm" :class="{varianthightlight:selectedvariant == index && selectedproduct==product._id}" @click="getProductInfo(product._id, index)" v-show="checkedVariants.includes(product._id)" v-for="(variant,index) in product.variants" :key="index">
                                                <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                    <!-- <div class="avatar ml-5">
                                                        <img class="avatar" src="https://cdn.digicollect.com/cdn/posv2/avatars/1.jpg">
                                                    </div> -->
                                                </div>
                                                <div class="mat-cell-scm mat-cell-scm-first">
                                                    <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                    {{variant.variant_name}}
                                                </div>
                                                <div class="mat-cell-scm uk-text-truncate pl-2">{{product.category || "-"}}</div>
                                                <div class="mat-cell-scm uk-text-truncate pl-2" v-if="!variantCountInfo">{{product.sub_category || '-'}}</div>
                                                <div class="mat-cell-scm pl-2" v-if="!variantCountInfo">
                                                    {{product.product_type_id || '-'}}
                                                </div>
                                                <div class="mat-cell-scm mat-cell-scm-class pl-2" :class="{removewidth:variantCountInfo==true}">
                                                    Product
                                                </div>
                                                <div class="mat-cell-scm pl-2" v-if="stockCountInfo">
                                                    {{ product.stock_count_cost || "0" }}
                                                </div>
                                                <div class="mat-cell-scm pl-2" v-if="variantCountInfo">
                                                    <span>{{ product.stock_count_cost || "0" }}</span>
                                                </div>
                                                <div class="mat-cell-scm pl-2" v-if="productInfo">
                                                    <!-- <div class="pointer">
                                                        <button class="btn btn-icon actionicons" v-if="product.variants.length>0" style="border-radius: 0px !important;">
                                                            <el-tooltip class="item" effect="dark" content="Show Variants" placement="top">
                                                                <img class="download-icon" src ="https://cdn.digicollect.com/cdn/posv2/scm/variant.svg" style="">
                                                            </el-tooltip>
                                                        </button>
                                                        <button class="btn btn-icon actionicons" v-if="product.variants.length==0" style="border-radius: 0px !important;">
                                                            <el-tooltip class="item" effect="dark" content="No Variants" placement="top">
                                                                <img class="download-icon" src ="https://cdn.digicollect.com/cdn/posv2/scm/novariant.svg" style="">
                                                            </el-tooltip>
                                                        </button>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <!-- End Variants -->
                                        </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="fuse-sidebar crmaccounttype-sidebar sidebar left-positioned open locked-open" :class="{increasesidebar:variantCountInfo==true}" style="border-radius: 15px;overflow-y: auto;overflow-x: hidden;margin: 10px 5px;box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);border: solid 1px #00ffeb;background-image: linear-gradient(to bottom, #00448b, #0c95fe);">
                            <div class="preview-elements details-block" v-if="!product">
                                <div class="px-3 py-2">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/noproducts.svg" class="img-fluid" style="width: 280px;margin: 60px auto 0px;display: block;">
                                    <h5 style="font-size: 18px;color: #303031;margin: 30px auto;line-height: 1.21;letter-spacing: 0.41px;text-align: center;font-weight: 600;">
                                        Please Select a Product to View Details
                                    </h5>
                                </div>
                            </div>
                            <div class="preview-elements details-block" v-else>
                                <div class="row">
                                    <div class="col-lg-12 mt-2">
                                      <ul class="nav nav-tabs item-tabs nav-fill">
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{active:productInfo==true}" @click="changeModelView('infoproduct')">INFO</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" :class="{active:stockCountInfo==true}" @click="changeModelView('stockcount')">STOCK<br> COUNT</a>
                                            </li>
                                            <li class="nav-item" >
                                                <a class="nav-link" :class="{active:variantCountInfo==true}" v-if="product.variant" @click="changeModelView('variantcount')">VARIANT<br> COUNT</a>
                                                <a class="nav-link blockred" v-else disabled>VARIANT<br> COUNT</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent">
                                            <div class="product-info-block" v-if="productInfo">
                                                <div class="px-3 py-2">
                                                    <div class="btnheader" v-if="product.variant">
                                                        {{product.name}} - {{product.variant_name}}
                                                    </div>
                                                    <div class="btnheader" v-else>
                                                        {{product.name}}
                                                    </div>
                                                    <div class="digi-list-timeline__items">
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Sale Quantity :</span>
                                                            <span class="digi-list-timeline__time">{{product.quantity || product.variant_quantity || '0'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Unit Of Measurement (UM) :</span>
                                                            <span class="digi-list-timeline__time">{{product.uom.name || '-'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Sales Price :</span>
                                                            <span class="digi-list-timeline__time">{{product.price || product.variant_price || '0'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">SKU :</span>
                                                            <span class="digi-list-timeline__time">{{product.stock_keeping_unit || '-'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">DAID :</span>
                                                            <span class="digi-list-timeline__time" style="word-break: break-all;">{{selectedproduct}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Brand :</span>
                                                            <span class="digi-list-timeline__time">{{product.brand || '-'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Status :</span>
                                                            <span class="digi-list-timeline__time">
                                                                <el-switch v-model="itemstatus" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-stock-block" v-if="stockCountInfo">
                                                <div class="px-3 py-2">
                                                    <div class="btnheader">
                                                        STOCK COUNT
                                                    </div>
                                                    <div class="digi-list-timeline__items_stocks ">
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Total Variant Count</span>
                                                            <span class="digi-list-timeline__time">{{product.variant_quanity || '0'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Total Recipe Count</span>
                                                            <span class="digi-list-timeline__time">{{recipecount || '0'}}</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Soho Pizza</span>
                                                            <span class="digi-list-timeline__time">55,999</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item">
                                                            <span class="digi-list-timeline__text">Cantina</span>
                                                            <span class="digi-list-timeline__time">2,000</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item" style="border-bottom: 0px solid transparent;">
                                                            <span class="digi-list-timeline__text">Vinoteca</span>
                                                            <span class="digi-list-timeline__time">3,000</span>
                                                        </div>
                                                        <div class="digi-list-timeline__item stockswhite">
                                                            <span class="digi-list-timeline__text">Total Stock Count :</span>
                                                            <span class="digi-list-timeline__time">
                                                                {{stockCountTotal || '0'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-variant-block" v-if="variantCountInfo">
                                                <div class="px-3 py-2">
                                                    <div class="btnheader">
                                                        VARIANT COUNT
                                                    </div>
                                                    <div class="table-responsive" style="overflow: hidden !important;">
                                                        <table class="table">
                                                            <thead>
                                                                <tr style="border-bottom: 1px solid transparent !important;">
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 40%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Product Name
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Quantity Sold
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Pour Size
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        UM
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(variant,index) in variants" :key="index">
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                                            {{variant.variant_name}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                            {{variant.sold || 0}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                            {{variant.measure || 0}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                            {{variant.uom.name || '-'}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="btnheader">
                                                        MEASUREMENT
                                                    </div>
                                                    <div class="table-responsive mt-3" style="overflow: hidden !important;">
                                                        <table class="table">
                                                            <thead>
                                                                <tr style="border-bottom: 1px solid transparent !important;">
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 40%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Product Name
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 10%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Size
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 10%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        UM
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Pour Size
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 8px 5px !important;border-bottom: 1px solid #fff !important;border-top: 1px solid #fff !important;">
                                                                        Pour
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(variant,index) in variants" :key="index">
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                                            {{variant.variant_name}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 10%;">
                                                                            {{variant.measure || 0}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 10%;">
                                                                            {{variant.uom.name || '-'}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                            {{variant.measure || 0}} {{variant.uom.name || '-'}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;">
                                                                        <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                            {{variant.measure || 0}}
                                                                            <span style="cursor: pointer" @click="showPours()">
                                                                              <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="digi-list-timeline__items_stocks">
                                                        <div class="digi-list-timeline__item stockswhite" style="border-bottom: 0px solid transparent !important;">
                                                            <span class="digi-list-timeline__text">Quantity Sold</span>
                                                            <span class="digi-list-timeline__time" style="width: 90px !important;">
                                                                {{quantitySoldVariants}}
                                                                  <span style="cursor: pointer" @click="showQuantitySold()">
                                                                    <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #00448b;"></i>
                                                                  </span>
                                                            </span>
                                                        </div>
                                                        <div class="digi-list-timeline__item stockswhite">
                                                            <span class="digi-list-timeline__text">Total Variant Count</span>
                                                            <span class="digi-list-timeline__time" style="width: 90px !important;">
                                                                {{variants.length}}
                                                                  <span style="cursor: pointer" @click="showTotalVariantCount()">
                                                                    <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #00448b;"></i>
                                                                  </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pours modal_name="pours-modal" :product="product"></pours>
        <quantity-sold modal_name="quantitysold-modal" :product="product"></quantity-sold>
        <total-variant-count modal_name="total-variant-count-modal" :product="product"></total-variant-count>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
const Pours = () => import('./Pours.vue');
const QuantitySold = () => import('./QuantitySold.vue');
const TotalVariantCount = () => import('./TotalVariantCount.vue');
import ScmServices from '../mixins/scm.js'
import Avatar from 'vue-avatar'
export default {
    data() {
        return {
          itemstatus: true,
          products:[],
          search_product:"",
          skip: 0,
          limit: 10,
          total:0,
          checkedVariants:[],
          product: false,
          ajaxCallinprogress: false,
          selectedvariant: null,
          selectedproduct: null,
          dateRangeAuditInfo: '',
          productInfo: false,
          stockCountInfo: false,
          variantCountInfo: false,
          variants: [],
          recipecount: 0,
          quantitySoldVariants: 0,
          stockCountTotal: 0
        };
    },
    mixins:[ScmServices],
    components: {
      Pours,
      QuantitySold,
      TotalVariantCount,
      DatePicker,
      Avatar
    },
    methods: {
      handleClick() {
			},
      showPours(){
        this.$modal.show('pours-modal');
      },
      showQuantitySold(){
        this.$modal.show('quantitysold-modal');
      },
      showTotalVariantCount(){
        this.$modal.show('total-variant-count-modal');
      },
      showVariant(e){
        if (e.target.checked) {
        //  console.log(e.target.value)
        }
      },
      searchProduct(){
          this.skip = 0;
          this.loadProducts()
      },
      nextPage() {
        let skip_temp = this.skip + this.limit > this.total? this.total: this.skip + this.limit;
        if (skip_temp >= this.total) {
            return;
        }
        this.skip = skip_temp;
        this.loadProducts();
      },
      previousPage() {
        if (this.skip == 0) {
            return;
        }
        this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
        this.loadProducts();
      },
      async loadProducts(){
          this.products = []
          this.ajaxCallinprogress = true;
          let response = await this.getScmProducts(
              {
                  "search_key":this.search_product,
                  "skip":this.skip,
                  "limit":this.limit
              }
          );
          this.products = response.response
          this.total = response.total
          this.ajaxCallinprogress = false;
      },
      async getProductInfo(productid, index = null){
          let response = await this.getScmProductDetails(
              {
                  "_id":productid,
              }
          );
          this.product = response.response
          this.selectedvariant = index
          this.selectedproduct = productid
          if(this.product&&this.product.variant) {
              this.variants = this.product.variants;
              this.product = this.product.variants[index];
              this.product.variant = true
              this.product.name = response.response.name
              this.quantitySoldVariants = 0;
              this.stockCountTotal = 0;
              this.variants.forEach(element => {
                  this.quantitySoldVariants +=element.sold
                  this.stockCountTotal += element.stock_count
              });
          }
          this.loadRecipeCount()
          this.productInfo = true
          this.stockCountInfo= false
          this.variantCountInfo= false
      },
        changeModelView(viewName){
            switch( viewName){

            case 'infoproduct':
                this.productInfo=true;
                this.stockCountInfo=false;
                this.variantCountInfo=false;
                break;
            case 'stockcount':
                this.productInfo=false;
                this.stockCountInfo=true;
                this.variantCountInfo=false;
                break;
            case 'variantcount':
                this.productInfo=false;
                this.stockCountInfo=false;
                this.variantCountInfo=true;
                break;
            }
        },
        async loadRecipeCount() {
          let response = await this.getRecipeCount(
            this.selectedproduct
          );
          this.recipecount = response.recipe_count
        }
    },
    created() {
        this.loadProducts();
        this.changeModelView('infoproduct');
    },
    computed: {
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        }
    },
};
</script>
<style scoped>
.page-layout.simple.registerblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.registerblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  /* background-color: #00448b; */
  background-color: #00448b;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.crmaccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.fuse-sidebar.crmaccounttype-sidebar.increasesidebar{
  width: 500px;
  min-width: 500px;
  max-width: 500px;
}
.fuse-sidebar.crmaccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.crmaccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  background-color: #00448b;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerblock.left-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section,
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.crmaccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.crmaccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.crmaccounttype-list-item .crmaccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.crmaccounttype-list-item .crmaccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.crmaccounttype-list-item img.crmaccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.crmaccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #00448b;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
.mat-table-scm {
    background-color: transparent;
    box-shadow: none;
    display: block;
    margin: 0px 0px 20px;
}
.mat-header-row-scm, .mat-row-scm{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 0px 0px;
}

.mat-header-cell-scm {
    font-size: 14px;
    font-weight: 600;
    color: #00448b;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
    text-transform: uppercase;
}
.mat-cell-scm, .mat-footer-cell, .mat-header-cell-scm {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
}

@media (max-width: 1280px) {
    .mat-header-cell-scm.mat-header-first, .mat-cell-scm.mat-cell-scm-first {
        flex: 1 1 150px;
        box-sizing: border-box;
        max-width: 150px;
        min-width: 150px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-class, .mat-cell-scm.mat-cell-scm-class {
        flex: 1 1 80px;
        box-sizing: border-box;
        max-width: 80px;
        min-width: 80px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-class.removewidth, .mat-cell-scm.mat-cell-scm-class.removewidth {
        flex: 1 1 100px;
        box-sizing: border-box;
        max-width: 100px;
        min-width: 100px;
    }
}
.mat-header-cell-scm {
    box-shadow: 4px 0 0px -2px #94bef0;
}
.mat-header-cell-scm:last-child, .mat-header-cell-scm:first-child  {
    box-shadow: none;
}
.mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
    flex: 1 1 60px;
    box-sizing: border-box;
    max-width: 60px;
    min-width: 60px;
}
.mat-header-cell-scm.mat-header-last, .mat-cell-scm.mat-cell-scm-last {
    flex: 1 1 85px;
    box-sizing: border-box;
    max-width: 85px;
    min-width: 85px;
}
.mat-header-cell-scm.mat-header-last.removewidth, .mat-cell-scm.mat-cell-scm-last.removewidth {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 100%;
}
.mat-header-row-scm {
    min-height: 44px;
    padding-left: 10px;
}
.mat-table-scm .mat-row-scm {
    position: relative;
    cursor: pointer;
    min-height: 60px;
    background-color: transparent;
    border-bottom: 1px solid #a8adb4;
}
.mat-table-scm .mat-row-scm:hover {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.mat-header-row-scm, .mat-row-scm {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    border-bottom-color: #a8adb4;
    padding-left: 10px;
}
.mat-cell-scm .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    font-weight: 400!important;
    line-height: 1;
}
.mat-cell-scm, .mat-footer-cell {
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.avatar.avatar-border {
    border: 1.5px solid rgb(205, 210, 218);
    background-color: rgba(255, 255, 255, 0);
    width: 47px !important;
    height: 47px !important;
    border-radius: 50%;
    display: inline-block;
    min-width: 47px !important;
}
.avatar.avatar-border img {
    width: 37px !important;
    height: 37px !important;
    border-radius: 50%;
    min-width: 37px !important;
    max-width: 37px !important;
    max-height: 37px !important;
}
.mat-cell-scm .list-group .list-group-item h3 {
    font-size: 14px !important;
    font-weight: 600;
}
.mat-cell-scm .list-group .list-group-item p {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #4e5764 !important;
}
.mat-cell-scm .list-group .list-group-item>.avatar {
    margin: 0 1rem 0 0 !important;
}
.des-list-box {
    border-radius: 5px;
    padding: 7px 10px;
    color: #fff;
    width: 110px;
    text-align: center;
}
.mat-row-scm:hover .eyeIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .editIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .deleteIcon {
    color: #00448b !important;
}
.mat-row-scm:hover .cloudIcon {
    color: #e82828 !important;
}
.list-group.list-cat {
    padding: 8px 30px;
}
.list-group.list-cat .list-group-item.two-line {
    min-height: 5.2rem;
}
.list-group.list-cat .list-group-item {
	background-color: transparent !important;
}
.list-group.list-cat .list-group-item h3 {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 1.22;
  	letter-spacing: 0.77px;
    color: #fff;
    padding-bottom: 5px;
}
.list-group.list-cat .list-group-item p {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 1.23;
  	letter-spacing: 0.56px;
    color: #fff;
}
.category-badge.category-badge--dot.category-badge--xl {
    line-height: 15px;
    min-height: 15px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    font-size: 0;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    border-radius: 50%;
    border: 2px solid #fff;
}
.category-badge.category-badge--veg {
    color: #fff;
    background: #008000;
}
.category-badge.category-badge--nonveg {
    color: #fff;
    background: #d40000;
}
.category-badge.category-badge--liquid {
    color: #fff;
    background: #fec502;
}
.category-badge.category-badge--transparent {
    color: transparent;
    background: transparent;
    border: 2px solid transparent !important;
}
.nav-tabs.item-tabs .nav-link {
    color: #00448b;
    border-radius: 6px;
    border: 1px solid #00448b;
    height: 46px;
    margin: 0 10px;
    background-color: #fff;
    font-weight: 600;
    width: 95px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
}
.nav-tabs.item-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
}
.nav-tabs.item-tabs .nav-link.blockred, .nav-tabs .nav-item.show .nav-link.blockred {
    color: #fff;
    background: #e82828;
}
.nav-tabs.item-tabs .nav-link.active:not(.dropdown-toggle):after, .nav-tabs.item-tabs .nav-item.show .nav-link:not(.dropdown-toggle):after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: transparent !important;
}
.btnheader {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
    padding: 5px 6px;
    min-width: 10rem;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px auto 10px;
    max-width: 25rem;
}
.digi-list-timeline__items {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 135px;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}


.digi-list-timeline__items_stocks {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}

.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px 0px 0px 6px;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 0px 6px 6px 0px;
}
.addspace {
    margin-left: 20px;
}
.varianthightlight{
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #f5a623 !important;
    background-color: #dee7f3 !important;
    margin: 0 2px;
}
.producthightlight {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #00448b !important;
    background-color: #dee7f3 !important;
}

.exportButton{
    width: auto;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #1a9347;
}
.exportButton img{
    width:13px;
}
.pdfButton img{
    width:13px;
}
.printButton img{
    width:13px;
}
.pdfButton{
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #e82828;
    width: 66px;
    font-size: 14px;
}
.printButton{
    width: 66px;
    font-size: 14px;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #00448b;
}
.export-index{
    z-index: 99999 !important;
}
.el-dropdown-menu__item{
    color: #303030 !important;
}
</style>