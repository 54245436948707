<template>
  <div>
    <modal
      class="sub-cat-info-modal final-modal"
      :name="modal_name"
      transition="nice-modal-fade"
      :class="{'dispalyHidden': subcategoryEdit}"
    >
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">Sub Category</span>
        </div>
        <div class="v-modal-body pr-1">
          <div
            class="v-modal-layout pt-2 pr-3"
            style="max-height: 410px !important; overflow-x: hidden !important;overflow-y: scroll !important"
          >
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary pl-4"
                  v-if="subCategories.length > 1"
                >{{ totalCategories}} Sub Categories</div>
                <div
                  v-else
                  class="list-title text-secondary pl-4"
                >{{ totalCategories }} Sub Category</div>
              </div>
              <div class="group mb-3 pl-4 pr-4">
                <input
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  @input="getAllSubCatgeoriesById('searchdata')"
                  v-model="searchdata"
                />
              </div>
              <div v-if="totalCategories" style="text-align: right;">
                <span
                  class="page-info px-2 hidden-md-down"
                >{{start}} - {{end}} of {{totalCategories}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button
                    :disabled="count <=0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="getAllSubCatgeoriesById('prev')"
                    type="button"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-left"></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button
                    :disabled="end >= totalCategories"
                    :class="{'disabled':ajaxCallInProgress}"
                    type="button"
                    v-on:click="getAllSubCatgeoriesById('next')"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-right"></i>
                  </button>
                </el-tooltip>
              </div>
            </div>
            <div class style="border: 1px solid #a8adb4 !important;border-radius: 7.5px;">
              <table class="table productTable table-scroll">
                <thead class="products-variants-table">
                  <tr>
                    <th
                      style="border-radius: 7.5px 0px 0px 0px;border-collapse: separate;flex:0 0 55px !important;"
                    ></th>
                    <th>Name</th>
                    <th style="white-space: nowrap !important;">No. Of Products</th>
                    <th>Status</th>
                    <th style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;">Admin</th>
                  </tr>
                </thead>
                <tbody v-if="ajaxCallInProgress">
                  <tr style="height:20px!important;">
                    <td colspan="6" class="text-center justify-content-center">
                      <div class="digi-table-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="subCategories.length == 0 && !ajaxCallInProgress">
                  <tr v-if="searchdata">
                    <td colspan="6" class="text-center justify-content-center">
                      <strong>No Sub Category Found</strong>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="6" class="text-center justify-content-center">
                      <strong>No Sub Category</strong>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="subcatgeory in subCategories" :key="subcatgeory.sub_category_id">
                    <td style="width:1%;flex:0 0 55px !important">
                      <img
                        v-if="subcatgeory.image_url"
                        class="avatar avatar-trans mx-2"
                        :src="subcatgeory.image_url"
                      />
                      <div v-else>
                        <avatar
                          class="mx-2"
                          :username="subcatgeory.sub_category_name.replace(/ .*/,'')"
                          :src="subcatgeory.image_url"
                          :size="40"
                          :rounded="true"
                          color="#fff"
                        ></avatar>
                      </div>
                    </td>
                    <td
                      :title="subcatgeory.sub_category_name"
                      style="max-width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 1rem 1rem !important;"
                    >{{subcatgeory.sub_category_name}}</td>
                    <td>{{subcatgeory.no_of_products|| '-'}}</td>
                    <td>
                      <el-switch
                        v-model="subcatgeory.status"
                        @change="subCategoryStatus(subcatgeory.status,subcatgeory.sub_category_id)"
                      ></el-switch>
                    </td>
                    <td>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Edit Sub Category"
                        placement="top"
                      >
                        <button
                          @click="showEditSubCategory(subcatgeory.sub_category_id)"
                          class="btn btn-icon companyactions actionicons"
                          style="padding: 0.4rem .2rem;"
                        >
                          <i class="icon icon-pencil"></i>
                        </button>
                      </el-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <button
              type="submit"
              class="btn btn-secondary btn-smm"
              @click="hideProductInformation()"
            >CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
    <edit-sub-category
      modal_edit_sub_category="edit-sub-category"
      :subCategoryId="subCategoryId"
      :categoryId="categoryId"
      :source="'fromCategory'"
      @cancelled="cancelled()"
      @saved="saved()"
    ></edit-sub-category>
  </div>
</template>
<script>
import EditSubCategory from './EditSubCategory'
import { EventBus } from './eventBus/nav-bar-event.js'
import Avatar from 'vue-avatar'
export default {
  props: ['modal_name', 'categoryId'],
  data() {
    return {
      status: true,
      searchdata: '',
      ajaxCallInProgress: false,
      subCategories: [],
      subCategoryId: '',
      subcategoryEdit: false,
      source: '',
      count: 0,
      limit: 10,
      skip: 0,
      start: 1,
      end: 0,
      totalCategories: 0
    }
  },
  methods: {
    hideProductInformation() {
      this.searchdata = ''
      this.count = 0
      this.limit = 10
      this.skip = 0
      this.start = 1
      this.end = 0
      this.$modal.hide(this.modal_name)
    },
    getAllSubCatgeoriesById(param) {
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'searchdata') {
        this.count = 0
      }
      this.ajaxCallInProgress = true
      this.subCategories = []
      this.$http
        .post('/categories/get_sub_category_by_category', {
          searchdata: this.searchdata,
          category_id: this.categoryId,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.subCategories = response.data.response.sub_category_list
          this.totalCategories = response.data.response.total_subcategory_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalCategories
              ? this.count * this.limit + this.limit
              : this.totalCategories
        })
    },
    subCategoryStatus(status, sub_category_id) {
      this.$http
        .post('/categories/edit_sub_category_status', {
          sub_category_id: sub_category_id,
          status: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(
              'Sub Category Status Updated Successfully'
            )
          } else {
            this.$toasted.global.error('Cannot Change Sub Category Status')
          }
        })
    },
    showEditSubCategory(subCategoryId) {
      this.subcategoryEdit = true
      // this.subCategoryId = subCategoryID
      EventBus.$emit('editSubCategoryByCategory', subCategoryId)
      this.showEditSubCategoryModal()
    },
    showEditSubCategoryModal() {
      this.$modal.show('edit-sub-category')
    },
    cancelled() {
      this.subcategoryEdit = false
    },
    saved() {
      this.subcategoryEdit = false
      this.getAllSubCatgeoriesById()
    }
  },
  components: {
    EditSubCategory,
    Avatar
  },
  mounted() {
    EventBus.$on('subCategoryByCategory', categoryId => {
      this.ajaxCallInProgress = true
      this.subCategories = []
      this.$http
        .post('/categories/get_sub_category_by_category', {
          searchdata: this.searchdata,
          category_id: categoryId,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.subCategories = response.data.response.sub_category_list
          this.totalCategories = response.data.response.total_subcategory_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalCategories
              ? this.count * this.limit + this.limit
              : this.totalCategories
        })
    })
  },
  destroyed() {
    EventBus.$off('subCategoryByCategory')
  }
}
</script>
<style>
table.productTable tbody {
  max-height: 250px;
}
table.productTable tbody tr {
  border-bottom: 1px solid #a8adb4;
}
table.productTable tbody tr:last-child {
  border-bottom: none !important;
}
thead.products-variants-table th {
  color: #fff !important;
  font-weight: 600 !important;
  padding: 8px 10px !important;
}
.sub-cat-info-modal .table td {
  padding: 1rem 1rem !important;
  font-weight: 600;
}
.dispalyHidden {
  display: none !important;
}
thead.products-variants-table {
  background-color: #00448b;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
</style>