import { mapGetters } from "vuex";
import { RSA_NO_PADDING } from "constants";

export default {
    data() {
        return {
            show_cross: false,
            hide_cross: true,
            employees: [],
            employeesCount: 0,
            ajaxCallinprogress: false,
            filterJSON: {'for_manage': true,
                         'search_key': '',
                         'skip': 0,
                         'limit': 10,
                         'sort': {'full_name': 1},
                        }
        }
    },
    created() {
        this.getemployees();
    },
    methods: {
        editEmployee(data) {
            this.$store.commit('editEmployee', data);
        },
        getemployees(e) {
            this.show_cross = true;
            this.hide_cross = false;
            this.employees = [];
            this.ajaxCallinprogress = true;
            this.$http.post('/employee/get_employees', this.filterJSON)
            .then(res => {
                this.ajaxCallinprogress = false;
                if(res.data.status_id == 1){
                    this.employees = res.data.employees;
                    this.employeesCount = res.data.employees_count;
                }
            }).catch((error)=>{
                this.ajaxCallinprogress = false;
            });
        },
        deleteEmployee(data) {
            this.$store.commit('deleteEmployee', data);
        },
        sortColumn(columnName) {
            this.filterJSON.skip=0;
            this.sortOrderValue=this.sortOrderValue*-1;
            this.filterJSON.limit=10;
            this.filterJSON.sort = {};
            this.filterJSON.sort[columnName] = this.sortOrderValue;
            this.getemployees();
        },
        employeePagination(left, right) {
            if (left && this.filterJSON.skip !== 0){
                this.filterJSON.skip -= this.filterJSON.limit;
            } else if (right && ((this.filterJSON.skip + this.employees.length) !== this.employeesCount)) {
                this.filterJSON.skip += this.filterJSON.limit;
            }
            this.getemployees();
        }
    },
  }