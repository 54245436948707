import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loggedInUserCompanyId: '',
        loggedInUser: {
            uid: null,
            email: null,
            business_day_start_time: null,
            full_name: null,
            profile_pic: null,
            actions: [],
            is_setup_done: false,
            isAdmin: false,
            is_authorized: false,
            is_loaded: false,
            is_cashier: false,
            cashier_session: {},
            outlet: null,
            outlet_type: null,
            role: null,
            service_type: null,
            connected: navigator.onLine,
            fcm_details: {
                fcm_token: "",
                fcm_server_key: ""
            }

        },
        bills: {
            open: null,
            closed: null,

        },
        session_id: null
    },
    getters: {
        loggedInUser: state => state.loggedInUser,
        bills: state => state.bills,
        session_id: state => state.session_id,
        connected: state => state.connected,
        fcmDetails: state => state.fcm_details,
        loggedInUserCompanyId: state => state.loggedInUserCompanyId,
    },
    mutations: {
        setLoggedInUser(state, user) {
            state.loggedInUser = user;
        },
        setCashRegister(state, cashRegister) {
            state.loggedInUser.cashier_session = {}
            state.loggedInUser.cashier_session['cash_register_details'] = cashRegister
        },
        setSessionId(state, session_id) {
            state.session_id = session_id
        },
        setFCMDetails(state, fcm_details) {
            state.fcm_details = fcm_details
        },
        setBills(state, bills) {
            state.bills.open = bills.open_orders_amount;
            state.bills.closed = bills.close_orders_amount;
        },
        'SET_CONNECTED' (state, payload) {
            state.connected = payload
        },
        setloggedInUserCompanyId(state, comp_id) {
            state.loggedInUserCompanyId = comp_id;
        },

    },
    actions: {
        setConnected({ commit }, payload) {
            commit('SET_CONNECTED', payload)
        },
    }
});
