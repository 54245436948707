export default {
    methods: {
        async addComboApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/combos/add_combo', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getAllComboApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/combos/get_all_combos', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async updateComboStatusApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/combos/change_combo_status', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async confirmDeleteApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/combos/delete_combo', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async editComboApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/combos/edit_combo', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
    }
}