<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-payment-qr-code">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">QR CODE</span>
            </div>
            <div class="general-section">
            <div class="" >
                <template v-if="is_api_call_inprogress">
                    <div class="digi-table-loader inverse">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
                </template>
                <template v-else>
                    <h1 class="fs-14 text-center mt-2">Scan this QR Code to make Payment</h1>
                    <div class="contentCenter width-fit qrcode">
                        <img :src="qrCodeDataURI" alt="QR Code" width="250" height="250" />
                    </div>
                    <div class="contentCenter width-fit py-4">
                        <span v-if="payment_status_in_progress" class="fw-500 text-danger fs-18">
                            Payment In Progress Please Wait.
                        </span>
                    </div>
                </template>
            </div>
            </div>
            <div v-if="countdown_for_close_btn <= 0" class="v-modal-footer modal-bg text-center pt-5 pb-5">
                <a class="btn btn-secondary btn-smm text-uppercase ml-1" @click="hideViewQrCode">CLOSE</a>
            </div>
        </div>
        <payment-msg-modal-scan-and-pay v-if="payment_status_modal" modal_name="paymentMsgModalScanAndPay" from_which_screen="payment_qr_code" :payment_status="payment_status" />
    </modal>
</template>
<script>
import paymentDeviceAPI from './mixins/paymentDeviceAPI.js'
import QRCode from 'qrcode-generator';
import printers from "./mixins/printers"
import PaymentMsgModalScanAndPay from './PaymentMsgModalScanAndPay.vue';
import { EventBus } from './eventBus/nav-bar-event.js';
export default {
    props:['modal_name', 'order_id'],
    data(){
        return{
            qr_code_data: '',
            qrCodeDataURI: '',
            payment_status_modal: false,
            payment_status: '',
            process_response: false,
            is_submit:false,
            show_countdown: false,
            countdown: 10,
            countdown_for_close_btn: 360,
            payment_status_in_progress: false,
            stored_transaction_ref_id: '',
            is_api_call_inprogress: false
        }
    },
    components: {
        PaymentMsgModalScanAndPay
    },
    mixins:[printers, paymentDeviceAPI],
    methods:{
        hideViewQrCode(){
            this.$emit("hideViewQrCode")
        },
        async printQrCode(order_id){
            try {
                this.is_api_call_inprogress = true;
                let response = await this.generatePaymentQrCode(order_id);
                this.qr_code_data = response.qr_code;
                this.stored_transaction_ref_id = response.transaction_ref_id
                this.qrCodeDataURI = this.generateQRCode(this.qr_code_data);
                this.is_api_call_inprogress = false;
            }
            catch(err) {
                this.$toasted.global.error(err);
            }
        },
        generateQRCode(longString) {
            const qr = QRCode(0, 'L');
            qr.addData(longString);
            qr.make();

            const qrCodeDataURI = qr.createDataURL();
            return qrCodeDataURI;
        },
        countDownTimer() {
            this.show_countdown = true
            if (this.countdown > 0) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else if (this.countdown === 0) {
                // this.clearOtpInputs();
                this.show_countdown = false
            }
        },
        countDownTimerForCloseBtn() {
            if (this.countdown_for_close_btn > 0) {
                setTimeout(() => {
                    this.countdown_for_close_btn -= 1;
                    this.countDownTimerForCloseBtn();
                }, 1000);
            } else if (this.countdown_for_close_btn === 0) {
                // this.clearOtpInputs();
            }
        },
        async checkLogic() {
            // this.stored_transaction_ref_id
            try {
                let response = await this.checkOnlinePaymentStatus(this.stored_transaction_ref_id);
                this.payment_status_in_progress = false;
                this.payment_status = 'success'
                this.payment_status_modal = true;
                setTimeout(() => {
                    this.$modal.show('paymentMsgModalScanAndPay')
                },500);
                this.$emit('payment_success_print_receipt_ticket', {status:'success'})
                setTimeout(() => {
                    this.paymentStatusUpdateCheck("paymentMsgModalScanAndPay");
                }, 3000);
            }
            catch(err) {
                if(err == 'Invalid transaction ID.') {
                    this.payment_status_in_progress = true;
                    this.countdown = 10
                    setTimeout(() => {
                        this.countDownTimer()
                    }, 500);
                } else {
                    this.payment_status_in_progress = false;
                    this.payment_status = 'failure'
                    this.payment_status_modal = true;
                    setTimeout(() => {
                        this.$modal.show('paymentMsgModalScanAndPay')
                    },500);
                    setTimeout(() => {
                        this.paymentStatusUpdateCheck("paymentMsgModalScanAndPay");
                    }, 3000);
                }
            }
        },
        paymentStatusUpdateCheck(value) {
            if(this.payment_status == 'success'){
                    this.payment_status_modal = false;
                    setTimeout(() => {
                        this.$modal.hide('paymentMsgModalScanAndPay')
                    },500);
                    if(this.$route.path == '/manageorder'){ 
                        this.$emit("hideViewQrCode")
                    } else {
                        this.$router.push({name: 'ManageOrder'})
                        this.$nextTick(() => {
                            document.getElementById('bigcontainer_scroll').scrollTop = 0
                            window.scrollTo(0,0);
                        })  
                    }
                    EventBus.$emit('PaymentComplete', true)
                } else if(this.payment_status == 'failure'){
                    this.payment_status_modal = false;
                    setTimeout(() => {
                        this.$modal.hide('paymentMsgModalScanAndPay')
                        this.$emit("hideViewQrCode")
                    },500);
                }
        },
    },
    mounted() {
        this.printQrCode(this.order_id);
        setTimeout(() => {
            this.countDownTimer();
            this.countDownTimerForCloseBtn();
        }, 10000);
    },
    watch: {
        countdown: function(val) {
            if (val == 0) {
                this.checkLogic();
            }
        },
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
    },
}
</script>
<style scoped>
    .width-fit{
        width: max-content;
    }
    .qrcode{
        background-color: #f5f6f9;
        padding: 15px;
        width: max-content;
    }
    .contentCenter{
        margin: 0 auto;
    }
    .fs-18{
        font-size: 18px;
    }
</style>