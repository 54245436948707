<template>
  <div class="page-layout blank">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="list-title d-flex" style="font-weight: 500;">
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <a class="btn btn-secondary btn-smm" @click="showSubCategory()">+ Add New Sub Category</a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  :maxlength="searchcustomer"
                  type="text"
                  class="form-control textremove holder"
                  id="inputWarning2"
                  aria-describedby="inputWarning2Status"
                  placeholder="Search..."
                  v-model="searchdata"
                  @input="getAllSubCategories('searchdata')"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="totalSubCategories">
              <span
                class="page-info px-2 hidden-md-down"
              >{{start}} - {{end}} of {{totalSubCategories}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :disabled="count <=0"
                  :class="{'disabled':ajaxCallInProgress}"
                  v-on:click="getAllSubCategories('prev')"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :disabled="end >= totalSubCategories"
                  :class="{'disabled':ajaxCallInProgress}"
                  type="button"
                  v-on:click="getAllSubCategories('next')"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
            <p class="text-center text-secondary fs-28 font-600 pt-5">No Sub Categories Yet!!!</p>
          </div>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info">SUB CATEGORIES</div>
        <div
          class="page-layout carded left-sidebar"
          style="background-color: #00448b!important;padding: 10px!important;"
        >
          <div class="page-content-wrapper">
            <div class="page-content">
              <div class="demo-content">
                <table class="table table-scroll">
                  <thead>
                    <tr>
                      <th style="width:1%;flex: 0 0 50px!important"></th>
                      <th style="flex:1 1 104px">
                        <span v-on:click="setPasskey('name')" class="cursor-th">
                          <span
                            v-if="passed_key.key=='name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          SUB CATEGORY NAME
                        </span>
                      </th>
                      <th style="flex: 1 1 26px;">CATEGORY NAME</th>
                      <th class="hidden-sm-down">DESCRIPTION</th>
                      <th style="flex: 0 0 180px;">NO. OF PRODUCT TYPES</th>
                      <th style="flex:0 0 160px;">NO. OF PRODUCTS</th>
                      <th style="flex: 0 0 123px;">STATUS</th>
                      <th style="flex: 0 0 150px;">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ajaxCallInProgress">
                      <td colspan="7" class="text-center justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="allSubCategories.length==0 && !ajaxCallInProgress"
                      style="background-color: transparent !important;border-bottom: none !important;"
                    >
                      <td v-if="searchdata" colspan="7" class="text-center justify-content-center">
                        <strong style="display:block;padding-top:38px;">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </strong>
                      </td>
                    </tr>
                    <tr v-for="subcategory in allSubCategories" :key="subcategory.sub_category_id">
                      <td style="flex: 0 !important">
                        <el-popover
                          placement="right"
                          class="productAvatar pointer"
                          width="124"
                          trigger="hover"
                        >
                          <div v-if="subcategory.image_url" class="img-icon">
                            <img :src="subcategory.image_url" style="border-radius:18px;" />
                          </div>
                          <div v-else>
                            <avatar
                              :username="subcategory.name.replace(/ .*/,'')"
                              :src="subcategory.image_url"
                              :size="100"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </div>
                          <a
                            slot="reference"
                            class="lightBlue moreMembers"
                            style="text-decoration: none!important;"
                          >
                            <avatar
                              class="default-category-icon"
                              :username="subcategory.name.replace(/ .*/,'')"
                              :src="subcategory.image_url"
                              :size="40"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </a>
                        </el-popover>
                      </td>
                      <td
                        style="width: 160px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding: 0px 0px 0px 14px !important;flex:1 1 100px;"
                        class="font-weight-bold"
                        :title="subcategory.name"
                      >{{subcategory.name}}</td>
                      <td
                        class="font-weight-bold"
                        style="width: 160px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"
                        :title="subcategory.category_name"
                      >{{subcategory.category_name}}</td>
                      <td
                        class="hidden-sm-down"
                        style="width: 209px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding: 0px 0px 0px 19px !important;flex: 1 1 57px;"
                        :title="subcategory.description"
                      >{{subcategory.description || '-'}}</td>
                      <td>
                        <el-tooltip content="View Product Type" placement="top">
                          <div
                            class="chip chipproducts"
                            style="width: 137px!important;"
                            @click="openProductTypeInfo(subcategory.sub_category_id)"
                            :class="{'clickDisabled':subcategory.no_of_product_types == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ subcategory.no_of_product_types }}</div>
                            <span
                              class="fs-12"
                              v-if="subcategory.no_of_product_types  > 1"
                            >Product Types</span>
                            <span v-else class="fs-12">Product Type</span>
                          </div>
                        </el-tooltip>
                      </td>
                      <td>
                        <el-tooltip content="View Products" placement="top">
                          <div
                            class="chip chipproducts"
                            @click="openProductInfo(subcategory.sub_category_id)"
                            :class="{'clickDisabled':subcategory.no_of_products == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ subcategory.no_of_products }}</div>
                            <span class="fs-12" v-if="subcategory.no_of_products  > 1">Products</span>
                            <span class="fs-12" v-else>Product</span>
                          </div>
                        </el-tooltip>
                      </td>
                      <td style="padding: 0px 0px 0px 0px !important;flex: 0 0 117px;">
                        <el-switch
                          v-model="subcategory.status"
                          @change="subCategoryStatus(subcategory.status,subcategory.sub_category_id)"
                        ></el-switch>
                      </td>
                      <td style="padding: 0px 0px 0px 10px !important;">
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="showEditSubCategory(subcategory.sub_category_id)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button
                            @click="confirmDelete(subcategory)"
                            type="button"
                            class="btn btn-icon actionicons"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-sub-category
        modal_sub_category="create-sub-category"
        @saving="getAllSubCategories()"
        v-if="create"
        @cancel="cancel"
      ></create-sub-category>
      <edit-sub-category
        modal_edit_sub_category="edit-sub-category"
        @savingEdited="getAllSubCategories()"
        v-if="edit"
        @canceledit="canceledit"
      ></edit-sub-category>
      <product-information
        modal_product_information="product-information"
        :productById="productById"
        :source="'fromsubCategory'"
        v-if="productInfo"
      ></product-information>
      <product-type-information
        modal_product_type_information="product-type-information"
        :productTypesById="productTypesById"
        v-if="productTypeInfo"
      ></product-type-information>
    </div>
  </div>
</template>
<script>
import CreateSubCategory from './CreateSubCategory'
import EditSubCategory from './EditSubCategory'
import SubCategories from './mixins/subcategories'
import Avatar from 'vue-avatar'
import ProductInformation from './ProductInformation'
import ProductTypeInformation from './ProductTypeByCategoryModal'
import { EventBus } from './eventBus/nav-bar-event.js'
export default {
  data() {
    return {
      searchdata: '',
      allSubCategories: [],
      searchcustomer: 100,
      subCategoryId: '',
      ajaxCallInProgress: false,
      productInfo: false,
      productTypeInfo: false,
      productById: '',
      source: '',
      productTypesById: '',
      create: false,
      edit: false
    }
  },
  mixins: [SubCategories],
  components: {
    CreateSubCategory,
    EditSubCategory,
    Avatar,
    ProductInformation,
    ProductTypeInformation
  },
  methods: {
    showSubCategory() {
      this.create = true
      setTimeout(() => {
        this.$modal.show('create-sub-category')
      }, 100)
    },
    cancel() {
      this.create = false
    },
    canceledit() {
      this.edit = false
    },
    cancelSubCategory() {
      this.$modal.hide('create-sub-category')
    },
    showEditSubCategory(subCategoryId) {
      this.edit = true
      EventBus.$emit('getSubCategoryById', subCategoryId)
      this.showEditSubCategoryModal()
    },
    showEditSubCategoryModal() {
      this.$modal.show('edit-sub-category')
    },
    cancelEditSubCategory() {
      this.$modal.hide('edit-sub-category')
    },
    subCategoryStatus(status, sub_category_id) {
      this.$http
        .post('/categories/edit_sub_category_status', {
          sub_category_id: sub_category_id,
          status: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(
              'Sub Category Status Updated Successfully'
            )
          } else {
            this.$toasted.global.error('Cannot Update Sub Category Status')
          }
        })
    },
    openProductInfo(productById) {
      this.productInfo = true
      this.productById = productById
      EventBus.$emit('subcategoryProductDetails', productById)
      this.$modal.show('product-information')
    },
    openProductTypeInfo(productTypesById) {
      this.productTypeInfo = true
      this.productTypesById = productTypesById
      EventBus.$emit('getProductTypesById', productTypesById)
      this.$modal.show('product-type-information')
    }
  },
  created() {
    this.getAllSubCategories('init')
  }
}
</script>
<style scoped>
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.avatar {
  background: #00448b;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
.table-inventery-manage tbody:nth-child(odd) {
  background-color: #f5f6f9 !important;
}
.table.table-inventery-manage td {
  padding: 0.2rem 1rem !important;
}
.page-layout.carded {
  overflow: hidden !important;
}
@media (max-width: 1200px) {
  .table.table-inventery-manage th {
    padding: 1.4rem 5px !important;
  }
  .table.table-inventery-manage td {
    padding: 0px 5px 0px 25px !important;
  }
}
.btn-icon:disabled {
  background: transparent !important;
}
.img-icon img {
  width: 100px !important;
}
.clickDisabled {
  pointer-events: none !important;
}
</style>