<template>
    <div class="font-poppin">
        <div class="filter-header">
            <div class="fs-16 font-weight-bold ml-4">Filters (<span style="color:#ffba00 !important;">{{totalFilters}}</span>)</div>
            <div class="pointer">
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ArrowBgBlue.svg" alt="arrow">
                </span>
                <span @click="resetFilter()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/CloseBgBlue.svg" alt="close">
                </span>
            </div>
        </div>
        <div class="container filter-content">
            <div v-if="loggedInUser.role == 'default_order_receiver'" class="group-setup mt-6">
                <span class="fs-14 text-secondary font-weight-bold">Category</span>
                <multiselect :limit="2" :limit-text="limitCategory" v-model="temp_categories" @open="getCo('Categories')" label="name" track-by="category_id" :blockKeys="['Delete']" @remove="removeCategory" placeholder="Select Categories" class="digi-multiselect category-multiple-addon" :options="category_list" :loading="is_loading_category" @search-change="searchCategoryList" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="40" :max-height="200" :show-no-results="true" :hide-selected="false" @close="update()">
                    <template slot="tag" slot-scope="{ option }">
                        <span class="custom__tag">
                        <span>{{ option.name }}</span>
                            <span class="custom__remove" @click="removeCategory(option)">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                            </span>
                        </span>
                    </template>
                    <template slot="afterList">
                        <div v-observe-visibility="reachEndOfCategoryList" />
                    </template>
                    <template slot="noOptions">
                        <div class="text-secondary text-center fw-600">Categories Not Found</div>
                    </template>
                    <template slot="noResult">
                        <div class="text-secondary text-center fw-600">No Results Found</div>
                    </template>
                </multiselect>
            </div>
            <div class="group-setup mt-6">
                <span class="font-weight-bold fs-15">Order Type</span>
                <el-checkbox-group v-model="order_type">
                    <el-checkbox class="elCheckBox mt-3" @change="handelChange(orderType.order_type)" v-for="orderType in allOrderTypes" :label="orderType.order_type" :key="orderType._id">{{orderType.order_type}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="group-setup mt-6" v-if="order_type.includes('Deliveries')">
                <span class="fs-14 text-secondary font-weight-bold">Deliveries</span>
                <multiselect :limit="2" :limit-text="limitDelivery" v-model="temp_delivery_companies" :loading="load_order_type_list" @open="getCo('Deliveries')" label="name" track-by="_id" :blockKeys="['Delete']" @remove="removeDelivery" placeholder="Select Deliveries" class="digi-multiselect category-multiple-addon" id="ajaxdelivery" :options="delivery_company_list" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="40" :max-height="200" :show-no-results="true" :hide-selected="false" @close="update()">
                    <template slot="tag" slot-scope="{ option }">
                        <span class="custom__tag">
                        <span>{{ option.name }}</span>
                            <span class="custom__remove" @click="removeDelivery(option)">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                            </span>
                        </span>
                    </template>
                </multiselect>
            </div>
            <div class="group-setup mt-6" v-if="order_type.includes('Online')">
                <span class="fs-14 text-secondary font-weight-bold">Online</span>
                <multiselect :limit="2" :limit-text="limitOnline" v-model="temp_online_companies" :loading="load_order_type_list" @open="getCo('Online')" label="name" track-by="_id" :blockKeys="['Delete']" @remove="removeOnline" placeholder="Select Online" class="digi-multiselect category-multiple-addon" id="ajaxonline" :options="delivery_company_list" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="false" :options-limit="40" :max-height="200" :show-no-results="true" :hide-selected="false" @close="update()">
                    <template slot="tag" slot-scope="{ option }">
                        <span class="custom__tag">
                        <span>{{ option.name }}</span>
                            <span class="custom__remove" @click="removeOnline(option)">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                            </span>
                        </span>
                    </template>
                </multiselect>
            </div>
            <div class="group-setup mt-6" v-if="loggedInUser.role != 'default_order_receiver'">
                <span class="fs-14 text-secondary font-weight-bold">Order Status</span>
                <multiselect v-model="payment_status" placeholder="Select Order Status" class="digi-multiselect approval-manager filter-multiple mt-1" id="ajaxss" :options="pay_json" :multiple="false" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="true" :options-limit="40" :max-height="200" :show-no-results="true" :hide-selected="false" @close="update()"></multiselect>
            </div>
        </div>
    </div>
</template>
<script>
import orders from './mixins/orders.js'
import { EventBus } from './eventBus/nav-bar-event'
import global from './global.js'
export default {
    mixins:[orders],
    props:['showfilterData'],
    data(){
        return{
            allOrderTypes:[],
            order_type:[],
            temp_delivery_companies:[],
            temp_online_companies:[],
            delivery_companies:[],
            online_companies:[],
            payment_status:'',
            delivery_company_list:[],
            pay_json:['PAID','UNPAID'],
            paykey:0,
            temp_categories: [],
            categories: [],
            category_list: [],
            categories_search_key : '',
            is_loading_category: false,
            categories_count: 0,
            scrollable: false,
            load_order_type_list: false
        }
    },
    methods:{
        limitDelivery(count) {
			return `and ${count} Delivery`
        },
        limitCategory(count) {
			return `and ${count} Category`
        },
        limitOnline(count) {
			return `and ${count} Online`
        },
        closeFilters(){
            this.hide_filters = false
            this.$emit('hideFilters')
        },
        resetFilter(){
            if(this.totalFilters > 0){
                this.order_type = []
                this.temp_categories = []
                this.temp_online_companies = []
                this.temp_delivery_companies = []
                this.delivery_companies = []
                this.online_companies = []
                this.payment_status = ''
                this.update()
            }
        },
        async getOrderTypes(){
            try {
                let params = {
                    search_key:'',
                    required_id:true
                }
                let res = await this.$http.post('/order_type/get_all_order_types_for_dropdown',params)
                this.allOrderTypes = res.data.response.order_types
            } catch (reason) {
                
            }
        },
        getCo(key){
            this.allOrderTypes.forEach((el,index)=>{
                if(el.order_type == key){
                    this.getCoAPI(el._id)
                } 
            })
        },
        async getCoAPI(id){
            this.load_order_type_list = true
            try {
                let params = {
                    order_type_id:id
                }
                this.delivery_company_list = []
                let res = await this.getDeliveryCo(params)
                this.delivery_company_list = res.response.delivery_companies
                this.load_order_type_list = false
            } catch (reason) {
                this.load_order_type_list = false
            }
        },
        removeDelivery(option){
            this.temp_delivery_companies.forEach((el,index)=>{
                if(el._id == option._id){
                    this.temp_delivery_companies.splice(index,1)
                }
            })
        },
        removeCategory(option){
            this.temp_categories.forEach((el,index)=>{
                if(el.category_id == option.category_id){
                    this.temp_categories.splice(index,1)
                }
            })
        },
        removeOnline(option){
            this.temp_online_companies.forEach((el,index)=>{
                if(el._id == option._id){
                    this.temp_online_companies.splice(index,1)
                }
            })
        },
        handelChange(key){
            if(key == 'Online'){
                this.temp_online_companies = []
            }else if(key == 'Deliveries'){
                this.temp_delivery_companies = []
            }
            this.update()
        },
        async searchCategoryList(search_key) {
            try {
                this.categories_search_key = search_key || ''
                this.category_list = []
                this.categories_count = 0
                this.is_loading_category = true;
                let response = await this.$http.post(global.POS_API + '/scm/category_filter',{
                    skip: 0,
                    limit: 10,
                    search_key: this.categories_search_key,
                });
                this.category_list = response.data.response
                this.categories_count = this.category_list.length
                this.is_loading_category = false;
			    this.scrollable = false
            }
            catch (e) {
                
            }
        },
        async reachEndOfCategoryList(reached) {
            if (reached) {
				if (this.scrollable == false) {
					this.is_loading_category = true
					let response = await this.$http.post(global.POS_API + '/scm/category_filter',{
                        skip: this.categories_count,
                        limit: 10,
                        search_key: this.categories_search_key,
                    });
					let temp_cat = response.data.response.length;
					if (response.data.skip == this.categories_count) {
						if (temp_cat > 0) {
							let tempFiles = response.data.response;
							tempFiles.forEach(el => {
								this.category_list.push(el);
							});
							this.categories_count += response.data.response.length;
							this.is_loading_category = false
						} else {
							this.scrollable = true
							this.is_loading_category = false
						}
					}
					this.is_loading_category = false
				}
			}
        },
        update(){
            this.delivery_companies = [...new Set(this.temp_delivery_companies.map(x =>x.name))]
            this.online_companies = [...new Set(this.temp_online_companies.map(x =>x.name))]
            this.categories = [...new Set(this.temp_categories.map(x =>x.category_id))]
            let params = {
                delivery_companies:this.delivery_companies,
                online_companies:this.online_companies,
                order_type:this.order_type,
                categories:this.categories,
                payment_status:this.payment_status
            }
            let params1 = {
                delivery_companies:this.temp_delivery_companies,
                online_companies:this.temp_online_companies,
                order_type:this.order_type,
                categories:this.temp_categories,
                payment_status:this.payment_status
            }
            let newData = {
                value:params,
                value1:params1
            }
            this.$emit('UpdateByFilter',newData)
        },
    },
    computed:{
        totalFilters(){
            if(this.payment_status)this.paykey = 1
            else this.paykey = 0
            return this.order_type.length + this.delivery_companies.length + this.online_companies.length + this.temp_categories.length + this.paykey
        },
        loggedInUser() {
            return this.$store.state.loggedInUser
        },
    },
    mounted(){
        this.temp_delivery_companies = this.showfilterData.delivery_companies
        this.temp_online_companies = this.showfilterData.online_companies
        this.order_type = this.showfilterData.order_type
        this.payment_status = this.showfilterData.payment_status
        this.temp_categories = this.showfilterData.categories
        this.getOrderTypes()
        EventBus.$on('removechipFromManage',params=>{
            if(params.key == 'order'){
                this.order_type.forEach((el,index)=>{
                    if(el == params.value){
                        this.order_type.splice(index,1)
                    }
                })
                this.update()
            }else if(params.key == 'delivery'){
                this.removeDelivery(params.value)
                this.update()
            }else if(params.key == 'online'){
                this.removeOnline(params.value)
                this.update()
            }else if(params.key == 'pay'){
                this.payment_status = ''
                this.update()
            }else if(params.key == 'category'){
                this.removeCategory(params.value)
                this.update()
            }
        })
        EventBus.$on('removeAllChips',params=>{
            this.order_type = []
            this.temp_delivery_companies = []
            this.temp_online_companies = []
            this.temp_categories = []
            this.payment_status = ''
            this.update();
        })
    }
}
</script>
<style scoped>
.category-multiple-addon .custom__tag {
    background: #00448b !important;
    color: #fff;
    border-radius: 18px !important;
}
.category-multiple-addon .multiselect__strong {
    line-height: 26px!important;
}
</style>