<template>
  <div class="page-layout blank">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div
              class="list-title"
              style="font-weight: 500;white-space: nowrap !important;"
              v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
            >
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <!-- <a class="btn btn-secondary btn-smm" @click="createNewproduct()">+ Add New Product</a>
              <a class="btn btn-secondary btn-smm" @click="viewFiltersNew()">
                 <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg"> Filter</a> -->
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div class="breadcome-heading">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  :maxlength="searchcustomer"
                  type="text"
                  class="form-control textremove holder"
                  placeholder="Search..."
                  v-model="searchkey"
                  @input="get_all_products('search')"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="total_products">
              <span
                class="page-info px-2"
              > {{start}} - {{end}} of {{total_products}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :disabled="count <= 0"
                  :class="{'disabled':ajaxCallinprogress}"
                  v-on:click="get_all_products('prev')"
                  type="button"
                  style="padding:.1rem !important;"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :class="{'disabled':ajaxCallinprogress}"
                  :disabled="end >= total_products"
                  v-on:click="get_all_products('next')"
                  type="button"
                  style="padding:.1rem !important;"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
        <!--Filter Code  Start-->
      <div class="card mb-2 p-2 d-block filter-new-component" v-if="filterNewComp">
        <el-popover
            class="pr-3"
            placement="bottom"
            width="370"
            height="160"
            trigger="click"
            popper-class="filter-popover"
            v-model="visibleCategory"
        >
            <div style="display: inline;">
                <span style="padding-left: 15px;color: #959ba0;font-weight:600;">Select Categories</span>
                <!-- <i
                    class="icon-close-circle"
                    @click="visibleCategory = false"
                ></i> -->
                <div class="list-title" style="font-weight: 500;float:right;margin-top: -7px;margin-right: 14px;">
                    <button  type="button" class="btn btn-secondary btn-smm" @click="resetfilterCategory()" style="text-transform:none!important;background: none!important;color: #00448b!important; border-radius: 18px;border: 1px solid #00448b;margin-right: 10px;font-size: 14px!important;">
                        Reset
                    </button>
                    <button class="btn btn-secondary btn-smm" style="text-transform:none!important;font-size: 14px!important;" @click="filterCategory();visibleCategory = false">
                        Apply
                    </button>
                </div>
            </div>
            <multiselect
                v-model="temp_category_ids"
                class="digi-multiselect multiple"
                id="ajax"
                label="name"                
                :options="categories"
                :multiple="true"
                :searchable="true"                
                :internal-search="true"
                :loading="isLoading"
                :clear-on-select="false"
                :close-on-select="false"
                :max-height="200"
                :show-no-results="true"
                :hide-selected="false"                
                :limit="3"
                track-by="category_id"
                @search-change="loadCategorySearch"
                :limit-text="limitText"
                :blockKeys="['Delete']"
                placeholder="Type to Search"
                @select="updateCategory"
                @remove="removeCategory"
            >
                <template slot="tag" slot-scope="{ option }">
                    <span class="custom__tag">
                      <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="removeCategory(option)">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                        </span>
                    </span>
                </template>  
                <template slot="afterList">
                    <div v-observe-visibility="reachedEndOfList" />
                </template>              
                <span
                slot="noResult"
                class="font-weight-bold text-center d-block"
                >No Results Found</span>
            </multiselect>
            <el-button slot="reference" @click="getCategory()">Category</el-button>
        </el-popover>
        <el-popover placement="bottom" class="pr-3" width="370" popper-class="filter-popover" trigger="click" v-model="visibleSubCategory">
            <div>
              <span style="padding-left: 15px;color: #959ba0;font-weight:600;">Select Sub Categories</span>
                 <!-- <span>
                    <i class="icon-close-circle" style="float:right;color: #00448b!important;" @click="visibleSubCategory = false"></i>
                </span> -->
                <div class="list-title" style="font-weight: 500;float:right;margin-top: -9px;margin-right: 10px;">
                    <button type="button" class="btn btn-secondary btn-smm" @click="resetfilterSubCategory" style="text-transform:none!important;background: none!important;color: #00448b!important; border-radius: 18px;border: 1px solid #00448b;margin-right: 10px;font-size: 14px!important;">
                        Reset
                    </button>
                    <button  class="btn btn-secondary btn-smm" style="text-transform:none!important;font-size: 14px!important;" @click="filterSubCategory();visibleSubCategory = false">
                        Apply
                    </button>
                </div>
            </div>
            <multiselect
                v-model="temp_subcategory_ids"
                class="digi-multiselect multiple"
                id="ajax1"
                label="sub_category_name"                
                :options="subCategories"
                :multiple="true"
                :searchable="true"                
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="false"
                :max-height="200"
                :show-no-results="true"
                :hide-selected="false"                
                :limit="3"
                track-by="sub_category_id"
                @search-change="loadSubCategorySearch"
                :limit-text="limitTextSubcategory"
                :blockKeys="['Delete']"
                placeholder="Type to Search"
                @select="updateSubCategory"
                @remove="removeSubCategory"
            >
                <template slot="tag" slot-scope="{ option }">
                    <span class="custom__tag">
                    <span>{{ option.sub_category_name }}</span>
                    <span class="custom__remove" @click="removeSubCategory(option)">
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                    </span>
                </span>
                </template>     
                <template slot="afterList">
                    <div v-observe-visibility="reachedEndOfListSubCategory" />
                </template>            
                <span
                slot="noResult"
                class="font-weight-bold text-center d-block"
                >No Results Found</span>
            </multiselect>
           
            <el-button slot="reference" @click="getSubCategory()">Sub-Category</el-button>
        </el-popover>
        <el-popover placement="bottom" class="pr-3" width="370" popper-class="filter-popover" trigger="click" v-model="visibleProductType">
            <div  style="display: inline;">
              <span style="padding-left: 15px;color: #959ba0;font-weight:600;">Select Product Type</span>
                 <!-- <span>
                    <i class="icon-close-circle" style="float:right;color: #00448b!important;" @click="visibleProductType = false"></i>
                </span> -->
                <div class="list-title" style="font-weight: 500;float:right;margin-top: -9px;margin-right: 14px;">
                    <button  type="button" class="btn btn-secondary btn-smm" @click="resetfilterProductType()" style="text-transform:none!important;background: none!important;color: #00448b!important; border-radius: 18px;border: 1px solid #00448b;margin-right: 10px;font-size: 14px!important;">
                        Reset
                    </button>
                    <button class="btn btn-secondary btn-smm" style="text-transform:none!important;font-size: 14px!important;" @click="filterProductType();visibleProductType = false">
                        Apply
                    </button>
                </div>
            </div>
            <multiselect
                v-model="temp_producttype_ids"
                class="digi-multiselect multiple"
                id="ajax2"
                label="product_type_name"                
                :options="productTypes"
                :multiple="true"
                :searchable="true"                
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="false"
                :max-height="200"
                :show-no-results="true"
                :hide-selected="false"                
                :limit="3"
                track-by="product_type_id"
                :limit-text="limitTextProductType"
                :blockKeys="['Delete']"
                placeholder="Type to Search"
                @search-change="loadProductTypeSearch"
                @select="updateProductType"
                @remove="removeProductType"
            >
                <template slot="tag" slot-scope="{ option }">
                    <span class="custom__tag">
                    <span>{{ option.product_type_name }}</span>
                    <span class="custom__remove" @click="removeProductType(option)">
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                    </span>
                </span>
                </template>   
                <template slot="afterList">
                    <div v-observe-visibility="reachedEndOfListProductType" />
                </template>             
                <span
                slot="noResult"
                class="font-weight-bold text-center d-block"
                >No Results Found</span>
            </multiselect>
           
            <el-button slot="reference" @click="getProductTypes()">Product Type</el-button>
        </el-popover>
        <button type="button" class="btn btn-icon fuse-ripple-ready" style="float: right;" @click="closeToggleNew">
            <i class="icon-close-circle"></i>
        </button>
      </div>
      <div class="chips-div-new mb-2" v-if="showList.length ">
        <div class="chip" v-for="(chips,cindex) in showList" :key="cindex" v-if="show_more && cindex < 8 || !show_more">
           <span class="text-truncate">{{chips.name}}</span>
            <el-tooltip class="item" effect="dark" content="Clear" placement="top-start">
               <i class="close material-icons" @click="removeChip(cindex)">close</i>
            </el-tooltip>
         
        </div>
        <a v-if="show_more && showList.length > 8" @click="showMore()" class="btn btn-secondary btn-smm" style="border:0px!important;text-transform:none !important;margin: 0px !important;">
            + View More
        </a>
        <a v-else-if="!show_more && showList.length > 8" @click="showLess()" class="btn btn-secondary btn-smm" style="border:0px!important;text-transform:none !important;margin: 0px !important;">
            - View Less 
        </a>
        <a  @click="clearAll()" class="btn btn-secondary btn-smm" style="border:0px!important;text-transform:none !important;margin: 0px !important;
        ">
            Clear All
        </a>
      </div>
      <!--Filter Code  End-->
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 22%;margin-top:25px;" />
            <p class="text-center text-secondary fs-26 font-600 pt-5">No Products Found!!!</p>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!showIllustrator"> 
          <!-- <div class="category-manage-info" style="position: relative!important;top: 19px!important;">PRODUCTS</div> -->
          <div
            class="page-layout carded left-sidebar"
            style="background-color: #00448b!important;padding: 10px!important;overflow: auto !important;"
          >
            <div class="page-content-wrapper">
              <div class="page-content">
                <div class="demo-content" style="overflow: auto;">
                  <table class="table table-inventery-manage">
                    <thead>
                      <tr>
                        <th style="width:1%;" colspan="2"></th>
                        <th class="text-capitalize" style="width:12%;">
                          <span v-on:click="setPasskey('name')" class="cursor-th">
                            <span
                              v-if="passed_key.key=='name' && passed_key.value== -1"
                              :class="{'hide-arrow': passed_key.key != 'name'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                              style="font-size: 16px !important;"
                              v-else
                              :class="{'hide-arrow': passed_key.key != 'name'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                            Product Name
                          </span>
                        </th>
                        <th class="text-capitalize" style="width:9%;white-space: nowrap;">
                          <span v-on:click="setPasskey('status')" class="cursor-th">
                            <span
                              v-if="passed_key.key=='status' && passed_key.value== -1"
                              :class="{'hide-arrow': passed_key.key != 'status'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                              style="font-size: 16px !important;"
                              v-else
                              :class="{'hide-arrow': passed_key.key != 'status'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                            Status
                          </span>
                        </th>
                        <th class="text-capitalize" style="width:6%;">
                          <!-- <span v-on:click="setPasskey('quantity')" class="cursor-th">
                            <span
                              v-if="passed_key.key=='quantity' && passed_key.value== -1"
                              :class="{'hide-arrow': passed_key.key != 'quantity'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                              style="font-size: 16px !important;"
                              v-else
                              :class="{'hide-arrow': passed_key.key != 'quantity'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                            Quantity
                          </span> -->
                            Quantity
                        </th>
                        <th class="text-capitalize" style="width:9%;">
                          <!-- <span v-on:click="setPasskey('price')" class="cursor-th">
                            <span
                              v-if="passed_key.key=='price' && passed_key.value== -1"
                              :class="{'hide-arrow': passed_key.key != 'price'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                              style="font-size: 16px !important;"
                              v-else
                              :class="{'hide-arrow': passed_key.key != 'price'}"
                              class="sorting-icon"
                            >
                              <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                          </span> -->
                          Base Price
                        </th>
                        <!-- <th class="cursor-th" style="width:9%;padding-left: 27px!important;">SKU</th>
                        <th style="width:9%;" class="hidden-md-down">
                            CATEGORY
                        </th>
                        <th style="width:9%;" class="hidden-md-down">
                            SUB CATEGORY
                        </th>
                        <th style="width:9%;white-space: nowrap;" class="hidden-md-down">
                          PRODUCT TYPE
                        </th>
                        <th style="width:9%;text-align: center;">Admin</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="products.length==0 && !ajaxCallinprogress">
                      <tr
                        style="background-color: transparent !important;border-bottom: none !important;"
                      >
                        <td v-if="searchkey" colspan="11" class="text-center">
                          <strong style="display:block;padding-top:38px;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                            <br />
                            <p
                              class="text-secondary font-20"
                              style="font-size: 22px; font-weight: 600;"
                            >No Search Results Found</p>
                          </strong>
                        </td>
                        <td v-else colspan="11" class="text-center">
                          <strong>No Products</strong>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="ajaxCallinprogress">
                      <tr>
                        <td colspan="11" class="text-center">
                          <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-for="(item,index) in products" :key="index">
                      <tr class="tr-no-color">
                        <td style="padding-left: 8px !important; padding-right: 0 !important;">
                          <!-- <el-popover
                            placement="right"
                            class="productAvatar pointer"
                            width="124"
                            trigger="hover"
                          > -->
                            <div v-if="item.image">
                              <img :src="item.image" width="40" height="40" style="border-radius:50px;width: 40px !important; height: 40px !important;" />
                            </div>
                            <div v-else>
                              <!-- <p class="image-p-td">Image Not Available</p> -->
                              <avatar
                                :username="item.name.replace(/ .*/,'')"
                                :src="item.image"
                                :size="100"
                                :rounded="true"
                                style="width: 40px !important; height: 40px !important;"
                                color="#fff"
                              ></avatar>
                            </div>
                            <!-- <a
                              slot="reference"
                              class="lightBlue moreMembers"
                              style="text-decoration: none !important;"
                            >
                              <avatar
                                :username="item.name.replace(/ .*/,'')"
                                :src="item.image"
                                :size="40"
                                :rounded="true"
                                color="#fff"
                              ></avatar>
                            </a> -->
                          <!-- </el-popover> -->
                        </td>
                        <td style="padding-left: 5px !important;" >
                          <span @click.stop="item.show = !item.show">
                            <i
                              v-if="item.variant && item.show"
                              class="icon-arrow-down-drop icon-down"
                            ></i>
                            <i
                              v-if="item.variant && !item.show"
                              class="icon-menu-right text-secondary icon-right"
                            ></i>
                          </span>
                        </td>
                        <td
                            class="text-truncate"
                            >
                            <span class="d-flex">
                            <!-- <el-tooltip class="item" effect="dark" :content="item.type" placement="top" v-if="item.type != 'N/A' && !item.variant">
                                <div class="showType mt-1" :class="{'veg':item.type == 'Veg','nonveg':item.type== 'Non-Veg','egg':item.type == 'Egg','pescatarian':item.type == 'Pescatarian'}">
                                    <div></div>
                                </div>   
                            </el-tooltip>
                            <div class="showType mt-1" v-else :class="{'veg':item.type == 'Veg','nonveg':item.type== 'Non-Veg','egg':item.type == 'Egg','pescatarian':item.type == 'Pescatarian'}">
                                <div></div>
                            </div>                          -->
                            <span :title="item.name"
                            style="max-width: 150px;padding-right: 0px!important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 3px!important;">
                                <!-- <a
                                class="product-name"
                                @click="showproductvariants(item._id)"
                                >{{item.name}}</a> -->
                                <span
                                class="product-name"
                                >{{item.name}}</span>
                            </span>
                            </span>
                        </td>
                        <td
                          v-if="loggedInUser.actions.inventory && loggedInUser.actions.inventory.write"
                        >
                          <el-switch
                            v-model="item.status"
                            v-on:change="changestatus(item.status, item._id)"
                          ></el-switch>
                        </td>
                        <td v-else>
                          <el-switch v-model="item.status"></el-switch>
                        </td>
                        <td class="text-truncate" v-if="item.variant">-</td>
                        <td class="text-truncate" v-else>{{item.quantity}}</td>
                        <td class="text-truncate" v-if="item.price">{{item.price}}</td>
                        <td class="text-truncate" v-else>-</td>
                        <!-- <td class="text-truncate">{{item.stock_keeping_unit || '-'}}</td> -->
                        <!-- <td
                          class="text-truncate hidden-md-down"
                          :title="item._id"
                          style="padding-right: 0px !important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 18px !important;"
                        >{{item._id}}</td>-->
                        <!-- <td
                          class="text-truncate hidden-md-down"
                          style="max-width:140px;padding: 0px 0px 0px 14px !important;"
                          :title="item.category"
                        >{{item.category || '-'}}</td>
                        <td
                          class="text-truncate hidden-md-down"
                          style="max-width:140px;padding: 0px 0px 0px 14px !important;"
                          :title="item.sub_category"
                        >{{item.sub_category || '-'}}</td>
                        <td
                          class="hidden-md-down text-truncate"
                          :title="item.product_type_name"
                          style="max-width:140px;padding: 0px 0px 0px 14px !important;"
                        >{{item.product_type_name || '-'}}</td> -->
                        <!-- <td
                          class="hidden-md-down text-truncate"
                          :title="item.brand_name"
                          style="max-width:140px"
                        >{{item.brand_name || '-'}}</td>-->
                        <!-- <td
                          v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                          style="padding: 0px 10px!important;text-align: center;white-space:nowrap"
                        >
                          <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                            <button
                              class="btn btn-icon companyactions actionicons"
                              @click="editproductmodal(item._id)"
                              style="padding: 0.4rem .2rem;"
                            >
                              <i class="icon icon-pencil"></i>
                            </button>
                          </el-tooltip>
                          <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                            <button
                              type="button"
                              class="btn btn-icon actionicons"
                              @click="deleteproduct(item)"
                              style="padding: 0.4rem .2rem;"
                            >
                              <i class="icon icon-delete"></i>
                            </button>
                          </el-tooltip>
                        </td> -->
                      </tr>
                      <tr
                        class="varitants-table-tr product-tbody"
                        v-if="item.show"
                        v-for="(i,index) in item.variants"
                        :key="index"
                      >
                        <td style="padding: 0px 8px!important;"></td>
                        <td style="padding: 0px 0px!important;"></td>
                        <td
                          class="text-truncate d-flex"                          
                        >
                          <div class="showType mt-5" v-if="i.type == 'N/A'" :class="{'veg':i.type == 'Veg','nonveg':i.type== 'Non-Veg','egg':i.type == 'Egg','pescatarian':i.type == 'Pescatarian'}">
                              <div></div>
                          </div>
                          <el-tooltip class="item" effect="dark" :content="i.type" placement="top" v-else>
                              <div class="showType mt-5" :class="{'veg':i.type == 'Veg','nonveg':i.type== 'Non-Veg','egg':i.type == 'Egg','pescatarian':i.type == 'Pescatarian'}">
                                  <div></div>
                              </div>   
                          </el-tooltip>
                          <span :title="i.variant_name"
                          style="width: 120px;padding-right: 0px!important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 18px!important;">
                          <a class="font-600" style="color:#303031;">{{i.variant_name}}</a>
                          </span>
                        </td>
                        <td>
                          <el-switch
                            v-model="i.status"
                            v-on:change="change_variant_status(i.status, item._id, i.id)"
                          ></el-switch>
                        </td>
                        <td class="text-truncate">{{i.variant_quantity}}</td>
                        <td class="text-truncate">{{i.variant_price}}</td>
                        <!-- <td class="hidden-md-down">{{i.stock_keeping_unit || '-'}}</td>
                        <td class="hidden-md-down">-</td>
                        <td>-</td>
                        <td class="hidden-md-down">-</td>
                        <td
                          v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                        ></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <create-product modal_name="product-create" :createByProduct="true"></create-product> -->
        <!-- <edit-product modal_name="product-edit"></edit-product> -->
      </div>
    </div>
    <products-variants modal_name="product_info" :productId="productDetailsId"></products-variants>
    <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="success_modal_create" icon="success">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="warning_modal_create" icon="warning">{{ modal_msg }}</sweet-modal>
  </div>
</template>
<style scoped>
.icon-arrow-down-drop.icon-down,
.icon-menu-right.icon-right {
  color: #00448b !important;
  cursor: pointer;
}

.v-modal-layout {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .sorting-icon{
    cursor: pointer;
    visibility: hidden;
} */
.table-inventery-manage tbody {
  border-bottom: 1px solid #a8adb4;
}
.image-p-td {
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.varitants-table-tr.product-tbody {
  background-color: #7abbfd1f !important;
}
.closeTax {
  position: absolute;
  right: 12px;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
.companyTimelineTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.companyRelationTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  border-left: none !important;
  cursor: pointer;
  /* border-radius: 0 20px 20px 0 !important; */
}
.companyGalleryTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.activeClass {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.avatar {
  background: #00448b;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.product-name {
  color: #00448b !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.table.table-inventery-manage {
  border-radius: 7.5px;
  background-color: #dee8f2;
}
.table.table-inventery-manage td {
  padding: 0px 0px 0px 28px !important;
  font-weight: 600;
}
.taxes-heading {
  font-size: 14px;
  font-weight: bold;
  color: #00448b;
  padding-left: 14px;
}
.v-modal-layout {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.contactGroup select.form-control {
  max-width: 100% !important;
  max-width: 100% !important;
}
.el-upload--picture-card {
  color: #00448b !important;
  font-weight: 600 !important;
}
.el-input__inner {
  color: #303031 !important;
}
.el-upload .icon-plus {
  background-color: #00448b;
  color: #fff;
  border-radius: 100px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.varitants-table-tr {
  background-color: #f5f6f9 !important;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.table-striped tbody:nth-of-type(odd) {
  background-color: #f5f6f9 !important;
}
.varitants-table-tr.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.table-inventery-manage thead th span {
  cursor: pointer;
}
.table-striped tbody:nth-of-type(odd) {
  background-color: #f5f6f9 !important;
}
.hide-arrow {
  visibility: hidden;
}
.sorting-icon {
  cursor: pointer;
}
.cursor-th {
  cursor: pointer;
}
.btn-icon:disabled {
  background: transparent !important;
}
@media (max-width: 1200px) {
  .table.table-inventery-manage th {
    padding: 1.4rem 5px !important;
  }
  .table.table-inventery-manage td {
    padding: 0px 5px 0px 25px !important;
  }
}
.productType {
  width: 70px;
}
.borderColor .el-checkbox__inner {
  border-color: #94bef0 !important;
}
.chip {
    display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,.6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 7.5px;
  background-color: #d0e8ff;
    margin-bottom: 5px;
    margin-right: 5px;
    width: inherit!important;
}
.chip .close {
    cursor: pointer;
    float: right;
    font-size: 16px;
    line-height: 26px;
    padding-left: 8px;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    color: rgba(0,0,0,.8);
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.filter-new-component.card {
    background-color: #dee8f2 !important;
    border-radius: 8px !important;
    -webkit-transition: height 2s; /* For Safari 3.1 to 6.0 */
    transition: height 2s;
    border: none !important;
    box-shadow: none !important;
}
.el-popover.el-popper.filter-popover {
    height: 50% !important;
    overflow-y: auto !important;
    overflow-x: hidden;
    box-shadow: -0.5px 0 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.apply-filter {
    position: absolute;
    margin: 0 auto;
    width: 300px;
    bottom: 5px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-top: 1px solid #fff;
}
.filter-new-component .el-button:focus,
.el-button:hover {
    color: #fff;
    border-color: #c6e2ff;
    font-weight: 600;
    background-color: #00448b !important;
}
</style>

<script>
import VueVisible from 'vue-visible'
import myUpload from 'vue-image-crop-upload'
import Avatar from 'vue-avatar'
import { SweetModal } from 'sweet-modal-vue'
import VueTagsInput from '@johmun/vue-tags-input'

import ForBidden from './ForBidden'
import CreateProduct from './CreateProduct'
import ProductsVariants from './ProductsVariants'
import EditProduct from './EditProduct'

import products from './mixins/products.js'
import productServices from './mixins/products.js'
import { EventBus } from './eventBus/nav-bar-event.js'

export default {
    data() {
        return {
            showVariantsTr: false,
            searchcustomer: 100,
            editTax: [],
            editVariants: [],
            dialogImageUrl: '',
            dialogVisible: false,
            isCompanyTimeline: true,
            isCompanyRelationship: false,
            isCompanygallery: false,
            show: false,
            imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg', // the datebase64 url of created image
            product_details: {},
            disabled: true,
            image: '',
            picture_message: 'Upload Image',
            modal_msg: '',
            variants_list: [],
            gallerylist: [],
            name: '',
            productDetailsId: '',
            createByProduct:false,
            displayFilters:false,
            storeData:[],

            filterNewComp:false,
            categories:[],
            subCategories:[],
            productTypes:[],
            temp_categories:[],
            temp_subCategories:[],
            temp_productTypes:[],
            category_ids:[],
            temp_category_ids:[],
            subcategory_ids:[],
            temp_subcategory_ids:[],
            producttype_ids:[],
            temp_producttype_ids:[],
            search_key:'',
            visibleCategory: false,
            visibleSubCategory: false,
            visibleProductType: false,
            chipList:[],
            showList:[],
            show_more: true,
            show_less: false,
            isLoading: false,
            isLoadingsubCategory: false,
            isLoadingproductType: false,
            isVisible: false,
            scrollableCategory: false,
            scrollableSubCategory: false,
            scrollableProductType: false,
        }
    },
    components: {
        'my-upload': myUpload,
        CreateProduct,
        Avatar,
        SweetModal,
        ProductsVariants,
        EditProduct,
        ForBidden,
        VueVisible
    },
    mixins: [productServices],
    methods: {
        createNewproduct(){
            this.$router.push({ name: 'CreateProductNew' })
        },
        // Filter Methods Start
        limitText (count) {
            return `and ${count} Categories`
        },
        limitTextSubcategory(count){
            return `and ${count} Sub-Categories`
        },
        limitTextProductType(count){
            return `and ${count} Product-Types`
        },
        handelCategory(){
            const result = [];
            const map = new Map();
            for (const item of this.categories) {
                if(!map.has(item.category_id)){
                    map.set(item.category_id, true);  
                    result.push({
                        category_id: item.category_id,
                        name:item.name
                    });
                }
            }
            this.categories = result
        },
        handelSubcategory(){
            const result = [];
            const map = new Map();
            for (const item of this.subCategories) {
                if(!map.has(item.sub_category_id)){
                    map.set(item.sub_category_id, true);  
                    result.push({
                        sub_category_id: item.sub_category_id,
                        sub_category_name:item.sub_category_name
                    });
                }
            }
            this.subCategories = result
        },
        handelProductType(){
            const result = [];
            const map = new Map();
            for (const item of this.productTypes) {
                if(!map.has(item.product_type_id)){
                    map.set(item.product_type_id, true);  
                    result.push({
                        product_type_id: item.product_type_id,
                        product_type_name:item.product_type_name
                    });
                }
            }
            this.productTypes = result
        },
        async loadCategorySearch(search_key) {
            this.isLoading = true			
            let response = await this.$http.post('/scm/category_filter', {
                search_key: search_key,	
                skip: 0,			
                limit:10
            })
            this.categories = response.data.response
            this.count = this.temp_categories.length
            this.isLoading = false
            this.scrollableCategory = false
            this.handelCategory()
        },
        async reachedEndOfList(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableCategory == false) {
                    this.isLoading = true					
                    let response = await this.$http.post('/scm/category_filter', {
                        search_key: this.search_key,	
                        skip: this.count,			
                        limit:10
                    })
                    this.temp_categories = response.data.response.length;
                    if (response.data.skip == this.count) {
                        if (this.temp_categories > 0) {
                            let tempFiles = response.data.response;	
                            tempFiles.forEach(el => {
                                this.categories.push(el);
                            });
                            this.count += response.data.response.length;	
                            this.isLoading = false
                        } else {
                            this.scrollableCategory = true
                            this.isLoading = false
                        }
                    }
                    this.isLoading = false
                }
            }
            this.handelCategory()
        },
        async getCategory(){
            let response = await this.$http.post("/scm/category_filter",{
                search_key:this.search_key,
                limit:10,
                skip:0
            })
            this.count = 0
            if(response.data.status_id == 1){
                this.temp_categories = []
                this.categories = []
                this.isLoading = false
            } 
            this.handelCategory()       
        }, 
        async loadSubCategorySearch(search_key) {
            this.isLoadingsubCategory = true			
            let response = await this.$http.post('/scm/sub_category_filter', {
                search_key: search_key,	
                skip: 0,			
                limit:10,
                category_id:this.category_ids
            })
            this.subCategories = response.data.response
            this.count = this.temp_subCategories.length
            this.isLoadingsubCategory = false
            this.scrollableSubCategory = false
            this.handelSubcategory()
        },
        async reachedEndOfListSubCategory(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableSubCategory == false) {
                    this.isLoadingsubCategory = true					
                    let response = await this.$http.post('/scm/sub_category_filter', {
                        search_key: this.search_key,	
                        skip: this.count,			
                        limit:10,
                        category_id:this.category_ids
                    })
                    this.temp_subCategories = response.data.response.length;
                    if (response.data.skip == this.count) {
                        if (this.temp_subCategories > 0) {
                            let tempFiles = response.data.response;	
                            tempFiles.forEach(el => {
                                this.subCategories.push(el);
                            });
                            this.count += response.data.response.length;	
                            this.isLoadingsubCategory = false
                        } else {
                            this.scrollableSubCategory = true
                            this.isLoadingsubCategory = false
                        }
                    }
                    this.isLoadingsubCategory = false
                }
            }
            this.handelSubcategory()
        },
        async getSubCategory(){
            let response = await this.$http.post("/scm/sub_category_filter",{
                search_key:this.search_key,
                category_id:this.category_ids,
                limit:10,
                skip:0
            })
            this.count = 0
            if(response.data.status_id == 1){
                this.temp_subCategories = []
                this.subCategories = []
                this.isLoadingsubCategory = false
            }
            this.handelSubcategory()
        },
        async loadProductTypeSearch(search_key) {
            this.isLoadingproductType = true			
            let response = await this.$http.post('/scm/product_filter', {
                search_key: search_key,	
                skip: 0,			
                limit:10,
                category_id:this.category_ids,
                sub_category:this.subcategory_ids,
            })
            this.productTypes = response.data.response
            this.count = this.temp_productTypes.length
            this.isLoadingproductType = false
            this.scrollableProductType = false
            this.handelProductType()
        },
        async reachedEndOfListProductType(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableProductType == false) {
                    this.isLoadingproductType = true					
                    let response = await this.$http.post('/scm/product_filter', {
                        search_key: this.search_key,	
                        skip: this.count,			
                        limit:10,
                        category_id:this.category_ids,
                        sub_category:this.subcategory_ids,
                    })
                    this.temp_productTypes = response.data.response.length;
                    if (response.data.skip == this.count) {
                        if (this.temp_productTypes > 0) {
                            let tempFiles = response.data.response;	
                            tempFiles.forEach(el => {
                                this.productTypes.push(el);
                            });
                            this.count += response.data.response.length;	
                            this.isLoadingproductType = false
                        } else {
                            this.scrollableProductType = true
                            this.isLoadingproductType = false
                        }
                    }
                    this.isLoadingproductType = false
                }
            }
            this.handelProductType()
        },
        async getProductTypes(){
            let response = await this.$http.post("/scm/product_filter",{
                search_key:this.search_key,
                category_id:this.category_ids,
                sub_category:this.subcategory_ids,
                limit:10,
                skip: 0
            })
                this.count = 0
            if(response.data.status_id == 1){
                this.temp_productTypes = []
                this.productTypes = []
                this.isLoadingproductType = false
            }
            this.handelProductType()
        },
        filterCategory(){            
            this.category_ids = [...new Set(this.temp_category_ids.map(x =>x.category_id))] 
            this.filterInventory() 
        },
        resetfilterCategory(){        
            this.category_ids = []
            this.temp_category_ids = []
            this.chipList = $.grep(this.chipList, function(data, index) {
                return data.type != 'Category'
            });   
            this.filterInventory()
        },
        filterSubCategory(){
            this.subcategory_ids = [...new Set(this.temp_subcategory_ids.map(x =>x.sub_category_id))]
            this.filterInventory()
        },
        resetfilterSubCategory(){
            this.subcategory_ids = []
            this.temp_subcategory_ids = []
            this.chipList = $.grep(this.chipList, function(data, index) {
                return data.type != 'Sub_category'
            }); 
            this.filterInventory()
        },
        filterProductType(){
            this.producttype_ids = [...new Set(this.temp_producttype_ids.map(x =>x.product_type_id))]
            this.filterInventory()
        },
        resetfilterProductType(){
            this.producttype_ids = []
            this.temp_producttype_ids = []  
            this.chipList = $.grep(this.chipList, function(data, index) {
                return data.type != 'Product_type'
            });       
            this.filterInventory()
        },
        removeCategory(option){            
            this.temp_category_ids.forEach((category,index) => {
                if(this.temp_category_ids[index].category_id == option.category_id){                                        
                    this.temp_category_ids.splice(index,1) 
                    this.showOption(option.category_id)                 
                }
            })
        },
        showOption(id){
            this.chipList = $.grep(this.chipList, function(data, index) {
                return data.id != id
            });
        },
        removeSubCategory(option){            
            this.temp_subcategory_ids.forEach((subcategory,index) => {
                if(this.temp_subcategory_ids[index].sub_category_id == option.sub_category_id){                                        
                    this.temp_subcategory_ids.splice(index,1) 
                    this.showOption(option.sub_category_id)                 
                }
            })
        },
        removeProductType(option){            
            this.temp_producttype_ids.forEach((producttype,index) => {
                if(this.temp_producttype_ids[index].product_type_id == option.product_type_id){                                        
                    this.temp_producttype_ids.splice(index,1) 
                    this.showOption(option.product_type_id)   
                }
            })
        },
        filterInventory(){
            const result = [];
            const map = new Map();
            for (const item of this.chipList) {
                if(!map.has(item.id)){
                    map.set(item.id, true);    // set any value to Map
                    result.push({
                        id: item.id,
                        name: item.name,
                        type:item.type
                    });
                }
            }
            this.showList = result
            this.count = 0
            this.get_all_products()
        },
        updateCategory(option){  
            let insert_obj = {
                'name':option['name'],
                'id':option['category_id'],
                'type':'Category'
            }
            this.chipList.push(insert_obj)
        },
        updateSubCategory(option){            
            let insert_obj = {
                'name':option['sub_category_name'],
                'id':option['sub_category_id'],
                'type':'Sub_category'
            }
            this.chipList.push(insert_obj)
        },
        updateProductType(option){
            let insert_obj = {
                'name':option['product_type_name'],
                'id':option['product_type_id'],
                'type':'Product_type'
            }
            this.chipList.push(insert_obj)
        },
        checkchiplistCategory(removed_object){
            this.temp_category_ids.forEach((category,index) => {
                if(this.temp_category_ids[index].category_id == removed_object.id){ 
                    this.temp_category_ids.splice(index,1)                  
                }
            })      
        },
        checkchiplistSubCategory(removed_object){
            this.temp_subcategory_ids.forEach((subcategory,index) => {
                if(this.temp_subcategory_ids[index].sub_category_id == removed_object.id){                                        
                    this.temp_subcategory_ids.splice(index,1)                  
                }
            })
        },
        checkchiplistProductType(removed_object){
            this.temp_producttype_ids.forEach((producttype,index) => {
                if(this.temp_producttype_ids[index].product_type_id == removed_object.id){                                        
                    this.temp_producttype_ids.splice(index,1)                  
                }
            })
        },
        removeChip(index){        
            var removed_object = this.chipList[index]
            this.chipList.splice(index,1)
            if (removed_object.type == "Category"){
                this.category_ids.splice(this.category_ids.indexOf(removed_object['id']),1)
                this.checkchiplistCategory(removed_object)
                this.filterInventory()
            }
            else if (removed_object.type == "Sub_category"){
                this.subcategory_ids.splice(this.subcategory_ids.indexOf(removed_object['id']),1)
                this.checkchiplistSubCategory(removed_object)
                this.filterInventory()
            }
            else{
                this.producttype_ids.splice(this.producttype_ids.indexOf(removed_object['id']),1)
                this.checkchiplistProductType(removed_object)
                this.filterInventory()
            }
        },
        showMore(){
            this.show_less = true
            this.show_more = false
        },
        showLess(){
            this.show_less = false
            this.show_more = true
        },
        clearAll(){
            this.chipList = []
            this.category_ids = []
            this.temp_category_ids = []
            this.subcategory_ids = []
            this.temp_subcategory_ids = []
            this.producttype_ids = []
            this.temp_producttype_ids = []
            this.filterInventory()
        },
        viewFiltersNew(){
            this.filterNewComp = !this.filterNewComp;
        },
        closeToggleNew(){            
            this.filterNewComp = false
        },
        // Filter Methods End
        createcategory() {
            this.$modal.show('category-create')
        },
        showbrandproduct() {
            this.$modal.show('brand-create')
        },
        showVariants(variants) {
            this.showVariantsTr = !this.showVariantsTr
        },
        editRemoveVariants: function(index) {
            this.editVariants.splice(index, 1)
        },
        handleRemove(file) {
            file.product_id = this.product_details._id
            this.$http.post('/products/remove_products_from_gallery', file).then(res => {
                if (res.data.status_id == 1) {
                    this.modal_msg = 'Removed Image From Gallery'
                }
            })
        },
        handlePictureCardPreview(file) {
            // this.productObj.gallery.push(file)
            if (file.url) {
                this.image = file.url
                this.dialogVisible = true
            } else {
                this.image = file.response.url
                this.dialogVisible = true
            }
        },
        toggleShow() {
            this.show = !this.show
        },
        /**
         * crop success
         *
         * [param] imgDataUrl
         * [param] field
         */
        cropSuccess(imgDataUrl, field) {
            this.imgDataUrl = imgDataUrl
            this.picture_message = 'Change Image'
        },
        /**
         * upload success
         *
         * [param] jsonData  server api return data, already json encode
         * [param] field
         */
        cropUploadSuccess(jsonData, field) {
            this.edit_product_details.image = jsonData
            this.picture_message = 'Change Image'
        },
        /**
         * upload fail
         *
         * [param] status    server api return error status, like 500
         * [param] field
         */
        cropUploadFail(status, field) {
        // TODO:
        },
        createproduct() {
            this.skuexists = false
            this.productObj = {
                name: '',
                description: '',
                quantity: 0,
                price: 0,
                currency: '',
                product_id: '',
                stock_keeping_unit: '',
                brand: '',
                category: '',
                status: true,
                tags: [],
                tax: [],
                variants: [],
                type: {
                vegetarian: false,
                vegan: false,
                spicy: false,
                signature_dish: false,
                halal: false
                },
                total_tax: 0,
                variant: '',
                image: '',
                gallery: []
            }
            this.variant_name = ''
            this.variant_quantity = ''
            this.variant_price = ''
            this.tax_name = ''
            this.tax_percentage = ''
            this.$modal.show('product-create')
        },
        hidecreateproduct() {
            this.skuexists = false
            ;(this.productObj = {
                name: '',
                description: '',
                quantity: 0,
                price: 0,
                currency: '',
                product_id: '',
                stock_keeping_unit: '',
                brand: '',
                category: '',
                status: true,
                tags: [],
                tax: [],
                variants: [],
                type: {
                vegetarian: false,
                vegan: false,
                spicy: false,
                signature_dish: false,
                halal: false
                },
                total_tax: 0,
                variant: '',
                image: '',
                gallery: []
            }),
            (this.variant_name = '')
            this.variant_quantity = ''
            this.variant_price = ''
            this.tax_name = ''
            this.tax_percentage = ''
            this.$modal.hide('product-create')
        },
        hideeditproduct() {
            this.$modal.hide('product-edit')
        },
        hideproduct() {
            this.$modal.hide('product-details')
        },
        companyTimeline() {
            this.isCompanyTimeline = true
            this.isCompanyRelationship = false
            this.isCompanygallery = false
        },
        relationshipPage() {
            this.isCompanyRelationship = true
            this.isCompanyTimeline = false
            this.isCompanygallery = false
        },
        galleryPage() {
            this.isCompanyRelationship = false
            this.isCompanyTimeline = false
            this.isCompanygallery = true
        },
        setProduct(product) {
            this.product_details = product
            this.$store.commit('setProductDetails', this.product_details)
            this.$modal.hide('product-create')
        },
        showUploadedImages(gallery) {
            return 'https://cdn.digicollect.com/cdn/dcpos/pics/47521fc0-f9d4-11e8-8890-f23c91b0d497/8525bb7f-ff8c-41d1-bf45-0cdd93cbbd3e.png'
        },
        showproductvariants(product_id) {
            this.productDetailsId = product_id
            EventBus.$emit('ProductVariant', product_id)
            this.$modal.show('product_info')
            // e.preventDefault()
            // e.stopPropagation()
        },
        editproductmodal(product_id) {
            // EventBus.$emit('editProductDetails', product_id)
            // this.$modal.show('product-edit')
            this.$router.push({
                name: 'EditProductNew',
                query: { id: product_id },
            })
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser
        }
    },

    mounted() {
        EventBus.$emit('settingsLoad', false)
        EventBus.$emit('customerdetailsLoad', false)
        EventBus.$emit('reportsLoad', false)
        EventBus.$on('ProductVariantChange', product => {
            this.products.forEach(element => {
                if (element._id == product.product_id) {
                    element.variants.forEach(elementIn => {
                        if (elementIn.id == product.variant_id) {
                            elementIn.status = product.status
                        }
                    })
                }
            })
        })
        EventBus.$on('ProductStatusChange', product => {
            this.products.forEach(element => {
                if (element._id == product.product_id) {
                    element.status = product.status
                }
            })
        })
        EventBus.$on('ProductVariantsDynamicChange', product => {
            this.products.forEach(element => {
                if (element._id == product.product_id) {
                    element.variants.forEach(elementIn => {
                        elementIn.status = product.status
                    })
                }
            })
        })
        EventBus.$on('Show Category', modal_name => {
            this.$modal.hide('product-create')
        })
        EventBus.$on('updateProductList', res => {
            this.get_all_products()
        })
        if (this.$route.query.add_product == 'true') {
            this.createproduct()
        }
    },
    created() {
        this.get_all_products('init')
        // this.get_categories_for_products()
        // this.get_brands_for_products()
    },
    destroyed() {
        EventBus.$off('ProductVariantChange')
        EventBus.$off('ProductStatusChange')
        EventBus.$off('ProductVariantsDynamicChange')
        EventBus.$off('Show Category')
        EventBus.$off('updateProductList')
    }
}
</script>
