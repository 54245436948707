<template>
  <div>
    <div class="v-modal-layout pt-3 edit-employee">
      <div class="row first-middle-input">
        <div class="col-md-6" style="box-shadow: 4.5px 0 0px 0 rgba(0, 0, 0, 0.23);">
          <div class="profile-left pr-1 pl-2 pt-3 pb-5">
            <div class="profile-image pl-3" style="margin: 0 auto;text-align: center;">

              <img src="https://cdn.digicollect.com/cdn/dccrm/profile_pics/defaults/person.png" v-if="!employee.profile_pic"
												style="width: 90px;">
              <img :src="employee.profile_pic" style="width: 90px;" v-else />
            </div>
          </div>
          <div class="widget-content p-4">
            <span class="heading-employee">Personal Information</span>
            <div class="py-1 pb-2 pt-3 row no-gutters align-items-center justify-content-between">
              <div class="col names-employees-heading">
                <div class>Name</div>
              </div>
              <div class="col-auto names-employees">{{employee.full_name}}</div>
            </div>
            <div class="py-1 row no-gutters align-items-center justify-content-between">
              <div class="col names-employees-heading">
                <div class>Title</div>
              </div>
              <div
                class="col-auto names-employees"
                style="text-transform:capitalize;"
              >{{employee.user_role.replace('default_', ' ').split('_').join(' ')}}</div>
            </div>
          </div>
          <div class="widget-content p-4">
            <span class="heading-employee">Communication Information</span>
            <div class="py-1 pb-2 pt-3 row no-gutters align-items-center justify-content-between">
              <div class="col names-employees-heading">
                <div class>Email</div>
              </div>
              <div class="col-auto names-employees">{{employee.email}}</div>
            </div>
            <div class="py-1 row no-gutters align-items-center justify-content-between">
              <div class="col names-employees-heading">
                <div class>Phone</div>
              </div>
              <div class="col-auto names-employees">{{employee.phone}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <span class="heading-employee">Employee Role</span>
          <br />
          <div class="pt-2">
            <!-- <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" v-model="employee.user_role" value="default_admin" name="role" >
                                <span class="radio-icon"></span>
                                <span class="form-check-description">General Manager</span>
                            </label>
            </div>-->
            <div class="form-check form-check-inline" v-if="loggedInUser.isAdmin">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="employee.user_role" @change="changeRole"
                  value="default_manager"
                  name="role"
                />
                <span class="radio-icon"></span>
                <span class="form-check-description">Manager</span>
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio" @change="changeRole"
                  v-model="employee.user_role"
                  value="default_order_receiver"
                  name="role"
                />
                <span class="radio-icon"></span>
                <span class="form-check-description">Chef</span>
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="radio" @change="changeRole"
                  v-model="employee.user_role"
                  value="default_order_taker"
                  name="role"
                />
                <span class="radio-icon"></span>
                <span class="form-check-description">Waiter</span>
              </label>
            </div>
          </div>
          <div class="form-group edit-emp-mul-select" v-if="employee.user_role != 'default_admin'">
            <label style="color:#00448b;">Select Oulets</label>
            <v-select
              label="name"
              v-model="selected"
              :selected="selected"
              :multiple="employee.user_role == 'default_manager'"
              placeholder="Search and Select Outlet"
              :filterable="false"
              :options="options"
              @search="onSearch"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="v-modal-footer text-center pt-5">
      <button
        class="btn btn-link"
        style="text-transform: uppercase !important;font-size: 12px !important;" :disabled="save_api_inprogress"
        @click="hideeditemployee()"
      >CANCEL</button>
      <button type="submit" class="btn btn-secondary" @click="editEmployee()" :disabled="save_api_inprogress">SAVE
        <half-circle-spinner :animation-duration="1000" :size="20" v-if="save_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
      </button>
    </div>
  </div>
</template>
<style>
.heading-employee {
  font-size: 14px;
  font-weight: bold;
  color: #303031;
}
.names-employees-heading {
  font-size: 14px;
  font-weight: bold;
  color: #4e5764;
}
.names-employees {
  font-size: 14px;
  font-weight: bold;
  color: #303031;
}

@media (min-width: 1200px) {
  .employee-edit-modal.v--modal-overlay .v--modal-box {
    top: 30px !important;
    max-width: 830px !important;
    left: 0px !important;
    width: 830px !important;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.43) !important;
    border-radius: 15px !important;
    max-height: 600px !important;
    max-height: 100vh !important;
    margin: 0 auto;
  }
}
@media (max-width: 992px) {
  .employee-edit-modal.v--modal-overlay .v--modal-box {
    width: inherit !important;
    max-width: 700px !important;
    /* left: 165px!important; */
  }
}
.edit-employee .v-select .dropdown-menu {
  max-height: 200px !important;
  overflow: auto !important;
}
</style>

<script>
import employees from "./mixins/employees.js";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  data() {
    return {
      options: [],
      selected: null,
      show: false,
      save_api_inprogress: false
    };
  },
  mixins: [employees],
  props: ["employee"],
  created() {
    this.$http.post("/outlet/get", {
          limit: 30,
          search_key: "",
          skip: 0
          }).then(res => {
      this.options = res.data.outlets;
      if(this.employee.user_role == 'default_manager'){
        this.selected = [];
        if(this.employee.outlet.length > 0) {
          this.employee.outlet.forEach((el2, index2) => {
            this.options.forEach((el, index) => {
              if (el.name == this.employee.outlet[index2]) {
                this.selected.push(el);
              }
            })
          });
        }
      } else {
        for (var outlet in this.options) {
          if(this.employee.outlet.length > 0) {
            if (this.options[outlet].name === this.employee.outlet[0]) {
              this.selected = this.options[outlet];
            }
          }
        }
      }
    });
  },
  components: {
    HalfCircleSpinner
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    }
  },
  methods: {
    changeRole(e) {
      // console.log(e.target.value);
      // console.log(this.selected);
      // this.selected = this.selected;
      // this.$forceUpdate();
    },
    hideeditemployee() {
      this.$modal.hide("employee-edit");
    },
    onSearch(search, loading) {
      loading(true);
      this.search(loading, search, this);
    },
    search(loading, search, vm) {
      this.$http.post("/outlet/get", { search_key: search }).then(res => {
        vm.options = res.data.outlets;
        loading(false);
      });
    },
    editEmployee() {
      this.save_api_inprogress = true
      var details = {
        user_id: this.employee.user_id,
        // outlet: this.selected.name,
        outlet_id: [],
        user_role: this.employee.user_role
      };
      if (this.employee.user_role == "default_manager") {
        if(this.selected.length > 0){
          this.selected.forEach((element, index) => {
            details.outlet_id.push(element._id);
          });
        }
      } else {
        details.outlet_id.push(this.selected._id);
      }
      this.$http.post("/employee/edit_employee", details).then(res => {
        if (res.data.status_id == 1) {
          this.$modal.hide("employee-edit");
          this.$emit("employeeEdited");
          this.$swal({
            title: res.data.message,
            text: "",
            icon: "success",
            type: "success"
          });
        } else {
          this.$swal({
            title: "Cannot Edit",
            text: res.data.reason || "",
            icon: "warning",
            buttons: ["ok"],
            type: "warning"
          });
        }
        this.save_api_inprogress = false
      }).catch((err) => {
        this.save_api_inprogress = true
      });
    }
  }
};
</script>
