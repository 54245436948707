<template>
    <div class="page-layout simple registerblock right-sidebar inner-sidebar">
        <div class="pb-10">
            <h2 class="headerblock pl-3 pr-3">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex">
                        <router-link
                            :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                              <button class="btn btn-light-grey btn-fab-dc mr-3">
                                <i class="icons icon-arrow-left"></i>
                              </button>
                            </el-tooltip>
                        </router-link>
                        <router-link
                            :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3">
                                  <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <!-- <a href="javascript:;" @click="addRecipe()" class="btn btn-secondary btn-smm ml-2">
                           + Add Recipe
                        </a> -->
                        <a href="javascript:;" class="btn btn-secondary btn-smm ml-2">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg">
                            Filter
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-end">
                        <div class="d-flex align-items-center">
                            <span class="page-info px-2 hidden-md-down"> {{getSkipCount}} -{{total>skip+limit?skip+limit:total}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-left" @click="previousPage()"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-right" @click="nextPage()"></i>
                                </button>
                            </el-tooltip>
                        </div>
                        <div class="breadcome-heading d-none d-xl-inline">
                          <div class="form-group formsearch has-warning has-feedback">
                            <input type="text" v-model="search_product" @input="searchProduct()" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                            <!-- <span class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" ></span> -->
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                          </div>
                        </div>
                        <div class="d-flex ml-3 mt-1">
                          <el-dropdown @click="handleClick" trigger="click">
                            <span class="pointer exportButton pl-2 d-flex align-items-center">
                              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg">
                              <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>PDF</el-dropdown-item>
                              <el-dropdown-item>XLSX</el-dropdown-item>
                              <el-dropdown-item>CSV</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <!-- <span class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
                            <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Pdf</span>
                          </span> -->
                          <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/printer.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                          </span>
                        </div>
                    </div>
                </div>
            </h2>
            <div class="account-type-block">
                <div id="mail" style="">
                    <div class="content-section registerblock-section" style="">
                        <div class="center-section" style="background-color: #dee8f2;overflow-y: auto;margin: 10px;border-radius: 15px;overflow-x: hidden;">
                          <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 2px solid #00448b;border-radius: 18px;background-color: #fff;top: -15px;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;">
                              RECIPE
                          </div>
                            <div class="crmaccounttypecard crmaccounttype-over-card personal">
                                <div class="crmaccounttypecard-body mb-0">
                                  <div class="mat-table-scm mb-0">
                                      <div class="mat-header-row-scm">
                                          <div class="mat-header-cell-scm mat-header-image"></div>
                                          <div class="mat-header-cell-scm mat-header-first">PRODUCT NAME</div>
                                          <div class="mat-header-cell-scm pl-2">CATEGORY</div>
                                          <div class="mat-header-cell-scm pl-2">SUB CATEGORY</div>
                                          <div class="mat-header-cell-scm pl-2">PRODUCT TYPE</div>
                                          <div class="mat-header-cell-scm pl-2">CLASS</div>
                                          <div class="mat-header-cell-scm pl-2">RECIPE/<br>VARIANT</div>
                                          <div class="mat-header-cell-scm mat-header-last pl-2">QR CODE</div>
                                          <!-- <div class="mat-header-cell-scm mat-header-last">TOTAL VARIANT<br>COUNT</div> -->
                                      </div>
                                      <div class="mat-row-scm text-center" style="justify-content: center;" v-if="ajaxCallinprogress">
                                            <div class="digi-table-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            </div>
                                      </div>
                                      <div class="mat-row-scm text-center text-secondary" v-if="!ajaxCallinprogress && !products.length && search_product.length == ''" style="justify-content: center;">
                                            No Recipes
                                      </div>
                                      <div class="mat-row-scm text-center text-secondary d-block" v-if="!ajaxCallinprogress && !products.length && search_product.length" style="justify-content: center;">
                                             <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/no_search_result.svg" class="pl-20" style="padding-top: 20px;"><br>
                                           <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Search Results Found</p>
                                      </div>
                                      <div v-for="(product,index) in products" :key="index">
                                        <label class="w-100" style="padding-left:0 !important">
                                            <div :class="{'producthightlight':selectedProduct._id == product._id}" @click="selectVariants(product.variant,false,product)" class="mat-row-scm">
                                                <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                        <input type="checkbox" class="form-check-input d-none" :value="product._id" :id="product._id" v-model="checkedVariants" @click="showVariant($event)" style="margin-bottom: 0px;">
                                                        <span class="icon icon-menu-down mt-1 text-secondary" style="width: 20px;" v-if="checkedVariants.includes(product._id) && product.variant == true"></span>
                                                        <span class="icon icon-menu-right mt-1 text-secondary" style="width: 20px;" v-show="product.variant == true" v-else></span>
                                                    <div class="avatar" :class="{addspace:!product.variant}" v-if="product.image != ''">
                                                        <img class="avatar" :src="product.image">
                                                    </div>
                                                    <avatar
                                                        v-else
                                                        :class="{addspace:!product.variant}"
                                                        :username="product.name.replace(/ .*/,'')"
                                                        :src="product.image"
                                                        :size="40"
                                                        :rounded="true"
                                                        color="#fff"
                                                    ></avatar>
                                                </div>
                                                <div class="mat-cell-scm mat-cell-scm-first">
                                                    <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                    {{product.name}}
                                                </div>
                                                <div class="mat-cell-scm uk-text-truncate pl-2">{{product.category}}</div>
                                                <div class="mat-cell-scm uk-text-truncate pl-2">{{product.sub_category || '-'}}</div>
                                                <div class="mat-cell-scm pl-2">
                                                    {{product.product_type_id || '-'}}
                                                </div>
                                                <div class="mat-cell-scm pl-2">
                                                    Products
                                                </div>
                                                <div class="mat-cell-scm pl-2">
                                                    <div class="pointer">
                                                        <button class="btn btn-icon actionicons" v-if="product.variants.length>0" style="border-radius: 0px !important;">
                                                            <el-tooltip class="item" effect="dark" content="Show Variants" placement="top">
                                                                <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg" style="">
                                                            </el-tooltip>
                                                        </button>
                                                        <button class="btn btn-icon actionicons" v-if="product.variants.length==0" style="border-radius: 0px !important;">
                                                            <el-tooltip class="item" effect="dark" content="No Variants" placement="top">
                                                                <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg" style="">
                                                            </el-tooltip>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="mat-cell-scm mat-cell-scm-last pl-2">
                                                    <div class="pointer">
                                                        <button class="btn btn-icon actionicons" style="border-radius: 0px !important;">
                                                            <!-- <el-tooltip class="item" effect="dark" content="Show Variants" placement="top"> -->
                                                                <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/qr.svg" style="">
                                                            <!-- </el-tooltip> -->
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                        <!--Variants-->
                                        <div class="mat-row-scm" :class="{'varianthightlight':selectedProduct.variant_name == variant.variant_name}" v-show="checkedVariants.includes(product._id)" @click="selectVariants(product.variant,true,variant,product._id,product,)" v-for="(variant,index) in product.variants" :key="index">
                                            <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                <!-- <div class="avatar ml-5">
                                                    <img class="avatar" src="https://cdn.digicollect.com/cdn/posv2/avatars/1.jpg">
                                                </div> -->
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-first">
                                                <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                {{variant.variant_name}}
                                            </div>
                                            <div class="mat-cell-scm uk-text-truncate pl-2">{{product.category}}</div>
                                            <div class="mat-cell-scm uk-text-truncate pl-2">{{product.sub_category || '-'}}</div>
                                            <div class="mat-cell-scm pl-2">
                                                {{product.product_type_id}}
                                            </div>
                                            <div class="mat-cell-scm pl-2">
                                                Products
                                            </div>
                                            <div class="mat-cell-scm pl-2">

                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-last pl-2">
                                                <div class="pointer">
                                                    <button class="btn btn-icon actionicons" style="border-radius: 0px !important;">
                                                        <!-- <el-tooltip class="item" effect="dark" content="Show Variants" placement="top"> -->
                                                            <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/qr.svg" style="">
                                                        <!-- </el-tooltip> -->
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Variants -->
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="fuse-sidebar crmaccounttype-sidebar sidebar left-positioned open locked-open" style="border-radius: 15px;overflow-y: auto;overflow-x: hidden;margin: 10px 5px;box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);border: solid 1px #00ffeb;background-image: linear-gradient(to bottom, #00448b, #0c95fe);">
                            <div class="preview-elements details-block" v-if="selectedProduct == ''">
                                <div class="px-3 py-2">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/noproducts.svg" class="img-fluid" style="width: 280px;margin: 60px auto 0px;display: block;">
                                    <h5 style="font-size: 18px;color: #303031;margin: 30px auto;line-height: 1.21;letter-spacing: 0.41px;text-align: center;font-weight: 600;">
                                        Please Select a Product to View Details
                                    </h5>
                                </div>
                            </div>
                            <div class="preview-elements details-block" v-else>
                                <div class="recipedescription">
                                    <div class="recipedescription-text w-100">
                                        <div class="btnheaderrecipe" v-if="selectedProduct.variant == false">
                                            {{selectedProduct.name }}
                                        </div>
                                        <div v-else class="btnheaderrecipe">
                                            {{productName}} - {{selectedProduct.variant_name}}
                                        </div>
                                    </div>
                                    <div class="recipe-source-edit" v-if="selectedRecipe != undefined && recipeLoaded == false">
                                        <div class="buttonedit" @click="addRecipe()">
                                            <i class="icon icon-plus mr-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                            Add
                                        </div>
                                    </div>
                                    <!-- <div class="recipe-source-edit">
                                        <div class="buttonedit" @click="editRecipe()">
                                            <i class="icon icon-pencil mr-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                            Edit
                                        </div>
                                    </div> -->
                                </div>
                                <div v-if="recipeLoaded == true" class="mat-row-scm text-center" style="justify-content: center;">
                                    <div class="digi-table-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    </div>
                                </div>
                                <div v-if="selectedRecipe !=undefined && recipeLoaded == false">
                                    <div class="d-flex">
                                        <div class="recipe-source-version-listgroup w-100">
                                            <div class="buttonedit-listgroup ml-2">
                                                <div class="btn-group" style="margin-top: 15px;">
                                                    <span class="recipe-title">RECIPE</span>
                                                    <button v-if="recipe_names.length>1" :title="selectedRecipe.recipe_name" class="btn btn-version btn-lg dropdown-toggle text-none text-truncate" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="text-truncate" style="max-width:141px;">{{selectedRecipe.recipe_name}}</span>
                                                    </button>
                                                    <button v-if="recipe_names.length==1" :title="selectedRecipe.recipe_name" class="btn btn-version btn-lg text-none" type="button" aria-haspopup="true" aria-expanded="false">
                                                        <span class="text-truncate" style="max-width:141px;">{{selectedRecipe.recipe_name}}</span>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a @click="loadRecipe(name)" class="dropdown-item text-none text-truncate" style="width: 130px;" href="javascript:;" v-for="(name,index) in recipe_names" :key="index" :title="name">{{name}}</a>
                                                    </div>
                                                </div>
                                                <span @click="editRecipe()" class="pull-right pointer">
                                                    <i class="icon icon-pencil mr-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="buttonedit-listgroup">
                                            <!-- <v-select v-model="selectedRecipe.version" :options="version_names" value="name" class="um-select um-select-version clearNone"/> -->
                                            <div class="btn-group mt-4">
                                                <button v-if="version_names.length>1" class="btn btn-version btn-lg dropdown-toggle text-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="text-truncate" style="max-width:80px;" :title="'VERSION'+selectedRecipe.version">VERSION {{selectedRecipe.version}}</span>
                                                </button>
                                                <button v-if="version_names.length==1" class="btn btn-version btn-lg text-none" type="button">
                                                    <span class="text-truncate" style="max-width:80px;" :title="'VERSION'+selectedRecipe.version">VERSION {{selectedRecipe.version}}</span>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a @click="loadVersions(index)" class="dropdown-item text-none text-truncate" style="width:120px;" href="javascript:;" v-for="(version,index) in version_names" :key="index" :title="'VERSION'+version">VERSION{{version}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive px-2" style="overflow: hidden !important;">
                                        <table class="table">
                                            <tbody>
                                                <tr style="height: 60px;" v-for="(ingredient,index) in selectedRecipe.ingredients" :key="index">
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 40%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            Ingredient Name
                                                        </span><br>
                                                        <span class="text-bold text-truncate d-block" style="font-size: 12px;font-weight: 600;color: #303031;max-width: 115px;">
                                                            {{ingredient.ingredient_name.name}}
                                                        </span>
                                                    </td>

                                                    <td style="padding: 5px 5px 0px 0px !important;width: 5%;">
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 10%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            Qty
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;">
                                                            {{ingredient.quantity}}
                                                        </span>
                                                    </td>

                                                    <td style="padding: 5px 5px 0px 0px !important;width: 5%;">
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 20%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            UM
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;">
                                                            {{ingredient.UM.name}}
                                                        </span>
                                                    </td>

                                                    <td style="padding: 5px 5px 0px 0px !important;width: 5%;">
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 20%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            M Cost
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;white-space:nowrap">
                                                            {{currency.symbol}} {{formatPrice(ingredient.material_cost)}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center px-2 py-5" style="border-bottom: 1px solid #fff;">
                                        <button type="button" class="btn btn-total w-100">
                                            Total Material Cost: {{currency.symbol}} {{formatPrice(total_material_cost)}}
                                        </button>
                                    </div>

                                    <div class="table-responsive px-2 pt-3" style="overflow: hidden !important;">
                                        <table class="table">
                                            <tbody>
                                                <tr style="height: 46px;" v-for="(labor,index) in selectedRecipe.labors" :key="index">
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 25%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            Cost/ Hour
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;">
                                                            {{currency.symbol}} {{formatPrice(labor.cost_hour)}}
                                                        </span>
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 5%;">
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 35%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            Number of Hours
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;">
                                                            {{labor.no_of_hours}}
                                                        </span>
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 5%;">
                                                    </td>
                                                    <td style="padding: 5px 5px 0px 0px !important;width: 30%;border-bottom: 1px solid #4e5764;">
                                                        <span class="text-bold" style="font-size: 14px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                            Labor Cost
                                                            <span style="cursor: pointer"  @click="showLaborCost(labor)">
                                                                <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                            </span>
                                                        </span><br>
                                                        <span class="text-bold" style="font-size: 12px;font-weight: 600;color: #303031;">
                                                            {{currency.symbol}} {{formatPrice(labor.labor_cost)}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center px-2 py-3" style="border-bottom: 1px solid #fff;">
                                        <button type="button" class="btn btn-total w-100">
                                            Total Labor Cost: {{currency.symbol}} {{formatPrice(total_labor_cost)}}
                                        </button>
                                    </div>
                                    <div class="text-center px-2 py-3">
                                        <button type="button" class="btn btn-total-blue w-100">
                                            COST COMPARISON METRICS
                                        </button>
                                    </div>

                                    <div class="border-radius mt-4 mb-3">
                                        <div class="row">
                                            <div class="col-lg-3 pr-0">
                                                <div class="bg-secondary-blue pt-3 pb-3">
                                                    <h6 class="font-weight-bold text-white text-center fs-12">M. COST</h6>
                                                    <h6 class="font-weight-bold text-white mt-2 text-center fs-12">{{currency.symbol}} {{formatPrice(total_material_cost)}}</h6>
                                                </div>
                                            </div>
                                            <div class="col-lg-1 px-0">
                                                <h6 class="font-weight-bold text-white text-center pt-5">+</h6>
                                            </div>
                                            <div class="col-lg-3 px-0">
                                                <div class="bg-secondary-blue pt-3 pb-3">
                                                    <h6 class="font-weight-bold text-white text-center fs-12">LABOR COST</h6>
                                                    <h6 class="font-weight-bold text-white mt-2 text-center fs-12">{{currency.symbol}} {{formatPrice(total_labor_cost)}}</h6>
                                                </div>
                                            </div>
                                            <div class="col-lg-1 px-0">
                                                <h6 class="font-weight-bold text-white text-center pt-5">=</h6>
                                            </div>
                                            <div class="col-lg-4 pl-0">
                                                <div class="bg-secondary-blue pt-3 pb-3">
                                                    <h6 class="font-weight-bold text-white text-center fs-12">INGREDIENT COST</h6>
                                                    <h6 class="font-weight-bold text-white mt-2 text-center fs-12">{{currency.symbol}} {{formatPrice(ingredient_cost)}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border-radius bg-secondary-blue pt-3 pb-3 mt-8 mb-3">
                                        <div class="row">
                                            <div class="col-lg-4 pr-0" style="border-right: 1px solid #fff;">
                                                <h6 class="font-weight-bold text-white text-center fs-13">
                                                CURRENT ING. <br>COST
                                                <span style="cursor: pointer"  @click="showIngredientCost()">
                                                    <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                                </span>
                                                </h6>
                                                <h6 class="font-weight-bold text-white mt-2 text-center fs-13">{{currency.symbol}} 400.00</h6>
                                            </div>
                                            <div class="col-lg-4 px-0" style="border-right: 1px solid #fff;">
                                                <h6 class="font-weight-bold text-white text-center fs-13">AVG. YTD <br>COST</h6>
                                                <h6 class="font-weight-bold text-white mt-2 text-center fs-13">{{currency.symbol}} 400.00</h6>
                                            </div>
                                            <div class="col-lg-4 pl-0">
                                                <h6 class="font-weight-bold text-white text-center fs-13">AVG HISTORICAL <br>COST</h6>
                                                <h6 class="font-weight-bold text-white mt-2 text-center fs-13">{{currency.symbol}} 400.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-50 text-black text-center pointer" @click="addRecipe()" v-else-if="selectedRecipe == undefined && recipeLoaded == false">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/add_recipe.svg">
                                    <h6 class="font-weight-bold">NO RECIPE FOUND<br>
                                        ADD NEW
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-recipe @saveRecipe="saveRecipe" :productName="productName" :variantId="variantId" :variantName="variantName" :productId="productId" modal_name="add-recipe"></add-recipe>
        <edit-recipe @editedRecipe="editedRecipe" v-if="editRecipeModal == true" :productName="productName" :variantId="variantId" :variantName="variantName" :productId="productId" @closeRecipe="closeRecipe" :recipe="selectedRecipe" modal_name="edit-recipe"></edit-recipe>
        <labor-cost :currency="currency" :labor_details="labor_details" modal_name="labor-cost"></labor-cost>
        <ingredient-cost :currency="currency" modal_name="ingredient-cost"></ingredient-cost>
  </div>
</template>
<script>
const AddRecipe = () => import('./AddRecipe.vue');
const EditRecipe = () => import('./EditRecipe.vue');
const LaborCost = () => import('./LaborCost.vue');
const IngredientCost = () => import('./IngredientCost.vue');
import Avatar from 'vue-avatar'
import ScmServices from '../mixins/scm.js'
export default {
    data() {
        return {
          itemstatus: false,
          products:[],
          search_product:"",
          skip: 0,
          limit: 10,
          total:0,
          ajaxCallinprogress: false,
          checkedVariants:[],
          recipes:[],
          versions:[],
          selectedProduct:'',
          selectedRecipe:'',
          productId: '',
          variantId: '',
          productName:'',
          variantName:'',
          editRecipeModal: false,
          labor_details: '',
          currency: '',
          recipe_names:[],
          version_names:[],
          recipeLoaded: false
        };
    },
    mixins:[ScmServices],
    components: {
        AddRecipe,
        Avatar,
        LaborCost,
        IngredientCost,
        EditRecipe
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      async selectVariants(hasVariants,isVariant,product,productId = null,productName){
          let product_id = product._id
          let product_name = product.name
          let variantId = null
          let variantName = null
          if(isVariant){
            variantId = product.id
            variantName = product.variant_name
            product_id = productId
            product_name = productName.name
          }
          else{
            if(hasVariants){
                return
            }
          }
          this.recipeLoaded = true
          let response = await this.getRecipeByProductId(product_id, variantId);
          this.recipes = response.recipes
          this.recipe_names = response.recipe_names
          this.version_names = response.version_names
          this.currency = response.currency
          this.selectedProduct = product
          this.productId = product_id
          this.variantId = variantId
          this.productName= product_name
          this.variantName = variantName
          this.selectedRecipe = this.recipes[0]
          if(this.recipes.length){
              this.loadRecipe(this.recipes[0].recipe_name);
          }
          this.recipeLoaded = false
      },
      showVariant(e){
        if (e.target.checked) {
        //  console.log(e.target.value)
        }
      },
      async loadRecipe(name){
        let recipe_name = name;
        let response = await this.getRecipeByProductId(this.productId, this.variantId,recipe_name);
        this.recipes = response.recipes
        this.version_names = response.version_names
        this.currency = response.currency
        this.selectedRecipe = this.recipes[0]
      },
      async loadVersions(index){
          this.selectedRecipe = this.recipes[index]
      },
      async saveRecipe(savedRecipe){
        let response = await this.getRecipeByProductId(this.productId, this.variantId,null);
        this.recipes = response.recipes
        this.recipe_names = response.recipe_names
        this.version_names = response.version_names
        this.currency = response.currency
        this.selectedRecipe = this.recipes[0]
      },
      async editedRecipe(savedRecipe){
        let selectedRecipeName= this.selectedRecipe.recipe_name
        this.recipe_names = this.recipe_names.filter(function(value, index, arr){ return value != selectedRecipeName ; });
        this.recipe_names.push(savedRecipe.recipe_name);
        let response = await this.getRecipeByProductId(this.productId, this.variantId,savedRecipe.recipe_name);
        this.recipes = response.recipes
        this.version_names = response.version_names
        this.currency = response.currency
        this.selectedRecipe = savedRecipe
      },
      searchProduct(){
        this.skip = 0;
        this.productId = '';
        this.variantId = '';
        this.selectedProduct = '';
        this.selectedRecipe = '';
        this.loadProducts()
      },
      nextPage() {
        this.productId = '';
        this.variantId = '';
        this.selectedProduct = '';
        this.selectedRecipe = '';
        let skip_temp = this.skip + this.limit > this.total? this.total: this.skip + this.limit;
        if (skip_temp >= this.total) {
            return;
        }
        this.skip = skip_temp;
        this.loadProducts();
      },
      previousPage() {
        if (this.skip == 0) {
            return;
        }
        this.productId = '';
        this.variantId = '';
        this.selectedProduct = '';
        this.selectedRecipe = '';
        this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
        this.loadProducts();
      },
      handleClick() {
      },
      addRecipe(){
          this.$modal.show('add-recipe');
      },
      closeRecipe(){
          this.editRecipeModal = false;
      },
      editRecipe(){
          this.editRecipeModal = true;
          setTimeout(() => {
            this.$modal.show('edit-recipe');
          }, 1000);
      },
      showLaborCost(labor){
          this.labor_details = labor
          this.$modal.show('labor-cost');
      },
      showIngredientCost(){
          this.$modal.show('ingredient-cost');
      },
      async loadProducts(){
          this.products = []
          this.ajaxCallinprogress = true;
          let response = await this.getScmProducts(
              {
                  "search_key":this.search_product,
                  "skip":this.skip,
                  "limit":this.limit
              }
          );
          this.products = response.response
          this.total = response.total
          this.ajaxCallinprogress = false;
      }
    },
    mounted(){
        this.loadProducts();
    },
    created() {
    },
    computed: {
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
        total_material_cost() {
            return this.selectedRecipe.ingredients.reduce((total, ingredient) => total += parseFloat(ingredient.material_cost), 0)
        },
        total_labor_cost(){
            return this.selectedRecipe.labors.reduce((total, labor) => total += parseFloat(labor.labor_cost), 0)
        },
        ingredient_cost(){
            return this.total_material_cost + this.total_labor_cost
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
};
</script>
<style scoped>
.text-none{
    text-transform: none !important;
}
.varianthightlight{
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #f5a623 !important;
    background-color: #dee7f3 !important;
    margin: 0 2px;
}
.producthightlight {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #00448b !important;
    background-color: #dee7f3 !important;
}
.addspace {
    margin-left: 20px;
}
.selectedVariant{
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #f5a623;
    background-color: #dee7f3;
}
.selectedProduct {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #00448b;
    background-color: #dee8f2;
}
.recipe-title{
    padding: 4px 7px;
    border-radius: 18px;
    -webkit-box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #00448b;
    position: absolute;
    text-align: center;
    width: 8rem;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    bottom: 35px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.recipe-type{
  border-radius: 6px;
  padding: 10px 7px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
}
.page-layout.simple.registerblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.registerblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  background-color: #00448b;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.crmaccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.fuse-sidebar.crmaccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.crmaccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  background-color: #00448b;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerblock.left-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section,
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.crmaccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.crmaccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.crmaccounttype-list-item .crmaccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.crmaccounttype-list-item .crmaccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.crmaccounttype-list-item img.crmaccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.crmaccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #00448b;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
.mat-table-scm {
    background-color: transparent;
    box-shadow: none;
    display: block;
    margin: 0px 0px 20px;
}
.mat-header-row-scm, .mat-row-scm{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 0px 0px;
}

.mat-header-cell-scm {
    font-size: 14px;
    font-weight: 600;
    color: #00448b;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
    text-transform: uppercase;
}
.mat-cell-scm, .mat-footer-cell, .mat-header-cell-scm {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
}
.mat-header-cell-scm.mat-header-first, .mat-cell-scm.mat-cell-scm-first {
    flex: 1 1 200px;
    box-sizing: border-box;
    max-width: 200px;
    min-width: 200px;
}
.mat-header-cell-scm {
    box-shadow: 4px 0 0px -2px #94bef0;
}
.mat-header-cell-scm:last-child, .mat-header-cell-scm:first-child  {
    box-shadow: none;
}
.mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
    flex: 1 1 60px;
    box-sizing: border-box;
    max-width: 60px;
    min-width: 60px;
}
.mat-header-cell-scm.mat-header-last, .mat-cell-scm.mat-cell-scm-last {
    flex: 1 1 85px;
    box-sizing: border-box;
    max-width: 85px;
    min-width: 85px;
}
.mat-header-row-scm {
    min-height: 44px;
    padding-left: 10px;
}
.mat-table-scm .mat-row-scm {
    position: relative;
    cursor: pointer;
    min-height: 60px;
    background-color: transparent;
    border-bottom: 1px solid #a8adb4;
}
.mat-table-scm .mat-row-scm:hover {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.mat-header-row-scm, .mat-row-scm {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    border-bottom-color: #a8adb4;
    padding-left: 10px;
}
.mat-cell-scm .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    font-weight: 400!important;
    line-height: 1;
}
.mat-cell-scm, .mat-footer-cell {
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.avatar.avatar-border {
    border: 1.5px solid rgb(205, 210, 218);
    background-color: rgba(255, 255, 255, 0);
    width: 47px !important;
    height: 47px !important;
    border-radius: 50%;
    display: inline-block;
    min-width: 47px !important;
}
.avatar.avatar-border img {
    width: 37px !important;
    height: 37px !important;
    border-radius: 50%;
    min-width: 37px !important;
    max-width: 37px !important;
    max-height: 37px !important;
}
.mat-cell-scm .list-group .list-group-item h3 {
    font-size: 14px !important;
    font-weight: 600;
}
.mat-cell-scm .list-group .list-group-item p {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #4e5764 !important;
}
.mat-cell-scm .list-group .list-group-item>.avatar {
    margin: 0 1rem 0 0 !important;
}
.des-list-box {
    border-radius: 5px;
    padding: 7px 10px;
    color: #fff;
    width: 110px;
    text-align: center;
}
.mat-row-scm:hover .eyeIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .editIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .deleteIcon {
    color: #00448b !important;
}
.mat-row-scm:hover .cloudIcon {
    color: #e82828 !important;
}
.list-group.list-cat {
    padding: 8px 30px;
}
.list-group.list-cat .list-group-item.two-line {
    min-height: 5.2rem;
}
.list-group.list-cat .list-group-item {
	background-color: transparent !important;
}
.list-group.list-cat .list-group-item h3 {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 1.22;
  	letter-spacing: 0.77px;
    color: #fff;
    padding-bottom: 5px;
}
.list-group.list-cat .list-group-item p {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 1.23;
  	letter-spacing: 0.56px;
    color: #fff;
}
.category-badge.category-badge--dot.category-badge--xl {
    line-height: 15px;
    min-height: 15px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    font-size: 0;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    border-radius: 50%;
    border: 2px solid #fff;
}
.category-badge.category-badge--veg {
    color: #fff;
    background: #008000;
}
.category-badge.category-badge--nonveg {
    color: #fff;
    background: #d40000;
}
.category-badge.category-badge--liquid {
    color: #fff;
    background: #fec502;
}
.category-badge.category-badge--transparent {
    color: transparent;
    background: transparent;
    border: 2px solid transparent !important;
}
.nav-tabs.item-tabs .nav-link {
    color: #00448b;
    border-radius: 6px;
    border: 1px solid #00448b;
    height: 46px;
    margin: 0 10px;
    background-color: #fff;
    font-weight: 600;
    width: 95px;
}
.nav-tabs.item-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #00448b;
}
.nav-tabs.item-tabs .nav-link.active:not(.dropdown-toggle):after, .nav-tabs.item-tabs .nav-item.show .nav-link:not(.dropdown-toggle):after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: transparent !important;
}
.btnheaderrecipe {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
    padding: 8px 10px;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0px 10px 5px;
}
.buttonedit {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(116deg, #005dae -11%, #004fe3 90%);
    padding: 6px 6px;
    min-width: 8rem;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0px 10px 5px;
    max-width: 18rem;
}
.digi-list-timeline__items {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}


.digi-list-timeline__items_stocks {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}

.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px 0px 0px 6px;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 0px 6px 6px 0px;
}
.exportButton{
    width: auto;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #1a9347;
}
.exportButton img{
    width:13px;
}
.pdfButton img{
    width:13px;
}
.printButton img{
    width:13px;
}
.pdfButton{
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #e82828;
    width: 66px;
    font-size: 14px;
}
.printButton{
    width: 66px;
    font-size: 14px;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #00448b;
}
.export-index{
    z-index: 99999 !important;
}
.el-dropdown-menu__item{
    color: #303030 !important;
}
.recipedescription {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid transparent;
}
.recipedescription .recipedescription-text h5 {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
}
.recipedescription .recipe-source-edit {
    cursor: pointer;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 12px;
    margin-left: auto;
    padding-left: 24px;
    color: rgba(0,0,0,0.54);
}
.recipedescription .recipe-source-edit .icon-code-tags {
    display: block;
}


.recipedescription-listgroup {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid transparent;
}
.recipedescription-listgroup .recipedescription-text-listgroup h5 {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
}
.recipedescription-listgroup .recipe-source-version {
    cursor: pointer;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 12px;
    margin-left: auto;
    padding-left: 24px;
    color: rgba(0,0,0,0.54);
}
.recipedescription-listgroup .recipe-source-version .icon-code-tags {
    display: block;
}

.btnheaderrecipe-listgroup {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
    padding: 20px 10px 8px;
    min-width: 23rem;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0px 10px 5px;
    max-width: 33rem;
}
.buttonedit-listgroup {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(116deg, #005dae -11%, #004fe3 90%);
    padding: 6px 6px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0px 10px 17px;
    height: 60px;
}
.btn.btn-version {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-weight: 600;
    color: #fff !important;
    font-size: 14px;
}
.btn.btn-total {
    box-shadow: 0 1px 2.5px 0 rgba(146, 104, 104, 0.47) !important;
    background-image: linear-gradient(to bottom, #d3ae7e, #ce5c43)!important;
    border-color: transparent !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    color: #303030 !important;
    font-size: 15px !important;
    letter-spacing: 0.3px !important;
    text-transform: capitalize !important;
}
.btn.btn-total-blue {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16) !important;
    background-image: linear-gradient(96deg, #005dae -12%, #004fe3 88%) !important;
    border-color: transparent !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    color: #fff !important;
    font-size: 15px !important;
    letter-spacing: 0.3px !important;
    text-transform: uppercase !important;
}
.bg-secondary-blue {
    border-radius: 8px !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16) !important;
    background-image: linear-gradient(102deg, #005dae -12%, #004fe3 89%) !important;
}
</style>