<template>
    <div class="mat-toolbar">
        <div class="mat-navbar">
            <nav id="toolbar" class="bg-secondary-light-header">
                <div class="row no-gutters align-items-center flex-nowrap" style="height: 58px;">
                    <div class="col">
                        <div class="row no-gutters align-items-center flex-nowrap">
                            <div class="shortcuts-wrapper row no-gutters align-items-center px-0 px-sm-0 hide-max-510px">
                                <div class="shortcuts row no-gutters align-items-center" style="cursor: auto;">
                                    <span style="cursor: auto;" class="shortcut-button mx-1 app-title text-white menu-btn-hide"
                                        >DigiCollect</span>
                                </div>
                            </div>
                            <div class="show-explogo-max-510px ml-4">
                                <span class="navlogo">
                                    <i class="logo-pos" style="color:#fff!important;"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="center-logo navbar-header hide-max-510px">
                        <router-link v-if="loggedInUser.role != 'customer'" class="navbar-branddigi" :to="{'name': 'Homenew'}">
                            <span class="navlogo">
                                <i class="logo-pos" style="color:#fff!important;"></i>
                            </span>
                            <span class="dark-logo hidden-sm-down ml-2">
                                DigiPOS
                            </span>
                        </router-link>
                        <a v-else class="navbar-branddigi cursor-not-allowed" href="javascript:;">
                            <span class="navlogo">
                                <i class="logo-pos" style="color:#fff!important;"></i>
                            </span>
                            <span class="dark-logo hidden-sm-down ml-2">
                                DigiPOS
                            </span>
                        </a>
                    </div>
                    <div class="col-auto">
                        <div class="row no-gutters align-items-center justify-content-end">
                            <div class="text-white box-shadow nav-options isp-active" :class="{'digi-red': !isp_stable}">
                                ISP
                            </div>
                            <div class="language-button dropdown timezone-button hide-max-1330px ml-2" :class="{'hidden-md-down':loggedInUser.role != 'customer'}">
                                <div class="dropdown-toggle row align-items-center justify-content-center no-gutters px-0 px-sm-4 btn btn-icon text-white products-button"
                                    role="button" id="dropdownLanguageMenu" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <div class="row no-gutters align-items-center">
                                        <span class="text-white" v-if="guess">
                                            {{timeDefault}}
                                        </span>
                                        <span class="text-white" v-else-if="newyork">
                                            {{timeNewYork}}
                                        </span>
                                        <span class="text-white" v-else-if="thailand">
                                            {{timeThailand}} +7
                                        </span>
                                        <span class="text-white" v-else-if="london">
                                            {{timeLondon}}
                                        </span>
                                        <span class="text-white" v-else-if="kolkata">
                                            {{timeKolkata}}
                                        </span>
                                        <span class="text-white" v-else-if="singapore">
                                            {{timeSingapore}} SGT
                                        </span>
                                        <span class="text-white" v-else-if="yangon">
                                            {{timeYangon}} MMT
                                        </span>
                                    </div>
                                </div>
                                <div class="dropdown-menu dropdown-time" aria-labelledby="dropdownLanguageMenu">
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <!-- <span class="flag flag-us"></span> -->
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = true, london=false, kolkata=false, singapore=false, yangon=false, thailand=false, setTimeZone("America/New_York") '>{{timeNewYork}}</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <!-- <span class="flag flag-sg"></span> -->
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=false, yangon=false, thailand=true, setTimeZone("Asia/Bangkok")'>{{timeThailand}}
                                                +07</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <!-- <span class="flag flag-sg"></span> -->
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = false, london=true, kolkata=false, singapore=false, yangon=false, thailand=false, setTimeZone("Europe/London")'>{{timeLondon}}</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <!-- <span class="flag flag-mm"></span> -->
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = false, london=false, kolkata=true, singapore=false, yangon=false, thailand=false, setTimeZone("Asia/Kolkata")'>{{timeKolkata}}</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=true, yangon=false, thailand=false, setTimeZone("Asia/Singapore")'>{{timeSingapore}}
                                                SGT</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item">
                                        <div class="row no-gutters align-items-center flex-nowrap">
                                            <span class="px-3"
                                                v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=false, yangon=true, thailand=false, setTimeZone("Asia/Yangon")'>{{timeYangon}}
                                                MMT</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="user-menu-button pt-1">
                                <el-dropdown class="pl-3 pr-2" trigger="click">
                                    <span class="el-dropdown-link">
                                        <div class="d-flex align-items-center pointer" @click="getNotifications()">
                                            <el-tooltip class="item" effect="dark" content="Notification" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/scmnew/images/images-new-theme/notification.svg" class="w-85">
                                            </el-tooltip>
                                        </div>
                                    </span>
                                    <el-dropdown-menu style="box-shadow:3px 2px 20px rgb(0 0 0 / 30%) !important" slot="dropdown">
                                        <div class="p-2">
                                            <div class="w-400px">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span class="fs-16">Notifications ({{total_notification_count}})</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div>
                                            <div v-if="NotificationsList.length == 0" class="text-center">
                                                <span>Notifications Not Found</span>
                                            </div>
                                            <ul v-if="NotificationsList.length > 0" class="p-1" id="lazy-load-approval1" @scroll="scrollEnd">
                                                <li v-for="(note, index) in NotificationsList" :key = "index">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div v-if="loggedInUser.role == 'default_manager'">
                                                            <router-link to="/pendingapproval" class="decoration-none">
                                                                <span>
                                                                    <span>
                                                                        {{note.title.substring(0, note.title.indexOf("for"))}}
                                                                    </span>
                                                                </span>
                                                            </router-link>
                                                        </div>
                                                        <div v-if="loggedInUser.role == 'default_order_taker'">
                                                            <div >
                                                                {{note.title.substring(0, note.title.indexOf("Request"))}} Request
                                                            </div>
                                                            <!-- <div @click="redirectToCheckout(note)" class="pointer">
                                                                {{note.title.substring(0, note.title.indexOf("Request"))}} Request
                                                            </div> -->
                                                        </div>
                                                        <!-- <span>
                                                            <img width="30" src="/images/images-new-theme/new-tag.svg" alt="">
                                                        </span> -->
                                                    </div>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="font-italic">
                                                            {{humanized_span_time[index]}}
                                                            * Order ID -
                                                            {{note.title.substring(note.title.indexOf('OrderId')+8)}}
                                                        </span>
                                                        <span :class="[note.status == 'Approved' ? 'text-success' : note.status == 'Rejected' ? 'text-danger' : 'text-warning']">
                                                            <span v-if="loggedInUser.role == 'default_order_taker'">
                                                                {{note.status}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <hr>
                                                </li>
                                            </ul>
                                            <div class="notification-spinner" id="spinner" style="height: 20px;">
                                                <div v-if="isLoadingNotifications" class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div class="digi-table-loader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="user-menu-button dropdown px-1 px-sm-3 hidden-sm-down hidden-lg-down" v-if="loggedInUser.role != 'customer'">
                                <div class="dropdown-toggle dropdownhideafter products-button row align-items-center no-gutters px-2 px-sm-4 btn btn-icon hidden-sm-down text-white c-button"
                                    role="button" id="dropdownShortcutMenu" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    Products
                                </div>
                                <product-dropdown></product-dropdown>
                            </div>
                            <div class="start-stop" style="cursor:pointer;" v-if="loggedInUser.is_cashier">
                                <div class="dropdown-toggle dropdownhideafter row align-items-center no-gutters"
                                    @click="StartSessionBtn()" v-if="!viewCashierBtn" id="dropdownShortcutMenu">
                                    <el-tooltip class="item" effect="dark" content="Start Cashier Session"
                                        placement="bottom">
                                        <img
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/start-session.svg">
                                    </el-tooltip>
                                </div>
                                <div class="dropdown-toggle dropdownhideafter row align-items-center no-gutters"
                                    @click="StopSessionBtn()" v-else id="dropdownShortcutMenu">
                                    <el-tooltip class="item" effect="dark" content="Stop Cashier Session"
                                        placement="bottom">
                                        <img
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/stop-session.svg">
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="user-menu-button dropdown">
                                <div class="dropdown-toggle row align-items-center no-gutters px-2 px-sm-4 text-white"
                                    role="button" id="dropdownUserMenu" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <div class="avatar-wrapper">
                                        <img :src="profile_pic" class="avatar" v-if="!loggedInUser.profile_pic">
                                        <img :src="loggedInUser.profile_pic" class="avatar" v-else>
                                    </div>
                                    <div style="margin-top: -15px;">
                                        <span class="username mx-3 d-none d-md-block text-white text-truncate"
                                            style="font-size: 14px;padding-top:2px;max-width:84px;height: 23px;font-weight: 500;">{{loggedInUser.full_name}}</span>
                                        <span class="location-menu hidden-sm-down"
                                            style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block;position: absolute;width:56%;"
                                            :title="getOutletName() || 'All Outlets'">{{ getOutletName() || "All Outlets"}}</span>
                                    </div>
                                </div>
                                <div class="dropdown-menu logout-menu" aria-labelledby="dropdownUserMenu"
                                    style="min-width:17rem;width: 312px;transform: translate3d(-110px, 58px, 0px)!important;">
                                    <div
                                        class="contact ripple flex-wrap flex-sm-nowrap row p-2 no-gutters align-items-center unread fuse-ripple-ready">
                                        <div class="col-auto avatar-wrapper">
                                            <img :src="profile_pic" class="avatar" v-if="!loggedInUser.profile_pic">
                                            <img :src="loggedInUser.profile_pic" class="avatar" v-else>
                                        </div>
                                        <div class="col px-4 text-truncate">
                                            <span class="name h6"
                                                :title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
                                            <div class="d-flex justify-content-between">
                                                <p class="last-message text-truncate text-muted" :title="loggedInUser.email">{{loggedInUser.email}}</p>
                                                <span v-if="(loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_receiver' || loggedInUser.role == 'default_order_taker') && (loggedInUser.pin != null)" class="text-secondary pointer" @click="ResetPinModal">Reset Pin</span>
                                            </div> 
                                        </div>
                                    </div>
                                    <li class="divider"></li>
                                    <li class="divider" v-if="loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager'"></li>
                                    <div v-if="loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager'"
                                        class="contact ripple flex-wrap flex-sm-nowrap row p-2 no-gutters align-items-center unread fuse-ripple-ready">
                                        <div class="col-auto avatar-wrapper">
                                            <img v-if="!loggedInUser.outlet" class="avatar"
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/all-outlets.svg"
                                                alt="All Outlets" style="background: transparent;">
                                            <img v-if="loggedInUser.outlet"
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/outlet-location.svg"
                                                class="avatar" alt="Location" style="background: transparent;">
                                        </div>
                                        <div class="col px-4 text-truncate"
                                            style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform:capitalize;">
                                            <span class="name h6">{{getOutletName()||"All Outlets"}}</span>
                                        </div>
                                        <div class="col-12 col-sm-auto d-flex flex-column align-items-end"
                                            @click="outlets()">
                                            <div class="last-message-time outlet-view">View Outlets</div>
                                        </div>
                                    </div>
                                    <a class="nav-link logout" @click="logout()">
                                        <span style="text-align: center;">
                                            <i class="icon-logout"></i>
                                            Logout
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <select-outlets modal_name="outlet"></select-outlets>
                            <start-session modal_name="session" @showcashierstart="showcashierstart"></start-session>
                            <end-session modal_name="stop-session" @showcashier="showcashier"
                                :cashierSessionData='cashierSessionData'></end-session>
                            <reset-pin modal_name="Reset-Pin-Modal" v-if="reset_pin_show" @hide-reset-modal='CloseResetModal' ></reset-pin>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <div v-if="logout_api_inprogress" class="full-page-loader">
            <div class="d-flex align-items-center justify-content-center">
                <div class="digi-table-loader inverse">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <!-- <sidebar></sidebar> -->
</template>
<script>
    import ResetPin from './ResetPin'
    import {
        EventBus
    } from "./eventBus/nav-bar-event.js";
    import {
        mapGetters
    } from "vuex";
    import Menubar from "./Menubar";
    import moment from "moment-timezone";
    import ProductDropdown from "./ProductDropdown";
    import StartSession from "./StartSession";
    import EndSession from "./EndSession";
    import SelectOutlets from "./SelectOutlets";
    import navbarMixin from './mixins/navbarMixin.js';
    import global from './global.js';
    import Axios from 'axios';
    const SwitchCompanySuccess = () => import('./SwitchCompanySuccess');
    export default {
        mixins: [navbarMixin],
        data() {
            return {
                resetpin: false,
                showOutletsList: false,
                profile_pic: 'https://cdn.digicollect.com/cdn/posv2/defaultUserPicture.svg',
                products: [],
                toggleSideBar: false,
                //isHidden: true,
                togglemenu: true,
                contentStyle: {
                    backgroundColor: "#000000".replace(/0/g, function () {
                        return (~~(Math.random() * 16)).toString(16);
                    })
                },
                viewCashierBtn: false,
                cashierSessionData: {
                    sales: {
                        total: null,
                        cash: '',
                        card: '',
                        others: ''
                    },
                    drawer_in_amount: null,
                    cash_received: null,
                    drawer_out_amount: null
                },
                //timeDefault: true,
                timeDefault: moment()
                    .tz(moment.tz.guess())
                    .format("LTS z"),
                timeNewYork: moment()
                    .tz("America/New_York")
                    .format("LTS z"),
                timeLondon: moment()
                    .tz("Europe/London")
                    .format("LTS z"),
                timeKolkata: moment()
                    .tz("Asia/Kolkata")
                    .format("LTS z"),
                timeSingapore: moment()
                    .tz("Asia/Singapore")
                    .format("LTS"),
                timeYangon: moment()
                    .tz("Asia/Yangon")
                    .format("LTS"),
                timeThailand: moment()
                    .tz("Asia/Bangkok")
                    .format("LTS"),
                timedefault: "",
                guess: true,
                newyork: false,
                london: false,
                kolkata: false,
                singapore: false,
                yangon: false,
                thailand: false,
                selectedTimeZone: "",
                //   faded: '',
                skip: 0,
                comp_data: [],
                comp_limit: 10,
                given_comp_index: 0,
                new_token: "",
                comp_active: false,
                switch_success: false,
                NotificationsList: [],
                humanized_span_time: [],
                total_notification_count : 0,

                // Lazy Loading
                scrollable_notifications: false,
                isLoadingNotifications: false,
                notification_count: 0,
                notification_limit: 5,
                notification_visible: false,
                reset_pin_show: false,
                logout_api_inprogress: false,
                isp_stable: true
            };
        },
        components: {
            ProductDropdown,
            Menubar,
            SelectOutlets,
            StartSession,
            EndSession,
            ResetPin,
            SwitchCompanySuccess
        },
        methods: {
            // redirectToCheckout(value){
            //     let orderId = value.message.order_id
            //     this.$router.push({
            //         name: 'TakeOrderNew',
            //         params: {
            //             orderId,
            //             editOrder: true
            //         }
            //     })
            //     setTimeout(() => {
            //         let param = {
            //             order_id: value.message.order_id,
            //             editOrder: true,
            //             customer_id: value.customer_id,
            //             items_count: value.items_count
            //         }
            //         EventBus.$emit("update_checkOut",param)
            //     }, 500);
            // },
            ResetPinModal(){
                this.reset_pin_show = true
                setTimeout(() => {
                    this.$modal.show('Reset-Pin-Modal')
                })
            },
            CloseResetModal(){
                this.reset_pin_show = false
                setTimeout(() => {
                    this.$modal.hide('Reset-Pin-Modal')
                }, 500);
            },
            showcashierstart() {
                this.viewCashierBtn = true;
            },
            showcashier() {
                this.viewCashierBtn = false;
            },

            StartSessionBtn() {
                this.$modal.show("session");
            },
            cancelSession() {
                this.$modal.hide("session");
            },
            StopSessionBtn() {
                this.getCashierSessionDetails()
                this.$modal.show("stop-session")
            },
            getCashierSessionDetails() {
                this.$http.get("/cashier/get_drawer_out_summary")
                    .then(res => {
                        if (res.data.status_id == 1) {
                            this.cashierSessionData = res.data;
                        }
                    })
            },
            cancelStopSession() {
                this.$modal.hide("stop-session");
            },
            toggleBtn1() {
                EventBus.$emit("navBarClick", true);
            },
            select() {
                EventBus.$emit("topBar", true);
            },
            async unSubscribeTokenToTopic(token) {
                let topic = this.loggedInUser.outlet_id + (this.loggedInUser.role == 'default_manager' ? 'manager' : 'waiter')
                let params = {
                    "to": `/topics/${topic}`,
                    "registration_tokens": [this.fcmDetails.fcm_token]
                }
                fetch('https://iid.googleapis.com/iid/v1:batchRemove',{
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'key='+this.fcmDetails.fcm_server_key
                    }),
                    body: JSON.stringify(params)
                }).then(response => {
                    if (response.status < 200 || response.status >= 400) {
                    throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                    }
                    console.log('UnSubscribed to "'+topic+'"');
                }).catch(error => {
                    console.error(error);
                })
            },
            async logout() {
                this.logout_api_inprogress = true;
                this.unSubscribeTokenToTopic()
                setTimeout(async () => {
                    var res = document.cookie;
                    var multiple = res.split(";");
                    for(var i = 0; i < multiple.length; i++) {
                        var key = multiple[i].split("=");
                        document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                    }
                    await Axios.get(global.ADMIN_API + '/auth/logout').then((resp) => {
                        if (resp.data.status_id == 1) {
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: '.digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: '.digicollect.com',
                                path: '/'
                            });
                            this.$cookie.delete('Token', {
                                domain:document.location.hostname,
                                path:document.location.pathname
                            });
                            this.$cookie.delete('Token', {
                                domain:document.location.hostname,
                            });
                            this.$cookie.delete('Token', {
                                path:document.location.pathname
                            });
                            this.$cookie.delete('Token', {
                                path: '/'
                            });
                            var res = document.cookie;
                            var multiple = res.split(";");
                            for(var i = 0; i < multiple.length; i++) {
                                var key = multiple[i].split("=");
                                document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                            }
                            this.logout_api_inprogress = false;
                            if(this.loggedInUser.role == 'customer'){
                                window.location.href = localStorage.getItem("set_location")
                            }
                            else{
                                window.location.href = global.POS_URL;
                            }
                            sessionStorage.removeItem("receipt_printer")
                            sessionStorage.removeItem("invoice_printer")
                            sessionStorage.removeItem("categories_printer")

                        }
                    }).catch((err) => {
                        this.$cookie.delete("Token");
                        this.$cookie.delete('Token', {
                            domain: window.location.hostname
                        });
                        var res = document.cookie;
                        var multiple = res.split(";");
                        for(var i = 0; i < multiple.length; i++) {
                            var key = multiple[i].split("=");
                            document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                        }
                        this.logout_api_inprogress = false;
                        if(this.loggedInUser.role == 'customer'){
                            window.location.href = localStorage.getItem("set_location")
                        }
                        else{
                            window.location.href = global.POS_URL;
                        }
                        sessionStorage.removeItem("receipt_printer")
                        sessionStorage.removeItem("invoice_printer")
                        sessionStorage.removeItem("categories_printer")
                    })
                }, 1000);
            },
            goToSettings() {
                this.$router.push('/outletmanage');
            },

            compActive(index) {
                this.given_comp_index = index;
                this.comp_active = !this.comp_active;
            },
            async getOrganizationAndComp() {
                try {
                    let param = {
                        skip: this.skip,
                        limit: this.comp_limit,
                        search_key: this.search_key,
                        is_company_switcher: true,
                        product: "SCM"
                    };
                    let response = await this.getHierarchyListView(param);
                    if (response.status_id == 1) {
                        this.comp_data = response.response;
                    }
                } catch (err) {
                    // console.log(err);
                }
            },
            async switchNewCompany(comp_id) {
                try {
                    let params = {
                        company_id: comp_id
                    }
                    let response = await this.switchCompany(params);
                    if (response.token) {
                        this.new_token = response.token;
                        this.$cookie.delete("Token");
                        this.$cookie.delete('Token', {
                            domain: window.location.hostname
                        });
                        this.switch_success = true;
                        setTimeout(() => {
                            this.$modal.show('switch-success');
                        }, 500);
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            setTimeZone(timezone) {
                this.$store.dispatch("setTimezone", timezone);
            },
            outlets() {
                this.$modal.show("outlet");
                EventBus.$emit('outletSwitcher')
            },
            hideoutlets() {
                this.$modal.hide("outlet");
            },
            getOutletName() {
                if (this.loggedInUser.outlet == {}) {
                    return null;
                } else if (this.loggedInUser.outlet) {
                    return this.loggedInUser.outlet;
                } else {
                    return null;
                }
            },
            isCashierSessionActive() {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    if (res.data.status_id) {
                        this.viewCashierBtn = res.data.response.show_stop_session
                    } else {
                        this.viewCashierBtn = false
                    }
                })
            },
            timeAgo(year, month, day, hour, mins, secs) {
                // var UTChours = new Date(year, month, day, hour, mins, secs).getHours()+5
                // var UTCMinutes = new Date(year, month, day, hour, mins, secs).getMinutes()+30

                var dateUTC = new Date().toUTCString()
                var UTCMonth
                if(dateUTC.slice(8, 11) == 'Jan'){
                    UTCMonth = 0
                } else if(dateUTC.slice(8, 11) == 'Feb'){
                    UTCMonth = 1
                } else if(dateUTC.slice(8, 11) == 'Mar'){
                    UTCMonth = 2
                } else if(dateUTC.slice(8, 11) == 'Apr'){
                    UTCMonth = 3
                } else if(dateUTC.slice(8, 11) == 'May'){
                    UTCMonth = 4
                } else if(dateUTC.slice(8, 11) == 'Jun'){
                    UTCMonth = 5
                } else if(dateUTC.slice(8, 11) == 'Jul'){
                    UTCMonth = 6
                } else if(dateUTC.slice(8, 11) == 'Aug'){
                    UTCMonth = 7
                } else if(dateUTC.slice(8, 11) == 'Sep'){
                    UTCMonth = 8
                } else if(dateUTC.slice(8, 11) == 'Oct'){
                    UTCMonth = 9
                } else if(dateUTC.slice(8, 11) == 'Nov'){
                    UTCMonth = 10
                } else if(dateUTC.slice(8, 11) == 'Dec'){
                    UTCMonth = 11
                }
                var UTCday = dateUTC.slice(5, 7)
                var UTCyear = dateUTC.slice(12, 16)
                var UTChour = dateUTC.slice(17, 19)
                var UTCmins = dateUTC.slice(20, 22)
                var UTCsecs = dateUTC.slice(23, 25)
                console.log(new Date())
                console.log(new Date(UTCyear, UTCMonth, UTCday, UTChour, UTCmins, UTCsecs))
                console.log(new Date(year, month, day, hour, mins, secs))

                var seconds = Math.floor((new Date(UTCyear, UTCMonth, UTCday, UTChour, UTCmins, UTCsecs) - new Date(year, month, day, hour, mins, secs)) / 1000);
                var intervalType;

                var interval = Math.floor(seconds / 31536000);
                if (interval >= 1) {
                    intervalType = 'year';
                } else {
                    interval = Math.floor(seconds / 2592000);
                    if (interval >= 1) {
                    intervalType = 'month';
                    } else {
                        interval = Math.floor(seconds / 86400);
                        if (interval >= 1) {
                            intervalType = 'day';
                        } else {
                            interval = Math.floor(seconds / 3600);
                            if (interval >= 1) {
                                intervalType = "hour";
                            } else {
                                interval = Math.floor(seconds / 60);
                                if (interval >= 1) {
                                    intervalType = "minute";
                                } else {
                                    interval = seconds;
                                    intervalType = "second";
                                }
                            }
                        }
                    }
                }
                if (interval > 1 || interval === 0) {
                    intervalType += 's ago';
                } else {
                    intervalType += ' ago';
                }

                this.humanized_span_time.push(interval + ' ' + intervalType);
            },
            async scrollEnd(e){
                const {target} = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    if(this.$store.getters.loggedInUser.role == 'default_manager'){
                        try {
                            this.isLoadingNotifications = true
                            let params = {
                                notification_topic: this.$store.getters.loggedInUser.outlet_id + 'manager',
                                skip: this.notification_count,
                                limit: 10
                            }
                            let result = await this.getNotificationsList(params)
                            console.log(result.status_id, result.skip )
                            if (result.status_id == 1) {
                                if (result.skip == this.notification_count) {
                                    let tempFiles = result.response
                                    tempFiles.forEach((res) => {
                                        this.timeAgo(res.last_modified_time.slice(0, 4), res.last_modified_time.slice(5, 7)-1, res.last_modified_time.slice(8, 10), res.last_modified_time.slice(11, 13), res.last_modified_time.slice(14, 16), res.last_modified_time.slice(17, 19));
                                    })
                                    console.log(tempFiles)
                                    console.log(this.humanized_span_time)
                                    tempFiles.forEach(el => {
                                        this.NotificationsList.push(el)
                                    })
                                    this.notification_count += result.response.length;
                                    this.isLoadingNotifications = false
                                }
                                if (result.response.length < 10){
                                    document.getElementById('spinner').style.display = 'none'
                                    this.isLoadingNotifications = false
                                }
                            }
                        } catch(error){

                        }
                        this.bottom = true;
                    }
                    else if(this.$store.getters.loggedInUser.role == 'default_order_taker'){
                        try {
                            this.isLoadingNotifications = true
                            let params = {
                                notification_topic: this.$store.getters.loggedInUser.outlet_id + 'waiter',
                                skip: this.notification_count,
                                limit: 10
                            }
                            let result = await this.getNotificationsList(params)
                            console.log(result.skip)
                            if (result.status_id == 1) {
                                if (result.skip == this.notification_count) {
                                    let tempFiles = result.response
                                    tempFiles.forEach((res) => {
                                        this.timeAgo(res.last_modified_time.slice(0, 4), res.last_modified_time.slice(5, 7)-1, res.last_modified_time.slice(8, 10), res.last_modified_time.slice(11, 13), res.last_modified_time.slice(14, 16), res.last_modified_time.slice(17, 19));
                                    })
                                    tempFiles.forEach(el => {
                                        this.NotificationsList.push(el)
                                    })
                                    this.notification_count += result.response.length;
                                    this.isLoadingNotifications = false
                                }
                                if (result.response.length < 10){
                                    document.getElementById('spinner').style.display = 'none'
                                    this.isLoadingNotifications = false
                                }
                            }
                        } catch(error){

                        }
                        this.bottom = true;
                    }
                    else if(this.$store.getters.loggedInUser.role == 'default_order_receiver'){
                        try {
                            this.isLoadingNotifications = true
                            let params = {
                                notification_topic: this.$store.getters.loggedInUser.outlet_id + 'chef',
                                skip: this.notification_count,
                                limit: 10
                            }
                            let result = await this.getNotificationsList(params)
                            console.log(result.skip)
                            if (result.status_id == 1) {
                                if (result.skip == this.notification_count) {
                                    let tempFiles = result.response
                                    tempFiles.forEach((res) => {
                                        this.timeAgo(res.last_modified_time.slice(0, 4), res.last_modified_time.slice(5, 7)-1, res.last_modified_time.slice(8, 10), res.last_modified_time.slice(11, 13), res.last_modified_time.slice(14, 16), res.last_modified_time.slice(17, 19));
                                    })
                                    tempFiles.forEach(el => {
                                        this.NotificationsList.push(el)
                                    })
                                    this.notification_count += result.response.length;
                                    this.isLoadingNotifications = false
                                }
                                if (result.response.length < 10){
                                    document.getElementById('spinner').style.display = 'none'
                                    this.isLoadingNotifications = false
                                }
                            }
                        } catch(error){

                        }
                        this.bottom = true;
                    }
                }
            },
            async getNotifications() {
                try{
                    // if(this.NotificationsList.length <= 10){
                        this.humanized_span_time = []
                        this.NotificationsList = []
                        this.notification_count = 0
                    // }
                    if(this.$store.getters.loggedInUser.role == 'default_manager'){
                        let params = {
                            notification_topic: this.$store.getters.loggedInUser.outlet_id + 'manager',
                            skip: 0,
                            limit: 10
                        }
                        let res = await this.getNotificationsList(params)
                        this.NotificationsList = res.response
                        this.total_notification_count = res.count
                        this.notification_count = res.response.length
                    } else if (this.$store.getters.loggedInUser.role == 'default_order_taker') {
                        let params = {
                            notification_topic: this.$store.getters.loggedInUser.outlet_id + 'waiter',
                            skip: 0,
                            limit: 10
                        }
                        let res = await this.getNotificationsList(params)
                        this.NotificationsList = res.response
                        this.total_notification_count = res.count
                        this.notification_count = res.response.length
                    }
                    else if (this.loggedInUser.role == 'default_order_receiver') {
                        let params = {
                            notification_topic: this.$store.getters.loggedInUser.outlet_id + 'chef',
                            skip: 0,
                            limit: 10
                        }
                        let res = await this.getNotificationsList(params)
                        this.NotificationsList = res.response
                        this.total_notification_count = res.count
                        this.notification_count = res.response.length
                    }

                    // Humanized span time Eg : '4 mins ago' format
                    if(this.NotificationsList.length <= 10) {
                        this.NotificationsList.forEach((date) => {
                            this.timeAgo(date.last_modified_time.slice(0, 4), date.last_modified_time.slice(5, 7)-1, date.last_modified_time.slice(8, 10), date.last_modified_time.slice(11, 13), date.last_modified_time.slice(14, 16), date.last_modified_time.slice(17, 19));
                        })
                    }
                    console.log(this.humanized_span_time)
                    console.log(this.NotificationsList)
                }
                catch(error){
                    console.log(error)
                }
            },
            checkISP(){
                setInterval(() => {
                    if(navigator.onLine){
                        this.isp_stable = true
                    }
                    else{
                        this.isp_stable = false
                    }
                    navigator.usb.addEventListener('opened', event => {
                        console.log(event)
                    });
                }, 1000);
            },
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            fcmDetails() {
                return this.$store.getters.fcmDetails;
            }
        },
        async mounted() {
            // this.getOrganizationAndComp();
            this.checkISP();
            EventBus.$on('updateHomeScreendata', () => {
                this.isCashierSessionActive()
            })
            this.isCashierSessionActive();
            setInterval(() => {
                    (this.timeDefault = moment()
                        .tz(moment.tz.guess())
                        .format("HH:mm:ss z")),
                    (this.timeNewYork = moment()
                        .tz("America/New_York")
                        .format("HH:mm:ss z")),
                    (this.timeLondon = moment()
                        .tz("Europe/London")
                        .format("HH:mm:ss z")),
                    (this.timeKolkata = moment()
                        .tz("Asia/Kolkata")
                        .format("HH:mm:ss z")),
                    (this.timeSingapore = moment()
                        .tz("Asia/Singapore")
                        .format("HH:mm:ss")),
                    (this.timeYangon = moment()
                        .tz("Asia/Yangon")
                        .format("HH:mm:ss")),
                    (this.timeThailand = moment()
                        .tz("Asia/Bangkok")
                        .format("HH:mm:ss"));
                }, 1000),
                EventBus.$on("navBarClick", toggle => {
                    this.isHidden = !this.isHidden;
                    this.togglemenu = !this.togglemenu;
                });
        },
    };

</script>

<style>
    .btn-start-session {
        border-radius: 8px !important;
        background-image: linear-gradient(to bottom, #1499f5, #00448b);
        color: #fff !important;
        width: 93%;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 17px;
        margin-top: 10px;
    }

    .btn-stop-session {
        border-radius: 8px !important;
        background-color: #ee0000;
        color: #fff !important;
        width: 93%;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 17px;
        margin-top: 10px;
    }

    @-webkit-keyframes myfirst {
        from {
            text-shadow: 3px 3px 5px #eee;
        }

        to {
            text-shadow: none;
        }
    }

    @-moz-keyframes myfirst {
        from {
            text-shadow: 3px 3px 5px #eee;
        }

        to {
            text-shadow: none;
        }
    }

    @keyframes myfirst {
        from {
            text-shadow: 5px 3px 5px #eee;
        }

        to {
            text-shadow: none;
        }
    }

    .nav-link.logout {
        height: 34px;
        font-weight: bold;
        color: #4e5764 !important;
        box-shadow: 0 -1.5px 2px 0 rgba(0, 0, 0, 0.16);
        background-color: #e5e5e5;
    }

    .nav-link.logout:hover {
        text-decoration: none !important;
        background-color: #00448b !important;
    }

    .nav-link.logout:hover span {
        color: #fff !important;
    }

    .nav-link.logout span {
        font-weight: bold;
        color: #4e5764 !important;
    }

    .nav-link.logout i {
        margin-right: 5px !important;
        font-size: 18px !important;
    }

    .dropdown-menu.logout-menu {
        padding: 0px !important;
    }

    .dropdown-menu .divider {
        height: 1px;
        margin: -4px 2px 4px;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    .location-menu {
        padding-left: 12px;
        font-weight: 700 !important;
        font-size: 13px;
        animation: blink 1s linear infinite;
        letter-spacing: 0.5px;
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }

        75% {
            opacity: 1;
        }
    }

    .last-message-time.outlet-view {
        color: #00448b;
        cursor: pointer;
    }

    .last-message-time.outlet-view:hover {
        transform: scale(1.1);
        transition-property: transform;
    }

    #toolbar {
        height: 58px !important;
        min-height: 58px !important;
    }

    #toolbar {
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
            0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
            0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
    }

    #toolbar .toggle-aside-button,
    #toolbar .search-button,
    #toolbar .quick-panel-button {
        height: 50px !important;
        width: 56px !important;
        padding-right: 0px !important;
    }

    #toolbar .user-menu-button>#dropdownUserMenu {
        height: -webkit-fill-available !important;
        padding-right: 24px !important;
    }

    .app-title {
        padding-left: 0px !important;
        margin-left: 0px !important;
    }

    i.dg-products-icons.md-36 {
        font-size: 36px !important;
        vertical-align: -36px;
        line-height: 36px;
        height: 36px;
        width: 36px;
        padding-bottom: 15px;
        color: rgb(13, 132, 242) !important;
    }

    .app-title.menu-btn-hide {
        padding-left: 15px !important;
    }

    .bg-secondary-light {
        background-image: linear-gradient(to right,
                #00448b,
                #00448b 51%,
                #69b3fc,
                #9fcbff) !important;
    }

    .bg-secondary-light-header {
        background: #00448b;
    }

    .center-logo.navbar-header {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        line-height: 34px;
        text-align: center !important;
        margin-top: 1px;
    }

    .center-logo.navbar-header .navbar-branddigi {
        margin-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        font-size: 20px;
        color: rgba(255, 255, 255, 1);
        text-decoration: none !important;
    }

    @media screen and (min-width: 400px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem !important;
        }
    }

    @media screen and (min-width: 648px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem !important;
        }
    }

    @media screen and (max-width: 1025px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem;
        }
    }

    .center-logo.navbar-header .navbar-branddigi span.navlogo {
        font-weight: 600;
        padding: 0rem 0.5rem 0rem 0.3rem;
        text-transform: none;
        border-color: transparent;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: 0;
        color: rgba(255, 255, 255, 1);
    }

    .center-logo.navbar-header .navbar-branddigi span.dark-logo {
        font-weight: 600;
        padding: 0.8rem 0.4rem 0.8rem 0rem;
        text-transform: none;
        border-color: transparent;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: 0;
        color: #fff !important;
    }

    @media (min-width: 768px) {
        .navbar-header .navbar-branddigi {
            padding-top: 0px;
        }
    }

    .navbar-header .navbar-branddigi i {
        display: inline-block;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
    }

    .timezone-button .dropdown-toggle::after {
        color: #fff;
    }

    .flag {
        height: 32px;
        width: 32px;
        line-height: 20px;
        margin-right: 10px;
    }

    #contenttime {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: 0.5s;
        text-align: center;
    }

    #contenttime .wrappertime {
        margin-right: 10px;
    }

    #contenttime span {
        transition: 0.2s;
        font-size: 16px;
        margin: 0;
        font-weight: 600;
    }

    #contenttime p {
        transition: 0.2s;
    }

    .faded {
        opacity: 0;
        transition: 0.2s;
    }

    .timezone-button {
        line-height: 100%;
    }

    .clock {
        font-size: 24px;
        padding-right: 20px;
        color: #fff;
    }

    .uk-margin-top {
        height: 80px;
    }

    .dropdown-toggle.products-button::after {
        content: none !important;
    }

    @media (min-width: 576px) {
        .px-sm-32 {
            padding-left: 3.2rem !important;
        }
    }

    .app-title {
        font-size: 20px;
        font-weight: 600;
        /* padding: 0rem 3.4rem 0.8rem 0.3rem; */
        text-transform: none;
        min-width: initial;
        height: auto;
        line-height: normal;
        cursor: pointer;
        border-radius: 100% !important;
        background-color: transparent;
        border-color: transparent;
        text-decoration: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 0px solid transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        letter-spacing: 0.04em;
        -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
            0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
            0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
        -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        will-change: box-shadow;
        position: relative;
        outline: none;
        color: #fff !important;
    }

    div.content-app {
        padding-left: 15px;
        padding-right: 15px;
    }

    .base-box.boxed,
    .base-box.boxed-inverse,
    .boxed-inverse,
    [class*="col-md-"].boxed,
    [class*="col-md-"].boxed-inverse,
    .grid-box>.grid-item.boxed-inverse,
    .row.vertical-row>div.boxed-inverse,
    .row.vertical-row>div.boxed {
        border-style: none !important;
    }

    .shadow-1,
    .adv-img-down-text.shadow-1 .img-box,
    .two-blocks-container.shadow-1>.row,
    .section-bg-color .advs-box.boxed-inverse,
    .section-bg-color .adv-img.boxed-inverse {
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.13) !important;
    }

    .black {
        color: #212121 !important;
    }

    .col-center {
        float: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
        clear: both;
        padding-top: 20px;
    }

    .section-bg-image .advs-box.boxed-inverse,
    .black .flex-control-paging li a:hover,
    .adv-img-down-text.boxed-inverse:hover,
    .advs-box-multiple div.circle,
    .list-blog input,
    .list-blog select,
    .section-bg-image [class*="col-md-"].boxed-inverse,
    [class*="shadow-"].boxed-inverse,
    .sidebar-nav ul ul,
    .base-box.boxed,
    .base-box.boxed-inverse,
    .advs-box.boxed-inverse,
    .section-bg-color .form-box .form-control,
    .boxed-inverse:not(.shadow-1) .form-box,
    .boxed-inverse:not(.shadow-1) .form-box .form-control,
    .section-two-blocks .form-box .form-control,
    .section-bg-color .advs-box.boxed-inverse {
        background-color: #fff;
    }

    hr.space {
        border: none !important;
        clear: both;
        height: 70px;
        margin: 0;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .text-l {
        line-height: 38px;
    }

    .text-l {
        font-size: 27px !important;
        line-height: 31px;
    }

    .grid-list {
        position: relative;
    }

    .grid-box.row {
        margin: -20px;
        width: calc(100% + 40px);
    }

    .grid-box>.grid-item {
        padding: 20px;
    }

    .app-box.app-box-top-bottom {
        display: block;
    }

    .grid-item>div {
        padding-left: 0;
        padding-right: 0;
    }

    .app-box-top-bottom .app-box-cell {
        display: block;
        text-align: center;
    }

    .app-box-cell {
        vertical-align: middle;
    }

    .app-box.app-box-top-bottom .app-box-cell>img {
        margin: 0px 0 15px 0;
    }

    .app-box.app-box-top-bottom .app-box-cell>img {
        float: none;
        margin: 7px 0 7px 0;
        display: inline-block;
    }

    .app-box-cell img.text-xl {
        width: 55px;
    }

    .app-box.app-box-top-bottom .app-box-cell>i {
        float: none;
        margin: 7px 0 7px 0;
        display: inline-block;
    }

    .app-box.app-box-top-bottom .app-box-cell>i {
        margin: 0px 0 15px 0;
        color: #5584ff;
    }

    .app-box-cell i.text-xl {
        width: 55px;
        font-size: 55px;
        height: 55px;
        line-height: 55px;
    }

    .app-box-top-bottom .app-box-cell label {
        margin-bottom: 10px;
    }

    .app-box-top-bottom .app-box-cell label {
        padding: 0;
    }

    .app-box label {
        font-weight: 600;
    }

    .app-box-top-bottom .app-box-cell p {
        padding: 0;
        margin-bottom: 0;
        margin: 0;
    }

    .grid-item p {
        height: auto;
    }

    .text-s {
        line-height: 20px;
        font-size: 13px !important;
    }

    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper,
    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper>.aside-content {
        width: 0rem !important;
        min-width: 0rem !important;
    }

    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside {
        width: 0rem;
    }

    .layout.layout-vertical.fuse-aside-folded.layout-left-navigation .wrapper {
        padding-left: 0rem;
    }

    /* .side-menu {
  width: 281px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  border-right: solid 1px #c5d6de;
  overflow: auto;
  z-index: 60;
  padding: 90px 0 110px;
  z-index: 1000;
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
} */
    /* body.sidebar-hidden .side-menu {
  display: none;
} */
    .layout>.aside>.aside-content-wrapper>.aside-content {
        width: 280px;
    }

    .layout>.aside>.aside-content-wrapper {
        width: 280px;
        min-width: 280px;
    }

    .nav-link {
        text-decoration: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 48px;
        padding: 0 24px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-size: 14px;
    }

    .layout>.aside>.aside-content-wrapper>.aside-content>#sidenav .nav-link {
        color: #212121;
    }

    .nav-link i,
    .nav-link [class^="icon-"],
    .nav-link [class*=" icon-"] {
        font-size: 24px !important;
        margin-right: 1.9rem;
    }

    .dropdown-toggle.dropdownhideafter::after {
        content: none !important;
    }

    .dropdown-toggle.text-white::after {
        color: #fff !important;
    }

    .dropdown-apps {
        width: 320px;
        min-height: 100px;
        max-height: 400px;
        overflow-y: auto;
        top: 15px !important;
    }

    .dropdown-apps {
        width: 320px;
        min-height: 100px;
        max-height: 400px;
        overflow-y: auto;
        top: 11px !important;
        left: -15px !important;
    }

    .dropdown-menu {
        -webkit-border-radius: 8px !important;
        border-radius: 8px !important;
        border: 1px solid #cdd2da !important;
        -webkit-box-shadow: 3px 2px 20px rgba(0, 0, 0, 0.2) !important;
        box-shadow: 3px 2px 20px rgba(0, 0, 0, 0.2) !important;
    }

    .dropdown-time {
        top: 11px !important;
    }

    .dropdown-time {
        top: 15px !important;
    }

    .uk-margin-bottom {
        margin-bottom: 16px !important;
    }

    .uk-grid:after,
    .uk-grid:before {
        content: "";
        display: block;
        overflow: hidden;
    }

    [class*="uk-grid-width"]>* {
        box-sizing: border-box;
        width: 100%;
    }

    .uk-grid-width-medium-1-3>* {
        width: 33.333%;
    }

    .uk-margin-top {
        margin-top: 15px !important;
    }

    @media (min-width: 1220px) {
        .uk-grid>* {
            padding-left: 0px;
        }
    }

    .uk-grid>* {
        -ms-flex: none;
        -webkit-flex: none;
        flex: none;
        margin: 0;
        float: left;
        text-align: center;
        text-decoration: none;
    }

    .uk-grid>*:hover {
        text-decoration: none;
    }

    i.dg-icons.md-36 {
        font-size: 36px !important;
        vertical-align: -36px;
        line-height: 36px;
        height: 36px;
        width: 36px;
        padding-bottom: 15px;
        color: #00448b !important;
    }

    .uk-grid>*> :last-child {
        margin-bottom: 0;
    }

    .uk-text-muted {
        color: #212121 !important;
    }

    .uk-display-block {
        display: block !important;
    }

    .logoblock .uk-display-block {
        margin-top: 8px;
    }

    .products-button {
        height: 3.4rem !important;
        line-height: 3.4rem !important;
        border-radius: 100px !important;
        border: 1px solid rgb(184, 223, 255) !important;
        padding: 0px 17.3px !important;
        text-transform: capitalize !important;
    }

    @media (max-width: 1030px) {
        .products-button {
            padding: 0px 7.3px !important;
        }
    }

    .md-theme-default a:not(.md-button):hover {
        color: inherit;
    }

    .md-theme-default a:not(.md-button) {
        color: inherit;
    }

    @media only screen and (max-width: 897px) {
        .app-title.menu-btn-hide {
            padding-left: 0px !important;
        }
    }

    @media (min-width: 899px) {
        .hidden-md-upp {
            display: none !important;
        }
    }

    #toolbar {
        height: 58px !important;
        min-height: 58px !important;
    }

    #toolbar {
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
            0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
            0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
    }

    #toolbar .toggle-aside-button,
    #toolbar .search-button,
    #toolbar .quick-panel-button {
        height: 50px !important;
        width: 56px !important;
        padding-right: 0px !important;
        z-index: 1;
    }

    #toolbar .user-menu-button>#dropdownUserMenu {
        height: -webkit-fill-available !important;
        padding-right: 24px !important;
    }

    .app-title {
        padding-left: 0px !important;
        margin-left: 0px !important;
    }

    .app-title.menu-btn-hide {
        padding-left: 15px !important;
    }

    .center-logo.navbar-header {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        line-height: 34px;
        text-align: center !important;
        margin-top: 1px;
    }

    @media screen and (min-width: 648px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem !important;
        }
    }

    @media screen and (min-width: 400px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem !important;
        }
    }

    @media screen and (max-width: 1025px) {
        .center-logo.navbar-header .navbar-branddigi span.navlogo {
            padding: 0.8rem 0.5rem 0rem 0.3rem;
        }
    }

    .center-logo.navbar-header .navbar-branddigi {
        margin-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        font-size: 20px;
        color: rgba(255, 255, 255, 1);
        text-decoration: none !important;
    }

    .center-logo.navbar-header .navbar-branddigi span.navlogo {
        font-weight: 600;
        padding: 0rem 0.5rem 0rem 0.3rem;
        text-transform: none;
        border-color: transparent;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: 0;
        color: rgba(255, 255, 255, 1);
    }

    .center-logo.navbar-header .navbar-branddigi span.dark-logo {
        font-weight: 600;
        padding: 0.8rem 0.4rem 0.8rem 0rem;
        text-transform: none;
        border-color: transparent;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: 0;
    }

    @media (min-width: 768px) {
        .navbar-header .navbar-branddigi {
            padding-top: 0px;
        }
    }

    .navbar-header .navbar-branddigi i {
        display: inline-block;
        color: rgba(255, 255, 255, 1);
        font-size: 22px;
    }

    .timezone-button .dropdown-toggle::after {
        color: #fff;
    }

    .flag {
        height: 32px;
        width: 32px;
        line-height: 20px;
        margin-right: 10px;
    }

    #contenttime {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: 0.5s;
        text-align: center;
    }

    #contenttime .wrappertime {
        margin-right: 10px;
    }

    #contenttime span {
        transition: 0.2s;
        font-size: 16px;
        margin: 0;
        font-weight: 600;
    }

    #contenttime p {
        transition: 0.2s;
    }

    .faded {
        opacity: 0;
        transition: 0.2s;
    }

    .timezone-button {
        line-height: 100%;
    }

    .clock {
        font-size: 24px;
        padding-right: 20px;
        color: #fff;
    }

    .uk-margin-top {
        height: 80px;
    }

    .dropdown-toggle.products-button::after {
        content: none !important;
    }

    @media (min-width: 576px) {
        .px-sm-32 {
            padding-left: 3.2rem !important;
        }
    }

    .app-title {
        font-size: 20px;
        font-weight: 600 !important;
        /* padding: 0rem 3.4rem 0.8rem 0.3rem; */
        text-transform: none;
        min-width: initial;
        height: auto;
        line-height: normal;
        cursor: pointer;
        border-radius: 100% !important;
        background-color: transparent;
        border-color: transparent;
        text-decoration: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 0px solid transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        letter-spacing: 0.04em;
        -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
            0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
            0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
        -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        will-change: box-shadow;
        position: relative;
        outline: none;
        z-index: 1;
    }

    div.content-app {
        padding-left: 15px;
        padding-right: 15px;
    }

    .base-box.boxed,
    .base-box.boxed-inverse,
    .boxed-inverse,
    [class*="col-md-"].boxed,
    [class*="col-md-"].boxed-inverse,
    .grid-box>.grid-item.boxed-inverse,
    .row.vertical-row>div.boxed-inverse,
    .row.vertical-row>div.boxed {
        border-style: none !important;
    }

    .shadow-1,
    .adv-img-down-text.shadow-1 .img-box,
    .two-blocks-container.shadow-1>.row,
    .section-bg-color .advs-box.boxed-inverse,
    .section-bg-color .adv-img.boxed-inverse {
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.13) !important;
    }

    .black {
        color: #212121 !important;
    }

    .col-center {
        float: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
        clear: both;
        padding-top: 20px;
    }

    .section-bg-image .advs-box.boxed-inverse,
    .black .flex-control-paging li a:hover,
    .adv-img-down-text.boxed-inverse:hover,
    .advs-box-multiple div.circle,
    .list-blog input,
    .list-blog select,
    .section-bg-image [class*="col-md-"].boxed-inverse,
    [class*="shadow-"].boxed-inverse,
    .sidebar-nav ul ul,
    .base-box.boxed,
    .base-box.boxed-inverse,
    .advs-box.boxed-inverse,
    .section-bg-color .form-box .form-control,
    .boxed-inverse:not(.shadow-1) .form-box,
    .boxed-inverse:not(.shadow-1) .form-box .form-control,
    .section-two-blocks .form-box .form-control,
    .section-bg-color .advs-box.boxed-inverse {
        background-color: #fff;
    }

    hr.space {
        border: none !important;
        clear: both;
        height: 70px;
        margin: 0;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .text-l {
        line-height: 38px;
    }

    .text-l {
        font-size: 27px !important;
        line-height: 31px;
    }

    .grid-list {
        position: relative;
    }

    .grid-box.row {
        margin: -20px;
        width: calc(100% + 40px);
    }

    .grid-box>.grid-item {
        padding: 20px;
    }

    .app-box.app-box-top-bottom {
        display: block;
    }

    .grid-item>div {
        padding-left: 0;
        padding-right: 0;
    }

    .app-box-top-bottom .app-box-cell {
        display: block;
        text-align: center;
    }

    .app-box-cell {
        vertical-align: middle;
    }

    .app-box.app-box-top-bottom .app-box-cell>img {
        margin: 0px 0 15px 0;
    }

    .app-box.app-box-top-bottom .app-box-cell>img {
        float: none;
        margin: 7px 0 7px 0;
        display: inline-block;
    }

    .app-box-cell img.text-xl {
        width: 55px;
    }

    .app-box.app-box-top-bottom .app-box-cell>i {
        float: none;
        margin: 7px 0 7px 0;
        display: inline-block;
    }

    .app-box.app-box-top-bottom .app-box-cell>i {
        margin: 0px 0 15px 0;
        color: #00448b;
    }

    .app-box-cell i.text-xl {
        width: 55px;
        font-size: 55px;
        height: 55px;
        line-height: 55px;
    }

    .app-box-top-bottom .app-box-cell label {
        margin-bottom: 10px;
    }

    .app-box-top-bottom .app-box-cell label {
        padding: 0;
    }

    .app-box label {
        font-weight: 600;
    }

    .app-box-top-bottom .app-box-cell p {
        padding: 0;
        margin-bottom: 0;
        margin: 0;
    }

    .grid-item p {
        height: auto;
    }

    .text-s {
        line-height: 20px;
        font-size: 13px !important;
    }

    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper,
    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper>.aside-content {
        width: 0rem !important;
        min-width: 0rem !important;
    }

    body.fuse-aside-folded:not(.fuse-aside-expanded) #aside {
        width: 0rem;
    }

    .layout.layout-vertical.fuse-aside-folded.layout-left-navigation .wrapper {
        padding-left: 0rem;
    }

    /* .side-menu {
  width: 281px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  border-right: solid 1px #c5d6de;
  overflow: auto;
  z-index: 60;
  padding: 90px 0 110px;
  z-index: 1000;
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: transform, width, min-width, max-width;
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
} */
    /* body.sidebar-hidden .side-menu {
  display: none;
} */
    .layout>.aside>.aside-content-wrapper>.aside-content {
        width: 280px;
    }

    .layout>.aside>.aside-content-wrapper {
        width: 280px;
        min-width: 280px;
    }

    .nav-link {
        text-decoration: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 48px;
        padding: 0 24px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-size: 14px;
    }

    .layout>.aside>.aside-content-wrapper>.aside-content>#sidenav .nav-link {
        color: #212121;
    }

    .nav-link i,
    .nav-link [class^="icon-"],
    .nav-link [class*=" icon-"] {
        font-size: 24px !important;
        margin-right: 1.9rem;
    }

    .dropdown-toggle.dropdownhideafter::after {
        content: none !important;
    }

    .dropdown-toggle.text-white::after {
        color: #fff !important;
    }

    .dropdown-apps {
        width: 320px;
        min-height: 100px;
        max-height: 400px;
        overflow-y: auto;
        top: 15px !important;
    }

    .dropdown-time {
        top: 15px !important;
    }

    .uk-margin-bottom {
        margin-bottom: 16px !important;
    }

    .uk-grid:after,
    .uk-grid:before {
        content: "";
        display: block;
        overflow: hidden;
    }

    [class*="uk-grid-width"]>* {
        box-sizing: border-box;
        width: 100%;
    }

    .uk-grid-width-medium-1-3>* {
        width: 33.333%;
    }

    .uk-margin-top {
        margin-top: 15px !important;
    }

    @media (min-width: 1220px) {
        .uk-grid>* {
            padding-left: 0px;
        }
    }

    .uk-grid>* {
        -ms-flex: none;
        -webkit-flex: none;
        flex: none;
        margin: 0;
        float: left;
        text-align: center;
        text-decoration: none;
    }

    .uk-grid>*:hover {
        text-decoration: none;
    }

    i.logo-material-icons.md-36 {
        font-size: 36px !important;
        vertical-align: -36px;
        line-height: 36px;
        height: 36px;
        width: 36px;
        padding-bottom: 15px;
        color: #00448b !important;
    }

    i.logo-crm.logo-material-icons.md-36,
    i.logo-cloud.logo-material-icons.md-36,
    i.logo-pay.logo-material-icons.md-36,
    i.logo-play.logo-material-icons.md-36,
    i.logo-pos.logo-material-icons.md-36 {
        width: 46px;
    }

    .uk-grid>*> :last-child {
        margin-bottom: 0;
    }

    .uk-text-muted {
        color: #212121 !important;
    }

    .uk-display-block {
        display: block !important;
    }

    .logoblock .uk-display-block {
        margin-top: 8px;
    }

    .products-button {
        height: 3.4rem !important;
        line-height: 3.4rem !important;
        border-radius: 100px !important;
        border: 1px solid rgb(184, 223, 255) !important;
        padding: 0px 17.3px !important;
        text-transform: capitalize !important;
    }

    @media only screen and (max-width: 768px) {
        .app-title.menu-btn-hide {
            padding-left: 15px !important;
        }

        .app-title.menu-btn-hide.menu-btn-show-admin {
            padding-left: 15px !important;
        }
    }

    .pointer-events-none {
        /* pointer-events: none; */
        cursor: not-allowed !important;
    }

    .settings-icon {
        top: 10px;
        right: 10px;
        padding: 4.1px 4px 4.1px 4.3px;
        border-radius: 2px;
        border: solid 0.5px #2b6ad0;
        background-color: #2b6ad0;
    }

    .switch-user-prpfile {
        border-radius: 50%;
        border: 2px solid #ecf1f9;
        width: 75px;
        height: 75px;
    }

    .company-list {
        border: solid 0.2px rgba(128, 132, 149, 0.05);
        background-color: rgba(13, 91, 230, 0.05);
    }

    .overflow-height {
        height: 300px;
    }
    .w-400px{
        width: 400px;
    }
    ul{
        list-style-type: none;
        padding-left: 0px;
    }
    .font-italic{
        font-style: italic
    }
    #lazy-load-approval1 {
        max-height:200px;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .decoration-none{
        text-decoration: none !important;
        color: #000;
    }
    .products-button {
        height: 3.4rem!important;
        line-height: 3.4rem!important;
        border-radius: 100px!important;
        border: 1px solid #b8dfff!important;
        padding: 0 17.3px!important;
        text-transform: capitalize!important;
    }
    .isp-active{
        width: 50px;
        border-radius: 7px;
        padding-left: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 5px;
        font-size: 16px;
        background: #25bb83;
        border: solid 1px #cdd2da;
        font-weight: 500;
    }
    .hide-max-1330px {
        display: block;
    }
    .hide-max-510px {
        display: block;
    }
    .show-explogo-max-510px {
        display: none;
    }
    @media screen and (max-width: 510px) {
        .hide-max-510px {
            display: none;
        }
        .show-explogo-max-510px {
            display: block;
        }
    }
    @media screen and (max-width: 1300px) {
        .hide-max-1330px {
            display: none;
        }
    }
</style>
