export default {
    data() {
        return {
            searchProduct: "",
        }
    },
    methods: {
        async brandsAdd(params) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.post('brands/add', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async brandsDelete(id) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.get(`brands/delete/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async brandsUpdate(params) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.post(
                        `brands/get/${params._id}`,
                        params
                    )
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async brandsGet(params) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.get(`brands/get`, { params })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async brandsGetId(id) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.get(`brands/get/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async brandsGetIdProducts(id, params) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.get(
                        `brands/get/${id}/products`, { params }
                    )
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        getProductsForBrand(id, searchData) {
            return new Promise((resolve, reject) => {
                if (id)
                    this.$http
                    .get("brands/get/" + id + "/products", {
                        params: { search: searchData }
                    })
                    .then(res => {
                        if (res.data.status_id == 1) resolve(res.data.message);
                        else reject(res.data.reason);
                    })
                    .catch(res => reject(res.response.data.reason));
            });
        },
        async searchProductsForBrand(id) {
            this.products = await this.getProductsForBrand(
                // this.categoryProductsID,
                id,
                this.searchProduct
            );
        }
    },
    watch: {
        searchProduct(newKey, oldKey) {
            this.searchProductsForCategory();
        }
    }
}