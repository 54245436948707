<template>
    <div class="app-digi" id="full-screen">
        <div class="horizontal-layout-1" v-if="$route.path.startsWith('/payments/scan_and_pay')">
            <main id="main" class="chrome">
                <!-- <div class="digitop" v-if="!mobileView">
                    <navbar></navbar>
                </div> -->
                <router-view />
            </main>
        </div>
		<div class="horizontal-layout-1" v-else-if="loadingGif">
			<main id="main" class="chrome">
                <div class="horizontal-layout-1 d-flex justify-content-center align-items-center">
                    <hollow-dots-spinner :animation-duration="1000" :dot-size="25" :dots-num="3" color="#206ad3" class="text-center d-flex w-100 mt-0 justify-content-center"/>
                </div>
			</main>
        </div>
        <div class="horizontal-layout-1" v-else-if="!register && String($route.path) == '/pagenotfound'">
            <main id="main" class="chrome">
                <router-view />
            </main>
        </div>
        <div class="horizontal-layout-1" v-else-if="!register && (String($route.path) == '/place_order/payment_screen' || String($route.path) == '/place_order/payment_screen_ach')">
            <main id="main" class="chrome">
                <div class="digitop" v-if="!mobileView">
                    <navbar></navbar>
                </div>
                <router-view />
            </main>
        </div>
        <div class="horizontal-layout-1" v-else-if="register && !String($route.path).startsWith('/place_order') && !String($route.path).startsWith('/view_customer_order') || !otpVerified && !String($route.path).startsWith('/place_order') && !String($route.path).startsWith('/view_customer_order')">
            <main id="main" class="chrome">
                <router-view />
            </main>
        </div>
        <div class="horizontal-layout-1" v-else-if="(!register && (String($route.path).startsWith('/place_order') && !String($route.path).startsWith('/view_customer_order')) || !register && (String($route.path).startsWith('/view_customer_order') && !String($route.path).startsWith('/place_order')) || otpVerified && (String($route.path).startsWith('/place_order') && !String($route.path).startsWith('/view_customer_order')) || otpVerified && (!String($route.path).startsWith('/place_order') &&  String($route.path).startsWith('/view_customer_order'))) || !register && loggedInUser.role != 'customer' || otpVerified && loggedInUser.role != 'customer'">
			<main id="main" class="chrome">
                <div class="digitop" v-if="!mobileView">
                    <navbar></navbar>
                </div>
                <div v-if="!mobileView && $route.path != '/'">
                    <menubar v-if="loggedInUser.actions ? loggedInUser.actions.home : loggedInUser.role == 'customer'"></menubar>
                </div>
                <div class="digicontainer">
                    <div class="dccontainer">
                        <section class="ps-container w-100 ps ps--theme_default">
                            <div class="bigcontainer" id="bigcontainer_scroll" ref="containerDiv" :class="{'overflow-hidden':showaccess}">
                                <div class="content-block" :class="{'pl-0': loggedInUser.role != 'customer','pr-0':loggedInUser.role != 'customer'}">
                                    <div v-if="!loadingApp && !showaccess">
                                        <router-view/>
                                    </div>
                                    <div v-else-if="loadingApp">
                                        <hollow-dots-spinner :animation-duration="1000" :dot-size="25" :dots-num="3" color="#206ad3" class="text-center d-flex w-100 mt-0 justify-content-center"/>
                                    </div>
                                    <div v-else-if="!loadingApp && showaccess">
                                        <access-page></access-page>
                                    </div>
                                </div>
                                <foot v-if="!mobileView"></foot>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import global from "./components/global.js"
import UserServices from './components/mixins/user'
import { checkPermissions } from './components/mixins/permissions.js'
import Navbar from './components/Navbar'
import Notify from './Notify';
import Foot from './components/Footer'
import Menubar from './components/Menubar'
import AccessPage from './components/Access'
import { EventBus } from './components/eventBus/nav-bar-event.js'
import { HollowDotsSpinner } from "epic-spinners";
import firebase from 'firebase/app';
import 'firebase/messaging'
require("firebase/messaging");
require("firebase/database");
export default {
    name: 'App',
    mixins:[UserServices],
    data(){
        return{
            fcmToken: "",
            firebaseServerKey: "AAAAbYnlazc:APA91bHIjq3SWC3cEyTPmxipH3jg_HDG5DDksHOZbpCbi4o9ZxU6Jm259Fdf-URMogP5w_ebYvghDd7HqpPcNZud3FcSqo_RlAK4vK0tsoA0hGxQiktSFFUlrWtmHVf4hsv2hDSUQztZ",
            loadingGif: true,
            showaccess:false,
            status_code: null,
            loggedIn: false,
            otpVerified: false,
            registered: true,
            register: false,
            isTablet: false,
            isIpad: false,
            loadingApp: false,
            mobileView: false,
            timeoutId: null,
            timeoutInMiliseconds: 0,
            stop_auto_logout: false,
            isTabActive: false,
            user_profile_pic: ''
        }
    },
    components:{
        Navbar,
        Foot,
        Menubar,
        AccessPage,
        Notify,
        HollowDotsSpinner
    },
    methods:{
        // Geting Logged In User and Set Token
        getLoggedInUser() {
            console.log(window.location.pathname)
            this.$http.get(global.ADMIN_API + "/auth/get_loggedin_user").then(response => {
                if (response.data.status_id == 1) {
                    this.loggedIn = true;
                    this.$http.defaults.headers.common.Authorization = this.$cookie.get('Token');
                    this.$store.commit('setSessionId', response.data.response.session_id)
                    if(response.data.response.login_id) {
                        let email_ids_for_pin_login = localStorage.getItem('email_ids_for_pin_login') ? JSON.parse(localStorage.getItem('email_ids_for_pin_login')) : [];
                        if(email_ids_for_pin_login.includes(response.data.response.login_id)) {

                        } else {
                            email_ids_for_pin_login.push(response.data.response.login_id);
                            localStorage.setItem('email_ids_for_pin_login', JSON.stringify(email_ids_for_pin_login));
                        }
                        // localStorage.setItem('email_ids_for_pin_login')
                    }
                    if (response.data.response.is_verified) {
                        this.$store.commit('setloggedInUserCompanyId', response.data.response.company_id);
                        this.otpVerified = true;
                        this.user_profile_pic = response.data.response.profile_pic
                        this.getUser()
                    } else {
                        this.$router.push("/verifyOtp");
                        this.loadingGif = false;
                    }
                } else {
                    this.loggedIn = false;
                    this.loadingGif = false;
                    let routePath = this.$route.path.split("/")[1];
                    switch (routePath) {
                        case "register":
                        case "registerInvited":
                            break;
                        default:
                            console.log("load test pos 1")
                            window.location.href = global.POS_URL;
                    }
                }
            }).catch(error => {
                // if (error.response.status == 403) {
                    this.loadingGif = false;
                    this.$cookie.delete("Token", {
                        domain: window.location.hostname
                    });
                    this.$cookie.delete("Token", {
                        domain: 'digicollect.com'
                    });
                    var res = document.cookie;
                    var multiple = res.split(";");
                    for(var i = 0; i < multiple.length; i++) {
                        var key = multiple[i].split("=");
                        document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                    }
                    console.log("load test pos 2")
                    setTimeout(() => {
                        window.location.href = global.POS_URL + '/login';
                    }, 500);
                // }
            });
        },
        handleConnectivityChange(status) {
            this.$log.debug(status)
        },
        registerInterceptors() {
            this.$http.interceptors.request.use(
                config => {
                    if (this.$cookie.get('Token')) {
                        config.headers.common.Authorization = this.$cookie.get(
                            'Token'
                        )
                    }
                    return config
                },
                error => {
                    return Promise.reject(error)
                }
            )
            this.$http.interceptors.response.use(undefined, err => {
                // console.log(err.response)
                // console.log(err.response.config.url)
                if(err.response.status == '403') {
                    this.$cookie.delete("Token");
                    this.$cookie.delete('Token', {
                        domain: 'digicollect.com'
                    });
                    this.$cookie.delete('Token', {
                        domain: '.digicollect.com'
                    });
                    this.$cookie.delete('Token', {
                        domain: '.digicollect.com',
                        path: '/'
                    });
                    this.$cookie.delete('Token', {
                        domain:document.location.hostname,
                        path:document.location.pathname
                    });
                    this.$cookie.delete('Token', {
                        domain:document.location.hostname,
                    });
                    this.$cookie.delete('Token', {
                        path:document.location.pathname
                    });
                    this.$cookie.delete('Token', {
                        path: '/'
                    });
                    console.log('deleting Token')
                    var res = document.cookie;
                    var multiple = res.split(";");
                    for(var i = 0; i < multiple.length; i++) {
                        var key = multiple[i].split("=");
                        document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                    }
                    setTimeout(() => {
                        window.location.href = global.POS_URL + '/login';
                    }, 500);
                }
                else {
                    // this.$log.error(err)
                    return Promise.reject(err)
                }
            })
        },
        setCookie(days, token) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = "Token" + "=" + (token) + expires + ";domain=.digicollect.com; path=/";
        },
        async getUser() {
            this.$http.get("/get_loggedin_user").then(response => {
                console.log(response.data)
                if (response.data.status_id == 1) {
                    let userDetails = response.data.user_details
                    let newLoggedInUser = {
                        access_change_discount: userDetails.access_change_discount,
                        access_remove_item: userDetails.access_remove_item,
                        auto_logout: userDetails.auto_logout,
                        auto_logout_mins: userDetails.auto_logout_mins,
                        hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                        show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                        print_receipt_by_default: userDetails.print_receipt_by_default || false,
                        uid: userDetails.user_id,
                        full_name: userDetails.full_name,
                        email: userDetails.email,
                        profile_pic: this.user_profile_pic,
                        actions: userDetails.actions,
                        is_setup_done: userDetails.is_setup_done,
                        isAdmin: userDetails.user_role === 'default_admin',
                        is_authorized: userDetails.is_authorized,
                        is_loaded: true,
                        has_order: userDetails.has_order,
                        outlet: userDetails.outlet,
                        business_day_start_time:
                        userDetails.business_day_start_time,
                        role: userDetails.user_role,
                        outlet_type: userDetails.outlet_type,
                        pin: userDetails.pin,
                        is_cashier: userDetails.is_cashier,
                        cashier_session: userDetails.cashier_session || null,
                        service_type: userDetails.service_type || null,
                        enable_fast_checkout: userDetails.enable_fast_checkout,
                        enable_double_receipts: userDetails.enable_double_receipts,
                        enable_course_firing: userDetails.enable_course_firing,
                        default_deliver_together: userDetails.default_deliver_together,
                        outlet_id: userDetails.outlet_id,
                        configure_payment_device:userDetails.configure_payment_device,
                    }
                    if(!userDetails.outlet_id){
                        this.$router.push("/customermanage")
                        this.loadingGif = false
                    }
                    else{
                        this.loadingGif = false
                    }
                    this.loadingGif = false
                    // if (userDetails.pos_token && !newLoggedInUser.isAdmin) {

                    // }
                    console.log(window.location.hostname)
                    if(window.location.hostname == 'testpos.digicollect.com' || window.location.hostname == 'pos.digicollect.com') {
                        this.$cookie.delete("Token");
                        this.$cookie.delete('Token', {
                            domain: 'digicollect.com'
                        });
                        this.$cookie.delete('Token', {
                            domain: window.location.hostname
                        });
                    }
                    
                    this.setCookie(7, userDetails.pos_token)
                    // this.$cookie.set('Token', userDetails.pos_token, {
                    //     // expires: 1,
                    //     domain: window.location.hostname
                    // })
                    this.$http.defaults.headers.common.Authorization = this.$cookie.get('Token');
                    // this.registerInterceptors()
                    EventBus.$emit('updateHomeScreen')
                    console.log(newLoggedInUser)
                    this.$store.commit('setLoggedInUser', newLoggedInUser)
                    if (userDetails.is_cashier && userDetails.cashier_session){
                        EventBus.$emit('updateBills', true)
                    }
                    if(!this.mobileView){
                        this.sendNotifications()
                    }
                }
                else{

                }
            }).catch((error) => {
                if(error.hasOwnProperty('response')){
                    if(error.response.hasOwnProperty('data')){
                        if(error.response.data.reason == "Table Doesn't Have a Order!" && window.location.pathname.startsWith('/view_customer_order')){
                            this.$toasted.global.error("Table Doesn't Have a Order!")
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: window.location.hostname
                            });
                            setTimeout(() => {
                                window.location.href = localStorage.getItem("set_location")
                            }, 3000);
                        }
                        else if(error.response.data.reason == "Table Doesn't Have a Order!" && window.location.pathname.startsWith('/place_order')){
                            this.$toasted.global.error("Table Doesn't Have a Order!")
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: window.location.hostname
                            });
                            setTimeout(() => {
                                window.location.href = localStorage.getItem("set_location")
                            }, 3000);
                        }
                        else if(error.response.data.reason == "No Item Added" && window.location.pathname.startsWith('/place_order')){
                            this.$toasted.global.error("No Item Added")
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: window.location.hostname
                            });
                            setTimeout(() => {
                                window.location.href = localStorage.getItem("set_location")
                            }, 3000);
                        }
                        else if(error.response.data.reason == "No Item Added" && window.location.pathname.startsWith('/view_customer_order')){
                            this.$toasted.global.error("No Item Added")
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: window.location.hostname
                            });
                            setTimeout(() => {
                                window.location.href = localStorage.getItem("set_location")
                            }, 3000);
                        }
                    }
                }
                // window.location.href = process.env.AUTH_URL + 'apps'
            })
        },
        async loadOtp(){
            let params = {
                account_id: this.$route.query.aid,
                table_id: this.$route.query.tid,
                outlet_id: this.$route.query.oid
            }
            let response = await this.$http.post("/b2c/login/otp", params)
            // If the Order isn't yet placed, go to place order else go to my order screen!
            if(response.data.hasOwnProperty('go_to_my_order') && response.data.go_to_my_order == true && this.$cookie.get("Token")){
                window.location.href = global.POS_URL+`/view_customer_order/`+response.data.order_id;
                console.log("My Order")
            }
            else if(response.data.hasOwnProperty('go_to_my_order') && response.data.go_to_my_order == false && this.$cookie.get("Token")){
                window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&table_id=${this.$route.query.tid}&order_id=${response.data.order_id}`;
                console.log("Place Order")
            }
            else if(response.data.status_id == 0 && !response.data.hasOwnProperty('delete_token')){
                // this.$toasted.global.error(response.data.hasOwnProperty('reason') ? response.data.reason : response.data.message)
            }
            // window.location.href = global.POS_URL+`/view_customer_order/`+response.data.order_id;
        },
	  	load() {
			if(this.$cookie.get('Token',{domain: window.location.hostname})) {
				this.$http.defaults.headers.common.Authorization = this.$cookie.get('Token');
                if(this.$route.query.outlet_id && this.$route.query.table_id && this.$route.query.order_id){
                    if(this.$route.query.hasOwnProperty('from_edit')) {
                        this.$router.push(`/place_order?outlet_id=${this.$route.query.outlet_id}&table_id=${this.$route.query.table_id}&order_id=${this.$route.query.order_id}&from_edit=${this.$route.query.from_edit}`);
                    } else {
                        this.$router.push(`/place_order?outlet_id=${this.$route.query.outlet_id}&table_id=${this.$route.query.table_id}&order_id=${this.$route.query.order_id}`);
                    }
                }
                else if(window.location.pathname.startsWith('/place_order')){
                    if(window.location.pathname.startsWith('/place_order/payment_screen')){

                    } else {
                        localStorage.setItem('is_b2c', false);
                    }
                }
                else {
                    if(window.location.pathname.startsWith('/view_customer_order')){
                        localStorage.setItem('is_b2c', false);
                    }
                    else{
                        if(this.$cookie.get("Token") && window.location.pathname.startsWith('/guestlogin')){
                            if(this.$route.query.hasOwnProperty('tid')){
                                this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&tid=${this.$route.query.tid}&b2c=${this.$route.query.b2c}`);
                                localStorage.setItem('is_b2c', this.$route.query.b2c)
                            }
                            else if(this.$route.query.hasOwnProperty('delivery_company')){
                                this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&order_type=${this.$route.query.order_type}&delivery_company=${this.$route.query.delivery_company}&b2c=${this.$route.query.b2c}`);
                                localStorage.setItem('is_b2c', this.$route.query.b2c)
                            }
                        }
                        else if(this.$cookie.get("Token")){
                            if(this.$route.path == '/'){
                                this.$router.push('/').catch(()=>{});
                                localStorage.setItem('is_b2c', false);
                            }
                        }
                    }
                }
			} else {
                console.log("load test pos")
				window.location.href = global.POS_URL;
                localStorage.setItem('is_b2c', false);
            }
            if(window.location.pathname.startsWith('/place_order')){
                this.getUser();
            }
            else if(window.location.pathname.startsWith('/view_customer_order')){
                this.getUser();
            }
            else{
                if(this.$cookie.get("Token") && window.location.pathname.startsWith('/guestlogin')){
                    console.log("load")
                    this.getUser();
                    setTimeout(() => {
                        this.loadOtp()
                    }, 500);
                }
                else if(this.$cookie.get("Token") && !window.location.pathname.startsWith('/guestlogin')){
                    this.getLoggedInUser();
                }
            }
	  	},
        scrollToTop() {
			this.$nextTick(function(){
                this.$refs.containerDiv.scrollTop = 0;
                window.scrollTo(0,0);
			})
		},
        scrollToTopPhone() {
			this.$nextTick(function(){
                this.$refs.containerDiv.scrollTop = 800;
                window.scrollTo(800,0);
			})
        },
        subscribeTokenToTopic(token) {
            let topic = this.loggedInUser.outlet_id + (this.loggedInUser.role == 'default_manager' ? 'manager' : 'waiter')
            fetch('https://iid.googleapis.com/iid/v1/'+this.fcmToken+'/rel/topics/'+topic, {
                method: 'POST',
                headers: new Headers({
                'Authorization': 'key='+this.firebaseServerKey
                })
            }).then(response => {
                if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                }
                console.log('Subscribed to "'+topic+'"');
            }).catch(error => {
                console.error(error);
            })
        },
        async paymentUnSubscribe(transaction_ref_id) {
            let topic = transaction_ref_id
            let params = {
                "to": `/topics/${topic}`,
                "registration_tokens": [this.fcmToken]
            }
            fetch('https://iid.googleapis.com/iid/v1:batchRemove',{
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'key='+this.firebaseServerKey
                }),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                }
                console.log('UnSubscribed to "'+topic+'"');
            }).catch(error => {
                console.error(error);
            })
        },
        subscribeTokenToOrder(token) {
            let topic = this.loggedInUser.outlet_id
            fetch('https://iid.googleapis.com/iid/v1/'+this.fcmToken+'/rel/topics/'+topic, {
                method: 'POST',
                headers: new Headers({
                'Authorization': 'key='+this.firebaseServerKey
                })
            }).then(response => {
                if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                }
                console.log('Subscribed to "'+topic+'"');
            }).catch(error => {
                console.error(error);
            })
        },
        async sendNotifications() {
            const config = {
                apiKey: "AIzaSyBWEqLS-P6JjwKcisUYNAgZmpcL--u09qA",
                authDomain: "digicrm-8aea4.firebaseapp.com",
                databaseURL: "https://digicrm-8aea4.firebaseio.com",
                projectId: "digicrm-8aea4",
                storageBucket: "digicrm-8aea4.appspot.com",
                messagingSenderId: "470464949047",
                appId: "1:470464949047:web:50022a33345733340a88b2",
                measurementId: "G-H2W1YNW57V"
            };
            let fire = firebase.initializeApp(config);

            const messaging = firebase.messaging();
            Notification.requestPermission().then(function(permission) { console.log('permiss', permission)});
            try {
                const oldToken = this.fcmToken;
                const currentToken = await messaging.getToken({ vapidKey: "BAqQfu09znIYuUNxsjkx3s96hmo0ooPo7vrPAoGqeB0j0iBPSL0iKyOM2BN4bx6VRuAhR9BplPzsPyOgKlXgDHY" });
                this.fcmToken = currentToken;
                let param = {
                    fcm_token: this.fcmToken,
                    fcm_server_key: this.firebaseServerKey
                }
                this.$store.commit('setFCMDetails', param)
                if (oldToken != this.fcmToken) {
                axios.put(global.ADMIN_API + "/internal/fcm/token", { token: this.fcmToken, session_id: this.session_id })
                    .then(res => {
                    if (res.data.status_id == 1) {
                        if(this.loggedInUser.role != 'default_order_receiver' && !this.mobileView){
                            this.subscribeTokenToTopic()
                        }
                        let is_b2c_flow = localStorage.getItem('is_b2c');
                        console.log("is_b2c_flow", is_b2c_flow)
                        if(is_b2c_flow == 'false' || is_b2c_flow == false){
                            this.subscribeTokenToOrder();
                        }
                    }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                    // if(this.loggedInUser.role != 'default_order_receiver' && !this.mobileView){
                    //     this.subscribeTokenToTopic()
                    // }
                    // let is_b2c_flow = localStorage.getItem('is_b2c');
                    // console.log("is_b2c_flow", is_b2c_flow)
                    // if(is_b2c_flow == 'false' || is_b2c_flow == false){
                    //     this.subscribeTokenToOrder();
                    // }
                }
            } catch (err) {
                console.error("Unable to retrieve token.", err);
            }
            messaging.onMessage(async payload => {
                console.log(payload)
                let body = payload.data
                if(this.loggedInUser.outlet) {
                    if(payload.notification.title == 'New Orders Available'){
                        // console.log(payload)
                        if(this.$route.path.startsWith('/place_order')) {
                            this.$toasted.show('New Orders Available',{
                                theme: "toasted-primary",
                                position: "top-center",
                                className: "fw-600",
                                duration: 10000,
                            });
                        } else {
                            this.$toasted.show('New Orders Available',{
                                theme: "toasted-primary",
                                position: "top-center",
                                className: "fw-600",
                                duration: 10000,
                                action : {
                                    text : 'Update',
                                    onClick : () => {
                                        if(this.$route.path != '/manageorder'){
                                            this.$router.push("/manageorder")
                                        }
                                        else{
                                            this.scrollToTop()
                                            EventBus.$emit("update_order")
                                        }
                                    }
                                }
                            });
                        }
                    }
                    else if(payload.notification.title == 'payments'){
                        console.log(body)
                        this.paymentUnSubscribe(body.transaction_id);
                        setTimeout(() => {
                            EventBus.$emit("payment_success_failure", body)
                        }, 1000);
                        setTimeout(() => {
                            EventBus.$emit("close_modal", "paymentModal")
                        },3000);      
                    }
                    else{
                        this.$toasted.show(payload.notification.title,{
                            theme: "toasted-primary",
                            position: "top-center",
                            className: "fw-600",
                            duration: 10000,
                            action : {
                                text : 'Click',
                                onClick : () => {
                                    // console.log(body)
                                    if(body.goto == "approval_listing"){
                                        if(this.$route.path == '/pendingapproval') {
                                            EventBus.$emit("update_approval_listing")
                                        }
                                        else{
                                            this.$router.push("/pendingapproval")
                                        }
                                    }
                                    else if(body.goto == "summary"){
                                        if(this.$route.path == '/approvalstatus') {
                                            EventBus.$emit("update_approval_status_listing_show_summary", body.order_id);
                                        }
                                        else{
                                            this.$router.push("/approvalstatus")
                                            setTimeout(() => {
                                                EventBus.$emit("update_approval_status_listing_show_summary", body.order_id);
                                            }, 500);
                                        }
                                    }

                                    else{
                                        let orderId = body.order_id
                                        this.$router.push({
                                            name: 'TakeOrderNew',
                                            params: {
                                                orderId,
                                                editOrder: false
                                            }
                                        })
                                        setTimeout(() => {
                                            let param = {
                                                order_id: body.order_id,
                                                editOrder: false,
                                                customer_id: body.customer_id,
                                                items_count: body.items_count,
                                                for: 'notification'
                                            }
                                            EventBus.$emit("update_checkOut",param)
                                        }, 500);
                                    }
                                }
                            }
                        })
                    }
                }
            })
            const vm = this;
            messaging.onTokenRefresh().then(async function(permission) {
                const refreshedToken = await messaging.getToken();
                console.log("Token refreshed.");
                vm.fcmToken = refreshedToken;
            });
        },
        async unSubscribeTokenToTopic(token) {
            let topic = this.loggedInUser.outlet_id + (this.loggedInUser.role == 'default_manager' ? 'manager' : 'waiter')
            let params = {
                "to": `/topics/${topic}`,
                "registration_tokens": [this.fcmDetails.fcm_token]
            }
            fetch('https://iid.googleapis.com/iid/v1:batchRemove',{
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'key='+this.fcmDetails.fcm_server_key
                }),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
                }
                console.log('UnSubscribed to "'+topic+'"');
            }).catch(error => {
                console.error(error);
            })
        },
        async logout() {
            this.unSubscribeTokenToTopic()
            setTimeout(async () => {
                await axios.get(global.ADMIN_API + '/auth/logout').then((resp) => {
                    if (resp.data.status_id == 1) {
                        this.$cookie.delete("Token");
                        this.$cookie.delete('Token', {
                            domain: 'digicollect.com'
                        });
                        this.$cookie.delete('Token', {
                            domain: '.digicollect.com'
                        });
                        this.$cookie.delete('Token', {
                            domain: '.digicollect.com',
                            path: '/'
                        });
                        this.$cookie.delete('Token', {
                            domain:document.location.hostname,
                            path:document.location.pathname
                        });
                        this.$cookie.delete('Token', {
                            domain:document.location.hostname,
                        });
                        this.$cookie.delete('Token', {
                            path:document.location.pathname
                        });
                        this.$cookie.delete('Token', {
                            path: '/'
                        });
                        var res = document.cookie;
                        var multiple = res.split(";");
                        for(var i = 0; i < multiple.length; i++) {
                            var key = multiple[i].split("=");
                            document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                        }
                        if(this.loggedInUser.role == 'customer'){
                            window.location.href = localStorage.getItem("set_location")
                        }
                        else{
                            window.location.href = global.POS_URL;
                        }
                        // sessionStorage.removeItem("receipt_printer")
                        // sessionStorage.removeItem("invoice_printer")
                        // sessionStorage.removeItem("categories_printer")

                    }
                }).catch((err) => {
                    this.$cookie.delete("Token");
                    this.$cookie.delete('Token', {
                        domain: 'digicollect.com'
                    });
                    this.$cookie.delete('Token', {
                        domain: '.digicollect.com'
                    });
                    this.$cookie.delete('Token', {
                        domain: '.digicollect.com',
                        path: '/'
                    });
                    this.$cookie.delete('Token', {
                        domain:document.location.hostname,
                        path:document.location.pathname
                    });
                    this.$cookie.delete('Token', {
                        domain:document.location.hostname,
                    });
                    this.$cookie.delete('Token', {
                        path:document.location.pathname
                    });
                    this.$cookie.delete('Token', {
                        path: '/'
                    });
                    var res = document.cookie;
                    var multiple = res.split(";");
                    for(var i = 0; i < multiple.length; i++) {
                        var key = multiple[i].split("=");
                        document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                    }
                    if(this.loggedInUser.role == 'customer'){
                        window.location.href = localStorage.getItem("set_location")
                    }
                    else{
                        window.location.href = global.POS_URL;
                    }
                    sessionStorage.removeItem("receipt_printer")
                    sessionStorage.removeItem("invoice_printer")
                    sessionStorage.removeItem("categories_printer")
                })
            }, 1000);
        },
        CheckIdleTime() {
            if(this.stop_auto_logout || this.isTablet) {
                return;
            } else {
                this.timeoutInMiliseconds++;
                var oPanel = document.getElementById("full-screen");
                if (oPanel)
                    console.log(this.timeoutId - this.timeoutInMiliseconds);
                if (this.timeoutInMiliseconds >= this.timeoutId) {
                    this.logout()
                }
            }
        },
        preventBack() {
            window.history.forward();
        },
        autoLogout() {
            this.timeoutId = this.loggedInUser.auto_logout_mins*60; //seconds
            this.timeoutInMiliseconds = 0;
            if(this.$cookie.get("Token")){
                document.addEventListener('mousemove', e => {
                    this.timeoutId = this.loggedInUser.auto_logout_mins*60;
                    this.timeoutInMiliseconds = 0;
                })
                document.addEventListener('keypress', e => {
                    this.timeoutId = this.loggedInUser.auto_logout_mins*60;
                    this.timeoutInMiliseconds = 0;
                })
                if(this.loggedInUser.auto_logout == true && !this.mobileView) {
                    window.setInterval(this.CheckIdleTime, 1000);
                }
            }
        }
    },
    computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser
        },
        session_id() {
			return this.$store.getters.session_id
		},
        userSettings() {
            return this.$store.state.store.userSettings
        },
        getGeneralSettings() {
            return this.$store.state.store.getGeneralSettings
        },
        crmDetails() {
            return this.$store.state.store.crmDetails
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
	},
    watch: {
        '$route' (to,from) {
            if (to.path != "/register" && from.path == "/login" && !this.$cookie.get("Token")) {
                if (window.location.pathname.startsWith('/guestlogin')) {
                    // this.$router.push("/login?redirect_to=" + window.location.href);
                    // this.$router.push(window.location.pathname);
                    if(this.$route.query.aid == undefined || this.$route.query.aid == ''  || this.$route.query.aid == null){
                        this.$router.push("/login");
                        localStorage.setItem('is_b2c', false);
                    } else {
                        if(this.$route.query.hasOwnProperty('tid')){
                            this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&tid=${this.$route.query.tid}&b2c=${this.$route.query.b2c}`);
                            localStorage.setItem('is_b2c', this.$route.query.b2c)
                        }
                        else if(this.$route.query.hasOwnProperty('delivery_company')){
                            this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&order_type=${this.$route.query.order_type}&delivery_company=${this.$route.query.delivery_company}&b2c=${this.$route.query.b2c}`);
                            localStorage.setItem('is_b2c', this.$route.query.b2c)
                        }
                        // this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&tid=${this.$route.query.tid}&b2c=${this.$route.query.b2c}`);
                    }
                } else if(window.location.pathname.startsWith('/payments/scan_and_pay')) {
                    if(this.$route.query.ot_id == undefined || this.$route.query.ot_id == null || this.$route.query.ot_id == '' || this.$route.query.or_id == '' || this.$route.query.or_id == null || this.$route.query.or_id == undefined || this.$route.query.token == null || this.$route.query.token == '' || this.$route.query.token == undefined) {
                        this.$router.push("/login");
                        localStorage.setItem('is_scanandpay', false)
                    } else {
                        this.$router.push(`/payments/scan_and_pay/?ot_id=${this.$route.query.ot_id}&or_id=${this.$route.query.or_id}&total=${this.$route.query.total}&t_id=${this.$route.query.t_id}&token=${this.$route.query.token}&scanandpay=${true}`).catch((err)=>{
                            console.log(err)
                        });
                        localStorage.setItem('is_scanandpay', true)
                    }
                } else {
                    this.$router.push("/login");
                    localStorage.setItem('is_b2c', false);
                }
            }
        },
        "$route.path": function (path) {
            if (this.$cookie.get("Token") && path == '/register') {
                this.otpVerified ? this.$router.push("/") : this.$router.push("/verifyOtp");
                localStorage.setItem('is_b2c', false);
                localStorage.setItem('is_scanandpay', false)
            }
            if (this.$cookie.get("Token") && path == '/verifyOtp') {
                this.otpVerified ? this.$router.push("/") : this.$router.push("/verifyOtp");
                localStorage.setItem('is_b2c', false);
                localStorage.setItem('is_scanandpay', false)
            }
        },
    },
	async mounted() {
         if (this.$cookie.get("Token")) {
            if(window.location.pathname.startsWith('/payments/scan_and_pay')) {
                if(this.$route.query.ot_id == undefined || this.$route.query.ot_id == null || this.$route.query.ot_id == '' || this.$route.query.or_id == '' || this.$route.query.or_id == null || this.$route.query.or_id == undefined || this.$route.query.token == null || this.$route.query.token == '' || this.$route.query.token == undefined) {
                    this.$router.push("/");
                    localStorage.setItem('is_scanandpay', false)
                } else {
                    this.$router.push(`/payments/scan_and_pay/?ot_id=${this.$route.query.ot_id}&or_id=${this.$route.query.or_id}&total=${this.$route.query.total}&t_id=${this.$route.query.t_id}&token=${this.$route.query.token}&scanandpay=${true}`).catch((err)=>{
                        console.log(err)
                    });
                    localStorage.setItem('stored_previous_token_when_scan_and_pay', JSON.stringify(this.$cookie.get("Token")))
                    localStorage.setItem('is_scanandpay', true)
                }
            } else {
                localStorage.setItem('is_b2c', false);
                if (this.$route.path == "/login") {
                    this.$router.push("/");
                }
                this.load()
            }
        } else {
            this.loadingGif = false;
            this.register = true;
            if (window.location.pathname.startsWith('/guestlogin')) {
                if(this.$route.query.aid == undefined || this.$route.query.aid == ''  || this.$route.query.aid == null){
                    this.$router.push("/login");
                    localStorage.setItem('is_b2c', false);
                } else {
                    if(this.$route.query.hasOwnProperty('tid')){
                        this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&tid=${this.$route.query.tid}&b2c=${this.$route.query.b2c}`);
                        localStorage.setItem('is_b2c', this.$route.query.b2c);
                    }
                    else if(this.$route.query.hasOwnProperty('delivery_company')){
                        this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&order_type=${this.$route.query.order_type}&delivery_company=${this.$route.query.delivery_company}&b2c=${this.$route.query.b2c}`);
                        localStorage.setItem('is_b2c', this.$route.query.b2c);
                    }
                    // this.$router.push(`/guestlogin?aid=${this.$route.query.aid}&oid=${this.$route.query.oid}&tid=${this.$route.query.tid}&b2c=${this.$route.query.b2c}`);
                }
            } else if(window.location.pathname.startsWith('/payments/scan_and_pay')) {
                if(this.$route.query.ot_id == undefined || this.$route.query.ot_id == null || this.$route.query.ot_id == '' || this.$route.query.or_id == '' || this.$route.query.or_id == null || this.$route.query.or_id == undefined || this.$route.query.token == null || this.$route.query.token == '' || this.$route.query.token == undefined) {
                    this.$router.push("/login");
                    localStorage.setItem('is_scanandpay', false)
                } else {
                    this.$router.push(`/payments/scan_and_pay/?ot_id=${this.$route.query.ot_id}&or_id=${this.$route.query.or_id}&total=${this.$route.query.total}&t_id=${this.$route.query.t_id}&token=${this.$route.query.token}&scanandpay=${true}`).catch((err)=>{
                        console.log(err)
                    });;
                    localStorage.setItem('is_scanandpay', true)
                }
            } else {
                this.$router.push("/login");
                localStorage.setItem('is_b2c', false);
            }
        }
        let browserLang = navigator.language.split('-')[0]
        localStorage.setItem('language', browserLang)
        localStorage.getItem('language');
		EventBus.$on("scrollToTop", () => this.scrollToTop());
        EventBus.$on("scrollToTopPhone", () => this.scrollToTopPhone());
        const userAgent = navigator.userAgent.toLowerCase();
        this.isTablet = /(iphone|ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        EventBus.$on('mobile', mobile => {
            this.mobileView = mobile
        })
        setTimeout(() => {
            if(!this.mobileView){
                this.autoLogout();
            }
        }, 3000);
        if(this.$route.path == '/login'){
            history.pushState(null, null, window.location.href);
            history.back();
            window.onpopstate = () => history.forward();
        }
        setTimeout(() => {
            EventBus.$on('force_update_auto_logout', (data)=>{
                if(data == true) {
                    this.stop_auto_logout = false;
                    this.getUser()
                    this.autoLogout();
                } else {
                    this.stop_auto_logout = true;
                    this.getUser()
                    this.autoLogout();
                }
                this.$forceUpdate();
            })
        }, 2000);
        // test
        setInterval(function () {
            if (document.hasFocus()){
                this.isTabActive = true;
            }
            else{
                this.isTabActive = false;
            }
        }, 1000);
        setTimeout(() => {
            EventBus.$on('update_user_pin_settings', ()=>{
                this.getUser()
            })
        }, 2000);   
        setTimeout(() => {
            EventBus.$on('call_logged_inuser_after_scan_and_pay', ()=>{
                window.location.reload();
                localStorage.setItem('is_scanandpay', false)
            })
        }, 1000);   
    },
    destroyed(){
        EventBus.$off('scrollToTop')
        EventBus.$off('scrollToTopPhone')
        EventBus.$off('force_update_auto_logout')
        EventBus.$off('update_user_pin_settings')
        EventBus.$off('call_logged_inuser_after_scan_and_pay');
    },
    watch:{
        isIpad(){
            if(isTablet){
                this.isIpad = true
            }
            else{
                this.isIpad = false
            }
        }
    }
}
</script>
<style scoped>
.ps {
  position: relative;
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps-container {
  position: relative;
}
.access-denied-alert {
    position: relative !important;
    top: 30% !important;
    justify-content: center !important;
    display: flex !important;
    font-size: 18px;
    text-align: center;
    color: #00448b;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 220px;
    position: relative;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3, MakeShadow=true, ShadowOpacity=0.30);
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
    zoom: 1;
    border-radius: 18px;
    width: 50%;
    margin: 0 auto;
}

.access-denied-alert span {
    justify-content: center !important;
    display: block !important;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}
.access-denied-alert span img {
    width: 40px;
}
.overflow-hidden{
    overflow: hidden !important;
}
.transform-screen{
    transform: rotate(90deg);
}
#full-screen:fullscreen {
	width: 100%;
	height: 100%;
}

/* prefix for Chrome & Safari */
#full-screen:-webkit-full-screen {
	width: 100%;
	height: 100%;
}
</style>
