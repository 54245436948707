<template>
  <div>
    <modal :name="modal_product_information" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span
            v-if="this.source == 'fromsubCategory'"
            class="v-modal-dialog-title v-modal-title"
          >Sub Category Products</span>
          <span
            v-if="this.source == 'fromproductType'"
            class="v-modal-dialog-title v-modal-title"
          >Products</span>
        </div>
        <div class="v-modal-body pr-1">
          <div
            class="v-modal-layout pt-2 pr-3"
            style="max-height: 410px !important; overflow-x: hidden !important;overflow-y: scroll !important"
          >
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary pl-4"
                  v-if="products.length > 1"
                >{{ totalProducts }} Products</div>
                <div v-else class="list-title text-secondary pl-4">{{ totalProducts }} Product</div>
              </div>
              <div class="group mb-3 pl-4 pr-4">
                <input
                  v-if="this.source == 'fromsubCategory'"
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  @input="getAllProductsForSubCategory('searchdata')"
                  v-model="searchdata"
                />
                <input
                  v-if="this.source == 'fromproductType'"
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  @input="getAllProductsForProductType('searchdata')"
                  v-model="searchdata"
                />
              </div>
              <div
                v-if="totalProducts && this.source == 'fromsubCategory'"
                style="text-align: right;"
              >
                <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{totalProducts}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button
                    :disabled="count <=0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="getAllProductsForSubCategory('prev')"
                    type="button"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-left"></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button
                    :disabled="end >= totalProducts"
                    :class="{'disabled':ajaxCallInProgress}"
                    type="button"
                    v-on:click="getAllProductsForSubCategory('next')"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-right"></i>
                  </button>
                </el-tooltip>
              </div>
              <div
                v-if="totalProducts && this.source == 'fromproductType'"
                style="text-align: right;"
              >
                <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{totalProducts}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button
                    :disabled="count <=0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="getAllProductsForProductType('prev')"
                    type="button"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-left"></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button
                    :disabled="end >= totalProducts"
                    :class="{'disabled':ajaxCallInProgress}"
                    type="button"
                    v-on:click="getAllProductsForProductType('next')"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-right"></i>
                  </button>
                </el-tooltip>
              </div>
            </div>
            <div class style="border: 1px solid #a8adb4 !important;border-radius: 7.5px;">
              <table class="table productTable table-scroll">
                <thead class="products-variants-table">
                  <tr>
                    <th
                      style="width: 1%;border-radius: 7.5px 0px 0px 0px;border-collapse: separate;flex:0 0 70px !important;"
                    ></th>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Status</th>
                    <!-- <th style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;">Admin</th> -->
                  </tr>
                </thead>
                <tbody v-if="ajaxCallInProgress">
                  <tr style="height:20px!important;" class="mt-5">
                    <td colspan="6" class="text-center justify-content-center">
                      <div class="digi-table-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="products.length == 0 && !ajaxCallInProgress">
                  <tr v-if="searchdata">
                    <td colspan="6" class="text-center justify-content-center">
                      <strong>No Products Found</strong>
                    </td>
                  </tr>
                  <tr v-else>
                    <td
                      colspan="6"
                      class="text-center"
                      style="justify-content: center;padding-top: 20px!important;"
                    >
                      <strong>No Products</strong>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template v-for="product in products">
                    <tr :key="product._id">
                      <td style="flex:0 0 70px !important;width:1%;">
                        <div class="d-flex">
                          <i
                            :class="{'hide-down-arrow': !product.variant,'rotate-down-arrow':product.show}"
                            class="icon-menu-right icon-right d-inline-block pt-2"
                            style="font-size: 24px !important;width: 16px;height: 16px;line-height: 16px;"
                            @click="product.show = !product.show"
                          ></i>
                          <img
                            v-if="product.image"
                            class="avatar avatar-trans mx-2"
                            :src="product.image"
                          />
                          <avatar
                            v-else
                            :class="{ 'avatarleft': !product.variants }"
                            class="mx-2 modalavatar"
                            :username="product.name.replace(/ .*/,'')"
                            :src="product.image_url"
                            :size="40"
                            :rounded="true"
                            color="#fff"
                          ></avatar>
                        </div>
                      </td>
                      <td
                        style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;"
                        :title="product.name"
                      >{{product.name}}</td>
                      <td>{{product.quantity || '-' }}</td>
                      <td>{{product.price || '-' }}</td>
                      <td>
                        <el-switch v-model="product.status" @change="changestatus(product)"></el-switch>
                      </td>
                      <!-- <td v-if="source == 'fromsubCategory'">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit Product"
                          placement="top"
                        >
                          <button
                            @click="showEditProductBySubCategory(product._id)"
                            class="btn btn-icon companyactions actionicons"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                      </td>
                      <td v-else>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit Product"
                          placement="top"
                        >
                          <button
                            @click="showEditProduct(product._id)"
                            class="btn btn-icon companyactions actionicons"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                      </td> -->
                    </tr>
                    <template v-for="(v, i) in product.variants" v-if="product.show">
                      <tr :key="i" style="height:35px !important;">
                        <td style="flex:0 0 70px !important;width:1%;"></td>
                        <td
                          style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                        >{{v.variant_name}}</td>
                        <td>{{v.variant_quantity}}</td>
                        <td>{{v.variant_price}}</td>
                        <td>
                          <el-switch
                            v-model="v.variant_status"
                            @change="changeStatusOfvariant(v.variant_status,product._id,v.id)"
                          ></el-switch>
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <button
              type="submit"
              class="btn btn-secondary btn-smm"
              @click="hideProductInformation()"
            >CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
    <edit-product
      modal_name="product-edit"
      :productById="productById"
      @save="getAllProductsForSubCategory()"
      @saved="getAllProductsForProductType()"
    ></edit-product>
  </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event'
import EditProduct from './EditProduct'
import Avatar from 'vue-avatar'
export default {
  props: ['modal_product_information', 'productById', 'source'],
  data() {
    return {
      status: true,
      searchdata: '',
      products: [],
      editproductById: '',
      ajaxCallInProgress: false,
      productId: '',
      count: 0,
      limit: 10,
      skip: 0,
      start: 1,
      end: 0,
      totalProducts: 0
    }
  },
  methods: {
    hideProductInformation() {
      this.products = []
      this.searchdata = ''
      this.count = 0
      this.limit = 10
      this.skip = 0
      this.start = 1
      this.end = 0
      this.$modal.hide('product-information')
    },
    showEditProductBySubCategory(productId) {
      this.searchdata = ''
      EventBus.$emit('productSubCategory', {
        productId: productId,
        isProductEdit: true
      })
      this.$modal.show('product-edit')
    },
    showEditProduct(productId) {
      this.searchdata = ''
      EventBus.$emit('productProductType', {
        productId: productId,
        isProductEdit: true
      })
      this.$modal.show('product-edit')
    },
    getAllProductsForSubCategory(param) {
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'searchdata') {
        this.count = 0
      }
      this.ajaxCallInProgress = true
      this.products = []
      this.$http
        .post('/categories/get_all_products_for_a_sub_category', {
          searchdata: this.searchdata,
          sub_category_id: this.productById,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.products = response.data.response.all_products
          this.totalProducts = response.data.response.all_products_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalProducts
              ? this.count * this.limit + this.limit
              : this.totalProducts
        })
    },
    getAllProductsForProductType(param) {
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'searchdata') {
        this.count = 0
      }
      this.ajaxCallInProgress = true
      this.products = []
      this.$http
        .post('/products/get_all_products_for_product_type', {
          searchdata: this.searchdata,
          product_type_id: this.productById,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.products = response.data.response.all_products
          this.totalProducts = response.data.response.all_products_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalProducts
              ? this.count * this.limit + this.limit
              : this.totalProducts
        })
    },
    productStatusChange(product_id, status) {
      this.products.forEach(element => {
        if (element._id == product_id) {
          element.status = status
        }
      })
    },
    productVariantDynamicChange(product_id, status) {
      this.products.forEach(element => {
        if (element._id == product_id) {
          element.variants.forEach(elementIn => {
            elementIn.variant_status = status
          })
        }
      })
    },
    changestatus(product) {
      this.$http
        .post('/products/change_product_status', {
          status: product.status,
          product_id: product._id
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success('Product Status Updated Successfully')
            this.productVariantDynamicChange(product._id, res.data.response)
          } else {
            this.$toasted.global.error('Cannot Change Product Status')
          }
        })
    },
    productVariantStatusChange(product_id, status, variant_id) {
      if (element._id == product_id) {
        element.variants.forEach(elementIn => {
          if (elementIn.id == variant_id) {
            elementIn.status = status
          }
        })
      }
    },
    changeStatusOfvariant(status, product_id, variant_id) {
      this.$http
        .post('/products/change_product_variant_status', {
          status,
          product_id,
          variant_id
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(
              'Product Variant Status Updated Successfully'
            )
            this.productStatusChange(product_id, res.data.response)
          } else {
            this.$toasted.global.error('Cannot Change Product Variant Status')
          }
        })
      this.productVariantStatusChange(status, product_id, variant_id)
    }
  },
  components: {
    EditProduct,
    Avatar
  },
  mounted() {
    EventBus.$on('subcategoryProductDetails', productById => {
      if (this.source == 'fromsubCategory') {
        this.ajaxCallInProgress = true
        this.products = []
        this.$http
          .post('/categories/get_all_products_for_a_sub_category', {
            searchdata: this.searchdata,
            sub_category_id: productById,
            skip: this.count * this.limit,
            limit: this.limit
          })
          .then(response => {
            this.ajaxCallInProgress = false
            this.products = response.data.response.all_products
            this.totalProducts = response.data.response.all_products_count
            this.start = this.count * this.limit + 1
            this.end =
              this.count * this.limit + this.limit < this.totalProducts
                ? this.count * this.limit + this.limit
                : this.totalProducts
          })
      }
    }),
      EventBus.$on('productTypeProductDetails', productById => {
        if (this.source == 'fromproductType') {
          this.ajaxCallInProgress = true
          this.products = []
          this.$http
            .post('/products/get_all_products_for_product_type', {
              searchdata: this.searchdata,
              product_type_id: productById,
              skip: this.count * this.limit,
              limit: this.limit
            })
            .then(response => {
              this.ajaxCallInProgress = false
              this.products = response.data.response.all_products
              this.totalProducts = response.data.response.all_products_count
              this.start = this.count * this.limit + 1
              this.end =
                this.count * this.limit + this.limit < this.totalProducts
                  ? this.count * this.limit + this.limit
                  : this.totalProducts
            })
        }
      })
  }
}
</script>
<style scoped>
.vue-avatar--wrapper.modalavatar {
  display: inline-block !important;
}
.avatarleft {
  margin-left: 2.8rem !important;
}
.hide-down-arrow {
  visibility: hidden;
}
.rotate-down-arrow {
  transform: rotate(90deg) !important;
}
.table td {
  font-weight: 600;
}
table.productTable tbody tr {
  border-bottom: 1px solid #a8adb4;
}
table.productTable tbody tr:last-child {
  border-bottom: none !important;
}
.table.productTable tbody {
  max-height: 250px !important;
  height: 250px !important;
}
.table.productTable thead {
  background-color: #00448b !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.table.productTable thead th {
  color: #fff !important;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
</style>