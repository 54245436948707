import Vue from 'vue';
import store from '../../store';

export const EventBus = new Vue({
  store,
  created() {
    this.$on('updateBills', () => {
      this.$http.get("/orders/get_open_and_close_bills")
        .then(res => {
          this.$store.commit('setBills', res.data);
        })
    });
  }
});
