<template>
  <div class="page-layout blank brand-manage">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div
              class="list-title d-flex"
              style="font-weight: 500;"
              v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
            >
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <a class="btn btn-secondary btn-smm" @click="showCreateBrand()">+ Add New Brand</a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  :maxlength="searchcustomer"
                  type="text"
                  class="form-control textremove holder"
                  id="inputWarning2"
                  aria-describedby="inputWarning2Status"
                  placeholder="Search..."
                  v-model="search"
                  @input="fetchSearch"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="totalBrands">
              <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{totalBrands}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :disabled="count <= 0 || ajaxCallInProgress"
                  v-on:click="count--"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :disabled="end === totalBrands || ajaxCallInProgress"
                  v-on:click="count++"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders pb-5">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/emptycart.svg" style="width:26%;margin-top:20px;" />
          </div>
          <span
            class="text-secondary font-weight-bold"
            style="font-size:30px !important;"
          >No Brands Yet !!!</span>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info" style="top: 54px!important;">BRANDS</div>
        <div
          class="page-layout carded left-sidebar"
          style="background-color: #00448b!important;padding: 10px!important;"
        >
          <div class="page-content-wrapper">
            <div class="page-content">
              <div class="demo-content">
                <table v-if="!showIllustrator" class="table table-scroll table-brand">
                  <thead>
                    <tr>
                      <th style="width:30%;">
                        <span @click="setPasskey('name')" class="brand-sorting">
                          <span
                            v-if="passedKey.key=='name' && passedKey.value== -1"
                            :class="{'hide-arrow': passedKey.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passedKey.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          BRAND NAME
                        </span>
                      </th>
                      <th style="width:30%;">
                        <span @click="setPasskey('supplier')" class="brand-sorting">
                          <span
                            v-if="passedKey.key=='supplier' && passedKey.value== -1"
                            :class="{'hide-arrow': passedKey.key != 'supplier'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passedKey.key != 'supplier'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          SUPPLIER
                        </span>
                      </th>
                      <th style="width:20%;">NUMBER OF PRODUCTS</th>
                      <th
                        style="width:10%;"
                        v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                      >Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ajaxCallInProgress">
                      <td colspan="7" class="text-center justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr v-else-if="brands.length == 0 && !showIllustrator" style="height:100%;">
                      <td v-if="search" colspan="5" class="text-center justify-content-center">
                        <strong style="display:block;">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </strong>
                      </td>
                      <td v-else colspan="4" class="text-center">
                        <strong>No Brands</strong>
                      </td>
                    </tr>
                    <tr v-else v-for="(item, index) in brands" :key="index">
                      <td
                        class="font-weight-bold"
                        :title="item.name"
                        style="width: 209px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding: 0px 0px 0px 19px !important;"
                      >{{item.name}}</td>
                      <td
                        style="width: 209px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding: 0px 0px 0px 38px !important;"
                      >{{item.supplier}}</td>
                      <td
                        style="padding:0px 12px!important;color:#00448b!important;font-weight:600 !important;"
                      >
                        <el-tooltip content="View Products" placement="top">
                          <div
                            v-if="item.product_count"
                            class="chip chipproducts"
                            @click="showProduct(item._id)"
                            :class="{'clickDisabled':item.product_count == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ item.product_count || 0 }}</div>
                            <span v-if="item.product_count > 1">Products</span>
                            <span v-else>Product</span>
                          </div>
                          <div v-else class="chip chipproducts clickDisabled">
                            <div class="circle-profile-photo prj-total-users">0</div>
                            <span>Product</span>
                          </div>
                        </el-tooltip>

                        <!-- <el-tooltip content="View Products" placement="top">
                          <span
                            v-if="item.product_count"
                            class="text-center pointer"
                            style="padding:0px 50px!important;"
                            @click="showProduct(item._id)"
                          >{{ item.product_count || 0 }}</span>
                          <span
                            v-else
                            class="text-center clickDisabled"
                            style="padding:0px 50px!important;"
                          >0</span>
                        </el-tooltip>-->
                      </td>
                      <td
                        style="padding:0px 12px!important;"
                        v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                      >
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="showEditBrand(item)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button
                            @click="confirmDelete(item._id, item.name)"
                            type="button"
                            class="btn btn-icon actionicons"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-brand modal_brand_change="brand-create" @close="hideCreateBrand"></create-brand>
    <edit-brand :brand="brand" @close="hideEditBrand"></edit-brand>
    <div>
      <product-details
        @updateSearchProduct="updateSearchProduct"
        :products="brandProducts"
        :search="searchProduct"
        :brandId="brandProductsID"
        :isBrandManage="true"
        modal_name="product-details"
      ></product-details>
    </div>
    <modal name="view-products" transition="nice-modal-fade">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">PRODUCTS</span>
        </div>
        <div class>
          <div class="v-modal-layout pt-1">
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary pl-4"
                  v-if="brandProducts.length > 1"
                >{{brandProducts.length}} Products</div>
                <div class="list-title text-secondary pl-4" v-else>{{brandProducts.length}} Products</div>
              </div>
              <div class="group mb-3 pl-4 pr-4">
                <input
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  v-model="searchProduct"
                  placeholder="Search..."
                  @input="searchProductsForBrand()"
                />
              </div>
              <div
                class="contact-item row no-gutters align-items-center multiple-heading py-sm-4"
                style="margin-left: -15px;margin-right: -15px;"
              >
                <img
                  class="avatar-img mx-4"
                  style="visibility:hidden;"
                  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/default.svg"
                />
                <div class="col multiple-heading-div ml-6">Name</div>
                <div class="col multiple-heading-div">Quantity</div>
                <div class="col multiple-heading-div">Price</div>
              </div>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5 pb-5">
            <a class="btn btn-secondary btn-smm text-uppercase" @click="hideProduct()">CLOSE</a>
          </div>
        </div>
      </div>
    </modal>
    <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
  </div>
</template>
<style>
	.brand-sorting {
		cursor: pointer;
	}
	.btn-icon:disabled {
		background: transparent !important;
	}
	.brand-manage .sorting-icon {
		cursor: pointer;
	}
	.chip.chipproducts {
		width: 115px !important;
	}
	.hide-arrow {
		visibility: hidden;
	}
	th:hover .sorting-icon {
		visibility: visible !important;
		cursor: pointer;
	}
	.table.table-scroll tbody tr {
		border-bottom: 1px solid #a8adb4;
	}
	.page-content {
		background-color: transparent !important;
	}
	.avatar {
		background: #00448b;
	}
	.avatar {
		width: 4rem;
		min-width: 4rem;
		height: 4rem;
		line-height: 4rem;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		font-size: 16px !important;
		font-weight: 600;
		text-align: center;
		color: #fff;
		background-color: #c3c3ba;
	}
	.avatar.avatar-trans {
		background-color: transparent !important;
	}
	.font-weight-bold.product-name {
		font-weight: bold !important;
		color: #00448b;
	}
	.v-select-label {
		font-size: 14px !important;
		color: #00448b !important;
	}
	.table.table-brand td {
		padding: 0px 31px !important;
	}
	.clickDisabled {
		pointer-events: none !important;
	}
</style>
<script>
	import { SweetModal } from 'sweet-modal-vue'

	import CreateBrand from './CreateBrand'
	import EditBrand from './EditBrand'
	import ProductDetails from './ProductDetails'

	import BrandServices from './mixins/brands'
	import { EventBus } from './eventBus/nav-bar-event'

	export default {
		data() {
			return {
				brand: {
					name: '',
					supplier: ''
				},
				searchcustomer: 100,
				brands: [],
				start: 1,
				end: 0,
				count: 0,
				totalBrands: 0,
				limit: 10,
				search: '',
				searchProduct: '',
				modal_msg: '',
				passedKey: {
					key: 'name',
					value: 1
				},
				brandProducts: [],
				brandProductsID: '',
				singleBrand: {},
				ajaxCallInProgress: false
			}
		},
		mixins: [BrandServices],
		components: {
			CreateBrand,
			EditBrand,
			SweetModal,
			ProductDetails
		},
		computed: {
			loggedInUser() {
				return this.$store.getters.loggedInUser
			},
			showIllustrator() {
				return !this.ajaxCallInProgress && !this.search && this.brands.length == 0
			}
		},
		watch: {
			count() {
				this.getBrands()
			},
			searchProduct() {
				this.searchProductsForBrand()
			}
		},
		methods: {
			hideEditBrand(flag) {
				if (flag) {
					this.getBrands()
				}
				this.$modal.hide('brand-edit')
			},
			showProductVariants() {
				this.$modal.show('product-details')
			},
			hideProductVariants() {
				this.searchProduct = ''
				this.$modal.hide('product-details')
			},
			showAlert() {
				this.$swal({
					title: 'Are you sure?',
					text: 'Are you sure to delete the Brand?',
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#00448b',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
				}).then(result => {
					if (result.value) {
						this.$swal('Deleted!', 'Your file has been deleted.', 'success')
					}
				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url
				this.dialogVisible = true
			},
			showCreateBrand() {
				this.$modal.show('brand-create')
			},
			hideCreateBrand(flag) {
				if (flag) {
					this.getBrands()
				}
				this.$modal.hide('brand-create')
			},
			async showProduct(id) {
				try {
					this.brandProductsID = id
					let params = { search: this.searchProduct }
					let res = await this.brandsGetIdProducts(this.brandProductsID, params)
					this.brandProducts = res.message
					this.$modal.show('product-details')
				} catch (reason) {
					// TODO: Handle Reason
				}
			},
			hideProduct() {
				this.searchProduct = ''
				this.$modal.hide('view-products')
			},
			async searchProductsForBrand() {
				try {
					let params = {
						search: this.searchProduct
					}
					let res = await this.brandsGetIdProducts(this.brandProductsID, params)
					this.brandProducts = res.message
				} catch (reason) {
					// TODO: Handle Reason
				}
			},
			showEditBrand(item) {
				let name = item.name,
					supplier = item.supplier,
					_id = item._id
				this.brand = { _id, name, supplier }
				this.$modal.show('brand-edit')
			},
			async getBrands() {
				try {
					this.ajaxCallInProgress = true
					let params = {
						skip: this.count * this.limit,
						limit: this.limit,
						search: this.search,
						sortKey: this.passedKey.key,
						sortValue: this.passedKey.value
					}
					let res = await this.brandsGet(params)
					this.brands = res.message.brands
					this.totalBrands = res.message.total
					this.start = this.count * this.limit + 1
					let temp = this.count * this.limit + this.limit
					this.end = temp < this.totalBrands ? temp : this.totalBrands
					this.ajaxCallInProgress = false
				} catch (reason) {
					this.modal_msg = reason
					this.$refs.warning_modal.open()
				}
			},
			async confirmDelete(id, name) {
				try {
					let result = await this.$swal({
						title: 'Are you sure?',
						text: 'Are you sure to delete the Brand ' + name + ' ?',
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#00448b',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					})
					if (result.value) {
						let res = await this.brandsDelete(id)
						this.$swal('Deleted!', name + ' Brand has been deleted.', 'success')
						this.checkPageCount()
					}
				} catch (reason) {
					this.modal_msg = reason
					this.$refs.warning_modal.open()
				}
			},
			setPasskey(newKey) {
				this.$set(this.passedKey, 'key', newKey)
				let value = this.passedKey.value == -1 ? 1 : -1
				this.$set(this.passedKey, 'value', value)
				this.getBrands()
				// if (this.count == 0) this.getBrands()
				// else this.count = 0
			},
			updateSearchProduct(newValue) {
				this.searchProduct = newValue
			},
			fetchSearch() {
				this.count = 0
				this.getBrands()
			},
			checkPageCount() {
				if (this.count >= 1) {
					if (this.totalBrands < this.count * this.limit + 1) {
						this.count--
					}
				}
				this.getBrands()
			}
		},
		mounted() {
			EventBus.$emit('customerdetailsLoad', false)
			EventBus.$emit('settingsLoad', false)
			EventBus.$emit('reportsLoad', false)
			EventBus.$on('getBrands', value => {
				this.getBrands()
			})
		},
		created() {
			this.getBrands()
		}
	}
</script>
