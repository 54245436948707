<template>
    <div class="page-layout blank promotions-manage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="list-title" style="font-weight: 500;">
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-left"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-right"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <a class="btn btn-secondary btn-smm" @click="showPromotions" v-show="isPromotionsActive">+ Add New Promotion</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center" style="margin:0 auto;">
                        <div class style="justify-content: space-between !important">
                            <div class="list-title pointer" style="font-weight: 500;">
                                <a class="promotionsTab" :class="{activeClass: isLoyaltyPage == true}" @click="loyaltyPage()">Loyalty</a>
                                <a class="loyaltyTab" :class="{ activeClass : isPromotionsActive == true }" @click="promotionsPage()">Promotions</a>
                                <!-- <a class="entertainmentTab">Entertainment</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <!-- <div class="d-flex ml-3 mt-1 pull-right">
                        <span class="pointer exportButton font-weight-bold pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg" />
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                        </span>
                        <span class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/pdf-file.svg" />
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Pdf</span>
                        </span>
                        <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/printer.svg" />
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                        </span>
                        </div> -->
                    </div>
                </div>
            </h2>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 hidden-md-down" v-show="isPromotionsActive">
                    <div class="breadcome-heading d-xl-inline">
                        <div class="form-group formsearch has-warning has-feedback">
                            <input
                                type="text"
                                class="form-control textremove holder"
                                id="inputWarning2"
                                aria-describedby="inputWarning2Status"
                                placeholder="Search..."
                                v-model="search_key"
                                @input="getPromotion('search_key')"
                            />
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                        </div>
                    </div>
                    <div class="pull-right mr-1" v-if="total">
                        <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{total}}</span>
                        <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                            <button :disabled="count<=0" :class="{'disabled':ajaxCallInProgress}" v-on:click="getPromotion('prev')" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                <i class="icon icon-chevron-left"></i>
                            </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Next" placement="top">
                            <button :disabled="end >= total" :class="{'disabled':ajaxCallInProgress}" v-on:click="getPromotion('next')" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                <i class="icon icon-chevron-right"></i>
                            </button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div v-show="isPromotionsActive" style="margin-top: 14px;">
                <div v-if="showIllustrator" class="row no-orders-row">
                    <div class="col-lg-12 text-center">
                        <div class="noorders">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                            <p class="text-center text-secondary fs-28 font-600 pt-5">No Promotions Yet!!!</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 2px solid #00448b;border-radius: 18px;background-color: #fff;margin-top: -12px;;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;z-index:3;">PROMOTIONS</div>
                    <div class="page-layout carded left-sidebar mt-2"
                    style="overflow: auto !important;padding-bottom: 5px;">
                    <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
                            <div class="page-content">
                                <div class="demo-content">
                                    <table class="table table-scroll">
                                        <thead>
                                            <tr>
                                            <th>
                                                <span v-on:click="setPasskey('name')" class="cursor-th" >
                                                    <span v-if="passed_key.key=='name' && passed_key.value== -1" :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span> Promotion NAME
                                                </span>
                                            </th>
                                            <th>Promotion TYPE</th>
                                            <th>Promotion Code</th>
                                            <th>Validity Start Date</th>
                                            <th>VALIDITY END DATE</th>
                                            <!-- <th>ASSIGNED TO LOYALTY</th> -->
                                            <th>ADMIN</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                                <td colspan="7" class="text-center justify-content-center">
                                                    <div class="digi-table-loader">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="promotionList.length == 0 && !ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                                <td v-if="search_key" colspan="7" class="text-center justify-content-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                                                        <br />
                                                        <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Promotions Found</p>
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(pr,index) in promotionList" :key="index">
                                            <td @click="viewppromotions(pr._id)" class="text-secondary" style="cursor:pointer !important;max-width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"
                                            :title="pr.name">
                                                <span style="padding-left: 18px !important;">{{pr.name}}</span>
                                            </td>
                                            <td>{{pr.promotion_type}}</td>
                                            <td>{{pr.code}}</td>
                                            <td>{{pr.validity.validity_start_date | moment("MM/DD/YYYY")}}</td>
                                            <td>{{pr.validity.validity_end_date | moment("MM/DD/YYYY")}}</td>
                                            <!-- <td>
                                                <button class="btn btn-icon actionicons" style="border-radius: 0px !important;">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/scm/variant.svg" class="download-icon"/>
                                                </button>
                                            </td> -->
                                            <td>
                                                <span class="badge bg-green text-white mr-3 font-600">Resend</span>
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="editPromotions(pr._id)">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <button @click="deletePromotions(pr)" type="button" class="btn btn-icon actionicons ml-3" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-delete"></i>
                                                    </button>
                                                </el-tooltip>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="isLoyaltyPage" style="margin-top: 48px;">
                <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border:2px solid #00448b;border-radius: 18px;background-color: #fff;margin-top: -12px;;left: 0;right:0;margin-left: auto;margin-right: auto;width: 240px;position: absolute;z-index:3;">LOYALTY POINT/ RATE CONVERSION</div>
                <div class="page-layout carded left-sidebar" style="background-color: #005dae!important;padding: 10px!important;display:block;">
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4 mt-6 d-flex res-settings flex-wrap">
                        <div class="col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg" alt="Vertical Cash" />
                            <div class="pt-3 pl-3" style="display: inline-flex;">
                                <el-tooltip class="item" effect="dark" content="Accumulation Rate - 1 point is earned for every" placement="top">
                                    <label class="text-secondary font-weight-bold pt-2 pr-2 loyaltyTitle whiteSpace">Accumulation Rate - 1 point is earned for every:</label>
                                </el-tooltip>
                                <input v-model.number="accumulation" :maxlength="acclength" oncopy="return false" onpaste="return false" v-on:keypress="isNumber($event)" class="form-control loyalty_input" type="text" placeholder="" />
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg" alt="Vertical Cash" />
                            <div class="pt-3 pl-3" style="display: inline-flex;">
                                <el-tooltip class="item" effect="dark" content="Redemption Rate - 1 point is redeemable for" placement="top">
                                    <label class="text-secondary font-weight-bold pt-2 pr-2 loyaltyTitle whiteSpace">Redemption Rate - 1 point is redeemable for:</label>
                                </el-tooltip>
                                <input v-model.number="redemption" :maxlength="redlength" oncopy="return false" onpaste="return false" v-on:keypress="isNumber($event)" class="form-control loyalty_input" type="text" placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-smm btn-white" @click="loyaltyupdate()" :disabled="loyality_save_api_inprogress">SAVE
                            <half-circle-spinner :animation-duration="1000" :size="20" v-if="loyality_save_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <create-promotions modal_name="promotion-create" @saved="getPromotion()"></create-promotions>
        <edit-promotion modal_name="promotion-edit" @editsaved="getPromotion()"></edit-promotion>
        <view-promotions modal_name="view-promotions"></view-promotions>
    </div>
</template>
<script>
const CreatePromotions = () => import('./CreatePromotions')
const EditPromotion = () => import('./EditPromotion')
const ViewPromotions = () => import('./ViewPromotions')
import Promotions from '../mixins/promotion'
import { EventBus } from '../eventBus/nav-bar-event'
import Swal from 'sweetalert2'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    mixins: [Promotions],
    data() {
        return {
            isPromotionsActive: true,
            isLoyaltyPage: false,
            skip:0,
            limit:10,
            passed_key:{
                key: 'name',
                value: 1
            },
            search_key:'',
            promotionList:'',
            total:0,
            start:1,
            end:0,
            count:0,
            ajaxCallInProgress:false,
            accumulation:0,
            redemption:0,
            acclength:4,
            redlength:4,
            showIllustrator:false,
            loyality_save_api_inprogress: false
        }
    },
    methods: {
        showPromotions() {
            this.$modal.show('promotion-create')
        },
        editPromotions(id) {
            EventBus.$emit('pViewEdit',id)
            this.$modal.show('promotion-edit')
        },
        deletePromotions(pr){
            Swal({
                title: 'Are you sure?',
                text:
                    'Are you sure To delete the Promotion ' +
                    pr.name +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.confirmDelete(pr)
                }
            })
        },
        /**
         * This method or function is used to delete the promotion
         * @param {_id} - _id is the String
         * @returns {response} - gets the Result
         */
        async confirmDelete(pr){
            try {
                let params = {
                    _id:pr._id
                }
                let res =  await this.$http.post('/promotions/delete_promotion',params)
                this.getPromotion()
                if(res.data.status_id == 1){
                    this.$swal({
                        title: 'Promotion Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                }else{
                    this.$swal({
                        title: res.data.reason,
                        text: '',
                        type: 'warning'
                    })
                }
            } catch (reason) {

            }
        },
        promotionsPage() {
            this.isPromotionsActive = true
            this.isLoyaltyPage = false
        },
        loyaltyPage() {
            this.isLoyaltyPage = true
            this.isPromotionsActive = false
        },
        viewppromotions(id) {
            EventBus.$emit('pView',id)
            this.$modal.show('view-promotions')
        },
        showLoyalty() {
            this.$modal.show('loyalty-create')
        },
        editLoyalty() {
            this.$modal.show('loyalty-edit')
        },
        viewloyalty() {
            this.$modal.show('view-loyalty')
        },
        loyaltyupdate(){
            if(this.accumulation == '' || this.redemption == ''){
                this.$swal({
                    title: 'Please Fill all Required Fields',
                    text: '',
                    icon: 'warning',
                    buttons: ['ok'],
                    type: 'warning'
                })
            }else{
                this.confirmloyaltyupdate()
            }
        },
        /**
         * This method or function is used to update loyalty details
         * @param {accumulation_rate} - accumulation_rate is the Integer
         * @param {redemption_rate} - redemption_rate is the Integer
         * @returns {response} - gets the Result
         */
        async confirmloyaltyupdate(){
            try {
                this.loyality_save_api_inprogress = true;
                let params = {
                   accumulation_rate:this.accumulation,
                   redemption_rate:this.redemption
                }
                let res = await this.updateLoyalty(params)
                if(res.status_id == 1){
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                }
                this.loyality_save_api_inprogress = false;
            } catch (reason) {
                this.$swal({
                        title: reason,
                        text: '',
                        type: 'warning'
                    })
                this.loyality_save_api_inprogress = false;
            }
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getPromotion()
        },
        /**
         * This method or function is used to fetch Promotions list
         * @param {search_key} - search_key is the String
         * @param {skip} - skip is the Integer
         * @param {limit} - limit is the Integer
         * @param {sort_key} - sort_key is the String
         * @param {sort_value} - sort_value is the Integer
         * @returns {response} - gets the Result
         */
        async getPromotion(param){
            this.ajaxCallInProgress = true
            if (param == 'prev') this.count--
            else if (param == 'next') this.count++
            else if (param == 'init') {
                this.count = 0
            } else if (param == 'search_key') {
                this.count = 0
            }
            try {
                this.promotionList = []
                let params = {
                    skip:this.count * this.limit,
                    limit:this.limit,
                    search_key:this.search_key,
                    sort_key:this.passed_key.key,
                    sort_value:this.passed_key.value
                }
                let res = await this.getAllPromotions(params)
                this.ajaxCallInProgress = false
                this.total = res.total_count
                this.promotionList = res.promotions
                this.start = this.count * this.limit + 1
                this.end =this.count * this.limit + this.limit <
                            this.total ?
                            this.count * this.limit + this.limit :
                            this.total
                if (this.promotionList.length == 0 && !this.ajaxCallInProgress && !this.search_key) {
                    this.showIllustrator = true
                } else {
                    this.showIllustrator = false
                }
            } catch (reason) {
                this.ajaxCallInProgress = false
                this.$toasted.global.error(reason);
            }
        },
        isNumber: function(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 &&(charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        /**
         * This method or function is used to fetch Loyalty Details
         * @returns {response} - gets the Result
         */
        async loyaltyGet(){
            try {
                let res = await this.getLoyalty()
                if(res.status_id == 1){
                    this.accumulation = res.accumulation_rate
                    this.redemption = res.redemption_rate
                }
            } catch (reason) {

            }
        }
    },
    mounted(){
        this.getPromotion()
        this.loyaltyGet()
    },
    components: {
        CreatePromotions,
        EditPromotion,
        ViewPromotions,
        HalfCircleSpinner
    }
}
</script>
<style>
@media only screen and (max-width: 1112px) and (min-width: 992px) {
  .promotionsTab {
    padding: 5px 10px 5px 10px !important;
  }
  .loyaltyTab {
    padding: 5px 20px 5px 20px !important;
  }
  .entertainmentTab {
    padding: 5px 10px 5px 10px !important;
  }
}
@media (max-width: 720px) {
  .pdfButton {
    margin-top: 0;
  }
}
.table.table-scroll-new {
  border-radius: 7.5px;
  background-color: #dee8f2;
}
.promotions-manage .table-scroll tbody {
  display: block !important;
  height: calc(100vh - 320px) !important;
  overflow: auto !important;
  max-height: initial !important;
}
.promotionsTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
  font-weight: 600;
}
.loyaltyTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 34px 5px 34px;
  font-size: 14px !important;
  font-weight: 500;
  border-left: none !important;
  cursor: pointer;
  margin-left: -5px !important;
  border-radius: 0 20px 20px 0 !important;
  font-weight: 600;
}
.entertainmentTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -7px !important;
  font-weight: 600;
}
.activeClass {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}

.exportButton {
  width: auto;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #1a9347;
  font-size: 14px;
}
.exportButton img {
  width: 13px;
}
.pdfButton img {
  width: 13px;
}
.printButton img {
  width: 13px;
}
.pdfButton {
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #e82828;
  width: 66px;
  font-size: 14px;
}
.printButton {
  width: 66px;
  font-size: 14px;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #00448b;
}
.res-settings {
  box-shadow: 0 1px 5px 0 rgba(187, 132, 132, 0.16) !important;
  background-color: #dee8f2 !important;
  margin-bottom: 16px;
  padding-bottom: 6px;
  border-radius: 8px;
}
.form-control.loyalty_input {
  display: block !important;
  width: 100% !important;
  height: 32px !important;
  padding: 7px 7px !important;
  font-size: 13px !important;
  line-height: 1.5384616 !important;
  color: #4a4c4e !important;
  border: 1px solid #00448b !important;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075) !important;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s !important;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
}
.whiteSpace {
  white-space: nowrap !important;
}

@media (max-width: 960px) {
  .loyaltyTitle {
    display: block;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
  }
}
@media (max-width: 870px) {
  .loyaltyTitle {
    display: block;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
  }
}
@media (max-width: 770px) {
  .loyaltyTitle {
    display: block;
    width: 100%;
    overflow: initial;
  }
}
</style>