<template>
	<div class="page-layout aside-enabled" id="takeorderdiv" style="background: #f5f6f9;">
		<div class="page-layout blank " style="margin-left: 0px!important;">
			<div v-if="callinProgress" class="checkoutLoader">
				<div class="LoaderPlacement">
					<div class="digi-table-loader inverse">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
			<div class="pb-10" v-else>
				<div class="checkoutLoader" v-if="loadData">
					<div class="LoaderPlacement">
						<div class="digi-table-loader inverse">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column flex-root">
					<div v-if="itemspageShow" class="page d-flex flex-column flex-column-fluid pb-5">
						<div class="d-flex flex-column flex-lg-row-fluid">
							<div class="d-flex flex-column-fluid align-items-start container-xxl">
								<div class="content flex-row-fluid">
									<div class="d-flex flex-column flex-lg-row course-table">
										<div class="d-flex flex-row-fluid flex-column">
											<div class="wrapper-course course-section d-flex flex-stack flex-wrap mb-5">
												<div class="page-title d-flex flex-row py-1 w-100">
													<h1 class="d-flex align-items-center my-1" v-if="order.type == 'Dine In'">
														<span class="text-darkblue fw-800 fs-1">COURSES :</span>
													</h1>
													<div class="d-flex flex-wrap" v-if="order.type == 'Dine In'">
														<div class="d-flex align-items-center py-1 pl-0 pr-2">
															<slick id="round_selecting_course" ref="slick" :options="slickOptionsCourses"
															@afterChange="handleAfterChange" @beforeChange="handleBeforeChange" @breakpoint="handleBreakpoint"
															@destroy="handleDestroy" @edge="handleEdge" @init="handleInit" @reInit="handleReInit"
															@setPosition="handleSetPosition" @swipe="handleSwipe" @lazyLoaded="handleLazyLoaded"
															@lazyLoadError="handleLazeLoadError" class="course-slick courseSlick  d-flex align-items-center">
																<div v-for="(course,cindex) in numberOfCourses" :key="cindex">
																	<div class="courses-no courses-no-45px me-3">
																		<div @click="selectCourseforProduct(course.course)" class="courses-no-label fs-3" :class="{'course-selected':course.areItemsAdded, 'text-white':course.areItemsAdded ,'present-course':selectedCourse == course.course}">
																			{{course.course}}
																		</div>
																	</div>
																</div>
															</slick>
															<!-- <div class="courses-no courses-no-45px me-3">
																<div class="courses-no-label fs-3">
																	2
																</div>
															</div>
															<div class="courses-no courses-no-45px me-3">
																<div class="courses-no-label course-selected fs-3">
																	3
																</div>
															</div> -->
														</div>
													</div>
													<span style="border-right: 2px solid #d5d4d4;" v-if="order.type == 'Dine In'"></span>
													<div class="flex-column flex-lg-row-auto ml-auto" :class="{'d-flex align-items-center justify-content-center mr-auto':order.type != 'Dine In'}">
														<div class="d-flex flex-wrap flex-stack pb-2" :class="{'pb-0':order.type != 'Dine In'}">
															<div class="d-flex flex-row flex-grow-1 pe-8">
																<div class="d-flex align-items-center fw-bold" style="width: 210px;" v-if="order.type == 'Dine In'">
																	<div class="text-titles">
																		TABLE NO. : 
																	</div>
																	<div class="table_no text-truncate" :title="order.tables[0]['name']" v-if="order.tables && order.tables.length != 0 && order.tables[0].name" style="width: 110px;">
																		<span>{{ order.tables[0]['name'] }}</span>
																	</div>
																	<div class="table_no text-truncate" :title="order.tables[0]" v-else-if="order.tables && order.tables[0] &&  !order.tables[0]['name']" style="width: 110px;">
																		<span>{{ order.tables[0]}}</span>
																	</div>
																	<div class="table_no" v-else>
																		<span @click="TableSearch()">SelectTable</span>
																	</div>
																	<!-- <button type="button" class="btn btn-icon actionicons cust-del ml-1" @click="TableSearch()">
																		<i class="icon icon-pencil"></i>
																	</button> -->
																	<!-- <div class="table_no" v-if="order.tables && order.tables.length != 0 && order.tables[0].name">
																		<span @click="TableSearch()">{{ order.tables[0]['name'] }}</span>
																	</div>
																	<div class="table_no" v-else-if="order.tables && order.tables[0] &&  !order.tables[0]['name']">
																		<span @click="TableSearch()">{{ order.tables[0]}}</span>
																	</div>
																	<div class="table_no" v-else>
																		<span @click="TableSearch()">SelectTable</span>
																	</div> -->
																</div>
																<div class="d-flex align-items-center fw-bold" v-if="order.type == 'Dine In'">
																	<div class="seperator">|</div>
																</div>
																<div class="d-flex align-items-center fw-bold" :class="{'w-165px':order.type == 'Dine In'}">
																	<div class="text-titles">
																		ORDER TYPE : 
																	</div>
																	<div class="order-type-selected text-uppercase">
																		{{order.type}}
																	</div>
																</div>
																<!-- <div class="d-flex align-items-end justify-content-end" :class="{'w-175px':order.type != 'Dine In'}">
																	<div class="float-right">
																		<el-dropdown trigger="click">
																			<span class="el-dropdown-link pointer ml-1" @click="loadOrderTypes(order.type)">
																				<i class="icon icon-pencil text-secondary fs-18"></i>
																			</span>
																			<el-dropdown-menu slot="dropdown">
																				<el-dropdown-item v-if="show_order_type_dropdown" >
																					<span>
																						---
																					</span>
																				</el-dropdown-item >
																				<el-dropdown-item v-else @click.native="changeOrderType(types)" v-for="(types,index) in order_types" :key="index">{{types.order_type}}</el-dropdown-item>
																			</el-dropdown-menu>
																		</el-dropdown>
																	</div>
																</div> -->
															</div>
														</div>
														<div class="d-flex flex-wrap flex-stack pt-2 tableno-ordertype" v-if="order.type == 'Dine In'">
															<div class="d-flex flex-row flex-grow-1 e-8 justify-content-between">
																<div class="d-flex align-items-center fw-bold">
																	<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" style="height: 14px;" class="pr-1"/>
																	<div class="text-titles pt-1" style="font-size: 12px !important">
																		NO. OF GUESTS : 
																	</div>
																	<div class="order-type-selected pt-1">
																		{{noOfPax}}
																	</div>
																</div>
																<div class="d-flex align-items-center fw-bold ml-8" v-if="showHideEditGuests">
																	<div class="form-check form-check-inline" style="padding-left:5px !important;">
																		<span>
																			<img class="pointer" src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" @click="decrementPax()" style="width: 22px;padding-bottom: 2px;">
																		</span>
																		<label class="form-check-label" style="padding-left: 5px !important;">
																			<input onkeypress='return event.charCode >= 48 && event.charCode <= 57' class="no-for-pax paxno" style="padding-left: 5px;" type="text" min="1" :value="no_of_pax_edit" @input="event => no_of_pax_edit = event.target.value" name="pax" v-validate="'required|numeric|min_value:1'" max="1000" onKeyUp="if(this.value>1000){this.value='1000';}else if(this.value<=0){this.value='1';}" id="nOOfPAX" />
																		</label>
																		<span class="pl-1">
																			<img class="pointer" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" @click="incrementPax()" style="width: 22px;padding-bottom: 2px;">
																		</span>
																	</div>
																	<button class="btn btn-small btn-green-small mr-1" @click="applyPax()">Apply</button>
																</div>
																<div class="d-flex align-items-end justify-content-end ml-4 w-200px" v-if="showHideEditPencil">
																	<div class="float-right">
																		<button type="button" class="btn btn-icon actionicons cust-del ml-1" @click="showInputGuest()">
																			<i class="icon icon-pencil"></i>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="d-flex flex-lg-row-fluid">
												<div class="asideLeft" style="padding-bottom: 10px;">
													<div class="hover-scroll-overlay-y my-5 my-lg-2 w-100 ps-4 ps-lg-0">
														<div class="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100">
															<div class="toolbar-header d-flex flex-stack flex-wrap" style="position: sticky;top: 0;z-index: 5;background: #fff;">
																<div class="page-title d-flex flex-column pt-1">
																	<h1 class="d-flex align-items-center mb-0">
																		<span>CATEGORIES</span>
																	</h1>
																</div>
															</div>
															<div class="">
																<div v-for="(category, category_index) in categories" :key="category._id+ ''+category_index">
																	<div class="font-weight-bold categories align-items-center text-uppercase pointer">
																		{{category.name}}
																	</div>
																	<div class="m-2">
																		<div class="menu-item menu-subcat mt-0 pl" v-for="(sub_category, sub_category_index) in category.sub_category_list" :key="sub_category._id+''+sub_category_index">
																			<div class="menu-link" :class="{'menu-selected':sub_category.selected}" @click="selectSubCategoryOption({category_id:category._id, sub_category_id:sub_category._id, category_index: category_index, sub_category_index: sub_category_index })">
																				<span class="menu-title">{{sub_category.name}}</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="new_selected_sub_category_id == '' && new_selected_category_id == ''" class="wrapper-take-order d-flex flex-column flex-row-fluid">
													<!-- <h1 class="fs-14 text-center text-secondary fw-bold mt-6">Please select a Sub Category.</h1> -->
													<div class="digi-table-loader d-flex justify-content-center" style="margin: 0 auto">
														<div></div>
														<div></div>
														<div></div>
														<div></div>
													</div>
												</div>
												<div v-else class="wrapper-take-order d-flex flex-column flex-row-fluid" style="overflow-y: auto;overflow-x: hidden;">
													<div class="content flex-column-fluid">
														<div class="pb-3" style="position: sticky;top: 0;z-index: 5;background-color: #fff;">
															<div class="toolbar-header d-flex flex-stack flex-wrap">
																<div class="page-title d-flex flex-row align-items-center justify-content-between w-100 pt-1">
																	<h1 class="d-flex align-items-center mb-0">
																		<span>PRODUCTS</span>
																	</h1>
																</div>
															</div>
															<div class="search-filter-header pt-1">
																<div class="filter-search w-100" style="max-width: 400px;">
																	<div class="d-flex align-items-center position-relative my-1">
																		<span class="svg-icon svg-icon-1 position-absolute ms-4">
																			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
																				<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
																			</svg>
																		</span>
																		<input type="text" @input="onSearchProducts" class="search-control form-control-solid w-100 ps-15 pr-8" v-model="searchdata" id="search_product"  placeholder="Search Products" />
																		<i class="icon icon-close-circle search-empty pr-7" v-if="searchdata.length" @click="clearSearchProducts"></i>
																	</div>
																</div>
																<div class="position-relative ml-auto">
																	<button class="ml-auto product-filter bg-white product-type-filter pointer my-1"
																		@click="showFilters">
																		<span class="ml-0 pr-1">Product Type Filter</span>
																		<img src="https://cdn.digicollect.com/cdn/posv2/Filter-icon.svg" class="mt-0 ml-1" style="width:20px;">
																	</button>
																	<div v-show="display_filters" class="scm-filters fadeInDown" style="position: absolute; bottom: auto; top: 100%; right: 0;max-height: 320px !important;border-radius: 8px;overflow: scroll !important;" :class="{'fadeInUp':hide_filters == true}">
																		<take-order-product-type-filter @hideFilters="hideFilters" @UpdateByFilter="UpdateByFilter" :new_selected_category_id="new_selected_category_id" :courseSelected="courseSelected" :order_id="order._id" :searchdata="searchdata" :new_selected_sub_category_id="new_selected_sub_category_id"></take-order-product-type-filter>
																	</div>
																</div>
															</div>
															<div v-if="product_type_selected_data.length" class="d-flex align-items-center border-bottom min-h-40px pt-1">
																<div class="text-darker fw-bold w-55px"> Sort by:</div>
																<div class="tagify border-0">
																	<div v-for="(product_type_selected, product_type_selected_index) in product_type_selected_data" :key="product_type_selected_index+'product_type_selected_index'" class="tagify__tag">
																		<span class="tagify__tag__removeBtn" role="button" @click="removeProductTypeFilter(product_type_selected_index)" aria-label="remove tag"></span>
																		<div class="d-flex align-items-center">
																			<div class="tagify__tag__avatar-wrap ps-0">
																				<span class="tagify__tag-text">
																					{{product_type_selected.name}}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div v-if="products.length < 1 && prod_loader">
															<div class="digi-table-loader d-flex justify-content-center" style="margin: 0 auto">
																<div></div>
																<div></div>
																<div></div>
																<div></div>
															</div>
														</div>
														<div v-else-if="products.length < 1 && !prod_loader">
															<div class="text-center">
																<img class="productData" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-product-yet.svg" alt="">
																<span class="no-prod">No Product Yet!!</span>
															</div>
														</div>
														<div v-else id="lazy-load-checkout-product" >
															<template v-for="(product, productIndex) in products">
																<div class="mansory-div product-div mt-2" :key="product.product_id">
																	<div class="card items-mansory product-card position-relative" :class="{'image-unavailable': product.is_blocked}" :key="product.product_id">
																		<!-- <div class="position-absolute" style="right: 10px; top: 10px;">
																			<el-tooltip class="item" effect="dark" content="Info" placement="top">
																				<i @click="showProductInfo(product)" class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;bottom: 5px;position: relative;"></i>
																			</el-tooltip>
																		</div> 
																		<div class="pointer" @click="wholeCardClick(productIndex)">-->
																		<div class="pointer">
																			<div class="m-1">
																				<img class="img-responsive masonry-img-items-cdn" v-if="product.image_url" :src="product.image_url" alt="Card image cap" />
																				<!-- <img class="img-responsive masonry-img-items" v-else src="https://cdn.digicollect.com/cdn/posv2/image-unavailable.svg" alt="Card image cap" /> -->
																				<div style="background-color: #242b34" v-else class="d-flex align-items-center justify-content-center masonry-img-items">
																					<span class="text-white text-center img-un">Image Unavailable</span>
																				</div>
																			</div>
																			<p class="unavailable-image" v-if="product.is_blocked">Unavailable</p>
																			<div class="card-body" style="margin-bottom: 0 !important;" :class="{'card-bodySelected':product.selected_quantity != 0}">
																				<h4 class="card-title d-flex  justify-content-center">
																					<!-- <el-tooltip class="item" effect="dark" content="Non-Veg" placement="top" v-if="product.product_type == 'Non-Veg'">
																						<img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
																					</el-tooltip>
																					<el-tooltip class="item" effect="dark" content="Veg" placement="top" v-else-if="product.product_type == 'Veg'" >
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
																					</el-tooltip>
																					<el-tooltip class="item" effect="dark" content="Egg" placement="top" v-else-if="product.product_type == 'Egg'" >
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
																					</el-tooltip>
																					<el-tooltip class="item" effect="dark" content="Pescatarian" placement="top" v-else-if="product.product_type == 'Pescatarian'">
																						<img  style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
																					</el-tooltip> -->
																					<span class="card-title-product-name product-name-card textCaps" :class="{'ml-2':product.product_type != ''}" :title="product.product_name">{{ product.product_name }}</span>
																				</h4>
																			</div>
																		</div>
																		<div class="mx-2 mt-0 mb-1">
																			<div v-if="product.pm_price >= 0 || (product.is_combo && product.formatted_pm_price)" class="border-variant product-price text-center">
																				<span>Price: {{product.formatted_pm_price}}</span>
																			</div>
																			<div class="" v-if="product.is_customisable == false">
																				<div v-if="product.selected_quantity != 0" class="d-flex align-items-center mt-2">
																					<span id="basic-addon2" class="btnqty" @click="decreaseQuantity(productIndex)"  :class="{'pointerEventsNone':disablePlus}" style="margin-right: -2px;">
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus-svg-radius.svg" class="img-add-button-svg" id="decrease_qty" />
																					</span>
																					<template>
																						<input style="line-height: 1.7;background-color: #2c96f4; border: solid 0.5px transparent !important;border-top-left-radius: 0px;border-bottom-left-radius: 0px; width: 100%; height: 28px; color: #fff !important;" readonly type="number" class="qtyinput" v-model.number="product.selected_quantity" id="decrease_qty_increase"/>
																					</template>
																					<span v-if="save_qty == false" id="basic-addon2" class="btnqty" @click="increaseQuantity(productIndex)"  :class="{'pointerEventsNone':disablePlus}" style="margin-left: -2px;">
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-svg-radius.svg" class="img-add-button-svg" id="increase_qty" />
																					</span>
																				</div>
																				<div v-else :class="{'pointerEventsNone':product.is_blocked}">
																					<div class="d-flex align-items-center mt-2" @click="addOrderItem(productIndex)">
																						<span class="text-white text-center fs-14 pointer" style="line-height: 1.7;background-color: #2c96f4; border: solid 0.5px #cdd2da;border-top-left-radius: 5px;border-bottom-left-radius: 5px; width: 100%; height: 29px;">Add</span>
																						<img style="left: -2px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-svg-radius.svg" class="img-add-button-svg position-relative" />
																					</div>
																				</div>
																			</div>
																			<div class="" v-if="product.is_customisable == true">
																				<div v-if="product.selected_quantity != 0" class="d-flex align-items-center mt-2">
																					<span id="basic-addon2" class="btnqty" @click="editCustomization(product)" style="margin-right: -2px;">
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus-svg-radius.svg" class="img-add-button-svg" id="decrease_qty"/>
																					</span>
																					<template>
																						<input style="line-height: 1.7;background-color: #2c96f4; border: solid 0.5px transparent !important;border-top-left-radius: 0px;border-bottom-left-radius: 0px; width: 100%; height: 28px; color: #fff !important;" type="text" class="qtyinput" v-model="product.selected_quantity" disabled  id="decrease_qty_increase"/>
																					</template>
																					<span id="basic-addon2" class="btnqty" @click="addCustomization(product)" style="margin-left: -2px;">
																						<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-svg-radius.svg" class="img-add-button-svg" id="increase_qty" />
																					</span>
																				</div>
																				<div v-else>
																					<span class="d-flex align-items-center mt-2 pl-2 pr-2" @click="addAddons(product)">
																						<span class="text-white text-center fs-14 pointer" style="line-height: 1.7;background-color: #2c96f4; border: solid 0.5px #cdd2da;border-top-left-radius: 5px;border-bottom-left-radius: 5px; width: 100%; height: 29px;">Add</span>
																						<img style="left: -2px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-svg-radius.svg" class="img-add-button-svg position-relative" />
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</template>
														</div>
														<!-- <div class="row ml-0 mr-0">
															<div class="col-md-4">
																<div class="d-flex flex-wrap d-grid gap-5 gap-xxl-9">
																	<div class="poscard poscard-flush flex-row-fluid p-1 pb-5 mw-100">
																		<img src="/images/brief-icon.svg" width="25" class="position-absolute icon-information" style="right:20px;z-index: 99;" alt="" @click="OpenInfo">
																		<div class="poscard-body text-center">
																			<img src="/images/img-3x.webp" class="rounded-3 pt-1 img-fluid" />
																			<div class="mb-2">
																				<div class="text-center">
																					<h4 class="card-title d-flex align-items-center justify-content-center">
																						<el-tooltip class="item" effect="dark" content="Egg" placement="top">
																							<img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
																						</el-tooltip>
																						<div title="Egg Noodles Bowl">
																							<span class="card-title-product-name textCaps text-secondary">Egg Noodles Bowl</span>
																						</div>
																					</h4>
																					<span class="text-gray-400 fw-semibold d-block fs-14 mt-n1">
																						$23.44
																					</span>
																				</div>
																				<div class="d-flex align-items-center mt-2 pl-2 pr-2">
																					<span class="text-white text-center fs-14 pointer" style="line-height: 1.7;background-color: #2c96f4; border: solid 0.5px #cdd2da;border-top-left-radius: 5px;border-bottom-left-radius: 5px; width: 100%; height: 25px;">+Add</span>
																					<img style="left: -3px;height:24px;" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg position-relative" />
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div> -->

													</div>
												</div>
											</div>
										</div>
										<div class="sidebar-cart flex-column flex-lg-row-auto w-lg-300px mb-10 mb-lg-0 ms-lg-7">
											<div class="">
												<div class="toolbar-header d-flex flex-stack flex-wrap" style="position: sticky;top: 0;z-index: 5;background-color: #fff;">
													<div class="page-title d-flex flex-column pt-1">
														<h1 class="d-flex align-items-center mb-0">
															<span>CART</span>
														</h1>
													</div>
													<div class="d-flex align-items-center py-1">
														<span class="no-of-items">{{order.itemCount}} ITEMS</span>
														<img v-if="order.itemCount == 0" src="/static/images/delete-disabled.svg" class="delete-disabled-img">
														<img v-else @click="discardOrderItems" src="https://cdn.digicollect.com/cdn/posv2/delete-whole-cart.svg" class="delete-disabled-img pointer">
													</div>
												</div>
												<div v-if="cart_data.length < 1" class="empty-cart text-center">
													<img src="https://cdn.digicollect.com/cdn/posv2/no-order.svg" class="item-img" />
													<span class="course-title font-weight-bold text-dark mr-3 align-items-center">
														Add items in the cart!
													</span>
												</div>
												<div v-else>
													<div class="overflow-auto pb-5">
														<div v-for="(cart, cart_index) in cart_data" :key="cart_index+'cart_index'" class="min-w-340px px-3 py-1">
															<div v-for="(cart_item, cart_item_index) in cart.items" :key="cart_item_index+'cart_item_index'" class="d-block border border-dashed border-gray-300 rounded min-h-50px mb-3 position-relative">
																<div class="d-flex flex-row align-items-center py-2">
																	<div class="d-flex fs-5 text-dark text-hover-primary fw-semibold">
																		<!-- <el-tooltip class="item pl-1" effect="dark" content="Pescatarian" placement="top">
																			<img style="width:13px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
																		</el-tooltip> -->
																		<span class="text-darkblue fs-13 textCaps pl-2 fw-600" style="width: 315px;">{{cart_item.product_name}}</span>
																		<div class="d-flex align-items-end justify-content-end ml-4">
																			<div class="float-right" v-if="cart_item.hasOwnProperty('modifiers') && ((cart_item.modifiers.hasOwnProperty('for_primary') && cart_item.modifiers.for_primary.length) || (cart_item.modifiers.hasOwnProperty('for_secondary') && cart_item.modifiers.for_secondary.length))">
																				<button type="button" @click="showModifierPopup(cart_item)" class="btn btn-icon actionicons cust-del ml-1">
																					<i class="icon icon-pencil"></i>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="d-flex flex-row align-items-center justify-content-between py-2 pl-1 pr-5">
																	<div class="d-flex justify-content-center" v-loading="cart_increase_decrease_in_progress">
																		<div v-if="cart_item.is_customizable" class="flex-wrap d-flex fs-13 position-relative align-items-center" style="padding-left:5px !important;">
																			<span >
																				<img class="pointer" @click="editCustomization(cart_item)" src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="width: 26px;">
																			</span>
																			<label class="pl-1 mb-0">
																				<input onkeypress='return event.charCode >= 48 && event.charCode <= 57' @change="enterCartQuantity($event, cart_item, cart_index, cart_item_index)" v-model="cart_item.quantity" class="cart-count" style="padding-left: 5px;" type="number" min="1" name="count"  />
																			</label>
																			<span class="pl-1">
																				<img class="pointer" @click="addCustomization(cart_item)" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" style="width: 26px;">
																			</span>
																		</div>
																		<div v-else class="flex-wrap fs-13 position-relative" style="padding-left:5px !important;">
																			<span>
																				<img @click="decreaseQtyFromCart(cart_item)" :class="{'pointerEventsNone':disablePlus}" class="pointer" src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="width: 26px;">
																			</span>
																			<label class="pl-1">
																				<input onkeypress='return event.charCode >= 48 && event.charCode <= 57' @change="saveQtyFromCart($event, cart_item)" v-model="cart_item.quantity" class="cart-count" style="padding-left: 5px;" type="number" min="1" name="count" />
																			</label>
																			<span class="pl-1">
																				<img class="pointer" :class="{'pointerEventsNone':disablePlus}" @click="increaseQtyFromCart(cart_item)" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" style="width: 26px;">
																			</span>
																		</div>
																	</div>
																	<div class="d-flex text-right pr-3 justify-content-end">
																		<div class="fs-13 fw-bold counted">
																			{{cart_item.total}}
																		</div>
																	</div>
																</div>
																<div class="double-arrow-icon position-absolute" :class="{'double-arrow-icon-rotate':cart_item.show_modifiers}" v-if="cart_item.hasOwnProperty('modifiers') && (cart_item.modifiers.hasOwnProperty('for_primary') || cart_item.modifiers.hasOwnProperty('for_secondary'))">
																	<img @click="showHideModifiers(cart_item)" src="https://cdn.digicollect.com/cdn/posv2/double-arrow-icon-for-cart.svg" class="pointer" />
																</div>
																<div v-if="cart_item.hasOwnProperty('modifiers') && cart_item.show_modifiers" class="d-block pb-1 pr-6">
																	<div v-if="cart_item.modifiers.hasOwnProperty('for_primary')">
																		<div v-for="(p_modifier, p_modifier_index) in cart_item.modifiers.for_primary" :key="p_modifier_index+'p_modifier_index'">
																			<div v-for="(p_modifier_item, p_modifier_item_index) in p_modifier.modifier_items" :key="p_modifier_item_index+'p_modifier_item_index'" class="d-flex flex-row align-items-center pb-1">
																				<div class="fs-5 text-dark text-hover-primary fw-semibold min-w-150px pl-4">
																					<div class="modifiers-list">
																						<span class="">
																							{{p_modifier_item.m_item_name}}
																						</span>
																					</div>
																				</div>
																				<div class="d-flex justify-content-center min-w-125px">
																					<span class="modifiers-qty">
																						{{p_modifier_item.quantity || ''}}
																					</span>
																				</div>
																				<div class="d-flex min-w-50px text-right pr-1 justify-content-end">
																					<span v-if="p_modifier_item.has_price" class="modifiers-price">
																						{{p_modifier_item.price || ''}}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div v-if="cart_item.modifiers.hasOwnProperty('for_secondary')">
																		<div v-for="(sec_modifier, sec_modifier_index) in cart_item.modifiers.for_secondary" :key="sec_modifier_index+'p_modifier_index'" class="d-flex flex-row align-items-center pb-1">
																			<div class="fs-5 text-dark text-hover-primary fw-semibold min-w-150px pl-4">
																				<div class="modifiers-list">
																					<span class="">
																						{{sec_modifier.m_item_name}}
																					</span>
																				</div>
																			</div>
																			<div class="d-flex justify-content-center min-w-125px">
																				<span class="modifiers-qty">
																					{{sec_modifier.quantity || ''}}
																				</span>
																			</div>
																			<div class="d-flex min-w-50px text-right pr-1 justify-content-end">
																				<span v-if="sec_modifier.has_price" class="modifiers-price">
																					{{sec_modifier.price || ''}}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="">
														<div class="pt-5 px-2">
															<button class="pointer product-filter d-flex justify-content-between text-dark align-items-center pl-2 pr-2 pt-1 pb-1 b-r-7 w-100" style="background-color: #b2d7fc;">
																<span class="fw-800 text-dark d-flex align-items-center fs-16">
																	Grand Total
																</span>
																<span class="text-dark fw-800 fs-16">{{order.total}}</span>
															</button>
														</div>
														<div class="d-flex mt-3 px-2">
                                                            <button class="btn btn-checkout mt-1 mr-1" :disabled="!this.loggedInUser.configure_payment_device && order.itemCount == 0" v-if="!editOrder" @click="payOrder" id="chechkout_button">
                                                                Pay
                                                                <i class="icon-arrow-right"></i>
                                                            </button>
                                                            <button class="btn btn-checkout mt-1 mr-1" :disabled="order.itemCount == 0" v-if="!editOrder" @click="placeOrder" id="chechkout_button">
                                                                Place Order
                                                                <i class="icon-arrow-right"></i>
                                                            </button>
                                                            <button class="btn btn-checkout mt-1 mr-1" v-else @click="updateOrder" id="chechkout_button">
                                                                Update Order
                                                                <i class="icon-arrow-right"></i>
                                                            </button>
															<!-- <button @click="fastCheckout" v-if="editOrder == false"
																class="pointer d-flex align-items-center bg-blue-image pl-3 pr-3 pt-1 pb-1 b-r-7 mr-1">
																<el-tooltip class="item pl-1" effect="dark" content="Fast Checkout" placement="top">
																	<span>
																		<img src="https://cdn.digicollect.com/cdn/posv2/fast-checkout.svg" />
																	</span>
																</el-tooltip>
															</button>
															<button v-if="editOrder == false" @click="fastPay" class="pointer d-flex bg-green-image align-items-center pl-4 pr-4 pt-1 pb-1 b-r-7 mr-1">
																<el-tooltip class="item pl-1" effect="dark" content="Fast Pay" placement="top">
																	<span>
																		<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-pay.svg" />
																	</span>
																</el-tooltip>
															</button>
															<button @click="showCheckout" class="pointer d-flex justify-content-between bg-orange-image align-items-center pl-2 pr-2 pt-1 pb-1 b-r-7 float-right w-100"
																:class="{'w-50':loggedInUser.role != 'customer','w-100':loggedInUser.role == 'customer'}">
																<span class="fw-800 fs-16">Checkout</span>
																<span>
																	<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkout-arrow.svg" />
																</span>
															</button> -->
														</div>
													</div>
												</div>
											</div>
											<!-- <div v-if="order.type !='Dine In' && !showselectedTable" class="px-3 pb-1 pt-2 d-flex justify-content-between" style="border-bottom:1px solid #00448b !important;">
												<span class="font-weight-bold fs-12 text-uppercase">
													Table no : 
													<span class="text-secondary"> {{order.type}}</span>
												</span>
												<span class="pull-right" @click="searchCustomer=true"></span>
												<span class="font-weight-bold fs-12  text-uppercase">
													Order Type : 
													<span class="text-secondary"> {{order.type}}</span>
												</span>
												<button type="button" class="btn btn-icon actionicons cust-del float-right" style="padding: 0rem 0rem;" id="edit_customer_take">
													<i class="icon icon-pencil"></i>
												</button>
											</div> -->
											<!-- <div class="px-3 pb-1 pt-2 justify-content-between">
												<div class="">
												<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" style="height: 12px;" class="pr-2 mt-1" />
												<span class="font-weight-bold fs-12 text-uppercase">No.of Guests : 
													<span class="text-secondary"> {{order.type}}</span>
												</span>
												<button type="button" class="btn mt-2 btn-icon actionicons cust-del float-right" style="padding: 0rem 0rem;" id="edit_customer_take"><i class="icon icon-pencil"></i></button></div>
											</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="d-flex flex-column-fluid align-items-stretch container-xxl">
							
						</div> -->
					</div>
					<product-info-new v-if="open_info_modal" modal_name="product-info" @hide-product-info="HideProductInfo"></product-info-new>

					<table-selection v-if="tableSelect" :orderedTable="order.tables" :order_id="order._id" :editOrderTable="editOrderTable" :inProgressOrder="inProgressOrder" :editTable="true" :editOrder="editOrder"></table-selection>
					<check-out @updateData="checkout" @updateCheckoutTablePax="updateCheckoutTablePax" @updateAddon="updateAddon" :callinProgress="callinProgress" :noOfPax="noOfPax" :storeorderId="storeorderId" @itemSummary="itemSummaryCheckout" v-if="CheckoutShow" @close="closeCheckout" :editOrder="editOrder" @updateCustomerName="updateCustomerName" :order="checkoutOrder" @updateCheckout="updateCheckout" :isPopUp="isPopUp" @discard="discardOrderFromCheckout"></check-out>
					<payment-method modal_name="method_payment" :ticket_printing="false" :summary="summary" :is_credit_card_used="order.is_credit_card_used" :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" :fastPay="true" @closePayment="closePaymentMethod" v-if="showpay"></payment-method>
					<div>
						<create-customer modal_name="customer-create" @close="closeCreateCustomer" v-if="customercreate"></create-customer>
					</div>
					<div>
						<show-product  modal_name="show-product" :order_id="order._id" :is_combo="is_combo" :product_id="product_id" v-if="showProductTrue" @closeProductInfo="closeProductInfo()"></show-product>
					</div>
					<select-addons :editOrder="editOrder" @item_summary="itemSummary" @fastCheckoutAddons="fastCheckoutAddons"  @hideAddons="hideAddons" v-if="selectAddons" :hold_status="hold_status" :order_index="order" :product="product_details" modal_name="select-addons" :selectedCourse="selectedCourse"></select-addons>
					<add-customization @add_new_cusomization="add_new_cusomization"  :selectedCourse="selectedCourse"  v-if="addCustom == true" :order_index="order._id" @saveAddCustom="saveAddCustom" @hideAddCustom="hideAddCustom" :product="product_details" modal_name="add-customization"></add-customization>
					<edit-customization v-if="editCustom == true" :order_index="order._id" :selectedCourse="selectedCourse" @hideEditCustom="hideEditCustom" @updateCustom="updateCustom" :product="product_details" modal_name="edit-customization"></edit-customization>
					<item-summary :editOrder="editOrder" @updateItemSummary="updateItemSummary" :order_id="order._id" @fastCheckoutAddons="fastCheckoutAddons" @hideItemsSummary="hideItemsSummary" @checkoutItems="checkoutItems" :order="checkoutOrder" v-if="showItemSummary == true" modal_name="item-summary"></item-summary>
					<select-combo :hold_status="hold_status" :editOrder="editOrder" modal_name="select-combo" :comboDetails="comboDetails" v-if="selectCombo" @checkoutfastProduct="checkoutfastProduct()" @cSelectCombo="cSelectCombo()" :selectedCourse="selectedCourse" :order_index="order._id"></select-combo>
					<modifier-modal modal_name="Modifier-Popup" v-if="modifier_popup" :editOrder="editOrder" @hideModifierpopup="hideModifierpopup" @fastCheckoutSuccessModifier="fastCheckoutSuccessModifier" :hold_status="hold_status" :order_data="order" :product="product_details" :selectedCourse="selectedCourse" :sub_category_id="sub_category_id"></modifier-modal>
					<modifier-modal-edit v-if="edit_modifier_modal" modal_name="edit-modifier-modal" @updateModifierSuccess="updateModifierSuccess" @hideModifierpopup="hideModifierEditPopup" :order_data="order" :item_id="modifier_item_id" :product="product_data_for_secondary"> </modifier-modal-edit>
					<order-type @updateData="updateData" @updateTablePax="updateTablePax" @cancelOrderType="cancelOrderType" modal_name="order_type" :order_type_id="order_type_id" :what_order_type_from_checkout="what_order_type" :order_id="order._id" v-if="show_order_type"></order-type>
					<order-type-change-success v-if="order_type_change_success" :delivery_name="''" :delivery_image="''" @hideSuccessPopup="hideSuccessPopup" modal_name="order-type-change-success" :order_type="what_order_type"></order-type-change-success>
				</div>
			</div>
			<sweet-modal ref="success_modal" icon="success"> {{modal_msg}}</sweet-modal>
			<sweet-modal ref="warning_modal" icon="warning"> {{modal_msg}}</sweet-modal>
		</div>
	</div>
</template>
<script>
	const ProductInfoNew = () => import('./ProductInfoNew')
	import { SweetModal } from "sweet-modal-vue";
	import Swal from 'sweetalert2'
	import vSelect from 'vue-select'
	import VueScrollTo from 'vue-scrollto'
	import Loader from './Loader'
	import CheckOut from './CheckOut'
	import Avatar from 'vue-avatar'
	import CreateCustomer from './CreateCustomer'
	import TableSelection from './TableSelection'
	import CustomerServices from './mixins/customer'
	import orderServices from './mixins/orders'
	import { EventBus } from './eventBus/nav-bar-event'
	import Slick from 'vue-slick';
	const SelectAddons = () => import('./SelectAddons')
	const AddCustomization = () => import('./AddCustomization')
	const EditCustomization = () => import('./EditCustomization')
	const TakeOrderProductTypeFilter = () => import('./TakeOrderProductTypeFilter')
	const ItemSummary = () => import('./ItemSummary')
	const Courses = () => import('./Courses')
	const PaymentMethod = () => import('./PaymentMethod')
	import { Carousel, Slide } from 'vue-carousel';
	const SelectCombo = () => import('./SelectCombo')
	const ShowProduct = () => import('./ShowProduct')
	const ModifierModal = () => import('./ModifierModal');
    const ModifierModalEdit = () => import('./ModifierModalEdit')
	const OrderTypeChangeSuccess = () => import('./OrderTypeChangeSuccess');
    const OrderType = () => import('./OrderTypeData')
	import printers from "./mixins/printers";
	import customerServices from './mixins/customer'
	export default {
		mixins: [CustomerServices, orderServices, printers, customerServices],
		data() {
			return {
				maxLength: 1000,
				customersLL: [],
				showOrderId:null,
				is_combo:false,
				comboDetails:{
					name:'',
					_id:'',
					image_url:'',
					selected_quantity:null,
					product_quantity:null,
					product_price:null
				},
				hold_status: false,
				selectCombo:false,
				callinProgress:false,
				showpay:false,
				customercreate:false,
				slickOptions: {
					infinite: false,
					slidesToShow: 10,
					slidesToScroll: 4,
					arrows: true,
					autoplay: false,
					speed: 2000,
					responsive:
					[
						{
						breakpoint: 1120,
						settings:{
							slidesToShow: 3,
							slidesToScroll: 1,
						}},
						{
						breakpoint: 800,
						settings:{
							slidesToShow: 2,
							slidesToScroll: 2
						}},
						{
						breakpoint: 600,
						settings:{
							slidesToShow: 1,
							slidesToScroll: 1
						}},
					]
				},
				noOfPax: 1,
				no_of_pax_edit: 1,
				addbtndiv: true,
				InputPlusMinus: false,
				orderType: 'Dine In',
				categories: [],
				sub_categories:[],
				totalCategories: 0,
				defaultQty: 1,
				order: {
					_id: null,
					orderId: null,
					type: '',
					status: 'Inactive',
					paymentStatus: 'UNPAID',
					tables: [],
					discount: {
						type: 'percent',
						value: 0
					},
					roundOff: 0.0,
					orderDayNumber: null,
					items: {},
					customerId: null,
					currency: {
						symbol: null,
						text: ''
					},
					itemCount: 0,
					total: 0.0,
					itemsLength: 0
				},
				isTableSelection: false,
				selectedVendor: null,
				availableCategories: [],
				tables: [],
				vendors: [],
				ajaxCallInProgress: false,
				editOrderTable: false,
				singleCustomer: {},
				type:"Categories",
				responseMessage:'',
				product_details:[],
				selectAddons: false,
				addCustom: false,
				editCustom: false,
				showItemSummary: false,
				grand_total_value:'',
				grand_total_amount:'',
				summary:'',
				modal_msg:'',
				count:0,
				infinite_scroll_reached_end: false,
				for_notification: '',
				update: false,
				disablePlus: false,
				// Customer lazy loading
				customer_count: 0,
				isVisibleCustomers: false,
				scrollable_customer: false,
				loadCustomer: false,
				temp_customers: 0,
				product_scroll_count: 0,

				prodtype_loader: false,
				subcat_loader: false,
				cat_loader: false,
				prod_loader: false,
				customer_search_key: '',
				modifier_popup: false,
				// ------------------------------------------------------------------ //
				showProductTrue: false,
				showselectedTable: false,
				loading: false,
				product_id: '',
				category_id:"",
				sub_category_id:"",
				product_type_id:"",
				itemspageShow: true,
				editOrder: false,
				storeorderId: '',
				inProgressOrder: false,
				isPopUp: false,
				CheckoutShow: false,
				tableSelect: false,
				searchdata: '',
				products: [],
				product_types: [],
				beverages: ['Beer',
					'Champaign',
					'Wine'
				],
				food: ['Breakfast',
					'Combo',
					'Brunch',
					'Dessert'
				],
				slickOptionsCourses: {
					infinite: false,
					slidesToShow: 10,
					slidesToScroll: 6,
					arrows: true,
					autoplay: false,
					speed: 1500,
					responsive: [{

							breakpoint: 1120,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							}
						},
						{

							breakpoint: 800,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 2
							}
						},
						{

							breakpoint: 600,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						},
					]
				},
				getCourses: true,
				selectedCourse: '',
				numberOfCourses: '',
				save_qty: false,
				loadCategories: true,
				open_info_modal: false,
				customers: [],
				selectedCustomer: null,
				selectTable: true,
				courseSelected:'',
				checkoutOrder: {},
				showAddedItems: false,
				searchCustomer: false,
				selectedCategory: null,
				showAvailableItems: false,
				handleclick:false,
				showHideEditGuests: false,
				showHideEditPencil: true,
				new_selected_sub_category_id: '',
				new_selected_product_type_id: [],
				new_selected_category_id: '',
				cart_data: [],
				display_filters: false,
            	hide_filters: false,
				product_type_selected_data: [],
				cart_increase_decrease_in_progress: false,
				show_order_type: false,
                order_types: [],
                order_type_id: null,
                order_type_change_success: false,
                what_order_type: '',
                show_order_type_dropdown: false,
				edit_modifier_modal: false,
                modifier_item_id: '',
                product_data_for_secondary: {},
				apiLock: false,
				loadData:false,
			}
		},
		components: {
			ProductInfoNew,
			vSelect,
			CheckOut,
			CreateCustomer,
			TableSelection,
			VueScrollTo,
			Loader,
			Slick,
			Avatar,
			SelectAddons,
			AddCustomization,
			EditCustomization,
			ItemSummary,
			PaymentMethod,
			SweetModal,
			Courses,
			Carousel,
			Slide,
			SelectCombo,
			ShowProduct,
			ModifierModal,
			ModifierModalEdit,
			TakeOrderProductTypeFilter,
			OrderTypeChangeSuccess,
			OrderType
		},
		methods: {
			async loadCustomerById(){
                let params = {
                    _id: localStorage.getItem("customer_id")
                }
                let response = await this.getCustomerById(params)
                this.customer_full_address = response.response.personal_full_address
            },
			imposeMinMax(){
				var max = parseInt($(this).attr('max'));
				var min = parseInt($(this).attr('min'));
				if ($(this).val() > max)
				{
					$(this).val(max);
				}
				else if ($(this).val() < min)
				{
					$(this).val(min);
				}       
			},
			showModifierPopup(m_data) {
				console.log(m_data);
                this.product_data_for_secondary = m_data;
                this.modifier_item_id = m_data._id
                this.edit_modifier_modal = true
                setTimeout(() => {
                    this.$modal.show('edit-modifier-modal')
                }, 500);
            },
            hideModifierEditPopup() {
                this.edit_modifier_modal = false
                setTimeout(() => {
                    this.$modal.hide('edit-modifier-modal')
                }, 500);
            },
            updateModifierSuccess() {
                this.edit_modifier_modal = false
                setTimeout(() => {
                    this.$modal.hide('edit-modifier-modal')
                    // this.updateCheckout();
					this.getProductsList();
					this.fetchCart();
                }, 500);
            },
			async discardOrderItems() {
				try {
					this.$swal({
						title: '',
						text:'Are you sure you want to discard the Current Order?',
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#00448b',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then(result => {
						if (result.value) {
							let params = {
								orderId:this.order._id
							}
							this.discardOrder(params).then(res=>{
								if(res.status_id == 1){
									this.$toasted.global.success(res.message)
									this.order._id = null
									this.$router.push('tableselection')
								}else{
									this.$toasted.global.error(res.reason)
								}
							}).catch((err) => {
								this.$toasted.global.error(err)
							})
						}
					})
				}
				catch (error) {
					this.$toasted.global.error(error)
				}
            },
			showFilters(){
				if(this.display_filters){
					this.hide_filters = true
					setTimeout(() => {
						this.display_filters = false
						this.hide_filters = false
					}, 500);
				}
				else{
					this.display_filters = true
				}
			},
			hideFilters(){
				this.hide_filters = true
				setTimeout(() => {
					this.display_filters = false
					this.hide_filters = false
				}, 500);
			},
			UpdateByFilter(data){
				this.product_type_selected_data = data
				let new_selected_product_type_id = []
				this.product_type_selected_data.forEach(element => {
					new_selected_product_type_id.push(element._id)
				});
				this.new_selected_product_type_id = [...new Set(new_selected_product_type_id)]
				this.getProductsList();
			},
			removeProductTypeFilter(product_type_selected_index) {
				let stored_product_type_data = JSON.parse(JSON.stringify(this.product_type_selected_data[product_type_selected_index]))
				let index_of_product_type = this.new_selected_product_type_id.indexOf(this.product_type_selected_data[product_type_selected_index]._id)
				this.product_type_selected_data.splice(product_type_selected_index, 1)
				this.new_selected_product_type_id.splice(index_of_product_type, 1)
				this.getProductsList();
				EventBus.$emit('removeProductTypeFilter', stored_product_type_data)
			},
			OpenInfo(){
				this.open_info_modal = true
				setTimeout(() => {
					this.$modal.show("product-info")
				}, 500)
			},
			HideProductInfo(){
				this.open_info_modal = false
			},
			showInputGuest() {
				this.showHideEditGuests = true
				this.showHideEditPencil = false
			},
			// ------------------------======================================================---------------------------------------
			// ########################------------------------------------------------------#######################################
			
			next() {
				this.$refs.slick.next();
			},
			prev() {
				this.$refs.slick.prev();
			},
			infinteScroll() {
				var scrollTop = document.getElementById('lazy-load').scrollTop;
				var scrollHeight = document.getElementById('lazy-load').scrollHeight;
				var offsetHeight = document.getElementById('lazy-load').offsetHeight;
				var clientHeight = document.getElementById('lazy-load').clientHeight;
				var contentHeight = scrollHeight - offsetHeight;

				if (contentHeight == scrollTop) {
					if (!this.infinite_scroll_reached_end) {
						this.loadingProducts();
					}
				}
			},
			// async getSubCategoriesList(data) {
			// 	try {
			// 		let params = {
			// 			category_id: data.category_id,
			// 			course: this.courseSelected,
			// 			order_id: this.order._id,
			// 			searchdata: "",
			// 			sub_category_id: ""
			// 		}
			// 		let response = await this.getNewProductListing(params)
			// 		if(this.getCourses){
			// 			this.numberOfCourses = response.response.courses_list
			// 			this.selectedCourse = this.numberOfCourses[0].course
			// 			this.courseSelected = this.numberOfCourses[0].course
			// 			this.getCourses = false
			// 		}
			// 		this.showOrderId = response.response.order_id
			// 		this.type = response.response.type
			// 		this.subcat_loader = true
			// 		this.product_types = [];
			// 		this.products = [];
			// 		this.count = 0;
			// 		let sub_cat_data = response.response.items;
			// 		sub_cat_data.forEach((sub_cat) => {
			// 			sub_cat['selected'] = false
			// 		});
			// 		this.categories[data.category_index].sub_categories = sub_cat_data
			// 		console.log(this.categories[data.category_index].sub_categories)
			// 		this.subcat_loader = false
			// 	}
			// 	catch(err) {
			// 		console.log(err)
			// 	}
			// },
			selectSubCategoryOption(params) {
				this.categories.forEach((category) => {
					category.sub_category_list.forEach((sub_category) => {
						sub_category['selected'] = false
					});
				})
				this.categories[params.category_index].sub_category_list[params.sub_category_index].selected = true
				this.new_selected_sub_category_id = params.sub_category_id
				this.new_selected_category_id = params.category_id
				console.log(this.categories[params.category_index].sub_category_list[params.sub_category_index])
				EventBus.$emit('product_type_filter', {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id
				});
				this.getProductsList();
				this.$forceUpdate();
			},
			async getProductsList() {
				this.prod_loader = true
				try {
					let params = {
						category_id: this.new_selected_category_id,
						course: this.courseSelected,
						order_id: this.order._id,
						searchdata: this.searchdata,
						sub_category_id: this.new_selected_sub_category_id,
						product_type_id: this.new_selected_product_type_id
					}
					//console.log(params)
					let response = await this.getNewProductListing(params)
					if(this.getCourses){
						this.numberOfCourses = response.response.courses_list
						this.selectedCourse = this.numberOfCourses[0].course
						this.courseSelected = this.numberOfCourses[0].course
						this.getCourses = false
					}
					this.showOrderId = response.response.order_id
					this.order.type = response.response.order_type
					this.type = response.response.type
					this.products = [];
					this.products = response.response.items
					this.product_scroll_count = response.response.items.length
					this.count = response.response.items.length;
					this.prod_loader = false
					this.loading = false;
				}
				catch(err) {
					console.log(err)
				}
			},
			onSearchProducts(event) {
				this.searchdata = event.target.value;
				this.getProductsList();
			},
			clearSearchProducts() {
				this.searchdata = "";
				this.getProductsList();
			},
			async loadProductListing(params){
				this.$nextTick(() => {
					document.getElementById('bigcontainer_scroll').scrollTop = 0
					window.scrollTo(0,0);
				})
				this.loading = true;
				this.infinite_scroll_reached_end = false
				try {
					this.category_id = params.category_id
					this.sub_category_id = params.sub_category_id
					this.product_type_id = params.product_type_id
					params.order_id = this.order._id
					params.course = this.selectedCourse
					if(this.getCourses){
						params.is_course_list_required = true
					}
					if(params.sub_category_id && params.category_id) {
						params.skip = 0;
						params.limit = 100;
					}
					let response = await this.getNewProductListing(params)
					if(this.getCourses){
						this.numberOfCourses = response.response.courses_list
						this.selectedCourse = this.numberOfCourses[0].course
						this.courseSelected = this.numberOfCourses[0].course
						this.getCourses = false
					}
					this.showOrderId = response.response.order_id
					this.order.type = response.response.order_type
					this.type = response.response.type
					if(this.type == "Categories"){
						this.cat_loader = true
						this.sub_category = [];
						this.product_types = [];
						this.products = [];
						this.categories = response.response.items
						this.categories.forEach((category) => {
							category.sub_category_list.forEach((sub_category) => {
								sub_category['selected'] = false
							});
						})
						if(this.categories.length >= 1) {
							if(this.categories[0].sub_category_list.length) {
								this.new_selected_category_id = this.categories[0]._id
								this.new_selected_sub_category_id = this.categories[0].sub_category_list[0]._id
								this.categories[0].sub_category_list[0].selected = true;
							}
							this.getProductsList();
						}
						this.cat_loader = false
						this.fetchCart();
						this.count = 0
					}
					else if(this.type =="Sub Categories"){
						this.subcat_loader = true
						this.product_types = [];
						this.products = [];
						this.count = 0;
						this.sub_categories = response.response.items
						this.subcat_loader = false
						if(this.sub_categories.length == 1) {
						let selected_subcategory_id = this.sub_categories[0]._id
						setTimeout(() => {
							this.loadProductListing({sub_category_id:selected_subcategory_id,category_id:params.category_id})
						}, 500);
						}
					}
					else if(this.type =="Product Types"){
						this.prodtype_loader = true
						this.products = [];
						this.count = 0;
						this.product_types = response.response.items
						this.prodtype_loader = false
						if(this.product_types.length == 1) {
						let product_type_id = this.product_types[0]._id
						setTimeout(() => {
							this.loadProductListing({sub_category_id:params.sub_category_id,category_id:params.category_id,product_type_id: product_type_id})
						}, 1000);
						}
					}
					else if(this.type =="Products"){
						this.prod_loader = true
						this.products = [];
						this.products = response.response.items
						this.product_scroll_count = response.response.items.length
						this.count = response.response.items.length;
						this.prod_loader = false
						this.loading = false;
						document.getElementById('lazy-load').addEventListener('scroll',this.infinteScroll,false);
					}
				} catch (reason) {
					this.loading = false;
				}
			},
			
			reInit() {
				// Helpful if you have to deal with v-for to update dynamic lists
				this.$nextTick(() => {
					this.$refs.slick.reSlick();
				});
			},
			selectCourseforProduct(course){
				this.selectedCourse = course
				this.courseSelected = course
				setTimeout(() => {
					let params = {
						course :this.courseSelected
					}
					this.loadProductListing(params)
				}, 1000)
			},
			searchdata: function(newValue) {
				this.loadProductListing({
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					searchdata: this.searchdata,
					product_type_id: this.new_selected_product_type_id
				});
			},
			showHideModifiers(cart_item) {
				cart_item.show_modifiers = !cart_item.show_modifiers
				this.$forceUpdate();
			},
			async fetchCart() {
				try {
					let params = { orderId: this.order._id }
					if(this.for_notification == 'notification') {
						params.for_notifications = true
					}
					let res = await this.getCart(params)
					console.log(res)
					this.numberOfCourses = res.order.active_courses
					this.order.itemCount = res.order.cart_count
					this.order.currency = res.order.currency
					this.cart_data = res.order.courses
					this.order.total = res.order.grand_total
					this.order.tables = res.order.tables
					this.noOfPax = res.order.no_of_pax
					this.no_of_pax_edit = JSON.parse(JSON.stringify(this.noOfPax))
					this.order['grand_total_with_credit_card_charges'] = res.order.grand_total_with_credit_card_charges
					this.cart_data.forEach((cart) => {
						cart.items.forEach((item) => {
							item['show_modifiers'] = false
						});
					})
				} catch (reason) {
					this.cart_data = []
					// this.$toasted.global.error(reason)
				}
			},
			openModifierPopup(){
				this.modifier_popup = true
				setTimeout(() => {
					this.$modal.show('Modifier-Popup')
				}, 500)
			},  
			hideModifierpopup(){
				this.modifier_popup = false
				setTimeout(() => {
					this.$modal.hide('Modifier-Popup')
				}, 500)
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
			},
			fastCheckoutSuccessModifier() {
				this.modifier_popup = false
				setTimeout(() => {
					this.$modal.hide('Modifier-Popup')
				}, 500);
			},
			updateItemSummary() {
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
				this.itemSummary();
			},
			cSelectCombo(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				// console.log(this.category_id)
				// console.log(this.sub_category_id)
				// console.log(this.product_type_id)
				// console.log(this.order._id)
				this.loadProductListing(params);
				this.fetchCart();
				this.selectCombo = false
			},
			loadingProducts() {
				this.loading = true;
				this.infinite_scroll_reached_end = false;
				let params={
					limit: 10,
					skip: this.count,
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id: this.new_selected_product_type_id,
					order_id: this.order._id
				}
				this.$http.post('/mobile/get_products',params)
				.then(response => {
				if(response.data.response.total_product_count > this.products.length){
					if (this.count > 0) {
						let tempFiles =  response.data.response.items;
						tempFiles.forEach(el =>{ this.products.push(el); });
						this.count += response.data.response.items.length;
					} else {
						this.products = response.data.response.items;
						this.count=response.data.response.items.length;
					}
				}
				if (response.data.response.items.length < 10) {
						this.infinite_scroll_reached_end=true;
						document.getElementById('spinner').style.display = "none";
						document.getElementById('spinner').removeEventListener('scroll',this.infinteScroll);
						this.loading = false;
					}
				})
				.catch(error => {
					this.loading = false;
					document.getElementById('spinner').style.display = "none";
					// document.getElementById('spinner').removeEventListener('scroll',this.infinteScroll);
				})
			},
			updateAddon(){
				this.showCheckout();
			},
			hideItemsSummary(){
				this.showItemSummary = false;

			},
			checkoutItems(){
				this.showItemSummary = false;

				this.showCheckout();
			},
			add_new_cusomization(product){
				let main_prod_index = this.products.findIndex(x => x.product_id == product.product_id);
				let main_product = this.products[main_prod_index];
				if(main_product.is_combo){
					this.selectCombo = true
					this.comboDetails = {
						_id:main_product._id,
						name:main_product.product_name,
						image_url:main_product.image_url,
						product_quantity:main_product.product_quantity,
						selected_quantity:1,
						product_price:main_product.product_price
					}
					setTimeout(() => {
						this.$modal.show('select-combo')
					}, 500);
				}else if(main_product.is_customisable || main_product.is_customizable){
					this.addCustom = false
					// this.selectAddons = true
					this.modifier_popup = true
					this.order._id
					this.product_details = main_product
					// console.log(this.product_details)
					// setTimeout(() => {
					//     this.$modal.show('select-addons')
					// }, 500);
					setTimeout(() => {
						this.$modal.show('Modifier-Popup')
					}, 500)
				}
			},
			addCustomization(product){
				if(product.is_customisable || product.is_customizable){
					this.addCustom = true
					this.order._id
					this.product_details = product
					setTimeout(() => {
						this.$modal.show('add-customization')
					}, 500);
				}
			},
			async enterCartQuantity(event, custom, custom_index, custom_item_index) {
				this.increase_decrease_in_progress = true;
				if (this.cart_data[custom_index].items[custom_item_index].quantity <= 0) {
					this.cart_data[custom_index].items[custom_item_index].quantity = 0
				}
				if (!this.cart_data[custom_index].items[custom_item_index].hasVariant && this.cart_data[custom_index].items[custom_item_index].quantity > this.productQty) {
					this.cart_data[custom_index].items[custom_item_index].quantity = this.productQty
				} else if (this.cart_data[custom_index].items[custom_item_index].hasVariant && this.cart_data[custom_index].items[custom_item_index].quantity > this.cart_data[custom_index].items[custom_item_index].variant_quantity + this.cart_data[custom_index].items[custom_item_index].selected_quantity) {
					this.cart_data[custom_index].items[custom_item_index].quantity = this.variantQty
				}
				let params = {
					item_id: this.cart_data[custom_index].items[custom_item_index]._id,
					quantity: parseInt(this.cart_data[custom_index].items[custom_item_index].quantity),
					variant_id: this.cart_data[custom_index].items[custom_item_index].variant_id,
					addons: this.cart_data[custom_index].items[custom_item_index].addons,
					is_customizable: this.cart_data[custom_index].items[custom_item_index].is_customizable
				}
				return new Promise(async (resolve, reject) => {
					let response = await this.$http.post('/mobile/orders/items/edit',params)
					if (response.data.status_id == 1) {
						resolve(response.data)
						console.log(response.data)
						this.increase_decrease_in_progress = false;
						this.getProductsList();
						this.fetchCart();
					} else {
						reject(response.data.reason)
					}
				})
			},
			updateData(){
                this.show_order_type = false;
                setTimeout(() => {
                    this.$modal.hide("order_type")
                }, 500);
				this.getProductsList();
				this.fetchCart();
            },
			updateCheckoutTablePax(checkoutTablePax) {
				this.order.tables = checkoutTablePax.tableName;
				this.noOfPax = checkoutTablePax.no_of_pax;
				this.no_of_pax_edit = JSON.parse(JSON.stringify(this.noOfPax))
                this.order.type = checkoutTablePax.order_type;
			},
			updateTablePax(datatablePax){
				this.order.tables = datatablePax.tableName;
				this.noOfPax = datatablePax.no_of_pax;
				this.no_of_pax_edit = JSON.parse(JSON.stringify(this.noOfPax))
            },
            hideSuccessPopup() {
                this.order_type_change_success = false;
				this.getProductsList()
                this.fetchCart()
                setTimeout(() => {
                    this.$modal.hide('order-type-change-success')
                }, 500);
            },
			async loadOrderTypes(order_type){
                this.show_order_type_dropdown = true;
                let params = {
                    search_key: "",
                    sort_key: "order_type",
                    sort_value: 1,
                    required_id: true,
                    existing_type: order_type
                }
                let response = await this.orderTypeDropdowns(params)
                this.order_types = response.response.order_types
                this.show_order_type_dropdown = false;
            },
			async changeOrderType(value){
                this.what_order_type = value.order_type
                if(value.order_type != 'Take Away'){
                    this.order_type_id = {
                        value: value,
                        order: this.order
                    }
                    this.show_order_type = true
                    setTimeout(() => {
                        this.$modal.show("order_type")
                    }, 500);
                }
                else{
                    this.loadData = true
                    try {
                        let params = {
                            order_id: this.order._id,
                            order_type: value.order_type,
                            tables: this.order.tables,
                            delivery_company: "",
                            no_of_pax: this.order.no_of_pax
                        }
                        let response = await this.updateOrderTypeDetails(params)
                        this.order_type_change_success = true;
                        setTimeout(() => {
                            this.$modal.show('order-type-change-success')
                        }, 500);
                        setTimeout(() => {
                            this.hideSuccessPopup();
                            this.loadOrderTypes(value.order_type)
                            this.loadData = false
                        }, 3000);
                    }
                    catch(err) {
                        this.loadData = false
                        this.modal_msg = err;
                        this.$refs.warning_modal.open();
                        setTimeout(() => this.$refs.warning_modal.close(), 2000);
                    }
                }
            },
            cancelOrderType(){
                this.show_order_type = false
                setTimeout(() => {
                    this.$modal.hide("order_type")
                }, 500);
            },
			saveAddCustom(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
				this.addCustom = false
			},
			hideAddCustom(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
				this.addCustom = false
			},
			editCustomization(product){
				this.editCustom = true

				this.order._id
				this.product_details = product
				setTimeout(() => {
					this.$modal.show('edit-customization')
				}, 500);
			},
			hideEditCustom(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();

				this.editCustom = false,
				this.order.itemCount = 0,
				this.order.total = 0
			},
			updateCustom(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();

				this.editCustom = false
			},
			addAddons(product){
				// console.log('add addons console 1')
				// console.log(product)
				if(product.is_combo){
					this.selectCombo = true
					this.comboDetails = {
						_id:product._id,
						name:product.product_name,
						image_url:product.image_url,
						product_quantity:product.pm_quantity,
						selected_quantity:1,
						product_price:product.pm_price
					}
					setTimeout(() => {
						this.$modal.show('select-combo')
					}, 500);
				}else if(product.is_customisable){
					// this.selectAddons = true
					this.modifier_popup = true
					this.order._id
					this.product_details = product
					// setTimeout(() => {
					//     this.$modal.show('select-addons')
					// }, 500);
					setTimeout(() => {
						this.$modal.show('Modifier-Popup')
					}, 500)
				}
			},
			hideAddons(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();

				this.selectAddons = false
			},
			async itemSummary(){
				this.checkoutOrder = {}
				try {
					let params = { orderId: this.order._id, origin: 'place_order'}
					if (this.editOrder) {
						params.update = true
					}
					let res = await this.getCheckout(params)
					this.checkoutOrder = res.order
					this.order.itemCount = res.order.cart_count
					this.order.currency = res.order.currency
					this.order.total = res.order.grand_total
					this.showItemSummary = true;
					setTimeout(() => {
						this.$modal.show('item-summary');
					}, 500);
				} catch (reason) {
					this.checkoutOrder.items = []
					this.order.itemCount = 0
					this.order.total = null
					this.showAddedItems = false
					this.$toasted.global.error(reason)
				}
			},
			async itemSummaryCheckout(){
				this.CheckoutShow = false
				this.itemspageShow = true
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
				try {
					let params = { orderId: this.order._id }
					if (this.editOrder) {
						params.update = true
					}
					let res = await this.getCheckout(params)
					this.checkoutOrder = res.order
					this.order.itemCount = res.order.cart_count
					this.order.currency = res.order.currency
					this.order.total = res.order.grand_total
					this.showItemSummary = true;

					setTimeout(() => {
						this.$modal.show('item-summary');
					}, 500);
				} catch (reason) {
					this.checkoutOrder.items = []
					this.order.itemCount = 0
					this.order.total = null
					this.showAddedItems = false
				}
			},
			updateCustomerName(order){
				// console.log(order)
				this.hold_status = order.hold_order_before_placing
				this.selectedCustomer.first_name = order.customer_name
				this.products.selected_quantity = order.quantity
				this.checkout();
			},
			async scrollProductsEnd(e, params) {
				const {target} = e;
				if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
					//this code will run when the user scrolls to the bottom of this div so
					//you could do an api call here to implement lazy loading
					let payload = {
						category_id: params.category_id,
						course: 1,
						limit: 100,
						order_id: this.order._id,
						searchdata: params.searchdata,
						skip: this.product_scroll_count,
						sub_category_id: params.sub_category_id,
					}
					this.loading = true
					let res = await this.getProductListing(payload)
					if (res.response.skip == this.product_scroll_count) {
						let tempFiles = res.response.items
						
						tempFiles.forEach(el => {
						this.products.push(el)
						})
						this.product_scroll_count += res.response.items.length
						this.loading = false
					}
					if (res.response.items.length < 10) {
						this.infinite_scroll_reached_end = true
						document.getElementById('spinner').style.display = 'none'
						this.loading = false
					}

				}
			},
			// Events listeners
			handleAfterChange(event, slick, currentSlide) {
				// console.log('handleAfterChange', event, slick, currentSlide);
			},
			handleBeforeChange(event, slick, currentSlide, nextSlide) {
				// console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
			},
			handleBreakpoint(event, slick, breakpoint) {
				// console.log('handleBreakpoint', event, slick, breakpoint);
			},
			handleDestroy(event, slick) {
				// console.log('handleDestroy', event, slick);
			},
			handleEdge(event, slick, direction) {
				// console.log('handleEdge', event, slick, direction);
			},
			handleInit(event, slick) {
				// console.log('handleInit', event, slick);
			},
			handleReInit(event, slick) {
				// console.log('handleReInit', event, slick);
			},
			handleSetPosition(event, slick) {
				// console.log('handleSetPosition', event, slick);
			},
			handleSwipe(event, slick, direction) {
				// console.log('handleSwipe', event, slick, direction);
			},
			handleLazyLoaded(event, slick, image, imageSource) {
				// console.log('handleLazyLoaded', event, slick, image, imageSource);
			},
			handleLazeLoadError(event, slick, image, imageSource) {
				// console.log('handleLazeLoadError', event, slick, image, imageSource);
			},
			closeCreateCustomer(flag) {
				this.customercreate = false
				this.$modal.hide('customer-create')
			},
			formatPrice(value) {
				if (value) {
					let val = (value / 1).toFixed(2).replace(',', '.')
					return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				} else return 0
			},
			updateCheckout() {
				setTimeout(() => {
					let params = {
						category_id: this.new_selected_category_id,
						sub_category_id: this.new_selected_sub_category_id,
						product_type_id:this.new_selected_product_type_id,
						order_id:this.order._id
					}
					this.loadProductListing(params);
					this.fetchCart();
				}, 500);
				this.update = true
				this.checkout()
			},
			async fetchVendors() {
				try {
					let res = await this.getVendors()
					this.vendors = res.vendors
					this.selectedVendor = this.vendors[0]
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			closeCheckout(flag) {
				if (flag) {
					this.order._id = null
					this.$router.push('manageorder')
				}
				this.CheckoutShow = false
				this.itemspageShow = true
				this.handleclick = true
				setTimeout(() => {
					this.handleclick = false
				}, 2000);
			},
			async getSearchCustomer(search = '') {
				try {
					let params = {
						properties: ['first_name', 'last_name'],
						search: { term: search, fields: ['first_name', 'last_name'] },
						sort: { by: 'first_name', order: 1 },
						skip: 0,
						limit: 5
					}
					let res = await this.customerGetCustomerWith(params)
					this.customers = res.response
				} catch (reason) {
					// TODO: Handle Error
				}
			},
			// async loadMeasurementsSearch(search) {
			// this.loadCustomer = true
			// let response = await this.getScmMeasurements({
			//     search_key: search,
			//     skip: 0,
			//     limit: 10
			// })
			// this.measurements = response.unit_of_measurements
			// this.count = this.measurements.length
			// this.isLoading = false
			// this.scrollable = false
			// },
			async loadCustomersSearch(search_key){
				this.customer_search_key = search_key || ''
				this.loadCustomer = true
				let params = {
					skip: this.customer_count,
					limit: 5,
					searchdata: this.customer_search_key,
					sortKey: 'first_name',
					sortValue: 1
				}
				let res = await this.getCustomers(params)
				this.customersLL = res.all_customers
				this.customer_count = this.customersLL.length
				this.loadCustomer = false
				this.scrollable_customer == false
			},
			async reachedEndOfCustomerList(reached){
				this.isVisibleCustomers = reached
				if(reached){
					if(this.scrollable_customer == false){
						this.loadCustomer = true
						let res = await this.getCustomers({
						skip: this.customer_count,
						limit: 5,
						searchdata: this.customer_search_key,
						sortKey: 'first_name',
						sortValue: 1
						})
						this.temp_customers = res.all_customers.length
						if(res.payload.skip == this.customer_count) {
						if(this.temp_customers > 0){
							let tempFiles = res.all_customers
							tempFiles.forEach((el) => {
								this.customersLL.push(el)
							})
							this.customer_count += res.all_customers.length
							this.loadCustomer = false
						} else {
							this.scrollable_customer = true
							this.loadCustomer = false
						}
						}
						this.loadCustomer = false
					}
				}
			},
			getSearchCategory(search = '') {
				this.selectedCategory = ''
			},
			getSearchVendor() {
				this.selectedVendor = ''
			},
			discardOrderFromCheckout() {
				this.order._id = null
				this.$router.push('tableselection')
			},
			// showCheckout() {
			//     this.storeorderId = this.order._id
			//     if (this.order.type == 'Dine In' || this.order.type == 'Take Away') {
			//         if (this.order.type == 'Take Away' || this.order.tables.length) {
			//             if (this.order.customerId) {
			//                 if (this.order._id) {
			//                     if (this.order.itemCount) {
			//                         this.CheckoutShow = true
			//                         this.itemspageShow = false
			//                         this.checkout()
			//                     } else {
			//                         this.$toasted.global.error('No Item Added')
			//                     }
			//                 } else {
			//                 this.$toasted.global.error('No Order Initiated')
			//                 }
			//             } else {
			//                 this.$toasted.global.error('Please Select a Customer')
			//             }
			//         } else {
			//             this.$toasted.global.error('Please Select a Table')
			//         }
			//     } else {
			//         this.$toasted.global.error('Please Select Order Type')
			//     }
			// },
			showCheckout() {
				this.storeorderId = this.order._id
				if (this.order.type == 'Dine In'){
					if(this.order.tables.length){
						if (this.order.customerId) {
						if (this.order._id) {
							if (this.order.itemCount) {
								this.CheckoutShow = true
								this.itemspageShow = false
								this.checkout();
								this.$router.replace(`/takeordernew?order_id=${this.order._id}&order_type=${this.order.type}`)
								localStorage.setItem('order_data_for_checkout', JSON.stringify(this.order))
							} else {
								this.$toasted.global.error('No Item Added')
							}
						} else {
						this.$toasted.global.error('No Order Initiated')
						}
						} else {
						this.$toasted.global.error('Please Select a Customer')
						}
					}else{
						this.$toasted.global.error('Please Select a Table')
					}
				}else{
					if (this.order.customerId) {
						if (this.order._id) {
						if (this.order.itemCount) {
							this.CheckoutShow = true
							this.itemspageShow = false
							this.checkout()
							this.$router.replace(`/takeordernew?order_id=${this.order._id}&order_type=${this.order.type}`)
							localStorage.setItem('order_data_for_checkout', JSON.stringify(this.order))
						} else {
							this.$toasted.global.error('No Item Added')
						}
						} else {
						this.$toasted.global.error('No Order Initiated')
						}
					} else {
						this.$toasted.global.error('Please Select a Customer')
					}
				}
			},
			async checkout() {
				if(!this.update){
					this.callinProgress = true
				} else {
					this.update = false
				}
				this.checkoutOrder = {}
				try {
					let params = { orderId: this.order._id }
					if (this.editOrder) {
						params.update = true
					}
					let res = await this.getCheckout(params)
					this.callinProgress = false
					this.checkoutOrder = res.order
					this.order.itemCount = res.order.cart_count
					this.order.currency = res.order.currency
					this.order.total = res.order.grand_total
					EventBus.$emit('emit_for_discount');
				} catch (reason) {
					this.checkoutOrder.items = []
					this.order.itemCount = 0
					this.order.total = null
					this.showAddedItems = false
				}
			},
			TableSearch() {
				this.itemspageShow = false
				this.tableSelect = true
				this.editOrderTable = true
			},
			refreshCateory() {
				this.categories.push({})
				this.categories.pop()
			},
			showCreateCustomer() {
				this.customercreate = true
				this.searchCustomer = false
				setTimeout(() => {
					this.$modal.show('customer-create')
				}, 500);
			},
			changeSelectCategory(category) {
				this.selectedCategory = category
			},
			async fetchTables() {
				try {
					let params
					if (this.order._id) {
						params.orderId = this.order._id
					}
					let res = await this.getTables(params)
					this.tables = res.message
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			closeProductInfo(){
				this.showProductTrue = false
			},
			showProductInfo(value) {
				this.is_combo = value.is_combo
				this.product_id = value.product_id
				this.showProductTrue = true
				setTimeout(() => {
					this.$modal.show('show-product')
				}, 200);
			},
			async addItem(params) {
				this.loadData = true
				try {
					this.disablePlus = true;
					let res = await this.addItemToOrder(params)
					if (res.response.order) {
						if (this.order.itemCount < res.response.cart_count) {
							this.$toasted.global.success('Item Added Successfully')
							this.disablePlus = false;
						} else if (this.order.itemCount == res.response.cart_count) {
							this.$toasted.global.success('Item Updated Successfully')
							this.disablePlus = false;
						} else {
							this.$toasted.global.success('Item Removed Successfully')
							this.disablePlus = false;
						}
					} else {
						this.$toasted.global.success('Item Removed Successfully')
						this.$toasted.global.error('No Items in Cart')
					}
					this.loadData = false
					this.fetchCart();
					this.getProductsList();
					this.checkout()
				} catch (reason) {
					this.loadData = false
					this.$toasted.global.error(reason)
					
				}
				this.callinProgress = false;
			},
			wholeCardClick(productIndex) {
				if(this.products[productIndex].is_blocked) {
					return;
				} else {
					if(this.products[productIndex].is_customisable == false) {
						if(this.products[productIndex].selected_quantity != 0) {
						this.increaseQuantity(productIndex);
						} else {
						this.addOrderItem(productIndex);
						}
					} else {
						if(this.products[productIndex].selected_quantity != 0) {
						this.addCustomization(this.products[productIndex]);
						} else {
						this.addAddons(this.products[productIndex]);
						}
					}
				}
			},
			addOrderItem(productIndex) {
				// console.log('add addons console')
				let product = this.products[productIndex]
				let data = {}
				product.selected_quantity = 1
				data.quantity = product.selected_quantity
				data.product_id = product.product_id
				data.order_id = this.order._id
				data.is_customizable = false
				data.course = this.selectedCourse
				data.is_combo = product.is_combo
				data.variant_id = product.variant_id || ''
				if (this.isPopUp) data.vendorId = this.selectedVendor._id
				this.addItem(data)
			},
			increaseQuantity(productIndex) {
				if (!this.callinProgress) {
                    this.callinProgress = true
					//this.loadData = true
					try {
						// TODO: increase with item_id
						let product = this.products[productIndex]
						// console.log(product)
						let data = {}
						if(product.is_combo){
							if (product.selected_quantity < product.product_quantity) {
								product.selected_quantity++
								data.quantity = product.selected_quantity
								data.product_id = product.product_id
								data.order_id = this.order._id
								data.is_customizable = false
								data.course = this.selectedCourse
								data.is_combo = product.is_combo
								data.variant_id = product.variant_id || ''
								if (this.isPopUp) data.vendorId = this.selectedVendor._id
								this.addItem(data)
								//this.callinProgress = false;
							}
						}else{
							if (product.selected_quantity < product.pm_quantity) {
								product.selected_quantity++
								data.quantity = product.selected_quantity
								data.product_id = product.product_id
								data.order_id = this.order._id
								data.is_customizable = false
								data.course = this.selectedCourse
								data.is_combo = product.is_combo
								data.variant_id = product.variant_id || ''
								if (this.isPopUp) data.vendorId = this.selectedVendor._id
								this.addItem(data)
								this.callinProgress = false;
								//this.loadData = false;
							}
						}
					}
					catch(err) {
						// console.log(err)
						this.callinProgress = false;
						this.$toasted.global.error(err)
					}
				}
			},
			decreaseQuantity(productIndex) {
				if (!this.callinProgress) {
                    this.callinProgress = true;
					//this.loadData = true
					try {
						let product = this.products[productIndex]
						// console.log(product)
						let data = {}
						if (product.selected_quantity > 0) {
							product.selected_quantity--
							data.quantity = product.selected_quantity
							data.product_id = product.product_id
							data.order_id = this.order._id
							data.is_customizable = false
							data.course = this.selectedCourse
							data.is_combo = product.is_combo
							data.variant_id = product.variant_id || ''
							if (this.isPopUp) data.vendorId = this.selectedVendor._id
							this.addItem(data)
							this.callinProgress = false;
							//this.loadData = false;
						}
					} catch(err) {
						// console.log(err)
						this.$toasted.global.error(err)
						//this.callinProgress = false;
					}
				}
				// TODO: decrease with item_id
			},
			increaseQtyFromCart(product) {
				try {
					// TODO: increase with item_id
					let data = {}
					if(product.is_combo){
						if (product.quantity < product.available_qty) {
							product.quantity++
							data.quantity = product.quantity
							data.product_id = product.product_id
							data.order_id = this.order._id
							data.is_customizable = false
							data.course = this.selectedCourse
							data.is_combo = product.is_combo
							data.variant_id = product.variant_id || ''
							if (this.isPopUp) data.vendorId = this.selectedVendor._id
							this.addItem(data)
						} else{
							this.$toasted.global.error('Product Quantity Cannot Exceed Max Quantity')
						}
					}else{
						if (product.quantity < product.available_qty) {
							product.quantity++
							data.quantity = product.quantity
							data.product_id = product.product_id
							data.order_id = this.order._id
							data.is_customizable = false
							data.course = this.selectedCourse
							data.is_combo = product.is_combo
							data.variant_id = product.variant_id || ''
							if (this.isPopUp) data.vendorId = this.selectedVendor._id
							this.addItem(data)
						} else{
							this.$toasted.global.error('Product Quantity Cannot Exceed Max Quantity')
						}
					}
				}
				catch(err) {
					// console.log(err)
					this.$toasted.global.error(err)
				}
			},
			decreaseQtyFromCart(product) {
				try {
					let data = {}
					if (product.quantity > 0) {
						product.quantity--
						data.quantity = product.quantity
						data.product_id = product.product_id
						data.order_id = this.order._id
						data.is_customizable = false
						data.course = this.selectedCourse
						data.is_combo = product.is_combo
						data.variant_id = product.variant_id || ''
						if (this.isPopUp) data.vendorId = this.selectedVendor._id
						this.addItem(data)
					}
				} catch(err) {
					// console.log(err)
					this.$toasted.global.error(err)
				}
			},
			saveQtyFromCart(event, product){
				let data = {}
				if(product.is_combo){
					if (parseInt(product.quantity) < product.available_qty) {
						data.quantity = parseInt(product.quantity)
						data.product_id = product.product_id
						data.order_id = this.order._id
						data.is_customizable = false
						data.course = this.selectedCourse
						data.is_combo = product.is_combo
						data.variant_id = product.variant_id || ''
						if (this.isPopUp) data.vendorId = this.selectedVendor._id
						this.addItem(data)
						this.save_qty = false
					}
				}else{
					if (parseInt(product.quantity) < product.available_qty) {
						data.quantity = parseInt(product.quantity)
						data.product_id = product.product_id
						data.order_id = this.order._id
						data.is_customizable = false
						data.course = this.selectedCourse
						data.is_combo = product.is_combo
						data.variant_id = product.variant_id || ''
						if (this.isPopUp) data.vendorId = this.selectedVendor._id
						this.addItem(data)
						this.save_qty = false
					}else{
						this.$toasted.global.error('Product Quantity Cannot Exceed Max Quantity')
					}
				}
			},
			saveQty(productIndex){
				let product = this.products[productIndex]
				let data = {}
				if(product.is_combo){
					if (product.selected_quantity < product.pm_quantity) {
						data.quantity = product.selected_quantity
						data.product_id = product.product_id
						data.order_id = this.order._id
						data.is_customizable = false
						data.course = this.selectedCourse
						data.is_combo = product.is_combo
						data.variant_id = product.variant_id || ''
						if (this.isPopUp) data.vendorId = this.selectedVendor._id
						this.addItem(data)
						this.save_qty = false
					}
				}else{
					if (product.selected_quantity < product.pm_quantity) {
						data.quantity = product.selected_quantity
						data.product_id = product.product_id
						data.order_id = this.order._id
						data.is_customizable = false
						data.course = this.selectedCourse
						data.is_combo = product.is_combo
						data.variant_id = product.variant_id || ''
						if (this.isPopUp) data.vendorId = this.selectedVendor._id
						this.addItem(data)
						this.save_qty = false
					}else{
						this.$toasted.global.error('Product Quantity Cannot Exceed Max Quantity')
					}
				}
			},
			discardQty(productIndex){
				let product = this.products[productIndex]
				product.selected_quantity = 1;
				this.save_qty = false
			},
			async updateCustomer() {
				try {
					let params = {
						customerId: this.order.customerId,
						orderId: this.order._id
					}
					let res = await this.orderCustomer(params)
					if (!this.order._id && res.order_id) {
						this.order._id = res.order_id
					}
					// this.$toasted.global.success('Customer Selected Successfully')
				} catch (reason) {
					this.$toasted.global.error(reason)
                    // if(reason == 'Order Already Moved to Different State'){
                    //     this.closeCheckout()
                    // }
				}
			},
			async removeTable() {
				try {
					let params = { orderId: this.order._id }
					let res = await this.orderRemoveTables(params)
					if (!this.order._id && res.order_id) {
						this.order._id = res.order_id
					}
					this.clearTables()
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async removeCustomer() {
				try {
					let params = { orderId: this.order._id }
					let res = await this.orderRemoveCustomer(params)
					if (!this.order._id && res.order_id) {
						this.order._id = res.order_id
					}
					this.selectedCustomer = null
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			clearTables() {
				if(this.order.tables.length){
					let selectedTable = this.tables.find(
						element => element == this.order.tables[0]
					)
					if(selectedTable){
						selectedTable.selected = false
					}
					this.order.tables = []
				}
			},
			async getOrderDetails() {
				try {
					this.showselectedTable = true
					let params = { orderId: this.order._id }
					let res = await this.orderDetails(params)
					this.showselectedTable = false
					this.selectedCustomer = res.order.customer
					this.order.tables = res.order.tables
					this.order.type = res.order.type
					this.noOfPax = res.order.no_of_pax
					this.no_of_pax_edit = JSON.parse(JSON.stringify(this.noOfPax))
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async change_table(old_table_id, new_table_id, order_id) {
				try {
					let params = {
						old_table_id: old_table_id,
						new_table_id: new_table_id,
						order_id: order_id
					}
					let res = await this.orderUpdateTable(params)
					this.$toasted.global.success('Table Changed')
					this.fetchTables()
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async fetchGuestCustomer() {
				try {
					let res = await this.getGuestCustomer()
					if (!this.selectedCustomer) {
						this.selectedCustomer = res.open_guest_id
					}
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async updateOrderType() {
				try {
					let params = {
						orderType: this.order.type,
						orderId: this.order._id
					}
					let res = await this.orderUpdateType(params)
					if (!this.order._id && res.order_id) {
						this.order._id = res.order_id
					}
					if (this.loggedInUser.service_type == 'Both') {
						this.$toasted.global.success('Order Type Selected Successfully')
					}
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async updateOrderCustomer() {
				try {
					if (this.editOrder) {
						if (this.selectedCustomer._id) {
						let params = {
							customer_id: this.selectedCustomer._id,
							order_id: this.order._id
						}
						let res = await this.orderUpdateCustomer(params)
						this.$toasted.global.success('Successfully Updated Customer')
						}
					}
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async applyPax() {
				try {
					// TODO: Validate Only Context fields
					if (parseInt(this.no_of_pax_edit) > 1000 && parseInt(this.no_of_pax_edit) < 1) {
						this.$toasted.global.error('Invalid Number of Guests')
						return
					}
					let result = await this.$validator.validateAll()
					if (result) {
						let params = {
							orderId: this.order._id,
							noOfPax: parseInt(this.no_of_pax_edit)
						}
						let res = await this.orderPax(params)
						this.$toasted.global.success('Successfully Updated Number of Guests')
						this.showHideEditGuests = false
						this.noOfPax = parseInt(this.no_of_pax_edit)
						this.showHideEditPencil = true
					} else {
						this.$toasted.global.error(this.errors.first('pax'))
						this.showHideEditGuests = true
						this.showHideEditPencil = false
					}
				} catch (reason) {
					this.$toasted.global.error(reason)
					this.showHideEditGuests = true
					this.showHideEditPencil = false
				}
			},
			async fastPay(){
				try {
					this.$http.get("/cashier/is_cashier_session_active").then(res => {
						this.modal_msg = res.data.reason;
						if (res.data.status_id == 1) {
						this.$store.commit("setCashRegister",res.data.response.cash_register_details);
						this.fastPayConfirm()
						} else {
						this.$toasted.global.error(res.data.reason);
						}
					});
				} catch (reason) {
					this.$toasted.global.error(reason);
				}
			},
			async fastPayConfirm(){
				try{
					let params = { orderId:this.order._id }
					let res = await this.getCheckout(params)
					this.summary = res.order
					if(res.status_id == 1){
						this.$http.post('orders/grand_total', { order_id: this.order._id }).then(response => {
						this.grand_total_value = response.data.value.toFixed(2)
						this.grand_total_amount = response.data.amount
						})
						this.showpay = true
						setTimeout(() => {
							this.$modal.show('method_payment')
						}, 500);
					}
				}
				catch(reason){
					this.$toasted.global.error(reason)
				}
			},
			closePaymentMethod(){
				this.showpay = false
				this.$modal.hide('method_payment')
			},
			SaveOrder() {
                this.$router.push({name: 'ManageOrder'})
				this.printTicket()
				// Swal({
				//     title: 'Print Ticket',
				//     text: 'Do You Want to Print Ticket ?',
				//     type: 'warning',
				//     showCancelButton: true,
				//     confirmButtonColor: '#00448b',
				//     cancelButtonColor: '#d33',
				//     confirmButtonText: 'Yes',
				//     cancelButtonText: 'Continue Without Printing',
				//     allowOutsideClick: false
				// }).then(result => {
				//     if (result.value) {
				//         this.printTicket()
				//     } else {
				//         this.$router.push({
				//             name: 'ManageOrder',
				//             params: {
				//                 orderType: this.order.type
				//             }
				//         })
				//     }
				// })
			},
			async fastCheckout() {
				try {
					// let category_data = []
					// let data = JSON.parse(sessionStorage.getItem("categories_printer"))
					// if(data){
					//     data.forEach(y => {
					//         y.printers.forEach(x => {
					//             if(y._id == x.category_id){
					//                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
					//             }
					//         })
					//     })
					// }
					let params = {
						order_id: this.order._id,
						note: this.order.note,
						// printer_details: category_data
					};
					let res = await this.orderMobilePlace(params);
					this.responseMessage = res.response.message
					this.$toasted.global.success(this.responseMessage);
                    this.$router.push({name: 'ManageOrder'})
					// if(data){
						// data.forEach(y => {
						// y.printers.forEach(x => {
							res.response.ticket_details.forEach(z => {
								this.store_ticket_data = z
								this.printTicket(z)
							})
						// })
						// })
					// }
					// this.SaveOrder();
				} catch (reason) {
					// this.$toasted.global.error(reason);
					this.modal_msg = reason
					this.$swal({
						type: 'warning',
						text: this.modal_msg,
						showConfirmButton: false,
						timer: 3000
					})
				}
			},
			async updateFastCheckout() {
				try {
					// let category_data = []
					// let data = JSON.parse(sessionStorage.getItem("categories_printer"))
					// if(data){
					//     data.forEach(y => {
					//         y.printers.forEach(x => {
					//             if(y._id == x.category_id){
					//                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
					//             }
					//         })
					//     })
					// }
					let params = {
						orderId: this.order._id,
						note: this.order.note,
						// printer_details: category_data
					};
					let res = await this.orderUpdateOrder(params);
					this.responseMessage = res.message
					this.$toasted.global.success(this.responseMessage);
					this.SaveOrder();
				} catch (reason) {
					this.$toasted.global.error(reason);
					this.$swal({
						type: 'warning',
						text: this.responseMessage,
						showConfirmButton: false,
						timer: 3000
					})
				}
			},
			async fastCheckoutAddons(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();

				this.selectAddons = false
				this.showItemSummary = false
				if(!this.editOrder && this.loggedInUser.enable_fast_checkout){
					this.fastCheckout()
				}
				else if(this.editOrder && this.loggedInUser.enable_fast_checkout){
					this.updateFastCheckout()
				}
			},
			async checkoutfastProduct(){
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();

				this.selectCombo = false
				this.showItemSummary = false
				if(!this.editOrder && this.fastcheckoutEnable){
					this.fastCheckout()
				}
				else if(this.editOrder && this.fastcheckoutEnable){
					this.updateFastCheckout()
				}
			},
			incrementPax() {
				if (this.no_of_pax_edit == 1000) {
					return
				}
				this.no_of_pax_edit = Number(this.no_of_pax_edit) + 1
			},
			decrementPax() {
				if (this.no_of_pax_edit <= 1) {
					this.no_of_pax_edit = 1
				} else {
					this.no_of_pax_edit = Number(this.no_of_pax_edit) - 1
				}
			},
			async createdOrderTypeUpdate() {
				this.order.type = 'Take Away'
				try {
					let params = {
						orderType: 'Take Away',
						orderId: '',
						update: false,
						is_delivered: false
					}
					let res = await this.orderUpdateType(params)
					this.order._id = res.order_id
					this.order.type = 'Take Away'
					this.selectTable = false
					this.fetchGuestCustomer()
				} catch (reason) {
					this.$toasted.global.error(reason)
				}
			},
			async getCustomer(id) {
				try {
					let params = {
						_id: id
					}
					let res = await this.getCustomerById(params)
					this.singleCustomer = res.response
				} catch (error) {
					this.$swal('Error', error.response, 'error')
				}
			},
            async placeOrder() {
                try {
                    let params = {
                        orderId: this.$route.query.order_id
                    };
                    console.log(params)
                    this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    this.modal_msg = res.data.reason;
                    if (res.data.status_id == 1) {
                        this.$store.commit("setCashRegister",res.data.response.cash_register_details);
                        this.orderPlace(params).then(res => {
                        this.responseMessage = res.response.message
                        this.movePage();
                        })
                    } else {
                        this.$toasted.global.error(res.data.reason);
                    }
                    });
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async updateOrder() {
                try {
                    let params = {
                        orderId: this.$route.query.order_id
                    };
                    let res = await this.orderUpdateOrder(params);
                    this.responseMessage = res.message
                    this.movePage();
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            payOrder(){
                this.$router.push(`/place_order/payment_screen?order_id=${this.$route.query.order_id}`)
            },
            movePage() {
                this.$toasted.global.success(this.responseMessage);
                this.closeCheckout()
            },
            closeCheckout(flag) {
                this.$router.push({
                    name: 'MyOrder',
                    params: {
                        id: this.order._id,
                        orderType: this.order.type
                    }
                })
                this.CheckoutShow = false
                this.itemspageShow = true
                this.handleclick = true
                setTimeout(() => {
                    this.handleclick = false
                }, 2000);
            },
		},
		watch: {
			'order.type'(newType, oldType) {
				if(newType == 'Dine In') this.selectTable = true
				else this.selectTable = false
				// if (this.order.tables && this.order.tables.length) this.clearTables()
			},
			selectedCustomer(newCustomer, oldCustomer) {
				this.searchCustomer = false
				if (newCustomer) this.order.customerId = newCustomer._id
				else this.order.customerId = null
			},
			singleCustomer(newCustomer) {
				this.selectedCustomer = newCustomer
			},
			selectedCategory(newValue, oldValue) {
				if (newValue && newValue.name) {
					let name = newValue.name.replace(/[^a-zA-Z]/g, '')
					this.$refs['category_' + name][0].click()
				}
			},
			selectedVendor(newValue, oldValue) {
				this.selectedCategory = 'All'
			},
			showAddedItems(newValue, oldValue) {
				if (typeof newValue == 'boolean') {
					this.categories = []
				} else {
					this.showAddedItems = false
				}
			},
			showAvailableItems(newValue, oldValue) {
				if (typeof newValue == 'boolean') {
					this.categories = []
				} else {
					this.showAvailableItems = false
				}
			},
			'order.customerId'(newCustomer, oldCustomer) {
				if (newCustomer && !this.editOrder) {
					this.updateCustomer()
				}
			},
			categories: function () {
				let currIndex = this.$refs.slick.currentSlide()
				this.$refs.slick.destroy()
				if(this.handleclick == false){
					this.$nextTick(() => {
						this.$refs.slick.create()
						this.$refs.slick.goTo(currIndex, true)
					})
				}
			},
			sub_categories: function () {
				let currIndex = this.$refs.sub_category.currentSlide()
				this.$refs.sub_category.destroy()
				if(this.handleclick == false){
					this.$nextTick(() => {
						this.$refs.sub_category.create()
						this.$refs.sub_category.goTo(currIndex, true)
					})
				}
			},
			product_types: function () {
				if(this.$refs.product_types != undefined){
					let currIndex = this.$refs.product_types.currentSlide()
					this.$refs.product_types.destroy()
					if(this.handleclick == false){
						this.$nextTick(() => {
						this.$refs.product_types.create()
						this.$refs.product_types.goTo(currIndex, true)
						})
					}
				}
			},
		},
		created() {
			const dict = {
				custom: {
					pax: {
						required: () => 'Guests is Required',
						numeric: () => 'Guests should be Numeric',
						min_value: () => 'Guests should be greater than 0'
					}
				}
			}
			this.$validator.localize('en', dict)
			EventBus.$on('createdCustomerId', newId => {
				this.getCustomer(newId)
			})
			EventBus.$on('loadProductList', () => {
				let params = {
					category_id: this.new_selected_category_id,
					sub_category_id: this.new_selected_sub_category_id,
					product_type_id:this.new_selected_product_type_id,
					order_id:this.order._id
				}
				this.loadProductListing(params);
				this.fetchCart();
			})
			EventBus.$on('closeTableSelect', table => {
				if(this.tableSelect){
					this.getOrderDetails()
				}
				this.itemspageShow = true
				this.tableSelect = false
			})
			EventBus.$on('updateTables', table => {
				this.change_table(table.old_table_id, table.new_table_id, this.order._id)
			})
			EventBus.$on('updateTablesNew', table => {
				this.updateTable()
			})
		},
		mounted() {
            if(this.$route.query.order_id){
                this.order._id = this.$route.query.order_id
                this.getOrderDetails()
            }
            // if(this.$route.query.hasOwnProperty('order_type')){
            //     this.loadCustomerById()
            // }
			this.loadProductListing({});
			EventBus.$emit('customerdetailsLoad', false)
			EventBus.$emit('settingsLoad', false)
			EventBus.$emit('reportsLoad', false)
			EventBus.$on('UpdateOrderType', value => {
				this.order.type = 'Take Away'
				this.selectTable = false
				this.order.tables = []
			})
			EventBus.$on('callProducts',temp_index => {
				this.courseSelected = temp_index
			})
		  if(this.$route.query.from_edit){
			this.temp_edit_order = Boolean(localStorage.getItem("edit_item"))
			if (this.temp_edit_order == true) {
				this.editOrder = true
			}
		  }
		},
		destroyed() {
			EventBus.$off('loadProductList')
		}

		,
		computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser
			},
			fastcheckoutEnable(){
				return this.loggedInUser.enable_fast_checkout
			}
		},
		beforeDestroy() {
			localStorage.removeItem('order_data_for_checkout')
		}
	}
</script>
<!-- <style>
	#lazy-load {
		height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	#lazy-load::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	.product-filter {
		border-radius: 4px;
		font-size: 14px !important;
		text-transform: capitalize;
		width: 175px;
		font-weight: 600;
		line-height: 2.6rem;
		height: 41px;
		color: #00448b;
		white-space: nowrap;
		display: flex;
		align-items: center;
		user-select: none;
		padding: 0.775rem 0.8rem;
	}

	.product-type-filter {
		border: 1.5px solid #00448b !important;
	}

	.categoryLine {
		position: relative;
		bottom: 11px;
		border-bottom: 0.1px solid #d2d2d2;
	}

	.productData {
		width: 240px;
		margin: 15px auto;
		display: block;
	}
	.no-prod {
		font-size: 16px;
		font-weight: 700;
	}
	.imageOrders {
		background: #fff;
		position: absolute;
		padding: 15px;
		border-radius: 12px;
	}

	.selectedBackground {
		box-shadow: 0 1px 6px 0 rgba(28, 141, 255, 0.34);
		background: #00448b !important;
	}

	@media (min-width: 768px) {
		.header-takeorder.col-md-6 {
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 44%;
			-ms-flex: 0 0 44%;
			flex: 0 0 44%;
			max-width: 44%;
		}
	}

	.skeleton-loader {
		padding: 10px;
	}

	@media screen and (max-width: 590px) {
		.checkoutTable {
			margin-top: 182px;
		}

		.applyTable {
			margin-top: 13px;
			margin-bottom: 129px;
		}
	}

	@media screen and (max-width: 766px) {
		.marginCategory {
			margin-top: 103px !important;
		}
	}

	.no-for-pax {
		border: 1px solid #eee !important;
		border-radius: 5px;
		padding-left: 10px;
		background-color: #fbfbfb;
		color: #303031;
		height: 30px;
		width: 100px;
		text-align: center;
		font-weight: 600;
	}

	.page-headerr,
	.course-header {
		position: fixed !important;
		-webkit-background-size: cover;
		background-size: cover;
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-color: #fafafa;
		box-shadow: 13px 3px 10px -1px rgba(0, 0, 0, 0.16);
		z-index: 99 !important;
		padding-bottom: 10px;
	}

	.unavailable-image {
		position: absolute;
		vertical-align: middle;
		top: 28%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: red;
		font-weight: 600;
		height: 26px;
		line-height: 26px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.88);
		text-align: center;
	}

	.mansory-div.product-div {
		display: -webkit-inline-box;
		padding: 0 6px;
		margin: 0 5px;
		width: 200px;
	}

	.masonry-img-items-cdn {
		width: 192px !important;
		height: 128px !important;
		border-radius: 5px;
	}

	.elselect-variant .el-input__prefix,
	.el-input__suffix {
		top: 4px !important;
	}

	.a-link-select-table {
		text-decoration: none !important;
	}

	#takeorderdiv .card-body .el-input.is-disabled .el-input__inner {
		background-color: transparent !important;
		border-color: #e4e7ed;
		cursor: not-allowed;
	}

	.btnqty {
		cursor: pointer;
	}

	.loading-click {
		position: absolute;
		height: 100%;
		width: 100%;
		text-align: center;
		vertical-align: middle;
		z-index: 99999 !important;
		font-size: 20px;
		color: #303031;
	}

	.no-results {
		width: 100%;
		text-align: center;
		padding-top: 5%;
	}

	.icon-information {
		cursor: pointer;
	}

	.card-title-product-name.product-name-card {
		overflow: hidden;
		white-space: normal;
		text-overflow: ellipsis;
		width: auto !important;
		display: inline-block;
		text-align: center;
	}

	.items-mansory.product-card .card-body {
		padding: 0.5rem !important;
		border-left: 0px solid #a1caff !important;
		margin-bottom: 6px !important;
		border-radius: 3px;
	}

	.card.items-mansory.product-card {
		box-shadow: none !important;
		margin-bottom: 15px !important;
		border-radius: 8px;
		width: 200px;
		border: 1px solid #00448b !important;
	}

	.card.items-mansory.product-card.image-unavailable {
		opacity: 0.6 !important;
		pointer-events: none;
	}

	.variant-span {
		color: #303031 !important;
		padding-right: 6px;
	}

	.elselect-variant .el-input__inner {
		border-bottom: 0px !important;
		color: #303031 !important;
	}

	.selectCustomer {
		width: 93%;
		height: 60px;
		box-shadow: -2.5px 0.5px 3px 0 rgba(0, 0, 0, 0.16);
		z-index: 9;
		position: absolute;
		background-color: #ffff !important;
		top: 84px;
	}

	.ribbon-clip.ribbon-reverse {
		right: -14px;
		left: auto;
	}

	.ribbon {
		position: absolute;
		top: -3px;
		left: -3px;
		width: 150px;
		height: 150px;
		text-align: center;
		background-color: transparent;
	}

	.ribbon-clip.ribbon-reverse .ribbon-inner {
		padding-right: 23px;
		padding-left: 15px;
		border-radius: 5px 0 0 5px;
		background-image: -webkit-gradient(linear,
				0% 30%,
				50% top,
				color-stop(0%, rgba(204, 99, 40, 0)),
				color-stop(40%, rgba(204, 99, 40, 0)),
				color-stop(90%, rgba(204, 99, 40, 0.3)),
				color-stop(100%, rgba(204, 99, 40, 0))),
			-webkit-gradient(linear, 50% 30%, 100% top, color-stop(0%, rgba(204, 99, 40, 0)), color-stop(25%, rgba(204, 99, 40, 0.3)), color-stop(50%, rgba(204, 99, 40, 0)), color-stop(80%, rgba(204, 99, 40, 0.3)), color-stop(100%, rgba(204, 99, 40, 0)));
		background-size: 200%;
		background-position: right;
		-webkit-animation-name: flagwave;
		-webkit-animation-duration: 10s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
	}

	@-webkit-keyframes flagwave {
		0% {
			background-position: -400px 0px, -400px 0px, -400px 0px, -400px 0px;
		}

		100% {
			background-position: 800px 0px, 800px 0px, 800px 0px, 800px 0px;
		}
	}

	.ribbon-primary .ribbon-inner {
		background-color: rgba(232, 40, 40, 0.95);
	}

	.ribbon-reverse .ribbon-inner {
		right: 0;
		left: auto;
	}

	.ribbon-inner {
		position: absolute;
		top: 16px;
		left: 0;
		display: inline-block;
		max-width: 100%;
		height: 27px;
		padding-right: 20px;
		padding-left: 20px;
		overflow: hidden;
		line-height: 27px;
		color: #fff;
		text-overflow: ellipsis;
		white-space: nowrap;
		background-color: #616161;
	}

	.ribbon-primary.ribbon-clip.ribbon-reverse:before {
		border-right-color: transparent;
		border-left-color: rgba(232, 40, 40, 0.95);
	}

	.ribbon-primary.ribbon-clip:before {
		border-top-color: rgba(232, 40, 40, 0.95);
		border-right-color: rgba(232, 40, 40, 0.95);
	}

	.ribbon-clip.ribbon-reverse:before {
		right: 0;
		left: auto;
		border-right-color: transparent;
		border-left-color: #424242;
	}

	.ribbon-clip:before {
		position: absolute;
		top: 43px;
		left: 0;
		width: 0;
		height: 0;
		content: '';
		border: 7px solid transparent;
		border-top-color: #424242;
		border-right-color: #424242;
	}

	.items-mansory.product-card .card-title {
		font-weight: 600 !important;
		color: #0d84f2 !important;
		font-size: 14px;
		margin-top: 0px;
		padding-top: 0px;
		margin-bottom: 0px;
	}

	.el-select.elselect-variant {
		width: initial !important;
	}

	.btn-checkout {
		border-radius: 23px;
		background-image: linear-gradient(to bottom, #ffb333, #e29005);
		color: #303031;
		padding: 0px 10px;
		margin: 0px;
		font-size: 13px !important;
		font-weight: 600;
		height: 3.2rem;
		line-height: 2.6rem;
		text-transform: capitalize;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
			0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.btn-checkout i {
		color: #303031;
		font-size: 19px !important;
		font-weight: 600;
		padding-right: 6px;
	}

	.el-checkbox__label {
		display: inline-block;
		padding-left: 5px;
		line-height: 19px;
		font-size: 12px !important;
	}

	.card.items-mansory.product-card:hover {
		box-shadow: 0 0 4px #b6b6b6 !important;
		z-index: 1;
		border-radius: 7px;
	}

	.btn-variants .btn {
		background-color: transparent;
		border-bottom: 0px solid #eee;
		text-transform: capitalize;
		padding: 0px;
	}

	.border-variant.product-price {
		border-top: 0px solid transparent !important;
		padding-top: 8px;
		padding-bottom: 1px;
		font-size: 14px;
		color: #303031;
		font-weight: 600;
	}

	.vselectcategory {
		position: relative !important;
		display: block !important;
	}

	.add-customer-order .dropdown-toggle.clearfix {
		/* border-radius: 50px!important; */
		background-color: #f7f7f7;
		border-bottom: 0px !important;
	}

	.add-customer-order .dropdown-toggle.clearfix input {
		padding-left: 15px !important;
	}

	#takeorderdiv .card-title {
		background-color: transparent !important;
		height: 40px;
	}

	.qtyinput {
		border-radius: 4px;
		background-color: #ecf3ff;
		border: 1px solid #e6e6e6 !important;
		color: #00448b;
		text-align: center;
		font-weight: 600;
		height: 30px;
		width: 74%;
		padding-top: 0px;
	}

	.img-add-button-svg {
		cursor: pointer;
	}

	.masonry-img-items {
		width: 192px !important;
		height: 128px !important;
		border-radius: 5px !important;
	}

	.label-header {
		font-weight: 600;
		color: #00448b;
		font-size: 13px;
	}

	#takeorderdiv .search {
		opacity: 0.74;
		border-radius: 5px;
		box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
		background-color: #f2f2f2 !important;
		height: 34px !important;
		line-height: 20px !important;
	}

	#takeorderdiv .search input {
		background-color: #f2f2f2 !important;
	}

	.v-select.vselect-category {
		height: 34px !important;
		line-height: 20px !important;
		border-radius: 50px;
		box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
		background-color: #f2f2f2;
	}

	.v-select.vselect-category .dropdown-toggle {
		border: 0px solid rgba(60, 60, 60, 0.26) !important;
		border-bottom: solid 0px #cdd2da !important;
		border-radius: 0px !important;
		white-space: normal !important;
	}

	.v-select.vselect-category ul.dropdown-menu {
		background-color: #fff !important;
	}

	.vselect-category .form-control {
		padding: 10px !important;
	}

	.v-select.vselect-category .selected-tag {
		padding-left: 10px !important;
		padding-top: 2px !important;
		color: rgba(0, 0, 0, 0.54) !important;
	}

	.table.table-order td {
		padding: 6px !important;
	}

	.table.table-order tr.add-customer-tr {
		border-bottom: 1px solid #00448b !important;
		border-top: 1px solid #00448b !important;
		cursor: pointer;
	}

	@media (max-width: 1030px) {
		.span-order {
			display: none;
		}
	}

	.span-order {
		color: #00448b;
		font-weight: 600;
		padding-right: 12px;
	}

	.span-add-customer {
		color: #00448b;
		font-weight: 600;
	}

	.span-cart-empty {
		font-weight: 600;
		color: #303031;
	}

	.heading-category {
		font-weight: bold;
		color: #303031;
		font-size: 14px;
	}

	.lightYellow {
		color: #f5a623;
		font-size: 20px;
	}

	.btn-yellow {
		-webkit-border-radius: 100px;
		-moz-border-radius: 100px;
		border-radius: 100px;
		box-shadow: 0.5px 1px 1.5px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffa104;
		color: #fff;
		text-transform: capitalize;
		height: 28px;
		line-height: 26px;
		font-weight: 600;
	}

	.btn-yellow.addbtn {
		width: 100% !important;
		float: right;
	}
	.snackbar {
		font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;
		font-size: 14px;
		color: #fff;
		text-align: left;
		font-weight: 600;
		border-radius: 12px;
		box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
	}

	.snackbar[class*='bottom'] {
		z-index: 999999 !important;
		background-color: #00448b !important;
		width: 50%;
		top: 10.5rem;
		bottom: inherit;
	}

	.notification-cart .badge-for-cart {
		padding: 1px 4px !important;
		font-size: 10px;
		border-radius: 100px;
		background: red;
		left: -11px;
		top: -7px;
		position: relative;
		color: #fff;
		font-weight: 600;
	}

	.total-take-order {
		font-weight: 600;
	}

	.total-amount {
		color: #00448b;
		padding-left: 8px;
	}

	#takeorderdiv .loading-click {
		height: 68% !important;
	}

	.paidBill .el-switch__core {
		border: 1px solid #ff2424 !important;
		background: #ff2424 !important;
	}

	.paidBill.el-switch.is-checked .el-switch__core {
		border-color: #1a9347 !important;
		background-color: #1a9347 !important;
	}

	.categoryselected {
		background: #005dae;
		border-radius: 12px;
		cursor: pointer;
	}

	.categoryunselected {
		background: #fff;
		border-radius: 12px;
		cursor: pointer;
	}

	.categoryLayout {
		background: #f3f7fa;
	}

	.borderBottom {
		border-bottom: 1px solid #eeeeee;
	}

	.items-course {
		background: #00448b !important;
		border-radius: 50% !important;
		padding: 6px;
		border: 1px solid #00448b !important;
	}

	.items-course a {
		color: #fff !important;
	}

	.whiteSpace {
		white-space: nowrap !important;
	}

	.pointerEventsNone {
		pointer-events: none !important;
	}

	.padding-top-responsiveness {
		padding-top: 140px;
	}


	.img-un {
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.53;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}

	@media screen and (max-width: 991px) {
		.padding-top-responsiveness {
			padding-top: 300px;
		}
	}

	#lazy-load-checkout-product {
		height: auto;
		/* overflow-y: auto;
		overflow-x: hidden; */
		margin-bottom: 30px;
		-ms-overflow-style: none;
		/* IE 11 */
		scrollbar-width: none;
		/* Firefox 64 */
	}

	/* #lazy-load-checkout-product::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	} */

	.course-head {
		top: 25%;
		left: 2px;
		right: 10px;
		border-radius: 10px;
	}

	.course-title {
		color: #00448b !important;
		font-size: 17px;
		padding-top: 10px;
		display: flex;
		justify-content: center
	}

	.flex-container {
		display: flex;
		width: 95%;
	}

	.flex-container>div {
		background-color: #fff;
		margin: 10px;
		padding: 10px;
		font-size: 30px;
	}

	.categories {
		margin: 0 0 2px;
		background-color: #e1edf9;
		font-size: 14px;
		font-weight: 800 !important;
		padding: 0.4rem 0rem 0.4rem 1rem;
	}

	.sub-categories {
		padding: 4px 8px 4px 22.5px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 500;
    	color: #303031 !important;
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

	.sub-categories.subcat-selected {
		width: auto !important;
		height: auto !important;
		background-color: #00448b;
		color: #fff !important;
	}

	.item-img {
		width: 250px;
	}

	.page {
		padding-top: 15px;
	}
	.course-table {
		padding-bottom: 15px;
	}
	.align-items-start {
		align-items: flex-start !important;
	}
	.flex-column {
		flex-direction: column !important;
	}
	.flex-column-fluid {
		flex: 1 0 auto;
	}

	.align-items-stretch {
		align-items: stretch !important;
	}

	.d-flex {
		display: flex !important;
	}
	.flex-root {
		flex: 1;
	}

	.asideLeft {
		flex-shrink: 0;
	}
	.hover-scroll-overlay-y {
		position: relative;
		height: 430px;
		min-height: 100%;
    	max-height: 100%;
	}
	.pe-4 {
		padding-right: 1rem !important;
	}
	.me-1 {
		margin-right: 0.25rem !important;
	}
	.w-100 {
		width: 100% !important;
	}
	.sidebar-cart {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		border-radius: 4px;
		box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
		background-color: #fff;
	}
	.flex-row-fluid {
		flex: 1 auto;
		min-width: 0;
	}
	.mb-10 {
		margin-bottom: 2.5rem !important;
	}
	.flex-column {
		flex-direction: column !important;
	}
	.flex-wrap {
		flex-wrap: wrap !important;
	}
	.mb-5 {
		margin-bottom: 1.25rem !important;
	}
	.flex-stack {
		justify-content: space-between;
		align-items: center;
	}
	.py-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.my-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.align-items-center {
		align-items: center !important;
	}
	.fw-bold {
		font-weight: 600 !important;
	}
	.fs-1 {
		font-size: calc(1.3rem + 0.6vw) !important;
	}
	.text-darkblue {
		color: #00448b !important;
	}
	.me-2 {
		margin-right: 0.5rem !important;
	}
	.me-3 {
		margin-right: 1.8rem !important;
	}
	.course-slick.courseSlick.slick-initialized.slick-slider{
		margin: 0 0px!important;
	}
	.course-slick.courseSlick.slick-initialized .slick-slide div{
		text-decoration: none!important;
		outline: none;
	}
	.course-slick.courseSlick.slick-initialized .slick-slide .present-course div{
		text-decoration: none!important;
		outline: none;
		color: #fff!important;
	}
	.course-slick.slick-initialized .slick-slide .previous-course div{
		text-decoration: none!important;
		outline: none;
		color: #fff!important;
	}
	.course-slick.slick-initialized .slick-slide .items-course div{
		text-decoration: none!important;
		outline: none;
		color: #303031!important;
	}
	.slick-slider {
		padding: 0px 0px !important;
	}
	.slick-track{
		margin-left: 0 !important;
		margin-right: 0 !important;
		width: auto !important;
	}
	.courses-no {
		display: inline-block;
		flex-shrink: 0;
		position: relative;
		border-radius: 0.475rem;
	}
	.courses-no .courses-no-label {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 15px;
		color: #303031;
		background-color: #fff;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-radius: 50%;
  		box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	}
	.present-course {
		background-color: #149c6e !important;
		color: #fff !important;
	}
	.course-selected {
		background-color: #00448b!important;
		color: #fff !important;
	}
	.courses-no.courses-no-45px .courses-no-label {
		width: 36px;
		height: 36px;
		line-height: 36px;
	}
	.bg-dark-success {
		background-color: #149c6e !important;
	}
	.tableno-ordertype {
		border-top: 1px solid #08448b !important;
	}
	.text-titles {
		color: #08448b !important;
		font-size: 12px;
		padding-right: 5px;
		font-weight: 600 !important;
	}
	.table_no {
		color: #08448b !important;
		font-size: 12px;
		font-weight: 600 !important;
		padding-right: 10px;
	}
	.seperator {
		color: #08448b !important;
		font-size: 13px;
		padding-right: 15px;
		font-weight: 600 !important;
	}
	.order-type-selected {
		color: #08448b !important;
		font-size: 12px;
		font-weight: 600 !important;
	}
	.btn-small {
		height: 28px !important;
		padding: 0 10px !important;
		border-radius: 18px !important;
		font-size: 12px !important;
		line-height: 28px !important;
		text-transform: none;
		font-weight: 600 !important;
		min-width: 5.8rem;
	}
	.btn-green-small {
		background-color: #045c3a !important;
		border-color: #045c3a !important;
		box-shadow: none !important;
		color: #fff !important;
	}
	.no-for-pax.paxno {
		border: 1px solid #08448b !important;
		border-radius: 5px;
		padding-left: 10px;
		background-color: #fbfbfb;
		color: #303031;
		height: 25px;
		width: 65px !important;
		text-align: center;
		font-weight: 600 !important;
	}
	.flex-grow-1 {
		flex-grow: 1 !important;
	}
	.w-200px {
		width: 200px;
	}
	.menu, .menu-wrapper {
		display: flex;
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.fs-6 {
		font-size: 1.075rem !important;
	}
	.menu-column {
		flex-direction: column;
		width: 100%;
	}
	.menu-item {
		display: block;
		padding: 0.15rem 0;
	}
	.menu-item.menu-subcat {
	}
	.menu-item .menu-content {
		padding: 1rem;
	}
	.menu-item .menu-link {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 0;
		flex: 0 0 100%;
		padding: 3px 20px;
		transition: none;
		outline: none !important;
	}
	.menu-item .menu-link .menu-title {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}
	.menu-item .menu-link.menu-selected {
		background-color: #00448b;
		border-radius: 4px;
	}
	.asideLeft .menu .menu-item > .menu-link > .menu-title {
		color: #303031;
		font-weight: 600 !important;
		font-size: 13px !important;
	}
	.asideLeft .menu .menu-item > .menu-link.menu-selected > .menu-title {
		color: #fff;
		font-weight: 600;
	}
	.menu-hover-bg .menu-item.hover:not(.here) > .menu-link.menu-title:not(.disabled):not(.active):not(.here), 
	.menu-hover-bg .menu-item:not(.here) .menu-link.menu-title:hover:not(.disabled):not(.active):not(.here) {
		transition: color 0.2s ease;
		background-color: #00448b;
		color: #fff;
	}
	.menu-item .menu-content .menu-section {
		font-size: 16px !important;
		font-weight: 800 !important;
		color: #303031 !important;
	}
	.toolbar-header {
		padding: 1rem 1rem 0.5rem 1rem;
	}
	.page-title h1 span {
		font-size: 16px !important;
		font-weight: 800 !important;
		/* color: #303031 !important; */
	}
	.menu-item .menu-link .menu-badge {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin-left: 0.5rem;
	}
	.asideLeft .menu .menu-item > .menu-link > .menu-badge {
		color: #A1A5B7;
		font-weight: 500;
		font-size: 0.9rem;
	}
	.fw-800 {
		font-weight: 800 !important;
	}
	.search-filter-header {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		min-height: 50px;
		padding: 0 1rem 0rem 1rem;
		margin-bottom: 0;
	}
	.search-filter-header {
		border-bottom: 0 !important;
	}
	.search-filter-header .filter-search {
		font-weight: 500;
		font-size: 14px !important;
		color: #303031;
	}
	.position-relative {
		position: relative !important;
	}
	.svg-icon {
		line-height: 1;
		color: #00448b;
	}
	.position-absolute {
		position: absolute !important;
	}
	.svg-icon svg {
		height: 1.15rem;
		width: 1.15rem;
	}
	.svg-icon.svg-icon-1 svg {
		height: 1.75rem !important;
		width: 1.75rem !important;
	}
	svg {
		vertical-align: middle;
	}
	.ms-6 {
		margin-left: 1.5rem !important;
	}
	.ms-4 {
		margin-left: 1rem !important;
	}
	.search-control {
		display: block;
		width: 100%;
		padding: 0.775rem 1rem;
		font-weight: 500;
		line-height: 1.5;
		color: #303031 !important;
		background-color: #ffffff !important;
		background-clip: padding-box;
		border: 1.5px solid #bfbdbd !important;
		appearance: none;
		border-radius: 4px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		box-shadow: none !important;
		font-size: 15px !important;
	}
	.search-empty {
		padding-top: 1px;
		font-size: 20px !important;
		cursor: pointer !important;
		position: absolute;
		right: 0px;
		color: #08448b;
	}
	.ps-15 {
		padding-left: 3.75rem !important;
	}
	.w-400px {
		width: 400px !important;
	}
	.no-of-items {
		font-size: 15px;
		font-weight: 600;
		padding-right: 10px;
	}
	.fw-900 {
		font-weight: 900 !important;
	}
	.min-h-40px {
		min-height: 0px !important;
		padding: 0rem 1.3rem;
	}
	.text-darker {
		color: #303031 !important;
	}
	.fw-bold {
		font-weight: 600 !important;
	}
	.w-55px {
		width: 55px !important;
	}
	.tagify {
		min-height: calc(1.5em + 1.55rem + 2px);
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
		display: flex;
		align-items: center;
		gap: 0.25rem;
		border: 0 !important;
	}
	.tagify__tag {
		display: inline-flex;
		align-items: center;
		margin: 0px 0px 5px 5px !important;
		position: relative;
		z-index: 1;
		outline: 0;
		line-height: normal;
		cursor: default;
		transition: 0.13s ease-out;
	}
	.tagify .tagify__tag {
		background-color: #f2f6fd;
		line-height: 1;
	}
	.tagify__tag {
		padding: 0.6rem 0.4rem;
		border-radius: 25px;
	}
	.tagify__tag__removeBtn {
		order: 5;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		cursor: pointer;
		font: 14px/1 Poppins;
		background: transparent;
		color: transparent;
		width: 14px;
		height: 14px;
		margin-right: 4.6666666667px;
		margin-left: auto;
		overflow: hidden;
		transition: 0.2s ease-out;
	}
	[role=button] {
		cursor: pointer;
	}
	.tagify .tagify__tag .tagify__tag__removeBtn {
		width: 1rem;
		height: 1rem;
		margin: 0 0.5rem 0 0;
		border-radius: 0;
		content: " ";
		mask-repeat: no-repeat;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: center;
		background-color: #0d84f2;
		-webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--kt-gray-500%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
		mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--kt-gray-500%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
	}
	.tagify__tag > div {
		vertical-align: top;
		box-sizing: border-box;
		max-width: 100%;
		padding: 0 0.5rem;
		color: #0d84f2;
		line-height: inherit;
		border-radius: 3px;
		white-space: nowrap;
		transition: 0.13s ease-out;
	}
	.tagify__tag > div::before {
		content: "";
		position: absolute;
		border-radius: inherit;
		z-index: -1;
		pointer-events: none;
		transition: 120ms ease;
		animation: tags--bump 0.3s ease-out 1;
	}
	.tagify .tagify__tag div {
		border-radius: inherit;
	}
	.tagify .tagify__tag .tagify__tag-text {
		overflow: visible;
	}
	.tagify__tag > div > * {
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		vertical-align: top;
		min-width: 1ch;
		max-width: auto;
		transition: 0.8s ease, 0.1s color;
	}
	.tagify__tag .tagify__tag-text {
		font-size: 13px;
		font-weight: 600 !important;
	}
	.gap-5 {
		gap: 1.25rem !important;
	}
	.poscard {
		border: 1px solid #00448b;
		box-shadow: none;
		background-color: #fff;
		position: relative;
		display: flex;
		flex-direction: column;
		border-width: 1px;
		border-color: #eff2f5;
		border-radius: 0.625rem;
		box-shadow: none;
		border-radius: calc(0.625rem - 1px);
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
		position: relative;
		display: flex;
		flex-direction: column;
		word-wrap: break-word;
		background-clip: border-box;
	}
	.mw-100 {
		max-width: 100% !important;
	}
	.poscard-body {
		border: 1px solid #00448b;
		border-radius: 10px;
		flex: 1 1 auto;
		padding: 0;
		position: relative
	}
	.rounded-3 {
		border-radius: 0.625rem !important;
	}
	.h-150px {
		height: 150px !important;
	}

	.w-150px {
		width: 150px !important;
	}
	.empty-cart {
		opacity: 0.97;
		border-radius: 3px;
		box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%);
		background-color: #fff;
		padding: 10px;
		margin: 10px 10px;
	}
	.overflow-auto {
		background-color: #fff;
		margin: 10px 4px;
		overflow-y: auto !important;
		overflow-x: hidden !important;
		max-height: 330px;
		min-height: 330px;
	}
	.min-w-340px {
		min-width: 340px !important;
	}
	.rounded {
		border-radius: 0.475rem !important;
	}

	.min-h-50px {
		min-height: 65px !important;
	}
	.py-3 {
		padding-top: 0.75rem !important;
		padding-bottom: 0.75rem !important;
	}
	.px-3 {
		padding-right: 0.75rem !important;
		padding-left: 0.75rem !important;
	}
	.border {
		border: 1.5px solid rgba(0, 68, 139, 0.5) !important;
	}
	.min-w-150px {
		min-width: 150px !important;
	}
	.fs-5 {
		font-size: 1.15rem !important;
	}
	.min-w-125px {
		min-width: 125px !important;
	}
	.pe-2 {
		padding-right: 0.5rem !important;
	}
	.min-w-50px {
		min-width: 50px !important;
	}
	.cart-count {
		border: 1px solid #08448b !important;
		border-radius: 5px;
		padding-left: 10px;
		background-color: #fbfbfb;
		height: 27px;
		width: 55px !important;
		text-align: center;
		font-weight: 600 !important;
		color: #08448b;
		font-size: 13px !important;
	}
	.modifiers-list {
		background: #f2f2f2;
		color: #303031;
		padding: 2px 8px;
		border-radius: 24px;
		font-weight: 600;
		font-style: italic;
		font-size: 13px !important;
		display: inline-block;
	}
	.modifiers-qty {
		color: #303031;
		font-size: 13px !important;
		font-weight: 600;
		font-style: italic;
	}
	.modifiers-price {
		color: #303031;
		font-size: 13px !important;
		font-weight: 600;
		font-style: italic;
	}
	.wrapper-course {
		border-radius: 4px;
		box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
		background-color: #fff;
		padding: 15px;
	}
	.wrapper-table {
		border-radius: 4px;
		box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
		background-color: #fff;
		margin-left: 12px;
		padding: 12px 10px 0px;
	}
	@media (min-width: 786px) {
		.w-lg-300px {
			width: 385px !important;
		}
		.sidebar-cart {
			width: 385px;
			margin-left: 12px;
		}
		.asideLeft {
			display: flex;
			width: 150px;
			border-radius: 4px;
  			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  			background-color: #fff;
			max-height: 415px;
    		min-height: 300px;
		}
	}
	@media (min-width: 992px) {
		.asideLeft {
			display: flex;
			width: 200px;
			border-radius: 4px;
  			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  			background-color: #fff;
			max-height: 415px;
    		min-height: 300px;
		}
		.container-xxl {
			padding: 0 10px;
		}
		.flex-lg-row-fluid {
			flex: 1 auto;
			min-width: 0;
		}
		.wrapper-take-order {
			border-radius: 4px;
  			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  			background-color: #fff;
			margin-left: 12px;
			max-height: 415px;
    		min-height: 300px;
		}
		.wrapper-course {
			border-radius: 4px;
  			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  			background-color: #fff;
			padding: 15px;
		}
		.wrapper-table {
			border-radius: 4px;
  			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  			background-color: #fff;
			margin-left: 12px;
			padding: 12px 10px 0px;
		}
		.ps-lg-0 {
			padding-left: 0 !important;
		}
		.my-lg-5 {
			margin-top: 1.25rem !important;
			margin-bottom: 1.25rem !important;
		}
		.my-lg-2 {
			margin-top: 0.5rem !important;
			margin-bottom: 0.5rem !important;
		}
		.mt-lg-10 {
			margin-top: 2.5rem !important;
		}
		.hover-scroll-overlay-y {
			overflow-y: auto;
			overflow-x: hidden;
		}
		.sidebar-cart {
			width: 385px;
			margin-left: 12px;
		}
		.py-lg-11 {
			padding-top: 2.75rem !important;
			padding-bottom: 2.75rem !important;
		}
		.px-lg-0 {
			padding-right: 0 !important;
			padding-left: 0 !important;
		}
		.flex-lg-row-auto {
			flex: 0 0 auto;
		}
		.flex-lg-row {
			flex-direction: row !important;
		}
		.ms-lg-7 {
			margin-left: 12px !important;
		}
		.mb-lg-0 {
			margin-bottom: 0 !important;
		}
		.w-lg-300px {
			width: 385px !important;
		}
		.mb-lg-7 {
			margin-bottom: 1.75rem !important;
		}
	}
	@media (min-width: 1200px) {
		.ms-xl-10 {
			margin-left: 12px !important;
		}
		.fs-1 {
			font-size: 1.75rem !important;
		}
		.fs-3 {
			font-size: 1.35rem !important;
		}
	}
	.horizontal-layout-1 #main>.digicontainer>.dccontainer {
		background: rgb(245, 246, 249);
	}
	.course-padding{
		padding: 6px;
		border: 1px solid #00448b;
		border-radius: 50%;
	}
	.double-arrow-icon {
		right: 3px;
		bottom: 0px;
		transform: rotate(180deg);
	}
	.double-arrow-icon-rotate {
		transform: rotate(0deg);
	}
	.w-165px {
		width: 165px;
	}
	.justify-content-between {
		justify-content: space-between !important;
	}
	.productCount {
		font-size: 15px;
		color: #303031;
		font-weight: 600;
		line-height: 15px;
	}
	.fs-18 {
		font-size: 18px !important;
	}
</style> -->