<template>
    <div class="main-bg">
        <div class="cr-backimg" :style="bgCover">
            <div class="d-flex flex-column" style="width: 100%;min-height:95%; height:100%;overflow-x:hidden;overflow-y:auto;">
                <div class="top">
                    <span class="dc dc-new" style="font-size: 25px; font-weight: 600;">DigiCollect</span>
                    <span v-if="loginPage === 'pos'" class="text-default-blue crm-right-logo" style="font-size: 20px;color: #005dae !important;"><img src="https://cdn.digicollect.com/cdn/posv2/pos_logo_new.svg" alt="DigiPOS" style="width: 35px; height: 30px;"> DigiPOS</span>
                </div>
                <div v-if="activediv == 'login'" class="middle d-flex py-10 px-20 h-100 w-100">
                    <div class="middle-left"></div>
                    <div class="middle-right pb-10 d-flex flex-column justify-content-center pl-10 ml-auto mr-auto" style="height:100%;max-width: 350px; margin-top: -5%;">
                        <span class="text-default-blue mb-5" style="font-size: 35px;color: #005dae !important;">Sign in</span>
                        <div class="pb-2">
                            <p class="text-default-heading mb-5 font-weight-bold" style="color: #4d4f5c !important;">Guest Name<span class="star ml-1">*</span></p>
                            <input type="text" v-validate="'required'" autocomplete="off" class="digi-form-full-bordered" style="max-width: 350px;" autofocus name="guest_name" placeholder="Enter Your Name" v-model="form.customer_name">
                            <span class="text-danger fw-600 fs-14 mb-5" style="text-transform: none !important;" v-show="errors.has('guest_name')">Guest Name is Required</span>
                        </div>
                        <div class="mt-2">
                            <p class="text-default-heading font-weight-bold mb-5" style="color: #4d4f5c !important;">Mobile Number<span class="star ml-1">*</span></p>
                            <vue-tel-input
                                name="userid"
                                :enabledCountryCode="true"
                                :disabledFormatting="false" :enabledFlags="false" :validCharactersOnly="true" :maxLen="15"
                                class="w-100 digi-form-full-bordered-phone" ref="telInput"
                                placeholder="Enter Mobile Number"
                                @onInput="compInfoPhone($event)"
                                data-vv-as="Mobile Number"
                                v-validate="{required: true,min:10}"
                                v-model="form.login_id">
                            </vue-tel-input>
                            <!-- <input type="text" autocomplete="off" class="digi-form-full-bordered mb-2" style="max-width: 350px;" autofocus name="userid" placeholder="Enter Your Mobile Number" v-model="form.login_id"> -->
                            <span class="text-danger fw-600 fs-14 mb-5" style="text-transform: none !important;" v-show="errors.has('userid')">Please enter a valid Phone Number</span>
                            <!-- <span v-if="is_email_phone_valid" class="text-danger fw-600 fs-14 mb-5" style="text-transform: none !important;">{{is_email_phone_valid_text}}</span> -->
                        </div>
                        <div v-if="!login_btn_disable" class="mt-8">
                            <button class="btn btn-new-primary" type="button" style="width: 160px !important;" @click="goToNext('enter_otp_screen')">Continue</button>
                        </div>
                        <div v-else class="mt-8" style="position: relative;">
                            <button class="btn btn-new-primary" style="width: 160px !important;" disabled>Loading</button>
                            <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" />
                        </div>
                    </div>
                </div>
                <div v-if="activediv == 'verify otp'" class="middle d-flex pt-5 pb-10 px-20" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column pb-10 pl-10">
                        <span class="text-default-blue mb-5" style="font-size: 35px;color: #005dae !important;">Sign in</span>
                        <h1 class="fs-14 font-weight-bold" style="color: #4d4f5c;">Verify your Mobile Number</h1>
                        <h1 class="fs-14 mb-6" style="color: #4d4f5c;">
                            Please enter  6-digit verification code sent to your <br>
                            Mobile Number <span class="text-default-blue">{{mobile_number}}</span></h1>
                        <div style="width: 36em;">
                            <span class="text-default-heading mr-10">Enter OTP Code</span>
                            <div style="min-height: 120px;">
                            <div class="d-flex align-items-start">
                                <div class="">
                                    <v-otp-input
                                    ref="otpInput"
                                    :input-classes="!otpInvalid ? 'otp-input' : 'otp-input-warning'"
                                    separator=""
                                    :num-inputs="6"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    @on-complete="otpEntered"
                                    @on-change="otpEntered"
                                    />
                                    <div class="">
                                        <span style="color: #f5a623;font-size: 14px;">OTP Valid for 15 minutes</span>
                                    </div>
                                </div>
                                <div class="resend-code">
                                    <div style="color: #3f3f3f">
                                        <button class="btn btn-new-primary pl-3 pr-3" type="button" style="width: 160px !important;" :disabled="!countdown == 0" @click="resendOtp">Resend code</button>
                                        <br>
                                        <span v-if="countdown > 0"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                    </div>
                                    <div v-if="code_resending" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                </div>
                            </div>
                            <div v-if="resend_code_success_text" class="mt-2">
                                <span class="text-default-blue fs-14">{{resend_code_success_text}}</span>
                            </div>
                            <div v-if="otpInvalid" class="mt-2">
                                <span class="text-danger fs-14">{{otp_success_error_text}}</span>
                            </div>
                            </div>
                            <div class="d-flex align-items-center mt-10 mb-5">
                                <div class="" v-if="!otp_success">
                                    <button class="btn btn-new-primary" style="width: 160px !important;" type="button" @click="goToNext('verify_otp')">Sign In</button>
                                </div>
                                <div v-else class="" style="position: relative;">
                                    <button class="btn btn-new-primary" style="width: 160px !important;" disabled>Signing In</button>
                                    <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" />
                                </div>
                                <div class="resend-code-block ml-3" :class="{'mt-21px': !countdown == 0}" style="display:none;">
                                    <div style="color: #3f3f3f">
                                        <button class="btn btn-new-primary pl-3 pr-3" type="button" style="height:3.7rem !important;width: 160px !important;" :disabled="!countdown == 0" @click="resendOtp">Resend code</button>
                                        <br>
                                        <span v-if="countdown > 0"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                    </div>
                                    <div v-if="code_resending" class="position-absolute" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center px-4 pb-4 copyright_link text-sm-right" style="bottom: 0; right: 0;">
                    <span class="copyright text-default-blue" style="color: #005dae !important;">© Copyright 2014 - {{ new Date().getFullYear() }} DigiCollect - All Rights Reserved.</span>
                </div>
            </div>
        </div>
        <delivery-location v-if="show_delivery_location" @hide-delivery-location="CloseDeliverylocation" :token="token" :customer_id="customer_id" modal_name="delivery_location" @saveLocation="saveLocation"></delivery-location>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import guest_auth from '../mixins/guest_auth';
import global from '../global'
const DeliveryLocation = () => import("./DeliveryLocation")
export default {
    data() {
        return {
            bgCover: "background: url('https://cdn.digicollect.com/cdn/posv2/crm_login_back.svg') left center no-repeat;",
            loginPage: "pos",
            show_countdown: false,
            is_email_phone_valid: false,
            is_email_phone_valid_text: '',
            login_btn_disable: false,
            code_resending: false,
            resend_code_success_text: '',
            otp_success_error_text: '',
            otp_success: false,
            mobile_number: '',
            mobile_number_valid: false,
            show_delivery_location: false,
            store_order_id: null,
            customer_id: null,
            token: null
        }
    },
    mixins: [guest_auth],
    components: {
        HollowDotsSpinner,
        DeliveryLocation
    },
    created() {
        const dict = {
            custom: {

            }
        };
        this.$validator.localize("en", dict);
    },
    methods: {
        compInfoPhone(data) {
            this.mobile_number = data.number.e164;
            this.mobile_number_valid = data.valid;
        },
        CloseDeliverylocation(){
            this.show_delivery_location = false
            setTimeout(() => {
                this.$modal.hide("delivery_location")
            }, 500);
        },
        async goToNext(type) {
            if(type == 'verify_otp') {
                if(this.otpValue == '' || this.otpValue == null || this.otpValue == undefined) {
                    this.otpInvalid = true;
                    this.otp_success_error_text = 'Please enter OTP code';
                    return;
                } else {
                    this.otp_success = true;
                    let params = {
                        phone_number: this.mobile_number,
                        otp_value: this.otpValue,
                        account_id: this.$route.query.aid,
                    }
                    if(this.$route.query.hasOwnProperty('delivery_company')){
                        params['order_type'] = this.$route.query.order_type
                    }
                    await this.$http.post("/b2c/login/otp", params).then((response)=>{
                        console.log(response.data);
                        if(response.data.status_id == 1) {
                            if(response.data.Token) {
                                this.$cookie.set("Token", response.data.Token, {
                                    expires: 7,
                                    domain: window.location.hostname,
                                });
                                this.$store.commit("setToken", response.data.Token);
                                localStorage.removeItem("edit_item")
                                localStorage.setItem("set_location",window.location.href)
                                console.log(response.data)
                                // window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&table_id=${this.$route.query.tid}&order_id=${response.data.order_id}`;
                                if(this.$route.query.hasOwnProperty('tid')){
                                    window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&table_id=${this.$route.query.tid}&order_id=${response.data.order_id}`;
                                }
                                else if(this.$route.query.hasOwnProperty('delivery_company')){
                                    this.token = response.data.Token
                                    this.store_order_id = response.data.order_id
                                    this.customer_id = response.data.customer_id
                                    localStorage.setItem("customer_id",this.customer_id)
                                    this.show_delivery_location = true
                                    setTimeout(() => {
                                        this.$modal.show("delivery_location")
                                    }, 500);
                                }
                            }
                        }
                        else if(response.data.status_id == 0 && response.data.hasOwnProperty('delete_token') && response.data.delete_token){
                            this.$cookie.delete("Token");
                            this.$cookie.delete('Token', {
                                domain: 'digicollect.com'
                            });
                            this.$cookie.delete('Token', {
                                domain: window.location.hostname
                            });
                            var res = document.cookie;
                            var multiple = res.split(";");
                            for(var i = 0; i < multiple.length; i++) {
                                var key = multiple[i].split("=");
                                document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                            }
                            setTimeout(() => {
                                localStorage.setItem("set_location",window.location.href)
                                window.location.href = localStorage.getItem("set_location")
                            }, 2000);
                        }
                        else if(response.data.status_id == 0 && !response.data.hasOwnProperty('delete_token')){
                            this.$toasted.global.error(response.data.hasOwnProperty('reason') ? response.data.reason : response.data.message)
                            // this.$toasted.global.error(response.data.message)
                            this.otp_success = false;
                            this.otpInvalid = true;
                        }
                        else {
                            this.otp_success_error_text = response.data.message;
                            this.otp_success = false;
                            this.otpInvalid = true;
                        }
                    }).catch((err)=>{
                        if(err.response.status == 400 || err.response.status == 401) {
                            this.otp_success_error_text = err.response.data.reason;
                            this.otp_success = false;
                            this.otpInvalid = true;
                        }
                    });
                }
            } else {
                this.$validator.validateAll().then(async result => {
                    if(result == true){
                        if (!this.mobile_number_valid) {
                            this.is_email_phone_valid = true;
                            this.is_email_phone_valid_text = "Please enter a valid Mobile Number";
                        } else {
                            this.is_email_phone_valid = false;
                            this.login_btn_disable = true;
                            let params
                            if(this.$route.query.hasOwnProperty('tid')){
                                params = {
                                    phone_number: this.mobile_number,
                                    customer_name: this.form.customer_name,
                                    account_id: this.$route.query.aid,
                                    table_id: this.$route.query.tid,
                                    outlet_id: this.$route.query.oid
                                }
                            }
                            else if(this.$route.query.hasOwnProperty('delivery_company')){
                                params = {
                                    phone_number: this.mobile_number,
                                    customer_name: this.form.customer_name,
                                    account_id: this.$route.query.aid,
                                    order_type: this.$route.query.order_type,
                                    delivery_company: this.$route.query.delivery_company,
                                    outlet_id: this.$route.query.oid
                                }
                            }
                            await this.$http.post("/b2c/login/otp", params).then((response)=>{
                                if(response.data.status_id == 1) {
                                    if(response.data.hasOwnProperty('message')){
                                        if(response.data.message == 'OTP Sent Successfully') {
                                            this.activediv = 'verify otp';
                                        }
                                    }
                                    else if(response.data.hasOwnProperty('Token')){
                                        this.$cookie.set("Token", response.data.Token, {
                                            expires: 7,
                                            domain: window.location.hostname,
                                        });
                                        this.$store.commit("setToken", response.data.Token);
                                        localStorage.removeItem("edit_item")
                                        localStorage.setItem("set_location",window.location.href)
                                        if(response.data.hasOwnProperty('go_to_my_order') && response.data.go_to_my_order == true){
                                            window.location.href = global.POS_URL+`/view_customer_order/`+response.data.order_id;
                                            console.log("My Order")
                                        }
                                        else if(response.data.hasOwnProperty('go_to_my_order') && response.data.go_to_my_order == false){
                                            if(this.$route.query.hasOwnProperty('tid')){
                                                window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&table_id=${this.$route.query.tid}&order_id=${response.data.order_id}`;
                                            }
                                            else if(this.$route.query.hasOwnProperty('delivery_company')){
                                                window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&order_type=${this.$route.query.order_type}&delivery_company=${this.$route.query.delivery_company}&order_id=${response.data.order_id}`;
                                            }
                                            console.log("Place Order")
                                        }
                                        // window.location.href = global.POS_URL+`/view_customer_order/`+response.data.order_id;
                                    }
                                }
                                else if(response.data.status_id == 0 && response.data.hasOwnProperty('delete_token') && response.data.delete_token){
                                    this.$cookie.delete("Token");
                                    this.$cookie.delete('Token', {
                                        domain: 'digicollect.com'
                                    });
                                    this.$cookie.delete('Token', {
                                        domain: window.location.hostname
                                    });
                                    var res = document.cookie;
                                    var multiple = res.split(";");
                                    for(var i = 0; i < multiple.length; i++) {
                                        var key = multiple[i].split("=");
                                        document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                                    }
                                    setTimeout(() => {
                                        localStorage.setItem("set_location",window.location.href)
                                        window.location.href = localStorage.getItem("set_location")
                                    }, 2000);
                                }
                                else if(response.data.status_id == 0 && !response.data.hasOwnProperty('delete_token')){
                                    this.$toasted.global.error(response.data.hasOwnProperty('reason') ? response.data.reason : response.data.message)
                                    this.login_btn_disable = false;
                                }
                            }).catch((err)=>{
                                if(err.response.status == 400 || err.response.status == 401) {
                                    this.is_email_phone_valid_text = err.response.data.reason;
                                    this.login_btn_disable = false;
                                    this.$toasted.global.error(err.response.data.reason)
                                }
                            });
                        }
                    }
                })
            }
        },
        saveLocation(){
            this.show_delivery_location = false
            this.$toasted.global.success("Customer Delivery Location Updated Successfully")
            setTimeout(() => {
                window.location.href = global.POS_URL+`/place_order?outlet_id=${this.$route.query.oid}&order_type=${this.$route.query.order_type}&delivery_company=${this.$route.query.delivery_company}&order_id=${this.store_order_id}`;
            }, 2000);
        },
        async resendOtp() {
            this.code_resending = true;
            this.otpInvalid = false;
            this.countdown = 60;
            this.countDownTimer();
            try {
                let params = {
                    phone_number: this.mobile_number,
                    customer_name: this.form.customer_name,
                    account_id: this.$route.query.aid,
                    table_id: this.$route.query.tid,
                    outlet_id: this.$route.query.oid
                }
                await this.$http.post("/b2c/login/otp", params).then((response)=>{
                    if(response.data.status_id == 1) {
                        if(response.data.message == 'OTP Sent Successfully') {
                            this.resend_code_success_text = response.data.message;
                            setTimeout(() => {
                                this.resend_code_success_text = '';
                            }, 2000);
                            this.code_resending = false;
                        }
                    }
                }).catch((err)=>{
                    if(err.response.status == 400 || err.response.status == 401) {
                        this.is_email_phone_valid_text = err.response.data.reason;
                        this.login_btn_disable = false;
                        this.code_resending = false;
                    }
                });
            }
            catch(err) {

            }
        },
        async checkUserExistence(){
            let params = {
                account_id: this.$route.query.aid,
                table_id: this.$route.query.tid,
                outlet_id: this.$route.query.oid
            }
            let response = await this.$http.post("/b2c/login/otp", params)
            console.log(response)
            if(response.data.status_id == 0){
                // this.$toasted.global.error('Deleting Old User Token.')
                this.$cookie.delete("Token");
                this.$cookie.delete('Token', {
                    domain: 'digicollect.com'
                });
                this.$cookie.delete('Token', {
                    domain: 'localhost'
                });
                this.$cookie.delete('Token', {
                    domain: window.location.hostname
                });
                var res = document.cookie;
                var multiple = res.split(";");
                for(var i = 0; i < multiple.length; i++) {
                    var key = multiple[i].split("=");
                    document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                }
                window.location.reload();
            }
        }
    },
    mounted() {
        localStorage.removeItem("set_location")
        if(this.$cookie.get("Token")) {
            this.$cookie.delete("Token");
            this.$cookie.delete('Token', {
                domain: 'digicollect.com'
            });
            this.$cookie.delete('Token', {
                domain: 'localhost'
            });
            this.$cookie.delete('Token', {
                domain: window.location.hostname
            });
            var res = document.cookie;
            var multiple = res.split(";");
            for(var i = 0; i < multiple.length; i++) {
                var key = multiple[i].split("=");
                document.cookie = key[i]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
            }
            window.location.reload();
        }
        // this.checkUserExistence()
        // let initHeight = window.innerHeight;
        // $('input, textarea')
        // .on('focusin', function (){
        //     if(window.innerHeight < initHeight && window.innerWidth < 800){
        //         // do stuff on keyboard opened...
        //         $(".copyright_link").css("position","relative");
        //     }
        // })
        // .on('focusout', function (){
        //     if(window.innerWidth < 800){
        //         initHeight = window.innerHeight; // ensure initHeight is still accurate
        //         // revert changes on keyboard closed...
        //         console.log("keyboard closed");
        //         $(".copyright_link").css("position","absolute");
        //     }
        // });
    }
}
</script>

<style scoped>
    .image-holder {
        width: 500px;
        background: #fff;
        height: 500px;
        border-radius: 50%;
        bottom: -90px;
        position: absolute;
        left: -60px;
    }
    .group-image {
        width: 350px;
        height: 350px;
        right: 60px;
        position: absolute;
        top: 60px;
    }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .middle-left {
        width: 50%;
    }
    .middle-right {
        width: 50%;
    }
    .star {
        font-size: 12px;
        color: #F44336;
    }
    @media (max-width: 1100px) {
        .middle-left {
            display: none;
        }
        .middle-right {
            width: 100%;
            justify-content: center;
        }
        .middle {
            max-width: 767px;
            margin: 0 auto;
        }
        .copyright_link {
            position: relative;
        }
    }
    @media screen and (min-width: 741px) {
        .crm-right-logo {
            margin-left: auto;
        }
    }
    @media (max-width: 740px) {
        .middle {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .middle-right {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
        .top {
            justify-content: center;
            flex-direction: column;
        }
        .copyright_link {
            position: relative;
        }
    }
    @media (max-width: 530px) {
        .resend-code{
            display: none;
        }
        .resend-code-block{
            display: block !important;
            margin-top: 3px;
        }
        .mt-21px{
            margin-top: 21px !important;
        }
        .copyright_link {
            position: relative;
        }
    }
    @media (max-width: 992px) {
        .copyright_link {
            position: relative;
            top: 110px;
        }
    }
    @media (max-width: 375px) {
        .copyright_link {
            position: relative;
            top: 110px;
        }
    }
    @media (max-width: 640px) {
        .copyright_link {
            position: relative;
            top: 110px;
        }
    }
    @media (max-width: 350px) {
        .copyright_link {
            position: relative;
            top: 110px;
        }
    }
</style>
