<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th style="width:30%;">Items</th>
          <th style="width:20%;">Qty</th>
          <th style="width:15%;">Rate</th>
          <th style="width:25%;">Discount</th>
          <th style="text-align:right;">Amount</th>
          <th style="width:10%;" v-if="!isSummary"></th>
        </tr>
      </thead>
      <tbody v-for="(vendor, vendorIndex) in order.vendors" :key="vendor._id">
        <tr style="height: 13px!important;background-color: #f6f6f6!important;">
          <td colspan="5" style="padding: 0.6rem 1.2rem!important;">
            <strong>{{vendor.name}}</strong>
          </td>
          <td class="pull-right" style="padding: 0.6rem 1.2rem!important;">
          </td>
        </tr>
        <tr v-for="(item, itemIndex) in vendor.items" :key="itemIndex">
          <th
            scope="row"
            class="text-secondary"
            v-if="item.variant_id"
            style="white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          max-width: 280px;
                                          display:inline-block;"
          >{{item.product_name + '/' + item.variant_name}}</th>
          <th scope="row" class="text-secondary" v-else>{{item.product_name}}</th>
          <td class>
            <span @click="decreaseItemQuantity(vendorIndex, itemIndex)" v-if="!isSummary">
              <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg">
            </span>
            <span class="qty-checkout" v-if="!isSummary">
              <input class="qty-input-checkout" :value="item.quantity" disabled>
            </span>
            <span class="qty-checkout" v-else>{{item.quantity}}</span>
            <span @click="increaseItemQuantity(vendorIndex, itemIndex)" v-if="!isSummary">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-yellow.svg">
            </span>
          </td>
          <td>{{item.price}}</td>
          <!-- <td>
            $0.00
            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
              <button
                class="btn btn-icon companyactions actionicons"
                style="padding: 0.4rem .8rem;"
              >
                <i class="icon icon-pencil"></i>
              </button>
            </el-tooltip>
          </td>-->
          <td v-if="!isSummary">
            {{item.discount.amount}}
            <el-popover placement="bottom" v-model="item.pop_over" width="300" trigger="click">
              <div class="row">
                <span class="defaultBlue mt-1 pl-3">
                  Select:
                  <sup>★</sup>
                </span>
                <span class="preview-elements pl-2">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="AMOUNT"
                        v-model="item.discount.type"
                        class="form-check-input"
                        :name="'item-discount'+vendor._id+item.product_id"
                        checked
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Price</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="PERCENT"
                        v-model="item.discount.type"
                        class="form-check-input"
                        :name="'item-discount'+vendor._id+item.product_id"
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Percentage</span>
                    </label>
                  </div>
                </span>
                <div class="pull-right" style="margin: 0 auto;">
                  <span @click="item.pop_over=false">
                    <i
                      class="icon icon-close"
                      style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"
                    ></i>
                  </span>
                </div>
                <div class="additionalCharges pl-2">
                  <input
                    type="number" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    class="enterCharges"
                    :id="'itemDiscount'+vendor._id+item.product_id"
                    v-model="item.discount.value"
                  >
                  <button
                    type="submit"
                    class="applyCharges"
                    @click="applyItemDiscount(vendor._id,item.product_id, item.order_item_id, item)"
                  >APPLY</button>
                </div>
              </div>
              <el-button slot="reference" class="el-button-edit">
                <button
                  class="btn btn-icon companyactions actionicons"
                  style="padding: 0.4rem 1.3rem;"
                >
                  <i class="icon icon-pencil"></i>
                </button>
              </el-button>
            </el-popover>
          </td>
          <td v-else>{{item.discount.amount}}</td>
          <td style="text-align:right;">{{item.total}}</td>
          <td v-if="!isSummary" style="padding: 0 13px!important;">
            <el-tooltip content="Add Note" placement="top">
              <span>
                <img
                  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-note.svg"
                  @click="AddNoteModal(vendorIndex, itemIndex)"
                >
              </span>
            </el-tooltip>
            <el-tooltip content="Delete" placement="top">
              <button
                type="button"
                class="btn btn-icon actionicons"
                style="padding: 0.4rem .2rem;"
                @click="removeItem(vendor._id, item)"
              >
                <i class="icon icon-delete"></i>
              </button>
            </el-tooltip>
          </td>
        </tr>
        <tr style="border-top:2px dotted #cdd2da;height: 40px!important;" class="TaxDiscountTr">
          <!-- <td ></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="4">Food Summary</td>
          <td>{{vendor.food_total}}</td>
          <td></td>
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="4">Beverage Summary</td>
          <td>{{vendor.beverage_total}}</td>
          <td></td>
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="4">Additional Charges</td>
          <td v-if="vendor.additional_charge">{{vendor.additional_charge.amount}}</td>
          <td v-else>{{formatPrice(0)}}</td>
          <td v-if="!isSummary">
            <el-popover placement="bottom" v-model="vendor.pop_over" width="300" trigger="click">
              <div class="row">
                <span class="defaultBlue mt-1 pl-3">
                  Select:
                  <sup>★</sup>
                </span>
                <span class="preview-elements pl-2">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="AMOUNT"
                        class="form-check-input"
                        v-model="vendor.additional_charge.type"
                        checked
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Price</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="PERCENT"
                        class="form-check-input"
                        v-model="vendor.additional_charge.type"
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Percentage</span>
                    </label>
                  </div>
                </span>
                <div class="pull-right" style="margin: 0 auto;">
                  <span @click="vendor.pop_over=false">
                    <i
                      class="icon icon-close"
                      style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"
                    ></i>
                  </span>
                </div>
                <div class="additionalCharges pl-2">
                  <input
                    type="number"
                    class="enterCharges"
                    v-model="vendor.additional_charge.value"
                  >
                  <button
                    type="submit"
                    class="applyCharges"
                    @click="updateAdditionalChargesVendor(vendor._id, vendor.additional_charge.type,vendor.additional_charge.value, vendor)"
                  >APPLY</button>
                </div>
              </div>
              <el-button slot="reference" class="el-button-edit">
                <button
                  class="btn btn-icon companyactions actionicons"
                  style="padding: 0.4rem 1.3rem;"
                >
                  <i class="icon icon-pencil"></i>
                </button>
              </el-button>
            </el-popover>
          </td>
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="4">Item Discount</td>
          <td v-if="vendor.items_discount_total">{{vendor.items_discount_total}}</td>
          <td v-else>{{formatPrice(0)}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="4" style="border-top: 1px dotted rgba(13, 132, 242, 0.55);">Sub-Total</td>
          <td style="border-top: 1px dotted rgba(13, 132, 242, 0.55);">{{vendor.sub_total}}</td>
          <td style="border-top: 1px dotted rgba(13, 132, 242, 0.55);"></td>
        </tr>
      </tbody>
      <tbody>
        <tr
          style="height: 40px!important;background-color:rgb(246, 246, 246) !important;"
          class="TaxDiscountTr"
        >
          <td
            colspan="6"
            style="text-align:left;border-top: 1px dotted rgba(13, 132, 242, 0.55);"
          >Aggregate Order Summary</td>
        </tr>
        <tr
          style="border-top:2px dotted #cdd2da;background-color: #2993f526;"
          class="backTaxes TaxDiscountTr"
        >
          <td colspan="4">Vendor Food Summary</td>
          <td>{{order.food_total || 0}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4">Vendor Beverage Summary</td>
          <td>{{order.beverage_total || 0}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4">Vendor Additional Charges</td>
          <td>{{order.additional_charges_total || 0}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4">Vendor Item Discount</td>
          <td>{{order.items_discount_total || 0}}</td>
          <td></td>
        </tr>
        <tr
          class="backTaxes TaxDiscountTr"
          style="border-top: 1px dotted #00448b;background-color: #2993f526;"
        >
          <td colspan="4" style="border-top: 1px dotted #00448b;">Sub-Total</td>
          <td style="border-top: 1px dotted #00448b;">{{order.sub_total}}</td>
          <td style="border-top: 1px dotted #00448b;"></td>
        </tr>
        <tr v-if="order.hasOwnProperty('additional_charges')" class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4" style="border-top: 1px dotted #00448b;">Order Additional Charges</td>
          <td style="border-top: 1px dotted #00448b;">{{order.additional_amount}}</td>
          <td style="border-top: 1px dotted #00448b;">
            <el-popover
              placement="bottom"
              v-model="order_additional_charges_popover"
              width="300"
              trigger="click"
            >
              <div class="row">
                <span class="defaultBlue mt-1 pl-3">
                  Select:
                  <sup>★</sup>
                </span>
                <span class="preview-elements pl-2">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="AMOUNT"
                        class="form-check-input"
                        name="order-additional-charges"
                        v-model="order.additional_charges.type"
                        checked
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Price</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="PERCENT"
                        class="form-check-input"
                        name="order-additional-charges"
                        v-model="order.additional_charges.type"
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Percentage</span>
                    </label>
                  </div>
                </span>
                <div class="pull-right" style="margin: 0 auto;">
                  <span @click="order_additional_charges_popover=false">
                    <i
                      class="icon icon-close"
                      style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"
                    ></i>
                  </span>
                </div>
                <div class="additionalCharges pl-2">
                  <input
                    type="number"
                    class="enterCharges"
                    v-model="order.additional_charges.value"
                  >
                  <button type="submit" class="applyCharges" @click="orderAdditionalCharges()">APPLY</button>
                </div>
              </div>
              <el-button slot="reference" class="el-button-edit">
                <button
                  class="btn btn-icon companyactions actionicons"
                  style="padding: 0.4rem 1.3rem;"
                >
                  <i class="icon icon-pencil"></i>
                </button>
              </el-button>
            </el-popover>
          </td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4">Order Discount</td>
          <td>{{order.discount_amount}}</td>
          <td>
            <el-popover
              placement="bottom"
              v-model="order_discount_popover"
              width="300"
              trigger="click"
            >
              <div class="row">
                <span class="defaultBlue mt-1 pl-3">
                  Select:
                  <sup>★</sup>
                </span>
                <span class="preview-elements pl-2">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="AMOUNT"
                        class="form-check-input"
                        name="order-discount"
                        v-model="order.discount.type"
                        checked
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Price</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        value="PERCENT"
                        class="form-check-input"
                        name="order-discount"
                        v-model="order.discount.type"
                      >
                      <span class="radio-icon"></span>
                      <span class="form-check-description">Percentage</span>
                    </label>
                  </div>
                </span>
                <div class="pull-right" style="margin: 0 auto;">
                  <span @click="order_discount_popover=false">
                    <i
                      class="icon icon-close"
                      style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"
                    ></i>
                  </span>
                </div>
                <div class="additionalCharges pl-2">
                  <input type="number" class="enterCharges" v-model="order.discount.value">
                  <button type="submit" class="applyCharges" @click="orderDiscount()">APPLY</button>
                </div>
              </div>
              <el-button slot="reference" class="el-button-edit">
                <button
                  class="btn btn-icon companyactions actionicons"
                  style="padding: 0.4rem 1.3rem;"
                >
                  <i class="icon icon-pencil"></i>
                </button>
              </el-button>
            </el-popover>
          </td>
        </tr>

        <tr
          class="backTaxes TaxDiscountTr"
          style="background-color: #2993f526;"
          v-for="(charges,chargesIndex) in order.other_charges"
          :key="chargesIndex"
        >
          <td colspan="4">{{charges.name}}</td>
          <td>{{charges.value}}</td>
          <td></td>
        </tr>
        <tr
          :class="{'dotteddHead': !editOrder}"
          class="backTaxes TaxDiscountTr"
          style="background-color: #2993f526;"
        >
          <td colspan="4" style="border-top: 1px dotted #00448b;">Total Amount</td>
          <td style="border-top: 1px dotted #00448b;">{{order.total_amount}}</td>
          <td style="border-top: 1px dotted #00448b;"></td>
        </tr>
        <tr
          class="backTaxes TaxDiscountTr"
          style="background-color: #2993f526;"
          v-for="t in order.tax"
          :key="t.code"
        >
          <td colspan="4" style="border-top: 1px dotted #00448b;">{{t.name}}</td>
          <td style="border-top: 1px dotted #00448b;">{{t.value}}</td>
          <td style="border-top: 1px dotted #00448b;"></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td colspan="4" style="border-top: 1px dotted #00448b;">Gross Total</td>
          <td style="border-top: 1px dotted #00448b;">{{order.gross_total}}</td>
          <td style="border-top: 1px dotted #00448b;"></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" style="background-color: #2993f526;">
          <td style="border-top: 1px dotted #00448b;" colspan="4">Round Off</td>
          <td style="border-top: 1px dotted #00448b;">{{order.round_off.value}}</td>
          <td style="border-top:1px dotted #00448b;padding: 0.6rem 1.2rem;">
            <!-- <el-popover placement="bottom" v-model="order_roundoff" width="300" trigger="click">
              <div class="row">
                <span class="defaultBlue mt-1 pl-3">
                  Enter Price:
                  <sup>★</sup>
                </span>
                <div class="pull-right" style="width: 61%;text-align: right;">
                  <span @click="order_roundoff=false">
                    <i
                      class="icon icon-close"
                      style="color: rgb(184, 212, 255); cursor: pointer;font-size: 18px;"
                    ></i>
                  </span>
                </div>
                <div class="additionalCharges pl-2">
                  <input type="number" class="enterCharges" v-model="roundOff">
                  <button type="submit" class="applyCharges" @click="applyRoundOff">APPLY</button>
                </div>
              </div>
              <el-button slot="reference" class="el-button-edit">
                <button
                  class="btn btn-icon companyactions actionicons"
                  style="padding: 0.4rem 1.3rem;"
                >
                  <i class="icon icon-pencil"></i>
                </button>
              </el-button>
            </el-popover> -->
          </td>
        </tr>
        <tr class="grandTotalTr">
          <td colspan="4" class="grandTotalText">Grand Total</td>
          <td class="grandValue">{{grandTotal}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div>
      <add-note
        modal_name="add-note-checkout"
        :note="note"
        @addNote="addItemNote"
        @closeNote="AddNoteModalHide"
      ></add-note>
    </div>
  </div>
</template>
<script>
import AddNote from "./AddNote";
import Swal from "sweetalert2";
export default {
  data () {
    return {
      note: {
        itemIndex: null,
        vendorIndex: null,
        value: '',
        name: null,
      },
      editRoundoff: true,
      round_off: 0.00,
      roundOff: 0,
      order_roundoff: false,
      order_discount_popover: false,
      order_additional_charges_popover: false
    };
  },
  props: ["order", "apiLock", "isSummary", "showRoundOffBox", "editOrder"],
  methods: {
    formatPrice (value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    showRoundOff () {
      this.round_off = this.order.round_off;
      this.editRoundoff = !this.editRoundoff;
    },
    CloseRoundOff () {
      this.editRoundoff = !this.editRoundoff;
      this.order.round_off = this.round_off;
    },
    rightEditRoundoff () {
      this.editRoundoff = !this.editRoundoff;
    },
    decreaseItemQuantity (vendorIndex, itemIndex) {
      if (!this.apiLock) {
        this.$emit('updateAPILock', true);
        let item = JSON.parse(JSON.stringify(this.order.vendors[vendorIndex].items[itemIndex]));
        item.vendorId = this.order.vendors[vendorIndex]._id;
        item.quantity--;
        this.$emit('updateOrderItem', item);
      } else {
        this.$toasted.global.error('Updating quantity in progress');
      }
    },
    increaseItemQuantity (vendorIndex, itemIndex) {
      if (!this.apiLock) {
        this.$emit('updateAPILock', true);
        let item = JSON.parse(JSON.stringify(this.order.vendors[vendorIndex].items[itemIndex]));
        item.vendorId = this.order.vendors[vendorIndex]._id;
        item.quantity++;
        this.$emit('updateOrderItem', item);
      } else {
        this.$toasted.global.error('Updating quantity in progress');
      }
    },
    AddNoteModal (vendorIndex, itemIndex) {
      this.note.itemIndex = itemIndex;
      this.note.vendorIndex = vendorIndex;
      this.note.value = this.order.vendors[vendorIndex].items[itemIndex].note;
      let name = this.order.vendors[vendorIndex].items[itemIndex].product_name;
      if (this.order.vendors[vendorIndex].items[itemIndex].variant_name) {
        name += '/' + this.order.vendors[vendorIndex].items[itemIndex].variant_name;
      }
      this.note.name = name;
      this.$modal.show('add-note-checkout');
    },
    addItemNote (note) {
      let vendorIndex = note.vendorIndex;
      let item = this.order.vendors[vendorIndex].items[note.itemIndex]
      let data = {
        productId: item.product_id,
        variantId: item.variant_id,
        orderId: this.order._id,
        vendorId: this.order.vendors[vendorIndex]._id,
        note: note.value
      }
      this.$http
        .post('/orders/add_item_note', data)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$emit('updateCheckout', true);
            this.$modal.hide('add-note-checkout');
          } else {
            this.$toasted.global.error(res.data.reason);
          }
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    },
    AddNoteModalHide () {
      this.$modal.hide('add-note-checkout');
    },
    discardOrder () {
      let data = {
        orderId: this.order._id,
      }
      this.$http
        .post('/orders/discard', data)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success('Order Discarded Successfully');
            this.$emit('close', true);
          } else {
            this.$toasted.global.error(res.data.reason);
          }
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    },
    removeItem (vendorId, item) {
      let data = {
        productId: item.product_id,
        variantId: item.variant_id,
        vendorId: vendorId,
        orderId: this.order._id
      }
      if (this.order.vendors.length == 1 && this.order.vendors[0].items.length == 1) {
        Swal({
          title: 'Are You Sure?',
          text: "Are You Sure To Discard The Order ?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#00448b',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.discardOrder()
          }
        })

      }
      else
        this.confirmRemoveItem(data)


    },
    confirmRemoveItem (data) {

      this.$http
        .post('/orders/remove_item', data)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$emit('updateCheckout', true);
          } else {
            this.$toasted.global.error(res.data.reason);
          }
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    },
    applyItemDiscount (vendor_id, item_id, order_item_id, item) {
      let data = {
        order_item_id: order_item_id,
        discount: {
          type: item.discount.type,
          value: parseFloat($('#itemDiscount' + vendor_id + item_id).val()),
          amount: parseFloat($('#itemDiscount' + vendor_id + item_id).val())
        }
      }
      item.pop_over = false
      this.$http.post('/orders/items/discount', data).then(res => {
        if (res.data.status_id == 1) {
          this.$emit('updateCheckout', true);
          this.$toasted.global.success("Applied Item Discount Successfully");
        }
        else
          this.$toasted.global.error(res.data.reason);

      })
    },
    updateAdditionalChargesVendor (vendor_id, type, value, vendor_pop) {
      let data = {
        vendorId: vendor_id,
        orderId: this.order._id,
        type: type,
        value: parseFloat(value),
      }
      if (this.editOrder) {
        data.update = true
      }
      this.additionalChargesVendor(data, vendor_pop);
    },
    additionalChargesVendor (data, vendor_pop) {
      this.$http
        .post('/orders/additional_charges', data)
        .then(res => {
          vendor_pop.pop_over = false
          if (res.data.status_id == 1) {
            this.$emit('updateCheckout', true);
            this.$toasted.global.success("Vendor Additional Charges Applied Successfully");

            this.order_charges_popover = false
          } else
            this.$toasted.global.error(res.data.reason);
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    },
    orderAdditionalCharges () {
      let data = {
        orderId: this.order._id,
        type: this.order.additional_charges.type,
        value: parseFloat(this.order.additional_charges.value),
      }
      if (this.editOrder) {
        data.update = true
      }
      this.$http.post("/orders/additional_charges", data)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$emit('updateCheckout', true);
            this.order_additional_charges_popover = false;
            this.$toasted.global.success("Order Additional Charges Applied Successfully");
          }
          else
            this.$toasted.global.error(res.data.reason);
        })
    },
    orderDiscount () {
      let data = {
        orderId: this.order._id,
        type: this.order.discount.type,
        value: parseFloat(this.order.discount.value),
      }
      if (this.editOrder) {
        data.update = true
      }
      this.$http.post("/orders/discount", data)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success("Order Discount Applied Successfully");
            this.order_discount_popover = false;
            this.$emit('updateCheckout', true);
          }
          else
            this.$toasted.global.error(res.data.reason);
        })
    },
    applyRoundOff () {
      this.$http.post('/orders/round_off', {
        roundOff: parseFloat(this.roundOff),
        orderId: this.order._id,
        isDelivered: false,
        update: this.editOrder      })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$emit('updateCheckout', true);
            this.order_roundoff = false;
            this.$toasted.global.success("Round Off Applied Successfully");
          }
          else
            this.$toasted.global.success("Round Off Apply Failed");
        })
    }
  },
  computed: {
    grandTotal () {
      //   if (this.order.round_off != "") return (this.order.grand_total + parseFloat(this.order.round_off)).toFixed(2);
      //   return this.order.grand_total.toFixed(2);
      return this.order.grand_total
    }
  },
  components: {
    AddNote
  },
  watch: {}
};
</script>d
<style>
.el-button.el-button-edit {
  border: 0px solid #dcdfe6 !important;
  padding: 0px !important;
  background-color: transparent;
}
.dottedHead {
  border-top: 2px dotted #cdd2da;
}
.roundoffInputCheckout {
  text-align: right;
  border: 1px solid #eee !important;
  border-radius: 10px;
}
.roundoffInputCheckout:disabled {
  border: 1px solid #f6f6f6 !important;
  background: transparent;
}
.grandTotalTr {
  border-top: 1px solid #00448b;
  border-bottom: 1px solid #00448b;
}
.grandTotalTr td.grandTotalText {
  color: #00448b;
  font-size: 14px;
  font-weight: 800;
  text-align: right;
}
.grandTotalTr td.grandValue {
  color: #4e5764;
  font-weight: 800;
  text-align: right;
}
tr.backTaxes {
  height: 40px !important;
}
.TaxDiscountTr td {
  color: #4e5764 !important;
  font-weight: 600;
}
.table .TaxDiscountTr td {
  padding: 0.6rem 1.2rem;
  text-align: right;
}
</style>
