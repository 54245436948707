<template>
    <div class="page-layout blank">
		<div class="pb-10">
			<h2 class="headerblock">
				<div class="row">
					<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
						<div class="list-title" style="font-weight: 500;">
                            <router-link to="/createopenmenu" class="btn btn-secondary btn-smm">+ Add New Open - Menu</router-link>
						</div>
					</div>
					<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
						<div class="breadcome-heading  d-xl-inline">
							<div class="form-group formsearch has-warning has-feedback">
								<input type="text" class="form-control textremove holder" placeholder="Search...">
								<!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
								<span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
							</div>
						</div>
						<el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
							<a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)">
								<i class="icons icon-arrow-left"></i>
							</a>
						</el-tooltip>
					</div>
				</div>
			</h2>
            <div class="page-layout carded left-sidebar">
				<div class="page-content-wrapper">
					<div class="page-content">
						<div class="demo-content">
							<table class="table table-striped table-scroll table-open-menu">
								<thead>
                                    <th>Open - Menu Name </th>
                                    <th>Description </th>
                                    <th>Qty</th>
                                    <th>PRICE</th>
                                    <th>NO. OF PRODUCTS </th>
                                    <th>AVAILABLE FOR MENU</th>
                                    <th>Actions</th>
								</thead>
                                <tbody>
                                    <tr>
                                        <td class="outlet-name">Coffee</td>
                                        <td class="td-truncate-table">Coffee is a brewed drink Coffee is a brewed drink Coffee is a brewed drink Coffee is a brewed drink</td>
                                        <td>1</td>
                                        <td>$15,000.00</td>
                                        <td>
                                            <el-tooltip content="View Products" placement="top">
												<div class="chip chipproducts">
													<div class="circle-profile-photo prj-total-users">2</div>
													<span>
														Products
													</span>
												</div>
											</el-tooltip>
                                        </td>
                                        <td>
                                            <el-switch
                                                v-model="value2"
                                                active-color="#13ce66"
                                                inactive-color="#ff4949">
                                            </el-switch>
                                        </td>
                                        <td>
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                <button class="btn btn-icon companyactions actionicons">
                                                <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                <button type="button" class="btn btn-icon actionicons">
                                                <i class="icon icon-delete"></i>
                                                </button>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
							</table>
						</div>
					</div>
				</div>
            </div>
		</div>
    </div>
</template>
<style>
.table.table-open-menu td {
    padding: 0px 12px !important;
}
.td-truncate-table{
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    display: block!important;
}
.outlet-name {
    font-weight: 600;
    cursor: pointer;
    color: #00448b!important;
    text-transform: capitalize;
}
</style>
<script>
import ElementUI from 'element-ui';
export default {
    data() {
        return {
        value2: true
        }
    }
}
</script>