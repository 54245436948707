<template>
     <div class="page-layout blank closed-orders">
          <div class="pb-10">
               <h2 class="headerblock">
                    <div class="row">
                         <div class="d-flex col-lg-2 col-md-2 col-sm-3">
                              <router-link style="color: transparent!important;"
                                   :disabled="!$routerHistory.hasPrevious()"
                                   :to="{ path: $routerHistory.previous().path }">
                                   <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                        <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                             <i class="icons icon-arrow-left"></i>
                                        </button>
                                   </el-tooltip>
                              </router-link>
                              <router-link style="color: transparent!important;"
                                   :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                                   <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                        <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                             <i class="icons icon-arrow-right"></i>
                                        </button>
                                   </el-tooltip>
                              </router-link>
                         </div>
                    </div>
               </h2>
               <div class="row pt-5 pb-2" v-if="ajaxCallInProgress">
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                         <div class="digi-table-loader">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                         </div>
                    </div>
               </div>
               <div style="background-color:#f5f6f9;height:96%" v-else-if="!ajaxCallInProgress && waiter_approval.length == 0">
                    <div class="text-auto pt-1 h-100" style="right: 15px;left: 15px;">
                         <div class="h-100">
                              <div class="row pt-15">
                              <img src="https://cdn.digicollect.com/cdn/posv2/pending_order.svg" class="pr-2 img-style">
                              </div>
                              <div class="text-style text-center mb-3">No Approval Status Added</div>
                         </div>
                    </div>
               </div>
               <div v-else class="row pt-5 pb-2" id="lazy-load-approval" @scroll="scrollEnd">
                    <div class="col-md-6 col-lg-6 col-sm-12 mb-5" v-for="(approve, index) in waiter_approval" :key="index">
                         <div class="order-card p-2">
                              <div class="row d-flex flex-nowrap py-2 h-52px">
                                   <div v-if="approve.order_type.startsWith('Online') || approve.order_type.startsWith('Deliveries')" class="col-md-6 pl-3">
                                        <!-- Image for Delivery -->
                                        <img src="/static/images/truck.svg" height="20" alt="">
                                        <span class="pl-2 text-color">{{approve.order_type}}</span>
                                   </div>
                                   <div v-else-if="approve.order_type == 'Take Away'" class="col-md-6 pl-3">
                                        <!-- Image for Take Away -->
                                        <img src="/static/images/takeaway.svg" height="20">
                                        <span class="pl-2 text-color">{{approve.order_type}}</span>
                                   </div>
                                   <div v-else class="col-md-6 pl-3">
                                        <!-- Image for Table -->
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/table-icon.svg" height="20">
                                        <span class="pl-2 text-color">{{approve.sales_order_no}}</span>
                                   </div>
                                   <div v-if="approve.status == 'pending'" class="col-md-6 text-right">
                                        <button class="btn btn-new-primary text-white" @click="withdrawApproval(approve)">Withdraw</button>
                                   </div>
                                   <div v-else class="col-md-6 text-right">
                                        <span :class="[approve.status == 'rejected' ? 'text-danger' : approve.status == 'approved' ? 'text-success' : '']" class="text-capitalize p-1 text-border">{{approve.status}}</span>
                                   </div>
                              </div>
                              <div class="d-flex flex-nowrap py-2 pt-3">
                                   <div class="col-md-6 pl-3">
                                        <img src="/static/images/User-logo-grey.svg"
                                             class="pr-2 icon-style">
                                        <!-- <span class="span-add-customer text-black">John Doe</span> -->
                                        <span class="span-add-customer text-black">{{approve.guest_name}}</span>
                                   </div>
                                   <div class="col-md-6 pl-3">
                                        <img src="/static/images/s.no-grey.svg"
                                             class="pr-2 icon-style">
                                        <span class="span-add-customer text-black">S.No : </span>
                                        <!-- <span class="span-add-customer text-black">01</span> -->
                                        <span class="span-add-customer text-black">{{approve.order_number}}</span>
                                   </div>
                              </div>
                              <div class="d-flex flex-nowrap py-2 pt-3">
                                   <div class="col-md-6 pl-3">
                                        <img src="/static/images/Order ID.svg"
                                             class="pr-2 icon-style">
                                        <!-- <span class="span-add-customer text-black">ID134577</span> -->
                                        <span class="span-add-customer text-black">{{approve.sales_order_no}}</span>
                                   </div>
                                   <div class="col-md-6 pl-3">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/orders-grey.svg"
                                             class="pr-2 icon-style">
                                        <span class="span-add-customer text-black">Order Value : </span>
                                        <!-- <span class="span-add-customer text-black">$123.00</span> -->
                                        <span class="span-add-customer text-black">{{approve.order_value}}</span>
                                   </div>
                              </div>
                              <div class="col-md-12 pl-3 py-2">
                                   <div class="d-flex justify-content-between">
                                        <!-- <span class="text-secondary fw-bold">Change Item discount</span> -->
                                        <span class="text-secondary fw-bold">{{approve.message}}</span>
                                        <img class="pointer" @click="showPayBill(approve.order_id)" src="/static/images/blue_newspaper.svg" width="15">
                                   </div>
                              </div>
                              <hr>
                              <div class="d-flex flex-nowrap py-2 pt-3">
                                   <div class="col-md-6 pl-3">
                                        <!-- <span class="text-grey span-add-customer">1 X EggNoodles/Large</span> -->
                                        <span class="text-grey span-add-customer">{{approve.request_for}}</span>
                                   </div>
                                   <div class="col-md-6 pl-3">
                                        <!-- <span class="text-grey span-add-customer">$25.00</span> -->
                                        <span class="text-grey span-add-customer">{{approve.request_value}}</span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="mt-5 mb-5" style="height: 60px;" v-if="loading" id="spinner">
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                         <div class="digi-table-loader">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                         </div>
                    </div>
               </div>
          </div>
          <sweet-modal ref="success_modal" icon="success">
               {{ modal_msg }}
          </sweet-modal>
          <sweet-modal ref="warning_modal" icon="warning">
               {{ modal_msg }}
          </sweet-modal>
          <pay-bill :for_approvals="true" :ticket_printing="ticket_printing" @update_cust_approval="closePayBill" v-if="paybillshow" modal_name="pay-bill" :summary="order" :isPopUp="isPopUp" @updateCheckout="updateSummary" :editOrder="false" @close="closePayBill" :inProgress="inProgress" :isDelivered="isDelivered" :storeorderId="storeorderId" :sourcemanage="'viewOrder'" @new_update_cust="new_update_cust"></pay-bill>
     </div>
</template>

<script>
     import approval from './mixins/approval.js'
     import {
          SweetModal
     } from 'sweet-modal-vue'
     import PayBill from './PayBill'
import { EventBus } from './eventBus/nav-bar-event.js'
     export default({
          mixins: [approval],
          data() {
               return{
                    waiter_approval: [],
                    modal_msg: '',
                    ajaxCallInProgress: false,
                    loading: false,
                    count1: 0,
                    paybillshow: false,
                    ticket_printing: false,
                    storeorderId: '',
                    order: '',
                    isPopUp: false,
                    inProgress: false,
                    isDelivered: false,
               }
          },
          components: {
               SweetModal,
               PayBill
          },
          methods: {
               showPayBill(order_id) {
                    this.ticket_printing = true
                    this.storeorderId = order_id
                    this.paybillshow = true
                    if (order_id) {
                         this.selectedOrderId = order_id
                    }
                    let params = {
                         order_id: this.selectedOrderId,
                         web: true,
                         for_approvals: true
                    }
                    // if (!this.order.isDelivered) params.in_progress = true
                    this.$http.post('/orders/summary', params).then(res => {
                         if (res.data.status_id == 1) {
                              this.order = res.data.order
                              EventBus.$emit('summaryChanged', res.data.order)
                              this.isDelivered = true
                              this.inProgress = false
                              setTimeout(() => {
                                   this.$modal.show('pay-bill')
                              }, 500)
                         } else {
                              this.$toasted.global.error(res.data.reason)
                         }
                    })
               },
               updateSummary() {
                    this.showPayBill()
               },
               closePayBill() {
                    this.paybillshow = false
                    setTimeout(() => {
                         this.$modal.hide('pay-bill')
                         this.getWaiterApproval();
                    }, 500);
               },
               new_update_cust(){
                    this.paybillshow = false
                    setTimeout(() => {
                         this.$modal.hide('pay-bill')
                         this.getWaiterApproval();
                    }, 500);
               },
               async scrollEnd(e) {
                    const {target} = e;
                    if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                         //this code will run when the user scrolls to the bottom of this div so
                         //you could do an api call here to implement lazy loading
                         try {
                              this.loading = true
                              let params = {
                                   skip: this.count1,
                                   limit: 10
                              }
                              let result = await this.getApprovalList(params)
                              if (result.status_id == 1) {
                              if (result.payload.skip == this.count1) {
                                   let tempFiles = result.approvals_list
                                   tempFiles.forEach(el => {
                                        this.waiter_approval.push(el)
                                   })
                                   this.count1 += result.approvals_list.length;
                                   this.loading = false
                              }
                              if (result.approvals_list.length < 10) {
                                   document.getElementById('spinner').style.display = 'none'
                                   this.loading = false
                              }
                              }
                         } catch (reason) {

                         }
                         this.bottom = true;
                    }
               },
               async withdrawApproval(order) {
                    try {
                         let params = {
                              order_id: order.order_id,
                         };
                         if(order.action == 'order_item_discount') {
                              params.order_item_id = order.order_item_id
                              params.action = 'order_item_discount'
                         } else if(order.action == 'order_discount') {
                              params.action = 'order_discount'
                         } else if(order.action == 'delete_order_item') {
                              params.order_item_id = order.order_item_id
                              params.action = 'delete_order_item'
                         }
                         let response = await this.withdrawApprovalApi(params);
                         this.modal_msg = response.message;
                         this.$refs.success_modal.open();
                         setTimeout(() => {
                              this.$refs.success_modal.close();
                              this.getWaiterApproval()
                         }, 2000);
                    }
                    catch(err) {
                         this.modal_msg = err;
                         this.$refs.warning_modal.open();
                         setTimeout(() => this.$refs.warning_modal.close(), 2000);
                    }
               },
               async getWaiterApproval(){
                    this.waiter_approval = []
                    this.ajaxCallInProgress = true
                    try{
                         let params = {
                              skip: 0,
                              limit: 10,
                              is_waiter: true
                         }
                         let result = await this.getApprovalList(params)
                         this.waiter_approval = result.approvals_list
                         this.count1 = result.approvals_list.length;
                         this.ajaxCallInProgress = false
                    } catch(error){
                         this.modal_msg = error;
                         this.$refs.warning_modal.open();
                         setTimeout(() => this.$refs.warning_modal.close(), 2000);
                    }
               }
          },
          mounted(){
               this.getWaiterApproval();
               EventBus.$on('update_approval_status_listing_show_summary', (order_id)=>{
                    this.showPayBill(order_id);
               })
          },
          beforeDestroy(){
               EventBus.$off('update_approval_status_listing_show_summary');
          }
     })

</script>

<style scoped>
     .order-card {
          /* box-shadow: 0 4px 8px 0 #fff; */
          border: 1px solid rgba(0, 0, 0, 0.2);
          max-width: 660px;
          margin: auto;
          margin-bottom: 20px;
          text-align: left;
          background-color: #fff !important;
     }

     .text-green {
          color: #1b9e4c !important
     }

     .text-grey {
          color: #7b7f83 !important;
     }
     #lazy-load-approval {
          height: 690px;
          overflow-y: auto;
          overflow-x: hidden;
          margin-bottom: 30px;
          -ms-overflow-style: none;
          scrollbar-width: none;
     }
     .h-52px{
          height: 52px;
     }
</style>
