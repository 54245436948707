<template>
    <div class="page-layout blank closed-orders">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="d-flex col-lg-1 col-md-1 col-sm-3">
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-left"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                    </div>
                    <div class="d-flex justify-content-center col-lg-10">
                        <h5 style="font-weight:bold">EDIT COMBO</h5>
                    </div>
                </div>
            </h2>
            <div class="body-sections">
                <div class="body-sections-head mb-5">
                    <span class="body-sections-header font-600">General Section</span>
                </div>
                <div class="row mx-0">
                    <div class="col-lg-2">
                        <span class="ml-5" style="text-align:center;margin-left:20px">
                            <img v-if="create.image_url" :src="create.image_url" style="width:90px!important;height:90px!important;"/>
                            <img v-else :src="imgDataUrl" style="width: 90px!important;height:90px!important;"/>
                        </span>
                        <upload-picture @uploadImage="uploadImage"></upload-picture>
                    </div>
                    <div class="col-lg-10">
                        <div class="row mx-0">
                            <div class="col-lg-12 mt-2 p-0">
                                <span style="font-weight: 600;">Status:<sup>★</sup></span>
                                <el-switch v-model="create.status" active-text="Yes" inactive-text="No" class="pl-4"></el-switch>
                            </div>
                            <div class="col-lg-12 mt-5 p-0">
                                <div class="form-group contactGroup">
                                    <span class="input-head">Combo Name<sup>*</sup></span>
                                    <input
                                        class="inputContact"
                                        name="combo_name"
                                        v-validate="'required'"
                                        :maxlength="combonamelength"
                                        required
                                        autocomplete="off"
                                        v-model.trim="create.name"
                                        type="text"
                                        placeholder="Enter Combo Name"
                                    />
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('combo_name')" style="display: inline !important">{{errors.first('combo_name')}}</span>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{combonamelength - create.name.length}} / {{combonamelength}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-lg-6">
                        <span class="input-head">Category<sup>*</sup></span>
                        <multiselect
                        v-model="create.category_id"
                        class="digi-multiselect"
                        id="ajax1"
                        placeholder="Select Category"
                        :options="sectionCategories"
                        label="name"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="40"
                        :max-height="200"
                        :show-no-results="true"
                        :hide-selected="false"
                        @close="updateSubcategory()"
                        ></multiselect>
                    </div>
                    <div class="col-lg-6">
                        <span class="input-head">Sub Category<sup>*</sup></span>
                        <multiselect
                        v-model="create.sub_category_id"
                        class="digi-multiselect"
                        id="ajax2"
                        disabled
                        label="name"
                        placeholder="Combos"
                        :options="[]"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="40"
                        :max-height="200"
                        :show-no-results="true"
                        :hide-selected="false"
                        ></multiselect>
                    </div>
                </div>
                <div class="row mx-0 mt-5">
                    <div class="col-md-6" v-if="available_productType">
                        <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product Type<sup>★</sup></label>
                        <multiselect
                            v-model="create.product_type_id"
                            class="digi-multiselect"
                            id="ajax3"
                            label="name"
                            placeholder="Select Product Type"
                            :options="allProductTypes"
                            :multiple="false"
                            :searchable="true"
                            :loading="loadingProductType"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="40"
                            :max-height="200"
                            :show-no-results="true"
                            :hide-selected="false"
                            name="productType"
                            v-validate="'required'"
                            @search-change="loadProductTypeSearch"
                        >
                        <template slot="tag" slot-scope="{ option, remove }">
                            <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                            </span>
                        </template>
                        <template slot="afterList">
                            <div v-observe-visibility="reachedEndOfListProductType" />
                        </template>
                        </multiselect>
                        <span class="invalid-feedback-form" v-show="errors.has('productType')" style="display:inline;">Please Select Product Type</span>
                    </div>
                    <div class="col-md-6" v-if="!available_productType">
                        <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product Type<sup>★</sup></label>
                        <multiselect
                            v-model="create.product_type_id"
                            class="digi-multiselect"
                            id="ajax4"
                            label="name"
                            placeholder="Select Product Type"
                            :options="allProductTypes"
                            :multiple="false"
                            :searchable="true"
                            :loading="loadingProductType"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="40"
                            :max-height="200"
                            :show-no-results="true"
                            :hide-selected="false"
                            @search-change="loadProductTypeSearch"
                        >
                        <template slot="tag" slot-scope="{ option, remove }">
                            <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                            </span>
                        </template>
                        <template slot="afterList">
                            <div v-observe-visibility="reachedEndOfListProductType" />
                        </template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="body-sections my-10" v-for="(product, index) in create.combo_items" :key="index">
                <div class="body-sections-head mb-5">
                    <span class="body-sections-header font-600">Product - {{index+1}}</span>
                </div>
                <div class="product-table" style="overflow-x: auto">
                    <table class="table combos-product-table" style="background-color: #dee8f2">
                        <thead>
                            <tr>
                                <th>CATEGORY</th>
                                <th>SUB-CATEGORY</th>
                                <th>PRODUCT TYPE</th>
                                <th>PRODUCT</th>
                                <th>VARIANT</th>
                                <th>QUANTITY</th>
                                <th class="add-th"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(alternative, aindex) in product.alternatives" :key="aindex">
                                <td>
                                    <multiselect
                                        v-model="alternative.category_id"
                                        class="digi-multiselect"
                                        id="ajax5"
                                        placeholder="Select Category"
                                        :options="categories"
                                        label="name"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @search-change="loadCategorySearch"
                                        @select="selectedType('category',aindex,index)"
                                        :name="'category'+aindex"
                                        v-validate="'required'"
                                        open-direction="bottom"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListCategory" />
                                    </template>
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('category'+aindex)" style="display:inline;">Please Select Category</span>
                                </td>
                                <td>
                                    <multiselect
                                        v-if="alternative.category_id == ''"
                                        v-model="alternative.sub_category_id"
                                        class="digi-multiselect"
                                        id="ajax6"
                                        placeholder="Select Sub Category"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        open-direction="bottom"
                                    >
                                    <template slot="noOptions">Please Select Category</template>
                                    </multiselect>
                                    <multiselect
                                        v-else
                                        v-model="alternative.sub_category_id"
                                        class="digi-multiselect"
                                        id="ajax7"
                                        placeholder="Select Sub Category"
                                        :options="subCategories"
                                        label="name"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @open="loadSubCategorySearch(alternative.category_id)"
                                        @select="selectedType('subCategory',aindex,index)"
                                        :name="'subcategory'+aindex"
                                        v-validate="'required'"
                                        open-direction="bottom"
                                    >
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('subcategory'+aindex)" style="display:inline;">Please Select Sub Category</span>
                                </td>
                                <td>
                                    <multiselect v-if="alternative.category_id == '' || alternative.sub_category_id == ''"
                                        v-model="alternative.product_type_id"
                                        class="digi-multiselect"
                                        id="ajax8"
                                        placeholder="Select Product Type"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        open-direction="bottom"
                                    >
                                    <template slot="noOptions">Please Select Sub Category</template>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="alternative.product_type_id"
                                        class="digi-multiselect"
                                        id="ajax9"
                                        placeholder="Select Product Type"
                                        label="name"
                                        :options="productTypes"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @open="loadProductTypeSearchNew(alternative.category_id,alternative.sub_category_id)"
                                        @select="selectedType('productType',aindex,index)"
                                        open-direction="bottom"
                                    >
                                    </multiselect>
                                </td>
                                <td>
                                    <multiselect v-if="alternative.category_id == '' || alternative.sub_category_id == ''"
                                        v-model="alternative.product_id"
                                        class="digi-multiselect"
                                        id="ajax10"
                                        placeholder="Select Product"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        open-direction="bottom"
                                    >
                                    <template slot="noOptions">Please Select Sub Category</template>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="alternative.product_id"
                                        class="digi-multiselect"
                                        id="ajax10"
                                        placeholder="Select Product"
                                        :options="allProducts"
                                        label="name"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @open="loadProductSearchNew(alternative.category_id,alternative.sub_category_id,alternative.product_type_id)"
                                        @select="selectedType('product',aindex,index)"
                                        :name="'product'+aindex"
                                        v-validate="'required'"
                                        open-direction="bottom"
                                    >
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('product'+aindex)" style="display:inline;">Please Select product</span>
                                </td>
                                <td>
                                    <multiselect
                                        v-if="alternative.product_id == ''"
                                        v-model="alternative.variant_id"
                                        class="digi-multiselect"
                                        id="ajax11"
                                        placeholder="Select Variant"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        open-direction="bottom"
                                    >
                                    <template slot="noOptions">Please Select Product</template>
                                    </multiselect>
                                    <multiselect
                                        v-else-if="alternative.product_id && alternative.product_id.name == 'Any'"
                                        v-model="alternative.variant_id"
                                        class="digi-multiselect"
                                        disabled
                                        id="ajax11"
                                        placeholder="Select Variant"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        open-direction="bottom"
                                    >
                                    </multiselect>
                                    <multiselect
                                        v-else
                                        v-model="alternative.variant_id"
                                        class="digi-multiselect"
                                        id="ajax12"
                                        label="variant_name"
                                        placeholder="Select Variant"
                                        :options="allVariants"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @open="getVariant(alternative.product_id)"
                                        @select="selectedType('variant',aindex,index)"
                                        :name="'variant'+aindex"
                                        v-validate="'required'"
                                        open-direction="bottom"
                                    ></multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('variant'+aindex)" style="display:inline;">Please Select Variant</span>
                                </td>
                                <td>
                                    <input :maxlength="12" v-on:keypress="isNumber($event)" v-validate="'required'" :name="'qty'+aindex" type="text" id="quantity-input" placeholder="Enter Quantity" v-model.number="alternative.quantity"/><br>
                                    <span class="invalid-feedback-form" v-show="errors.has('qty'+aindex)" style="display:inline;">Quantity is Required</span>
                                </td>
                                <td class="add-td">
                                    <el-tooltip content="Remove Alternative" placement="top">
                                        <span @click="product.alternatives.splice(aindex,1)" v-if="aindex != 0">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/icons/combo-minus.svg"/>
                                        </span>
                                    </el-tooltip>
                                    <el-tooltip content="Add Alternative" placement="top">
                                        <span @click="product.alternatives.push(JSON.parse(JSON.stringify(AddNew)))">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/icons/combo-plus.svg" />
                                        </span>
                                    </el-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row d-flex flex-row mx-5 my-5">
                    <a v-if="index != 0" class="btn btn-smm btn-danger mr-2" style="width: 200px" @click="create.combo_items.splice(index,1)">- Delete Product</a>
                    <a class="btn btn-smm btn-secondary" style="width: 200px" @click="create.combo_items.push(JSON.parse(JSON.stringify(AddNewAlternative)))">+ Add Product</a>
                </div>
            </div>
            <div class="body-sections my-10">
                <div class="body-sections-head">
                    <span class="body-sections-header font-600">Yield Manager</span>
                </div>
                <div class="row mx-0 mb-5">
                    <div class="col-lg-6">
                        <div class="form-group contactGroup">
                            <span class="input-head">Base Combo Price</span>
                            <input :maxlength="12"  v-on:keypress="isNumber($event)" @input="calculateYield()" v-validate="'required'" name="basePrice" class="inputContact" required autocomplete="off" v-model.number="create.combo_price" type="text" placeholder="Enter Base Combo Price"/>
                            <span class="invalid-feedback-form" v-show="errors.has('basePrice')" style="display:inline;">Base Combo Price is Required</span>
                        </div>
                    </div>
                </div>
                <div class="product-table" style="overflow-x: auto">
                    <table class="table combos-product-table" style="background-color: #dee8f2">
                        <thead>
                            <tr>
                                <th>ORDER TYPE</th>
                                <th>SELLING PRICE</th>
                                <th>GROSS MARGIN
                                    <el-popover placement="right-end" width="400" trigger="hover" popper-class="combo-popover">
                                        <div class="d-flex">
                                            <div>
                                                <div class="mt-2 ml-2">Gross Margin (%) = </div>
                                            </div>
                                            <div>
                                                <div> Selling Price - Base Combo Price</div>
                                                <div class="text-center borderTop">Selling price</div>
                                            </div>
                                            <div>
                                                <div class="mt-2 ml-2"> * 100</div>
                                            </div>
                                        </div>
                                        <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                    </el-popover>
                                </th>
                                <th class="add-th"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(yielddata, yindex) in create.yield_manager_details" :key="yindex">
                                <td class="vABaseLine">
                                    <multiselect
                                        v-model="yielddata.order_type"
                                        class="digi-multiselect"
                                        id="ajax12"
                                        placeholder="Select Order Type"
                                        :options="allOrderTypes"
                                        label="order_type"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        style="width: 60% !important"
                                        :name="'orderType'+yindex"
                                        v-validate="'required'"
                                    ></multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('orderType'+yindex)" style="display:inline;">Please Select Order Type</span>
                                </td>
                                <td class="vABaseLine">
                                    <div class="input-group payment-currency">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">$</span>
                                        </div>
                                        <input :maxlength="12" style="padding: 10px !important;" v-on:keypress="isNumber($event)" :name="'price'+yindex" type="text" class="form-control" v-validate="'required'" @input="calculateGross(yielddata.price,yindex)" v-model.number="yielddata.price">
                                    </div>
                                    <span class="invalid-feedback-form" v-show="errors.has('price'+yindex)" style="display:inline;">Selling Price is Required</span>
                                </td>
                                <td class="vABaseLine">
                                    <div class="input-group payment">
                                        <el-tooltip content="Autofilled" placement="top">
                                            <i class="icon icon-check-circle fs-14 text-success mt-1px" style="position: absolute;right: 22px;z-index: 9999;"></i>
                                        </el-tooltip>
                                        <input style="padding: 10px !important;" type="number" readonly class="form-control" v-model.number="yielddata.gross_margin">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">%</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="add-td d-flex mt-2">
                                    <el-tooltip content="Remove Order Type" placement="top">
                                        <span @click="create.yield_manager_details.splice(yindex,1)" v-if="yindex != 0" class="mr-2">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/icons/combo-minus.svg" />
                                        </span>
                                    </el-tooltip>
                                    <el-tooltip content="Add Order Type" placement="top">
                                        <span @click="create.yield_manager_details.push(JSON.parse(JSON.stringify(AddNewYield)))">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/icons/combo-plus.svg" />
                                        </span>
                                    </el-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center mx-0 my-2">
                <a class="btn btn-smm btn-danger my-4 mx-2" @click="cancel()">CANCEL</a>
                <a class="btn btn-smm btn-success my-4 mx-2" style="color: #ffffff !important" @click="save()">SAVE</a>
            </div>
        </div>
    </div>
</template>

<script>
import combo from './mixins/combo.js'
import UploadPicture from './UploadPicture'
export default {
    mixins: [combo],
    data() {
        return {
            imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg',
            AddNew:{
                category_id:'',
                sub_category_id:'',
                product_type_id:'',
                product_id:'',
                variant_id:'',
                quantity:null
            },
            AddNewAlternative:{
                alternatives: [
                    {
                        category_id:'',
                        sub_category_id:'',
                        product_type_id:'',
                        product_id:'',
                        variant_id:'',
                        quantity:null
                    }
                ]
            },
            create: {
                status:true,
                name: '',
                image_url: '',
                sub_category_id: '',
                category_id: '',
                product_type_id: '',
                combo_items:[
                    {
                        alternatives: [
                            {
                                category_id:'',
                                sub_category_id:'',
                                product_type_id:'',
                                product_id:'',
                                variant_id:'',
                                quantity:null
                            }
                        ]
                    },
                ],
                combo_price: null,
                yield_manager_details: [
                    {
                        order_type: '',
                        price: null,
                        gross_margin: null
                    }
                ]
            },
            AddNewYield:{
                order_type: '',
                price: null,
                gross_margin: null
            },
            combonamelength: 45,
            sectionCategories:[],
            available_productType:true,
            loadingProductType:false,
            scrollableProductType:false,
            temp_allProductTypes:[],
            allProductTypes:[],
            count:0,
            skip:0,
            limit:10,
            search:'',
            loadingCategory:false,
            scrollableCategory:false,
            categories:[],
            temp_categories:[],
            subCategories:[],
            temp_subCategories:[],
            loadingSubCategory:false,
            scrollableSubCategory:false,
            productTypes:[],
            temp_productTypes:[],
            loadingProductTypeNew:false,
            scrollableProductTypeNew:false,
            allProducts:[],
            allVariants:[],
            allOrderTypes:[],
            search_key:'',
        }
    },
    components: { UploadPicture },
    methods: {
        isNumber: function(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        uploadImage(imgData) {
            this.create.image_url = imgData
        },
        addRowToYeildTable() {
            let rowToAdd = {
                order_type: '',
                selling_price: '',
                gross_margin: ''
            }
            this.create.yield_data.push(JSON.parse(JSON.stringify(rowToAdd)))
        },
        getDetails(){
            this.$http.get('/combos/get_combo_creation_details').then(res=>{1
                if(res.data.status_id == 1){
                    this.sectionCategories = res.data.combo_creation_details
                }else{

                }
            })
        },
        // Product Type
        async loadProductTypeSearch(search){
            this.loadingProductType = true
            let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
                searchdata: search,
                skip: 0,
                limit: 10,
                sub_category_id:this.create.sub_category_id._id
            })
            this.allProductTypes = response.data.product_types
            this.count = this.temp_productTypes.length
            if(response.data.product_types.length == 0){
                this.available_productType = false
                this.$validator.reset();
				this.errors.clear();
            }else{
                this.available_productType = true
            }
            this.loadingProductType = false
            this.scrollableProductType = false
        },
        async reachedEndOfListProductType(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableProductType == false) {
                    this.loadingProductType = true
                    let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
                        searchdata: this.search,
                        skip: this.count,
                        limit: 10,
                        sub_category_id:this.create.sub_category_id._id
                    })
                    this.temp_allProductTypes = response.data.product_types.length
                    if(response.data.product_types.length == 0){
                        this.available_productType = false
                        this.$validator.reset();
						this.errors.clear();
                    }else{
                        this.available_productType = true
                    }
                    if (response.data.skip == this.count) {
                        if (this.temp_allProductTypes > 0) {
                            let tempFiles = response.data.product_types
                            tempFiles.forEach(el => {
                                this.allProductTypes.push(el)
                            })
                            this.count += response.data.product_types.length
                            this.loadingProductType = false
                        } else {
                            this.scrollableProductType = true
                            this.loadingProductType = false
                        }
                    }
                    this.loadingProductType = false
                }
            }
        },
        async loadCategorySearch(search){
            this.loadingCategory = true
            let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                search: search,
                skip: 0,
                limit: 10,
            })
            this.categories = response.data.categories
            this.count = this.temp_categories.length
            this.loadingCategory = false
            this.scrollableCategory = false
        },
        async reachedEndOfListCategory(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableCategory == false) {
                    this.loadingCategory = true
                    let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                        searchdata: this.search,
                        skip: this.count,
                        limit: 10,
                    })
                    this.temp_categories = response.data.categories.length
                    if (response.data.skip == this.count) {
                        if (this.temp_categories > 0) {
                            let tempFiles = response.data.categories
                            tempFiles.forEach(el => {
                                this.categories.push(el)
                            })
                            this.count += response.data.categories.length
                            this.loadingCategory = false
                        } else {
                            this.scrollableCategory = true
                            this.loadingCategory = false
                        }
                    }
                    this.loadingCategory = false
                }
            }
        },
        async loadSubCategorySearch(category_id){
            this.loadingSubCategory = true
            this.subCategories = []
            let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                search: this.search,
                skip: 0,
                limit: 10,
                category_id:category_id._id
            })
            this.subCategories = response.data.sub_categories
            // this.count = this.temp_subCategories.length
            this.loadingSubCategory = false
            this.scrollableSubCategory = false
        },
        async loadProductTypeSearchNew(category_id,sub_category_id){
            this.loadingProductTypeNew = true
            this.productTypes = []
            this.allProducts = []
            let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                search: this.search,
                skip: 0,
                limit: 10,
                category_id:category_id._id,
                sub_category_id:sub_category_id._id,
            })
            if(response.data.type == 'Products'){
                this.allProducts = response.data.products
            }else if(response.data.type == 'Product Type'){
                this.productTypes = response.data.product_types
            }
            this.loadingProductTypeNew = false
            this.scrollableProductTypeNew = false
        },
        async loadProductSearchNew(category_id,sub_category_id,product_type_id){
            this.productTypes = []
            this.allProducts = []
            let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                search: this.search,
                skip: 0,
                limit: 10,
                category_id:category_id._id,
                sub_category_id:sub_category_id._id,
                product_type_id:product_type_id._id || ''
            })
            if(response.data.type == 'Products'){
                this.allProducts = response.data.products
            }else if(response.data.type == 'Product Type'){
                this.productTypes = response.data.product_types
            }
            this.loadingProductTypeNew = false
            this.scrollableProductTypeNew = false
        },
        async getVariant(product){
            let response = await this.$http.post('/combos/get_combo_product_dropdown', {
                search: this.search,
                skip: 0,
                limit: 10,
                product_id:product._id,
            })
            this.allVariants = response.data.Variants
        },
        selectedType(type,aindex,index){
            if(type == 'category'){
                this.create.combo_items[index].alternatives.forEach((el,index)=>{
                    if(index == aindex){
                        el.sub_category_id = ''
                        el.product_type_id = ''
                        el.product_id = ''
                        el.variant_id = ''
                        el.quantity = null
                    }
                })
            }else if(type == 'subCategory'){
                this.create.combo_items[index].alternatives.forEach((el,index)=>{
                    if(index == aindex){
                        el.product_type_id = ''
                        el.product_id = ''
                        el.variant_id = ''
                        el.quantity = null
                    }
                })
            }else if(type == 'productType'){
                this.create.combo_items[index].alternatives.forEach((el,index)=>{
                    if(index == aindex){
                        el.product_id = ''
                        el.variant_id = ''
                        el.quantity = null
                    }
                })
            }else if(type == 'product'){
                this.create.combo_items[index].alternatives.forEach((el,index)=>{
                    if(index == aindex){
                        el.variant_id = ''
                        el.quantity = null
                    }
                })
            }else if(type == 'variant'){
                this.create.combo_items[index].alternatives.forEach((el,index)=>{
                    if(index == aindex){
                        el.quantity = null
                    }
                })
            }
        },
        async getAllOrderTypes(){
            let params = {
                search_key:this.search_key
            }
            let res = await this.$http.post('order_type/get_all_order_types_for_dropdown',params)
            this.allOrderTypes = res.data.response.order_types
        },
        calculateGross(price,index){
            if(this.create.combo_price == null || ''){
                this.create.combo_price = 0
            }
            if(price == null || ''){
                price = 0
            }
            let value = price - this.create.combo_price/price*100
            this.create.yield_manager_details[index].gross_margin = (Math.round(value * 100) / 100)
        },
        calculateYield(){
            if(this.create.combo_price == ''){
                this.create.combo_price = 0
            }
            this.create.yield_manager_details.forEach((el,index)=>{
                if(el.price == null || ''){
                    el.price = 0
                }
                let value = el.price - this.create.combo_price/el.price*100
                el.gross_margin = (Math.round(value * 100) / 100)
            })
        },
        updateSubcategory(){
            if(this.create.category_id != null){
                this.create.sub_category_id = ''
                this.create.sub_category_id = this.create.category_id.sub_category
                this.allProductTypes = []
                this.create.product_type_id = ''
                this.scrollableProductType = false
                this.count = 0
            }else{
                this.create.sub_category_id = ''
            }
        },
        clearAllData(){
            this.create = {
                status:true,
                name: '',
                image_url: '',
                sub_category_id: '',
                category_id: '',
                product_type_id: '',
                combo_items:[
                    {
                        alternatives: [
                            {
                                category_id:'',
                                sub_category_id:'',
                                product_type_id:'',
                                product_id:'',
                                variant_id:'',
                                quantity:null
                            }
                        ]
                    },
                ],
                combo_price: null,
                yield_manager_details: [
                    {
                        order_type: '',
                        price: null,
                        gross_margin: null
                    }
                ]
            }
        },
        cancel(){
            this.clearAllData()
            if(this.$route.params.view == true){
                let paramsnew = this.$route.query.id
                this.$router.push({
                    name: 'ViewCombo',
                    query: { id: paramsnew },
                })
            }else{
                this.$router.push({
                    name: 'ComboManage',
                })
            }
        },
        save(){
            this.$validator.validateAll().then(result => {
        		if (result) {
					this.confirmSave(this.create)
				}
			})
        },
        confirmSave(params){
            params.combo_items.forEach((el,index)=>{
                el.alternatives.forEach((el1,index1)=>{
                    el1.category_id = el1.category_id._id
                    el1.sub_category_id = el1.sub_category_id._id
                    el1.product_type_id = el1.product_type_id._id || ''
                    el1.product_id = el1.product_id._id
                    el1.variant_id = el1.variant_id.variant_id || ''
                })
            })
            params.yield_manager_details.forEach((ym,index)=>{
                ym.order_type = ym.order_type.order_type
            })
            params.sub_category_id = params.sub_category_id._id
            params.product_type_id = params.product_type_id._id || ''
            params.category_id = params.category_id._id
            if(params.hasOwnProperty('outlet_currency')){
                delete params.outlet_currency
            }
            this.editcombo(params)
        },
        async editcombo(params){
            try {
                let res = await this.editComboApi(params)
                this.$swal({
                    title: res.response,
                    text: '',
                    type: 'success'
                })
                this.cancel()
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
                this.handleErrorResponse(params)
            }
        },
        handleErrorResponse(params){
            this.sectionCategories.forEach((cat,index)=>{
                if(cat._id == params.category_id){
                    this.create.category_id = cat
                    this.create.sub_category_id = cat.sub_category
                }
            })
            this.allProductTypes.forEach((p,i)=>{
                if(p._id == params.product_type_id){
                    this.create.product_type_id = p
                }
            })
            this.allOrderTypes.forEach((el,i)=>{
                params.yield_manager_details.forEach((el1,i1)=>{
                    if(el.order_type == el1.order_type){
                        this.create.yield_manager_details[i1].order_type = el
                    }
                })
            })
            params.combo_items.forEach((c,i)=>{
                c.alternatives.forEach((a,ai)=>{
                    a.category_id = ''
                    a.sub_category_id = ''
                    a.product_type_id = ''
                    a.product_id = ''
                    a.variant_id = ''
                    a.quantity = null
                })
            })
        },
        async getComboById(){
            try {
                let params = {
                    _id:this.$route.query.id
                }
                let res = await this.$http.post('/combos/get_combo_by_id',params)
                if(res.data.status_id == 1){
                    this.create = res.data.combo
                    this.create.yield_manager_details.forEach((el,index)=>{
                        el.order_type = {order_type:el.order_type}
                    })
                    this.getDetails()
                }else{
                    this.$swal({
                        title: res.data.reason,
                        text: '',
                        type: 'warning'
                    })
                    this.$router.push({
                        name: 'ComboManage'
                    })
                }
            } catch (reason) {

            }
        }
    },
    mounted(){
        this.getAllOrderTypes()
        this.getComboById()
    },
    created() {
		const dict = {
			custom: {
				combo_name: {
					required: () => "Combo Name is Required"
                },
            }
        }
        this.$validator.localize("en", dict);
    },
}
</script>

<style scoped>
.body-sections {
  margin-left: 20px;
  margin-right: 20px;
}
.body-sections-head {
  background-color: #00448b;
  padding: 15px;
}
.body-sections-header {
  color: #ffffff;
  margin: 0;
  font-size: 15px;
}
.input-head {
  color: #4e4e4e;
  font-weight: bold;
  font-size: 14px;
}
.inputContact {
  padding: 12px 10px 2px 0px !important;
  border-bottom: 1px solid #4e4e4e !important;
}
.inputContact::placeholder {
  color: #4e4e4e !important;
  font-weight: normal !important;
}
.combos-product-table th,
td {
  font-size: 12px !important;
  font-weight: bold;
}
#quantity-input {
  background-color: transparent;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #a9aaad;
  line-height: 25px;
  padding-top: 7px;
}
#quantity-input input[type=number]::placeholder {
  color: #303031 !important;
}
.add-th,
.add-td {
  border-bottom: 0 !important;
}
.input-prev {
  width: 20px;
  text-align: center;
  color: #303031;
  height: 25px;
  background-color: #cdd2da !important;
  padding: 0 !important;
}
.input-after {
  height: 25px;
  padding: 0 !important;
  width: 60%;
}
.payment .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.payment .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.payment-currency .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.payment-currency .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment-currency.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment-currency.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
</style>