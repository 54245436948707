<template>
    <div class="page-layout simple registerblock right-sidebar inner-sidebar">
        <div class="pb-10">
            <h2 class="headerblock pl-3 pr-3">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex">
                        <router-link
                            :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                              <button class="btn btn-light-grey btn-fab-dc mr-3">
                                <i class="icons icon-arrow-left"></i>
                              </button>
                            </el-tooltip>
                        </router-link>
                        <router-link
                            :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3">
                                  <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <a href="javascript:;" class="btn btn-secondary btn-smm ml-2">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg">
                            Filter
                        </a>
                        <date-picker
                          class="ml-3 calendarAuditAnalysisWidth"
                          v-model="dateRange"
                          @confirm="onDateChange()"
                          format="DD-MM-YYYY hh:mm A"
                          :first-day-of-week="7"
                          range
                          :show-second="false"
                          :shortcuts="shortcuts"
                          :lang="lang"
                          :clearable="true"
                          confirm
                          :not-after="new Date()"
                          confirm-text="APPLY"
                        ></date-picker>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-end">
                        <div class="d-flex align-items-center">
                            <span class="page-info px-2 hidden-md-down"> {{getSkipCount}} -{{total>skip+limit?skip+limit:total}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-left" @click="previousPage()"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-right" @click="nextPage()"></i>
                                </button>
                            </el-tooltip>
                        </div>
                        <div class="breadcome-heading d-none d-xl-inline">
                          <div class="form-group formsearch has-warning has-feedback">
                            <input type="text" v-model="search_product" @input="searchProduct();" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                            <!-- <span class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" ></span> -->
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                          </div>
                        </div>
                        <div class="d-flex ml-3 mt-1">
                          <el-dropdown @click="handleClick" trigger="click">
                            <span class="pointer exportButton pl-2 d-flex align-items-center">
                              <img src="https://cdn.digicollect.com/cdn/posv2/scm/exports.svg">
                              <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>PDF</el-dropdown-item>
                              <el-dropdown-item>XLSX</el-dropdown-item>
                              <el-dropdown-item>CSV</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <!-- <span class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
                            <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Pdf</span>
                          </span> -->
                          <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/posv2/scm/printer.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                          </span>
                        </div>
                    </div>
                </div>
            </h2>
            <div class="account-type-block">
                <div id="mail" style="">
                    <div class="content-section registerblock-section" style="">
                        <div class="center-section" style="background-color: #dee8f2;overflow-y: auto;margin: 10px;border-radius: 15px;padding: 10px 0px 0px 0px!important;">
                          <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 2px solid #00448b;border-radius: 18px;background-color: #fff;top: -15px;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;">
                              AUDIT - ANALYSIS
                          </div>
                            <div class="crmaccounttypecard crmaccounttype-over-card personal">
                                <div class="crmaccounttypecard-body mb-0">
                                  <div class="mat-table-scm mb-0">
                                      <div class="mat-header-row-scm">
                                            <div class="mat-header-cell-scm mat-header-image"></div>
                                            <div class="mat-header-cell-scm mat-header-first">
                                              <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                              PRODUCT NAME
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-cost pl-2">COST PRICE</div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-posfinal-qty pl-2 word-break">
                                                POS FINAL<br>QUANTITY
                                                <!-- <span class="mt-5 ml-1">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span> -->
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-posfinal pl-2 word-break">
                                                POS FINAL<br>QUANTITY(COST)
                                                <span class="mt-5 ml-1" @click="finalQunatityCost()">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span>
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-wastage pl-2">FREE OF CHARGE</div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-wastage pl-2">WASTAGE</div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-stockcount pl-2">
                                                STOCK COUNT<br>QUANTITY
                                                <span class="mt-5 ml-1" @click="stockCountQuantity()">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span>
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-stockcount pl-2">
                                                STOCK COUNT<br>(COST)
                                                <span class="mt-5 ml-1" @click="stockCountCost()">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span>
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-variant pl-2 word-break">
                                                VARIANCE<br>QUANTITY
                                                <span class="mt-5 ml-1" @click="varianceQuantity()">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span>
                                            </div>
                                            <div class="mat-header-cell-scm mat-header-cell-scm-variant pl-2 word-break">
                                                VARIANCE<br>(COST)
                                                <span class="mt-5 ml-1" @click="varianceCost()">
                                                    <i class="icon icon-information text-secondary pointer fs-14"></i>
                                                </span>
                                            </div>
                                      </div>
                                      <div class="mat-row-scm text-center" style="justify-content: center;" v-if="ajaxCallinprogress">
                                          <div class="digi-table-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                        <div class="mat-row-scm text-center text-secondary" v-if="!ajaxCallinprogress && !products.length && !search_product.length" style="justify-content: center;">
                                           No Products
                                        </div>
                                        <div class="mat-row-scm text-center text-secondary d-block" v-if="!ajaxCallinprogress && !products.length && search_product.length" style="justify-content: center;">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/no_search_result.svg" class="pl-20" style="padding-top: 20px;"><br>
                                           <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Search Results Found</p>
                                        </div>

                                      <div v-for="(product,index) in products" :key="index" v-else>
                                        <label class="w-100" style="padding-left:0 !important">
                                          <div class="mat-row-scm">
                                              <!-- <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                  <div class="avatar">
                                                      <img class="avatar" src="https://cdn.digicollect.com/cdn/posv2/avatars/1.jpg">
                                                  </div>
                                              </div> -->
                                              <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                    <input type="checkbox" class="form-check-input d-none" :value="product._id" :id="product._id" v-model="checkedVariants" @click="showVariant($event)" style="margin-bottom: 0px;">
                                                    <span class="icon icon-menu-down mt-1 text-secondary" v-if="checkedVariants.includes(product._id) && product.variant == true" style="width: 20px;"></span>
                                                    <span class="icon icon-menu-right mt-1 text-secondary" v-else v-show="product.variant == true" style="width: 20px;"></span>
                                                    <div class="avatar" :class="{addspace:!product.variant}" v-if="product.image != ''">
                                                        <img class="avatar" :src="product.image">
                                                    </div>
                                                    <avatar
                                                        v-else
                                                        :username="product.name.replace(/ .*/,'')"
                                                        :src="product.image"
                                                        :size="40"
                                                        :rounded="true"
                                                        color="#fff"
                                                        :class="{addspace:!product.variant}"
                                                    ></avatar>
                                                </div>
                                              <div class="mat-cell-scm mat-cell-scm-first">
                                                  <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                  {{product.name}}
                                              </div>
                                              <div class="mat-cell-scm mat-cell-scm-cost uk-text-truncate pl-2">{{product.price || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-posfinal-qty uk-text-truncate pl-2">{{product.pos_final_quantity || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-posfinal uk-text-truncate pl-2">{{product.pos_final_cost || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-wastage uk-text-truncate pl-2">{{product.free_of_charge || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-wastage uk-text-truncate pl-2">{{product.wastage || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-stockcount uk-text-truncate pl-2">{{product.stock_count || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-stockcount uk-text-truncate pl-2">{{product.stock_count_cost || 0}}</div>
                                              <div class="mat-cell-scm mat-cell-scm-variant pl-2" v-visible="!product.variant">
                                                  <span :class="{positiveCost:product.pos_final_quantity<product.stock_count,zeroCost:product.pos_final_quantity==product.stock_count,negativeCost:product.pos_final_quantity>product.stock_count}">
                                                    {{product.variance || 0}}
                                                  </span>
                                              </div>
                                              <div class="mat-cell-scm mat-cell-scm-variant pl-2" v-visible="!product.variant">
                                                  <span :class="{positiveCost:product.pos_final_quantity<product.stock_count,zeroCost:product.pos_final_quantity==product.stock_count,negativeCost:product.pos_final_quantity>product.stock_count}">
                                                    {{product.variance_cost || 0}}
                                                  </span>
                                              </div>
                                          </div>
                                        </label>
                                        <!--Variants-->
                                        <div class="mat-row-scm" v-show="checkedVariants.includes(product._id)" v-for="(variant,index) in product.variants" :key="index">
                                            <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                <!-- <div class="avatar ml-5">
                                                    <img class="avatar" src="https://cdn.digicollect.com/cdn/posv2/avatars/1.jpg">
                                                </div> -->
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-first">
                                                <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                {{variant.variant_name}}
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-cost uk-text-truncate pl-2">{{variant.variant_price || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-posfinal-qty uk-text-truncate pl-2">{{variant.pos_final_quantity || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-posfinal uk-text-truncate pl-2">{{variant.pos_final_cost || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-wastage uk-text-truncate pl-2">{{variant.free_of_charge || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-wastage uk-text-truncate pl-2">{{variant.wastage || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-stockcount uk-text-truncate pl-2">{{variant.stock_count || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-stockcount uk-text-truncate pl-2">{{variant.stock_count_cost || 0}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-variant pl-2">
                                                <span :class="{positiveCost:variant.pos_final_quantity<variant.stock_count,zeroCost:variant.pos_final_quantity==variant.stock_count,negativeCost:variant.pos_final_quantity>variant.stock_count}">
                                                  {{variant.variance || 0}}
                                                </span>
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-variant pl-2">
                                                <span :class="{positiveCost:variant.pos_final_quantity<variant.stock_count,zeroCost:variant.pos_final_quantity==variant.stock_count,negativeCost:variant.pos_final_quantity>variant.stock_count}">
                                                  {{variant.variance_cost || 0}}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- End Variants -->
                                        </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <final-cost modal_name="final-quantity-cost"></final-cost>
        <stock-cost modal_name="stock-count-cost"></stock-cost>
        <stock-count-quantity modal_name="stock-count-quantity"></stock-count-quantity>
        <variance-quantity modal_name="variance-quantity"></variance-quantity>
        <variance-cost modal_name="variance-cost"></variance-cost>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
const FinalCost = () => import('./FinalCost.vue');
const StockCost = () => import('./StockCost.vue');
const StockCountQuantity = () => import('./StockCountQuantity.vue');
const VarianceQuantity = () => import('./VarianceQuantity.vue');
const VarianceCost = () => import('./VarianceCost.vue');
import ScmServices from '../mixins/scm.js'
import Avatar from 'vue-avatar'
export default {
    data() {
        return {
          itemstatus: false,
          products:[],
          search_product:"",
          skip: 0,
          limit: 10,
          total:0,
          checkedVariants:[],
          ajaxCallinprogress: false,
          startDate: new Date(new Date().getFullYear(), 0, 1),
          endDate: new Date(new Date().getFullYear() + 1, 0, 1),
          dateRange: [],
          lang: {
                type: "en",
                days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                months: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ],
                pickers: [
                    "next 7 days",
                    "next 30 days",
                    "previous 7 days",
                    "previous 30 days"
                ],
                placeholder: {
                    date: "Select Date",
                    dateRange: "Select Date Range"
                }
            },
            confirmText: {
                type: String,
                default: "APPLY"
            },
            shortcuts: [
                {
                    text: "Today",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate()
                        );
                        this.endDate = new Date();
                        this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Week",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate() - new Date().getDay()
                        );
                        this.endDate = new Date();
                        this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Month",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                        );
                        this.endDate = new Date();
                        this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Year",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            0,
                            1
                        );
                        this.endDate = new Date();
                        this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                }
            ],
        };
    },
    mixins: [ScmServices],
    components: {
      DatePicker,
      FinalCost,
      StockCost,
      StockCountQuantity,
      VarianceQuantity,
      VarianceCost,
      Avatar,
    },
    methods: {
      handleClick() {
			},
      onDateChange() {
        this.endDate;
        this.dateRange[1];
        if (!this.dateRange[0] && !this.dateRange[1]) {
            this.startDate = new Date();
            this.startDate;
            this.endDate = new Date();
            this.dateRange = [this.startDate, this.endDate];
        }
      },
      finalQunatityCost(){
        this.$modal.show('final-quantity-cost');
      },
      stockCountCost(){
        this.$modal.show('stock-count-cost');
      },
      stockCountQuantity(){
        this.$modal.show('stock-count-quantity');
      },
      varianceQuantity(){
        this.$modal.show('variance-quantity');
      },
      varianceCost(){
        this.$modal.show('variance-cost');
      },
      searchProduct(){
          this.skip = 0;
          this.loadProducts()
      },
      showVariant(e){
        if (e.target.checked) {
        //  console.log(e.target.value)
        }
      },
      nextPage() {
        let skip_temp = this.skip + this.limit > this.total? this.total: this.skip + this.limit;
        if (skip_temp >= this.total) {
            return;
        }
        this.skip = skip_temp;
        this.loadProducts();
      },
      previousPage() {
        if (this.skip == 0) {
            return;
        }
        this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
        this.loadProducts();
      },
      async loadProducts(){
          this.products = []
          this.ajaxCallinprogress = true;
          let response = await this.getScmProducts(
              {
                  "search_key":this.search_product,
                  "skip":this.skip,
                  "limit":this.limit
              }
          );
          this.products = response.response
          this.total = response.total
          this.ajaxCallinprogress = false;
      },
    },
    created() {
        this.loadProducts();
    },
    computed: {
      loggedInUser() {
          return this.$store.getters.loggedInUser;
      },
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        }
    },
};
</script>
<style scoped>
.calendarWidth.mx-datepicker-range{
	width: 350px !important;
}
.page-layout.simple.registerblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.registerblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  background-color: #00448b;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.crmaccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.fuse-sidebar.crmaccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.crmaccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  background-color: #00448b;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerblock.left-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section,
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  flex: 1 1 250px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.crmaccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.crmaccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.crmaccounttype-list-item .crmaccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.crmaccounttype-list-item .crmaccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.crmaccounttype-list-item img.crmaccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.crmaccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #00448b;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
.mat-table-scm {
    background-color: transparent;
    box-shadow: none;
    display: block;
    margin: 0px 0px 20px;
}
.mat-header-row-scm, .mat-row-scm{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 0px 0px;
}

.mat-header-cell-scm {
    font-size: 14px;
    font-weight: 600;
    color: #00448b;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
    text-transform: uppercase;
}
.mat-cell-scm, .mat-footer-cell, .mat-header-cell-scm {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
}
.mat-header-cell-scm.mat-header-first, .mat-cell-scm.mat-cell-scm-first {
    flex: 1 1 150px;
    box-sizing: border-box;
    max-width: 150px;
    min-width: 150px;
}
.mat-header-cell-scm.mat-header-cell-scm-lastanalysis, .mat-cell-scm.mat-cell-scm-lastanalysis{
    flex: 1 1 120px;
    box-sizing: border-box;
    max-width: 120px;
    min-width: 120px;
    padding-right: 5px;
}
.mat-header-cell-scm {
  box-shadow: 4px 0 0px -2px #94bef0;
}
/* .word-break{
  word-break: break-all;
} */
.mat-header-cell-scm:last-child, .mat-header-cell-scm:first-child  {
    box-shadow: none;
}
.mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
    flex: 1 1 60px;
    box-sizing: border-box;
    max-width: 60px;
    min-width: 60px;
}
@media (max-width: 1280px) {
    .mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
        flex: 1 1 60px;
        box-sizing: border-box;
        max-width: 60px;
        min-width: 60px;
    }
    .mat-header-cell-scm.mat-header-last, .mat-cell-scm.mat-cell-scm-last {
        flex: 1 1 85px;
        box-sizing: border-box;
        max-width: 85px;
        min-width: 85px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-posfinal-qty, .mat-cell-scm.mat-cell-scm-posfinal-qty {
        flex: 1 1 100px;
        box-sizing: border-box;
        max-width: 100px;
        min-width: 100px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-cost, .mat-cell-scm.mat-cell-scm-cost {
        flex: 1 1 80px;
        box-sizing: border-box;
        max-width: 80px;
        min-width: 80px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-posfinal, .mat-cell-scm.mat-cell-scm-posfinal {
        flex: 1 1 145px;
        box-sizing: border-box;
        max-width: 145px;
        min-width: 145px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-stockcount, .mat-cell-scm.mat-cell-scm-stockcount {
        flex: 1 1 125px;
        box-sizing: border-box;
        max-width: 125px;
        min-width: 125px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-wastage, .mat-cell-scm.mat-cell-scm-wastage {
        flex: 1 1 80px;
        box-sizing: border-box;
        max-width: 80px;
        min-width: 80px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-variant, .mat-cell-scm.mat-cell-scm-variant {
        flex: 1 1 125px;
        box-sizing: border-box;
        max-width: 125px;
        min-width: 125px;
    }
}

/* @media (min-width: 1400px) {
    .mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
        flex: 1 1 60px;
        box-sizing: border-box;
        max-width: 60px;
        min-width: 60px;
    }
    .mat-header-cell-scm.mat-header-last, .mat-cell-scm.mat-cell-scm-last {
        flex: 1 1 140px;
        box-sizing: border-box;
        max-width: 140px;
        min-width: 140px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-posfinal-qty, .mat-cell-scm.mat-cell-scm-posfinal-qty {
        flex: 1 1 140px;
        box-sizing: border-box;
        max-width: 140px;
        min-width: 140px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-cost, .mat-cell-scm.mat-cell-scm-cost {
        flex: 1 1 140px;
        box-sizing: border-box;
        max-width: 140px;
        min-width: 140px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-posfinal, .mat-cell-scm.mat-cell-scm-posfinal {
        flex: 1 1 145px;
        box-sizing: border-box;
        max-width: 145px;
        min-width: 145px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-stockcount, .mat-cell-scm.mat-cell-scm-stockcount {
        flex: 1 1 145px;
        box-sizing: border-box;
        max-width: 145px;
        min-width: 145px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-wastage, .mat-cell-scm.mat-cell-scm-wastage {
        flex: 1 1 140px;
        box-sizing: border-box;
        max-width: 140px;
        min-width: 140px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-variant, .mat-cell-scm.mat-cell-scm-variant {
        flex: 1 1 145px;
        box-sizing: border-box;
        max-width: 145px;
        min-width: 145px;
    }
} */
.mat-header-row-scm {
    min-height: 50px;
    padding-left: 10px;
    padding-bottom: 8px;
}
.mat-table-scm .mat-row-scm {
    position: relative;
    cursor: pointer;
    min-height: 60px;
    background-color: transparent;
    border-bottom: 1px solid #a8adb4;
}
.mat-table-scm .mat-row-scm:hover {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.mat-header-row-scm, .mat-row-scm {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    border-bottom-color: #a8adb4;
    padding-left: 10px;
}
.mat-cell-scm .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    font-weight: 400!important;
    line-height: 1;
}
.mat-cell-scm, .mat-footer-cell {
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.avatar.avatar-border {
    border: 1.5px solid rgb(205, 210, 218);
    background-color: rgba(255, 255, 255, 0);
    width: 47px !important;
    height: 47px !important;
    border-radius: 50%;
    display: inline-block;
    min-width: 47px !important;
}
.avatar.avatar-border img {
    width: 37px !important;
    height: 37px !important;
    border-radius: 50%;
    min-width: 37px !important;
    max-width: 37px !important;
    max-height: 37px !important;
}
.mat-cell-scm .list-group .list-group-item h3 {
    font-size: 14px !important;
    font-weight: 600;
}
.mat-cell-scm .list-group .list-group-item p {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #4e5764 !important;
}
.mat-cell-scm .list-group .list-group-item>.avatar {
    margin: 0 1rem 0 0 !important;
}
.des-list-box {
    border-radius: 5px;
    padding: 7px 10px;
    color: #fff;
    width: 110px;
    text-align: center;
}
.mat-row-scm:hover .eyeIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .editIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .deleteIcon {
    color: #00448b !important;
}
.mat-row-scm:hover .cloudIcon {
    color: #e82828 !important;
}
.list-group.list-cat {
    padding: 8px 30px;
}
.list-group.list-cat .list-group-item.two-line {
    min-height: 5.2rem;
}
.list-group.list-cat .list-group-item {
	background-color: transparent !important;
}
.list-group.list-cat .list-group-item h3 {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 1.22;
  	letter-spacing: 0.77px;
    color: #fff;
    padding-bottom: 5px;
}
.list-group.list-cat .list-group-item p {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 1.23;
  	letter-spacing: 0.56px;
    color: #fff;
}
.category-badge.category-badge--dot.category-badge--xl {
    line-height: 15px;
    min-height: 15px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    font-size: 0;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    border-radius: 50%;
    border: 2px solid #fff;
}
.category-badge.category-badge--veg {
    color: #fff;
    background: #008000;
}
.category-badge.category-badge--nonveg {
    color: #fff;
    background: #d40000;
}
.category-badge.category-badge--liquid {
    color: #fff;
    background: #fec502;
}
.category-badge.category-badge--transparent {
    color: transparent;
    background: transparent;
    border: 2px solid transparent !important;
}
.positiveCost{
    background-color: #f5a623;
    padding: 5px;
    width:110px;
    max-width: 110px;
    color: #303031;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
}
.zeroCost{
    background-color: #1a9347;
    padding: 5px;
    width:110px;
    max-width: 110px;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
}
.negativeCost{
    background-color: #e82828;
    padding: 5px;
    width:110px;
    max-width: 110px;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
}
.exportButton{
		width: auto;
		padding-right: 7px;
		height: 25px;
		border-radius: 4px;
		background-color: #1a9347;
	}
	.exportButton img{
		width:13px;
	}
  .pdfButton img{
    width:13px;
  }
  .printButton img{
		width:13px;
	}
	.pdfButton{
		padding-right: 7px;
		height: 25px;
		border-radius: 4px;
		background-color: #e82828;
    width: 66px;
    font-size: 14px;
	}
	.printButton{
    width: 66px;
    font-size: 14px;
		padding-right: 7px;
		height: 25px;
		border-radius: 4px;
		background-color: #00448b;
	}
	.export-index{
		z-index: 99999 !important;
	}
	.el-dropdown-menu__item{
		color: #303030 !important;
	}
  .addspace {
    margin-left: 20px;
  }
</style>