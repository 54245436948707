<template>
    <div class="page-layout blank create-outlet">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row col-md-12">
                    <router-link :disabled="!$routerHistory.hasPrevious()"
                        :to="{ path: $routerHistory.previous().path }">
                        <el-tooltip class="item pull-right" effect="dark" content="Click to Go Back" placement="top">
                            <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                <i class="icons icon-arrow-left"></i>
                            </button>
                        </el-tooltip>
                    </router-link>
                    <router-link :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                        <el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward" placement="top">
                            <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                <i class="icons icon-arrow-right"></i>
                            </button>
                        </el-tooltip>
                    </router-link>
                </div>
            </h2>
            <div class="page-layout carded left-sidebar" style="background: #00448b;">
                <div class="content-settings outlet-new-design" style="max-width:100% !important;margin:0 !important;padding-left:150px !important;padding-right:150px !important;">
                    <div class="create-heading">ADD NEW OUTLET</div>
                    <h6 class="heading-details pb-1 pt-2" style="color:#303031 !important">GENERAL SETTINGS</h6>
                    <h6 class="heading-details pb-1 fs-16" style="padding-top:25px;">General Details</h6>
                    <div class="row first-middle-input">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="profile-left pt-4 text-left textCenter">
                                <div class="profile-image ml-4 alignText">
                                    <my-upload field="img" @crop-success="onOutletPicCrop" v-model="showOutletPic"
                                        :width="300" langType="en" :height="300"
                                        :params="{token: '123456798', name: 'avatar'}" :headers="{smail: '*_~'}"
                                        img-format="png"></my-upload>
                                    <img :src="imgDataUrl" style="width: 90px;background: #fff;" alt="Outlet Image" />
                                </div>
                                <div class="mb-4 mt-4 profile-button">
                                    <a class="btn btn-secondary btn-smm" @click="showOutletPic=!showOutletPic"
                                        id="upload_picture">Upload Picture</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <div class="row mt-4 pl-2">
                                <div class="col-lg-12">
                                    <div class="group-setup mb-4 mt-4">
                                        <input id="outle_name" class="digi-form" type="text"
                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}" name="outletName"
                                            :maxlength="createoutletname" v-model="outletDetails.name" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            Outlet Name
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" style="display:inline;"
                                            v-show="errors.has('outletName') && ifSubmitted">{{errors.first('outletName')}}</span>
                                        <span class="text-danger fs-13" style="display:inline;" v-if="false">Duplicate
                                            Outlet Name</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                            {{createoutletname - outletDetails.name.length}} / {{createoutletname}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-5">
                                    <div class="group-setup">
                                        <input id="website" class="digi-form" type="text" name="outletWebsite"
                                            v-validate="{required:true,regex:/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/}"
                                            v-model="outletDetails.website" :maxlength="createwebsite" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            Website
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" style="display:inline;"
                                            v-show="errors.has('outletWebsite') && ifSubmitted">{{errors.first('outletWebsite')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                            {{createwebsite - outletDetails.website.length}} / {{createwebsite}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-2">
                                    <div class="form-group contactGroup selectlabels">
                                        <float-label label="Outlet Type">
                                            <select id="outlet_type" class="form-control" name="OutletType"
                                                v-validate="'required'" v-model="outletDetails.outlet_type"
                                                style="max-width: 100% !important;">
                                                <!-- <option disabled selected>Select Outlet Type<sup>*</sup></option> -->

                                                <option value="" disabled selected>Select Outlet Type</option>
                                                <option value="pop-up">
                                                    Pop-up (Acts as a master, can add vendors to his
                                                    pop up store)
                                                </option>
                                                <option value="standalone">
                                                    Standalone (Operates independently of other
                                                    outlets)
                                                </option>
                                                <option value="vendor">
                                                    Vendor (Acts as standalone also is ready to be a
                                                    part of pop up)
                                                </option>
                                            </select>
                                        </float-label>
                                        <span class="text-danger fs-13"
                                            v-show="errors.has('OutletType') && ifSubmitted">
                                            Outlet Type is
                                            Required
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-md-6">
                                <label class="fs-14" style="color:#00448b;">Is Outlet? <sup>★</sup></label><br>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="outletDetails.branch_type" type="radio" class="form-check-input"
                                            value="headquarters" readonly />
                                        <span class="radio-icon"></span>
                                        <span>Headquarters</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="outletDetails.branch_type" type="radio" class="form-check-input"
                                            value="branch" readonly />
                                        <span class="radio-icon"></span>
                                        <span>Branch</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="outletDetails.branch_type == 'branch'">
                                <div class="form-group" style="margin-top:-23px !important;">
                                    <label class="fs-14" style="color:#00448b;">Branch <sup>★</sup></label>
                                    <v-select label="name" index="_id"
                                        class="select-width clearNone optionsHeight vSelectWidth"
                                        placeholder="Select Branch" :filterable="true" :options="branchs" required
                                        v-model="outletDetails.branch_id" v-validate="'required'" name="branch"
                                        id="select_branch"></v-select>
                                    <span class="invalid-feedback-form text-danger fs-13"
                                        v-show="errors.has('branch')">Branch Name is Required</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-md-6">
                                <label class="fs-14" style="color:#00448b;">Select Outlet mode? <sup>★</sup></label><br>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="outlet_mode" type="radio" class="form-check-input"
                                            value="cloud" />
                                        <span class="radio-icon"></span>
                                        <span>Cloud</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="outlet_mode" type="radio" class="form-check-input"
                                            value="hybrid" />
                                        <span class="radio-icon"></span>
                                        <span>Hybrid</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-5">
                            <float-label label="Tax ID">
                                <input id="tax_id" class="form-control" name="taxId" type="text" placeholder="Tax ID*"
                                    :maxlength="createtaxid" v-model="outletDetails.tax_id" v-validate="'required'" />
                                <span class="text-danger fs-13"
                                    v-show="errors.has('taxId') && ifSubmitted">{{errors.first('taxId')}}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                    {{createtaxid - outletDetails.tax_id.length}} / {{createtaxid}}</p>
                            </float-label>
                        </div>
                        <div class="col-lg-6 mt-5">
                            <float-label label="Email">
                                <input id="email" class="form-control" name="email"
                                    placeholder="Please enter your Email ID*" v-model="outletDetails.email"
                                    :maxlength="createemail"
                                    v-validate="{required:true,regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/}" />
                                <span class="text-danger fs-13" style="display:inline;"
                                    v-show="errors.has('email')">{{errors.first('email')}}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                    {{createemail - outletDetails.email.length}} / {{createemail}}</p>
                            </float-label>
                        </div>
                        <div class="col-lg-12 pt-7">
                            <label for="inputSattus" class="col-form-label text-secondary">Phone Number<sup>★</sup></label>
                            <vue-tel-input id="phone_number" placeholder="Please enter your Phone Number"
                                :preferredCountries="['th', 'in', 'us']" v-model="phoneNumber"
                                @onInput="onPhoneNumberInput" :showDialCode="true"></vue-tel-input>
                            <span class="text-danger fs-13" v-show="isPhoneNumberInvalid">
                                Please Enter
                                Valid Phone Number
                            </span>
                            <span class="text-danger fs-13" v-show="!phoneNumber && ifSubmitted"
                                v-if="phoneNumber.length<2">
                                Phone Number is
                                Required
                            </span>
                        </div>
                    </div>
                    <h6 class="heading-details pb-1" style="padding-top:25px;">Locale Settings</h6>
                    <div class="row pt-6 flex-wrap">
                        <div class="col-lg-6 col-md-6 col-sm-12 selectlabels">
                            <float-label label="Timezone">
                                <select id="timezone" class="form-control" name="timezone" v-validate="'required'"
                                    v-model="outletDetails.timezone" placeholder="Select Timezone"
                                    style="max-width: 100% !important;">
                                    <option value="" disabled selected>Select Timezone</option>
                                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                    <option value="America/Juneau">(GMT-08:00) Alaska</option>
                                    <option value="America/Phoenix">(GMT-07:00) Arizona</option>
                                    <option value="America/Dawson">(GMT-07:00) Pacific Time (US and Canada); Tijuana
                                    </option>
                                    <option value="America/Belize">(GMT-06:00) Central America</option>
                                    <option value="America/Chihuahua">(GMT-06:00) Chihuahua, La Paz, Mazatlan</option>
                                    <option value="America/Regina">(GMT-06:00) Saskatchewan</option>
                                    <option value="America/Boise">(GMT-06:00) Mountain Time (US and Canada)</option>
                                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito</option>
                                    <option value="America/Chicago">(GMT-05:00) Central Time (US and Canada)</option>
                                    <option value="America/Mexico_City">(GMT-05:00) Guadalajara, Mexico City, Monterrey
                                    </option>
                                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                    <option value="America/Indiana/Indianapolis">(GMT-04:00) Indiana (East)</option>
                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                    <option value="America/Detroit">(GMT-04:00) Eastern Time (US and Canada)</option>
                                    <option value="America/Glace_Bay">(GMT-03:00) Atlantic Time (Canada)</option>
                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown
                                    </option>
                                    <option value="America/St_Johns">(GMT-02:30) Newfoundland and Labrador</option>
                                    <option value="Etc/GMT+2">(GMT-02:00) Mid-Atlantic</option>
                                    <option value="America/Godthab">(GMT-02:00) Greenland</option>
                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Islands</option>
                                    <option value="Africa/Casablanca">(GMT) Casablanca, Monrovia</option>
                                    <option value="Atlantic/Azores">(GMT) Azores</option>
                                    <option value="GMT">(GMT) Dublin, Edinburgh, Lisbon, London</option>
                                    <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                                    <option value="Atlantic/Canary">(GMT+01:00) Canary Islands</option>
                                    <option value="Europe/Brussels">(GMT+02:00) Brussels, Copenhagen, Madrid, Paris
                                    </option>
                                    <option value="Europe/Sarajevo">(GMT+02:00) Sarajevo, Skopje, Warsaw, Zagreb
                                    </option>
                                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                    <option value="Europe/Amsterdam">
                                        (GMT+02:00) Amsterdam, Berlin, Bern, Rome,
                                        Stockholm, Vienna
                                    </option>
                                    <option value="Europe/Belgrade">
                                        (GMT+02:00) Belgrade, Bratislava, Budapest,
                                        Ljubljana, Prague
                                    </option>
                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                    <option value="Europe/Athens">(GMT+03:00) Athens, Istanbul, Minsk</option>
                                    <option value="Asia/Baghdad">(GMT+03:00) Baghdad</option>
                                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                    <option value="Europe/Bucharest">(GMT+03:00) Bucharest</option>
                                    <option value="Europe/Helsinki">
                                        (GMT+03:00) Helsinki, Kiev, Riga, Sofia, Tallinn,
                                        Vilnius
                                    </option>
                                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh</option>
                                    <option value="Asia/Jerusalem">(GMT+03:00) Jerusalem</option>
                                    <option value="Asia/Baku">(GMT+04:00) Baku, Tbilisi, Yerevan</option>
                                    <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                    <option value="Asia/Tehran">(GMT+04:30) Tehran</option>
                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                    <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                                    </option>
                                    <option value="Asia/Colombo">(GMT+05:30) Sri Jayawardenepura</option>
                                    <option value="Asia/Kathmandu">(GMT+05:45) Kathmandu</option>
                                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon Rangoon</option>
                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaanbaatar</option>
                                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                    <option value="Asia/Shanghai">(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi
                                    </option>
                                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                    <option value="Australia/Sydney">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Islands, New Caledonia
                                    </option>
                                    <option value="Pacific/Fiji">(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands
                                    </option>
                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                </select>
                            </float-label>
                            <span class="text-danger fs-13"
                                v-show="errors.has('timezone') && ifSubmitted">{{errors.first('timezone')}}</span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 selectlabels timezonelocale">
                            <!-- <label class="text-secondary">Currency<sup>★</sup></label> -->
                            <float-label label="Currency">
                                <select id="currency" class="form-control" v-model="outletDetails.currency.name"
                                    @change="assignCurrencySymbol()" name="currency" v-validate="'required'"
                                    placeholder="Select Currency" style="max-width: 100% !important;">
                                    <!-- <option disabled selected>Select Currency<sup>★</sup></option> -->
                                    <option value="" disabled selected>Select Currency</option>
                                    <option value="USD" data-symbol="$">USD ($)</option>
                                    <option value="INR" data-symbol="₹">INR (₹)</option>
                                    <option value="EUR" data-symbol="€">EUR (€)</option>
                                    <option value="GBP" data-symbol="£">GBP (£)</option>
                                    <option value="JPY" data-symbol="¥">JPY (¥)</option>
                                    <option value="MMK" data-symbol="K">MMK (K)</option>
                                    <option value="SGD" data-symbol="S$">SGD (S$)</option>
                                    <option value="NGN" data-symbol="₦">NGN (₦)</option>
                                    <option value="THB" data-symbol="฿">THB (฿)</option>
                                </select>
                            </float-label>
                            <span class="text-danger fs-13" v-show="errors.has('currency') && ifSubmitted">
                                Currency is
                                Required
                            </span>
                        </div>
                    </div>
                    <!-- <div class="row mt-5">
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex flex-wrap res-settings pl-4" style="display: -webkit-inline-box;">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group contactGroup w-100 pb-3">
                                    <label class="text-secondary pr-2 fw-600 pb-1">Precision Settings :</label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 d-flex flex-wrap">
                                <label class="text-secondary">
                                    Allow Decimal Points?
                                </label>
                                <el-switch class="pl-5" active-text="Yes" inactive-text="No" @change="UpdateDecimal()" v-model="outletDetails.is_decimal_points_allowed"></el-switch>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 d-flex flex-nowrap align-items-center" v-if="outletDetails.is_decimal_points_allowed">
                                <label class="text-secondary">
                                    No. Of Decimal Points:
                                </label>
                                <input class="ml-5" id="preventkeys" maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' @blur="checkDp()" :readonly="!outletDetails.is_decimal_points_allowed" style="width:200px !important;padding: 5px 10px !important;border-radius: 8px !important;" type="number" v-on:keypress="isNumber($event)" v-model.number="outletDetails.no_of_decimal_points"/>
                            </div>
                        </div>
                    </div> -->
                    <div class="row mt-5">
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <div class="col-lg-6">
                                <label class="text-secondary pr-2 fw-600 pb-1">Roundoff Settings :</label><br>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="outletDetails.round_off_settings" type="radio"
                                            class="form-check-input" value="neutral" />
                                        <span class="radio-icon"></span>
                                        <span>Neutral</span>
                                    </label>
                                </div>
                                <!-- <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input
                                            v-model="outletDetails.round_off_settings"
                                            type="radio"
                                            class="form-check-input"
                                            value="round_down"
                                        />
                                        <span class="radio-icon"></span>
                                        <span>Round Down</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                    <input
                                        v-model="outletDetails.round_off_settings"
                                        type="radio"
                                        class="form-check-input"
                                        value="round_up"
                                    />
                                    <span class="radio-icon"></span>
                                    <span>Round Up</span>
                                    </label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-lg-12">
                            <h6 class="heading-details">
                                Long Form Address
                            </h6>
                            <div class="form-group form-bottom selectlabels">
                                <div class="group-setup">
                                    <input id="long_address_street" class="digi-form" type="text" name="address1"
                                        v-validate="'required'" :maxlength="createstreet"
                                        v-model="outletDetails.address.street" required />
                                    <span class="highlight digi-highlight"></span>
                                    <span class="bar digi-bar"></span>
                                    <label class="digi-label">
                                        Address 1
                                        <sup>★</sup>
                                    </label>
                                    <span class="text-danger fs-13" v-show="errors.has('address1') && ifSubmitted"
                                        style="display:inline;">Address 1 is Required</span>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                        style="margin-bottom: 0px!important;">
                                        {{createstreet - outletDetails.address.street.length}} / {{createstreet}}</p>
                                </div>
                            </div>
                            <div class="form-group form-bottom selectlabels">
                                <div class="group-setup">
                                    <input id="long_shprt_address" class="digi-form" type="text"
                                        :maxlength="createstreetnew" v-model="outletDetails.address.street2" required />
                                    <span class="highlight digi-highlight"></span>
                                    <span class="bar digi-bar"></span>
                                    <label class="digi-label">Address 2</label>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                        style="margin-bottom: 0px!important;">
                                        {{createstreetnew - outletDetails.address.street2.length}} / {{createstreetnew}}
                                    </p>
                                </div>
                            </div>
                            <div class="form-group form-top row flex-wrap mt-8">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="group-setup">
                                        <input id="long_township" v-model="outletDetails.address.township"
                                            class="digi-form" type="text"
                                            :maxlength="createtownship" name="township" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            Township
                                        </label>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createtownship - outletDetails.address.township.length}} /
                                            {{createtownship}}</p>
                                        <!-- <span class="text-danger fs-13"
                                            v-show="errors.has('township') && ifSubmitted">Township is Required</span> -->
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="group-setup">
                                        <input id="long_city" class="digi-form" type="text"
                                            v-model="outletDetails.address.city" :maxlength="createcity" name="city"
                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            City
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('city') && ifSubmitted"
                                            style="display:inline;">{{errors.first('city')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createcity - outletDetails.address.city.length}} / {{createcity}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="group-setup">
                                        <input id="long_state" class="digi-form" type="text"
                                            v-model="outletDetails.address.state" :maxlength="createstate" name="state"
                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            State
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('state') && ifSubmitted"
                                            style="display:inline;">{{errors.first('state')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createstate - outletDetails.address.state.length}} / {{createstate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group form-bottom row flex-wrap selectlabels">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="group-setup">
                                        <input id="long_zipcode" class="digi-form" type="text"
                                            v-model="outletDetails.address.zipcode" name="zipcode"
                                            v-validate="'required|numeric'" :maxlength="createzipcode" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            Zip Code
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('zipcode')"
                                            style="display:inline;">{{errors.first('zipcode')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createzipcode - outletDetails.address.zipcode.length}} / {{createzipcode}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 col-sm-12 mt-1 selectlabels">
                                    <float-label label="Country">
                                        <select id="long_zipcode" class="form-control" placeholder="Select Country"
                                            name="country" v-validate="'required'"
                                            v-model="outletDetails.address.country" style="max-width: 100% !important;">
                                            <option value="" disabled selected>Select Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Island">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia, Plurinational State of">
                                                Bolivia, Plurinational State
                                                of
                                            </option>
                                            <option value="Bonaire, Sint Eustatius and Saba">
                                                Bonaire, Sint Eustatius and
                                                Saba
                                            </option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">
                                                British Indian Ocean
                                                Territory
                                            </option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, the Democratic Republic of the">
                                                Congo, the Democratic
                                                Republic of the
                                            </option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaçao">Curaçao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)
                                            </option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories
                                            </option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and McDonald Islands">
                                                Heard Island and McDonald
                                                Islands
                                            </option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)
                                            </option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">
                                                Korea, Democratic
                                                People's Republic of
                                            </option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">
                                                Lao People's Democratic
                                                Republic
                                            </option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, the Former Yugoslav Republic of">
                                                Macedonia, the
                                                Former Yugoslav Republic of
                                            </option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">
                                                Micronesia, Federated States
                                                of
                                            </option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestine, State of">Palestine, State of</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Réunion">Réunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthélemy">Saint Barthélemy</option>
                                            <option value="Saint Helena, Ascension and Tristan da Cunha">
                                                Saint Helena,
                                                Ascension and Tristan da Cunha
                                            </option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Martin (French part)">Saint Martin (French part)
                                            </option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">
                                                Saint Vincent and the
                                                Grenadines
                                            </option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">
                                                South Georgia
                                                and the South Sandwich Islands
                                            </option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of
                                            </option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">
                                                United States Minor
                                                Outlying Islands
                                            </option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela, Bolivarian Republic of">
                                                Venezuela, Bolivarian
                                                Republic of
                                            </option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </float-label>
                                    <span class="text-danger fs-13"
                                        v-show="errors.has('country') && ifSubmitted">Country is Required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-lg-12">
                            <h6 class="heading-details">
                                Short Form Address
                                <span style="padding: 0 20px;color: #303031!important;">|</span>
                                <el-switch v-model="cop_address_model" @change="copy_address_long_to_short()"
                                    inactive-text="Copy from Long Form Address"></el-switch>
                            </h6>
                            <div class="form-group form-bottom selectlabels">
                                <div class="group-setup">
                                    <input class="digi-form" type="text" name="streetshort" v-validate="'required'"
                                        :maxlength="createstreetshort" v-model="outletDetails.short_address.street"
                                        required id="short_address" />
                                    <span class="highlight digi-highlight"></span>
                                    <span class="bar digi-bar"></span>
                                    <label class="digi-label">
                                        Address 1
                                        <sup>★</sup>
                                    </label>
                                    <span class="text-danger fs-13" v-show="errors.has('streetshort') && ifSubmitted"
                                        style="display:inline;">Address 1 is Required</span>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                        style="margin-bottom: 0px!important;">
                                        {{createstreetshort - outletDetails.short_address.street.length}} /
                                        {{createstreetshort}}</p>
                                </div>
                            </div>
                            <div class="form-group form-top row flex-wrap mt-8">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="group-setup">
                                        <input id="short_city" class="digi-form" type="text"
                                            v-model="outletDetails.short_address.city" :maxlength="createcityshort"
                                            name="cityshort" v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                            required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            City
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('cityshort') && ifSubmitted"
                                            style="display:inline;">{{errors.first('cityshort')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createcityshort - outletDetails.short_address.city.length}} /
                                            {{createcityshort}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="group-setup">
                                        <input id="short_state" class="digi-form" type="text"
                                            v-model="outletDetails.short_address.state" :maxlength="createstate"
                                            name="stateshort" v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                            required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            State
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('stateshort') && ifSubmitted"
                                            style="display:inline;">{{errors.first('stateshort')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createstateshort - outletDetails.short_address.state.length}} /
                                            {{createstateshort}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group form-bottom row flex-wrap selectlabels">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="group-setup">
                                        <input id="short_zipcode" class="digi-form" type="text"
                                            v-model="outletDetails.short_address.zipcode" name="zipcodeshort"
                                            v-validate="'required|numeric'" :maxlength="createzipcodeshort" required />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">
                                            Zip Code
                                            <sup>★</sup>
                                        </label>
                                        <span class="text-danger fs-13" v-show="errors.has('zipcodeshort')"
                                            style="display:inline;">{{errors.first('zipcodeshort')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{createzipcodeshort - outletDetails.short_address.zipcode.length}} /
                                            {{createzipcodeshort}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mt-1 selectlabels"
                                    :class="{'defautselected':cop_address_model}">
                                    <float-label label="Country">
                                        <select id="short_country" class="form-control" placeholder="Select Country"
                                            name="countryshort" v-validate="'required'"
                                            v-model="outletDetails.short_address.country"
                                            style="max-width: 100% !important;"> 
                                            <option value="" disabled selected>Select Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Island">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia, Plurinational State of">
                                                Bolivia, Plurinational State
                                                of
                                            </option>
                                            <option value="Bonaire, Sint Eustatius and Saba">
                                                Bonaire, Sint Eustatius and
                                                Saba
                                            </option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">
                                                British Indian Ocean
                                                Territory
                                            </option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, the Democratic Republic of the">
                                                Congo, the Democratic
                                                Republic of the
                                            </option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaçao">Curaçao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)
                                            </option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories
                                            </option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and McDonald Islands">
                                                Heard Island and McDonald
                                                Islands
                                            </option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)
                                            </option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">
                                                Korea, Democratic
                                                People's Republic of
                                            </option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">
                                                Lao People's Democratic
                                                Republic
                                            </option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, the Former Yugoslav Republic of">
                                                Macedonia, the
                                                Former Yugoslav Republic of
                                            </option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">
                                                Micronesia, Federated States
                                                of
                                            </option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestine, State of">Palestine, State of</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Réunion">Réunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthélemy">Saint Barthélemy</option>
                                            <option value="Saint Helena, Ascension and Tristan da Cunha">
                                                Saint Helena,
                                                Ascension and Tristan da Cunha
                                            </option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Martin (French part)">Saint Martin (French part)
                                            </option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">
                                                Saint Vincent and the
                                                Grenadines
                                            </option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">
                                                South Georgia
                                                and the South Sandwich Islands
                                            </option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of
                                            </option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">
                                                United States Minor
                                                Outlying Islands
                                            </option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela, Bolivarian Republic of">
                                                Venezuela, Bolivarian
                                                Republic of
                                            </option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </float-label>
                                    <span class="text-danger fs-13"
                                        v-show="errors.has('countryshort') && ifSubmitted">Country is Required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">RECEIPT SETTINGS</h6>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" />
                        <div class="col-lg-6">
                            <label class="text-secondary pr-2 fw-600 pb-1">Receipt Format Configuration :</label>
                            <br />
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="outletDetails.receipt_format" type="radio" class="form-check-input"
                                        name="receiptFormat" id="optionsRadios1" value="restaurant" readonly />
                                    <span class="radio-icon"></span>
                                    <span>Restaurant</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="outletDetails.receipt_format" type="radio" class="form-check-input"
                                        name="receiptFormat" id="optionsRadios2" value="restaurant_and_hotels"
                                        readonly />
                                    <span class="radio-icon"></span>
                                    <span>Restaurant and Hotels</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input v-model="outletDetails.receipt_format" type="radio" class="form-check-input"
                                        name="receiptFormat" id="optionsRadios3" value="events" readonly checked />
                                    <span class="radio-icon"></span>
                                    <span>Events</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" />
                        <div class="col-lg-6">
                            <label class="text-secondary pr-2 fw-600 pb-1">Address Format for Invoices :</label>
                            <br />
                            <el-switch style="display: block"
                                v-model="outletDetails.use_short_address_format_for_invoice" active-color="#00448b"
                                inactive-color="#00448b" active-text="Long Form" inactive-text="Short Form"></el-switch>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" />
                        <div class="col-lg-6" id="receipts_format">
                            <label class="text-secondary pr-2 fw-600 pb-1">Address Format for Receipts :</label>
                            <br />
                            <el-switch id="address_format_receipts" style="display: block"
                                v-model="outletDetails.use_short_address_format_for_receipt" active-color="#00448b"
                                inactive-color="#00448b" active-text="Long Form" inactive-text="Short Form"></el-switch>
                        </div>
                    </div>
                    <div class="d-flex pt-3 mt-3 res-settings flex-wrap">
                        <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg" />
                            <div class="pl-5" id="recepit_settings">
                                <label class="text-secondary pr-2 fw-600 pb-1">Receipt Settings :</label>
                                <br />
                                <el-checkbox v-model="outletDetails.is_round_off_required" id="enable_round_off">Enable
                                    Roundoff</el-checkbox>
                                <el-checkbox v-model="outletDetails.prompt_user_to_print" id="print_defalut">Print
                                    Receipt by Default</el-checkbox>
                                <el-checkbox v-model="outletDetails.add_tips" id="add_tips">Add Tips</el-checkbox>
                                <el-checkbox v-model="outletDetails.enable_double_receipts" id="enable_double_receipts">
                                    Enable Double Receipts</el-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4 align-items-center"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" />
                        <div class="col-lg-3">
                            <label class="text-secondary pr-2 fw-600 pb-1">Other Receipt Settings :</label>
                            <br />
                            <el-checkbox v-model="outletDetails.add_wifi_info" id="add_wifi_info">Add Wifi Info
                            </el-checkbox>
                        </div>
                        <div class="col-lg-4 pt-8" style="display: inline-flex;" v-if="outletDetails.add_wifi_info">
                            <label class="text-secondary pr-2 fw-600 pt-1">Username:</label>
                            <input class="form-control wifi_input" type="username"
                                v-model="outletDetails.guest_wifi_info.username" name="username" id="username_wifi" />
                        </div>
                        <div class="col-lg-4 pt-8" style="display: inline-flex;" v-if="outletDetails.add_wifi_info">
                            <label class="text-secondary pr-2 fw-600 pt-1">Password:</label>
                            <input class="form-control wifi_input" type="password"
                                v-model="outletDetails.guest_wifi_info.password" name="password" id="password_wifi" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">TABLE LAYOUT SETTINGS</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group contactGroup">
                                <input class="inputContact" type="text" v-validate="'required|numeric'"
                                    :maxlength="createnooftables" v-model="outletDetails.no_of_tables" name="noOfTables"
                                    id="table_layout_enter" required />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">
                                    Please Enter The Number of Tables
                                    <sup>★</sup>
                                </label>
                                <span class="text-danger fs-13"
                                    v-show="errors.has('noOfTables') && ifSubmitted">{{ errors.first("noOfTables") }}</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    style="margin-bottom: 0px!important;">
                                    {{createnooftables - outletDetails.no_of_tables.length}} / {{createnooftables}}</p>
                            </div>
                            <div class="form-group contactGroup">
                                <input id="table_prefix" class="inputContact" type="text" v-validate="'required'"
                                    name="sequence" :maxlength="createtablenumber" v-model="outletDetails.table_prefix"
                                    required />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">
                                    Please Enter The Table Number Prefix (For Ex. T1, T2 or
                                    FF01)
                                    <sup>★</sup>
                                </label>
                                <span class="text-danger fs-13" v-show="errors.has('sequence') && ifSubmitted">
                                    Table
                                    Number Prefix is Required
                                </span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    style="margin-bottom: 0px!important;">
                                    {{createtablenumber - outletDetails.table_prefix.length}} / {{createtablenumber}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">OPERATION HOURS</h6>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-lg-12">
                            <div class="form-group contactGroup" style="padding-top: 0px;">
                                <label for="inputSattus" class="col-form-label text-secondary fs-14"
                                    style="font-size:14px!important;">
                                    Business Day Start Time
                                    <sup>★</sup>
                                </label>
                                <vue-timepicker drop-direction="down" id="timepicker" class="organizationDate"
                                    v-model="outletDetails.business_day_start_time" @close="businessStartDaySelected" format="hh:mm A" close-on-complete>
                                </vue-timepicker>
                                <span class="text-danger fs-13" v-show="isInvalid">Business Day Start Time is
                                    Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-lg-12">
                            <h6 class="heading-details pb-3">
                                Opening Hours
                                <span class="text-danger fs-13" v-show="noStartEndTime && ifSubmitted"
                                    style="font-weight:500;">(Please Fill Start-Time/End-Time For Open Days)</span>
                            </h6>
                            <div class="contact-item d-flex flex-nowrap row align-items-center multiple-heading py-sm-4 pl-5 pr-3"
                                style="background-color:#005dae !important;">
                                <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">Day</div>
                                <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">Open/Close</div>
                                <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div openingHours" style="white-space:nowrap;">Opening Hours
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div closingHours" style="white-space:nowrap;">Closing Hours
                                </div>
                            </div>
                            <div class="add-member-modal pl-5 pr-3">
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Monday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.monday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.monday.status,'monday')">
                                        </el-switch>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 d-flex flex-nowrap justify-content-center time-pick text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.monday.start" :disabled="!outletDetails.opening_hours.monday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.monday.end" :disabled="!outletDetails.opening_hours.monday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Tuesday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.tuesday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.tuesday.status,'tuesday')">
                                        </el-switch>
                                    </div>
                                    <div
                                        class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.tuesday.start" :disabled="!outletDetails.opening_hours.tuesday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.tuesday.end" :disabled="!outletDetails.opening_hours.tuesday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Wednesday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.wednesday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.wednesday.status,'wednesday')">
                                        </el-switch>
                                    </div>
                                    <div
                                        class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.wednesday.start" :disabled="!outletDetails.opening_hours.wednesday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.wednesday.end" :disabled="!outletDetails.opening_hours.wednesday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Thursday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.thursday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.thursday.status,'thursday')">
                                        </el-switch>
                                    </div>
                                    <div
                                        class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.thursday.start" :disabled="!outletDetails.opening_hours.thursday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.thursday.end" :disabled="!outletDetails.opening_hours.thursday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Friday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.friday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.friday.status,'friday')">
                                        </el-switch>
                                    </div>
                                    <div
                                        class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.friday.start" :disabled="!outletDetails.opening_hours.friday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.friday.end" :disabled="!outletDetails.opening_hours.friday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Saturday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.saturday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.saturday.status,'saturday')">
                                        </el-switch>
                                    </div>
                                    <div class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.saturday.start" :disabled="!outletDetails.opening_hours.saturday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.saturday.end" :disabled="!outletDetails.opening_hours.saturday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Sunday</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                        <el-switch v-model="outletDetails.opening_hours.sunday.status"
                                            @change="clearOpeningHours(outletDetails.opening_hours.sunday.status,'sunday')">
                                        </el-switch>
                                    </div>
                                    <div
                                        class="col-lg-8 col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center flex-nowrap text-center">
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.sunday.start" :disabled="!outletDetails.opening_hours.sunday.status" format="hh:mm A"></vue-timepicker>
                                        <vue-timepicker class="filterName mr-1" v-model="outletDetails.opening_hours.sunday.end" :disabled="!outletDetails.opening_hours.sunday.status" format="hh:mm A"></vue-timepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-lg-12">
                            <h6 class="heading-details pb-3">
                                Outlet Detail Timings
                                <el-tooltip content="This Timing are noted for Generating Report" placement="top">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg" />
                                </el-tooltip>
                            </h6>
                            <div class="contact-item d-flex flex-nowrap row align-items-center multiple-heading py-sm-4 pl-5 pr-3"
                                style="background-color:#005dae !important;">
                                <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">Meals</div>
                                <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div"></div>
                                <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div openingHours">Starting Time
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div closingHours">Ending Time
                                </div>
                            </div>
                            <div class="add-member-modal pl-5 pr-3">
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Breakfast</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                    <div
                                        class="col-lg-8 col-md-8 col-sm-8 d-flex flex-nowrap justify-content-center time-pick text-center">
                                        <div class="w-100">
                                            <vue-timepicker v-validate="'required'" name="starting_time" class="filterName mr-1" v-model="outletDetails.default_timings.breakfast.start_time" @change="defaultTimmingsChangeHnadler($event, 'breakfast_start_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('starting_time')">{{ errors.first("starting_time") }}</span>
                                        </div>
                                        <div class="w-100 ml-2">
                                            <vue-timepicker v-validate="'required'" name="ending_time" class="filterName mr-1" v-model="outletDetails.default_timings.breakfast.end_time" @change="defaultTimmingsChangeHnadler($event, 'breakfast_end_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('ending_time')">{{ errors.first("ending_time") }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Lunch</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                    <div
                                        class="col-lg-8 col-md-8 col-sm-8 d-flex flex-nowrap justify-content-center time-pick text-center">
                                        <div class="w-100">
                                            <vue-timepicker v-validate="'required'" name="starting_time_lunch" class="filterName mr-1" v-model="outletDetails.default_timings.lunch.start_time" @change="defaultTimmingsChangeHnadler($event, 'lunch_start_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('starting_time_lunch')">{{ errors.first("starting_time_lunch") }}</span>
                                        </div>
                                        <div class="w-100 ml-2">
                                            <vue-timepicker v-validate="'required'" name="ending_time_lunch" class="filterName mr-1" v-model="outletDetails.default_timings.lunch.end_time" @change="defaultTimmingsChangeHnadler($event, 'lunch_end_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('ending_time_lunch')">{{ errors.first("ending_time_lunch") }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="contact-item row align-items-center py-sm-4">
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                        Dinner</div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                    <div
                                        class="col-lg-8 col-md-8 col-sm-8 d-flex flex-nowrap justify-content-center time-pick text-center">
                                        <div class="w-100">
                                            <vue-timepicker v-validate="'required'" name="starting_time_dinner" class="filterName mr-1" v-model="outletDetails.default_timings.dinner.start_time" @change="defaultTimmingsChangeHnadler($event, 'dinner_start_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('starting_time_dinner')">{{ errors.first("starting_time_dinner") }}</span>
                                        </div>
                                        <div class="w-100 ml-2">
                                            <vue-timepicker v-validate="'required'" name="ending_time_dinner" class="filterName mr-1" v-model="outletDetails.default_timings.dinner.end_time" @change="defaultTimmingsChangeHnadler($event, 'dinner_end_time')" format="hh:mm A"></vue-timepicker>
                                            <span
                                                class="invalid-feedback-form text-danger mt-0 text-left d-block "
                                                v-show="errors.has('ending_time_dinner')">{{ errors.first("ending_time_dinner") }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">OUTLET CONFIGURATION</h6>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" class="pl-4" />
                        <div class="col-lg-6 pl-5">
                            <label class="text-secondary pr-2 fw-600 pb-1">Order Placement Type :</label>
                            <br />
                            <el-checkbox v-model="outletDetails.enable_fast_checkout">Enable Fast Checkout</el-checkbox>
                        </div>
                    </div>
                    <div class="d-flex pt-3 mt-3 res-settings flex-wrap">
                        <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="pl-5">
                                <label class="text-secondary pr-2 fw-600 pb-1">Include Discount in :</label>
                                <br />
                                <el-checkbox v-model="outletDetails.additional_charge_with_discount">Additional Charges
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertial Cash" />
                            <div class="pl-5">
                                <label class="text-secondary pr-2 fw-600 pb-1">Double Discount :</label>
                                <br />
                                <el-checkbox v-model="outletDetails.double_discount">Allow Double Discount</el-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex pt-3 mt-3 res-settings flex-wrap">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="text-secondary pr-2 fw-600 pb-1">Courses Settings :</label>
                            <br />
                            <div class="col-lg-12 row">
                                <div class="form-group contactGroup" style="width: 100%;margin-top: -3px!important;">
                                    <input name="courses_empty" class="inputContact" type="text"
                                        v-model.number="outletDetails.courses_settings.no_of_courses"
                                        v-validate="'required|min_value:1|max_value:5'" v-on:keypress="isNumber($event)" required />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                        Enter the Number of Courses
                                        <sup>★</sup> (Enter value from 1 to 5)
                                    </label>
                                    <span class="text-danger fs-13" v-show="errors.has('courses_empty')"
                                        style="display:inline;">{{errors.first('courses_empty')}}</span>
                                </div>
                            </div>
                            <div class="row pt-3 pb-2">
                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                        alt="Vertical Cash" />
                                    <div class="pl-2">
                                        <label class="text-secondary pr-2 fw-600 pb-1">Course Management Configuration
                                            :</label>
                                        <br />
                                        <el-checkbox v-model="outletDetails.courses_settings.default_deliver_together">
                                            Default Deliver Together</el-checkbox>
                                        <el-checkbox v-model="outletDetails.courses_settings.enable_courses_firing">
                                            Enable Course Firing</el-checkbox>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                        alt="Vertical Cash" />
                                    <div class="pl-5">
                                        <label class="text-secondary pr-2 fw-600 pb-1">Default Display Status For
                                            :</label>
                                        <br />
                                        <el-checkbox
                                            v-model="outletDetails.courses_settings.default_display_status_for_courses">
                                            Courses</el-checkbox>
                                        <el-checkbox
                                            v-model="outletDetails.courses_settings.default_display_status_for_items">
                                            Items</el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pt-3 mt-3 res-settings flex-wrap">
                        <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="pl-5">
                                <label class="text-secondary pr-2 fw-600 pb-1">Promotion and Entertainment :</label>
                                <br />
                                <el-checkbox v-model="outletDetails.show_promotion_when_value_zero">Show
                                    Promotion/Entertainment when value is Zero</el-checkbox>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertial Cash" />
                            <div class="pl-5">
                                <label class="text-secondary pr-2 fw-600 pb-1">Loyalty :</label>
                                <br />
                                <el-checkbox v-model="outletDetails.show_loyalty_when_value_zero">Show Loyalty when
                                    value is Zero</el-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">VOID SETTINGS</h6>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" class="pl-4" />
                        <div class="col-lg-6 pl-5">
                            <label class="text-secondary pr-2 fw-600 pb-1">Enable Void Closed Orders :</label>
                            <br />
                            <el-switch active-text="Yes" v-model="outletDetails.delete_void_order" inactive-text="No">
                            </el-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">SALES DATA</h6>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                        style="display: -webkit-inline-box;">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                            alt="Vertical Cash" class="pl-4" />
                        <div class="col-lg-6 pl-5">
                            <label class="text-secondary pr-2 fw-600 pb-1">Is Waiter able to see sales data :</label>
                            <br />
                            <el-switch active-text="Yes" v-model="outletDetails.can_waiter_see_sales_data"
                                inactive-text="No"></el-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="heading-details-black">ALERT SETTINGS</h6>
                        </div>
                    </div>
                    <div class="d-flex mt-3 res-settings flex-wrap">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group contactGroup">
                                <label class="fs-14" style="color:#00448b;">Distribution Email List :</label>
                                <vue-tags-input v-model="report_email" name="tags" id="tags"
                                    :tags="outletDetails.report_emails" placeholder="Add Emails"
                                    @tags-changed="newTags => outletDetails.report_emails = newTags" />
                            </div>
                        </div>
                    </div>

                    <div class="row pt-6 text-center" style="margin-top: 20px">
                        <div class="col-lg-12">
                            <div class="list-title" style="font-weight: 500;">
                                <button type="button" class="btn btn-link" :disabled="create_outlet_api_inprogress" @click="$router.go(-1)">Cancel</button>
                                <button class="btn btn-secondary btn-smm" :disabled="create_outlet_api_inprogress" style="text-transform:uppercase!important;"
                                    v-on:click="setupDefaultOutlet()">
                                    Save
                                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="create_outlet_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <select-map modal_name="map-create" @update_address="updateAddress"></select-map>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>
<script>
    import VueTimepicker from 'vue2-timepicker'
    import axios from 'axios';
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import myUpload from 'vue-image-crop-upload'
    import SelectMap from './SelectMap'
    import Utils from './utils/utils'
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import {
        HollowDotsSpinner
    } from 'epic-spinners'
    import { SweetModal } from 'sweet-modal-vue'
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        data() {
            return {
                report_email: '',
                createtaxid: 20,
                createoutletname: 30,
                createwebsite: 45,
                createemail: 230,
                createstreet: 42,
                createstreetnew: 42,
                createcity: 21,
                createtownship: 21,
                createstate: 30,
                createzipcode: 12,
                createstreetshort: 42,
                createcityshort: 21,
                createstateshort: 30,
                createzipcodeshort: 12,
                createnooftables: 10,
                createtablenumber: 15,
                selected: 'Canada',
                noStartEndTime: false,
                isPhoneNumberInvalid: false,
                isInvalidForm: false,
                phoneNumber: '',
                ifSubmitted: false,
                cop_address_model: false,
                branchs: [],
                outletDetails: {
                    report_emails: [],
                    name: '',
                    outlet_type: '',
                    branch_type: 'headquarters',
                    round_off_settings: 'neutral',
                    branch_id: '',
                    website: '',
                    email: '',
                    profile_pic: '',
                    phone_number: '',
                    countrysettings: '',
                    timezone: '',
                    add_wifi_info: false,
                    use_short_address_format_for_receipt: false,
                    use_short_address_format_for_invoice: false,
                    add_tips: true,
                    enable_double_receipts: false,
                    currency: {
                        name: '',
                        symbol: ''
                    },
                    no_of_tables: '',
                    table_prefix: '',

                    tax_rules: '', //<inclusive> or <exclusive>,
                    address: {
                        street: '',
                        street2: '',
                        city: '',
                        township: '',
                        state: '',
                        zipcode: '',
                        country: ''
                    },
                    short_address: {
                        street: '',
                        city: '',
                        state: '',
                        zipcode: '',
                        country: ''
                    },
                    courses_settings: {
                        no_of_courses: '',
                        default_deliver_together: '',
                        enable_courses_firing: '',
                        default_display_status_for_courses: '',
                        default_display_status_for_items: ''
                    },
                    guest_wifi_info: {
                        username: '',
                        password: ''
                    },
                    opening_hours: {
                        monday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        tuesday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        wednesday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        thursday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        friday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        saturday: {
                            status: false,
                            start: '',
                            end: ''
                        },
                        sunday: {
                            status: false,
                            start: '',
                            end: ''
                        }
                    },
                    default_timings: {
                        breakfast: {
                            start_time: '',
                            end_time: '',
                            start_meridian: '',
                            end_meridian: '',
                        },
                        lunch: {
                            start_time: '',
                            end_time: '',
                            start_meridian: '',
                            end_meridian: '',
                        },
                        dinner: {
                            start_time: '',
                            end_time: '',
                            start_meridian: '',
                            end_meridian: '',
                        }
                    },
                    delete_void_order: false,
                    can_waiter_see_sales_data: false,
                    restaurant_type: 'both',
                    prompt_user_to_print: true,
                    is_round_off_required: false,
                    double_discount: false,
                    additional_charge_with_discount: false,
                    tax_id: '',
                    enable_fast_checkout: false,
                    business_day_start_time: '',
                    use_short_address_format_for_receipt: false,
                    receipt_format: 'restaurant',
                    show_promotion_when_value_zero: false,
                    show_loyalty_when_value_zero: false,
                    no_of_decimal_points: 0,
                    is_decimal_points_allowed: false
                },
                showOutletPic: false,
                imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/company-logo.svg', // the datebase64 url of created image
                creating: false,
                isInvalid: false,
                success_msg: '',
                warning_msg: '',
                breakfast_start_time:'',
                lunch_start_time:'',
                dinner_start_time:'',
                breakfast_end_time:'',
                lunch_end_time:'',
                dinner_end_time:'',
                breakfast_start_meridian:'',
                lunch_start_meridian:'',
                dinner_start_meridian:'',
                breakfast_end_meridian:'',
                lunch_end_meridian:'',
                dinner_end_meridian:'',
                isUploadingInProgress: false,
                attachment_file:"",
                check_attachments: false,
                attachments: {},
                outlet_mode: 'cloud',
                create_outlet_api_inprogress: false
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser
            }
        },
        components: {
            'my-upload': myUpload,
            SelectMap,
            VueTimepicker,
            HollowDotsSpinner,
            SweetModal,
            HalfCircleSpinner
        },
        watch: {
            loggedInUser(newValue) {
                if (!(newValue.actions.settings && newValue.actions.settings.view_other_outlets)) {
                    this.$router.push('/')
                }
            }
        },
        methods: {
            businessStartDaySelected() {
                if(this.outletDetails.business_day_start_time) {
                    this.isInvalid = false;
                } else {
                    this.isInvalid = true;
                }
            },
            isNumber: function (evt, index) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            UpdateDecimal() {
                if (this.outletDetails.is_decimal_points_allowed) {
                    this.outletDetails.no_of_decimal_points = 2
                } else {
                    this.outletDetails.no_of_decimal_points = 0
                }

            },
            checkDp() {
                if (this.outletDetails.no_of_decimal_points == '' || this.outletDetails.no_of_decimal_points == null) {
                    this.outletDetails.no_of_decimal_points = 0
                }
                if (this.outletDetails.no_of_decimal_points < 2) {
                    this.outletDetails.no_of_decimal_points = 2
                }
            },
            ModalMap() {
                this.$modal.show('map-create')
            },
            copy_address_long_to_short() {
                if (this.cop_address_model) {
                    this.outletDetails.short_address.city = this.outletDetails.address.city
                    this.outletDetails.short_address.street = this.outletDetails.address.street
                    this.outletDetails.short_address.country = this.outletDetails.address.country
                    this.outletDetails.short_address.state = this.outletDetails.address.state
                    this.outletDetails.short_address.zipcode = this.outletDetails.address.zipcode
                } else {
                    this.outletDetails.short_address.city = ''
                    this.outletDetails.short_address.street = ''
                    this.outletDetails.short_address.country = ''
                    this.outletDetails.short_address.state = ''
                    this.outletDetails.short_address.zipcode = ''
                }
            },
            updateAddress(address) {
                this.outletDetails.address.city = address.city
                this.outletDetails.address.street = address.street
                this.outletDetails.address.street2 = address.street2
                this.outletDetails.address.country = address.country
                this.outletDetails.address.state = address.state
                this.outletDetails.address.zipcode = address.zipcode
            },
            uploadAttachment(newFile) {
                try {
                    var file = newFile;
                    if (file) {
                        const CancelToken = axios.CancelToken;
                        const source = CancelToken.source();
                        let attachment = {
                            // id: uuid(),
                            name: file.name,
                            size: JSON.stringify(file.size),
                            source: source,
                            progress: 0,
                            created_time: new Date(),
                            error: ""
                        }
                        this.attachments = attachment;
                        this.getResumableURL(file, this.attachments);
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            getResumableURL(file, attachment) {
                try {
                    this.isUploadingInProgress = true;
                    attachment.uploading = true;
                    let data = {
                        file: attachment.name
                    }
                    data = new FormData()
                    data.append('file',file)
                    this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                        onUploadProgress: function (progressEvent) {
                            let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                            if (progress < 100) {
                                attachment.progress = progress;
                            }
                        }.bind(this)
                    })
                    .then(response => {
                        let resumable_url = response.data.url;
                        attachment.url = resumable_url;
                        attachment.progress = 100;
                        attachment.uploading = false;
                        this.isUploadingInProgress = false;
                        delete attachment['source'];

                        this.imgDataUrl = resumable_url
                        this.outletDetails.profile_pic = resumable_url

                    })
                    .catch(error => {
                        console.log(error.data.reason)
                    })
                } catch (error) {
                    console.log(error);
                }
            },
            blobToFile(theBlob, fileName){
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                const myFile = new File([theBlob], 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png', {
                    type: theBlob.type,
                });
                return myFile;
            },
            async onOutletPicCrop(imgUploaded, field) {
                const base64 = await fetch(imgUploaded);
                const blob = await base64.blob();
                let fill = this.blobToFile(blob, 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png');
                this.uploadAttachment(fill)
            },
            defaultTimmingsChangeHnadler(event_data, type) {
                if(type == 'breakfast_start_time') {
                    this.breakfast_start_time = event_data.displayTime.split(' ')[0] || '';
                    this.breakfast_start_meridian = event_data.displayTime.split(' ')[1] || '';
                } else if(type == 'breakfast_end_time') {
                    this.breakfast_end_time = event_data.displayTime.split(' ')[0] || '';
                    this.breakfast_end_meridian = event_data.displayTime.split(' ')[1] || '';
                } else if(type == 'lunch_start_time') {
                    this.lunch_start_time = event_data.displayTime.split(' ')[0] || '';
                    this.lunch_start_meridian = event_data.displayTime.split(' ')[1] || '';
                } else if(type == 'lunch_end_time') {
                    this.lunch_end_time = event_data.displayTime.split(' ')[0] || '';
                    this.lunch_end_meridian = event_data.displayTime.split(' ')[1] || '';
                } else if(type == 'dinner_start_time') {
                    this.dinner_start_time = event_data.displayTime.split(' ')[0] || '';
                    this.dinner_start_meridian = event_data.displayTime.split(' ')[1] || '';
                } else if(type == 'dinner_end_time') {
                    this.dinner_end_time = event_data.displayTime.split(' ')[0] || '';
                    this.dinner_end_meridian = event_data.displayTime.split(' ')[1] || '';
                }
            },
            setupDefaultOutlet() {
                try {
                    if (typeof this.outletDetails.phone_number == 'object') {
                        this.outletDetails.phone_number = this.outletDetails.phone_number['e164']
                    }
                    if (this.outletDetails.business_day_start_time == '') {
                        this.isInvalid = true
                    } else {
                        this.isInvalid = false
                    }
                    this.outletDetails.report_emails.forEach((element, index, theArray) => {
                        if (typeof element === 'object') {
                            theArray[index] = element.text
                        }
                    })
                    if(this.outlet_mode == 'cloud') {
                        this.outletDetails['is_outlet_hybrid'] = true
                    } else if(this.outlet_mode == 'hybrid') {
                        this.outletDetails['is_outlet_hybrid'] = false
                    }
                    if (typeof this.outletDetails.business_day_start_time == 'object') {
                        this.outletDetails.business_day_start_time = this.outletDetails.business_day_start_time['HH'] +
                            ':' + this.outletDetails.business_day_start_time['mm']
                    } else {
                        this.outletDetails.business_day_start_time = this.outletDetails.business_day_start_time
                    }
                    this.ifSubmitted = true
                    this.creating = true
                    this.checkIfNoStartEndTime()
                    // if (this.outletDetails.default_timings.breakfast.start_time != null && this.outletDetails.default_timings.breakfast.end_time != null && this.outletDetails.default_timings.dinner.start_time != null && this.outletDetails.default_timings.dinner.end_time != null && this.outletDetails.default_timings.lunch.start_time != null && this.outletDetails.default_timings.lunch.end_time != null) {
                        // this.outletDetails.default_timings.breakfast['start_meridian'] = this.outletDetails.default_timings.breakfast.start_time.slice(6,8)
                        // this.outletDetails.default_timings.breakfast['end_meridian'] = this.outletDetails.default_timings.breakfast.end_time.slice(6,8)
                        // this.outletDetails.default_timings.lunch['start_meridian'] = this.outletDetails.default_timings.lunch.start_time.slice(6,8)
                        // this.outletDetails.default_timings.lunch['end_meridian'] = this.outletDetails.default_timings.lunch.end_time.slice(6,8)
                        // this.outletDetails.default_timings.dinner['start_meridian'] = this.outletDetails.default_timings.dinner.start_time.slice(6,8)
                        // this.outletDetails.default_timings.dinner['end_meridian'] = this.outletDetails.default_timings.dinner.end_time.slice(6,8)
                        // this.outletDetails.default_timings.breakfast.start_time = this.outletDetails.default_timings.breakfast.start_time.slice(0,5)
                        // this.outletDetails.default_timings.breakfast.end_time = this.outletDetails.default_timings.breakfast.end_time.slice(0,5)
                        // this.outletDetails.default_timings.lunch.start_time = this.outletDetails.default_timings.lunch.start_time.slice(0,5)
                        // this.outletDetails.default_timings.lunch.end_time = this.outletDetails.default_timings.lunch.end_time.slice(0,5)
                        // this.outletDetails.default_timings.dinner.start_time = this.outletDetails.default_timings.dinner.start_time.slice(0,5)
                        // this.outletDetails.default_timings.dinner.end_time = this.outletDetails.default_timings.dinner.end_time.slice(0,5)
                    // }
                    if(this.breakfast_start_time != '' && this.breakfast_end_time != '' && this.lunch_start_time != '' && this.lunch_end_time != '' && this.dinner_start_time != '' && this.dinner_end_time != '' && this.breakfast_start_meridian != '' && this.breakfast_end_meridian != '' && this.lunch_start_meridian != '' && this.lunch_end_meridian != '' && this.dinner_start_meridian != '' && this.dinner_end_meridian != '') {
                        this.outletDetails.default_timings.breakfast.start_meridian = this.breakfast_start_meridian
                        this.outletDetails.default_timings.breakfast.end_meridian = this.breakfast_end_meridian
                        this.outletDetails.default_timings.lunch.start_meridian = this.lunch_start_meridian
                        this.outletDetails.default_timings.lunch.end_meridian = this.lunch_end_meridian
                        this.outletDetails.default_timings.dinner.start_meridian = this.dinner_start_meridian
                        this.outletDetails.default_timings.dinner.end_meridian = this.dinner_end_meridian
                        this.outletDetails.default_timings.breakfast.start_time = this.breakfast_start_time
                        this.outletDetails.default_timings.breakfast.end_time = this.breakfast_end_time
                        this.outletDetails.default_timings.lunch.start_time = this.lunch_start_time
                        this.outletDetails.default_timings.lunch.end_time = this.lunch_end_time
                        this.outletDetails.default_timings.dinner.start_time = this.dinner_start_time
                        this.outletDetails.default_timings.dinner.end_time = this.dinner_end_time
                    }
                    this.$validator.validateAll().then(result => {
                        this.isInvalidForm = !result
                        if (result && !this.noStartEndTime && !this.isPhoneNumberInvalid && !this.isInvalid) {
                            this.$swal.fire({
                                title: 'Creating Outlet Please Wait....',
                                timerProgressBar: true,
                                onBeforeOpen: () => {
                                    this.$swal.showLoading()
                                }
                            }).then(result => {
                                if (result.dismiss === this.$swal.DismissReason.timer) {

                                }
                            })
                            this.create_outlet_api_inprogress = true;
                            this.$http.post('/outlet/add', this.outletDetails).then(res => {
                                if (res.data.status_id === 1) {
                                    this.$swal({
                                        title: 'Outlet Created Successfully',
                                        text: '',
                                        icon: 'success',
                                        type: 'success'
                                    })
                                    EventBus.$emit('updateOutlets', true)
                                    this.$router.push('/outletmanage')
                                    this.create_outlet_api_inprogress = false;
                                } else {
                                    this.$swal({
                                        title: 'Cannot create',
                                        text: res.data.reason || '',
                                        icon: 'warning',
                                        buttons: ['ok'],
                                        type: 'warning'
                                    })
                                    this.creating = false
                                    this.create_outlet_api_inprogress = false;
                                }
                            })
                        } else {
                            this.$swal({
                                title: 'Please Fill all Required Fields',
                                text: '',
                                icon: 'warning',
                                buttons: ['ok'],
                                type: 'warning'
                            })
                            this.creating = false
                            this.create_outlet_api_inprogress = false;
                        }
                    })
                } catch (e) {
                    console.log(e)
                    this.warning_msg = e;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000);
                }
            },
            clearOpeningHours(status, week) {
                if (!status) {
                    this.outletDetails.opening_hours[week].start = ''
                    this.outletDetails.opening_hours[week].end = ''
                }
                this.checkIfNoStartEndTime()
            },
            checkIfNoStartEndTime() {
                for (let day in this.outletDetails.opening_hours) {
                    if (this.outletDetails.opening_hours[day].status && (!this.outletDetails.opening_hours[day].start | !this.outletDetails.opening_hours[day].end)) {
                        this.noStartEndTime = true
                        break
                    }
                    else{
                        if(this.outletDetails.opening_hours[day].status){
                            this.outletDetails.opening_hours[day]['start_meridian'] = this.outletDetails.opening_hours[day].start.slice(6,8)
                            this.outletDetails.opening_hours[day]['end_meridian'] = this.outletDetails.opening_hours[day].end.slice(6,8)
                            this.outletDetails.opening_hours[day].start = this.outletDetails.opening_hours[day].start.slice(0,5)
                            this.outletDetails.opening_hours[day].end = this.outletDetails.opening_hours[day].end.slice(0,5)
                        }
                    }
                    this.noStartEndTime = false
                }
            },
            assignCurrencySymbol() {
                this.outletDetails.currency.symbol = Utils.currency[this.outletDetails.currency.name]
            },
            onPhoneNumberInput({
                number,
                isValid,
                country
            }) {
                this.isPhoneNumberInvalid = !isValid
                if (isValid) {
                    this.outletDetails.phone_number = number
                }
                if ($('.vue-tel-input')[0].lastChild.value.match('.*[a-z].*')) {
                    this.isPhoneNumberInvalid = true
                }
            },
            async getBranchs() {
                try {
                    let res = await this.$http.get('/crm/branches')
                    if (res.data.status_id == 1) {
                        this.branchs = res.data.result
                    }
                } catch (reason) {

                }
            }
        },
        mounted() {
            $(document).bind('cut copy', function (e) {
                $("#preventkeys").fadeIn("slow");
                setTimeout(function () {
                    $("#preventkeys").fadeOut("slow");
                }, 2000);
                return false;
            });
            $(document).on("keydown keyup", "#preventkeys", function (event) {
                if (event.which == 38 || event.which == 40) {
                    event.preventDefault();
                }
            });
            this.getBranchs();
            EventBus.$emit('settingsLoad', true)
        },
        created() {
            const dict = {
                custom: {
                    outletName: {
                        required: () => 'Outlet Name is Required',
                        regex: () => 'Please Enter Valid Outlet Name'
                    },
                    OutletType: {
                        required: () => 'Outlet Type is Required'
                    },
                    outletWebsite: {
                        required: () => 'Outlet Website is Required',
                        regex: () => 'Outlet Website Should be URL'
                    },
                    email: {
                        required: () => 'Email is Required',
                        regex: () => 'Please Enter Valid Email Address'
                    },
                    currency: {
                        required: () => 'Currency is Required'
                    },
                    street: {
                        required: () => 'Street is Required'
                    },
                    city: {
                        required: () => 'City is Required',
                        regex: () => 'Please Enter Valid City Name'
                    },
                    cityshort: {
                        required: () => 'City is Required',
                        regex: () => 'Please Enter Valid City Name'
                    },
                    state: {
                        required: () => 'State is Required',
                        regex: () => 'Please Enter Valid State Name'
                    },
                    stateshort: {
                        required: () => 'State is Required',
                        regex: () => 'Please Enter Valid State Name'
                    },
                    zipcode: {
                        required: () => 'Zipcode is Required',
                        numeric: () => 'Zipcode should be Numeric'
                    },
                    zipcodeshort: {
                        required: () => 'Zipcode is Required',
                        numeric: () => 'Zipcode should be Numeric'
                    },
                    country: {
                        required: () => 'Country is Required'
                    },
                    noOfTables: {
                        required: () => 'No of Tables is Required',
                        numeric: () => 'Enter a Valid Number'
                    },
                    sequence: {
                        required: () => 'Table Prefix is Required'
                    },
                    taxId: {
                        required: () => 'Tax Id is Required'
                    },
                    timezone: {
                        required: () => 'Timezone is Required'
                    },
                    courses_empty: {
                        required: () => 'Number of Courses is Required',
                        min_value: () => 'Number of Courses should not be less than 1.',
                        max_value: () => 'Number of Courses should not be greater than 5.',
                    },
                    starting_time: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time: {
                        required: () => 'End Time is Required',
                    },
                    starting_time_lunch: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time_lunch: {
                        required: () => 'End Time is Required',
                    },
                    starting_time_dinner: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time_dinner: {
                        required: () => 'End Time is Required',
                    }
                }
            }
            this.$validator.localize('en', dict)
        }
    }
</script>
<style>
    .heading-details {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: 1px;
        text-align: left;
        /* color: #00448b; */
        color: #00448b;
        padding-top: 15px;
    }

    .outlet-new-design {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
        width: 100%;
        margin: 10px;
        padding: 20px;
        border-radius: 7.5px;
        padding-left: 200px;
        padding-right: 200px;
    }

    .create-outlet .res-settings {
        box-shadow: 0 1px 5px 0 rgba(187, 132, 132, 0.16) !important;
        background-color: #f6f9ff !important;
        margin-bottom: 16px;
        padding-bottom: 10px;
        margin-left: 1px;
        padding-left: 0;
        border-radius: 10px;
    }

    .create-outlet .vue-tags-input {
        background-color: transparent !important;
    }

    .create-outlet .new-tag-input-wrapper input {
        background-color: transparent !important;
    }

    .group-setup {
        position: relative;
        margin-bottom: 20px;
    }

    .select-map-span {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        margin-left: 10px;
    }

    .heading-details-black {
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        color: #303031;
        padding-top: 20px;
        margin-bottom: 4px;
    }

    .setting-div span {
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303031;
    }

    .form-control.wifi_input {
        display: block !important;
        width: 100% !important;
        height: 32px !important;
        padding: 7px 7px !important;
        font-size: 13px !important;
        line-height: 1.5384616 !important;
        color: #303031 !important;
        border: 1px solid #a8adb4 !important;
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075) !important;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075) !important;
        -webkit-transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s !important;
        -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
    }

    .create-heading {
        text-align: center;
        /* color: #00448b; */
        color: #00448b;
        font-weight: 600;
        padding-top: 10px;
        font-size: 16px;
    }

    input:-webkit-autofill {
        background: transparent !important;
    }

    .create-outlet .el-input.is-disabled .el-input__inner {
        background-color: transparent !important;
    }

    @media (max-width: 576px) {
        .textCenter {
            text-align: center !important;
        }

        .alignText {
            margin-left: 6px !important;
        }
    }

    @media (max-width: 1040px) {
        .outlet-new-design {
            padding-left: 20px;
            padding-right: 20px;
        }

        .openingHours {
            padding-left: 9px !important;
        }

        .closingHours {
            padding-left: 5rem !important;
        }
    }

    @media (max-width: 766px) {
        .timezonelocale {
            margin-top: 25px;
        }
    }

    @media (max-width: 810px) {
        .openingHours {
            padding-left: 5rem !important;
        }

        .closingHours {
            padding-left: 5rem !important;
        }
    }

    .openingHours {
        padding-left: 11rem;
    }

    .closingHours {
        padding-left: 7rem;
    }
    button.bs-placeholder {
        font-style: italic !important;
        color: #0006 !important
    }
    option:first-child{
        color: #0006 !important
    }
    select[title] option.optionSelected{
        color: #0006 !important;
    }
    label.vfl-label::after {
        content: "*" ;
        color: #F44336 ;
    }
    sup {
        color: #F44336;
        top: -0.8em !important;
        font-size: 60%;
        padding-left: 0.6px !important;
    }
    .inputContact ~ label > sup, .inputContact ~ label > sup,
    input.digi-form ~ label.digi-label > sup, input.digi-form ~ label.digi-label > sup {
        color: #b4b4b4 !important;
    }
    .inputContact:focus ~ label > sup, .inputContact:valid ~ label > sup,
    input.digi-form:focus ~ label.digi-label > sup, input.digi-form:valid ~ label.digi-label > sup {
        color: #F44336 !important;
    }
</style>