<template>
    <div class="page-layout" id="takeorderdiv" style="background: #f5f6f9;">
        <div v-if="itemspageShow">
            <div class="page-headerr text-auto pt-1 Feb" ref="pageHeader" style="right: 15px;left: 15px;">
                <!-- <div class="header-content d-flex flex-column justify-content-between"> -->
                    <div class="row flex-wrap">
                        <!-- <div class="col-lg-12 col-md-12 col-sm-12 d-flex no-gutters flex-wrap mb-2"> -->
                            <div class="col-lg-8 col-md-12 col-sm-12 border-right-responsive">
                                <div id="selectinf_course" v-if="order.type == 'Dine In'">
                                    <span class="font-weight-bold ml-2 mr-3 d-flex align-items-center whiteSpace mt-2 pl-3">Courses:</span>
                                    <slick id="round_selecting_course"
                                    ref="slick"
                                    :options="slickOptionsCourses"
                                    @afterChange="handleAfterChange"
                                    @beforeChange="handleBeforeChange"
                                    @breakpoint="handleBreakpoint"
                                    @destroy="handleDestroy"
                                    @edge="handleEdge"
                                    @init="handleInit"
                                    @reInit="handleReInit"
                                    @setPosition="handleSetPosition"
                                    @swipe="handleSwipe"
                                    @lazyLoaded="handleLazyLoaded"
                                    @lazyLoadError="handleLazeLoadError" class="course-slick">
                                    <div v-for="(course,cindex) in numberOfCourses" :key="cindex" :class="{'present-course':course.areItemsAdded ,'items-course':selectedCourse == course.course}" class="course-padding">
                                        <div @click="selectCourseforProduct(course.course)" style="width:100%;">{{course.course}}</div>
                                    </div>
                                    </slick>
                                </div>
                                <div class="header-top flex-wrap d-flex flex-column flex-sm-row align-items-center col-md-12" style="padding-right: 0px!important;">
                                    <div class="logo row align-items-center no-gutters mb-8 vselectcategory col-md-2" v-if="isPopUp">
                                        <label class="label-header">Select Vendor</label>
                                        <v-select v-model="selectedVendor" @search="getSearchVendor" :options="vendors" label="name" class="vselect-category mt-2" id="select_vendor_take"></v-select>
                                    </div>
                                    <div class="toolbar row no-gutters align-items-center vselectcategory header-takeorder pt-3 pb-3" :class="{'col-md-3': isPopUp, 'col-md-12': !isPopUp}">
                                        <label class="label-header">Find Product by Name</label>
                                        <div class="d-flex align-items-center">
                                            <div class="search md-elevation-1 row no-gutters align-items-center text-auto mt-2 w-100">
                                                <i class="col-auto icon-magnify s-6 mx-4"></i>
                                                <input class="col" type="text" placeholder="Search Product" v-model="searchdata"  id="search_product"/>
                                                <i class="icon icon-close-circle pr-7" style="padding-top: 1px;!important;font-size:20px !important;cursor:pointer !important;" v-if="searchdata.length" @click="searchdata=''"></i>
                                            </div>
                                            <span class="mt-2 ml-6 item-summary pointer" @click="itemSummary()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/saved.svg" style="width:16px;">
                                                <span class="ml-1">Item Summary</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="row justify-content-between no-gutters align-items-center pt-5 pb-2" v-if="order.type=='Dine In' && !showselectedTable" style="border-bottom: 1px solid #00448b">
                                    <div class="">
                                        <span class="span-order pl-3">No. of Guest :</span>
                                        <div class="form-check form-check-inline" style="padding-left:5px !important;">
                                            <span>
                                                <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" @click="decrementPax()" style="width: 20px;height: 25px!important;"></span>
                                            <label class="form-check-label" style="padding-left: 5px !important;">
                                                <input onkeypress='return event.charCode >= 48 && event.charCode <= 57' class="no-for-pax" style="padding-left: 5px;" type="number" min="1" v-model="noOfPax" name="pax" v-validate="'required|numeric|min_value:1'" max="1000" id="nOOfPAX"  />
                                            </label>
                                            <span class="pl-1"><img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" @click="incrementPax()" style="width: 20px;height: 25px!important;"></span>
                                        </div>
                                    </div>
                                    <button class="btn btn-smm btn-secondary mr-2" @click="applyPax()">Apply</button>
                                </div>
                                <div v-if="order.type !='Dine In' && !showselectedTable" class="pr-3 pl-3 pb-1 pt-3 d-flex justify-content-between" style="border-bottom:1px solid #00448b !important;">
                                    <span class="font-weight-bold">Order Type :
                                        <span class="text-secondary">{{order.type}}</span>
                                    </span>
                                    <span class="font-weight-bold"> Order ID :
                                        <span class="text-secondary">{{showOrderId || '-'}}</span>
                                    </span>
                                </div>
                                <div class="d-flex flex-nowrap pt-3 pb-1" style="border-bottom: 1px solid #00448b" :class="{'pt-5':order.type !='Dine In'}">
                                    <div class="col-md-8 pl-3" :class="{'col-md-12':!selectTable}">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" style="height: 12px;margin-bottom:3px;" class="pr-2" />
                                        <span v-if="selectedCustomer && selectedCustomer.first_name" @click="searchCustomer=true" class="span-add-customer">{{selectedCustomer.first_name + ' ' + selectedCustomer.last_name}}</span>
                                        <span class="pull-right" @click="searchCustomer=true">
                                            <el-tooltip class="item" effect="dark" content="Select Customer" placement="top">
                                                <button type="button" class="btn btn-icon actionicons cust-del" style="padding: 0rem 0rem;" id="edit_customer_take">
                                                <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-tooltip>
                                        </span>
                                    </div>
                                    <div class="col-md-4 pl-3" style="border-left: 2px solid #00448b">
                                        <div v-if="!selectTable"></div>
                                        <div v-if="selectTable">
                                            <span class="span-add-customer" v-if="order.tables && order.tables.length != 0 && order.tables[0].name">
                                                <span @click="TableSearch()">{{ order.tables[0]['name'] }}</span>
                                            </span>
                                            <span class="span-add-customer" v-else-if="order.tables && order.tables[0] &&  !order.tables[0]['name']">
                                                <span @click="TableSearch()">{{ order.tables[0]}}</span>
                                            </span>
                                            <span v-else class="span-add-customer" @click="TableSearch()">
                                            Select
                                            Table
                                            </span>
                                            <span v-if="selectTable" class="pull-right">
                                                <el-tooltip class="item" effect="dark" content="Change Table" placement="top">
                                                    <button type="button" class="btn btn-icon actionicons cust-del" style="padding: 0rem 0rem;" @click="TableSearch()" id="table_search">
                                                    <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex pt-3 justify-content-between">
                                    <span class="pl-3 d-flex">
                                        <div class="pt-3">
                                            <a class="notification-cart">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/shopping-cart.svg" style="height: 12px;margin-bottom:3px;" class="pr-1" />
                                                <span class="badge-for-cart">{{order.itemCount}}</span>
                                            </a>
                                            <span class="total-take-order" v-if="order.itemCount">
                                                Total:
                                                <span class="total-amount">{{order.grand_total_with_credit_card_charges}}</span>
                                            </span>
                                            <span class="total-take-order whiteSpace" v-else>Cart Empty</span>
                                        </div>
                                        <div v-show="order.itemCount" style="white-space: nowrap !important;" class="pr-2">
                                            <span @click="fastPay" v-if="!editOrder && order.hold_order_before_placing == false" class="pointer">
                                                <el-tooltip class="item" effect="dark" content="Fast Pay" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-pay.svg" style="height: 34px !important;width: 34px !important;margin-right:5px !important;margin-left:5px !important;" id="fast_pay_click">
                                                </el-tooltip>
                                            </span>
                                            <!-- <span @click="SaveOrder">
                                                <el-tooltip class="item" effect="dark" content="Save Order" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/PlaceOrder.svg" style="height: 30px !important;width: 30px !important" id="placeolder_click">
                                                </el-tooltip>
                                            </span>  -->
                                            <span v-if="!editOrder">
                                                <span @click="fastCheckout" v-if="fastcheckoutEnable" class="pointer">
                                                    <el-tooltip class="item" effect="dark" content="Fast Checkout" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/FastCheckout.svg" style="height: 45px !important;width: 45px !important" id="fastcheckout">
                                                    </el-tooltip>
                                                </span>
                                            </span>
                                            <span v-else>
                                                <span @click="updateFastCheckout" v-if="fastcheckoutEnable" class="pointer">
                                                    <el-tooltip class="item" effect="dark" content="Fast Checkout" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/FastCheckout.svg" style="height: 45px !important;width: 45px !important" id="update_fastcheckout">
                                                    </el-tooltip>
                                                </span>
                                            </span>
                                        </div>
                                    </span>
                                    <button class="btn btn-checkout mt-1 mr-2" @click="showCheckout" id="chechkout_button">
                                    Checkout
                                    <i class="icon-arrow-right"></i>
                                    </button>
                                </div>
                                <div class="selectCustomer" style="height: auto;" v-show="searchCustomer">
                                    <div @click="searchCustomer=false" class="d-flex pull-right pointer" id="click_customer_edit">
                                        <i class="icon icon-close lightBlue"></i>
                                    </div>
                                    <div class="p-4">
                                        <!-- <v-select v-model="selectedCustomer" :close-on-select="true" class="user-select add-customer-order" label="first_name" @search="getSearchCustomer" @search:blur="getSearchCustomer" placeholder="Search By Name, Number, Email" :options="customers" @input="updateOrderCustomer" id="search_click_customer">
                                            <span slot="no-options">
                                            No Matches Found. Please Create New Account
                                            <span class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="showCreateCustomer()">Here</span>
                                            </span>
                                            <template v-slot:option="option">
                                                {{ option.first_name + ' ' + option.last_name }}
                                            </template>
                                        </v-select> -->

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <span class="text-secondary font-600 fs-14" style="position: relative;top: 8px;">
                                                Select Customer
                                                <sup>★</sup>
                                            </span>
                                            <!-- <v-select
                                                v-model="to_unit"
                                                @input="updateAbbreviation"
                                                :options="measurements"
                                                :filterable="true"
                                                @search="loadMeasurements"
                                                data-vv-as="UM"
                                                name="to_unit"
                                                v-validate="'required'"
                                                label="name"
                                                placeholder="Select Unit of Measurement (UM)"
                                                class="recipe-select max-select measurevselect clearNone"
                                            />-->
                                            <multiselect
                                                data-vv-as="Customer"
                                                name="customer"
                                                v-validate="'required'"
                                                v-model="selectedCustomer"
                                                class="digi-multiselect"
                                                id="ajax"
                                                label="first_name"
                                                placeholder="Select Customer"
                                                :options="customersLL"
                                                openDirection="bottom"
                                                open-direction="bottom"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="loadCustomer"
                                                :internal-search="false"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :options-limit="40"
                                                :max-height="100"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                @search-change="loadCustomersSearch"
                                            >
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.name }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfCustomerList" />
                                                </template>
                                                <span slot="noResult" class="font-weight-bold text-center d-block">
                                                    No Results Found.
                                                </span>
                                            </multiselect>
                                            <span
                                                class="invalid-feedback-form text-danger fs-13"
                                                v-show="errors.has('customer')"
                                            >{{ errors.first('customer')}}</span>
                                            <span class="d-block pointer text-secondary mt-2" style="width: max-content;" @click="showCreateCustomer">
                                            + Add New Customer
                                        </span>
                                        </div>


                                        <!-- <multiselect
                                            data-vv-as="Customer" name="customer" v-validate="'required'" v-model="selectedCustomer" class="digi-multiselect" id="ajax" label="first_name" placeholder="Select Customer" :options="customersLL" :multiple="false" :searchable="true" :loading="loadCustomer" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="40" open-direction="bottom" :max-height="100"
                                                  :show-no-results="true" :hide-selected="false" @search-change="loadCustomersSearch"
                                        >   
                                            <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfCustomerList" />
                                            </template>
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">Customers Not Found</div>
                                            </template>
                                            <template slot="noResult">
												<div class="text-secondary text-center fw-600">No Results Found
													</div>
											</template>
                                            
                                        </multiselect>
                                        <span class="invalid-feedback-form text-danger fs-13"
                                            v-show="errors.has('customer')">{{ errors.first('customer')}}</span>
                                        <span class="d-block pointer text-secondary mt-2" style="width: max-content;" @click="showCreateCustomer">
                                            + Add New Customer
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>
                <!-- </div> -->
            </div>
            <!-- <div class="description p-5" style="padding-top: 140px !important;background: #f5f6f9 !important;"> -->
            <div class="description px-5 pb-5 padding-top-responsiveness" style="background: #f5f6f9 !important;">
                <div class="page-content-wrapper checkoutTable" style="width:100%;background: #f5f6f9 !important;">
                    <div style="display: flex;" class="skeleton-div" v-if="ajaxCallInProgress">
                        <div class="skeleton-loader">
                            <img src="https://cdn.digicollect.com/cdn/posv2/skeleton_order.gif" />
                        </div>
                        <div class="skeleton-loader">
                            <img src="https://cdn.digicollect.com/cdn/posv2/skeleton_order.gif" />
                        </div>
                        <div class="skeleton-loader">
                            <img src="https://cdn.digicollect.com/cdn/posv2/skeleton_order.gif" />
                        </div>
                        <div class="skeleton-loader">
                            <img src="https://cdn.digicollect.com/cdn/posv2/skeleton_order.gif" />
                        </div>
                        <div class="skeleton-loader lastskeleton">
                            <img src="https://cdn.digicollect.com/cdn/posv2/skeleton_order.gif" alt="laoder" />
                        </div>
                    </div>
                    <div class="digi-table-loader d-flex justify-content-center mt-10" v-if="loadCategories" style="margin: 0 auto">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="page-content  marginCategory" v-else style="background-color: #f5f6f9 !important;">
                        <span class="font-weight-bold mt-12 d-flex text-secondary fs-13">Categories</span>
                        <hr class="ml-20 categoryLine">
                        <slick id="category_list_takeorder"
                            ref="slick"
                            :options="slickOptions"
                            @afterChange="handleAfterChange"
                            @beforeChange="handleBeforeChange"
                            @breakpoint="handleBreakpoint"
                            @destroy="handleDestroy"
                            @edge="handleEdge"
                            @init="handleInit"
                            @reInit="handleReInit"
                            @setPosition="handleSetPosition"
                            @swipe="handleSwipe"
                            @lazyLoaded="handleLazyLoaded"
                            @lazyLoadError="handleLazeLoadError">
                            <div v-for="category in categories" :key="category._id" >
                            <a class="pointer text-decoration outline"  @click="loadProductListing({category_id:category._id})">
                                <span class="imageOrders" :class="{'selectedBackground':category._id.includes(category_id)}">
                                <avatar
                                    class="product-avatar"
                                    v-if="category.image_url == ''"
                                    :username="category.name.replace(/ .*/,'')"
                                    :src="category.image_url"
                                    :size="50"
                                    :rounded="false"
                                    color="#f5a623"
                                    background-color="transparent"
                                    ></avatar>
                                <img :src="category.image_url" v-else style="width: 50px !important;height: 50px !important;text-align: center;font-weight: 500;line-height: 50px;border-radius: none !important;">
                                </span>
                                <span class="text-capitalize fs-14 text-black lable-slide-take" :title="category.name" lang="de">{{category.name}}</span>
                            </a>
                            </div>
                        </slick>
                    </div>
                    <div v-if="sub_categories.length > 1" class="page-content sub-category" :class="{'borderSolid':category_id != undefined}" style="background-color: #f5f6f9 !important;" v-show="category_id != undefined || ''">
                        <span class="font-weight-bold mt-6 d-flex text-secondary fs-13" v-if="category_id != undefined">Sub Categories</span>
                        <hr class="ml-25 categoryLine" v-if="category_id != undefined">
                        <slick id="sub_category_list_takeorder" style="padding-left: 20px;"
                            ref="sub_category"
                            :options="slickOptions"
                            @afterChange="handleAfterChange"
                            @beforeChange="handleBeforeChange"
                            @breakpoint="handleBreakpoint"
                            @destroy="handleDestroy"
                            @edge="handleEdge"
                            @init="handleInit"
                            @reInit="handleReInit"
                            @setPosition="handleSetPosition"
                            @swipe="handleSwipe"
                            @lazyLoaded="handleLazyLoaded"
                            @lazyLoadError="handleLazeLoadError">
                            <div v-for="(sub_category) in sub_categories" :key="sub_category._id" >
                            <a class="pointernew text-decoration outline pl-3" @click="loadProductListing({sub_category_id:sub_category._id,category_id:category_id, searchdata: searchdata})">
                                <span class="imageOrders" :class="{'selectedBackground':sub_category._id.includes(sub_category_id)}">
                                <avatar
                                    class="product-avatar"
                                    v-if="sub_category.image_url == ''"
                                    :username="sub_category.name.replace(/ .*/,'')"
                                    :src="sub_category.image_url"
                                    :size="50"
                                    :rounded="false"
                                    color="#f5a623"
                                    background-color="transparent">
                                </avatar>
                                <img :src="sub_category.image_url" class="" v-else style="width: 50px !important;height: 50px !important;text-align: center;font-weight: 500;line-height: 50px;border-radius: none !important;">
                                </span>
                                <span class="text-capitalize fs-14 text-black lable-slide-take" :title="sub_category.name" lang="de">{{sub_category.name}}</span>
                            </a>
                            </div>
                        </slick>
                        <span class="font-weight-bold mt-6 mb-6 d-block text-center text-secondary" v-if="sub_categories.length == 0 && category_id != undefined && loading == false">No Sub Categories Found</span>
                    </div>
                    <div v-if="type == 'Product Types' && product_types.length>0 || product_type_id != undefined" class="page-content" :class="{'borderSolid':product_types.length>0}" style="background-color: #f5f6f9 !important;">
                        <span class="font-weight-bold mt-6 d-flex text-secondary fs-13">Product Type</span>
                        <hr class="ml-23 categoryLine">
                        <slick id="product_type_list_takeorder"
                            ref="product_types"
                            :options="slickOptions"
                            @afterChange="handleAfterChange"
                            @beforeChange="handleBeforeChange"
                            @breakpoint="handleBreakpoint"
                            @destroy="handleDestroy"
                            @edge="handleEdge"
                            @init="handleInit"
                            @reInit="handleReInit"
                            @setPosition="handleSetPosition"
                            @swipe="handleSwipe"
                            @lazyLoaded="handleLazyLoaded"
                            @lazyLoadError="handleLazeLoadError">
                            <div v-for="(product_type) in product_types" :key="product_type._id" >
                            <a class="pointernew text-decoration outline" @click="loadProductListing({sub_category_id:sub_category_id,category_id:category_id,product_type_id:product_type._id, searchdata: searchdata})">
                                <span class="imageOrders" :class="{'selectedBackground':product_type._id.includes(product_type_id)}">
                                <avatar
                                    class="product-avatar"
                                    v-if="product_type.image_url == ''"
                                    :username="product_type.name.replace(/ .*/,'')"
                                    :src="product_type.image_url"
                                    :size="50"
                                    :rounded="false"
                                    color="#f5a623"
                                    background-color="transparent">
                                </avatar>
                                <img :src="product_type.image_url" class="" v-else style="width: 50px !important;height: 50px !important;text-align: center;font-weight: 500;line-height: 50px;border-radius: none !important;">
                                </span>
                                <span class="text-capitalize fs-14 text-black lable-slide-take" :title="product_type.name" lang="de">{{product_type.name}}</span>
                            </a>
                            </div>
                        </slick>
                    </div>
                    <hr class="mt-10s categoryLine" v-if="sub_category_id != undefined">
                    <!-- <div class="mt-5 mb-5 text-center" v-if="product_type_id == undefined && loading == true && sub_category_id != undefined && products.length == 0">
                        <span>No Data</span>
                    </div> -->
                    <div class="mt-5 mb-5 text-center" v-if="loading == true && sub_category_id != undefined && products.length == 0">
                        <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="mt-5 mb-5" v-else-if="loading == false && sub_category_id != undefined && products.length == 0 && (!product_types.length || product_type_id)">
                        <img class="productData" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-data-yet.svg" alt="">
                    </div>
                    <div id="lazy-load-checkout-product" @scroll="scrollProductsEnd($event, {sub_category_id:sub_category_id,category_id:category_id, searchdata: searchdata})" >
                        <template v-for="(product, productIndex) in products">
                            <div class="mansory-div mt-5" :key="product.product_id" v-if="product.category_id == category_id && sub_category_id != undefined">
                                <div class="card items-mansory position-relative" style="box-shadow: 0 0 4px #b6b6b6 !important;" :class="{'image-unavailable': product.is_blocked}" :key="product.product_id">
                                    <!-- <div class="position-absolute" style="right: 10px; top: 10px;">
                                        <el-tooltip class="item" effect="dark" content="Info" placement="top">
                                            <i @click="showProductInfo(product)" class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;bottom: 5px;position: relative;"></i>
                                        </el-tooltip>
                                    </div> -->
                                    <div class="pointer" @click="wholeCardClick(productIndex)">
                                        <div class="m-1">
                                            <img class="img-responsive masonry-img-items-cdn" v-if="product.image_url" :src="product.image_url" alt="Card image cap" />
                                            <!-- <img class="img-responsive masonry-img-items" v-else src="https://cdn.digicollect.com/cdn/pos/images-new-theme/img-un.svg" alt="Card image cap" /> -->
                                            <div style="background-color: #242b34" v-else class="d-flex align-items-center justify-content-center masonry-img-items">
                                                <span class="text-white text-center img-un">Image Unavailable</span>
                                            </div>
                                        </div>
                                        <p class="unavailable-image" v-if="product.is_blocked">Unavailable</p>
                                        <div class="card-body" style="margin-bottom: 0 !important;" :class="{'card-bodySelected':product.selected_quantity != 0}">
                                            <h4 class="card-title d-flex align-items-center">
                                                <el-tooltip class="item" effect="dark" content="Non-Veg" placement="top" v-if="product.product_type == 'Non-Veg'">
                                                    <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Veg" placement="top" v-else-if="product.product_type == 'Veg'" >
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Egg" placement="top" v-else-if="product.product_type == 'Egg'" >
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Pescatarian" placement="top" v-else-if="product.product_type == 'Pescatarian'">
                                                    <img  style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                </el-tooltip>
                                                <span class="card-title-product-name textCaps" :class="{'ml-2':product.product_type != ''}" :title="product.product_name">{{ product.product_name }}</span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="m-2">
                                        <div v-if="product.pm_price > 0 || (product.is_combo && product.formatted_pm_price)" class="border-variant">
                                            <span>
                                            Price :
                                            <!-- <span v-if="product.is_combo">{{product.formatted_pm_price}}</span> -->
                                            <span >{{product.pm_price}}</span>
                                            </span>
                                        </div>
                                        <hr style="border:solid 1px rgb(216, 216, 216);">
                                        <!-- <span class="font-weight-bold" v-if="product.is_customisable == true">Customizable</span>
                                        <span class="font-weight-bold" v-else>Non Customizable</span> -->
                                        <div class="" v-if="product.is_customisable == false">
                                            <div v-if="product.selected_quantity != 0" class="d-flex align-items-center justify-content-between flex-wrap">
                                                <div class="d-flex align-items-center mt-2">
                                                    <!-- <span v-if="save_qty == false" id="basic-addon2" class="btnqty" @click="decreaseQuantity(productIndex)"> -->
                                                    <span id="basic-addon2" class="btnqty" @click="decreaseQuantity(productIndex)">
                                                        <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" class="img-add-button-svg" id="decrease_qty" />
                                                    </span>
                                                    <!-- <span @click="discardQty(productIndex)" v-else id="basic-addon2" class="btnqty">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-cross.svg" class="img-add-button-svg" id="discard_qty"/>
                                                    </span> -->
                                                    <template>
                                                        <!-- <input style="width: 50px; height: 25px; margin: 0 2px;" @keydown="save_qty = true" @focus="save_qty = true" type="number" class="qtyinput" v-model.number="product.selected_quantity" id="decrease_qty_increase"/> -->
                                                        <input style="width: 50px; height: 25px; margin: 0 2px;" readonly type="number" class="qtyinput" v-model.number="product.selected_quantity" id="decrease_qty_increase"/>
                                                    </template>
                                                    <!-- <span v-if="save_qty == false" id="basic-addon2" class="btnqty" @click="increaseQuantity(productIndex)"> -->
                                                    <span v-if="save_qty == false" id="basic-addon2" class="btnqty" @click="increaseQuantity(productIndex)">
                                                        <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg" id="increase_qty" />
                                                    </span>
                                                    <!-- <span @click="saveQty(productIndex)" v-else id="basic-addon2" class="btnqty">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-check.svg" class="img-add-button-svg" id="add_input_qty"/>
                                                    </span> -->
                                                </div>
                                                <span class="mt-2" v-if="product.is_combo">{{product.formatted_product_price}}</span>
                                                <span class="mt-2" v-else>{{product.formatted_variant_price}}</span>
                                            </div>
                                            <div v-else class="d-flex align-items-center justify-content-between flex-wrap" :class="{'pointerEventsNone':product.is_blocked}">
                                                <div class="d-flex align-items-center mt-2" @click="addOrderItem(productIndex)">
                                                    <span class="text-white text-center fs-14 pointer" style="line-height: 1.7;background-color: #ffa038; border: solid 0.5px #cdd2da;border-top-left-radius: 5px;border-bottom-left-radius: 5px; width: 80px; height: 25px;">+Add</span>
                                                    <img style="left: -3px;" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg position-relative" />
                                                </div>
                                                <span class="mt-2" v-if="product.is_combo">{{product.formatted_product_price}}</span>
                                                <span class="mt-2" v-else>{{product.formatted_variant_price}}</span>
                                            </div>
                                        </div>
                                        <div class="" v-if="product.is_customisable == true">
                                            <div v-if="product.selected_quantity != 0" class="d-flex align-items-center justify-content-between flex-wrap">
                                                <div class="d-flex align-items-center mt-2">
                                                    <span id="basic-addon2" class="btnqty" @click="editCustomization(product)">
                                                        <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" class="img-add-button-svg" id="decrease_qty"/>
                                                    </span>
                                                    <template>
                                                        <input style="width: 50px; height: 25px; margin: 0 2px;" type="text" class="qtyinput" v-model="product.selected_quantity" disabled  id="decrease_qty_increase"/>
                                                    </template>
                                                    <span id="basic-addon2" class="btnqty" @click="addCustomization(product)">
                                                        <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg" id="increase_qty" />
                                                    </span>
                                                </div>
                                                <span class="mt-2" v-if="product.is_combo">{{product.formatted_product_price}}</span>
                                                <span class="mt-2" v-else>{{product.formatted_variant_price}}</span>
                                            </div>
                                            <div v-else>
                                                <span class="d-flex align-items-center justify-content-between flex-wrap" @click="addAddons(product)">
                                                    <div class="d-flex align-items-center mt-2">
                                                        <span class="text-white text-center fs-14 pointer" style="line-height: 1.7;background-color: #ffa038; border: solid 0.5px #cdd2da;border-top-left-radius: 5px;border-bottom-left-radius: 5px; width: 80px; height: 25px;">+Add</span>
                                                        <img style="left: -3px;" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg position-relative" />
                                                    </div>
                                                    <span class="mt-2" v-if="product.is_combo">{{product.formatted_product_price}}</span>
                                                    <span class="mt-2" v-else>{{product.formatted_variant_price}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- <div class="digi-table-loader d-flex justify-content-center mt-10" v-if="loading" id="spinner" style="margin: 0 auto">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <table-selection v-if="tableSelect" :orderedTable="order.tables" :order_id="order._id" :editOrderTable="editOrderTable" :inProgressOrder="inProgressOrder" :editTable="true" :editOrder="editOrder"></table-selection>
        <check-out @updateData="checkout" @updateAddon="updateAddon" :callinProgress="callinProgress" :noOfPax="noOfPax" :storeorderId="storeorderId" @itemSummary="itemSummaryCheckout" v-if="CheckoutShow" @close="closeCheckout" :editOrder="editOrder" @updateCustomerName="updateCustomerName" :order="checkoutOrder" @updateCheckout="updateCheckout" :isPopUp="isPopUp" @discard="discardOrder"></check-out>
        <payment-method modal_name="method_payment" :summary="summary" :is_credit_card_used="order.is_credit_card_used" :grand_total_value="grand_total_value" :grand_total_amount="grand_total_amount" :fastPay="true" @closePayment="closePaymentMethod" v-if="showpay"></payment-method>
        <div>
            <create-customer modal_name="customer-create" @close="closeCreateCustomer" v-if="customercreate"></create-customer>
        </div>
        <div>
            <show-product  modal_name="show-product" :order_id="order._id" :is_combo="is_combo" :product_id="product_id" v-if="showProductTrue" @closeProductInfo="closeProductInfo()"></show-product>
        </div>
        <select-addons :editOrder="editOrder" @item_summary="itemSummary" @fastCheckoutAddons="fastCheckoutAddons"  @hideAddons="hideAddons" v-if="selectAddons" :hold_status="hold_status" :order_index="order" :product="product_details" modal_name="select-addons" :selectedCourse="selectedCourse"></select-addons>
        <add-customization @add_new_cusomization="add_new_cusomization"  :selectedCourse="selectedCourse"  v-if="addCustom == true" :order_index="order._id" @saveAddCustom="saveAddCustom" @hideAddCustom="hideAddCustom" :product="product_details" modal_name="add-customization"></add-customization>
        <edit-customization v-if="editCustom == true" :order_index="order._id" :selectedCourse="selectedCourse" @hideEditCustom="hideEditCustom" @updateCustom="updateCustom" :product="product_details" modal_name="edit-customization"></edit-customization>
        <item-summary :editOrder="editOrder" @updateItemSummary="updateItemSummary" :order_id="order._id" @fastCheckoutAddons="fastCheckoutAddons" @hideItemsSummary="hideItemsSummary" @checkoutItems="checkoutItems" :order="checkoutOrder" v-if="showItemSummary == true" modal_name="item-summary"></item-summary>
        <select-combo :hold_status="hold_status" :editOrder="editOrder" modal_name="select-combo" :comboDetails="comboDetails" v-if="selectCombo" @checkoutfastProduct="checkoutfastProduct()" @cSelectCombo="cSelectCombo()" :selectedCourse="selectedCourse" :order_index="order._id"></select-combo>
        <modifier-modal modal_name="Modifier-Popup" v-if="modifier_popup" :editOrder="editOrder" @hideModifierpopup="hideModifierpopup" @fastCheckoutSuccessModifier="fastCheckoutSuccessModifier" :hold_status="hold_status" :order_data="order" :product="product_details" :selectedCourse="selectedCourse" :sub_category_id="sub_category_id"></modifier-modal>
        <sweet-modal ref="success_modal" icon="success">
        {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
        {{ modal_msg }}
        </sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import VueScrollTo from 'vue-scrollto'
import Loader from './Loader'
import CheckOut from './CheckOut'
import Avatar from 'vue-avatar'
import CreateCustomer from './CreateCustomer'
import TableSelection from './TableSelection'
import CustomerServices from './mixins/customer'
import orderServices from './mixins/orders'
import { EventBus } from './eventBus/nav-bar-event'
import Slick from 'vue-slick';
const SelectAddons = () => import('./SelectAddons')
const AddCustomization = () => import('./AddCustomization')
const EditCustomization = () => import('./EditCustomization')
const ItemSummary = () => import('./ItemSummary')
const Courses = () => import('./Courses')
const PaymentMethod = () => import('./PaymentMethod')
import { Carousel, Slide } from 'vue-carousel';
const SelectCombo = () => import('./SelectCombo')
const ShowProduct = () => import('./ShowProduct')
const ModifierModal = () => import('./ModifierModal');
import printers from "./mixins/printers";
import customerServices from './mixins/customer'
export default {
    mixins: [CustomerServices, orderServices, printers, customerServices],
    data() {
        return {
            customersLL: [],
            showselectedTable:false,
            showOrderId:null,
            showProductTrue:false,
            is_combo:false,
            product_id:'',
            comboDetails:{
                name:'',
                _id:'',
                image_url:'',
                selected_quantity:null,
                product_quantity:null,
                product_price:null
            },
            hold_status: false,
            selectCombo:false,
            callinProgress:false,
            showpay:false,
            customercreate:false,
            handleclick:false,
            getCourses:true,
            courseSelected:'',
            selectedCourse:'',
            numberOfCourses:'',
            save_qty: false,
            loadCategories: true,
            slickOptions: {
                infinite: false,
                slidesToShow: 10,
                slidesToScroll: 4,
                arrows: true,
                autoplay: false,
                speed: 2000,
                responsive:
                [
                    {
                    breakpoint: 1120,
                        settings:
                        {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings:
                        {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings:
                        {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            },
            slickOptionsCourses: {
                infinite: false,
                slidesToShow: 10,
                slidesToScroll: 6,
                arrows: true,
                autoplay: false,
                speed: 1500,
                responsive:
                [
                    {
                    breakpoint: 1120,
                        settings:
                        {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings:
                        {
                            slidesToShow: 1,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings:
                        {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            },
            customers: [],
            noOfPax: 1,
            addbtndiv: true,
            InputPlusMinus: false,
            isPopUp: false,
            showAddedItems: false,
            showAvailableItems: false,
            orderType: 'Dine In',
            selectTable: true,
            searchdata: '',
            categories: [],
            sub_categories:[],
            products:[],
            product_types:[],
            totalCategories: 0,
            loading: false,
            CheckoutShow: false,
            itemspageShow: true,
            searchCustomer: false,
            defaultQty: 1,
            order: {
                _id: null,
                orderId: null,
                type: '',
                status: 'Inactive',
                paymentStatus: 'UNPAID',
                tables: [],
                discount: {
                    type: 'percent',
                    value: 0
                },
                roundOff: 0.0,
                orderDayNumber: null,
                items: {},
                customerId: null,
                currency: {
                    symbol: null,
                    text: ''
                },
                itemCount: 0,
                total: 0.0,
                itemsLength: 0
            },
            selectedCustomer: null,
            isTableSelection: false,
            selectedCategory: null,
            selectedVendor: null,
            availableCategories: [],
            tables: [],
            tableSelect: false,
            checkoutOrder: {},
            editOrder: false,
            inProgressOrder: false,
            vendors: [],
            ajaxCallInProgress: false,
            editOrderTable: false,
            inProgressOrder: false,
            singleCustomer: {},
            type:"Categories",
            category_id:"",
            sub_category_id:"",
            product_type_id:"",
            responseMessage:'',
            product_details:[],
            selectAddons: false,
            addCustom: false,
            editCustom: false,
            showItemSummary: false,
            grand_total_value:'',
            grand_total_amount:'',
            summary:'',
            modal_msg:'',
            count:0,
            infinite_scroll_reached_end: false,
            storeorderId:'',
            for_notification: '',
            update: false,
            // Customer lazy loading
            customer_count: 0,
            isVisibleCustomers: false,
            scrollable_customer: false,
            loadCustomer: false,
            temp_customers: 0,
            product_scroll_count: 0,

            prodtype_loader: false,
            subcat_loader: false,
            cat_loader: false,
            prod_loader: false,
            customer_search_key: '',
            modifier_popup: false,
        }
    },
    components: {
        vSelect,
        CheckOut,
        CreateCustomer,
        TableSelection,
        VueScrollTo,
        Loader,
        Slick,
        Avatar,
        SelectAddons,
        AddCustomization,
        EditCustomization,
        ItemSummary,
        PaymentMethod,
        SweetModal,
        Courses,
        Carousel,
        Slide,
        SelectCombo,
        ShowProduct,
        ModifierModal
    },
    methods: {
        openModifierPopup(){
            this.modifier_popup = true
            setTimeout(() => {
                this.$modal.show('Modifier-Popup')
            }, 500)
        },  
        hideModifierpopup(){
            this.modifier_popup = false
            setTimeout(() => {
                this.$modal.hide('Modifier-Popup')
            }, 500)
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
        },
        fastCheckoutSuccessModifier() {
            this.modifier_popup = false
            setTimeout(() => {
                this.$modal.hide('Modifier-Popup')
            }, 500);
        },
        updateItemSummary() {
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
            this.itemSummary();
        },
        cSelectCombo(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            // console.log(this.category_id)
            // console.log(this.sub_category_id)
            // console.log(this.product_type_id)
            // console.log(this.order._id)
            this.loadProductListing(params);
            this.fetchCart();
            this.selectCombo = false
        },
        loadingProducts() {
            this.loading = true;
            this.infinite_scroll_reached_end = false;
            let params={
                limit: 10,
                skip: this.count,
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id: this.product_type_id,
                order_id: this.order._id
            }
            this.$http.post('/mobile/get_products',params)
            .then(response => {
            if(response.data.response.total_product_count > this.products.length){
                if (this.count > 0) {
                    let tempFiles =  response.data.response.items;
                    tempFiles.forEach(el =>{ this.products.push(el); });
                    this.count += response.data.response.items.length;
                } else {
                    this.products = response.data.response.items;
                    this.count=response.data.response.items.length;
                }
            }
            if (response.data.response.items.length < 10) {
                    this.infinite_scroll_reached_end=true;
                    document.getElementById('spinner').style.display = "none";
                    document.getElementById('spinner').removeEventListener('scroll',this.infinteScroll);
                    this.loading = false;
                }
            })
            .catch(error => {
                this.loading = false;
                document.getElementById('spinner').style.display = "none";
                // document.getElementById('spinner').removeEventListener('scroll',this.infinteScroll);
            })
        },
        updateAddon(){
            this.showCheckout();
        },
        hideItemsSummary(){
            this.showItemSummary = false;

        },
        checkoutItems(){
            this.showItemSummary = false;

            this.showCheckout();
        },
        add_new_cusomization(product){
            if(product.is_combo){
                this.selectCombo = true
                this.comboDetails = {
                    _id:product._id,
                    name:product.product_name,
                    image_url:product.image_url,
                    product_quantity:product.product_quantity,
                    selected_quantity:1,
                    product_price:product.product_price
                }
                setTimeout(() => {
                    this.$modal.show('select-combo')
                }, 500);
            }else if(product.is_customisable){
                this.addCustom = false
                // this.selectAddons = true
                this.modifier_popup = true
                this.order._id
                this.product_details = product
                // console.log(this.product_details)
                // setTimeout(() => {
                //     this.$modal.show('select-addons')
                // }, 500);
                setTimeout(() => {
                    this.$modal.show('Modifier-Popup')
                }, 500)
            }
        },
        addCustomization(product){
            if(product.is_customisable){
                this.addCustom = true
                this.order._id
                this.product_details = product
                setTimeout(() => {
                    this.$modal.show('add-customization')
                }, 500);
            }
        },
        saveAddCustom(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
            this.addCustom = false
        },
        hideAddCustom(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
            this.addCustom = false
        },
        editCustomization(product){
            this.editCustom = true

            this.order._id
            this.product_details = product
            setTimeout(() => {
                this.$modal.show('edit-customization')
            }, 500);
        },
        hideEditCustom(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();

            this.editCustom = false,
            this.order.itemCount = 0,
            this.order.total = 0
        },
        updateCustom(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();

            this.editCustom = false
        },
        addAddons(product){
            // console.log('add addons console 1')
            // console.log(product)
            if(product.is_combo){
                this.selectCombo = true
                this.comboDetails = {
                    _id:product._id,
                    name:product.product_name,
                    image_url:product.image_url,
                    product_quantity:product.pm_quantity,
                    selected_quantity:1,
                    product_price:product.pm_price
                }
                setTimeout(() => {
                    this.$modal.show('select-combo')
                }, 500);
            }else if(product.is_customisable){
                // this.selectAddons = true
                this.modifier_popup = true
                this.order._id
                this.product_details = product
                // setTimeout(() => {
                //     this.$modal.show('select-addons')
                // }, 500);
                setTimeout(() => {
                    this.$modal.show('Modifier-Popup')
                }, 500)
            }
        },
        hideAddons(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();

            this.selectAddons = false
        },
        async itemSummary(){
            this.checkoutOrder = {}
            try {
                let params = { orderId: this.order._id, origin: 'place_order'}
                if (this.editOrder) {
                    params.update = true
                }
                let res = await this.getCheckout(params)
                this.checkoutOrder = res.order
                this.order.itemCount = res.order.cart_count
                this.order.currency = res.order.currency
                this.order.total = res.order.grand_total
                this.showItemSummary = true;
                setTimeout(() => {
                    this.$modal.show('item-summary');
                }, 500);
            } catch (reason) {
                this.checkoutOrder.items = []
                this.order.itemCount = 0
                this.order.total = null
                this.showAddedItems = false
                this.$toasted.global.error(reason)
            }
        },
        async itemSummaryCheckout(){
            this.CheckoutShow = false
            this.itemspageShow = true
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
            try {
                let params = { orderId: this.order._id }
                if (this.editOrder) {
                    params.update = true
                }
                let res = await this.getCheckout(params)
                this.checkoutOrder = res.order
                this.order.itemCount = res.order.cart_count
                this.order.currency = res.order.currency
                this.order.total = res.order.grand_total
                this.showItemSummary = true;

                setTimeout(() => {
                    this.$modal.show('item-summary');
                }, 500);
            } catch (reason) {
                this.checkoutOrder.items = []
                this.order.itemCount = 0
                this.order.total = null
                this.showAddedItems = false
            }
        },
        updateCustomerName(order){
            // console.log(order)
            this.hold_status = order.hold_order_before_placing
            this.selectedCustomer.first_name = order.customer_name
            this.products.selected_quantity = order.quantity
            this.checkout();
        },
        async scrollProductsEnd(e, params) {
            const {target} = e;
            if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                //this code will run when the user scrolls to the bottom of this div so
                //you could do an api call here to implement lazy loading
                let payload = {
                    category_id: params.category_id,
                    course: 1,
                    limit: 100,
                    order_id: this.order._id,
                    searchdata: params.searchdata,
                    skip: this.product_scroll_count,
                    sub_category_id: params.sub_category_id,
                }
                this.loading = true
                let res = await this.getProductListing(payload)
                if (res.response.skip == this.product_scroll_count) {
                    let tempFiles = res.response.items
                    
                    tempFiles.forEach(el => {
                        this.products.push(el)
                    })
                    this.product_scroll_count += res.response.items.length
                    this.loading = false
                }
                if (res.response.items.length < 10) {
                    this.infinite_scroll_reached_end = true
                    document.getElementById('spinner').style.display = 'none'
                    this.loading = false
                }

            }
        },
        async loadProductListing(params){
            this.$nextTick(() => {
                document.getElementById('bigcontainer_scroll').scrollTop = 0
                window.scrollTo(0,0);
            })
            this.loading = true;
            this.infinite_scroll_reached_end = false
            try {
                this.category_id = params.category_id
                this.sub_category_id = params.sub_category_id
                this.product_type_id = params.product_type_id
                params.order_id = this.order._id
                params.course = this.selectedCourse
                if(this.getCourses){
                    params.is_course_list_required = true
                }
                if(params.sub_category_id && params.category_id) {
                    params.skip = 0;
                    params.limit = 100;
                }
                let response = await this.getProductListing(params)
                if(this.getCourses){
                    this.numberOfCourses = response.response.courses_list
                    this.selectedCourse = this.numberOfCourses[0].course
                    this.courseSelected = this.numberOfCourses[0].course
                    this.getCourses = false
                }
                this.showOrderId = response.response.order_id
                this.type = response.response.type
                if(this.type == "Categories"){
                    this.cat_loader = true
                    this.sub_category = [];
                    this.product_types = [];
                    this.products = [];
                    this.categories = response.response.items
                    this.cat_loader = false
                    this.fetchCart();
                    this.count = 0
                }
                else if(this.type =="Sub Categories"){
                    this.subcat_loader = true
                    this.product_types = [];
                    this.products = [];
                    this.count = 0;
                    this.sub_categories = response.response.items
                    this.subcat_loader = false
                    if(this.sub_categories.length == 1) {
                        let selected_subcategory_id = this.sub_categories[0]._id
                        setTimeout(() => {
                            this.loadProductListing({sub_category_id:selected_subcategory_id,category_id:params.category_id})
                        }, 500);
                    }
                }
                else if(this.type =="Product Types"){
                    this.prodtype_loader = true
                    this.products = [];
                    this.count = 0;
                    this.product_types = response.response.items
                    this.prodtype_loader = false
                    if(this.product_types.length == 1) {
                        let product_type_id = this.product_types[0]._id
                        setTimeout(() => {
                            this.loadProductListing({sub_category_id:params.sub_category_id,category_id:params.category_id,product_type_id: product_type_id})
                        }, 1000);
                    }
                }
                else if(this.type =="Products"){
                    this.prod_loader = true
                    this.products = [];
                    this.products = response.response.items
                    this.product_scroll_count = response.response.items.length
                    this.count = response.response.items.length;
                    this.prod_loader = false
                    this.loading = false;
                    document.getElementById('lazy-load').addEventListener('scroll',this.infinteScroll,false);
                }
            } catch (reason) {
                this.loading = false;
            }
        },
        infinteScroll() {
            var scrollTop = document.getElementById('lazy-load').scrollTop;
            var scrollHeight = document.getElementById('lazy-load').scrollHeight;
            var offsetHeight = document.getElementById('lazy-load').offsetHeight;
            var clientHeight = document.getElementById('lazy-load').clientHeight;
            var contentHeight = scrollHeight - offsetHeight;
            if (contentHeight == scrollTop)
            {
                if(!this.infinite_scroll_reached_end) {
                    this.loadingProducts();
                }
            }
        },
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },
        // Events listeners
        handleAfterChange(event, slick, currentSlide) {
            // console.log('handleAfterChange', event, slick, currentSlide);
        },
        handleBeforeChange(event, slick, currentSlide, nextSlide) {
            // console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
        },
        handleBreakpoint(event, slick, breakpoint) {
            // console.log('handleBreakpoint', event, slick, breakpoint);
        },
        handleDestroy(event, slick) {
            // console.log('handleDestroy', event, slick);
        },
        handleEdge(event, slick, direction) {
            // console.log('handleEdge', event, slick, direction);
        },
        handleInit(event, slick) {
            // console.log('handleInit', event, slick);
        },
        handleReInit(event, slick) {
            // console.log('handleReInit', event, slick);
        },
        handleSetPosition(event, slick) {
            // console.log('handleSetPosition', event, slick);
        },
        handleSwipe(event, slick, direction) {
            // console.log('handleSwipe', event, slick, direction);
        },
        handleLazyLoaded(event, slick, image, imageSource) {
            // console.log('handleLazyLoaded', event, slick, image, imageSource);
        },
        handleLazeLoadError(event, slick, image, imageSource) {
            // console.log('handleLazeLoadError', event, slick, image, imageSource);
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
        closeCreateCustomer(flag) {
            this.customercreate = false
            this.$modal.hide('customer-create')
        },
        formatPrice(value) {
            if (value) {
                let val = (value / 1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } else return 0
        },
        updateCheckout() {
            setTimeout(() => {
                let params = {
                    category_id: this.category_id,
                    sub_category_id: this.sub_category_id,
                    product_type_id:this.product_type_id,
                    order_id:this.order._id
                }
                this.loadProductListing(params);
                this.fetchCart();
            }, 500);
            this.update = true
            this.checkout()
        },
        async fetchVendors() {
            try {
                let res = await this.getVendors()
                this.vendors = res.vendors
                this.selectedVendor = this.vendors[0]
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        closeCheckout(flag) {
            if (flag) {
                this.order._id = null
                this.$router.push('manageorder')
            }
            this.CheckoutShow = false
            this.itemspageShow = true
            this.handleclick = true
            setTimeout(() => {
                this.handleclick = false
            }, 2000);
        },
        async getSearchCustomer(search = '') {
            try {
                let params = {
                    properties: ['first_name', 'last_name'],
                    search: { term: search, fields: ['first_name', 'last_name'] },
                    sort: { by: 'first_name', order: 1 },
                    skip: 0,
                    limit: 5
                }
                let res = await this.customerGetCustomerWith(params)
                this.customers = res.response
            } catch (reason) {
                // TODO: Handle Error
            }
        },
        // async loadMeasurementsSearch(search) {
        // this.loadCustomer = true
        // let response = await this.getScmMeasurements({
        //     search_key: search,
        //     skip: 0,
        //     limit: 10
        // })
        // this.measurements = response.unit_of_measurements
        // this.count = this.measurements.length
        // this.isLoading = false
        // this.scrollable = false
        // },
        async loadCustomersSearch(search_key){
            this.customer_search_key = search_key || ''
            this.loadCustomer = true
            let params = {
                skip: this.customer_count,
                limit: 5,
                searchdata: this.customer_search_key,
                sortKey: 'first_name',
                sortValue: 1
            }
            let res = await this.getCustomers(params)
            this.customersLL = res.all_customers
            this.customer_count = this.customersLL.length
            this.loadCustomer = false
            this.scrollable_customer == false
        },
        async reachedEndOfCustomerList(reached){
            this.isVisibleCustomers = reached
            if(reached){
                if(this.scrollable_customer == false){
                    this.loadCustomer = true
                    let res = await this.getCustomers({
                        skip: this.customer_count,
                        limit: 5,
                        searchdata: this.customer_search_key,
                        sortKey: 'first_name',
                        sortValue: 1
                    })
                    this.temp_customers = res.all_customers.length
                    if(res.payload.skip == this.customer_count) {
                        if(this.temp_customers > 0){
                            let tempFiles = res.all_customers
                            tempFiles.forEach((el) => {
                                this.customersLL.push(el)
                            })
                            this.customer_count += res.all_customers.length
                            this.loadCustomer = false
                        } else {
                            this.scrollable_customer = true
                            this.loadCustomer = false
                        }
                    }
                    this.loadCustomer = false
                }
            }
        },
        getSearchCategory(search = '') {
            this.selectedCategory = ''
        },
        getSearchVendor() {
            this.selectedVendor = ''
        },
        discardOrder() {
            this.order._id = null
            this.$router.push('tableselection')
        },
        // showCheckout() {
        //     this.storeorderId = this.order._id
        //     if (this.order.type == 'Dine In' || this.order.type == 'Take Away') {
        //         if (this.order.type == 'Take Away' || this.order.tables.length) {
        //             if (this.order.customerId) {
        //                 if (this.order._id) {
        //                     if (this.order.itemCount) {
        //                         this.CheckoutShow = true
        //                         this.itemspageShow = false
        //                         this.checkout()
        //                     } else {
        //                         this.$toasted.global.error('No Item Added')
        //                     }
        //                 } else {
        //                 this.$toasted.global.error('No Order Initiated')
        //                 }
        //             } else {
        //                 this.$toasted.global.error('Please Select a Customer')
        //             }
        //         } else {
        //             this.$toasted.global.error('Please Select a Table')
        //         }
        //     } else {
        //         this.$toasted.global.error('Please Select Order Type')
        //     }
        // },
        showCheckout() {
            this.storeorderId = this.order._id
            if (this.order.type == 'Dine In'){
                if(this.order.tables.length){
                    if (this.order.customerId) {
                        if (this.order._id) {
                            if (this.order.itemCount) {
                                this.CheckoutShow = true
                                this.itemspageShow = false
                                this.checkout();
                                this.$router.replace(`/takeordernew?order_id=${this.order._id}&order_type=${this.order.type}`)
                                localStorage.setItem('order_data_for_checkout', JSON.stringify(this.order))
                            } else {
                                this.$toasted.global.error('No Item Added')
                            }
                        } else {
                        this.$toasted.global.error('No Order Initiated')
                        }
                    } else {
                        this.$toasted.global.error('Please Select a Customer')
                    }
                }else{
                    this.$toasted.global.error('Please Select a Table')
                }
            }else{
                if (this.order.customerId) {
                    if (this.order._id) {
                        if (this.order.itemCount) {
                            this.CheckoutShow = true
                            this.itemspageShow = false
                            this.checkout()
                            this.$router.replace(`/takeordernew?order_id=${this.order._id}&order_type=${this.order.type}`)
                            localStorage.setItem('order_data_for_checkout', JSON.stringify(this.order))
                        } else {
                            this.$toasted.global.error('No Item Added')
                        }
                    } else {
                        this.$toasted.global.error('No Order Initiated')
                    }
                } else {
                    this.$toasted.global.error('Please Select a Customer')
                }
            }
        },
        async checkout() {
            if(!this.update){
                this.callinProgress = true
            } else {
                this.update = false
            }
            this.checkoutOrder = {}
            try {
                let params = { orderId: this.order._id }
                if (this.editOrder) {
                    params.update = true
                }
                let res = await this.getCheckout(params)
                this.callinProgress = false
                this.checkoutOrder = res.order
                this.order.itemCount = res.order.cart_count
                this.order.currency = res.order.currency
                this.order.total = res.order.grand_total
                EventBus.$emit('emit_for_discount');
            } catch (reason) {
                this.checkoutOrder.items = []
                this.order.itemCount = 0
                this.order.total = null
                this.showAddedItems = false
            }
        },
        TableSearch() {
            this.itemspageShow = false
            this.tableSelect = true
            this.editOrderTable = true
        },
        refreshCateory() {
            this.categories.push({})
            this.categories.pop()
        },
        async fetchCart() {
            try {
                let params = { orderId: this.order._id }
                if(this.for_notification == 'notification') {
                    params.for_notifications = true
                }
                let res = await this.getCart(params)
                this.numberOfCourses = res.order.active_courses
                this.order.itemCount = res.order.cart_count
                this.order.currency = res.order.currency
                this.order.total = res.order.grand_total
                this.order['grand_total_with_credit_card_charges'] = res.order.grand_total_with_credit_card_charges
                console.log(this.order)
            } catch (reason) {
                // this.$toasted.global.error(reason)
            }
        },
        showCreateCustomer() {
            this.customercreate = true
            this.searchCustomer = false
            setTimeout(() => {
                this.$modal.show('customer-create')
            }, 500);
        },
        changeSelectCategory(category) {
            this.selectedCategory = category
        },
        async fetchTables() {
            try {
                let params
                if (this.order._id) {
                    params.orderId = this.order._id
                }
                let res = await this.getTables(params)
                this.tables = res.message
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        closeProductInfo(){
            this.showProductTrue = false
        },
        showProductInfo(value) {
            this.is_combo = value.is_combo
            this.product_id = value.product_id
            this.showProductTrue = true
            setTimeout(() => {
                this.$modal.show('show-product')
            }, 200);
        },
        async addItem(params) {
            try {
                let res = await this.addItemToOrder(params)
                if (res.response.order) {
                    if (this.order.itemCount < res.response.cart_count) {
                        this.$toasted.global.success('Item Added Successfully')
                    } else if (this.order.itemCount == res.response.cart_count) {
                        this.$toasted.global.success('Item Updated Successfully')
                    } else {
                        this.$toasted.global.success('Item Removed Successfully')
                    }
                } else {
                    this.$toasted.global.success('Item Removed Successfully')
                    this.$toasted.global.error('No Items in Cart')
                }
                this.fetchCart();
                this.checkout()
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        wholeCardClick(productIndex) {
            if(this.products[productIndex].is_blocked) {
                return;
            } else {
                if(this.products[productIndex].is_customisable == false) {
                    if(this.products[productIndex].selected_quantity != 0) {
                        this.increaseQuantity(productIndex);
                    } else {
                        this.addOrderItem(productIndex);
                    }
                } else {
                    if(this.products[productIndex].selected_quantity != 0) {
                        this.addCustomization(this.products[productIndex]);
                    } else {
                        this.addAddons(this.products[productIndex]);
                    }
                }
            }
        },
        addOrderItem(productIndex) {
            // console.log('add addons console')
            let product = this.products[productIndex]
            let data = {}
            product.selected_quantity = 1
            data.quantity = product.selected_quantity
            data.product_id = product.product_id
            data.order_id = this.order._id
            data.is_customizable = false
            data.course = this.selectedCourse
            data.is_combo = product.is_combo
            data.variant_id = product.variant_id || ''
            if (this.isPopUp) data.vendorId = this.selectedVendor._id
            this.addItem(data)
        },
        increaseQuantity(productIndex) {
            try {
                // TODO: increase with item_id
                let product = this.products[productIndex]
                console.log(product)
                let data = {}
                if(product.is_combo){
                    if (product.selected_quantity < product.product_quantity) {
                        product.selected_quantity++
                        data.quantity = product.selected_quantity
                        data.product_id = product.product_id
                        data.order_id = this.order._id
                        data.is_customizable = false
                        data.course = this.selectedCourse
                        data.is_combo = product.is_combo
                        data.variant_id = product.variant_id || ''
                        if (this.isPopUp) data.vendorId = this.selectedVendor._id
                        this.addItem(data)
                    }
                }else{
                    if (product.selected_quantity < product.pm_quantity) {
                        product.selected_quantity++
                        data.quantity = product.selected_quantity
                        data.product_id = product.product_id
                        data.order_id = this.order._id
                        data.is_customizable = false
                        data.course = this.selectedCourse
                        data.is_combo = product.is_combo
                        data.variant_id = product.variant_id || ''
                        if (this.isPopUp) data.vendorId = this.selectedVendor._id
                        this.addItem(data)
                    }
                }
            }
            catch(err) {
                // console.log(err)
            }
        },
        decreaseQuantity(productIndex) {
            try {
                let product = this.products[productIndex]
                console.log(product)
                let data = {}
                if (product.selected_quantity > 0) {
                    product.selected_quantity--
                    data.quantity = product.selected_quantity
                    data.product_id = product.product_id
                    data.order_id = this.order._id
                    data.is_customizable = false
                    data.course = this.selectedCourse
                    data.is_combo = product.is_combo
                    data.variant_id = product.variant_id || ''
                    if (this.isPopUp) data.vendorId = this.selectedVendor._id
                    this.addItem(data)
                }
            } catch(err) {
                // console.log(err)
            }
            // TODO: decrease with item_id
        },
        saveQty(productIndex){
            let product = this.products[productIndex]
            let data = {}
            if(product.is_combo){
                if (product.selected_quantity < product.pm_quantity) {
                    data.quantity = product.selected_quantity
                    data.product_id = product.product_id
                    data.order_id = this.order._id
                    data.is_customizable = false
                    data.course = this.selectedCourse
                    data.is_combo = product.is_combo
                    data.variant_id = product.variant_id || ''
                    if (this.isPopUp) data.vendorId = this.selectedVendor._id
                    this.addItem(data)
                    this.save_qty = false
                }
            }else{
                if (product.selected_quantity < product.pm_quantity) {
                    data.quantity = product.selected_quantity
                    data.product_id = product.product_id
                    data.order_id = this.order._id
                    data.is_customizable = false
                    data.course = this.selectedCourse
                    data.is_combo = product.is_combo
                    data.variant_id = product.variant_id || ''
                    if (this.isPopUp) data.vendorId = this.selectedVendor._id
                    this.addItem(data)
                    this.save_qty = false
                }else{
                    this.$toasted.global.error('Product Quantity Cannot Exceed Max Quantity')
                }
            }
        },
        discardQty(productIndex){
            let product = this.products[productIndex]
            product.selected_quantity = 1;
            this.save_qty = false
        },
        async updateCustomer() {
            try {
                let params = {
                    customerId: this.order.customerId,
                    orderId: this.order._id
                }
                let res = await this.orderCustomer(params)
                if (!this.order._id && res.order_id) {
                    this.order._id = res.order_id
                }
                // this.$toasted.global.success('Customer Selected Successfully')
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async removeTable() {
            try {
                let params = { orderId: this.order._id }
                let res = await this.orderRemoveTables(params)
                if (!this.order._id && res.order_id) {
                    this.order._id = res.order_id
                }
                this.clearTables()
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async removeCustomer() {
            try {
                let params = { orderId: this.order._id }
                let res = await this.orderRemoveCustomer(params)
                if (!this.order._id && res.order_id) {
                    this.order._id = res.order_id
                }
                this.selectedCustomer = null
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        clearTables() {
            if(this.order.tables.length){
                let selectedTable = this.tables.find(
                    element => element == this.order.tables[0]
                )
                if(selectedTable){
                    selectedTable.selected = false
                }
                this.order.tables = []
            }

        },
        async getOrderDetails() {
            try {
                this.showselectedTable = true
                let params = { orderId: this.order._id }
                let res = await this.orderDetails(params)
                this.showselectedTable = false
                this.selectedCustomer = res.order.customer
                this.order.tables = res.order.tables
                this.order.type = res.order.type
                this.noOfPax = res.order.no_of_pax
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async change_table(old_table_id, new_table_id, order_id) {
            try {
                let params = {
                    old_table_id: old_table_id,
                    new_table_id: new_table_id,
                    order_id: order_id
                }
                let res = await this.orderUpdateTable(params)
                this.$toasted.global.success('Table Changed')
                this.fetchTables()
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async fetchGuestCustomer() {
            try {
                let res = await this.getGuestCustomer()
                if (!this.selectedCustomer) {
                    this.selectedCustomer = res.open_guest_id
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async updateOrderType() {
            try {
                let params = {
                    orderType: this.order.type,
                    orderId: this.order._id
                }
                let res = await this.orderUpdateType(params)
                if (!this.order._id && res.order_id) {
                    this.order._id = res.order_id
                }
                if (this.loggedInUser.service_type == 'Both') {
                    this.$toasted.global.success('Order Type Selected Successfully')
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async updateOrderCustomer() {
            try {
                if (this.editOrder) {
                    if (this.selectedCustomer._id) {
                        let params = {
                            customer_id: this.selectedCustomer._id,
                            order_id: this.order._id
                        }
                        let res = await this.orderUpdateCustomer(params)
                        this.$toasted.global.success('Successfully Updated Customer')
                    }
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async applyPax() {
            try {
                // TODO: Validate Only Context fields
                if (parseInt(this.noOfPax) > 1000 && parseInt(this.noOfPax) < 1) {
                    this.$toasted.global.error('Invalid Number of Guest')
                    return
                }
                let result = await this.$validator.validateAll()
                if (result) {
                    let params = {
                        orderId: this.order._id,
                        noOfPax: parseInt(this.noOfPax)
                    }
                    let res = await this.orderPax(params)
                    this.$toasted.global.success('Successfully Updated Number of Guest')
                } else {
                    this.$toasted.global.error(this.errors.first('pax'))
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async fastPay(){
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    this.modal_msg = res.data.reason;
                    if (res.data.status_id == 1) {
                        this.$store.commit("setCashRegister",res.data.response.cash_register_details);
                        this.fastPayConfirm()
                    } else {
                        this.$toasted.global.error(res.data.reason);
                    }
                });
            } catch (reason) {
                this.$toasted.global.error(reason);
            }
        },
        async fastPayConfirm(){
            this.showpay = true
            try{
                let params = { orderId:this.order._id }
                let res = await this.getCheckout(params)
                this.summary = res.order
                if(res.status_id == 1){
                    this.$http.post('orders/grand_total', { order_id: this.order._id }).then(response => {
                        this.grand_total_value = response.data.value.toFixed(2)
                        this.grand_total_amount = response.data.amount
                    })
                    this.$modal.show('method_payment')
                }
            }
            catch(reason){
                this.$toasted.global.error(reason)
            }
        },
        closePaymentMethod(){
            this.showpay = false
            this.$modal.hide('method_payment')
        },
        SaveOrder() {
            this.printTicket()
            // Swal({
            //     title: 'Print Ticket',
            //     text: 'Do You Want to Print Ticket ?',
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#00448b',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'Continue Without Printing',
            //     allowOutsideClick: false
            // }).then(result => {
            //     if (result.value) {
            //         this.printTicket()
            //     } else {
            //         this.$router.push({
            //             name: 'ManageOrder',
            //             params: {
            //                 orderType: this.order.type
            //             }
            //         })
            //     }
            // })
        },
        async fastCheckout() {
            try {
                // let category_data = []
                // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                // if(data){
                //     data.forEach(y => {
                //         y.printers.forEach(x => {
                //             if(y._id == x.category_id){
                //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                //             }
                //         })
                //     })
                // }
                let params = {
                    order_id: this.order._id,
                    note: this.order.note,
                    // printer_details: category_data
                };
                let res = await this.orderMobilePlace(params);
                this.responseMessage = res.response.message
                this.$toasted.global.success(this.responseMessage);
                // if(data){
                    // data.forEach(y => {
                        // y.printers.forEach(x => {
                            res.response.ticket_details.forEach(z => {
                                // if(x.category_id == z.category_id){
                                    this.store_ticket_data = z
                                // }
                            })
                        // })
                    // })
                // }
                this.SaveOrder();
            } catch (reason) {
                // this.$toasted.global.error(reason);
                this.modal_msg = reason
                this.$swal({
                    type: 'warning',
                    text: this.modal_msg,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        },
        async updateFastCheckout() {
            try {
                // let category_data = []
                // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                // if(data){
                //     data.forEach(y => {
                //         y.printers.forEach(x => {
                //             if(y._id == x.category_id){
                //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                //             }
                //         })
                //     })
                // }
                let params = {
                    orderId: this.order._id,
                    note: this.order.note,
                    // printer_details: category_data
                };
                let res = await this.orderUpdateOrder(params);
                this.responseMessage = res.message
                this.$toasted.global.success(this.responseMessage);
                this.SaveOrder();
            } catch (reason) {
                this.$toasted.global.error(reason);
                this.$swal({
                    type: 'warning',
                    text: this.responseMessage,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        },
        async fastCheckoutAddons(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();

            this.selectAddons = false
            this.showItemSummary = false
            if(!this.editOrder && this.loggedInUser.enable_fast_checkout){
                this.fastCheckout()
            }
            else if(this.editOrder && this.loggedInUser.enable_fast_checkout){
                this.updateFastCheckout()
            }
        },
        async checkoutfastProduct(){
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();

            this.selectCombo = false
            this.showItemSummary = false
            if(!this.editOrder && this.fastcheckoutEnable){
                this.fastCheckout()
            }
            else if(this.editOrder && this.fastcheckoutEnable){
                this.updateFastCheckout()
            }
        },
        incrementPax() {
            if (this.noOfPax == 1000) {
                return
            }
            this.noOfPax = Number(this.noOfPax) + 1
        },
        decrementPax() {
            if (this.noOfPax == 1) {
                return
            }
            this.noOfPax = Number(this.noOfPax) - 1
        },
        async createdOrderTypeUpdate() {
            this.order.type = 'Take Away'
            try {
                let params = {
                    orderType: 'Take Away',
                    orderId: '',
                    update: false,
                    is_delivered: false
                }
                let res = await this.orderUpdateType(params)
                this.order._id = res.order_id
                this.order.type = 'Take Away'
                this.selectTable = false
                this.fetchGuestCustomer()
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async getCustomer(id) {
            try {
                let params = {
                    _id: id
                }
                let res = await this.getCustomerById(params)
                this.singleCustomer = res.response
            } catch (error) {
                this.$swal('Error', error.response, 'error')
            }
        },
        selectCourseforProduct(course){
            this.selectedCourse = course
            this.courseSelected = course
            setTimeout(() => {
                let params = {
                    course :this.courseSelected
                }
                this.loadProductListing(params)
            }, 1000)
        }
    },
    created() {
        const dict = {
            custom: {
                pax: {
                    required: () => 'PAX is Required',
                    numeric: () => 'PAX should be Numeric',
                    min_value: () => 'PAX should be greater than 0'
                }
            }
        }
        this.$validator.localize('en', dict)
        EventBus.$on('createdCustomerId', newId => {
            this.getCustomer(newId)
        })
        EventBus.$on('loadProductList', () => {
            let params = {
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                product_type_id:this.product_type_id,
                order_id:this.order._id
            }
            this.loadProductListing(params);
            this.fetchCart();
        })
        EventBus.$on('closeTableSelect', table => {
            if(this.tableSelect){
                this.getOrderDetails()
            }
            this.itemspageShow = true
            this.tableSelect = false
        })
        EventBus.$on('updateTables', table => {
            this.change_table(table.old_table_id, table.new_table_id, this.order._id)
        })
        EventBus.$on('updateTablesNew', table => {
            this.updateTable()
        })
        // if (this.$route.params.orderId == '' && this.$route.params.orderType == 'Take Away') {
        if ((this.$route.query.order_id == '' || this.$route.params.orderId == '') && (this.$route.params.order_type == 'Take Away' || this.$route.params.orderType == 'Take Away')) {
            this.createdOrderTypeUpdate()
        } else if (this.$route.query.order_id || this.$route.params.orderId) {
            if(this.$route.query.order_id && localStorage.getItem('order_data_for_checkout')) {
                this.order = JSON.parse(localStorage.getItem('order_data_for_checkout'))
                this.storeorderId = this.order._id
                this.CheckoutShow = true
                this.itemspageShow = false
                this.checkout();
            } else {
                if (this.$route.params.inProgressOrder) {
                    this.inProgressOrder = true
                    this.order._id = this.$route.query.order_id || this.$route.params.orderId
                    this.getOrderDetails()
                } else if (this.$route.params.order_type == 'Dine In' || this.$route.params.orderType == 'Dine In') {
                    this.order._id = this.$route.query.order_id || this.$route.params.orderId
                    this.order.tables = this.$route.params.selectTable
                    this.order.type = 'Dine In'
                }else if (this.$route.params.order_type != 'Dine In' || this.$route.params.orderType != 'Dine In') {
                    this.order._id = this.$route.query.order_id || this.$route.params.orderId
                    this.order.tables = this.$route.params.selectTable
                    this.order.type = this.$route.params.order_type || this.$route.params.orderType
                    this.getOrderDetails()
                }else{
                    this.editOrder = true
                    this.order._id = this.$route.query.order_id || this.$route.params.orderId
                    this.getOrderDetails()
                }
                if (this.$route.params.editOrder) {
                    this.editOrder = this.$route.params.editOrder
                } else this.editOrder = false
                if (this.$store.getters.loggedInUser.outlet_type == 'pop-up') {
                    this.isPopUp = false
                    // this.fetchVendors()
                    this.getSearchCustomer()
                } else {
                    this.getSearchCustomer()
                }
                if (!this.editOrder && !this.inProgressOrder) this.fetchGuestCustomer()
            }
            } else {
            this.$router.push('/tableselection')
        }
    },
    destroyed() {
        EventBus.$off('loadProductList')
    },
    watch: {
        'order.type'(newType, oldType) {
            if(newType == 'Dine In') this.selectTable = true
            else this.selectTable = false
            // if (this.order.tables && this.order.tables.length) this.clearTables()
        },
        selectedCustomer(newCustomer, oldCustomer) {
            this.searchCustomer = false
            if (newCustomer) this.order.customerId = newCustomer._id
            else this.order.customerId = null
        },
        singleCustomer(newCustomer) {
            this.selectedCustomer = newCustomer
        },
        selectedCategory(newValue, oldValue) {
            if (newValue && newValue.name) {
                let name = newValue.name.replace(/[^a-zA-Z]/g, '')
                this.$refs['category_' + name][0].click()
            }
        },
        selectedVendor(newValue, oldValue) {
            this.selectedCategory = 'All'
        },
        showAddedItems(newValue, oldValue) {
            if (typeof newValue == 'boolean') {
                this.categories = []
            } else {
                this.showAddedItems = false
            }
        },
        showAvailableItems(newValue, oldValue) {
            if (typeof newValue == 'boolean') {
                this.categories = []
            } else {
                this.showAvailableItems = false
            }
        },
        'order.customerId'(newCustomer, oldCustomer) {
            if (newCustomer && !this.editOrder) {
                this.updateCustomer()
            }
        },
        categories: function () {
            let currIndex = this.$refs.slick.currentSlide()
            this.$refs.slick.destroy()
            if(this.handleclick == false){
                this.$nextTick(() => {
                    this.$refs.slick.create()
                    this.$refs.slick.goTo(currIndex, true)
                })
            }
        },
        sub_categories: function () {
            let currIndex = this.$refs.sub_category.currentSlide()
            this.$refs.sub_category.destroy()
            if(this.handleclick == false){
                this.$nextTick(() => {
                    this.$refs.sub_category.create()
                    this.$refs.sub_category.goTo(currIndex, true)
                })
            }
        },
        product_types: function () {
            if(this.$refs.product_types != undefined){
                let currIndex = this.$refs.product_types.currentSlide()
                this.$refs.product_types.destroy()
                if(this.handleclick == false){
                    this.$nextTick(() => {
                        this.$refs.product_types.create()
                        this.$refs.product_types.goTo(currIndex, true)
                    })
                }
            }

        },
        searchdata: function(newValue) {
            this.loadProductListing({
                category_id: this.category_id,
                sub_category_id: this.sub_category_id,
                searchdata: this.searchdata,
                product_type_id: this.product_type_id
            });
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser
        },
        fastcheckoutEnable(){
            return this.loggedInUser.enable_fast_checkout
        }
    },
    mounted() {
        this.loadProductListing({});
        EventBus.$emit('customerdetailsLoad', false)
        EventBus.$emit('settingsLoad', false)
        EventBus.$emit('reportsLoad', false)
        EventBus.$on('UpdateOrderType', value => {
            this.order.type = 'Take Away'
            this.selectTable = false
            this.order.tables = []
        })
        EventBus.$on('callProducts',temp_index => {
            this.courseSelected = temp_index
        })
        EventBus.$on("update_checkOut",async (value) => {
            this.for_notification = value.for;
            this.order._id = value.order_id
            this.storeorderId = value.order_id
            this.editOrder = false
            this.order.customerId = value.customer_id
            this.order.itemCount = value.items_count
            try {
                let params = { orderId: value.order_id }
                if (this.editOrder) {
                    params.update = true
                }
                if(value.for == 'notification') {
                    this.fetchCart();
                    params.for_notification = true
                    params.update = false
                }
                let res = await this.getCheckout(params)
                this.checkoutOrder = res.order
                this.order.currency = res.order.currency
                this.order.total = res.order.grand_total
                if (this.order.customerId) {
                    if (this.order._id) {
                        if (this.order.itemCount) {
                            this.CheckoutShow = true
                            this.itemspageShow = false
                        } else {
                            this.$toasted.global.error('No Item Added')
                        }
                    } else {
                        this.$toasted.global.error('No Order Initiated')
                    }
                } else {
                    this.$toasted.global.error('Please Select a Customer')
                }
            } catch (reason) {
                this.checkoutOrder.items = []
                this.order.itemCount = 0
                this.order.total = null
                this.showAddedItems = false
            }
            this.$forceUpdate()
        })
    },
    beforeDestroy() {
        localStorage.removeItem('order_data_for_checkout')
    }
}
</script>
<style>
#lazy-load{
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
#lazy-load::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
.item-summary{
  border-radius: 18px;
  padding-left: 10px;
  padding-right: 12px;
  font-size: 14px !important;
  text-transform: capitalize;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 147px;
  font-weight: 600;
  line-height: 2.6rem;
  height: 3.3rem;
  color: #fff;
  white-space: nowrap;
  display: flex;
  align-items: center;
  background-color: #1a9347;
  user-select: none;
}
.categoryLine{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #d2d2d2;
}
.productData{
    width: 300px;
    height: 300px;
    margin: 0 auto;
    display: block;
}
.previous-course{
    background: #00448b!important;
    border-radius: 50%!important;
    padding: 6px;
    border: 1px solid #00448b!important;
}
.previous-course a{
    color: #fff!important;
}
.present-course{
    background: #1a9347!important;
    border-radius: 50%!important;
    border: 1px solid #1a9347!important;
}
.present-course a{
    color: #fff!important;
}
.course-padding{
    padding: 6px;
    border: 1px solid #00448b;
    border-radius: 50%;
}
.course-slick.slick-initialized.slick-slider{
  margin: 0 25px!important;
}
.course-slick.slick-initialized .slick-slide {
    margin: 5px 4px 3px 20px!important;
    color: #00448b;
    border-radius: 50% !important;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer!important;
    width: 36px!important;
    height: 36px;
    text-align: center;
}
.course-slick.slick-initialized .slick-slide div{
    text-decoration: none!important;
    outline: none;
    /* color: #00448b!important; */
}
.course-slick.slick-initialized .slick-slide .present-course div, .course-slick.slick-initialized .slick-slide .previous-course div,
.course-slick.slick-initialized .slick-slide .items-course div{
    text-decoration: none!important;
    outline: none;
    color: #fff!important;
}
.slick-slider {
  padding: 0px 20px;
}
/* .borderSolid{
  border-bottom: solid 1px #cdd2da;
} */
.imageOrders{
    background: #fff;
    position: absolute;
    padding: 15px;
    border-radius: 12px;
}
.selectedBackground{
  box-shadow: 0 1px 6px 0 rgba(28, 141, 255, 0.34);
  background: #00448b !important;
}
.slick-track{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (min-width: 768px) {
  .header-takeorder.col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 44%;
    -ms-flex: 0 0 44%;
    flex: 0 0 44%;
    max-width: 44%;
  }
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: #00448b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: -10px !important;
}
.slick-next {
    right: 1px !important;
}
.slick-initialized .slick-slide {
    display: block;
    width: 60px!important;
    margin: 18px 40px 18px 18px!important;
}
.lable-slide-take{
  margin-top: 80px;
  position: relative;
  display: block;
  text-align: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /* word-break: break-word; */
  width: 80px;
}
.skeleton-loader {
  padding: 10px;
}
@media screen and (max-width: 590px) {
  .checkoutTable {
    margin-top: 182px;
  }
  .applyTable {
    margin-top: 13px;
    margin-bottom: 129px;
  }
}
@media screen and (max-width: 766px) {
  .marginCategory{
    margin-top: 103px !important;
  }
}
.no-for-pax {
  border: 1px solid #eee !important;
  border-radius: 5px;
  padding-left: 10px;
  background-color: #fbfbfb;
  color: #303031;
  height: 30px;
  width: 100px;
  text-align: center;
  font-weight: 600;
}
#takeorderdiv .btn-icon.actionicons.cust-del {
  height: 28px !important;
  margin-top: -7px;
}

:root {
  /* --card-padding: 10px; */
  --card-height: 340px;
  --card-skeleton: linear-gradient(lightgrey var(--card-height), transparent 0);
  --avatar-size: 160px;
  --avatar-position: var(--card-padding) var(--card-padding);
  --avatar-skeleton: radial-gradient(
    circle 100px at center,
    white 99%,
    transparent 0
  );
  --title-height: 32px;
  --title-width: 100%;
  --title-position: var(--card-padding) 180px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);
  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(
    white var(--desc-line-height),
    transparent 0
  );
  --desc-line-1-width: calc(100% - var(--card-padding) * 2);
  --desc-line-1-position: var(--card-padding) 242px;
  --desc-line-2-width: 70%;
  --desc-line-2-position: var(--card-padding) 265px;
  --footer-height: 10px;
  --footer-position: 0 calc(var(--card-height) - var(--footer-height));
  --footer-skeleton: linear-gradient(white var(--footer-height), transparent 0);
  --blur-width: 70%;
  --blur-size: var(--blur-width) calc(var(--card-height) - var(--footer-height));
}

/*
 * You can also use media queries to adjust the skeleton
 */
@media screen and (min-width: 64em) {
  :root {
    --title-width: 90%;
    --card-padding: 10px;
  }
}

.card-skel {
  width: 80%;
  margin: 0 auto;
  height: var(--card-height);
  padding: 10px;
}

.card-skel:empty::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(
      90deg,
      rgba(211, 211, 211, 0) 0,
      rgba(211, 211, 211, 0.8) 50%,
      rgba(211, 211, 211, 0) 100%
    ),
    var(--title-skeleton), var(--desc-line-skeleton), var(--desc-line-skeleton),
    var(--avatar-skeleton), var(--footer-skeleton), var(--card-skeleton);
  background-size: var(--blur-size), var(--title-width) var(--title-height),
    var(--desc-line-1-width) var(--desc-line-height),
    var(--desc-line-2-width) var(--desc-line-height),
    var(--avatar-size) var(--avatar-size), 100% var(--footer-height), 100% 100%;
  background-position: -150% 0, var(--title-position),
    var(--desc-line-1-position), var(--desc-line-2-position),
    var(--avatar-position), var(--footer-position), 0 0;
  background-repeat: no-repeat;
  -webkit-animation: loading 1.5s infinite;
  animation: loading 1.5s infinite;
}

@-webkit-keyframes loading {
  to {
    background-position: 350% 0, var(--title-position),
      var(--desc-line-1-position), var(--desc-line-2-position),
      var(--avatar-position), var(--footer-position), 0 0;
  }
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--title-position),
      var(--desc-line-1-position), var(--desc-line-2-position),
      var(--avatar-position), var(--footer-position), 0 0;
  }
}

#takeorderdiv .page-headerr {
  position: fixed !important;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-color: #fafafa;
  box-shadow: 13px 3px 10px -1px rgba(0, 0, 0, 0.16);
  z-index: 99 !important;
  padding-bottom: 10px;
}

#takeorderdiv .el-checkbox + .el-checkbox {
  margin-left: 10px !important;
}

.unavailable-image {
  position: absolute;
  vertical-align: middle;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  text-align: center;
}

.mansory-div {
  display: -webkit-inline-box;
  padding: 0 12px;
  margin: 0 12px;
  width: 200px;
}

.masonry-img-items-cdn {
  width: 192px !important;
  height: 128px !important;
  border-radius: 5px;
}

.elselect-variant .el-input__prefix,
.el-input__suffix {
  top: 4px !important;
}

.a-link-select-table {
  text-decoration: none !important;
}

#takeorderdiv .card-body .el-input.is-disabled .el-input__inner {
  background-color: transparent !important;
  border-color: #e4e7ed;
  cursor: not-allowed;
}

.btnqty {
  cursor: pointer;
}

.loading-click {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  z-index: 99999 !important;
  font-size: 20px;
  color: #303031;
}

.no-results {
  width: 100%;
  text-align: center;
  padding-top: 5%;
}

.icon-information {
  cursor: pointer;
}

.card-title-product-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 207px;
  display: inline-block;
}

.items-mansory .card-body {
  padding: 1rem !important;
  border-left: 0px solid #a1caff !important;
  margin-bottom: 6px !important;
  border-radius: 3px;
}
/* .items-mansory .card-bodySelected{
  margin-bottom: 20px !important;
} */

.card.items-mansory {
  /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important; */
  /* border: 0px !important; */
  box-shadow: 0 0 4px #b6b6b6 !important;
  margin-bottom: 15px !important;
  border-radius: 8px;
  width: 200px;
}

.card.items-mansory.image-unavailable {
  opacity: 0.6 !important;
  pointer-events: none;
}

.variant-span {
  color: #303031 !important;
  padding-right: 6px;
}

.elselect-variant .el-input__inner {
  border-bottom: 0px !important;
  color: #303031 !important;
}

.selectCustomer {
  width: 93%;
  height: 60px;
  box-shadow: -2.5px 0.5px 3px 0 rgba(0, 0, 0, 0.16);
  z-index: 9;
  position: absolute;
  background-color: #ffff !important;
  top: 84px;
}

.ribbon-clip.ribbon-reverse {
  right: -14px;
  left: auto;
}

.ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 150px;
  height: 150px;
  text-align: center;
  background-color: transparent;
}

.ribbon-clip.ribbon-reverse .ribbon-inner {
  padding-right: 23px;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
  background-image: -webkit-gradient(
      linear,
      0% 30%,
      50% top,
      color-stop(0%, rgba(204, 99, 40, 0)),
      color-stop(40%, rgba(204, 99, 40, 0)),
      color-stop(90%, rgba(204, 99, 40, 0.3)),
      color-stop(100%, rgba(204, 99, 40, 0))
    ),
    -webkit-gradient(linear, 50% 30%, 100% top, color-stop(0%, rgba(204, 99, 40, 0)), color-stop(25%, rgba(204, 99, 40, 0.3)), color-stop(50%, rgba(204, 99, 40, 0)), color-stop(80%, rgba(204, 99, 40, 0.3)), color-stop(100%, rgba(204, 99, 40, 0)));
  background-size: 200%;
  background-position: right;
  -webkit-animation-name: flagwave;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes flagwave {
  0% {
    background-position: -400px 0px, -400px 0px, -400px 0px, -400px 0px;
  }

  100% {
    background-position: 800px 0px, 800px 0px, 800px 0px, 800px 0px;
  }
}

.ribbon-primary .ribbon-inner {
  background-color: rgba(232, 40, 40, 0.95);
}

.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}

.ribbon-inner {
  position: absolute;
  top: 16px;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 27px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: hidden;
  line-height: 27px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #616161;
}

.ribbon-primary.ribbon-clip.ribbon-reverse:before {
  border-right-color: transparent;
  border-left-color: rgba(232, 40, 40, 0.95);
}

.ribbon-primary.ribbon-clip:before {
  border-top-color: rgba(232, 40, 40, 0.95);
  border-right-color: rgba(232, 40, 40, 0.95);
}

.ribbon-clip.ribbon-reverse:before {
  right: 0;
  left: auto;
  border-right-color: transparent;
  border-left-color: #424242;
}

.ribbon-clip:before {
  position: absolute;
  top: 43px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 7px solid transparent;
  border-top-color: #424242;
  border-right-color: #424242;
}

.items-mansory .card-title {
  font-weight: bold;
  color: #00448b;
  font-size: 14px;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.el-select.elselect-variant {
  width: initial !important;
}

.btn-checkout {
  border-radius: 23px;
  background-image: linear-gradient(to bottom, #ffb333, #e29005);
  color: #303031;
  padding: 0px 10px;
  margin: 0px;
  font-size: 13px !important;
  font-weight: 600;
  height: 3.2rem;
  line-height: 2.6rem;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.btn-checkout i {
  color: #303031;
  font-size: 19px !important;
  font-weight: 600;
  padding-right: 6px;
}

.el-checkbox__label {
  display: inline-block;
  padding-left: 5px;
  line-height: 19px;
  font-size: 12px !important;
}

.card.items-mansory:hover {
  box-shadow: 0 0 4px #b6b6b6 !important;
  z-index: 1;
  border-radius: 7px;
}

.btn-variants .btn {
  background-color: transparent;
  border-bottom: 0px solid #eee;
  text-transform: capitalize;
  padding: 0px;
}

.border-variant {
  border-top: 1px solid #eeeeee;
  padding-top: 8px;
  padding-bottom: 1px;
}

.vselectcategory {
  position: relative !important;
  display: block !important;
}

.add-customer-order .dropdown-toggle.clearfix {
  /* border-radius: 50px!important; */
  background-color: #f7f7f7;
  border-bottom: 0px !important;
}

.add-customer-order .dropdown-toggle.clearfix input {
  padding-left: 15px !important;
}

#takeorderdiv .card-title {
  background-color: transparent !important;
}

.qtyinput {
  border-radius: 4px;
  background-color: #ecf3ff;
  border: 1px solid #e6e6e6 !important;
  color: #00448b;
  text-align: center;
  font-weight: 600;
  height: 30px;
  width: 74%;
  padding-top: 0px;
}
.img-add-button-svg {
  cursor: pointer;
}
.masonry-img-items {
  width: 192px !important;
  height: 128px !important;
}

.label-header {
  font-weight: 600;
  color: #00448b;
  font-size: 13px;
}

#takeorderdiv .search {
  opacity: 0.74;
  border-radius: 50px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f2f2f2 !important;
  height: 34px !important;
  line-height: 20px !important;
}

#takeorderdiv .search input {
  background-color: #f2f2f2 !important;
}

.v-select.vselect-category {
  height: 34px !important;
  line-height: 20px !important;
  border-radius: 50px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f2f2f2;
}

.v-select.vselect-category .dropdown-toggle {
  border: 0px solid rgba(60, 60, 60, 0.26) !important;
  border-bottom: solid 0px #cdd2da !important;
  border-radius: 0px !important;
  white-space: normal !important;
}

.v-select.vselect-category ul.dropdown-menu {
  background-color: #fff !important;
}

.vselect-category .form-control {
  padding: 10px !important;
}

.v-select.vselect-category .selected-tag {
  padding-left: 10px !important;
  padding-top: 2px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.table.table-order td {
  padding: 6px !important;
}

.table.table-order tr.add-customer-tr {
  border-bottom: 1px solid #00448b !important;
  border-top: 1px solid #00448b !important;
  cursor: pointer;
}

@media (max-width: 1030px) {
  .span-order {
    display: none;
  }
}

.span-order {
  color: #00448b;
  font-weight: 600;
  padding-right: 12px;
}

.span-add-customer {
  color: #00448b;
  font-weight: 600;
}

.span-cart-empty {
  font-weight: 600;
  color: #303031;
}

.heading-category {
  font-weight: bold;
  color: #303031;
  font-size: 14px;
}

.lightYellow {
  color: #f5a623;
  font-size: 20px;
}

.btn-yellow {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  box-shadow: 0.5px 1px 1.5px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffa104;
  color: #fff;
  text-transform: capitalize;
  height: 28px;
  line-height: 26px;
  font-weight: 600;
}

.btn-yellow.addbtn {
  width: 100% !important;
  float: right;
}

/* .card.items-mansory{
    color: red;
} */
.snackbar {
  font-family: 'Lato', 'Helvetica Neue', sans-serif !important;
  font-size: 14px;
  color: #fff;
  text-align: left;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
}

.snackbar[class*='bottom'] {
  z-index: 999999 !important;
  background-color: #00448b !important;
  width: 50%;
  top: 10.5rem;
  bottom: inherit;
}

.notification-cart .badge-for-cart {
  padding: 1px 4px !important;
  font-size: 10px;
  border-radius: 100px;
  background: red;
  left: -11px;
  top: -7px;
  position: relative;
  color: #fff;
  font-weight: 600;
}

.total-take-order {
  font-weight: 600;
}

.total-amount {
  color: #00448b;
  padding-left: 8px;
}

#takeorderdiv .loading-click {
  height: 68% !important;
}
.paidBill .el-switch__core {
  border: 1px solid #ff2424 !important;
  background: #ff2424 !important;
}
.paidBill.el-switch.is-checked .el-switch__core {
  border-color: #1a9347 !important;
  background-color: #1a9347 !important;
}
.categoryselected{
  background: #005dae;
  border-radius: 12px;
  cursor: pointer;
}
.categoryunselected{
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
}
.categoryLayout{
  background:#f3f7fa;
}
.borderBottom{
  border-bottom: 1px solid #eeeeee;
}
.items-course{
    background: #00448b!important;
    border-radius: 50%!important;
    padding: 6px;
    border: 1px solid #00448b!important;
}
.items-course a{
    color: #fff!important;
}
.whiteSpace{
  white-space:  nowrap !important;
}
.pointerEventsNone{
  pointer-events: none !important;
}
.padding-top-responsiveness {
    padding-top: 140px;
}
.border-right-responsive {
    border-right: 4px solid #c0c4cc;
    border-bottom: 0;
}
.img-un{
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
@media screen and (max-width: 991px) {
  .padding-top-responsiveness {
    padding-top: 300px;
  }
  .border-right-responsive {
    border-right: 0;
    border-bottom: 4px solid #c0c4cc;
}
}
#lazy-load-checkout-product {
    /* height: 500px;
    overflow-y: auto;
    overflow-x: hidden; */
    margin-bottom: 30px;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
}

/* #lazy-load-checkout-product::-webkit-scrollbar {
    width: 0px;
    height: 0px;
} */
</style>
