<template>
    <div class="page-layout blank mt-6">
		<div class="pb-10">
			<div class="page-layout carded left-sidebar">
				<div class="page-content-wrapper">
					<div class="page-content page-blue px-4">
                        <div class="align-items-center">
                            <div class="row home-new reducewidth" v-if="loggedInUser.actions && loggedInUser.actions.home">
                                <div class="col-lg-7 col-sm-12 col-md-12">
                                    <div class="venue-details-dashboard">
                                        <div class="p-1 align-items-center">
                                            <p>
                                                Venue (Outlet) : {{details.outlet_name || '-'}}
                                            </p>
                                            <p style="border-top: 1px solid #d2d6da!important;">
                                                Location :  {{details.location || '-'}}
                                            </p>
                                            <p v-if="details.is_cashier_session_active" style="border-top: 1px solid #d2d6da!important;">
                                                Cashier Start Date & Time :  {{session_start_date || '-'}} {{session_start_time || '-'}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div class="div-dog" v-if="show">
                                        <div class="widget widget2 text-auto text-white">
                                            <div class="d-flex justify-content-between" style="padding-top: 25px;">
                                                <div class="col no-gutters align-items-center">
                                                    <carousel :autoplay="true" :nav="false" :dots="false" :autoWidth="true" :loop="true" :autoplayTimeout="60000" :autoplayHoverPause="true" :items="1"  style="height: 105px;margin:0 auto;" class="caurosel-div-height">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/snow_monkey.svg" class="img-slider-home">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/elephant_mahout.svg" class="img-slider-home" >
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/cow_boy.svg" class="img-slider-home">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/camels_oilfieds.svg" class="img-slider-home">
                                                    </carousel>
                                                </div>
                                                <div class="col no-gutters dog-middle-div align-items-center text-center" style="border-right: 1px solid #fff;border-left: 1px solid #fff;flex: 0 0 35.66667%!important;max-width: 35.66667%!important;">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/tango.svg" class="text-center dog-img">
                                                </div>
                                                 <div class="col no-gutters align-items-center" style="text-align: -webkit-right!important;">
                                                    <carousel :autoplay="true" :nav="false" :dots="false" :autoWidth="true" :loop="true" :autoplayTimeout="59990" :autoplayHoverPause="true" :items="1"  style="height: 105px;margin:0 auto;" class="caurosel-div-height">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/elephant_mahout.svg" class="img-slider-home">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/cow_boy.svg" class="img-slider-home">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/camels_oilfieds.svg" class="img-slider-home">
                                                        <img style="width: 200px;border-radius: 10px;border: 2px solid #fff;" src="https://cdn.digicollect.com/cdn/posv2/images/gallery_images_new/snow_monkey.svg" class="img-slider-home">
                                                    </carousel>
                                                </div>

                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="div-widget" v-if="!show">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="d-flex justify-content-between flex-wrap">
                                                   <div class="image-home-left-new" v-if="loggedInUser.actions && loggedInUser.actions.home">
                                                        <div class="img-products-dashboard align-items-center">
                                                            <span>
                                                                <img class="w145 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/food-home-new.svg">
                                                            </span>
                                                             <div style="position: relative;font-weight: 600;text-align: center;margin-top: -36px;">{{details.food_sales}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="image-home-left-new" v-if="loggedInUser.actions && loggedInUser.actions.home">
                                                        <div class="img-products-dashboard align-items-center">
                                                            <span>
                                                                <img class="w162 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/bevarage-home-new.svg">
                                                            </span>
                                                             <div style="position: relative;font-weight: 600;text-align: center;margin-top: -36px;">{{details.beverage_sales}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="image-home-left-new" v-if="loggedInUser.actions && loggedInUser.actions.home">
                                                        <div class="img-products-dashboard align-items-center">
                                                            <span>
                                                                <img class="w162 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/sales-home-top-new.svg">
                                                            </span>
                                                             <div style="position: relative;font-weight: 600;text-align: center;margin-top: -36px;">{{details.other_sales}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="image-home-left-new col" style="background-color: #d2e1f1;border-radius: 8px;padding: 2px;" v-if="loggedInUser.actions && loggedInUser.actions.home">
                                                        <div class="heading-head p-1">
                                                            <img class="product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cash-home-new.svg" style="height: 20px;width:20px;margin:0 5px;">
                                                            Cash : {{details.cash}}
                                                        </div>
                                                        <div class="heading-head p-1" style="border-top: 1px solid #d2d6da!important;">
                                                            <img class="product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/master-card-home-new.svg" style="height: 20px;width:20px;margin:0 5px;">
                                                            Card : {{details.card}}
                                                        </div>
                                                        <div class="heading-head p-1" style="border-top: 1px solid #d2d6da!important;">
                                                            <img class="product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/shopping-cart-home-new.svg" style="height: 20px;width:20px;margin:0 5px;">
                                                                Delivery Company : {{details.sales_info.delivery_sales}}
                                                        </div>
                                                        <div class="heading-head p-1" style="border-top: 1px solid #d2d6da!important;">
                                                            <img class="product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/hanhome-new.svg" style="height: 20px;width:20px;margin:0 5px;">
                                                            Others : {{details.others}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="loggedInUser.role == 'default_admin' ||loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker' && this.details.outlet_preferences && this.details.outlet_preferences.can_waiter_see_sales_data">
                                        <hr style="border-top:1px solid #fff!important;margin-top: 23px;">
                                        <router-link to="/reportsDashboard" class="btn Paid-ex-Tax btn-smm" style="left: 40%;position:relative;margin-top: -30px;border-radius:8px !important;" v-if="!show">Paid ex Tax</router-link>
                                        <span @click="show = !show">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg" style="cursor:pointer;margin-top: -20px;" class="pull-right" v-if="show">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg" style="cursor:pointer;margin-top: -20px;transform:rotate(180deg)" class="pull-right" v-else>
                                        </span>
                                    </div>
                                    <div class="row pl-3">
                                        <!-- <div class="col-md-6 col-sm-6" :class="{'col-xl-6 col-lg-6 pr-0':!loggedInUser.is_cashier,'col-xl-4 col-lg-6':loggedInUser.is_cashier}">
                                            <button class="btn btn-reserve-table btn-sm ml-0" style="cursor: default;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/reserve_table.svg" style="width: 30px;" class="pl-2 pr-2">  Reserve a Table
                                            </button>
                                        </div> -->
                                        <div class="col-md-6 col-sm-12" :class="{'col-xl-4 col-lg-6 pr-0':!loggedInUser.is_cashier,'col-xl-4 col-lg-6':loggedInUser.is_cashier}">
                                            <button class="btn btn-reserve-table btn-sm ml-0" style="cursor: default;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/tables-ava-home-new.svg" style="width: 30px;" class="pr-2">
                                                Tables Available <span class="pull-right">{{details.tables_available}}</span>
                                            </button>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" v-if="show_online_session">
                                            <button class="btn btn-cashier-start btn-sm ml-0" @click="StartOnlineSessionBtn()" v-if="!online_session_status">
                                                <img src="/static/images/start_stop_online_session.svg" style="width: 30px;" class="pr-2">  Start Online Session
                                            </button>
                                            <button class="btn btn-cashier-end btn-sm ml-0" @click="StopOnlineSessionBtn()" v-else>
                                                <img src="/static/images/start_stop_online_session.svg" style="width: 30px;" class="pr-2">  Stop Online Session
                                            </button>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" v-if="loggedInUser.is_cashier" :class="{'pr-6' : this.loggedInUser.role == 'default_order_taker'}">
                                            <button class="btn btn-cashier-start btn-sm ml-0" @click="StartSessionBtn()" v-if="!viewCashierBtn">
                                                <img src="https://cdn.digicollect.com/cdn/posv2/images/dashboardicons/startcashier.svg" style="width: 30px;" class="pr-2">  Start Cashier Session
                                            </button>
                                            <button class="btn btn-cashier-end btn-sm ml-0" @click="StopSessionBtn()" v-else>
                                                <img src="https://cdn.digicollect.com/cdn/posv2/images/dashboardicons/stopcashier.svg" style="width: 30px;" class="pr-2">  Stop Cashier Session
                                            </button>
                                        </div>
                                    </div>
                                    <div class="boxes-home">
                                        <div class="justify-content-between d-flex flex-wrap">
                                            <!-- <div class="image-home-left-new" @click="reportsDash()" style="cursor:pointer;"> -->
                                            <div class="image-home-left-new" >
                                                <div class="img-products-dashboard align-items-center">
                                                    <a>
                                                        <img class="w162 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/pax-home-new.svg" style="height: 100px;">
                                                    </a>
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -26px;">{{details.pax}}</div>
                                                </div>
                                                <div class="mt-3 text-white text-center font-600">Guest Numbers (Covers)</div>
                                            </div>
                                            <div class="image-home-left-new">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link to="/closedorders" >
                                                        <img class="w162 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/order-home-new.svg" style="height: 100px;">
                                                    </router-link>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -26px;padding-bottom:8px;">{{details.bills}}</div>
                                                </div>
                                                <div class="mt-1 text-white text-center font-600">Order History</div>
                                            </div>
                                            <div class="image-home-left-new">
                                                <div class="img-products-dashboard align-items-center" >
                                                    <router-link :to="{ name: 'ManageOrder', params: { orderType: 'All Orders' }}" >
                                                        <img class="w162 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/manage_order_home_new.svg" style="height: 100px;">
                                                    </router-link>
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -26px;">{{details.pending_orders}}</div>
                                                </div>
                                                <div class="mt-3 text-white text-center font-600">Manage Orders</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="boxes-home pb-5" v-if="loggedInUser.role == 'default_admin' ||loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker' && this.details.outlet_preferences && this.details.outlet_preferences.can_waiter_see_sales_data">
                                        <div class="infoHeader">
                                            <div class="descriptionheader">
                                                <div class="description-text-header-left pl-2">
                                                    <h5 class="font-weight-bold text-white whiteSpace">Sales Information</h5>
                                                </div>
                                                <div class="description-text-header-right">
                                                    <span @click="showsales = !showsales">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg" style="cursor:pointer;bottom:8px !important;" class="pull-right" v-if="showsales">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg" style="cursor:pointer;bottom:8px !important;transform:rotate(180deg)" class="pull-right" v-else>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex" v-if="showsales">
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/order-discount-home-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.discounts}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Orders Discount</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/void-home-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.voids}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Voids</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/enter-home-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.entertainment}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Entertainment</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/dine-in-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.dine_in_sales}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Dine-In-Sales</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/deliver-sales-home-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.delivery_sales}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Delivery Sales</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <span>
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/take-away-home-new.svg" style="height: 80px;">
                                                    </span>
                                                    <div style="position: relative;font-weight: 600;text-align: center;margin-top: -25px;height: 30px;font-size: 11px;">{{details.sales_info.take_away_sales}}</div>
                                                </div>
                                                <div class="text-white text-center font-600">Take Away Sales</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-sm-12 col-md-12">
                                    <div class="class-div-right">
                                        <div class="row" :class="{'borderBottom':loggedInUser.role !='default_order_taker' && loggedInUser.role != 'default_manager'}" v-if="loggedInUser.role != 'default_manager' && loggedInUser.role != 'default_order_taker'">
                                             <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link to="/customermanage">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers-home.svg" style="height: 80px;">
                                                    </router-link>
                                                </div>
                                                <div class="mt-1 text-white text-center font-600">Customers</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link to="/employeemanage">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/employee-dash.svg" style="height: 80px;">
                                                    </router-link>
                                                </div>
                                                <div class="mt-1 text-white text-center font-600">Employee</div>
                                            </div>
                                            <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <a href="https://scm.digicollect.com/inventory/products/table" target="_blank">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/products-new.svg" style="height: 80px;">
                                                    </a>
                                                </div>
                                                <div class="mt-1 text-white text-center font-600">Products</div>
                                            </div>
                                             <div class="image-div-home">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link to="/outletmanage">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/outlets-dash.svg" style="height: 80px;">
                                                    </router-link>
                                                </div>
                                                <div class="mt-1 text-white text-center font-600">Outlets</div>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="text-white w-100 font-weight-bold" :class="{'pt-3':loggedInUser.role !='default_order_taker' && loggedInUser.role != 'default_manager'}">New Orders</p>
                                        </div>
                                        <div class="justify-content-between d-flex flex-wrap" :class="{'pt-2 pb-1':loggedInUser.role !='default_order_taker' && loggedInUser.role != 'default_manager'}">
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center pointer" @click="newOrderDineIn">
                                                    <!-- <router-link to="/tableselection" > -->
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/new-order.svg" style="height: 100px;">
                                                    <!-- </router-link> -->
                                                </div>
                                                <div class="text-white text-center font-600">Dine In</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center pointer" @click="newOrderTakeAway">
                                                    <!-- <router-link :to="{ name: 'TableSelection', params: { orderType: 'Take Away' }}"> -->
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/takeaway_new_home.svg" style="height: 100px;">
                                                    <!-- </router-link> -->
                                                </div>
                                                <div class="text-white text-center font-600">Take Away</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center pointer" @click="newOrderDeliveries">
                                                    <!-- <router-link :to="{ name: 'TableSelection', params: { orderType: 'Deliveries' }}"> -->
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/truck_new_home.svg" style="height: 100px;">
                                                    <!-- </router-link> -->
                                                </div>
                                                <div class="text-white text-center font-600">Deliveries</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center pointer" @click="newOrderOnline">
                                                    <!-- <router-link :to="{ name: 'TableSelection', params: { orderType: 'Online' }}"> -->
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/online_new_home.svg" style="height: 100px;">
                                                    <!-- </router-link> -->
                                                </div>
                                                <div class="text-white text-center font-600">Online</div>
                                            </div>
                                        </div>
                                        <div style="border-top: 1px solid #eeeeee2e;">
                                            <p class="text-white w-100 pt-3 font-weight-bold">Manage Orders</p>
                                        </div>
                                        <div class="justify-content-between d-flex flex-wrap" :class="{'pt-2 pb-1':loggedInUser.role !='default_order_taker' && loggedInUser.role != 'default_manager'}">
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link  to="/orderTableView" class="tDeco">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/posv2/images/new-order.svg" style="height: 100px;">
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -64px;height: 70px;font-size: 11px;">{{details.dine_in_count}}</div>
                                                    </router-link>
                                                </div>
                                                <div class="text-white text-center font-600">Dine In</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link  :to="{ name: 'ManageOrder', params: { orderType: 'Take Away' }}" class="tDeco">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/posv2/images/takeaway_new_home.svg" style="height: 100px;">
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -64px;height: 70px;font-size: 11px;">{{details.take_away}}</div>
                                                    </router-link>
                                                </div>
                                                <div class="text-white text-center font-600">Take Away</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link  :to="{ name: 'ManageOrder', params: { orderType: 'Deliveries' }}" class="tDeco">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/truck_new_home.svg" style="height: 100px;">
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -64px;height: 70px;font-size: 11px;">{{details.on_deliveries}}</div>
                                                    </router-link>
                                                </div>
                                                <div class="text-white text-center font-600">Deliveries</div>
                                            </div>
                                            <div class="image-home-left-new pr-0">
                                                <div class="img-products-dashboard align-items-center">
                                                    <router-link  :to="{ name: 'ManageOrder', params: { orderType: 'Online' }}" class="tDeco">
                                                        <img class="w-100 product-image" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/online_new_home.svg" style="height: 100px;">
                                                        <div style="position: relative;font-weight: 600;text-align: center;margin-top: -64px;height: 70px;font-size: 11px;">{{details.online_count || 0}}</div>
                                                    </router-link>
                                                </div>
                                                <div class="text-white text-center font-600">Online</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <start-session modal_name="show-session"></start-session>
        <end-session modal_name="show-stop-session" :cashierSessionData='cashierSessionData'></end-session>
        <cashier-session-not-active-popup-message-for-new-order @startCashierSessionFromPopup="startCashierSessionFromPopup" @cashierSessionNotActivePopupMessageForNewOrder="cashierSessionNotActivePopupMessageForNewOrder" modal_name="cashier-session-not-active-popup-message-for-new-order"></cashier-session-not-active-popup-message-for-new-order>
        <sweet-modal ref="success_modal" icon="success">{{modal_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{modal_msg}}</sweet-modal>
    </div>
</template>
<script>
import VueRouter from 'vue-router'
import { EventBus } from "./eventBus/nav-bar-event.js";
import carousel from 'vue-owl-carousel'
import CreateCustomer from "./CreateCustomer"
import DashboardServices from '@/components/mixins/dashboard.js'
import StartSession from "./StartSession";
import EndSession from "./EndSession";
const CashierSessionNotActivePopupMessageForNewOrder = () => import("./CashierSessionNotActivePopupMessageForNewOrder")
import moment from 'moment-timezone';
import cashier from './mixins/cashier.js';
import { SweetModal } from 'sweet-modal-vue'

export default {
    data() {
        return {
            leftimages: [],
            modal_msg: '',
            rightimages: [],
            viewCashierBtn: false,
	        cashierSessionData:{
                sales:{
                    total:null,
                    cash:'',
                    card:'',
                    others:''
                },
                drawer_in_amount:null,
                cash_received:null,
                drawer_out_amount:null
            },
            show:true,
            showsales:false,
            details: {
                cash: null,
                card: null,
                food_panda: null,
                others: null,
                outlet_name: null,
                location: null,
                start_time: null,
                tables_available: 0,
                pending_orders: 0,
                bills: 0,
                on_deliveries: 0,
                take_away: 0,
                pax: 0,
                food_sales: null,
                beverage_sales: null,
                sales_info: {
                    discounts: null,
                    voids: null,
                    entertainment: null,
                    dine_in_sales: null,
                    delivery_sales: null,
                    take_away_sales: null
                },
                is_cashier_session_not_active_popup_message_for_new_order: false
            },
            session_start_date: '',
            session_start_time: '',
            show_online_session: false,
            online_session_status: false,
            intervalID: null
        }
    },
    components:  {
        StartSession,
        EndSession,
        carousel,
        VueRouter,
        CashierSessionNotActivePopupMessageForNewOrder,
        SweetModal
    },
    mixins: [DashboardServices, cashier],
    methods: {
        /**
         * This method or function is used to start the online session
         * @param {online_payment_status} - online_payment_status is the Boolean
         * @returns {response} - gets the Result
         */
        async StartOnlineSessionBtn() {
            try {
                let params = {
                    online_payment_status: true
                };
                let response = await this.startOnlineSession(params);
                this.modal_msg = 'Successfully Started Online Session.'
                this.$refs.success_modal.open()
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.isCashierSessionActive();
                    EventBus.$emit('update_user_pin_settings');
                }, 2000);
            }
            catch(err) {
                console.log(err);
            }
        },
        /**
         * This method or function is used to stop the online session
         * @param {online_payment_status} - online_payment_status is the Boolean
         * @returns {response} - gets the Result
         */
        async StopOnlineSessionBtn() {
            try {
                let params = {
                    online_payment_status: false
                };
                let response = await this.endOnlineSession(params);
                this.modal_msg = 'Successfully Stopped Online Session.'
                this.$refs.success_modal.open()
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.isCashierSessionActive();
                    EventBus.$emit('update_user_pin_settings');
                }, 2000);
            }
            catch(err) {
                console.log(err);
            }
        },
        moment(date) {
            return moment(date);
        },
        cashierSessionNotActivePopupMessageForNewOrder() {
            this.is_cashier_session_not_active_popup_message_for_new_order = false;
            setTimeout(() => {
                this.$modal.hide('cashier-session-not-active-popup-message-for-new-order')
            }, 500);
        },
        startCashierSessionFromPopup() {
            this.$modal.show("show-session");
        },
        newOrderDineIn() {
            if(this.loggedInUser.is_cashier && this.viewCashierBtn) {
                this.$router.push('/tableselection')
            } else {
                this.is_cashier_session_not_active_popup_message_for_new_order = true;
                setTimeout(() => {
                    this.$modal.show('cashier-session-not-active-popup-message-for-new-order')
                }, 500);
            }
        },
        newOrderTakeAway() {
            if(this.loggedInUser.is_cashier && this.viewCashierBtn) {
                this.$router.push({
                    name: 'TableSelection', params: { orderType: 'Take Away' }
                })
            } else {
                this.is_cashier_session_not_active_popup_message_for_new_order = true;
                setTimeout(() => {
                    this.$modal.show('cashier-session-not-active-popup-message-for-new-order')
                }, 500);
            }
        },
        newOrderDeliveries() {
            if(this.loggedInUser.is_cashier && this.viewCashierBtn) {
                this.$router.push({ name: 'TableSelection', params: { orderType: 'Deliveries' }})
            } else {
                this.is_cashier_session_not_active_popup_message_for_new_order = true;
                setTimeout(() => {
                    this.$modal.show('cashier-session-not-active-popup-message-for-new-order')
                }, 500);
            }
        },
        newOrderOnline() {
            if(this.loggedInUser.is_cashier && this.viewCashierBtn) {
                this.$router.push({ name: 'TableSelection', params: { orderType: 'Online' }})
            } else {
                this.is_cashier_session_not_active_popup_message_for_new_order = true;
                setTimeout(() => {
                    this.$modal.show('cashier-session-not-active-popup-message-for-new-order')
                }, 500);
            }
        },
        billsDash(){
            this.$router.push({
                name: "ClosedOrders",

            });
        },
        tablesAvail(){
            this.$router.push({
                name: "TableSelection",

            });
        },
        pendingOrders(){
            this.$router.push({
                name: "ManageOrder",

            });
        },
        deliveriesDash(){
            this.$router.push({
                name: "ManageOrder",

            });
        },
        takeAwayDash(){
            this.$router.push({
                name: "ManageOrder",

            });
        },
         reportsDash(){
            this.$router.push({
                name: "ReportsDashboard",

            });
        },
        /**
         * This method or function is used to fetch the dashboard details data
         * @returns {response} - gets the Result
         */
       async loadDashboard() {
           try {
               let response = await this.getDashboard({})
               this.details = response
               let start_date = this.details.start_time.split(',')[0]
               start_date.split(' ')[0]
               const d = new Date(`${start_date.split(' ')[1]} ${start_date.split(' ')[0]}, ${start_date.split(' ')[2]} 00:00:00`);
               this.session_start_date = this.moment(d).format('L');
               this.session_start_time = this.details.start_time.split(',')[1]
           } catch (reason) {
               console.log(reason)
           }
       },
       /**
         * This method or function is used to call the loggedin user api
         * @returns {response} - gets the Result
         */
       updateConfigurePaymentDeviceKey() {
            this.$http.get("/get_loggedin_user").then(response => {
                if (response.data.status_id == 1) {
                    let userDetails = response.data.user_details
                    let newLoggedInUser = {
                        access_change_discount: userDetails.access_change_discount,
                        access_remove_item: userDetails.access_remove_item,
                        auto_logout: userDetails.auto_logout,
                        auto_logout_mins: userDetails.auto_logout_mins,
                        hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                        show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                        print_receipt_by_default: userDetails.print_receipt_by_default || false,
                        uid: userDetails.user_id,
                        full_name: userDetails.full_name,
                        email: userDetails.email,
                        profile_pic: userDetails.profile_pic,
                        actions: userDetails.actions,
                        is_setup_done: userDetails.is_setup_done,
                        isAdmin: userDetails.user_role === 'default_admin',
                        is_authorized: userDetails.is_authorized,
                        is_loaded: true,
                        has_order: userDetails.has_order,
                        outlet: userDetails.outlet,
                        business_day_start_time:
                        userDetails.business_day_start_time,
                        role: userDetails.user_role,
                        outlet_type: userDetails.outlet_type,
                        pin: userDetails.pin,
                        is_cashier: userDetails.is_cashier,
                        cashier_session: userDetails.cashier_session || null,
                        service_type: userDetails.service_type || null,
                        enable_fast_checkout: userDetails.enable_fast_checkout,
                        enable_double_receipts: userDetails.enable_double_receipts,
                        enable_course_firing: userDetails.enable_course_firing,
                        default_deliver_together: userDetails.default_deliver_together,
                        outlet_id: userDetails.outlet_id,
                        configure_payment_device:userDetails.configure_payment_device,
                    }
                    console.log(newLoggedInUser)
                    this.$store.commit('setLoggedInUser', newLoggedInUser)
                }
                else{

                }
            }).catch((error) => {
                
            })
       },

       StartSessionBtn () {
        this.$modal.show("show-session");
       },
       cancelSession () {
        this.$modal.hide("show-session");
        },
        StopSessionBtn () {
            this.getCashierSessionDetails()
            this.$modal.show("show-stop-session")
        },
        /**
         * This method or function is used to get the cashier session details
         * @returns {response} - gets the Result
         */
        getCashierSessionDetails () {
        this.$http.get("/cashier/get_drawer_out_summary")
            .then(res => {
            if (res.data.status_id == 1) {
                this.cashierSessionData = res.data;
            }
            })
        },
        cancelStopSession () {
            this.$modal.hide("show-stop-session");
        },
        /**
         * This method or function is used to call the cashier session api to fetch latest cashier session details
         * @returns {response} - gets the Result
         */
        isCashierSessionActive(){
		this.$http.get("/cashier/is_cashier_session_active").then(res =>{
            if(res.data.status_id) {
                this.viewCashierBtn = res.data.response.show_stop_session
                this.show_online_session = res.data.response.show_online_session
                this.online_session_status = res.data.response.online_session_status
            } else {
                this.viewCashierBtn = false
            }
		})
    },
    },
    created(){
        if (this.loggedInUser.role == "default_order_receiver") {
            this.$router.push("manageorder")
        }

        // if(this.$cookie.get('Token') && !this.loggedInUser.isAdmin) {
        //     this.loadDashboard();
        //     this.isCashierSessionActive();
        // }
        EventBus.$emit("customerdetailsLoad", false)
        EventBus.$emit("settingsLoad", false)
        EventBus.$emit("reportsLoad", false)
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    mounted(){
        // if (this.$route.path == '/home') {
        //     history.pushState(null, null, location.href);
        //     window.onpopstate = function () {
        //         history.go(1);
        //     };
        // }

        this.intervalID = setInterval(()=> {
            this.loadDashboard();
            this.updateConfigurePaymentDeviceKey();
        }, 15000);

        EventBus.$on('updateHomeScreendata', () => {
            this.isCashierSessionActive()
            this.loadDashboard();
        })
       EventBus.$on('updateHomeScreen', () => {
           this.isCashierSessionActive();
          this.loadDashboard();

        });
        this.isCashierSessionActive();
        this.loadDashboard();

        $("#leftslideshow > div:gt(0)").hide();
        setInterval(function() {
        $('#leftslideshow > div:first')
            .fadeOut(2000)
            .next()
            .fadeIn(2000)
            .end()
            .appendTo('#leftslideshow')
        }, 60000);

        $("#rightslideshow > div:gt(0)").hide();
        setInterval(function() {
        $('#rightslideshow > div:first')
            .fadeOut(2000)
            .next()
            .fadeIn(2000)
            .end()
            .appendTo('#rightslideshow')
        }, 60000);
    },
    beforeDestroy() {
        clearInterval(this.intervalID);
    },
    watch: {
    loggedInUser() {
      if (this.loggedInUser.role == "default_order_receiver") {
        this.$router.push("manageorder")
      }
       if (this.loggedInUser.actions.home && !this.loggedInUser.outlet) {
        this.$router.push('/customermanage')
        const router = new VueRouter({ linkActiveClass: 'active' });
        EventBus.$emit("customerdetailsLoad", true)
        EventBus.$emit("settingsLoad", false)
        EventBus.$emit("reportsLoad", false)
      }
    },
  },
}
</script>
<style scoped>
/* .home-new{
    border-radius: 40px;
    background-image: linear-gradient(114deg, #005dae 0%, #0081ef 50%, #004fe3 100%);
    margin-top: 20px;
} */
.page-content.page-blue {
    background-image: linear-gradient(114deg, #00448b 30%, #00448b 50%, #005dae 70%);
}

.btn-disabled-dash{
    pointer-events: none;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
/* .div-dog{
    border-radius: 6px;
    box-shadow: 0 1px 16.5px 0 rgba(0, 0, 0, 0.23);
    background-image: linear-gradient(100deg, #005dae -12%, #004fe3 88%);
    margin-top: 22px;
    border-radius: 18px;
} */
.dog-img{
    margin: 0 auto;
    border: 2px solid #fff;
    border-radius: 8px;
}
.div-dog{
    margin-bottom: 30px;
}
.image-home-left-new{
    display:inline-block;
    margin-bottom: 10px;
    padding-right: 10px;
}
@media (max-width:520px){
    .image-home-left-new{
        padding-right: 0px !important;
    }
}
.sales-dashboard{
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.Paid-ex-Tax{
    border-radius: 6.5px;
    border: solid 0.6px #00ffeb;
    background-image: linear-gradient(to bottom, #ffa62e, #ea4d2c);
    color:#fff!important;
}
.venue-details-dashboard{
    opacity: 0.95;
    box-shadow: 0 1.5px 16.5px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px rgba(255, 255, 255, 0.39);
    text-align: center;
    color: #303031;
    font-weight: 600;
    margin-top: 22px;
    border-radius: 18px;
    font-size:14px;
    background-color: #dee8f2;
}
.btn-order-history, .btn-take-order, .btn-manage-order, .btn-delivery-dashboard, .btn-reports-dashboard, .btn-take-away-dashboard{
    color:#fff!important;
    font-weight: 600;
    font-size: 12px!important;
    cursor: pointer;
}
.btn-order-history{
  border-radius: 6.5px;
  border: solid 0.6px #00ffeb;
  background-image: linear-gradient(to bottom, #ac5fcf, #4b2fb7);
  padding: 0px 8px !important;
}
.btn-take-order{
  border-radius: 6.5px;
  border: solid 0.6px #00ffeb;
  background-image: linear-gradient(to bottom, #ff7500, #dc033a);
  padding: 0px 8px !important;
}
.btn-manage-order{
  border-radius: 6.5px;
  border: solid 0.6px #00ffeb;
  background-image: linear-gradient(to bottom, #ffa62e, #ea4d2c);
  padding: 0px 8px !important;
}
.btn-delivery-dashboard{
  border-radius: 6.5px;
  border: solid 0.6px #00ffeb;
  background-image: linear-gradient(to bottom, #ac5fcf, #4b2fb7);
  padding: 0px 8px !important;
}
.btn-take-away-dashboard{
  border-radius: 6.5px;
  border: solid 0.6px #00ffeb;
  background-image: linear-gradient(to bottom, #ff7500, #dc033a);
  padding: 0px 8px !important;
}
.btn-reports-dashboard{
    border-radius: 6.5px;
    border: solid 0.6px #00ffeb;
    background-image: linear-gradient(to bottom, #ffa62e, #ea4d2c);
    padding: 0px 8px !important;
}
.div-widget{
    margin-top: 10px;
    padding-top: 9px;
    padding-bottom: 11px;
}
.heading-head{
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #303031;
  padding-top: 8px;
  width: 100%;
}
.btn-reserve-table{
    height: 38px !important;
    border-radius: 8px !important;
    box-shadow: 0 5px 16.5px 0 rgba(0, 0, 0, 0.06) !important;
    background-image: linear-gradient(114deg, #004fe3 0%, #0081ef 50%, #005dae 90%) !important;
    margin-left: 5px;
    margin-bottom: 23px;
    margin-top: 20px;
    line-height: 38px !important;
    color: #fff !important;
    text-transform: none !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    width: 100%;
    padding: 0 1.6rem 0 1.6rem;
    text-align: left;
    border: 1px solid #fff;
}
.boxes-home .widget-content{
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(140deg, #005dae -9%, #004fe3 95%);
    margin: 10px;
    border-radius: 12px;
}

.fs-16{
    font-size: 16px;
}
.img-bus{
    width: 25px;
    float: right;
    text-align: right;
    margin: 0 auto;
    margin-right: 5px;
}
.boxes-home .sub-title{
    font-size: 11px;
}
.col-hm-1{
    width: 14.4%;
}
.fs-18{
    font-size: 18px;
}
.class-div-right{
    border-radius: 6.5px;
    box-shadow: 0 1px 27.5px 0 rgba(0, 255, 245, 0.47);
    border: solid 1px #00ffeb;
    background-color: #005dae;
    margin-top: 20px;
    padding: 20px 30px 20px 27px;
    margin-bottom: 20px;
}
.btn-cashier-end{
    height: 38px !important;
    border-radius: 8px !important;
    box-shadow: 0 5px 16.5px 0 rgba(0, 0, 0, 0.06) !important;
    background-image: linear-gradient(114deg, #c51a1a 0%, #ff3a3a 50%, #ff3a3a 90%) !important;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 25px;
    margin-top: 20px;
    text-transform: capitalize;
    line-height: 38px !important;
    font-size: 13px !important;
    width: 100%;
    padding: 0 1.6rem 0 1.6rem;
    text-align: left;
}
.btn-cashier-start{
    height: 38px !important;
    border-radius: 8px !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16) !important;
    background-image: linear-gradient(99deg, #0fb74e 30%, #059a3c 48%, #04963a 60%);
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-top: 20px;
    text-transform: capitalize;
    line-height: 38px !important;
    font-size: 13px !important;
    width: 100%;
    padding: 0 1.6rem 0 1.6rem;
    text-align: left;
}
.class-div-right .img-products-dashboard{
    width: 100px;
    line-height: 90px;
    border-radius: 10px;
}
.image-div-home{
    padding: 8px;
}
.caurosel-div-height{
    pointer-events:none!important;
}
@media (max-width: 600px) {
    .reducewidth .col,.col-md-4,.col-md-8{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media (max-width:1200px){
    .img-slider-home{
        width: 130px!important;
    }
    .dog-img{
        width: 130px;
    }
    .caurosel-div-height{
        height: 70px!important;
    }
    .col-5.dog-middle-div{
        flex: 0 0 29.6667% !important;
        max-width: 29.6667% !important;
    }
    .owl-carousel .owl-stage-outer {
        width: 130px!important;
    }
}
.div-widget.upDiv {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}



.uk-grid{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    list-style:none
}
.uk-grid:after,.uk-grid:before{
    content:"";
    display:block;
    overflow:hidden
}
.uk-grid:after{
    clear:both
}
.uk-grid>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    margin:0;
    float:left
}
.uk-grid>*>:last-child{
    margin-bottom:0
}
.uk-grid{
    margin-left:-25px
}
.uk-grid>*{
    padding-left:25px
}
.uk-grid+.uk-grid,.uk-grid-margin,.uk-grid>*>.uk-panel+.uk-panel{
    margin-top:25px
}
@media (min-width:1220px){
    .uk-grid{
        margin-left:-35px
    }
    .uk-grid>*{
        padding-left:35px
    }
    .uk-grid+.uk-grid,.uk-grid-margin,.uk-grid>*>.uk-panel+.uk-panel{
        margin-top:35px
    }
}
.uk-grid-collapse{
    margin-left:0
}
.uk-grid-collapse>*{
    padding-left:0
}
.uk-grid-collapse+.uk-grid-collapse,.uk-grid-collapse>*>.uk-panel+.uk-panel,.uk-grid-collapse>.uk-grid-margin{
    margin-top:0
}
.uk-grid-small{
    margin-left:-10px
}
.uk-grid-small>*{
    padding-left:10px
}
.uk-grid-small+.uk-grid-small,.uk-grid-small>*>.uk-panel+.uk-panel,.uk-grid-small>.uk-grid-margin{
    margin-top:10px
}
.uk-grid-medium{
    margin-left:-25px
}
.uk-grid-medium>*{
    padding-left:25px
}
.uk-grid-medium+.uk-grid-medium,.uk-grid-medium>*>.uk-panel+.uk-panel,.uk-grid-medium>.uk-grid-margin{
    margin-top:25px
}
@media (min-width:960px){
    .uk-grid-large{
        margin-left:-35px
    }
    .uk-grid-large>*{
        padding-left:35px
    }
    .uk-grid-large+.uk-grid-large,.uk-grid-large-margin,.uk-grid-large>*>.uk-panel+.uk-panel{
        margin-top:35px
    }
}
@media (min-width:1220px){
    .uk-grid-large{
        margin-left:-50px
    }
    .uk-grid-large>*{
        padding-left:50px
    }
    .uk-grid-large+.uk-grid-large,.uk-grid-large-margin,.uk-grid-large>*>.uk-panel+.uk-panel{
        margin-top:50px
    }
}
.uk-grid-divider:not(:empty){
    margin-left:-25px;
    margin-right:-25px
}
.uk-grid-divider>*{
    padding-left:25px;
    padding-right:25px
}
.uk-grid-divider>[class*=uk-width-9-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-1-]:not(.uk-width-1-1):nth-child(n+2),.uk-grid-divider>[class*=uk-width-2-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-3-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-4-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-5-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-6-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-7-]:nth-child(n+2),.uk-grid-divider>[class*=uk-width-8-]:nth-child(n+2){
    border-left:1px solid #ddd
}
@media (min-width:768px){
    .uk-grid-divider>[class*=uk-width-medium-]:not(.uk-width-medium-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:960px){
    .uk-grid-divider>[class*=uk-width-large-]:not(.uk-width-large-1-1):nth-child(n+2){
        border-left:1px solid #ddd
    }
}
@media (min-width:1220px){
    .uk-grid-divider:not(:empty){
        margin-left:-35px;
        margin-right:-35px
    }
    .uk-grid-divider>*{
        padding-left:35px;
        padding-right:35px
    }
    .uk-grid-divider:empty{
        margin-top:35px;
        margin-bottom:35px
    }
}
.uk-grid-divider:empty{
    margin-top:25px;
    margin-bottom:25px;
    border-top:1px solid #ddd
}
.uk-grid-match>*{
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap
}
.uk-grid-match>*>*{
    -ms-flex:none;
    -webkit-flex:none;
    flex:none;
    box-sizing:border-box;
    width:100%
}
/* [class*=uk-grid-width]>*{
    box-sizing:border-box;
    width:100%
} */
.uk-grid-width-1-2>*{
    width:50%
}
.uk-grid-width-1-3>*{
    width:33.333%
}
.uk-grid-width-1-4>*{
    width:25%
}
.uk-grid-width-1-5>*{
    width:20%
}
.uk-grid-width-1-6>*{
    width:16.666%
}
.uk-grid-width-1-10>*{
    width:10%
}
.uk-grid-width-auto>*{
    width:auto
}
@media (min-width:480px){
    .uk-grid-width-small-1-1>*{
        width:100%
    }
    .uk-grid-width-small-1-2>*{
        width:50%
    }
    .uk-grid-width-small-1-49>*{
        width:49%
    }
    .uk-grid-width-small-1-3>*{
        width:33.333%
    }
    .uk-grid-width-small-1-4>*{
        width:25%
    }
    .uk-grid-width-small-1-5>*{
        width:20%
    }
    .uk-grid-width-small-1-6>*{
        width:16.666%
    }
    .uk-grid-width-small-1-10>*{
        width:10%
    }
}
@media (min-width:768px){
    .uk-grid-width-medium-1-1>*{
        width:100%
    }
    .uk-grid-width-medium-1-2>*{
        width:50%
    }
    .uk-grid-width-medium-1-3>*{
        width:33.333%
    }
    .uk-grid-width-medium-1-32>*{
        width:32.333%
    }
    .uk-grid-width-medium-1-4>*{
        width:25%
    }
    .uk-grid-width-medium-1-5>*{
        width:20%
    }
    .uk-grid-width-medium-1-6>*{
        width:16.666%
    }
    .uk-grid-width-medium-1-10>*{
        width:10%
    }
}
@media (min-width:960px){
    .uk-grid-width-large-1-1>*{
        width:100%
    }
    .uk-grid-width-large-1-2>*{
        width:50%
    }
    .uk-grid-width-large-1-3>*{
        width:33.333%
    }
    .uk-grid-width-large-1-31>*{
        width:31.5%
    }
    .uk-grid-width-large-1-4>*{
        width:25%
    }
    .uk-grid-width-large-1-24>*{
        width:24%
    }
    .uk-grid-width-large-1-5>*{
        width:20%
    }
    .uk-grid-width-large-1-6>*{
        width:16.666%
    }
    .uk-grid-width-large-1-10>*{
        width:10%
    }
}
@media (min-width:1220px){
    .uk-grid-width-xlarge-1-1>*{
        width:100%
    }
    .uk-grid-width-xlarge-1-2>*{
        width:50%
    }
    .uk-grid-width-xlarge-1-3>*{
        width:33.333%
    }
    .uk-grid-width-xlarge-1-4>*{
        width:25%
    }
    .uk-grid-width-xlarge-1-5>*{
        width:20%
    }
    .uk-grid-width-xlarge-1-23>* {
        width: 23%;
    }
    .uk-grid-width-xlarge-1-15>*{
        width:15.866%
    }
    .uk-grid-width-xlarge-1-6>*{
        width:16.666%
    }
    .uk-grid-width-xlarge-1-16>*{
        width:16% !important;
    }
    .uk-grid-width-xlarge-1-10>*{
        width:10%
    }
}
[class*=uk-width]{
    box-sizing:border-box;
    width:100%
}
.uk-width-1-1{
    width:100%
}
.uk-width-1-2,.uk-width-2-4,.uk-width-3-6,.uk-width-5-10{
    width:50%
}
.uk-width-1-3,.uk-width-2-6{
    width:33.333%
}
.uk-width-2-3,.uk-width-4-6{
    width:66.666%
}
.uk-width-1-4{
    width:25%
}
.uk-width-3-4{
    width:75%
}
.uk-width-1-5,.uk-width-2-10{
    width:20%
}
.uk-width-2-5,.uk-width-4-10{
    width:40%
}
.uk-width-3-5,.uk-width-6-10{
    width:60%
}
.uk-width-4-5,.uk-width-8-10{
    width:80%
}
.uk-width-1-6{
    width:16.666%
}
.uk-width-5-6{
    width:83.333%
}
.uk-width-1-10{
    width:10%
}
.uk-width-3-10{
    width:30%
}
.uk-width-7-10{
    width:70%
}
.uk-width-9-10{
    width:90%
}
@media (min-width:480px){
    .uk-width-small-1-1{
        width:100%
    }
    .uk-width-small-1-2,.uk-width-small-2-4,.uk-width-small-3-6,.uk-width-small-5-10{
        width:50%
    }
    .uk-width-small-1-3,.uk-width-small-2-6{
        width:33.333%
    }
    .uk-width-small-2-3,.uk-width-small-4-6{
        width:66.666%
    }
    .uk-width-small-1-4{
        width:25%
    }
    .uk-width-small-3-4{
        width:75%
    }
    .uk-width-small-1-5,.uk-width-small-2-10{
        width:20%
    }
    .uk-width-small-2-5,.uk-width-small-4-10{
        width:40%
    }
    .uk-width-small-3-5,.uk-width-small-6-10{
        width:60%
    }
    .uk-width-small-4-5,.uk-width-small-8-10{
        width:80%
    }
    .uk-width-small-1-6{
        width:16.666%
    }
    .uk-width-small-5-6{
        width:83.333%
    }
    .uk-width-small-1-10{
        width:10%
    }
    .uk-width-small-3-10{
        width:30%
    }
    .uk-width-small-7-10{
        width:70%
    }
    .uk-width-small-9-10{
        width:90%
    }
}
@media (min-width:768px){
    .uk-width-medium-1-1{
        width:100%
    }
    .uk-width-medium-1-2,.uk-width-medium-2-4,.uk-width-medium-3-6,.uk-width-medium-5-10{
        width:50%
    }
    .uk-width-medium-1-3,.uk-width-medium-2-6{
        width:33.333%
    }
    .uk-width-medium-2-3,.uk-width-medium-4-6{
        width:66.666%
    }
    .uk-width-medium-1-4{
        width:25%
    }
    .uk-width-medium-3-4{
        width:75%
    }
    .uk-width-medium-1-5,.uk-width-medium-2-10{
        width:20%
    }
    .uk-width-medium-2-5,.uk-width-medium-4-10{
        width:40%
    }
    .uk-width-medium-3-5,.uk-width-medium-6-10{
        width:60%
    }
    .uk-width-medium-4-5,.uk-width-medium-8-10{
        width:80%
    }
    .uk-width-medium-1-6{
        width:16.666%
    }
    .uk-width-medium-5-6{
        width:83.333%
    }
    .uk-width-medium-1-10{
        width:10%
    }
    .uk-width-medium-3-10{
        width:30%
    }
    .uk-width-medium-7-10{
        width:70%
    }
    .uk-width-medium-9-10{
        width:90%
    }
}
@media (min-width:960px){
    .uk-width-large-1-1{
        width:100%
    }
    .uk-width-large-1-2,.uk-width-large-2-4,.uk-width-large-3-6,.uk-width-large-5-10{
        width:50%
    }
    .uk-width-large-1-3,.uk-width-large-2-6{
        width:33.333%
    }
    .uk-width-large-2-3,.uk-width-large-4-6{
        width:66.666%
    }
    .uk-width-large-1-4{
        width:25%
    }
    .uk-width-large-3-4{
        width:75%
    }
    .uk-width-large-1-5,.uk-width-large-2-10{
        width:20%
    }
    .uk-width-large-2-5,.uk-width-large-4-10{
        width:40%
    }
    .uk-width-large-3-5,.uk-width-large-6-10{
        width:60%
    }
    .uk-width-large-4-5,.uk-width-large-8-10{
        width:80%
    }
    .uk-width-large-1-6{
        width:16.666%
    }
    .uk-width-large-5-6{
        width:83.333%
    }
    .uk-width-large-1-10{
        width:10%
    }
    .uk-width-large-3-10{
        width:30%
    }
    .uk-width-large-7-10{
        width:70%
    }
    .uk-width-large-9-10{
        width:90%
    }
}
@media (min-width:1220px){
    .uk-width-xlarge-1-1{
        width:100%
    }
    .uk-width-xlarge-1-2,.uk-width-xlarge-2-4,.uk-width-xlarge-3-6,.uk-width-xlarge-5-10{
        width:50%
    }
    .uk-width-xlarge-1-3,.uk-width-xlarge-2-6{
        width:33.333%
    }
    .uk-width-xlarge-2-3,.uk-width-xlarge-4-6{
        width:66.666%
    }
    .uk-width-xlarge-1-4{
        width:25%
    }
    .uk-width-xlarge-3-4{
        width:75%
    }
    .uk-width-xlarge-1-5,.uk-width-xlarge-2-10{
        width:20%
    }
    .uk-width-xlarge-2-5,.uk-width-xlarge-4-10{
        width:40%
    }
    .uk-width-xlarge-3-5,.uk-width-xlarge-6-10{
        width:60%
    }
    .uk-width-xlarge-4-5,.uk-width-xlarge-8-10{
        width:80%
    }
    .uk-width-xlarge-1-6{
        width:16.666%
    }
    .uk-width-xlarge-5-6{
        width:83.333%
    }
    .uk-width-xlarge-1-10{
        width:10%
    }
    .uk-width-xlarge-3-10{
        width:30%
    }
    .uk-width-xlarge-7-10{
        width:70%
    }
    .uk-width-xlarge-9-10{
        width:90%
    }
}
@media (min-width:768px){
    [class*=uk-push-],[class*=uk-pull-]{
        position:relative
    }
    .uk-push-1-2,.uk-push-2-4,.uk-push-3-6,.uk-push-5-10{
        left:50%
    }
    .uk-push-1-3,.uk-push-2-6{
        left:33.333%
    }
    .uk-push-2-3,.uk-push-4-6{
        left:66.666%
    }
    .uk-push-1-4{
        left:25%
    }
    .uk-push-3-4{
        left:75%
    }
    .uk-push-1-5,.uk-push-2-10{
        left:20%
    }
    .uk-push-2-5,.uk-push-4-10{
        left:40%
    }
    .uk-push-3-5,.uk-push-6-10{
        left:60%
    }
    .uk-push-4-5,.uk-push-8-10{
        left:80%
    }
    .uk-push-1-6{
        left:16.666%
    }
    .uk-push-5-6{
        left:83.333%
    }
    .uk-push-1-10{
        left:10%
    }
    .uk-push-3-10{
        left:30%
    }
    .uk-push-7-10{
        left:70%
    }
    .uk-push-9-10{
        left:90%
    }
    .uk-pull-1-2,.uk-pull-2-4,.uk-pull-3-6,.uk-pull-5-10{
        left:-50%
    }
    .uk-pull-1-3,.uk-pull-2-6{
        left:-33.333%
    }
    .uk-pull-2-3,.uk-pull-4-6{
        left:-66.666%
    }
    .uk-pull-1-4{
        left:-25%
    }
    .uk-pull-3-4{
        left:-75%
    }
    .uk-pull-1-5,.uk-pull-2-10{
        left:-20%
    }
    .uk-pull-2-5,.uk-pull-4-10{
        left:-40%
    }
    .uk-pull-3-5,.uk-pull-6-10{
        left:-60%
    }
    .uk-pull-4-5,.uk-pull-8-10{
        left:-80%
    }
    .uk-pull-1-6{
        left:-16.666%
    }
    .uk-pull-5-6{
        left:-83.333%
    }
    .uk-pull-1-10{
        left:-10%
    }
    .uk-pull-3-10{
        left:-30%
    }
    .uk-pull-7-10{
        left:-70%
    }
    .uk-pull-9-10{
        left:-90%
    }
}
.uk-panel{
    display:block;
    position:relative
}
.uk-panel,.uk-panel:hover{
    text-decoration:none
}
.uk-panel:after,.uk-panel:before{
    content:"";
    display:table
}
.uk-panel:after{
    clear:both
}
.uk-panel>:not(.uk-panel-title):last-child{
    margin-bottom:0
}
.uk-panel-title{
    margin-top:0;
    margin-bottom:15px;
    font-size:18px;
    line-height:24px;
    font-weight:400;
    text-transform:none;
    color:#444
}
.uk-panel-badge{
    position:absolute;
    top:0;
    right:0;
    z-index:1
}
.uk-panel-teaser{
    margin-bottom:15px
}
.uk-panel-body{
    padding:15px
}
.uk-panel-box{
    padding:15px;
    background:#fafafa;
    color:#444;
    border:1px solid #ddd;
    border-radius:4px
}
.uk-panel-box-hover:hover{
    color:#444
}
.uk-panel-box .uk-panel-title{
    color:#444
}
.uk-panel-box .uk-panel-badge{
    top:10px;
    right:10px
}
.uk-panel-box>.uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.uk-panel-box>.uk-nav-side{
    margin:0 -15px
}
.uk-panel-box-primary{
    background-color:#ebf7fd;
    color:#2d7091;
    border-color:rgba(45,112,145,.3)
}
.uk-panel-box-primary-hover:hover{
    color:#2d7091
}
.uk-panel-box-primary .uk-panel-title{
    color:#2d7091
}
.uk-panel-box-secondary{
    background-color:#fff;
    color:#444
}
.uk-panel-box-secondary-hover:hover{
    color:#444
}
.uk-panel-box-secondary .uk-panel-title{
    color:#444
}
.uk-panel-hover{
    padding:15px;
    color:#444;
    border:1px solid transparent;
    border-radius:4px
}
.uk-panel-hover:hover{
    background:#fafafa;
    color:#444;
    border-color:#ddd
}
.uk-panel-hover .uk-panel-badge{
    top:10px;
    right:10px
}
.uk-panel-hover>.uk-panel-teaser{
    margin-top:-16px;
    margin-left:-16px;
    margin-right:-16px
}
.uk-panel-header .uk-panel-title{
    padding-bottom:10px;
    border-bottom:1px solid #ddd;
    color:#444
}
.uk-panel-space{
    padding:30px
}
.uk-panel-space .uk-panel-badge{
    top:30px;
    right:30px
}
.uk-panel+.uk-panel-divider{
    margin-top:50px!important
}
.uk-panel+.uk-panel-divider:before{
    content:"";
    display:block;
    position:absolute;
    top:-25px;
    left:0;
    right:0;
    border-top:1px solid #ddd
}
@media (min-width:1220px){
    .uk-panel+.uk-panel-divider{
        margin-top:70px!important
    }
    .uk-panel+.uk-panel-divider:before{
        top:-35px
    }
}
.uk-panel-box .uk-panel-teaser{
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    overflow:hidden;
    -webkit-transform:translateZ(0)
}
.uk-block{
    position:relative;
    box-sizing:border-box;
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .uk-block{
        padding-top:50px;
        padding-bottom:50px
    }
}
.uk-block:after,.uk-block:before{
    content:"";
    display:table
}
.uk-block:after{
    clear:both
}
.uk-block>:last-child{
    margin-bottom:0
}
.uk-block-large{
    padding-top:20px;
    padding-bottom:20px
}
@media (min-width:768px){
    .uk-block-large{
        padding-top:50px;
        padding-bottom:50px
    }
}
@media (min-width:960px){
    .uk-block-large{
        padding-top:100px;
        padding-bottom:100px
    }
}
.uk-block-default{
    background:#fff
}
.uk-block-muted{
    background:#f9f9f9
}
.uk-block-primary{
    background:#005dae
}
.uk-block-secondary{
    background:#222
}
.uk-block-default+.uk-block-default,.uk-block-muted+.uk-block-muted,.uk-block-primary+.uk-block-primary,.uk-block-secondary+.uk-block-secondary{
    padding-top:0
}
.md-card.dashboarddetailswidget-card{
    position:relative;
    border-radius: 6px;
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(140deg, #005dae -9%, #004fe3 95%);
    cursor: pointer;
}
.md-card.dashboarddetailswidget-card+.md-card.dashboarddetailswidget-card,.md-card.dashboarddetailswidget-card+.uk-grid,.uk-grid+.md-card.dashboarddetailswidget-card{
    margin-top:25px
}
.md-card.dashboarddetailswidget-card .full_width_in_card{
    padding:16px 24px;
    background:rgba(0,0,0,.085)
}
.md-card.dashboarddetailswidget-card .md-card-toolbar{
    height:48px;
    padding:0 16px;
    border-bottom:1px solid rgba(0,0,0,.12);
    background:#fff
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after,.md-card.dashboarddetailswidget-card .md-card-toolbar:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card .md-card-toolbar:after{
    clear:both
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text{
    font:500 14px/50px Roboto,sans-serif;
    color:#212121;
    margin:0;
    float:left;
    overflow:hidden;
    height:48px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-heading-text.large{
    font-size:18px;
    font-weight:400
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group{
    float:left;
    margin:8px 0 0 16px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-toggle-group.md-toggle-group-small{
    margin-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions{
    float:right;
    padding-top:10px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .uk-open .md-card-toolbar-icon{
    background:rgba(0,0,0,.085);
    color:#212121
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .md-card-dropdown{
    display:inline-block;
    position:relative
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control{
    min-width:220px;
    margin-top:-3px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-input{
    min-height:30px;
    padding:4px 8px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-toolbar-actions .selectize-control .selectize-dropdown{
    margin-top:-34px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-icon+.md-card-dropdown{
    margin-left:4px
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .md-card-fullscreen-deactivate{
    margin:9px 8px 0 0
}
.md-card.dashboarddetailswidget-card .md-card-toolbar-input{
    border:none;
    font:400 18px/24px Roboto,sans-serif;
    height:auto;
    background:0 0!important;
    padding:12px 0;
    width:50%;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .uk-tab{
    margin-top:-2px;
    border-bottom:none
}
.md-card.dashboarddetailswidget-card .md-card-toolbar .uk-tab li>a{
    padding:10px 8px!important
}
.md-card.dashboarddetailswidget-card .md-card-head{
    height:125px;
    position:relative;
    border-bottom:1px solid transparent
}
.md-card.dashboarddetailswidget-card .md-card-head-menu{
    position:absolute;
    right:8px;
    top:8px;
    border: 1px solid #e0e3e6;
    border-radius: 4px;
    background:#fff;
}
.img-div {
    background-color: rgba(255, 255, 255, 0);
    width: 86px;
    height: 86px;
    border-radius: 50%;
    margin-top: 20px;
    display: inline-block;
}
.md-card.dashboarddetailswidget-card .md-card-head-avatar{
    width:75px;
    height:75px;
    border-radius:50%;
    margin-top:4px;
    display:inline-block
}
.md-card.dashboarddetailswidget-card .md-card-head-text{
    padding:10px 5px;
    font-size: 16px;
    color:#fff;
    margin:0;
    font-weight: 600;
    line-height: 1.19;
}
.md-card.dashboarddetailswidget-card .md-card-head-text span:not(.listNavSelector){
    display:block;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
    line-height: 1.25;
}
.md-card.dashboarddetailswidget-card .md-card-head-text.text_dark{
    color: #303031 !important
}
.md-card.dashboarddetailswidget-card .md-card-head-text-over{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.65)),to(rgba(0,0,0,0)));
    background-image:linear-gradient(to bottom,rgba(0,0,0,.65) 0,rgba(0,0,0,0) 100%)
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext{
    position:absolute;
    bottom:10px;
    left:0;
    right:0;
    padding:0 16px;
    text-align:right;
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head-subtext span{
    font-size:26px
}
.md-card.dashboarddetailswidget-card .md-card-head-icon{
    font-size:48px;
    color:#fff;
    vertical-align:middle
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background{
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    border-bottom-color:transparent
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background .md-card-head-text{
    color:#fff
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_top{
    background-repeat:no-repeat;
    background-position:center top
}
.md-card.dashboarddetailswidget-card .md-card-head.head_background_bottom{
    background-repeat:no-repeat;
    background-position:center bottom
}
.md-card.dashboarddetailswidget-card .md-card-head .head_chart{
    height:100px;
    width:100%;
    position:absolute!important;
    left:0;
    top:40px
}
.md-card.dashboarddetailswidget-card .md-card-head .fitVid_player{
    width:100%;
    height:160px;
    overflow:hidden
}
.md-card.dashboarddetailswidget-card .md-card-head-img{
    height:100%;
    width: auto;
}
.md-card.dashboarddetailswidget-card .md-card-head iframe{
    height:160px
}
.md-card.dashboarddetailswidget-card .md-card-content{
    padding: 5px 12px 5px;
}
.md-card.dashboarddetailswidget-card .md-card-content.padding-reset{
    padding:0
}
.md-card.dashboarddetailswidget-card .md-card-content.large-padding{
    padding:24px 35px
}
.md-card.dashboarddetailswidget-card .md-card-content.small-padding{
    padding:8px
}
.md-card.md-card-fullscreen-activate{
    cursor:pointer
}
.md-card.md-card-fullscreen{
    position:fixed;
    z-index:9998;
    overflow-x:hidden;
    cursor:default
}
.md-card.md-card-fullscreen .md-card-fullscreen-activate{
    display:none
}
.md-card.md-card-fullscreen .md-card-fullscreen-hide{
    display:none
}
.md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:none;
    padding:16px
}
.md-card-fullscreen-content-hidden .md-card.dashboarddetailswidget-card .md-card-fullscreen-content{
    display:block;
    visibility:hidden;
    padding:0
}
.md-card.mdToolbar_fixed{
    overflow-y:hidden
}
.md-card.mdToolbar_fixed>.md-card-toolbar{
    position:fixed;
    left:0;
    right:0;
    top:0;
    z-index:9999;
    -webkit-box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23);
    box-shadow:0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)
}
.md-card.mdToolbar_fixed>.md-card-content{
    overflow-y:scroll;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    width:100%;
    top:48px;
    bottom:0
}
.md-card.mdToolbar_fixed>.md-card-content .md-card-fullscreen-content{
    padding:16px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay{
    overflow:hidden;
    padding-bottom:54px
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content{
    height:142px;
    overflow:hidden;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card .md-card-overlay .md-card-content.no_truncate{
    position:relative
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content.no_truncate:after{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:8px;
    background-image:-webkit-gradient(linear,left bottom,left top,color-stop(50%,#fff),to(rgba(255,255,255,0)));
    background-image:linear-gradient(to top,#fff 50%,rgba(255,255,255,0) 100%);
    display:block;
    content:'';
    z-index:10
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre{
    margin-top:0;
    max-height:110px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-content>pre>code{
    overflow:hidden
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content{
    position:absolute;
    top:100%;
    left:0;
    right:0;
    padding:0 16px;
    margin-top:-54px;
    border-top:1px solid rgba(0,0,0,.12);
    text-align:left;
    bottom:0;
    background:#fff;
    z-index:10;
    -webkit-transition:all 280ms cubic-bezier(.4,0,.2,1);
    transition:all 280ms cubic-bezier(.4,0,.2,1)
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p{
    margin:0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+*{
    margin-top:16px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-content p+p{
    margin-top:4px
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header{
    padding:12px 0
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:before{
    content:" ";
    display:table
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header:after{
    clear:both
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h3,.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header h4{
    margin:0;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    padding-right:32px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card.dashboarddetailswidget-card.md-card-overlay .md-card-overlay-header .md-icon{
    position:absolute;
    right:12px;
    top:11px
}
.md-card.dashboarddetailswidget-card.md-card-overlay-active .md-card-overlay-content{
    top:-1px;
    margin-top:0
}
.uk-sortable-dragged .md-card{
    -webkit-box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
    box-shadow:0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)
}
.uk-sortable-dragged .md-card.dashboarddetailswidget-card canvas{
    margin:0 auto;
    display:block
}
.md-card.dashboarddetailswidget-card .heading_list{
    padding:0
}
.md-card-danger,.md-card-primary,.md-card-success,.md-card-warning{
    border-left:4px solid transparent
}
.md-card-primary{
    border-left-color:#00448b
}
.md-card-success{
    border-left-color:#7cb342
}
.md-card-danger{
    border-left-color:#e53935
}
.md-card-warning{
    border-left-color:#ffa000
}
.md-card-horizontal{
    position:relative;
    min-height:200px
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal:after,.md-card-horizontal:before{
    content:" ";
    display:table
}
.md-card-horizontal:after{
    clear:both
}
.md-card-horizontal-grid{
    min-height:auto
}
.md-card-horizontal .md-card-head{
    border-right:1px solid rgba(0,0,0,.12);
    border-bottom:none;
    width:200px;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    bottom:0
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-head{
        width:140px
    }
}
.md-card-horizontal .md-card-head-grid{
    border-right:1px solid rgba(0,0,0,.12)
}
.md-card-horizontal .md-card-head-menu{
    right:auto;
    left:8px
}
.md-card-horizontal .md-card-content{
    margin-left:200px
}
@media only screen and (max-width:767px){
    .md-card-horizontal .md-card-content{
        margin-left:140px
    }
}
.md-card-horizontal .md-card-content-grid{
    padding:16px
}
.md-expand,.md-expand-group>*{
    opacity:0
}
.md-card-placeholder{
    min-width:100%
}
.md-card-list-wrapper,.md-card-list-wrapper *,.md-card-list-wrapper :after,.md-card-list-wrapper :before,.md-card-list-wrapper:after,.md-card-list-wrapper:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-card-list-wrapper .md-card-list-header{
    position:absolute;
    top:-24px;
    left:0
}
.md-card-list-wrapper .md-card-list{
    margin:48px 0 0 0;
    position:relative
}
.md-card-list-wrapper .md-card-list:first-child{
    margin-top:24px
}
.md-card-list-wrapper .md-card-list>ul{
    margin:0;
    padding:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-card-list-wrapper .md-card-list>ul>li{
    min-height:34px;
    padding:8px 16px;
    font-size:13px;
    -webkit-transition:background 150ms,padding .2s;
    transition:background 150ms,padding .2s;
    background:#fff;
    -webkit-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li:after,.md-card-list-wrapper .md-card-list>ul>li:before{
    content:" ";
    display:table
}
.md-card-list-wrapper .md-card-list>ul>li:after{
    clear:both
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown{
    background:#fff;
    padding:8px 36px
}
@media only screen and (max-width:767px){
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject{
        clear:both;
        float:none;
        padding-top:16px
    }
    .md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-subject>span{
        white-space:normal
    }
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown .md-card-list-item-sender{
    width:auto;
    overflow:hidden
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected{
    position:relative
}
.md-card-list-wrapper .md-card-list>ul>li.item-shown.md-card-list-item-selected:before{
    content:'';
    position:absolute;
    display:block;
    left:0;
    top:0;
    bottom:0;
    width:8px;
    background:#e3f2fd
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-select,.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    float:left
}
.md-card-list-wrapper .md-card-list .md-card-list-item-select{
    padding:6px 8px 0 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper,.md-card-list-wrapper .md-card-list .md-card-list-item-date,.md-card-list-wrapper .md-card-list .md-card-list-item-sender,.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    padding:0 8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar{
    background:#757575;
    color:#fff;
    width:34px;
    border-radius:50%;
    display:block
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper .md-card-list-item-avatar-large{
    width:82px;
    border-radius:50%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-avatar-wrapper span.md-card-list-item-avatar{
    line-height:34px;
    text-transform:uppercase;
    text-align:center
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender{
    width:220px;
    line-height:34px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-sender>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-sender{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject{
    overflow:hidden;
    font-weight:500
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject>span{
    line-height:34px;
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
    display:none
}
@media only screen and (max-width:1219px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small{
        display:block
    }
    .md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small+span{
        line-height:inherit
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-subject .md-card-list-item-sender-small>span{
    text-overflow:ellipsis;
    display:inline-block;
    vertical-align:top;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    font-size:12px;
    color:#999
}
.md-card-list-wrapper .md-card-list .md-card-list-item-date{
    line-height:34px;
    float:right;
    color:#999
}
@media only screen and (max-width:479px){
    .md-card-list-wrapper .md-card-list .md-card-list-item-date{
        display:none
    }
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu{
    float:right;
    margin:0 0 0 8px;
    position:relative
}
.md-card-list-wrapper .md-card-list .md-card-list-item-menu .uk-dropdown .material-icons{
    margin-right:8px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content-wrapper{
    display:none;
    clear:both;
    opacity:0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content{
    padding:16px 16px 0 0;
    max-height:360px;
    overflow-x:hidden;
    margin:0 0 40px;
    top:20px;
    position:relative;
    font-size:14px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-content+.md-card-list-item-reply{
    padding-top:10px
}
.md-card-list-wrapper .md-card-list .md-card-list-item-reply{
    padding:16px 0
}
.md-card-list-wrapper .md-card-list .md-card-list-item-selected{
    background:#e3f2fd
}
.md-list{
    margin:0;
    padding:0;
    list-style:none
}
.md-list>li{
    padding:0;
    margin:0;
    list-style:none
}
.md-list,.md-list *,.md-list :after,.md-list :before,.md-list:after,.md-list:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.md-list .uk-nestable-list>li,.md-list>li{
    min-height:48px;
    padding:8px 4px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    border-bottom:1px solid rgba(0,0,0,.12);
    position:relative
}
.md-list .uk-nestable-list>li>.md-list-content,.md-list>li>.md-list-content{
    overflow:hidden
}
.md-list .uk-nestable-list>li>.md-list-content>span,.md-list>li>.md-list-content>span{
    display:block
}
.md-list .uk-nestable-list>li>.md-list-content .md-list-heading,.md-list>li>.md-list-content .md-list-heading{
    margin:0;
    font-weight:500;
    display:block;
    overflow:hidden;
    padding-bottom:1px
}
.md-list .uk-nestable-list>li>.md-list-content .md-list-menu,.md-list>li>.md-list-content .md-list-menu{
    float:right
}
.md-list .uk-nestable-list>li>.md-list-content .md-list-menu .md-list-menu-toggle,.md-list>li>.md-list-content .md-list-menu .md-list-menu-toggle{
    display:block;
    font-size:18px;
    color:rgba(0,0,0,.8);
    width:28px;
    height:28px;
    line-height:28px;
    border-radius:14px;
    text-align:center
}
.md-list .uk-nestable-list>li>.md-list-content .uk-badge,.md-list>li>.md-list-content .uk-badge{
    float:right;
    color:#fff!important
}
.md-list .uk-nestable-list>li>.md-list-content .md-list-action,.md-list>li>.md-list-content .md-list-action{
    float:right;
    margin-left:8px;
    margin-top:2px;
    display:none
}
.md-list .uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.md-list>li>.md-list-content .md-list-action-placeholder{
    float:right;
    margin-left:8px;
    display:none;
    width:32px;
    height:32px
}
.md-list .uk-nestable-list>li .md-list-action-dropdown,.md-list>li .md-list-action-dropdown{
    position:absolute;
    right:16px;
    top:10px;
    display:none
}
.md-list .uk-nestable-list>li>a.md-list-content,.md-list>li>a.md-list-content{
    display:block;
    color:#212121
}
.md-list .uk-nestable-list>li:last-child,.md-list>li:last-child{
    border-bottom:none
}
.md-list .uk-nestable-list>li.md-list-item-active,.md-list>li.md-list-item-active{
    color:#7cb342;
    background:#ededed
}
.md-list .uk-nestable-list>li.md-list-item-disabled>.md-list-content,.md-list>li.md-list-item-disabled>.md-list-content{
    color:#bdbdbd
}
.md-list .uk-nestable-list>li.md-list-item-disabled>.md-list-content span,.md-list>li.md-list-item-disabled>.md-list-content span{
    color:#bdbdbd!important
}
.md-list .uk-nestable-list>li.heading_list,.md-list>li.heading_list{
    min-height:32px;
    padding:32px 8px 16px;
    border-bottom:none;
    background:0 0!important;
    text-transform:uppercase
}
.md-list .uk-nestable-list>li:hover>.md-list-addon-element .uk-nestable-handle,.md-list>li:hover>.md-list-addon-element .uk-nestable-handle,.uk-touch .md-list .uk-nestable-list>li>.md-list-addon-element .uk-nestable-handle,.uk-touch .md-list>li>.md-list-addon-element .uk-nestable-handle{
    display:block
}
.md-list .uk-nestable-list>li:hover>.md-list-content .md-list-action,.md-list .uk-nestable-list>li:hover>.md-list-content .md-list-action-placeholder,.md-list>li:hover>.md-list-content .md-list-action,.md-list>li:hover>.md-list-content .md-list-action-placeholder,.uk-touch .md-list .uk-nestable-list>li>.md-list-content .md-list-action,.uk-touch .md-list .uk-nestable-list>li>.md-list-content .md-list-action-placeholder,.uk-touch .md-list>li>.md-list-content .md-list-action,.uk-touch .md-list>li>.md-list-content .md-list-action-placeholder{
    display:block
}
.md-list .uk-nestable-list>li:hover .md-list-action-dropdown,.md-list>li:hover .md-list-action-dropdown,.uk-touch .md-list .uk-nestable-list>li .md-list-action-dropdown,.uk-touch .md-list>li .md-list-action-dropdown{
    display:block
}
.md-list .uk-nestable-list>li{
    margin-left:64px
}
.md-list-addon>li{
    margin-left:64px;
    position:relative
}
.md-list-addon>li:last-child .md-list-addon-element{
    border-bottom:none;
    bottom:0
}
.md-list-addon>li:first-child .md-list-addon-element{
    top:0
}
.md-list-addon>li.md-list-item-active .md-list-addon-element,.md-list-addon>li.md-list-item-active .md-list-addon-element .material-icons{
    color:#7cb342
}
.md-list-addon-element{
    position:absolute;
    left:-64px;
    top:-1px;
    bottom:-1px;
    width:64px;
    text-align:center;
    padding:8px 0;
    display:block
}
.md-list-addon-element .element-status{
    position:absolute;
    right:12px;
    top:10px;
    width:12px;
    height:12px;
    border-radius:50%;
    background:#bdbdbd;
    border:1px solid #fff
}
.md-list-addon-element .element-status-danger{
    background:#e53935
}
.md-list-addon-element .element-status-success{
    background:#7cb342
}
.md-list-addon-element .element-status-warning{
    background:#ffa000
}
.md-list-addon-element>.md-list-addon-avatar{
    margin-top:2px
}
.md-list-addon-element>.md-list-addon-icon{
    font-size:28px;
    margin-top:4px;
    color:#727272
}
.md-list-addon-element>.material-icons{
    margin-top:6px
}
.md-list-addon-element .icheckbox_md,.md-list-addon-element .iradio_md{
    margin-top:10px
}
.md-list-addon-element .uk-nestable-handle{
    position:absolute;
    left:-2px;
    top:12px;
    display:none
}
.md-list-interactive li{
    cursor:pointer
}
.md-list-bg{
    background:#fff
}
.md-list-separated li{
    background:#fff;
    padding:8px
}
.md-list-separated li+li{
    border-top:none;
    margin-top:8px
}
.md-list-bg-no-sep{
    background:#fff;
    padding:8px
}
.md-list-bg-no-sep li>.md-list-content{
    padding:0 4px
}
.md-list-outside>li{
    padding:0
}
.md-list-outside>li>.md-list-content{
    padding:8px 16px;
    display:block;
    color:#212121
}
.md-list-outside>li.md-list-item-active,.md-list-outside>li:hover:not(.heading_list){
    background:rgba(0,0,0,.085)
}
.md-list-outside>li.heading_list{
    padding:32px 16px 16px
}
.md-list-outside.md-list-addon li{
    margin-left:0
}
.md-list-outside.md-list-addon li .md-list-addon-element{
    position:relative;
    top:auto;
    left:auto;
    float:left
}
.md-list-outside.md-list-addon li .md-list-content{
    padding-left:0
}
.md-list-outside-wrapper{
    position:relative
}
.md-list-outside-wrapper .md-list-outside-search{
    position:absolute;
    top:-46px;
    left:16px;
    right:16px;
    z-index:1000
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper{
    padding:0
}
.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:after,.md-list-outside-wrapper .md-list-outside-search .md-input-wrapper .md-input-bar:before{
    background:#fff
}
.md-list-outside-wrapper .md-list-outside-search .md-input{
    border-bottom-color:transparent!important
}
.md-list .uk-nestable-list{
    padding-left:0
}
.md-list .uk-nestable-list .uk-nestable-item{
    padding-right:0
}
.md-list .uk-nestable-item+.uk-nestable-item{
    margin-top:0
}
.md-list-right.md-list-addon>li{
    margin-left:0;
    margin-right:64px
}
.md-list-right.md-list-addon>li .md-list-addon-element{
    left:auto;
    right:-64px
}
.uk-touch .md-list-addon-element .uk-nestable-handle{
    display:block!important
}
.uk-touch .md-list-content .md-list-action,.uk-touch .md-list-content .md-list-action-placeholder{
    display:block!important
}
.uk-touch .md-list-action-dropdown{
    display:block!important
}

.card-box {
    box-sizing: border-box;
    padding-left: 15px;
    padding-bottom: 20px;
    display: inline-block;
}
.des-box {
    border-radius: 5px;
    padding: 5px 5px;
    color: #43425d !important;
    font-size: 13px;
    font-weight: 500;
}
.pink-box {
    box-shadow: 0 2px 10px 0 rgba(255, 38, 138, 0.55);
    background-color: #ff268a;
}
.blue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #00448b;
}
.lightblue-box {
    box-shadow: 0 2px 10px 0 rgba(13, 132, 242, 0.36);
    background-color: #50bcff;
}
.orange-box {
    box-shadow: 0 2px 10px 0 rgba(245, 166, 35, 0.35);
    background-color: #f5a623;
}
.border-orange1 {
    border-color: #ff7f00 !important;
}
.border-green1 {
    border-color: #1a9347 !important;
}
.border-red1 {
    border-color: #e82828 !important;
}
.card-group {
    margin-bottom: 2.143rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}
.card-block {
    position: relative;
    padding: 1.429rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.card.card-block {
    box-shadow: none;
}
@media (min-width: 480px) {

    .card-group {
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group .card {
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    }
}
.counter {
    text-align: center;
}
.vertical-align:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
}
.vertical-align-bottom, .vertical-align-middle {
    display: inline-block;
    max-width: 100%;
    font-size: 1rem;
}
.vertical-align-middle {
    vertical-align: middle;
}
.counter-lg .counter-icon {
    font-size: 40px;
}
.counter-inverse .counter-icon {
    color: #00448b;
}
.counter-inverse {
    color: #303031;
}
.h-150 {
    height: 150px!important;
}
.modal-qr-block {
    width: 100%;
    position: relative;
    display: block;
    padding: 20px 10px 0px;
    text-align: center;
}
.modal-passport-block {
    width: 100%;
    position: relative;
    display: block;
    padding: 5px 10px 0px;
}
.option__image {
	max-height: 80px;
	margin-right: 10px
}
.option__desc,
.option__image {
	display: inline-block;
	vertical-align: middle
}
.option__desc {
	padding: rem(10px)
}
.option__title {
	font-size: rem(24px)
}
.option__small {
	margin-top: rem(10px);
	display: block
}
.form__label {
	margin-top: 5px!important
}
.badge__img {
	vertical-align: middle;
	float: right
}
.badge__name {
	vertical-align: middle;
	display: inline-block;
	margin-left: 5px;
	float: left
}
.dashboarddetailswidget-section {
    border: solid 0px transparent;
    background-color: transparent;
}
.dashboardugaheader {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 40px;
    height: 100%;
    position: relative;
    background: #FFFFFF;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.dashboardugaheader>.descriptionheader {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 25px;
    border-bottom: 1px solid transparent;
}
.dashboardugaheader>.descriptionheader .description-text-header-left h5 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: #00448b;
}
.dashboardugaheader>.descriptionheader .description-text-header-right {
    font-size: 13px;
    margin-left: auto;
    padding-left: 12px;
    color: #4e5764;
    font-weight: 500;
}
.dashboardugaheader>.descriptionheader .description-text-header-right  {
    display: block;
    margin-top: 30px;
}
.dashboarddetailswidget-tab-header {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: 0.77px;
    color: #303031;
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100% !important;
    text-align: left;
    padding-left: 63px;
}
.see-link {
    text-decoration: underline;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.2;
    color: #00448b;
    cursor: pointer;
    float: right;
}
.btn-secondary.btn-outline {
    color: #00448b !important;
    background-color: transparent;
    background-image: none;
    border: 1px solid #00448b;
}
.btn-secondary.btn-outline:hover {
    color: #fff !important;
    background-color: #00448b;
    border-color: #00448b;
}
.img-card-section {
    border: solid 0px transparent;
    background-color: transparent;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
}
.img-card-box {
    box-sizing: border-box;
    padding-left: 15px;
    padding-bottom: 20px;
    display: inline-block;
}

.img-md-card {
    background: #fff;
    position: relative;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    border: none;
    border-radius: 6px;
}
.img-md-card.img-md-card-hover {
    -webkit-transition: all 280ms cubic-bezier(.4,0,.2,1);
    transition: all 280ms cubic-bezier(.4,0,.2,1);
    will-change: box-shadow;
}
.gallery_grid_item {
    padding: 0!important;
    border-radius: 6px;
}
.gallery_grid_item, .gallery_grid_item:focus, .gallery_grid_item:hover {
    color: #fff;
}
.gallery_grid_item>a {
    display: block;
    border-radius: 6px;
}
.gallery_grid_item img {
    display: block;
    border: 0;
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    border-radius: 6px;
}
.gallery_grid_image_caption {
    padding: 8px 4px;
    display: block;
    position: relative;
    z-index: 10;
    background-color: transparent;
    text-align: center;
    margin: 8px auto;
}
.gallery_grid_image_caption .gallery_image_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: #fff;
    text-align: center;
    display: block;
}

.description-widget {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0px 10px 10px;
    border-bottom: 0px solid transparent;
}
.description-widget-text {
    text-align: left;
}
.description-widget .description-widget-text h5 {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    color: #fff;
    word-wrap: break-word;
    line-height: 1.5;
    width: 105%;
}
.description-widget .toggle-source-preview {
    cursor: pointer;
    font-size: 12px;
    margin-left: auto;
    padding-left: 15px;
}
.description-widget .toggle-source-preview img {
    display: block;
}
.pointerEvents{
    pointer-events: none !important;
}
#leftslideshow {
  margin: 0px auto;
  position: relative;
  width: 120px;
  height: 120px;
  padding: 0px;
  -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
}
@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}

#leftslideshow > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
#leftslideshow > div img {
  width: 105px;
}
#rightslideshow {
  margin: 0px auto;
  position: relative;
  width: 120px;
  height: 120px;
  padding: 0px;
}

#rightslideshow > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
#rightslideshow > div img {
  width: 105px;
}
.w162{
    width: 162px !important;
}
.w145{
    width: 145px !important;
}
.whiteSpace{
    white-space: nowrap !important;
}
.borderBottom{
    border-bottom: 1px solid #eeeeee2e !important;
}
.tDeco{
    text-decoration: none !important;
    color: #212121 !important;
}
</style>
