import global from "../global.js"
export default {
    data() {
        return {
            form: {
                login_id: "",
                customer_name: null
            },
            activediv: "login",
            countdown: 60,
            otpInvalid: false,
            otpValue: "",
            callInProgress: false,
            otpVerifying: false,
        }
    },
    methods:{
        otpEntered(value) {
            this.otp_error_text = '';
            this.otpInvalid = false;
            this.otpValue = value;
        },
        countDownTimer() {
            this.show_countdown = true
            if (this.countdown > 0 && this.activediv === "verify otp" && !this.otpVerifying) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else if (this.countdown === 0 && !this.otpVerifying) {
                // this.clearOtpInputs();
                this.show_countdown = false
            }
        },
    },
    watch: {
        activediv: function(newVal,oldVal) {
            if (newVal == "verify otp") {
                this.countDownTimer();
            } else {
                this.countdown = 60;
            }
        },
        countdown: function(val) {
            if (val == 0) {
                this.$refs.otpInput.clearInput();
                this.otpValue = "";
            }
        },
        "form.login_id": function(newVal, oldVal) {
            if (newVal) {
                this.is_email_phone_valid_text = "";
            }
        },
    }
}
