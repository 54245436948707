<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal" :class="{'popup-modal-all-width-height-height-600':(DeletedItemsTag || refund_items), 'popup-modal-all-width-height':OrderedItemsTag}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">{{orderId}}</span>
                </div>
                <div v-if="ajax_call_in_progress" class="general-section h-100" style="padding: 15px 0px;">
                    <div class="text-center w-100 h-100 d-flex align-items-center justify-content-center">
                        <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div v-else-if="!ajax_call_in_progress && details_error_message" class="general-section h-100" style="padding: 15px 0px;">
                    <div class="text-center w-100 h-100 d-flex align-items-center justify-content-center font-bold">
                        {{details_error_message}}
                    </div>
                </div>
                <div v-else class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">
                    <div>
                        <div class="tabs-promotion text-center pb-4 mb-3">
                            <ul class="tabs_deleted_items">
                                <li class="ordered_item" :class="{'ordered_items_active': OrderedItemsTag }"
                                    @click="OrderedItems()">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/ordered_item_blue.svg"
                                        v-if="!OrderedItemsTag" />
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/order-white.svg"
                                        v-if="OrderedItemsTag" />
                                    <br />Ordered Items
                                </li>
                                <li class="deleted_items" :class="{'deleted_items_active': DeletedItemsTag, 'refund-data-show': (status == 'closed' && refund_items_details.length > 0) || status == 'refund'}"
                                    @click="DeletedItems()">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/rubbish-bin-blue.svg"
                                        v-if="!DeletedItemsTag" />
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/rubbish-bin-white.svg"
                                        v-if="DeletedItemsTag" />
                                    <br />Deleted Items
                                </li>
                                <li v-if="(status == 'closed' && refund_items_details.length > 0) || status == 'refund'" class="deleted_items" :class="{'deleted_items_active': refund_items}"
                                    @click="refundItems()">
                                    <img src="/static/images/refund_icon.svg" width="20"
                                        v-if="!refund_items" />
                                    <img src="/static/images/refund_icon_white.svg" width="20"
                                        v-if="refund_items" />
                                    <br />Refunds
                                </li>
                            </ul>
                        </div>
                        <div v-if="OrderedItemsTag">
                            <div class="row justify-content-around">
                                <div class="font-weight-bold col-6" v-if="details.created_time">
                                    <span class="text-secondary whiteSpace col-6">Order Date/Time :</span>
                                    {{details.created_time.slice(3,5) + '/' + details.created_time.slice(0,2) + '/' + details.created_time.slice(6,) || '-'}}
                                </div>
                                <div class="font-weight-bold col-6" v-else>
                                    <span class="text-secondary whiteSpace col-6">Order Date/Time :</span>
                                    -
                                </div>
                                <div class="font-weight-bold col-6">
                                    <span class="text-secondary whiteSpace col-6">Order Type :</span>
                                    {{details.order_type}}
                                </div>
                            </div>
                            <div class="row justify-content-around pt-2 pb-2">
                                <div class="font-weight-bold col-6">
                                    <span class="text-secondary whiteSpace col-6">Customer Name :</span>
                                    {{details.customer_name}}
                                </div>
                                <div class="font-weight-bold col-6" v-if="details.order_type == 'Dine In'">
                                    <span class="text-secondary whiteSpace col-6">Table No :</span>
                                    {{details.table_number}}
                                </div>
                                <div class="col-6" v-else></div>
                            </div>
                            <table class="table tablebill closeOrders mt-3">
                                <thead>
                                    <tr>
                                        <th class="thbill"
                                            style="width: 30%;border-bottom: 1px solid transparent!important;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                                            Items</th>
                                        <th class="thbill"
                                            style="width: 15%;border-bottom: 1px solid transparent!important;">Qty</th>
                                        <th class="thbill"
                                            style="width: 15%;border-bottom: 1px solid transparent!important;">Rate</th>
                                        <th class="thbill"
                                            style="width: 15%;border-bottom: 1px solid transparent!important;">Discount
                                        </th>
                                        <th class="thbill"
                                            style="width: 25%;border-bottom-right-radius: 5px;border-top-right-radius: 5px;border-bottom: 1px solid transparent!important;">
                                            Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item,iindex) in details.item_details">
                                        <tr :key="iindex">
                                            <td v-if="item.hasVariant"
                                                class="tdcart deepSkyBlue pointer td-truncate text-secondary font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                <span v-if="item.variant_name != ''">{{item.product_name}}</span>
                                                <span v-else>{{item.product_name}}</span>
                                            </td>
                                            <td v-else
                                                class="tdcart deepSkyBlue pointer td-truncate text-secondary font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 30%;">
                                                {{item.product_name}}</td>
                                            <td class="tdcart font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.quantity}}
                                            </td>
                                            <td class="tdcart font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.price}}
                                            </td>
                                            <td class="tdcart font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.discount}}
                                            </td>
                                            <td class="tdcart font-600"
                                                style="padding: 0.6rem 0.8rem !important;width: 25%;">{{item.total}}
                                            </td>
                                        </tr>
                                        <!-- <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem !important; width: 30%;">
                                                <div class="text-blue font-600 fs-14 py-1">
                                                    <span>{{modifier.m_name}}</span>
                                                </div>
                                                <div class="d-flex ml-0 pl-0">
                                                    <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                                </div>
                                            </td>
                                            <td style="padding: 0.4rem 1.2rem!important;border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 30%;vertical-align:bottom;">
                                                <div>
                                                    {{modifier.quantity}}
                                                </div>
                                            </td>
                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                <div>
                                                    {{modifier.price}}
                                                </div>
                                            </td>
                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                <div>
                                                    -
                                                </div>
                                            </td>
                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 25%;vertical-align:bottom;">
                                                <div>
                                                    {{modifier.amount}}
                                                </div>
                                            </td>
                                        </tr> -->

                                        <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                            <td colspan="5" style="padding: 0px !important">
                                                <table class="w-100">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="fs-14 py-1" style="padding:0.6rem 0.8rem !important">{{item.variant_name}}</td>
                                                        </tr>
                                                        <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem !important; width: 30%;">
                                                                <!-- <div class="text-blue font-600 fs-14 py-1">
                                                                    <span>{{modifier.m_name}}</span>
                                                                </div> -->
                                                                <div class="d-flex ml-0 pl-0">
                                                                    <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                                                </div>
                                                            </td>
                                                            <td style="padding: 0.4rem 1.2rem!important;border-radius: 0px;padding: 0.6rem 0.8rem !important; width: 15%;vertical-align:bottom;">
                                                                <div v-if="modifier.quantity > 0">
                                                                    {{modifier.quantity}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                                <div v-if="parseFloat(modifier.price.substring(1,)) > 0">
                                                                    {{modifier.price}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 25%;vertical-align:bottom;">
                                                                <div v-if="parseFloat(modifier.amount.substring(1,)) > 0">
                                                                    {{modifier.amount}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'">
                                                        <tr>
                                                            <td colspan="6" style="border-radius: 0px;padding: 0.6rem 0.8rem !important;">
                                                                <div class="text-blue font-600 fs-14">
                                                                    <span>{{modifier.modifier_name}}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 30%;">
                                                                <div class="d-flex ml-0 pl-0">
                                                                    <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div v-if="modifier_item.quantity > 0">
                                                                    {{modifier_item.quantity}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div v-if="parseFloat(modifier_item.price.substring(1,)) > 0">
                                                                    {{modifier_item.price}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 25%;vertical-align:bottom;">
                                                                <div v-if="parseFloat(modifier_item.amount.substring(1,)) > 0">
                                                                    {{modifier_item.amount}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr style="height: 40px;" v-for="(item,sindex) in details.category_summary"
                                        :key="sindex">
                                        <td class="tdbill font-600"
                                            style="border-top: 2px dotted #94bef0;text-align: right;padding: 0.6rem 1.2rem;font-weight: 600;text-transform: capitalize!important;"
                                            colspan="3">{{item.name}}</td>
                                        <td
                                            style="border-top: 2px dotted #94bef0;padding: 0.6rem 1.2rem;font-weight: 600;">
                                        </td>
                                        <td class="tdbill font-600"
                                            style="border-top: 2px dotted #94bef0;padding: 0.6rem 1.2rem;font-weight: 600;">
                                            {{item.value}}</td>
                                    </tr>
                                    <tr v-if="details.sub_total_without_discount">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Sub-Total Without
                                            Discount</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.sub_total_without_discount}}</td>
                                    </tr>
                                    <tr v-if="parseInt(details.item_level_discount.substring(1)) > 0">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Item Discount</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.item_level_discount}}</td>
                                    </tr>
                                    <tr v-if="parseInt(details.order_discount.amount.replace('(','').replace(')','').slice(1,)) > 0">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-bottom: 2px dotted #00448b;">Order Discount
                                        </td>
                                        <td style="border-bottom: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-bottom: 2px dotted #00448b;">
                                            {{details.order_discount.amount}}</td>
                                    </tr>
                                    <tr v-if="details.sub_total_with_discount" style="border-bottom: 2px dotted #00448b;">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Sub-Total With
                                            Discount</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.sub_total_with_discount}}</td>
                                    </tr>
                                    <tr v-if="!details.sub_total_with_discount && !details.sub_total_without_discount" style="border-bottom: 2px dotted #00448b;">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Sub-Total</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.sub_total}}</td>
                                    </tr>
                                    <tr v-for="(charge ,ocindex) in details.other_charges" :key="ocindex">
                                        <td class="tdbill font-600" style="text-align: right;" colspan="3">
                                            {{charge.name}}<span
                                                v-if="charge.type == 'PERCENTAGE'">({{charge.tax_rate}}%)</span>
                                            <span v-if="charge.discount_included">
                                                <el-tooltip class="item" effect="dark"
                                                    content="Discount not applicable for this charge" placement="top">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                </el-tooltip>
                                            </span>
                                        </td>
                                        <td></td>
                                        <td class="tdbill font-600">{{charge.value}}</td>
                                    </tr>
                                    <tr v-if="details.loyalty_applied">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Reward Points</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.loyalty_amount}}</td>
                                    </tr>
                                    <tr v-if="details.total_amount">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Total Amount</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.total_amount}}</td>
                                    </tr>
                                    <tr v-for="(tax, tindex) in details.order_tax" :key="tindex">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">
                                            {{tax.name}}({{tax.rate}}%)</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{tax.value}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Gross Total</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.gross_total}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Round Off</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.round_off}}</td>
                                    </tr>
                                    <tr v-if="details.is_deposit_used">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Deposit Amount
                                        </td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.customer_deposit_used}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr v-if="details.is_deposit_used">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Loyalty Points
                                            Earned</td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.loyalty_points_earned}}</td>
                                    </tr>
                                </tfoot>
                                <tfoot>
                                    <tr>
                                        <th class="tfbill text-secondary" colspan="3"
                                            style="text-align: right;color:#303031!important;font-size:15px;background-color:#f29c1f;border-top-left-radius: 5px;border-bottom-left-radius: 5px">
                                            Grand Total</th>
                                        <td style="background-color:#f29c1f;"></td>
                                        <th class="tfbill"
                                            style="color:#303031!important;background-color:#f29c1f;border-top-right-radius: 5px;border-bottom-right-radius: 5px">
                                            {{details.grand_total}}</th>
                                    </tr>
                                </tfoot>
                                <tfoot>
                                    <tr  v-if="details.upcharge_for_credit_card_usage">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;">
                                            <span class="fw-bold fs-14" style="color: #4e5764;">Payment Type :</span>
                                            <span class="fs-14" style="color: #4e5764; font-weight: 500;">
                                                Credit Card
                                            </span>
                                        </td>
                                        <td style="padding: 0.6rem 1.2rem;" class="text-center"></td>
                                        <td style="padding: 0.6rem 1.2rem;font-size:15px;color:#303031!important;"
                                            class="value-checkout-img">
                                            <el-switch class="ml-2 approvalswitch" disabled v-model="details.is_credit_card_used"></el-switch>
                                        </td>
                                    </tr>
                                    <tr v-if="details.is_credit_card_used">
                                        <td class="tdbill font-600" colspan="3"
                                            style="text-align: right;border-top: 2px dotted #00448b;">Credit Card Charges
                                        </td>
                                        <td style="border-top: 2px dotted #00448b;"></td>
                                        <td class="tdbill font-600" style="border-top: 2px dotted #00448b;">
                                            {{details.credit_card_charges_amount}}</td>
                                    </tr>
                                    <tr>
                                        <th class="tfbill text-secondary" colspan="3"
                                            style="text-align: right;color:#303031!important;font-size:15px;background-color:#f29c1f;border-top-left-radius: 5px;border-bottom-left-radius: 5px">
                                            Grand Total with CC Charges</th>
                                        <td style="background-color:#f29c1f;"></td>
                                        <th class="tfbill"
                                            style="color:#303031!important;background-color:#f29c1f;border-top-right-radius: 5px;border-bottom-right-radius: 5px">
                                            {{details.grand_total_with_credit_card_charges}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="deposit-details pt-2 mt-4" v-if="details.is_deposit_used">
                                <span class="text-center text-white font-weight-bold d-block">Deposit Info</span>
                                <hr style="border:0.7px solid rgb(132, 136, 140);" class="ml-3 mr-3" />
                                <div class="m-4 p-2 text-white font-weight-bold">
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Issued by</div>
                                            <div class="content-right w-150">
                                                <span class="right-div whiteSpace">{{bankDetails.bank_name}}</span>
                                            </div>
                                        </div>
                                        <div class="div-content">
                                            <div class="content-left specmeter left-flex-summary">Date of Issue</div>
                                            <div class="content-right">
                                                <span class="right-div">{{bankDetails.date_of_issue_formatted}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Bank</div>
                                            <div class="content-right">
                                                <span class="right-div">{{bankDetails.bank_name}}</span>
                                            </div>
                                        </div>
                                        <div class="div-content">
                                            <div class="content-left specmeter left-flex-summary">Cheque/Transfer no
                                            </div>
                                            <div class="content-right">
                                                <span class="right-div">{{bankDetails.cheque_number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-nowrap">
                                        <div class="div-content">
                                            <div class="content-left specmeter">Deposit Note</div>
                                            <div class="content-right">
                                                <span class="right-div">{{bankDetails.deposit_notes}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table tablebill closeOrders table-striped" v-if="DeletedItemsTag">
                            <thead>
                                <tr>
                                    <th class="thbill"
                                        style="border-top-left-radius: 5px;border-bottom-left-radius: 5px;border-bottom: 0px solid #a8adb4 !important;">
                                        Items</th>
                                    <th class="thbill" style="border-bottom: 0px solid #a8adb4 !important;">Qty</th>
                                    <th class="thbill" style="border-bottom: 0px solid #a8adb4 !important;">Reason</th>
                                    <th class="thbill"
                                        style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;border-bottom: 0px solid #a8adb4 !important;">
                                        Comments</th>
                                </tr>
                            </thead>
                            <tbody v-if="details.cancelled_items.length == 0">
                                <tr style="background-color: #cddbeb !important;">
                                    <td colspan="4" class="text-center justify-content-center">
                                        <strong style="display:block;" class="pt-3">
                                            <img
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/nodeleteditems.svg" />
                                            <br />
                                            <p class="text-primary pt-3 font-600" style="font-size:18px !important;">No
                                                Items Deleted</p>
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <template v-for="(item,cancelledindex) in details.cancelled_items">
                                    <tr :key="cancelledindex">
                                        <td v-if="item.variant_name"
                                            style="padding: 1.6rem 0.8rem !important;max-width:180px !important;display:block !important;"
                                            class="text-truncate">
                                            <span v-if="item.variant_name != ''">{{item.product_name}}</span>
                                            <span v-else>{{item.product_name}}</span>
                                        </td>
                                        <td v-else
                                            style="padding: 1.6rem 0.8rem !important;max-width:150px !important;display:block !important;"
                                            class="text-truncate">{{item.product_name}}</td>
                                        <td style="padding: 1.6rem 0.8rem !important;">{{item.quantity}}</td>
                                        <td style="padding: 1.6rem 0.8rem !important;max-width:150px !important;display:block !important;"
                                            class="text-truncate">{{item.reason}}</td>
                                        <td :title="item.comments"
                                            style="max-width: 250px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 1.6rem 0.8rem !important;">
                                            {{item.comments}}</td>
                                    </tr>

                                    <!-- <tr v-if="item.modifiers" v-for="(addon, acindex) in item.modifiers.for_primary"
                                        :key="acindex">
                                        <td>
                                            <span class="text-blue font-600 d-flex">
                                                <span>{{addon.m_name}} - Small</span>
                                            </span>
                                            <span class="text-black font-600">Modifiers :</span>
                                            <div>
                                                <span :title="addon.m_item_name"
                                                    style="white-space: nowrap;width: 200px;overflow: hidden;text-overflow: ellipsis;">{{addon.m_item_name}}</span>
                                            </div>
                                        </td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.quantity}}</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.price}}</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            -</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.amount}}</td>
                                    </tr>
                                    
                                    <tr v-if="item.modifiers" v-for="(addon, acindex) in item.modifiers.for_secondary"
                                        :key="acindex">
                                        <td>
                                            <span class="text-blue font-600 d-flex">
                                                <span>{{addon.m_name}} - </span>
                                                <span>Small</span>
                                            </span>
                                            <div>
                                                <span class="text-black font-600">Modifiers :</span>
                                            </div>  
                                            <div>
                                                <span :title="addon.m_item_name"
                                                style="white-space: nowrap;width: 200px;overflow: hidden;text-overflow: ellipsis;">{{addon.m_item_name}}</span>
                                            </div>
                                        </td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.quantity}}</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.price}}</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            -</td>
                                        <td
                                            style="background: #fff;padding: 0.6rem 0.8rem!important;font-weight:600;">
                                            {{addon.amount}}</td>
                                    </tr> -->

                                    <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                            <td colspan="4" style="padding: 0px !important">
                                                <div class="text-black font-600 pt-1">
                                                        <span>{{item.variant_name || "-"}}</span>
                                                    </div>
                                                    <div class="d-block w-100 py-1" v-for="(modifier,mindex) in item.modifiers.for_secondary"  :key="'C1'+ mindex">
                                                        <div>
                                                            <span class="fw-700">{{modifier.m_item_name}}</span> 
                                                            <span class="text-green ml-1 fw-700" v-if="modifier.quantity > 0">X
                                                                {{modifier.quantity}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-block w-100 py-1" v-for="(modifier,mindex) in item.modifiers.for_primary"  :key="'C'+ mindex">
                                                        <div class="text-blue font-600">
                                                            <span>{{modifier.modifier_name}}</span>
                                                        </div>
                                                        <div v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                            <div>
                                                                <span class="fw-700">{{modifier_item.m_item_name}}</span> 
                                                                <span class="text-green ml-1 fw-700" v-if="modifier_item.quantity > 0">X
                                                                    {{modifier_item.quantity}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <!-- <table class="w-100">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="4" class="fs-14 py-1" style="padding:0.6rem 0.8rem !important">{{item.variant_name}}</td>
                                                        </tr>
                                                        <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem !important; width: 30%;">
                                                                <div class="text-blue font-600 fs-14 py-1">
                                                                    <span>{{modifier.m_name}}</span>
                                                                </div>
                                                                <div class="d-flex ml-0 pl-0">
                                                                    <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                                                </div>
                                                            </td>
                                                            <td style="padding: 0.4rem 1.2rem!important;border-radius: 0px;padding: 0.6rem 0.8rem !important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier.quantity}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier.price}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    -
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.4rem 1.2rem!important; width: 25%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier.amount}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'">
                                                        <tr>
                                                            <td colspan="6" style="border-radius: 0px;padding: 0.6rem 0.8rem !important;">
                                                                <div class="text-blue font-600 fs-14">
                                                                    <span>{{modifier.modifier_name}}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 30%;">
                                                                <div class="d-flex ml-0 pl-0">
                                                                    <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier_item.quantity}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier_item.price}}
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 15%;vertical-align:bottom;">
                                                                <div>
                                                                    -
                                                                </div>
                                                            </td>
                                                            <td style="border-radius: 0px;padding: 0.6rem 0.8rem!important; width: 25%;vertical-align:bottom;">
                                                                <div>
                                                                    {{modifier_item.amount}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> -->
                                            </td>
                                        </tr>
                                </template>
                            </tbody>
                        </table>
                        <div v-if="refund_items">
                            <div class="row justify-content-around">
                                <div class="font-weight-bold col-6" v-if="details.created_time">
                                    <span class="text-secondary whiteSpace col-6">Order Date/Time :</span>
                                    {{details.created_time.slice(3,5) + '/' + details.created_time.slice(0,2) + '/' + details.created_time.slice(6,) || '-'}}
                                </div>
                                <div class="font-weight-bold col-6" v-else>
                                    <span class="text-secondary whiteSpace col-6">Order Date/Time :</span>
                                    -
                                </div>
                                <div class="font-weight-bold col-6">
                                    <span class="text-secondary whiteSpace col-6">Order Type :</span>
                                    {{details.order_type}}
                                </div>
                            </div>
                            <div class="row justify-content-around pt-2 pb-2">
                                <div class="font-weight-bold col-6">
                                    <span class="text-secondary whiteSpace col-6">Customer Name :</span>
                                    {{details.customer_name}}
                                </div>
                                <div class="col-6" ></div>
                            </div>
                            <table v-if="refund_items_details.length > 0" class="table tablebill closeOrders table-striped">
                                <thead>
                                    <tr>
                                        <th class="thbill"
                                            style="border-top-left-radius: 5px;border-bottom-left-radius: 5px;border-bottom: 0px solid #a8adb4 !important;">
                                            Item Name</th>
                                        <!-- <th class="thbill" style="border-bottom: 0px solid #a8adb4 !important;">

                                        </th> -->
                                        <th class="thbill" style="border-bottom: 0px solid #a8adb4 !important; width: 70%;">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,refund_items) in refund_items_details" :key="refund_items+'refund_items'">
                                        <td class="text-capitalize" style="padding: 1.6rem 0.8rem !important;border-bottom: dashed 1.5px #005fa8;">{{item.product_name}}</td>
                                        <!-- <td style="padding: 1.6rem 0.8rem !important;color: #00448b !important;">

                                        </td> -->
                                        <td style="padding: 1.6rem 0.8rem !important;width: 70%;border-bottom: dashed 1.5px #005fa8;">
                                            <div class="text-secondary" style="padding: 3.5px 0 3.5px 11.5px;border-radius: 2px;background-color: #fff;">{{item.comments || "-"}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex align-items-center justify-content-between refund-item-amount mt-3">
                                <span class="fs-16 fw-500" style="color: #2d2e2e;">
                                    Total Refund
                                </span>
                                <span class="text-success fs-16 fw-600">
                                    {{details.refund_amount}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <button class="btn btn-danger btn-smm text-uppercase" @click="hideorderDetails">CLOSE</button>
                    <button class="btn btn-secondary btn-smm text-uppercase ml-2" @click="reprint()">REPRINT RECEIPT</button>
                    <!-- <button class="btn btn-success btn-smm text-uppercase text-white ml-2" v-if="details.can_item_refund" @click="showRefundPopup" :disabled="details.status == 'refund'">PAY REFUND</button> -->
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
        <view-order-refund v-if="is_view_order_refund" from_which_page="order_history" :order_id="order_id" :course_number="course_number" @hideRefundPop="hideRefundPopup" modal_name="view-order-refund" ></view-order-refund>
    </div>
</template>
<script>
    import {EventBus} from './eventBus/nav-bar-event.js'
    import printers from './mixins/printers.js'
    import { SweetModal } from "sweet-modal-vue";
    import Swal from 'sweetalert2'
    import orders from './mixins/orders.js'
    const ViewOrderRefund = () => import('./ViewOrderRefund')
    export default {
        data() {
            return {
                OrderedItemsTag: true,
                modal_msg: '',
                DeletedItemsTag: false,
                refund_items: false,
                refund_data_present: true,
                details: {
                    deposit_information: {}
                },
                bankDetails: {},
                refund_items_details: [],
                ajax_call_in_progress: false,
                is_view_order_refund: false,
                course_number: 0,
                details_error_message: ''
            }
        },
        props: ['modal_name', 'status', 'orderId', 'order_id'],
        mixins: [printers, orders],
        components:{
            Swal,
            SweetModal,
            ViewOrderRefund
        },
        methods: {
            showRefundPopup() {
                this.is_view_order_refund = true;
                setTimeout(() => {
                    this.$modal.show("view-order-refund")
                }, 500);
            },
            hideRefundPopup() {
                this.is_view_order_refund = false;
                setTimeout(() => {
                    this.$modal.hide("view-order-refund")
                    this.loadOrderDetailsData()
                    // this.$emit('hideModal')
                    
                }, 500);
            },
            hidePopup(){
                console.log(this.details.status)
                if(this.details.status == 'refund'){
                    this.is_view_order_refund = false
                } 
            },
            hideorderDetails() {
                console.log('hideorderDetails')
                this.DeletedItemsTag = false
                this.OrderedItemsTag = true
                this.refund_items = false;
                // this.$modal.hide(this.modal_name)
                // this.$validator.reset();
                this.$emit('hideModal')
                // this.errors.clear();
            },
            OrderedItems() {
                this.refund_items = false;
                this.OrderedItemsTag = true;
                this.DeletedItemsTag = false;
            },
            DeletedItems() {
                this.refund_items = false;
                this.OrderedItemsTag = false;
                this.DeletedItemsTag = true;
            },
            refundItems() {
                this.refund_items = true;
                this.OrderedItemsTag = false;
                this.DeletedItemsTag = false;
            },
            async getReceiptDetails(params) {
                return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/mobile/get_details_for_receipt', params)
                    try {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                        } else {
                            reject(response.data.reason)
                        }
                    }
                    catch(err) {
                        this.$toasted.global.error(err.response.reason)
                    }
                })
            },
            async reprint(){
                let response = await this.getReceiptDetails({order_id : this.order_id})
                Swal({
                    title: 'Re-Printing Receipt',
                    text: 'Do You Want to Re-Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    showDenyButton: true,
                    denyButtonText: `Choose Alternate Printer`,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#ff0000f2',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.store_receipt_data = response.response
                        this.printReceipt(response.response)
                        if(this.loggedInUser.enable_double_receipts) {
                            setTimeout(() => {
                                this.printReceipt(response.response)
                            }, 3000);
                        }
                    } else {
                        this.$modal.hide(this.modal_name)
                    }
                })
            },
            async loadOrderDetailsData() {
                await this.$http
                    .post('/orders/order_history_details', {
                        order_id: this.order_id
                    })
                    .then(res => {
                        console.log(res)
                        if (res.data.status_id == 1) {
                            if(res.data.response.message) {
                                this.ajax_call_in_progress = false;
                                this.details_error_message = res.data.response.message
                            } else {
                                this.bankDetails = res.data.response.deposit_information
                                this.details = res.data.response
                                this.refund_items_details = res.data.response.refund_items || []
                                if(this.refund_items_details.length) {
                                    this.refund_items_details.forEach((refund, index) => {
                                        refund['checked'] = false;
                                        refund['stored_quantity'] = parseInt(JSON.stringify(JSON.parse(refund.quantity)));
                                    })
                                }
                                this.ajax_call_in_progress = false;
                                this.hidePopup();
                            }
                        } else {
                            this.$toasted.error(res.data.reason)
                            this.ajax_call_in_progress = false;
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
            }

        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            },
        },
        async mounted() {
            console.log(this.order_id)
            this.loadOrderDetailsData();
            this.ajax_call_in_progress = true;
            // EventBus.$on('callorderDetails', async () => {
            //     this.loadOrderDetailsData()
            // })
        }
    }
</script>
<style scoped>
    .table.tablebill.closeOrders th.thbill {
        color: #fff;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 13px !important;
        padding: 5px 8px !important;
        text-transform: none !important;
        background-color: #005dae;
        height: 40px;
    }

    .table.tablebill td.tdbill {
        color: #4e5764;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 13px;
        padding: 8px 8px !important;
    }

    .table.tablebill tfoot th.tfbill {
        padding: 10px 8px !important;
    }

    /* .table.tablebill tfoot tr {
				border-top: dotted 0.5px #b8b9bb;
				border-bottom: solid 0.5px #94bef0;
			} */
    .table.tablebill tbody tr {
        height: auto !important;
    }

    a.add-customer-heading {
        font-weight: 600;
        line-height: 1.21;
        letter-spacing: 0.6px;
        color: #00448b !important;
    }

    li.ordered_item {
        height: 70px !important;
        width: 134px !important;
        background-color: #cddbeb !important;
        color: #00448b !important;
        font-weight: 600;
        text-transform: capitalize !important;
        border: 1px solid #00448b;
        margin-right: -5px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    li.deleted_items {
        height: 70px !important;
        width: 134px !important;
        background-color: #cddbeb !important;
        color: #00448b !important;
        font-weight: 600;
        text-transform: capitalize !important;
        border: 1px solid #00448b;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    li.ordered_item.ordered_items_active,
    li.deleted_items.deleted_items_active {
        background-color: #00448b !important;
        color: #fff !important;
    }

    .whiteSpace {
        white-space: nowrap !important;
    }

    .tabs_deleted_items {
        display: inline-flex;
        margin: 0 auto;
        text-align: center;
    }

    .tabs_deleted_items li {
        display: inline;
        padding: 13px 12px;
        position: relative;
    }

    ul li:hover {
        background-color: #c0c0c0;
        cursor: pointer;
    }

    ul li:hover:after {
        border-top: #c0c0c0;
    }

    ul li.ordered_items_active:after,
    ul li.deleted_items_active:after {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 8px solid #00448b;
        content: ' ';
        position: absolute;
        bottom: -9px;
        left: 42%;
    }

    .content-left.specmeter {
        color: white !important;
        flex: 0 0 130px !important;
    }

    .left-flex-summary.content-left.specmeter {
        color: white !important;
        flex: 0 0 170px !important;
    }

    .content-right {
        color: white !important;
    }

    .content-right .right-div {
        color: white !important;
    }

    .div-content {
        flex: 0 0 293px;
    }

    .deposit-details {
        border-radius: 8px;
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-image: #005dae;
    }
    .refund-data-show{
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: 0 !important;
    }
    .refund-item-amount {
        padding: 5px 11.5px 5px 11.5px;
        border-radius: 4px;
        background-color: #eeebeb;
    }
    .quantity-number-input {
        border-radius: 2px;
        border: solid 1.5px #00448b;
        outline: 0 none !important;
        color: #00448b;
        width: 50px;
        height: 20px;
        text-align: center;
        padding: 4px;
    }
</style>