<template>
    <div class="main-bg" style="background: url(/https://cdn.digicollect.com/cdn/posv2/crm-register-bg.svg) no-repeat center fixed; background-size: cover; height: 100%; background-color: #fff;">
        <div class="d-flex flex-column" style="width: 100%; height: 90%; overflow: auto;">
            <div class="top">
                <span class="dc" style="font-size: 25px; font-weight: 600;">DigiCollect</span>
                <span class="text-default-blue" style="font-size: 25px;"><img src="https://cdn.digicollect.com/cdn/posv2/crm-logo.svg" alt="crm" style="width: 40px; height: 40px;"> DigiCRM</span>
            </div>
            <div v-if="activediv == 'verify otp'" class="middle d-flex pt-5 pb-10 px-20">
                <div class="middle-left"></div>
                <div class="middle-right d-flex flex-column pb-10 pl-20">
                    <span class="text-default-blue mt-20 mb-5" style="font-size: 35px;">Verify OTP</span>
                    <span class="mb-5" style="color: #3f3f3f;">A 6 digit verification code has been sent to your <span v-if="loggedInUser.registration_type == 'email'">email ID</span> <span v-else>phone number</span> <span style="color: #0e56eb">{{ loggedin_id }}</span></span>
                    <div style="width: 35em;">
                        <div class="d-flex align-items-center mb-1">
                            <span class="text-default-heading mr-10">Enter OTP Code</span>
                            <div class="otp-div">
                                <v-otp-input
                                v-if="!otpInvalid"
                                ref="otpInput"
                                input-classes="otp-input"
                                separator=""
                                :num-inputs="6"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-complete="otpEntered"
                                />
                                <v-otp-input
                                v-else
                                ref="otpInput"
                                input-classes="otp-input-warning"
                                separator=""
                                :num-inputs="6"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-complete="otpEntered"
                                />
                            </div>
                        </div>
                        <div class="text-right pr-5 mb-10">
                            <span style="color: #f53123;font-size: 14px;" v-if="otpExpired">OTP Expired</span>
                            <span style="color: #f5a623;font-size: 14px;" v-else>OTP Valid for 15 minutes</span>
                        </div>
                        <span class="d-flex pr-5 mb-8" :class="countdown == 0 && !otpInvalid || callInProgress ? 'justify-content-end' : 'justify-content-between'">
                            <span v-if="!otpInvalid && !errorMessage && !callInProgress && !countdown == 0" style="color: #3f3f3f">Resend code in <span style="font-weight: 800;">{{countdown}}</span> seconds</span>
                            <span v-if="otpInvalid" style="color: #f61919;">Invalid OTP Code</span>
                            <span v-if="!otpInvalid && errorMessage" class="text-danger" style="font-size: 14px; font-weight: 600;">{{errorMessage}}</span>
                            <span v-if="countdown == 0 || otpInvalid" style="color: #0475f3; cursor: pointer;" @click="getOtp('resend')">Resend Code</span>
                            <span v-else-if="callInProgress" style="color: #0475f3;">Resending Code...</span>
                            <span v-else style="color: #cdd2da;">Resend Code</span>
                        </span>
                        <span>
                            <button class="btn btn-default-blue mb-5" @click="verifyOtp">Verify</button>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="activediv == 'reset successful'" class="middle d-flex pt-20 pb-10 px-20">
                <div class="middle-left"></div>
                <div class="middle-right d-flex flex-column pb-10">
                    <img class="mb-12" style="height: 12em;" src="https://cdn.digicollect.com//cdn/dc_crm_fe/static/images/images/parrot.svg" alt="parrot">
                    <p class="text-default-heading text-center mb-5" style="font-size: 30px; font-weight: 700;">Success</p>
                    <p class="text-default-heading text-center mb-10" style="font-size: 18px;">CRM Registration Successful</p>
                    <span class="text-center">
                        <button class="btn btn-default-blue mb-5" >OK</button>
                    </span>
                </div>
            </div>
            <div v-if="activediv == 'reset failed'" class="middle d-flex pt-20 pb-10 px-20">
                <div class="middle-left"></div>
                <div class="middle-right d-flex flex-column pb-10">
                    <img class="mb-12" style="height: 12em;" src="https://cdn.digicollect.com/cdn/posv2/ducks.svg" alt="parrot">
                    <p class="text-default-heading text-center mb-5" style="font-size: 30px; font-weight: 700;"></p>
                    <p class="text-default-heading text-center mb-10" style="font-size: 18px;">CRM Registration Failed</p>
                    <span class="text-center">
                        <button class="btn btn-default-blue mb-5" >OK</button>
                    </span>
                </div>
            </div>
        </div>
        <div class="footercopyright">
            <span class="copyright">© Copyright 2014 - {{ new Date().getFullYear() }} DigiCollect - All Rights Reserved.</span>
        </div>
    </div>
</template>

<script>
import global from './global';
export default {
    data() {
        return {
            activediv: "verify otp",
            countdown: 60,
            errorMessage: "",
            callInProgress: false,
            otpInvalid: false,
            otpValue: "",
            loggedin_id: "",
            otpExpired: false,
        }
    },
    methods: {
        countDownTimer() {
            if (this.countdown > 0 && this.activediv === "verify otp" && !this.otpVerifying) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                },1000);
            } else if (this.countdown === 0 && this.activediv === "verify otp" && !this.otpVerifying) {
                this.clearOtpInputs();
            }
        },
        getOtp(type) {
            this.otpInvalid = false;
            this.errorMessage = "";
            this.countdown = 60;
            this.callInProgress = true;
            this.otpVerifying = false;
            this.otpExpired = false;
            let params = {};
            if (this.loggedInUser.login_id && this.loggedInUser.login_id) {
                params = {
                    login_id: this.loggedInUser.login_id,
                };
            }
            this.$http.post(global.ADMIN_API + "/registration/resend_otp", params).then(response => {
                if (response.data.status_id == 1) {
                    this.errorMessage = "";
                    this.callInProgress = false;
                    if (type && type === "resend") {
                        this.clearOtpInputs();
                        this.countdown = 60;
                        this.countDownTimer();
                    }
                    this.otpExpireCountDown();
                } else {
                    this.errorMessage = response.data.reason || "Something went wrong";
                    this.callInProgress = false;
                }
            });
        },
        otpEntered(value) {
            this.errorMessage = "";
            this.otpValue = value;
        },
        verifyOtp() {
            this.otpVerifying = true;
            this.$http.post(global.ADMIN_API + "/registration/verify", { otp: this.otpValue }).then(response => {
                if (response.data.status_id == 1) {
                    window.location.href = global.SCM_URL;
                } else {
                    this.otpInvalid = true;
                }
            });
        },
        clearOtpInputs() {
            this.$refs.otpInput.clearInput();
            this.otpValue = "";
        },
        otpExpireCountDown() {
            let date = new date();
            if ((date.getMinutes() % 15) === 0) {
                this.otpExpired = true;
            }
        }
    },
    created() {
        if (this.loggedInUser.registration_type == "email") {
            this.loggedin_id = this.loggedInUser.login_id;
        } else {
            let phn = this.loggedInUser.login_id.split("");
            this.loggedin_id = "XXXXXX"+phn[9]+phn[10]+phn[11]+phn[12];
        }
        this.countDownTimer();
    },
    watch: {
        activediv: function(val) {
            if (val == "verify otp") {
                this.countDownTimer();
            } else {
                this.countdown = 60;
            }
        },
    },
    computed: {
      loggedInUser() {
        return this.$store.getters.loggedInUser;
      },
    },
}
</script>

<style scoped>
    .image-holder {
        width: 500px;
        background: #fff;
        height: 500px;
        border-radius: 50%;
        bottom: -90px;
        position: absolute;
        left: -60px;
    }
    .group-image {
        width: 350px;
        height: 350px;
        right: 60px;
        position: absolute;
        top: 60px;
    }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .middle-left {
        width: 50%;
    }
    .middle-right {
        width: 50%;
        max-height: 35em;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .star {
        font-size: 12px;
    }
    @media (max-width: 980px) {
        .middle {
            margin-top: 30px;
        }
        .middle-left {
            display: none;
        }
        .middle-right {
            width: 100%;
            max-height: 100%;
            justify-content: center;
        }
    }
    @media (max-width: 740px) {
        .middle {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .middle-right {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .top {
            justify-content: center;
            flex-direction: column;
        }
    }
</style>