<template>
    <div class="page-layout blank customer-mamage" v-if="loggedInUser.actions.customers">
        <div class="pb-10">
        <h2 class="headerblock">
            <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-2">
                <div
                class="list-title d-flex"
                style="font-weight: 500;"
                v-if="loggedInUser.actions.customers.write"
                >
                <router-link
                    style="color: transparent!important;"
                    :disabled="!$routerHistory.hasPrevious()"
                    :to="{ path: $routerHistory.previous().path }"
                >
                    <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                        <i class="icons icon-arrow-left"></i>
                    </button>
                    </el-tooltip>
                </router-link>
                <router-link
                    style="color: transparent!important;"
                    :disabled="!$routerHistory.hasForward()"
                    :to="{ path: $routerHistory.next().path }"
                >
                    <el-tooltip
                    class="item"
                    effect="dark"
                    content="Click to Go Forward"
                    placement="top"
                    >
                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                        <i class="icons icon-arrow-right"></i>
                    </button>
                    </el-tooltip>
                </router-link>
                <button
                    class="btn btn-secondary btn-smm"
                    @click="showCreateCustomer"
                >+ Add New Customer</button>
                </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 mt-2">
                <div class="breadcome-heading d-xl-inline">
                <div class="form-group formsearch has-warning has-feedback" style="width: 280px !important;">
                    <input
                    type="text"
                    :maxlength="searchcustomer"
                    class="form-control textremove holder"
                    id="inputWarning2"
                    aria-describedby="inputWarning2Status"
                    placeholder="Search by customer name"
                    v-model="search"
                    @input="searchCustomerInput"
                    />
                    <!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
                    <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                </div>
                </div>

                <!-- <el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
                <a
                    class="btn btn-light-grey btn-fab-dc mr-3 pull-right"
                    @click="$router.go(-1)"
                    style="margin-top: 2px;padding-top: 2px;"
                >
                    <i class="icons icon-arrow-left"></i>
                </a>
                </el-tooltip>-->
                <div class="pull-right" v-if="totalCustomers">
                <span class="page-info px-2">
                    {{start}} - {{end}} of
                    {{totalCustomers}}
                </span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                    <button
                    :disabled="count <= 0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="count--"
                    type="button"
                    class="btn btn-icon el-tooltip item"
                    >
                    <i class="icon icon-chevron-left"></i>
                    </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                    <button
                    :class="{'disabled':ajaxCallInProgress}"
                    :disabled="end >= totalCustomers"
                    v-on:click="count++"
                    type="button"
                    class="btn btn-icon el-tooltip item"
                    >
                    <i class="icon icon-chevron-right"></i>
                    </button>
                </el-tooltip>
                </div>
            </div>
            </div>
        </h2>
        <div class="category-manage-info">CUSTOMERS</div>
        <div class="page-layout carded left-sidebar mt-2"
          style="overflow: auto !important;padding-bottom: 5px;">
          <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
            <div class="page-content">
                <div class="demo-content">
                <table class="table table-customer-manage table-scroll">
                    <thead>
                    <tr>
                        <!-- <th  style="width:1%;">
                        <el-checkbox v-model="checked"></el-checkbox>
                        </th>-->
                        <th style="width:1%;flex: 0 0 57px !important"></th>
                        <th>
                        <span v-on:click="setPasskey('first_name')" class="cursor-th">
                            <span
                            v-if="passedKey.key=='first_name' && passedKey.value== -1"
                            :class="{'hide-arrow': passedKey.key != 'first_name'}"
                            class="sorting-icon"
                            >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passedKey.key != 'first_name'}"
                            class="sorting-icon"
                            >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            NAME
                        </span>
                        </th>
                        <th>
                        <span v-on:click="setPasskey('email')" class="cursor-th">
                            <span
                            v-if="passedKey.key=='email' && passedKey.value== -1"
                            :class="{'hide-arrow': passedKey.key != 'email'}"
                            class="sorting-icon"
                            >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                            </span>
                            <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passedKey.key != 'email'}"
                            class="sorting-icon"
                            >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                            </span>
                            EMAIL
                        </span>
                        </th>
                        <th class="hidden-sm-down">
                        <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                        PHONE
                        </th>
                        <th class="text-center">ORDER COUNT</th>
                        <th class="text-center">TOTAL ORDER VALUE</th>
                        <th class="text-center">Admin</th>
                    </tr>
                    </thead>
                    <!-- <tbody @scroll="infiniteScroll"> -->
                    <tbody style="height: 550px;max-height: 560px;">
                    <tr v-if="ajaxCallInProgress" style="height: 30px!important;">
                        <td
                        colspan="7"
                        class="text-center justify-content-center"
                        style="padding: 10px!important;"
                        >
                        <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        </td>
                    </tr>
                    <tr v-else-if="customers.length==0" style="height:100%;">
                        <td
                        v-if="search"
                        colspan="7"
                        class="text-center justify-content-center"
                        style="display:block;"
                        >
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                        <br />
                        <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                        >No Search Results Found</p>
                        </td>
                        <td v-else colspan="7" class="text-center justify-content-center">
                        <strong>No Customers</strong>
                        </td>
                    </tr>
                    <tr v-else v-for="(customer, index) in customers " :key="index">
                        <!-- <td v-if="customer.img_url">
                                                <img class="avatar avatar-trans mr-2" alt="Alice Freeman" :src="customer.img_url">
                                            </td>
                                            <td v-else>
                                                <img class="avatar avatar-trans mr-2" alt="Alice Freeman" src="https://cdn.digicollect.com/cdn/posv2/img/i1.png">
                        </td>-->
                        <td v-if="!customer.image" style="flex: 0 !important">
                        <avatar :username="customer.first_name" :size="40"></avatar>
                        <!-- <img src="https://cdn.digicollect.com/cdn/dcpos/pics/028bf7fa-3c39-11ea-8414-f23c91b0d497/1720350e-0091-4b26-b642-3c730f20efe7.png" style="width:41px;height:41px;border-radius:100%;" /> -->
                        </td>
                        <td v-else style="flex: 0 !important">
                        <img :src="customer.image" style="width:41px;height:41px;border-radius:100%;" />
                        </td>
                        <td
                        style="text-transform:capitalize;width: 224px;overflow: hidden;text-overflow: ellipsis;display:block;white-space: nowrap;padding-top:18px!important;text-indent:10px;color:#00448b !important;cursor:pointer !important;"
                        class="customer-name"
                        :title="customer.full_name"
                        >
                        <a v-on:click="customerDetails(customer._id)">{{customer.full_name || '-'}}</a>
                        <!-- {{customer.full_name}} -->
                        </td>
                        <!-- <td
                        style="text-transform:capitalize;width: 224px;overflow: hidden;text-overflow: ellipsis;display:block;white-space: nowrap;padding-top:18px!important;text-indent:10px;"
                        class="customer-name"
                        :title="customer.full_name"
                        >{{customer.full_name}}</td>-->
                        <td
                        style="text-transform:lowercase!important;width: 224px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;text-indent:10px;"
                        >{{customer.email || '-'}}</td>
                        <td
                        style="padding-left:0px !important;"
                        class="hidden-sm-down"
                        >{{customer.phone_number || "-"}}</td>
                        <td
                        class="text-center"
                        style="padding: 0rem 1.1rem !important;text-indent:4px;"
                        >{{customer.order_count || '-'}}</td>
                        <td class="text-center" style="text-indent:7px;">{{customer.order_total || '-'}}</td>
                        <td class="tableactions text-center" style="text-indent:4px;">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Order History"
                            placement="top"
                            v-if="loggedInUser.actions.customers.read||(loggedInUser.actions.orders&&loggedInUser.actions.orders.order_history)"
                        >
                            <router-link
                            :to="{name: 'CustomerDetails', query: { id: customer._id } }"
                            >
                            <img
                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/order-history.svg"
                                style="height: 16px;margin-top: -4px;"
                            />
                            </router-link>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Edit"
                            placement="top"
                            v-if="loggedInUser.actions.customers.write"
                        >
                            <button
                            class="btn btn-icon p-icon"
                            @click="editedCustomer(customer)"
                            >
                            <i class="icon icon-pencil text-secondary fs-20"></i>
                            </button>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Delete"
                            placement="top"
                            v-if="loggedInUser.actions.customers.delete"
                        >
                            <button
                            type="button"
                            class="btn btn-icon p-icon"
                            @click="confirmDelete(customer)"
                            >
                            <i class="icon icon-delete text-secondary fs-20"></i>
                            </button>
                        </el-tooltip>
                        </td>
                        <!-- <td class="icon-one" style="text-align: center;width: 1%;cursor:pointer;">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                            <i
                                class="icon icon-dots-vertical defaultBlue"
                                style="position: relative;bottom: 12px;"
                            ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                icon="el-icon-plus"
                                v-if="loggedInUser.actions.customers.read||(loggedInUser.actions.orders&&loggedInUser.actions.orders.order_history)"
                            >
                                <router-link
                                :to="{name: 'CustomerDetails', query: { id: customer._id } }"
                                style="text-decoration:none!important;color: #303031;"
                                >
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/order-history.svg" style="height: 15px;" />
                                <span style="padding-left:10px;">Order History</span>
                                </router-link>
                            </el-dropdown-item>
                            <el-dropdown-item
                                icon="icon icon-pencil"
                                v-if="loggedInUser.actions.customers.write"
                            >
                                <button
                                class="btn btn-icon companyactions actionicons"
                                @click="editedCustomer(customer)"
                                style="text-transform:capitalize!important;"
                                >
                                <i class="icon icon-pencil pr-2"></i> Edit
                                </button>
                            </el-dropdown-item>
                            <el-dropdown-item
                                icon="icon icon-delete"
                                v-if="loggedInUser.actions.customers.delete"
                            >
                                <button
                                type="button"
                                class="btn btn-icon actionicons"
                                @click="confirmDelete(customer)"
                                style="text-transform:capitalize!important;"
                                >
                                <i class="icon icon-delete pr-2"></i> Delete
                                </button>
                            </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        </td> -->
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
        <create-customer modal_name="customer-create" @close="closeCreateCustomer"></create-customer>
        <edit-customer modal_name="customer-edit" @close="closeEditCustomer"></edit-customer>
    </div>
    <div class="d-flex align-items-center justify-content-center w-100" v-else>
      <div class="digi-table-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
    </div>
    <!-- <for-bidden v-else></for-bidden> -->
</template>
<script>
import Avatar from 'vue-avatar'
import { SweetModal } from 'sweet-modal-vue'
import CreateCustomer from './CreateCustomer'
import EditCustomer from './EditCustomer'
import ForBidden from './ForBidden'
import customerServices from './mixins/customer'
import { EventBus } from '../components/eventBus/nav-bar-event'
import customer from './mixins/customer'

export default {
    data() {
        return {
        ajaxCallInProgress: false,
        customer: {},
        customers: [],
        count: 0,
        limit: 10,
        search: '',
        start: 0,
        end: 0,
        totalCustomers: 0,
        searchcustomer: 100,
        modal_msg: '',
        passedKey: {
            key: 'first_name',
            value: 1
        },
        customer_count: 0,
        }
    },
    computed: {
        loggedInUser() {
        return this.$store.getters.loggedInUser
        }
    },
    watch: {
        count() {
        this.fetchCustomers()
        }
    },
  components: {
    CreateCustomer,
    EditCustomer,
    Avatar,
    SweetModal,
    ForBidden
  },
  mixins: [customerServices],
  methods: {
    updateDetails() {
      this.customer.is_deposit_available = true
      this.customer.deposit = {
        issued_by: '',
        date_of_issue: '',
        bank_name: '',
        cheque_number: '',
        deposit_notes: ''
      }
    },
    closeCreateCustomer(flag) {
      this.$modal.hide('customer-create')
      if (flag) {
        this.fetchCustomers()
      }
    },
    closeEditCustomer(flag) {
      this.$modal.hide('customer-edit')
      if (flag) {
        this.fetchCustomers()
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showCreateCustomer() {
      EventBus.$emit('createcustnew')
      this.$modal.show('customer-create')
    },
    editedCustomer(customer) {
      if (customer.full_name === 'Open Guest') {
        this.$swal({
          title: 'Warning!',
          text: 'Cannot Edit Open Guest',
          type: 'warning'
        })
      } else {
        let id = customer._id
        EventBus.$emit('editCustomerApi', id)
        this.$modal.show('customer-edit')
      }
    },
    searchCustomerInput() {
      this.count = 0
      this.fetchCustomers()
    },
    async fetchCustomers() {
        try {
            this.ajaxCallInProgress = true
            let params = {
            skip: this.count * this.limit,
            limit: this.limit,
            searchdata: this.search,
            sortKey: this.passedKey.key,
            sortValue: this.passedKey.value
            }
            let res = await this.getCustomers(params)
            this.customers = res.all_customers
            this.totalCustomers = res.total_customers
            this.start = this.count * this.limit + 1
            let tmp = this.count * this.limit + this.limit
            this.end = tmp < this.totalCustomers ? tmp : this.totalCustomers
            this.ajaxCallInProgress = false
            this.customer_count = res.all_customers.length
        } catch (reason) {
            this.modal_msg = reason
            this.$refs.warning_modal.open()
        }
    },
    async infiniteScroll(e) {
        const {target} = e;
        if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                //this code will run when the user scrolls to the bottom of this div so
                //you could do an api call here to implement lazy loading
                try {
                    // this.loading = true
                    let params = {
                        skip: this.customer_count,
                        limit: 10,
                        searchdata: this.search,
                        sortKey: this.passedKey.key,
                        sortValue: this.passedKey.value
                    }
                    let result = await this.getCustomers(params)
                    if (result.status_id == 1) {
                        if (result.payload.skip == this.customer_count) {
                            let tempFiles = result.all_customers
                            tempFiles.forEach(el => {
                                this.customers.push(el)
                            })
                            this.customer_count += result.all_customers.length;
                            // this.loading = false
                        }
                        // if (result.all_customers.length < 10) {
                        //     document.getElementById('spinner').style.display = 'none'
                        //     this.loading = false
                        // }
                    }
                } catch (reason) {

                }
                // this.bottom = true;
        }
    },
    async deleteCustomer(customer) {
      try {
        let params = customer
        let res = await this.removeCustomer(params)
        this.$swal({
          title: 'Deleted!',
          text: customer.first_name + ' Customer has been deleted.',
          type: 'success'
        })
        this.fetchCustomers()
      } catch (reason) {
        this.modal_msg = reason
        this.$refs.warning_modal.open()
      }
    },
    async confirmDelete(customer) {
      try {
        if (customer.full_name === 'Open Guest') {
          this.$swal({
            title: 'Warning!',
            text: 'Cannot Delete Open Guest',
            type: 'warning'
          })
        } else {
          let result = await this.$swal({
            title: 'Are you sure?',
            text:
              'Are you sure to delete the Customer ' +
              customer.first_name +
              ' ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00448b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          })
          if (result.value) this.deleteCustomer(customer)
        }
      } catch (error) {
        // TODO: Handle Error Case
      }
    },
    setPasskey(newKey) {
      this.$set(this.passedKey, 'key', newKey)
      let value = this.passedKey.value == -1 ? 1 : -1
      this.$set(this.passedKey, 'value', value)
      if (this.count == 0) this.fetchCustomers()
      else this.count = 0
    },
    /**
     * @param {array} properties properties that you want in response.
     * @param {object} search search object.
     * @param {string} search.term search term.
     * @param {array} search.fields fields to search.
     * @param {object} sort sort object.
     * @param {string} sort.by sort by field.
     * @param {0 or 1} sort.order order by field.
     * @param {number} skip skip.
     * @param {number} limit limit.
     */
    async getCustomerWith(properties, search, sort, skip, limit) {
      // generalized function to get the provided properties from the customer collection
      try {
        let params = {
          properties: properties,
          search: search,
          sort: sort,
          skip: skip,
          limit: limit
        }
        let res = await this.customerGetCustomerWith(params)
        this.customers = res.response
      } catch (error) {
        this.modal_msg = error.response
        this.$refs.warning_modal.open()
      }
    },
    customerDetails(customer_id) {
      this.$router.push({
        name: 'CustomerDetails',
        query: { id: customer_id, display: 'about' }
      })
    }
  },
  mounted() {
    EventBus.$emit('customerdetailsLoad', false)
    EventBus.$emit('settingsLoad', false)
    EventBus.$emit('reportsLoad', false)
    if (this.$route.query.add_customer == 'true') {
      this.$modal.show('customer-create')
      EventBus.$emit('customerdetailsLoad', false)
    }
  },
  created() {
    this.fetchCustomers()
  }
}
</script>
<style>
.cursor-th {
  cursor: pointer;
}

.btn-icon:disabled {
  background: transparent !important;
}

.customer-mamage .sorting-icon {
  cursor: pointer;
  /* visibility: hidden; */
}

.hide-arrow {
  visibility: hidden;
}

th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}

td.icon-one {
  display: none !important;
}

.table-customer-manage.table td {
  padding: 1.6rem 1.2rem !important;
  font-weight: 600;
}

@media (max-width: 966px) {
  td.three-icon-td {
    display: none !important;
  }

  td.icon-one {
    display: block !important;
  }
}

/* .table td {
    padding: 0px 31px!important;
} */
.customer-name {
  text-transform: capitalize;
  font-weight: 600;
}

.customer-mamage .avatar {
  background: #00448b;
}

.customer-mamage .avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}

.avatar.avatar-trans {
  background-color: transparent !important;
}

.font-weight-bold.product-name {
  font-weight: bold !important;
  color: #00448b;
}

.v-select-label {
  font-size: 14px !important;
  /* color: #00448b; */
  color: #00448b !important;
}

.actionicons {
  padding: 0.4rem 0.3rem;
}
.table.table-scroll tbody tr {
  border-bottom: 1px solid #a8adb4;
}
.table.table-scroll tbody tr:last-child {
  border-bottom: none !important;
}
.page-content {
  background-color: transparent !important;
}
.actionicons i {
  font-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.detailsimg {
  padding-right: 1.6rem !important;
}

@media (max-width: 1200px) {
  .actionicons {
    padding: 0.4rem 0.1rem;
  }

  .actionicons i {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 1px;
  }

  .table.table-customer-manage td.tableactions {
    padding: 1.6rem 0.4rem 1.6rem 0.2rem !important;
  }

  .detailsimg {
    padding-right: 0.4rem !important;
  }
}
.fs-20{
  font-size: 20px !important;
}
.p-icon{
  padding: 0.7rem 0rem 0.9rem 0.7rem;
}
</style>
