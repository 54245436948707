<template>
    <div class="page-layout simple registerblock right-sidebar inner-sidebar">
        <div class="pb-10">
            <h2 class="headerblock pl-3 pr-3">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 d-flex">
                        <router-link
                            :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Back" placement="top">
                              <button class="btn btn-light-grey btn-fab-dc mr-3">
                                <i class="icons icon-arrow-left"></i>
                              </button>
                            </el-tooltip>
                        </router-link>
                        <router-link
                            :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item pull-left" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3">
                                  <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <a href="javascript:;" class="btn btn-secondary btn-smm ml-2">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg">
                            Filter
                        </a>
                        <date-picker
                          class="ml-3 calendarAuditAnalysisWidth"
                          v-model="dateRangeAuditCount"
                          @confirm="onDateChange()"
                          format="DD-MM-YYYY hh:mm A"
                          :first-day-of-week="7"
                          range
                          :show-second="false"
                          :shortcuts="shortcuts"
                          :lang="lang"
                          :clearable="true"
                          confirm
                          placeholder="Select Date Range"
                          :not-after="new Date()"
                          confirm-text="APPLY"
                        ></date-picker>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 d-flex justify-content-end">
                        <div class="d-flex align-items-center">
                            <span class="page-info px-2 hidden-md-down"> {{getSkipCount}} -{{total>skip+limit?skip+limit:total}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-left" @click="previousPage()"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                                    <i class="icon icon-chevron-right" @click="nextPage()"></i>
                                </button>
                            </el-tooltip>
                        </div>
                        <div class="breadcome-heading d-none d-xl-inline">
                          <div class="form-group formsearch has-warning has-feedback">
                            <input type="text" v-model="search_product" @input="searchProduct();" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                            <!-- <span class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" ></span> -->
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                          </div>
                        </div>
                        <div class="d-flex ml-3 mt-1">
                          <el-dropdown @click="handleClick" trigger="click">
                            <span class="pointer exportButton pl-2 d-flex align-items-center">
                              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg">
                              <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>PDF</el-dropdown-item>
                              <el-dropdown-item>XLSX</el-dropdown-item>
                              <el-dropdown-item>CSV</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <!-- <span class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
                            <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Pdf</span>
                          </span> -->
                          <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/printer.svg">
                            <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                          </span>
                        </div>
                    </div>
                </div>
            </h2>
            <div class="account-type-block">
                <div id="mail" style="">
                    <div class="content-section registerblock-section" style="">
                        <div class="center-section" style="background-color: #dee8f2;overflow-y: auto;margin: 10px;border-radius: 15px;padding: 10px 0px 0px 0px!important;">
                          <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 1px solid #00448b;border-radius: 18px;background-color: #fff;top: -15px;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;">
                              AUDIT - PRODUCT COUNT
                          </div>
                            <div class="crmaccounttypecard crmaccounttype-over-card personal">
                                <div class="crmaccounttypecard-body mb-0">
                                  <div class="mat-table-scm mb-0">
                                      <div class="mat-header-row-scm">
                                          <div class="mat-header-cell-scm mat-header-image"></div>
                                          <div class="mat-header-cell-scm mat-header-first-text">
                                                <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                              PRODUCT NAME
                                          </div>
                                          <div class="mat-header-cell-scm mat-header-cell-scm-second pl-2">CATEGORY</div>
                                          <div class="mat-header-cell-scm mat-header-cell-scm-third pl-2">SUB CATEGORY</div>
                                          <div class="mat-header-cell-scm pl-2">PRODUCT TYPE</div>
                                          <div class="mat-header-cell-scm mat-header-cell-scm-class pl-2">CLASS</div>
                                          <div class="mat-header-cell-scm pl-2">RECIPE/<br>VARIANT</div>
                                          <div class="mat-header-cell-scm mat-header-cell-scm-last pl-2">
                                            STARTING<br>STOCK
                                            <span class="ml-2 pointer" @click="startingStockEdit = true ,startingStockEditValue = product.starting_stock" v-if="startingStockEdit == false">
                                                <i class="icon icon-pencil text-secondary pointer fs-16"></i>
                                            </span>
                                            <div v-else-if="startingStockEdit == true">
                                                <span @click="closeStartingStock()" style="cursor: pointer">
                                                    <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                </span>
                                                <span style="cursor: pointer" @click="startStockUpdate();startingStockEdit = false">
                                                    <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                </span>
                                            </div>
                                          </div>
                                      </div>
                                      <div class="mat-row-scm text-center" style="justify-content: center;" v-if="ajaxCallinprogress">
                                        <div class="digi-table-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                        <div class="mat-row-scm text-center text-secondary font-600" v-if="!ajaxCallinprogress && !products.length && !search_product.length" style="justify-content: center;">
                                           No Products
                                        </div>
                                        <div class="mat-row-scm text-center text-secondary font-600 d-block" v-if="!ajaxCallinprogress && !products.length && search_product.length" style="justify-content: center;">
                                           <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" class="pl-20" style="padding-top: 20px;"><br>
                                           <p class="text-secondary font-20" style="font-size: 22px; font-weight: 600;">No Search Results Found</p>
                                        </div>
                                      <div v-for="(product,index) in products" :key="index" v-else>
                                            <div :class="{'producthightlight':selectedproduct == product._id && product.variant == false}" @click="getProductInfo(product._id)" class="mat-row-scm">
                                                <label style="padding-left:0 !important" class="d-flex pointer w-100">
                                                    <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                        <input type="checkbox" class="form-check-input d-none" :value="product._id" :id="product._id" v-model="checkedVariants" @click="showVariant($event)" style="margin-bottom: 0px;">
                                                        <span class="icon icon-menu-down mt-1 text-secondary" v-if="checkedVariants.includes(product._id) && product.variant == true" style="width: 20px;"></span>
                                                        <span class="icon icon-menu-right mt-1 text-secondary" v-else v-show="product.variant == true" style="width: 20px;"></span>
                                                        <div class="avatar" :class="{addspace:!product.variant}" v-if="product.image != ''">
                                                            <img class="avatar" :src="product.image">
                                                        </div>
                                                        <avatar
                                                            v-else
                                                            :username="product.name.replace(/ .*/,'')"
                                                            :src="product.image"
                                                            :size="40"
                                                            :rounded="true"
                                                            color="#fff"
                                                            :class="{addspace:!product.variant}"
                                                        ></avatar>
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-first-text">
                                                        <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                        {{product.name}}
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-second uk-text-truncate pl-2">{{product.category || "-"}}</div>
                                                    <div class="mat-cell-scm mat-cell-scm-third uk-text-truncate pl-2">{{product.sub_category || '-'}}</div>
                                                    <div class="mat-cell-scm pl-2">
                                                        {{product.product_type_id || '-'}}
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-class pl-2">
                                                        Product
                                                    </div>
                                                    <div class="mat-cell-scm pl-2">
                                                        <div class="pointer">
                                                            <button class="btn btn-icon actionicons" v-if="product.variants.length>0" style="border-radius: 0px !important;">
                                                                <el-tooltip class="item" effect="dark" content="Show Variants" placement="top">
                                                                    <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg" style="">
                                                                </el-tooltip>
                                                            </button>
                                                            <button class="btn btn-icon actionicons" v-if="product.variants.length==0" style="border-radius: 0px !important;">
                                                                <el-tooltip class="item" effect="dark" content="No Variants" placement="top">
                                                                    <img class="download-icon" src ="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg" style="">
                                                                </el-tooltip>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="mat-cell-scm mat-cell-scm-last-start pl-2">
                                                        <span v-if="startingStockEdit == false && product.variant == false">{{ formatPrice(product.starting_stock || 0) }}</span>
                                                        <input v-on:keypress="isNumber(event)" maxlength="13" @change="convertToPositive(product,'product')" v-else-if="startingStockEdit == true && product.variant == false" type="text" v-model.number="product.starting_stock" aria-label="Recipient's username" aria-describedby="basic-addon2" style="padding: 0px 3px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;width: 120px;">
                                                    </div>
                                                </label>
                                            </div>
                                        <!-- Variants -->
                                        <div class="mat-row-scm" :class="{'varianthightlight':selectedvariant == index1 && selectedproduct==product._id}" v-show="checkedVariants.includes(product._id)" v-for="(variant,index1) in product.variants" :key="index1" @click="getProductInfo(product._id, index1)">
                                            <div class="mat-cell-scm mat-cell-scm-image uk-text-truncate">
                                                <!-- <div class="avatar ml-5">
                                                    <img class="avatar" src="https://cdn.digicollect.com/cdn/posv2/avatars/1.jpg">
                                                </div> -->
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-first-text pointer">
                                                <span class="category-badge category-badge--transparent category-badge--dot category-badge--xl mr-1"></span>
                                                {{variant.variant_name}}
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-second uk-text-truncate pl-2">{{product.category || "-"}}</div>
                                            <div class="mat-cell-scm mat-cell-scm-third uk-text-truncate pl-2">{{product.sub_category || '-'}}</div>
                                            <div class="mat-cell-scm pl-2">
                                                {{product.product_type_id || '-'}}
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-class pl-2">
                                                Products
                                            </div>
                                            <div class="mat-cell-scm pl-2">
                                                <!-- <div class="pointer">
                                                    <button class="btn btn-icon actionicons" v-if="product.variants.length>0" style="border-radius: 0px !important;">
                                                        <el-tooltip class="item" effect="dark" content="Show Variants" placement="top">
                                                            <img class="download-icon" src ="https://cdn.digicollect.com/cdn/posv2/scm/variant.svg" style="">
                                                        </el-tooltip>
                                                    </button>
                                                    <button class="btn btn-icon actionicons" v-if="product.variants.length==0" style="border-radius: 0px !important;">
                                                        <el-tooltip class="item" effect="dark" content="No Variants" placement="top">
                                                            <img class="download-icon" src ="https://cdn.digicollect.com/cdn/posv2/scm/novariant.svg" style="">
                                                        </el-tooltip>
                                                    </button>
                                                </div> -->
                                            </div>
                                            <div class="mat-cell-scm mat-cell-scm-last-start pl-2">
                                                <span v-if="startingStockEdit == false">{{formatPrice(variant.starting_stock || 0) }}</span>
                                                <input v-on:keypress="isNumber(event)" @change="convertToPositive(variant,'variant')" v-else type="text" maxlength="13" v-model.number="variant.starting_stock" aria-label="Recipient's username" aria-describedby="basic-addon2" style="padding: 0px 3px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;width: 120px;">
                                            </div>
                                        </div>
                                        <!-- End Variants -->
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="fuse-sidebar crmaccounttype-sidebar sidebar left-positioned open locked-open" style="border-radius: 15px;overflow-y: auto;overflow-x: hidden;margin: 10px 5px;box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);border: solid 1px #00ffeb;background-image: linear-gradient(to bottom, #00448b, #0c95fe);">
                            <div class="preview-elements details-block" v-if="!product">
                                <div class="px-3 py-2">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/noproducts.svg" class="img-fluid" style="width: 280px;margin: 60px auto 0px;display: block;">
                                    <h5 style="font-size: 18px;color: #303031;margin: 30px auto;line-height: 1.21;letter-spacing: 0.41px;text-align: center;font-weight: 600;">
                                        Please Select a Product to View Details
                                    </h5>
                                </div>
                            </div>
                            <div class="preview-elements details-block" v-else>
                                <div class="row">
                                    <div class="col-lg-12 mt-2">
                                        <div class="px-3 py-2">
                                            <!-- <div class="btnheader">
                                                {{product.name || product.variant_name }}
                                            </div> -->
                                            <div class="btnheader" v-if="product.variant">
                                                {{product.name}} - {{product.variant_name }}
                                            </div>
                                            <div class="btnheader" v-else>
                                                {{product.name}}
                                            </div>
                                            <div class="table-responsive" style="overflow: hidden !important;">
                                                <table class="table">
                                                    <thead>
                                                        <tr style="border-bottom: 1px solid transparent !important;height: 40px;">
                                                            <th class="text-uppercase" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 50%;padding: 3px 5px !important;border-bottom: 1px solid #fff !important;">
                                                                Unit of Measurement (UM) :
                                                            </th>
                                                            <th class="text-uppercase" v-if="!uomEdit" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 30%;padding: 3px 5px !important;border-bottom: 1px solid #fff !important;">
                                                                {{product.uom.name || '-'}}
                                                            </th>
                                                            <th class="text-uppercase" v-if="!uomEdit" style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 20%;padding: 3px 5px !important;border-bottom: 1px solid #fff !important;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="uomEdit = true; uomEditValue = product.uom">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <th colspan="2"  style="text-transform: capitalize !important;color: #303031;font-size: 13px !important;width: 50%;padding: 3px 5px !important;border-bottom: 1px solid #fff !important;" v-if="uomEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <!-- <input type="text" v-model="uomEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;"> -->

                                                                    <!-- <v-select v-model="uomEditValue" :title="uomEditValue.name" :options="measurements" @search="loadMeasurements" value="name" label="name" placeholder="Select UM" class="um-select text-um max-select clearNone"/> -->
                                                                    <multiselect data-vv-as="UM" v-validate="'required'"
                                                                        v-model="uomEditValue" class="digi-multiselect um-multi-select" id="ajax" label="name"
                                                                        placeholder="Select UM" :options="measurements"
                                                                        :multiple="false" :searchable="true" :loading="isLoading"
                                                                        :internal-search="false" :clear-on-select="false" :close-on-select="true"
                                                                        :options-limit="40" :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false" @search-change="loadMeasurementsSearch">
                                                                        <template slot="tag" slot-scope="{ option, remove }">
                                                                            <span class="custom__tag">
                                                                                <span>{{ option.name }}</span>
                                                                                <span class="custom__remove" @click="remove(option)">❌</span>
                                                                            </span>
                                                                        </template>
                                                                        <template slot="afterList">
                                                                            <div v-observe-visibility="reachedEndOfList" />
                                                                        </template>
                                                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results
                                                                            Found.</span>
                                                                    </multiselect>
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;position: absolute: right: 1px;">
                                                                        <span style="cursor: pointer" @click="uomEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'uom':uomEditValue});uomEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 50%;">
                                                                    Stock In (From Suppliers) :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;" v-if="!stockInEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                                    {{ product.stock_in || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;" v-if="!stockInEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="stockInEdit = true; stockInEditValue = product.stock_in">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="stockInEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="number" v-model.number="stockInEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="stockInEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'stock_in':stockInEditValue});stockInEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 50%;">
                                                                    Free of Charge (FOC) :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;" v-if="!freeOfChargeEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                                    {{ product.free_of_charge || 0 }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;" v-if="!freeOfChargeEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 20%;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="freeOfChargeEdit = true; freeOfChargeEditValue = product.free_of_charge">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="freeOfChargeEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="number" v-model.number="freeOfChargeEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="freeOfChargeEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'free_of_charge':freeOfChargeEditValue});freeOfChargeEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;border-bottom: 1px solid rgb(255, 255, 255) !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Transfer In :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;border-bottom: 1px solid rgb(255, 255, 255) !important;width: 30%;" v-if="!transferInEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.transfer_in || 0}}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;border-bottom: 1px solid rgb(255, 255, 255) !important;width: 20%;" v-if="!transferInEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="transferInEdit = true; transferInEditValue = product.transfer_in">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 3px 5px !important;width: 50%;border-bottom: 1px solid rgb(255, 255, 255) !important;" v-if="transferInEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="number" v-model.number="transferInEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="transferInEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'transfer_in':transferInEditValue});transferInEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Quantity Sold :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.sold || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 20%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <el-tooltip class="item" effect="dark" content="System Generated" placement="top">
                                                                        <span style="cursor: not-allowed !important;">
                                                                            <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                        </span>
                                                                    </el-tooltip>
                                                                </span>
                                                            </td>
                                                            <!-- <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="quantitySoldEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="quantitySoldEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="quantitySoldEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'sold':quantitySoldEditValue});quantitySoldEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td> -->
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Transfer Out :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;" v-if="!transferOutEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.transfer_out || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 20%;" v-if="!transferOutEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="transferOutEdit = true; transferOutEditValue = product.transfer_out">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="transferOutEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="transferOutEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="transferOutEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'transfer_out':transferOutEditValue});transferOutEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Wastage :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.wastage || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 20%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <el-tooltip class="item" effect="dark" content="System Generated" placement="top">
                                                                        <span style="cursor: not-allowed !important;">
                                                                            <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                        </span>
                                                                    </el-tooltip>
                                                                </span>
                                                            </td>
                                                            <!-- <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="wastageEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="wastageEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="wastageEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'wastage':parseFloat(wastageEditValue)});wastageEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td> -->
                                                        </tr>
                                                        <tr style="height: 0px;">
                                                            <td style="padding: 3px 5px !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Void :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.void || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 20%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <el-tooltip class="item" effect="dark" content="System Generated" placement="top">
                                                                        <span style="cursor: not-allowed !important;">
                                                                            <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                        </span>
                                                                    </el-tooltip>
                                                                </span>
                                                            </td>
                                                            <!-- <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="voidEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="voidEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="voidEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'void':parseFloat(voidEditValue)});voidEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td> -->
                                                        </tr>
                                                        <tr style="background-color: #dee8f2;height: 0px;width: 50%;">
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 6px 0px 0px 6px;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    POS Final Quantity :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    {{ product.pos_final_quantity || '-' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 0px 6px 6px 0px;width: 20%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    <span style="cursor: pointer"  @click="showFinalQuantity()">
                                                                        <i class="icon icon-information ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #00448b;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr style="height: 30px;">
                                                            <td style="padding: 3px 5px !important;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    Stock Count :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;" v-if="!stockCountEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    {{ product.stock_count || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 20%;" v-if="!stockCountEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="stockCountEdit = true; stockCountEditValue = product.stock_count">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #303031;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="stockCountEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="stockCountEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="stockCountEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'stock_count':parseFloat(stockCountEditValue)});stockCountEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr style="background-color: #005dae;height: 0px;">
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 6px 0px 0px 6px;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    Variance (Qty) :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    {{ product.variance || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 0px 6px 6px 0px;width: 20%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #00448b;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #00448b;"></i>
                                                                    </span>
                                                                    <el-tooltip class="item" effect="dark" content="System Generated" placement="top">
                                                                        <span style="cursor: not-allowed !important;">
                                                                            <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #00448b;"></i>
                                                                        </span>
                                                                    </el-tooltip>
                                                                </span>
                                                            </td>
                                                            <!-- <td colspan="2" style="padding: 3px 5px !important;width: 50%;" v-if="varianceEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #00448b !important;height: 25px;">
                                                                    <input type="text" v-model="varianceEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #303031 !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="varianceEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'variance':parseFloat(varianceEditValue)});varianceEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td> -->
                                                        </tr>
                                                        <tr style="height: 40px;">
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 6px 0px 0px 6px;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #fff;">
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;width: 30%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #fff;">
                                                                </span>
                                                            </td>
                                                            <td style="padding: 3px 5px !important;border-collapse: separate;border-radius: 0px 6px 6px 0px;width: 20%;">
                                                            </td>
                                                        </tr>
                                                        <tr style="background-image: linear-gradient(to bottom, #005dae, #004fe3);height: 0px;">
                                                            <td style="padding: 5px 5px !important;border-collapse: separate;border-radius: 6px 0px 0px 6px;width: 50%;">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #fff;">
                                                                    Re-Order Level :
                                                                </span>
                                                            </td>
                                                            <td style="padding: 5px 5px !important;width: 30%;" v-if="!reorderEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #fff;">
                                                                    {{ product.reorder_level || '0' }}
                                                                </span>
                                                            </td>
                                                            <td style="padding: 5px 5px !important;border-collapse: separate;border-radius: 0px 6px 6px 0px;width: 20%;" v-if="!reorderEdit">
                                                                <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #fff;">
                                                                    <span style="cursor: pointer">
                                                                        <i class="icon icon-eye ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                                                    </span>
                                                                    <span style="cursor: pointer" @click="reorderEdit = true; reorderEditValue = product.reorder_level">
                                                                        <i class="icon icon-pencil ml-1" style="font-size: 16px;width: 16px;height: 16px;line-heiight: 16px;color: #fff;"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td colspan="2" style="padding: 5px 5px !important;width: 50%;" v-if="reorderEdit">
                                                                <div class="input-group" style="border-bottom: 1px solid #fff !important;height: 25px;">
                                                                    <input type="text" v-model="reorderEditValue" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' aria-label="Recipient's username" aria-describedby="basic-addon2" style="width: 1% !important;border-bottom: 0px solid transparent !important;padding: 0px 0px !important;height: 25px !important;line-height: 25px !important;color: #fff !important;font-weight: 600 !important;">
                                                                    <div class="input-group-append" style="height: 24px;line-height: 24px;margin-left: 0px;margin-right: 18px;">
                                                                        <span style="cursor: pointer" @click="reorderEdit = false">
                                                                            <i class="icon icon-close-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #e82828;"></i>
                                                                        </span>
                                                                        <span style="cursor: pointer" @click="editValueUpdate({'reorder_level':parseFloat(reorderEditValue)});reorderEdit = false">
                                                                            <i class="icon icon-check-circle ml-1" style="font-size: 20px;width: 16px;height: 16px;line-heiight: 16px;color: #1a9347;"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <final-quantity modal_name="final-quanity-modal" :product="product"></final-quantity>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
const FinalQuantity = () => import('./FinalQuantity.vue');
import ScmServices from '../mixins/scm.js'
import Avatar from 'vue-avatar';
import Swal from "sweetalert2";
export default {
    data() {
        return {
          itemstatus: false,
          products:[],
          search_product:"",
          skip: 0,
          limit: 10,
          total:0,
          checkedVariants:[],
          product: false,
          ajaxCallinprogress: false,
          selectedvariant: null,
          selectedproduct: null,
          startDate: new Date(new Date().getFullYear(), 0, 1),
          endDate: new Date(new Date().getFullYear() + 1, 0, 1),
          dateRangeAuditCount: [],
          startingStockEdit: false,
          startingStockEditValue: 0,
          stockInEdit: false,
          stockInEditValue: 0,
          freeOfChargeEdit: false,
          freeOfChargeEditValue: 0,
          transferInEdit: false,
          transferInEditValue: 0,
          //quantitySoldEdit: false,
          //quantitySoldEditValue: 0,
          transferOutEdit: false,
          transferOutEditValue: 0,
          //wastageEdit: false,
          //wastageEditValue: 0,
          //voidEdit: false,
          //voidEditValue: 0,
          stockCountEdit: false,
          stockCountEditValue: 0,
          startStockEdit: false,
          varianceEdit: false,
          varianceEditValue: 0,
          reorderEdit: false,
          reorderEditValue: 0,
          uomEdit: false,
          uomEditValue: 0,
          measurements: [],
            isLoading: false,
            count: 0,
            limit: 10,
            unitMeasure: [],
            scrollable: false,
          lang: {
                type: "en",
                days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                months: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ],
                pickers: [
                    "next 7 days",
                    "next 30 days",
                    "previous 7 days",
                    "previous 30 days"
                ],
                placeholder: {
                    date: "Select Date",
                    dateRangeAuditCount: "Select Date Range"
                }
            },
            confirmText: {
                type: String,
                default: "APPLY"
            },
            shortcuts: [
                {
                    text: "Today",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate()
                        );
                        this.endDate = new Date();
                        this.dateRangeAuditCount = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Week",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate() - new Date().getDay()
                        );
                        this.endDate = new Date();
                        this.dateRangeAuditCount = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Month",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                        );
                        this.endDate = new Date();
                        this.dateRangeAuditCount = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                },
                {
                    text: "This Year",
                    onClick: () => {
                        this.startDate = new Date(
                            new Date().getFullYear(),
                            0,
                            1
                        );
                        this.endDate = new Date();
                        this.dateRangeAuditCount = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
                    }
                }
            ],

        };
    },
    mixins:[ScmServices],
    components: {
      FinalQuantity,
      DatePicker,
      Avatar,
      Swal
    },
    methods: {

      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        convertToPositive(product,obj){
            if(product.starting_stock < 0){
                product.starting_stock = product.starting_stock * -1;
            }
            return product.starting_stock
        },
      closeStartingStock(){
          this.startingStockEdit = false
          this.loadProducts();
      },
      showEditStock(){
          this.startingStockEdit = true
      },
      showFinalQuantity(){
        this.$modal.show('final-quanity-modal');
      },
      handleClick() {
        },
        handleTrigger(e){
            e.stopPropagation();
        },
      onDateChange() {
        this.endDate;
        this.dateRangeAuditCount[1];
        if (!this.dateRangeAuditCount[0] && !this.dateRangeAuditCount[1]) {
            this.startDate = new Date();
            this.startDate;
            this.endDate = new Date();
            this.dateRangeAuditCount = [this.startDate, this.endDate];
        }
      },
      showVariant(e){
        if (e.target.checked) {
        //  console.log(e.target.value)
        }
      },
      searchProduct(){
          this.skip = 0;
          this.loadProducts()
      },
      nextPage() {
        if(this.startingStockEdit == true){
            Swal.fire(
                {
                    type: 'warning',
                    title: 'Warning',
                    text: 'Please Save Changes To Proceed Further',
                }
            )
        }
        else{
            let skip_temp = this.skip + this.limit > this.total? this.total: this.skip + this.limit;
            if (skip_temp >= this.total) {
                return;
            }
            this.skip = skip_temp;
            this.loadProducts();
        }
      },
      previousPage() {
        if(this.startingStockEdit == true){
            Swal.fire(
                {
                    type: 'warning',
                    title: 'Warning',
                    text: 'Please Save Changes To Proceed Further',
                }
            )
        }
        else{
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.loadProducts();
        }
      },
      async loadProducts(){
          this.products = []
          this.ajaxCallinprogress = true;
          let response = await this.getScmProducts(
              {
                  "search_key":this.search_product,
                  "skip":this.skip,
                  "limit":this.limit
              }
          );
          this.products = response.response
          this.total = response.total
          this.ajaxCallinprogress = false;
      },
      async getProductInfo(productid, index = null){
          this.selectedproduct = null
          this.selectedvariant = null
          let response = await this.getScmProductDetails(
              {
                  "_id":productid,
              }

          );
          this.product = response.response
          if(this.product.variant) {
              this.product = this.product.variants[index];
              this.product.variant=true;
          }
          this.selectedvariant = index
          this.selectedproduct = productid
          this.uomEdit = false
          this.product.name = response.response.name
        //   this.startingStockEdit = false,
          this.startingStockEditValue =  0,
          this.stockInEdit = false,
          this.stockInEditValue =  0,
          this.freeOfChargeEdit = false,
          this.freeOfChargeEditValue = 0,
          this.transferInEdit = false,
          this.transferInEditValue = 0,
          this.transferOutEdit = false,
          this.transferOutEditValue = 0,
          this.stockCountEdit = false,
          this.stockCountEditValue = 0,
          this.varianceEdit = false,
          this.varianceEditValue = 0,
          this.reorderEdit = false,
          this.reorderEditValue = 0
      },
      async loadMeasurementsSearch(search) {
            this.isLoading = true
            let response = await this.getScmMeasurements({
                search_key: search,
                skip: 0,
                limit: 10
            })
            this.measurements = response.unit_of_measurements
            this.count = this.measurements.length
            this.isLoading = false
            this.scrollable = false
        },
        async reachedEndOfList(reached) {
            if (reached) {
                if (this.scrollable == false) {
                    this.isLoading = true
                    let response = await this.getScmMeasurements({
                        skip: this.count,
                        limit: 10
                    })
                    this.unitMeasure = response.unit_of_measurements.length;
                    if (response.skip == this.count) {
                        if (this.unitMeasure > 0) {
                            let tempFiles = response.unit_of_measurements;
                            tempFiles.forEach(el => {
                                this.measurements.push(el);
                            });
                            this.count += response.unit_of_measurements.length;
                            this.isLoading = false
                        } else {
                            this.scrollable = true
                            this.isLoading = false
                        }
                    }
                    this.isLoading = false
                }
            }
        },
      async loadMeasurements(search){
          let response = await this.getScmMeasurements(
               {
                  "search_key":search,
                  'skip':0,
                  'limit':10
               }
          );
          if(response.skip == this.count){
            this.unitMeasure = []
            this.measurements = response.unit_of_measurements
            this.count = response.unit_of_measurements.length
          }
      },
      editValueUpdate(variable) {
          if(variable){
            let data = variable
            if (this.product.variant) {
                data.id = this.product.id
            }
            this.$http.post("/scm/update_product_values", {_id:this.selectedproduct,variant: this.product.variant, details: data}).then(result => {
                if (result.data.status_id) {
                    this.product = result.data.response
                    if(this.product.variant) {this.product = this.product.variants[this.selectedvariant]; this.product.variant = true}
                }
                })
            }
            this.loadMeasurements();
      },
      async startStockUpdate() {
            let start_stock = []
            let params = [this.products.forEach(product => {
              if(product.variant == true){
                product.variants.forEach(variant => {
                    start_stock.push({
                        "product_id": product._id,
                        "variant_id": variant.id,
                        "variant": product.variant,
                        "starting_stock": variant.starting_stock
                    })
                })
              }
              else{
                start_stock.push({
                    "product_id": product._id,
                    "variant": product.variant,
                    "starting_stock": product.starting_stock
                })
              }
            })]
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/scm/update_multiple_stock_counts", {'data':start_stock})
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
    },
    created() {
        this.loadProducts();
        this.loadMeasurements('');
    },
    computed: {
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        }
    },
};
</script>
<style scoped>
.page-layout.simple.registerblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.registerblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  /* background-color: #00448b; */
  background-color: #00448b;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.crmaccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.fuse-sidebar.crmaccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.crmaccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  background-color: #00448b;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerblock.left-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section,
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.crmaccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.crmaccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.crmaccounttype-list-item .crmaccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.crmaccounttype-list-item .crmaccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.crmaccounttype-list-item img.crmaccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.crmaccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #00448b;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
.mat-table-scm {
    background-color: transparent;
    box-shadow: none;
    display: block;
    margin: 0px 0px 20px;
}
.mat-header-row-scm, .mat-row-scm{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 0px 0px;
}

.mat-header-cell-scm {
    font-size: 14px;
    font-weight: 600;
    /* color: #00448b; */
    color: #00448b;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
    text-transform: uppercase;
}
.mat-cell-scm, .mat-footer-cell, .mat-header-cell-scm {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
}

@media (max-width: 1280px) {
    .mat-header-cell-scm.mat-header-first-text, .mat-cell-scm.mat-cell-scm-first-text {
        flex: 1 1 170px;
        box-sizing: border-box;
        max-width: 170px;
        min-width: 170px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-second, .mat-cell-scm.mat-cell-scm-second {
        flex: 1 1 100px;
        box-sizing: border-box;
        max-width: 100px;
        min-width: 100px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-third, .mat-cell-scm.mat-cell-scm-third {
        flex: 1 1 100px;
        box-sizing: border-box;
        max-width: 100px;
        min-width: 100px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-class, .mat-cell-scm.mat-cell-scm-class {
        flex: 1 1 70px;
        box-sizing: border-box;
        max-width: 70px;
        min-width: 70px;
    }
    .mat-header-cell-scm.mat-header-cell-scm-last, .mat-cell-scm.mat-cell-scm-last-start {
        flex: 1 1 140px;
        box-sizing: border-box;
        max-width: 140px;
        min-width: 140px;
    }
}
.mat-header-cell-scm {
    box-shadow: 4px 0 0px -2px #94bef0;
}
.mat-header-cell-scm:last-child, .mat-header-cell-scm:first-child  {
    box-shadow: none;
}
.mat-header-cell-scm.mat-header-image, .mat-cell-scm.mat-cell-scm-image {
    flex: 1 1 60px;
    box-sizing: border-box;
    max-width: 60px;
    min-width: 60px;
}
.mat-header-cell-scm.mat-header-last, .mat-cell-scm.mat-cell-scm-last {
    flex: 1 1 85px;
    box-sizing: border-box;
    max-width: 85px;
    min-width: 85px;
}
.mat-header-row-scm {
    min-height: 44px;
    padding-left: 10px;
}
.mat-table-scm .mat-row-scm {
    position: relative;
    cursor: pointer;
    min-height: 60px;
    background-color: transparent;
    border-bottom: 1px solid #a8adb4;
}
.mat-table-scm .mat-row-scm:hover {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.mat-header-row-scm, .mat-row-scm {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    border-bottom-color: #a8adb4;
    padding-left: 10px;
}
.mat-cell-scm .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    font-weight: 400!important;
    line-height: 1;
}
.mat-cell-scm, .mat-footer-cell {
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}

.avatar.avatar-border {
    border: 1.5px solid rgb(205, 210, 218);
    background-color: rgba(255, 255, 255, 0);
    width: 47px !important;
    height: 47px !important;
    border-radius: 50%;
    display: inline-block;
    min-width: 47px !important;
}
.avatar.avatar-border img {
    width: 37px !important;
    height: 37px !important;
    border-radius: 50%;
    min-width: 37px !important;
    max-width: 37px !important;
    max-height: 37px !important;
}
.mat-cell-scm .list-group .list-group-item h3 {
    font-size: 14px !important;
    font-weight: 600;
}
.mat-cell-scm .list-group .list-group-item p {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #4e5764 !important;
}
.mat-cell-scm .list-group .list-group-item>.avatar {
    margin: 0 1rem 0 0 !important;
}
.des-list-box {
    border-radius: 5px;
    padding: 7px 10px;
    color: #fff;
    width: 110px;
    text-align: center;
}
.mat-row-scm:hover .eyeIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .editIcon {
    color: #00448b !important;
}

.mat-row-scm:hover .deleteIcon {
    color: #00448b !important;
}
.mat-row-scm:hover .cloudIcon {
    color: #e82828 !important;
}
.list-group.list-cat {
    padding: 8px 30px;
}
.list-group.list-cat .list-group-item.two-line {
    min-height: 5.2rem;
}
.list-group.list-cat .list-group-item {
	background-color: transparent !important;
}
.list-group.list-cat .list-group-item h3 {
    font-size: 18px !important;
    font-weight: bold;
    line-height: 1.22;
  	letter-spacing: 0.77px;
    color: #fff;
    padding-bottom: 5px;
}
.list-group.list-cat .list-group-item p {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 1.23;
  	letter-spacing: 0.56px;
    color: #fff;
}
.category-badge.category-badge--dot.category-badge--xl {
    line-height: 15px;
    min-height: 15px;
    min-width: 15px;
    height: 15px;
    width: 15px;
    font-size: 0;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    border-radius: 50%;
    border: 2px solid #fff;
}
.category-badge.category-badge--veg {
    color: #fff;
    background: #008000;
}
.category-badge.category-badge--nonveg {
    color: #fff;
    background: #d40000;
}
.category-badge.category-badge--liquid {
    color: #fff;
    background: #fec502;
}
.category-badge.category-badge--transparent {
    color: transparent;
    background: transparent;
    border: 2px solid transparent !important;
}
.nav-tabs.item-tabs .nav-link {
    color: #00448b;
    border-radius: 6px;
    border: 1px solid #00448b;
    height: 46px;
    margin: 0 10px;
    background-color: #fff;
    font-weight: 600;
    width: 95px;
}
.nav-tabs.item-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #00448b;
}
.nav-tabs.item-tabs .nav-link.active:not(.dropdown-toggle):after, .nav-tabs.item-tabs .nav-item.show .nav-link:not(.dropdown-toggle):after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: transparent !important;
}
.btnheader {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(103deg, #005dae -12%, #004fe3 89%);
    padding: 3px 5px;
    min-width: 10rem;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px auto 10px;
    max-width: 25rem;
}
.digi-list-timeline__items {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}


.digi-list-timeline__items_stocks {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}

.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px 0px 0px 6px;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 0px 6px 6px 0px;
}
.addspace {
    margin-left: 20px;
}
.varianthightlight{
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #f5a623 !important;
    background-color: #dee7f3 !important;
    margin: 0 2px;
}
.producthightlight {
    box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #00448b !important;
    background-color: #dee7f3 !important;
}
.exportButton{
    width: auto;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #1a9347;
}
.exportButton img{
    width:13px;
}
.pdfButton img{
    width:13px;
}
.printButton img{
    width:13px;
}
.pdfButton{
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    background-color: #e82828;
    width: 66px;
    font-size: 14px;
}
.printButton{
    width: 66px;
    font-size: 14px;
    padding-right: 7px;
    height: 25px;
    border-radius: 4px;
    /* background-color: #00448b; */
    background-color: #00448f;
}
.export-index{
    z-index: 99999 !important;
}
.el-dropdown-menu__item{
    color: #303030 !important;
}
</style>