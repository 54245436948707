<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                <span class="v-modal-dialog-title v-modal-title">Edit {{editName}}</span>
                <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                    <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                </span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-3">
                        <div class="row first-middle-input">                    
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="routerName"
                                        autofocus
                                        required
                                        v-model.trim="edit_router.name"
                                        :maxlength="routerName"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Enter Router Name
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('routerName')"
                                        style="display:inline;"
                                    >{{ errors.first('routerName') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{routerName - edit_router.name.length}} / {{routerName}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">                                    
                                    <input
                                        class="inputContact"
                                        v-validate="'required'"
                                        type="text"
                                        name="routerPassword"
                                        autofocus
                                        required
                                        v-model.trim="edit_router.password"
                                        :maxlength="routerPassword"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Enter Router Password
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('routerPassword')"
                                        style="display:inline;"
                                    >{{ errors.first('routerPassword') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{routerPassword - edit_router.password.length}} / {{routerPassword}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row first-middle-input">                    
                            <div class="col-md-6">
                                <div class="form-group mt-0 contactGroup">
                                    <input
                                        class="inputContact"
                                        v-validate="'required|ip'"
                                        type="text"
                                        name="routerIP"
                                        autofocus
                                        required
                                        v-model="edit_router.ip_address"
                                        :maxlength="routerIP"
                                        autocomplete="off"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Enter IP Address
                                        <sup>★</sup>
                                    </label>
                                    <span
                                        class="invalid-feedback-form"
                                        v-show="errors.has('routerIP')"
                                        style="display:inline;"
                                    >{{ errors.first('routerIP') }}</span>
                                    <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                    >{{routerIP - edit_router.ip_address.length}} / {{routerIP}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="v-modal-footer text-center pt-5">
                        <button class="btn btn-link btn-smm" @click="cancel" :disabled="edit_router_api_inprogress">CANCEL</button>
                        <button
                            class="btn btn-secondary btn-smm text-uppercase ml-5"
                            @click="save" :disabled="edit_router_api_inprogress"
                        >SAVE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_router_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import NetworkSettings from './mixins/networksettings'
import { EventBus } from './eventBus/nav-bar-event.js'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    data(){
        return {
            routerName:30,
            routerPassword:30,
            routerIP:15,
            edit_router:{
                name:'',
                password:'',
                ip_address:'',
                type:''
            },
            editName:'',
            edit_router_api_inprogress: false
        }
    },
    props:['modal_name'],
    mixins: [NetworkSettings],
    components: {
        HalfCircleSpinner
    },
    methods:{        
        cancel(){
            this.$modal.hide(this.modal_name)
            this.edit_router = {
                name:'',
                password:'',
                ip_address:'',
                type:''
            }
        },
        async save() {
            try {
                this.edit_router_api_inprogress = true;
                const result = await this.$validator.validateAll()
                if (result) { 
                    let name= this.edit_router.name
                    let password = this.edit_router.password
                    let ip_address = this.edit_router.ip_address
                    let type = this.edit_router.type
                    let _id = this.edit_router._id
                    let editRouter = {
                        name,
                        password,
                        ip_address,
                        type,
                        _id
                    }                              
                    let response = await this.updateSetting(editRouter)
                    this.$emit('editrouter')
                    this.$swal({
                        title: 'Router Updated Successfully',
                        text: '',
                        type: 'success'
                    })                
                    this.cancel()
                    this.edit_router_api_inprogress = false;
                }
            } catch (reason) {               
                this.$swal('Warning', reason, 'warning') 
                this.edit_router_api_inprogress = false;
            }
        },
    },
    created() {   
        EventBus.$on('getRouterDetailsByID',routerId=>{
            this.$http.get('/outlet/get_network/' +routerId).then(response => {
                if (response.data.status_id == 1) {                    
                    this.edit_router = response.data.response
                    this.editName = response.data.response.name                   
                }
            })
        })     
        const dict = {
            custom: {
                routerName: {
                    required: () => 'Router Name is Required'
                },
                routerPassword: {
                    required: () => 'Router Password is Required'
                },
                routerIP: {
                    required: () => 'Router IP is Required',
                    ip:() => 'Enter a Valid IP Address'
                }
            }
        }
        this.$validator.localize('en', dict)
    },
    destroyed() {
      EventBus.$off('getRouterDetailsByID')
    }
}
</script>