import Swal from 'sweetalert2'
export default {
     data(){
          return{

          }
     },
     components:{
          Swal
     },
     methods:{
          async managePaymentDevice(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.get('/orders/payment/card/get_devices', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          async getPayDevices() {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.get('/orders/payment/card/get_active_devices')
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          async getMerchantData(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.post('/outlet/payment/card/merchant_id', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          async getPaymentDeviceList(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.get('/orders/payment/card/get_active_devices', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          
          async payAmount(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.post('/orders/payment/online', params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                         this.warning_msg = error.response.data.reason
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                         this.$refs.warning_modal.close();
                         }, 3000);
                    }
               })
          },
          async checkOnlinePaymentStatus(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.get('/orders/payment/status?transaction_id='+params)
                         console.log(response)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                    }
               })
          },
          async generatePaymentQrCode(params) {
               return new Promise(async (resolve, reject) => {
                    try{
                         const response = await this.$http.get('/orders/payment/get_qr_code?orderId='+params)
                         if(response.data.status_id == 1){
                              resolve(response.data)
                         } else {
                              reject(response.data.reason)
                         }
                    } catch(error) {
                         reject(error.response.data.reason)
                    }
               })
          },
     }
}