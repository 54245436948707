<template>
    <div>
        <modal :name="modal_name" class="select-addons-modal final-modal" :class="{'modalZindex':safariTrue}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">PRODUCT INFO</span>
                    <span @click="hideproduct()">
                        <i class="icon icon-close pointer"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-1 modal-background" style="max-height: 400px;overflow-x: hidden;overflow-y: auto;background-color: #f3f3f3 !important;">
                    <div class="v-modal-layout" style="padding-right: 10px;">
                        <div class="d-flex p-3 flex-column">
                            <div class="row no-gutters mb-5">
                                <div class="details-button mr-5 pointer" @click="companyTimeline()" :class="{ activeClass : isCompanyTimeline == true }">
                                    <span class="text-white font-bold">Details</span>
                                </div>
                                <div class="borderRight mr-5"></div>
                                <div class="details-variants mr-5 pointer" @click="relationshipPage()" :class="{activeClass: isCompanyRelationship == true}">
                                    <span class="text-white font-bold">Variants</span>
                                </div>
                                <div class="borderRight mr-5 "></div>
                                <div class="details-gallery pointer" @click="galleryPage()" :class="{activeClass: isCompanygallery == true}">
                                    <span class="text-white font-bold">Gallery</span>
                                </div>
                            </div>
                            <div v-show="isCompanyTimeline">
                                <div class="fill" style="width:100%; height:200px">
                                    <img v-if="product_details.image" :src="product_details.image" alt="" class="">
                                    <img v-else src="https://cdn.digicollect.com/cdn/pos/images-new-theme/img-un.svg" alt="" class="">
                                </div>
                                <h5 class="text-center mt-2 mb-2 font-500">{{product_details.name}}</h5>
                                <div class="d-flex p-3 flex-column main-info mb-5">
                                    <div class="borderBottom mt-2">
                                        <span class="font-500 fs-14">Description</span>
                                        <p class="pt-1 pb-1 fs-14">{{product_details.description || '-'}}</p>
                                    </div>
                                    <div class="row mt-2 mb-2 borderBottom no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">Status</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <a class="item-summary-product-inactive" v-if="product_details.status == false">In Active</a>
                                             <a class="item-summary-product-active" v-if="product_details.status == true">Active</a>
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-2 borderBottom no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">Category</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <span class="fs-14" v-if="product_details.category_id">
                                                {{product_details.category_id.category_name || '-'}}
                                            </span>
                                            <span v-else class="fs-14">
                                                -
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2 borderBottom mb-2 no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">Sub Category</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <span class="fs-14" v-if="product_details.sub_category_id">
                                                {{product_details.sub_category_id.sub_category_name || '-'}}
                                            </span>
                                            <span v-else class="fs-14">
                                                -
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2 borderBottom mb-2 no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">Product Type</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <span class="fs-14" v-if="product_details.product_type_id">
                                                {{ product_details.product_type_id.name}}
                                            </span>
                                            <span v-else class="fs-14">
                                                -
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2 borderBottom mb-2 no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">Brand</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <span class="fs-14" v-if="product_details.brand">
                                                {{product_details.brand.name}}
                                            </span>
                                            <span v-else class="fs-14">
                                                -
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="row mt-2 borderBottom mb-5 no-gutters">
                                        <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                            <span class="fs-14">No. of Variants Available</span>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                            <span class="fs-14">
                                            fghf
                                            </span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div v-show="isCompanyRelationship">
                                <div class="main-info p-4 mb-5" v-for="(item,index) in product_details.variants" :key="index">
                                    <div class="body-sections-alternative" >
                                            <div class="body-sections-head-alternative mb-3">
                                                <span class="body-sections-header-alternative font-600">{{item.variant_name}}</span>
                                            </div>
                                        <div class="description-text-header-right">
                                            <span @click="collapseVariant(item)" class="collapse-addon">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" v-if="showVariantInfo.includes(item.variant_name)" style="width: 25px;bottom: 20px !important;position: absolute;left: -35px;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width: 25px;bottom: 20px !important;position: absolute;left: -35px;" v-else>
                                                <!-- <img src="https://cdn.digicollect.com/cdn/posv2/main-collapse.svg" class="rotateIcon"  style="width: 25px;bottom: 20px !important;position: absolute;left: -35px;"> -->
                                                <span class="borderrotate"></span>
                                            </span>
                                        </div>
                                        </div>
                                        <div v-if="showVariantInfo.includes(item.variant_name)">
                                            <div >
                                            <div class="row mt-2 mb-2 borderBottom no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Quantity</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="">
                                                    {{item.variant_quantity}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Size or Measure</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="">
                                                    {{item.measure || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Unit of Measure</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="" v-if="item.uom">
                                                        {{item.uom.name || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Product Code</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="">
                                                        {{item.product_code || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Product ID</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="">
                                                    {{item.product_id || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Stock Keeping Unit</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="">
                                                    {{item.stock_keeping_unit || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2 borderBottom mb-2 no-gutters">
                                                <div class="col-lg-8 col-md-8 col-sm-8 p-1">
                                                    <span class="">Sale Location</span>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 text-left p-1">
                                                    <span class="" v-for="outlet in item.outlet_id" :key="outlet._id">
                                                    {{outlet.name}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="orderTypeTable">
                                                <table class="table">
                                                    <thead>
                                                        <th>Order Type</th>
                                                        <th>Selling Price</th>
                                                        <th>Gross Margin</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(y,yi) in item.yield_manager_details" :key="yi" style="height:36px !important;">
                                                            <td>{{y.order_type}}</td>
                                                            <td>{{y.price}}</td>
                                                            <td>{{y.gross_margin}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div v-show="isCompanygallery" class="main-info p-4">
                            <el-upload
                              :action="$serverURL + 'products/upload_file_to_gallery?product_id='+product_details['_id']"
                              list-type="picture-card"
                              :file-list="gallerylist"
                              :on-preview="handlePictureCardPreview"
                              :params="params"
                              :headers="headers"
                              :on-remove="handleRemove"
                            >
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Click and Add Images"
                                placement="top-start"
                              >
                                <span class="icon icon-plus"></span>
                              </el-tooltip>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible" :modalAppendToBody="false">
                              <img width="100%" :src="image" alt />
                            </el-dialog>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<style>
.item-summary-product-active {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 12px;
    font-size: 14px !important;
    text-transform: capitalize;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100px;
    font-weight: 600;
    line-height: 3.3rem;
    height: 3.3rem;
    color: #fff !important;
    white-space: nowrap;
    background-color: #4caf50;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.item-summary-product-inactive {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 12px;
    font-size: 14px !important;
    text-transform: capitalize;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100px;
    font-weight: 600;
    line-height: 3.3rem;
    height: 3.3rem;
    color: #fff !important;
    white-space: nowrap;
    background-color: #e82828;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.fs-14 {
    font-size: 14px;
}
div.fill {
    position:relative;
    border-radius: 15px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden
}
div.fill img {
    flex-shrink:0;
    min-width:100%;
    min-height:100%
}
.font-bold{
    font-weight: bold;
}
.details-button{
    background: #29c2e0;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    padding: 10px;
    width: 29%;
}
.details-variants{
    background: #29c2e0;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    padding: 10px;
    width: 29%;
}
.details-gallery{
    background: #29c2e0;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    padding: 10px;
    width: 29%;
}
.borderrotate {
    right: 36px;
    border-bottom: 1px solid #a9aaad;
    position: absolute;
    bottom: 30px;
    width: 450px;
}
.body-sections-product {
  border-radius: 2px;
}
.body-sections-head {
  background-color: #00448b;
  padding: 10px;
}
.body-sections-header {
  color: #ffffff;
  margin: 0;
  font-size: 15px;
}
.body-sections-alternative {
  border-radius: 2px;
}
.body-sections-head-alternative {
  background-color: #ffea92;
  padding: 10px;
}
.body-sections-header-alternative {
  color: #303031;
  margin: 0;
  font-size: 15px;
}
.borderBottom{
    border-bottom: 1px solid #a9aaad;
}
.borderRight{
    border-right: 1px solid #d5d5d5;
}
.main-info {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    border-radius: 5px;
}
.font-500{
    font-weight: 500 !important;
}
.modal-bg-combo {
    background-color: #f3f3f3 !important;
}
 .modal-background {
    background-color: #f3f3f3 !important;
}
.combo-image img {
    object-fit: cover !important;
}
.combo-image{
    border-radius: 15px;
    width: 100%;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
}
.unavailable-addons {
    opacity: 0.6 !important;
    pointer-events: none;
    position: relative;
    vertical-align: middle;
    top: 28%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    text-align: center;
}
.whiteSpace{
    white-space: nowrap;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.word-break{
    word-break: break-word;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.variant-addon-img{
    cursor: pointer;
    width: 174px;
    height: 95px;
    text-align: center;
    border-radius: 7px;
    padding-top: 5px;
    -webkit-box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
.v-modal-layout{
    overflow: initial !important;
}
.addon-main{
    width: 121px;
    margin-left: 7px;
}
.addon-note{
    height: 96px!important;
    border: 1px solid #b2b2b3!important;
    border-radius: 7px!important;
    padding: 10px 6px!important;
}
.addon-quantity{
    position: relative;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.w-52{
    width: 62px !important;
}
.orderTypeTable thead{
    background: #005dae !important;
}
.orderTypeTable thead th{
    color: #fff !important;
}
.orderTypeTable tbody tr{
    border: 1px solid rgba(162, 141, 141, 0.16) !important;
}
.total-addon{
    box-shadow: 0 2px 4px 0 rgba(162, 141, 141, 0.16);
    background-image: linear-gradient(97deg, #005dae 20%, #004fe3 99%);
    border-radius: 7px;
}
.add-custom-addon{
    width: 75px !important;
    color: #fff !important;
    border-radius: 8px!important;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #fff!important;
}
.bg-green-image{
    background-image: linear-gradient(83deg, #1a9347 8%, #1ca04d 53%, #20b256 93%);
}
.bg-blue-image{
    background-image: linear-gradient(92deg, #004fe3 0%, #005dae 103%);
}
.bg-orange-image{
    background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
}
.bg-orange-image:focus{
    outline: 0px auto -webkit-focus-ring-color!important;
    color: #303031!important;
}
.b-r-7{
    border-radius: 7px;
}
.w-30{
    width: 28px;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.preferences{
    border-radius: 18px;
    border: solid 1px #b2b2b3;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    margin-left: 4px;
}
.PointerEventsNone{
    pointer-events: none !important;
}
	/* .post{
	  width: 220px;
	  height: 80px;
	} */
	.post .line.left {
		float: left;
		width: 140px;
		height: 16px;
		border-radius: 7px;
		background-image: linear-gradient(
			90deg,
			#f4f4f4 0px,
			rgba(229, 229, 229, 0.8) 40px,
			#f4f4f4 80px
		);
		background-size: 600px;
		animation: shine-lines 2s infinite ease-out;
	}
	.product-var .content-left.specmeter {
		color: #303031 !important;
	}
	.post .line.right {
		width: 140px;
		height: 16px;
		border-radius: 7px;
		background-image: linear-gradient(
			90deg,
			#f4f4f4 0px,
			rgba(229, 229, 229, 0.8) 40px,
			#f4f4f4 80px
		);
		background-size: 600px;
		animation: shine-lines 2s infinite ease-out;
	}
	.post .avatar + .line {
		margin-top: 11px;
		width: 100px;
	}
	.post .line ~ .line {
		background-color: #ddd;
	}
	@keyframes shine-lines {
		0% {
			background-position: -100px;
		}
		40%,
		100% {
			background-position: 140px;
		}
	}
	@keyframes shine-avatar {
		0% {
			background-position: -32px;
		}
		40%,
		100% {
			background-position: 208px;
		}
	}
	.product-var .content-right .right-div {
		color: #303031 !important;
	}
	.line-title {
		width: 140px;
		height: 16px;
		border-radius: 7px;
		background-color: #e4eee9;
		background-image: linear-gradient(315deg, #e4eee9 0%, #b8c0d4 74%);
		background-size: 600px;
		animation: shine-lines 2s infinite ease-out;
	}
	.el-select {
		display: inline-block;
		position: relative;
		width: 100% !important;
	}
	.companyTimelineTab {
		border: 1px solid #00448b;
		height: 34px !important;
		color: #00448b !important;
		background: #fff !important;
		line-height: 32px !important;
		text-transform: none !important;
		padding: 5px 15px 5px 15px;
		font-size: 14px !important;
		cursor: pointer;
		font-weight: 500;
		border-radius: 20px 0 0 20px !important;
	}
	.companyRelationTab {
		border: 1px solid #00448b;
		height: 34px !important;
		color: #00448b !important;
		background: #fff !important;
		line-height: 32px !important;
		text-transform: none !important;
		padding: 5px 15px 5px 15px;
		font-size: 14px !important;
		font-weight: 500;
		border-left: none !important;
		cursor: pointer;
		margin-left: -3px !important;
		/* border-radius: 0 20px 20px 0 !important; */
	}
	.companyGalleryTab {
		border: 1px solid #00448b;
		height: 34px !important;
		color: #00448b !important;
		background: #fff !important;
		line-height: 32px !important;
		text-transform: none !important;
		padding: 5px 15px 5px 15px;
		font-size: 14px !important;
		font-weight: 500;
		cursor: pointer;
		border-left: none !important;
		border-radius: 0 20px 20px 0 !important;
		margin-left: -3px;
	}
	.activeClass {
		background: #00448b !important;
		color: #fff !important;
		font-weight: bold;
	}
	.taxes-heading {
		font-size: 14px;
		font-weight: bold;
		color: #00448b;
		padding-left: 14px;
	}
	.v-modal-layout {
		max-height: 400px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.contactGroup select.form-control {
		max-width: 100% !important;
		max-width: 100% !important;
	}
	.el-upload--picture-card {
		color: #00448b !important;
		font-weight: 600 !important;
	}
	.el-input__inner {
		color: #303031 !important;
	}
	.el-upload .icon-plus {
		background-color: #00448b;
		color: #fff;
		border-radius: 100px;
	}
	.el-upload-list--picture-card
		.el-upload-list__item-actions
		.el-upload-list__item-delete {
		display: none !important;
	}
	.variantInfo td {
		padding: 0.6rem 1.2rem !important;
	}
	.variantBorderBottom {
		border-top: 1px solid rgb(168, 173, 180);
	}
	.variantBorderBottom:first-child {
		border-top: none !important;
	}
</style>

<script>
	import { EventBus } from './eventBus/nav-bar-event.js'
	import productServices from './mixins/products.js'
	import VueContentLoading from 'vue-content-loading'
	import EditProduct from './EditProduct'

	export default {
		props: ['modal_name', 'productId'],
		data() {
			return {
        safariTrue:false,
				showVariantsTr: false,
				skeleton: true,
				editTax: [],
				editVariants: [],
				dialogImageUrl: '',
				dialogVisible: false,
				isCompanyTimeline: true,
				isCompanyRelationship: false,
				isCompanygallery: false,
				show: false,
				imgDataUrl: '', // the datebase64 url of created image
				product_details: {
					name: '',
					variant: '',
					stock_keeping_unit: '',
					_id: '',
					tags: '',
					description: '',
					category: '',
					sub_category: '',
					product_type: '',
					brand: '',
					quantity: '',
					status: '',
					price: '',
					tax: '',
					variants: ''
				},
				disabled: true,
				image: '',
				picture_message: 'Upload Image',
				modal_msg: '',
				variants_list: [],
				gallerylist: [],
				params: {
					Authorization: this.$cookie.get('Token')
				},
				headers: {
					Authorization: this.$cookie.get('Token')
				},
				showdiv: false,
				editProduct: false,
				showVariantInfo: []
			}
		},
		components: {
			VueContentLoading,
			EditProduct
		},
		methods: {
			collapseVariant(item) {
				if (this.showVariantInfo.find(x => x === item.variant_name)) {
					this.showVariantInfo = this.showVariantInfo.filter(function(x) {
						if (x !== item.variant_name) {
							return x
						}
					})
				} else {
					this.showVariantInfo.push(item.variant_name)
				}
			},
			editProductDetails(productId) {
				this.editProduct = true
				this.$modal.hide('product_info')
				EventBus.$emit('editProduct', {
					productId: productId,
					isProductEdit: true
				})
				this.$modal.show('product-edit')
			},
			companyTimeline() {
				this.isCompanyTimeline = true
				this.isCompanyRelationship = false
				this.isCompanygallery = false
			},
			relationshipPage() {
				this.isCompanyRelationship = true
				this.isCompanyTimeline = false
				this.isCompanygallery = false
			},
			galleryPage() {
				this.isCompanyRelationship = false
				this.isCompanyTimeline = false
				this.isCompanygallery = true
			},
			hideproduct(modal_name) {
				EventBus.$emit('closeProductInfo', modal_name)
				this.$modal.hide('product_info')
				this.isCompanyTimeline = true
				this.isCompanyRelationship = false
				this.isCompanygallery = false
				this.skeleton = true
				this.showdiv = false
				// e.preventDefault()
				// e.stopPropagation()
			},
			handleRemove(file) {
				file.product_id = this.product_details._id
				this.$http
					.post('/products/remove_products_from_gallery', {
						product_id: this.product_details._id,
						url: file.url
					})
					.then(res => {
						if (res.data.status_id == 1) {
							this.modal_msg = 'Removed Image From Gallery'
						}
					})
			},
			handlePictureCardPreview(file) {
				// this.productObj.gallery.push(file)
				if (file.url) {
					this.image = file.url
					this.dialogVisible = true
				} else {
					this.image = file.response.url
					this.dialogVisible = true
				}
			},
			create_gallery_json(gallery) {
				this.gallerylist.splice(0, this.gallerylist.length)
				gallery.forEach(element => {
					var obj = new Object()
					obj.name = 'image.jpg'
					obj.url = element
					this.gallerylist.push(obj)
				})
			},
			change_variant_status(status, product_id, variant_name) {
				this.$http
					.post('/products/change_product_variant_status', {
						status: status,
						product_id: product_id,
						variant_name: variant_name
					})
					.then(res => {
						if (res.data.status_id == 1) {
							EventBus.$emit('ProductStatusChange', {
								product_id: product_id,
								status: res.data.response
							})
						}
					})
				EventBus.$emit('ProductVariantChange', {
					product_id: product_id,
					status: status,
					name: variant_name
				})
			}
		},
		// mixins:[productServices],
		mounted() {
      this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			EventBus.$on('ProductVariant', product_id => {
				this.$http
					.post('products/get_product_by_id', { _id: product_id })
					.then(res => {
						if (res.data.status_id == 1) {
							this.product_details = res.data.response.result
							this.gallery = res.data.response.result.gallery
							this.skeleton = false
							this.showdiv = true
							if (this.gallery) {
								this.create_gallery_json(this.gallery)
							}
						}
					})
			})
			EventBus.$on('viewProductDetails', edited_product => {
				this.$http
					.post('products/get_product_by_id', { _id: edited_product._id })
					.then(res => {
						if (res.data.status_id == 1) {
							this.product_details = res.data.response.result
							this.gallery = res.data.response.result.gallery
							this.skeleton = false
							this.showdiv = true
							if (this.gallery) {
								this.create_gallery_json(this.gallery)
							}
						}
					})
			})
		},
		destroyed() {
			EventBus.$off('ProductVariant')
			EventBus.$off('viewProductDetails')
		}
	}
</script>
