<template>
    <div class="page-layout blank closed-orders">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="d-flex col-lg-1 col-md-1 col-sm-3">
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-left"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                    </div>
                    <div class="d-flex justify-content-center col-lg-10">
                        <h5 style="font-weight:bold">VIEW COMBO</h5>
                    </div>
                </div>
            </h2>
            <div class="body-sections">
                <div class="body-sections-head mb-5">
                    <span class="body-sections-header font-600">General Section</span>
                    <button class="btn btn-smm btn-secondary pull-right" @click="editComboDetails(details._id)" style="background:#fff !important;color:#303031 !important;">
                      <i class="icon icon-pencil fs-18" style="color:#00448b !important;"></i>
                      Edit
                    </button>
                </div>
                <div class="row mx-0 justify-content-center mb-10">
                    <div class="col-lg-2">
                        <span style="text-align:center;margin-left:20px">
                            <img v-if="details.image_url == ''" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg" style="width:90px!important;height:90px!important;"/>
                            <img v-else :src="details.image_url" style="width:90px!important;height:90px!important;"/>
                        </span>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="font-weight-bold">Combo Name</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2"></div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="font-weight-bold">
                            {{details.name}}
                        </span>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center borderTop mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="font-weight-bold">Category</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2"></div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="font-weight-bold" v-if="details.category_id">{{details.category_id.name}}</span>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center borderTop mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="font-weight-bold">Sub Category</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2"></div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="font-weight-bold" v-if="details.sub_category_id">{{details.sub_category_id.name}}</span>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center borderTop mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="font-weight-bold">Product Type</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2"></div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="font-weight-bold" v-if="details.product_type_id != ''">{{details.product_type_id.name}}</span>
                        <span v-else class="font-weight-bold">-</span>
                    </div>
                </div>
                <div v-if="details.combo_items">
                    <div class="body-sections my-10" v-for="(product, index) in details.combo_items" :key="index">
                        <div class="body-sections-head mb-5">
                            <span class="body-sections-header font-600">Product - {{index+1}}</span>
                        </div>
                        <div class="product-table" style="overflow-x: auto">
                            <table class="table combos-product-table" style="background-color: #dee8f2">
                                <thead>
                                    <tr>
                                        <th>CATEGORY</th>
                                        <th>SUB-CATEGORY</th>
                                        <th>PRODUCT TYPE</th>
                                        <th>PRODUCT</th>
                                        <th>VARIANT</th>
                                        <th>QUANTITY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(alternative, aindex) in product.alternatives" :key="aindex">
                                        <td v-if="alternative.category_id">{{alternative.category_id.name}}</td>
                                        <td v-if="alternative.sub_category_id">{{alternative.sub_category_id.name}}</td>
                                        <td v-if="alternative.product_type_id && alternative.product_type_id != ''">{{alternative.product_type_id.name}}</td>
                                        <td v-else>-</td>
                                        <td v-if="alternative.product_id">{{alternative.product_id.name}}</td>
                                        <td v-if="alternative.variant_id">{{alternative.variant_id.variant_name}}</td>
                                        <td v-else>-</td>
                                        <td v-if="alternative.quantity">{{alternative.quantity}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="body-sections-head mb-5">
                    <span class="body-sections-header font-600">Yield Manager</span>
                </div>
                <div class="row mx-0 justify-content-center mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="font-weight-bold">Base Combo Price</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2"></div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <span class="font-weight-bold">
                            <span v-if="details.outlet_currency">{{details.outlet_currency.symbol}}</span>
                            {{details.combo_price}}
                        </span>
                    </div>
                </div>
                <div v-if="details.yield_manager_details">
                    <div class="product-table" style="overflow-x: auto">
                        <table class="table combos-product-table" style="background-color: #dee8f2">
                            <thead>
                                <tr>
                                    <th>ORDER TYPE</th>
                                    <th>SELLING PRICE</th>
                                    <th>GROSS MARGIN
                                        <el-popover placement="right-end" width="400" trigger="hover" popper-class="combo-popover">
                                            <div class="d-flex">
                                                <div>
                                                    <div class="mt-2 ml-2">Gross Margin (%) = </div>
                                                </div>
                                                <div>
                                                    <div> Selling Price - Base Combo Price</div>
                                                    <div class="text-center borderTop">Selling price</div>
                                                </div>
                                                <div>
                                                    <div class="mt-2 ml-2"> * 100</div>
                                                </div>
                                            </div>
                                            <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                                        </el-popover>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(yielddata, yindex) in details.yield_manager_details" :key="yindex">
                                    <td>
                                        {{yielddata.order_type}}
                                    </td>
                                    <td>
                                        <div class="input-group payment-currency">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">$</span>
                                            </div>
                                            <input style="padding: 10px !important;" readonly  type="number" class="form-control" v-validate="'required'" v-model.number="yielddata.price">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group payment">
                                            <el-tooltip content="Autofilled" placement="top">
                                                <i class="icon icon-check-circle fs-14 text-success mt-1px" style="position: absolute;right: 22px;z-index: 9999;"></i>
                                            </el-tooltip>
                                            <input style="padding: 10px !important;" type="number" readonly class="form-control" v-model.number="yielddata.gross_margin">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <button class="btn btn-secondary btn-smm" @click="cancel ">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event'
export default {
    data(){
        return{
            details: {
                status:false,
                name: '',
                image_url: '',
                sub_category_id: '',
                category_id: '',
                product_type_id: '',
                combo_items:[
                    {
                        alternatives: [
                            {
                                category_id:'',
                                sub_category_id:'',
                                product_type_id:'',
                                product_id:'',
                                variant_id:'',
                                quantity:null
                            }
                        ]   
                    },
                ],
                combo_price: null,
                yield_manager_details: [
                    {
                        order_type: '',
                        price: null,
                        gross_margin: null
                    }
                ]
            },
        }
    },
    methods:{
        showCombo(){
            this.combodisplay = true;
             let params ={
                _id:this.$route.query.id
            }
            setTimeout(() => {
                EventBus.$emit('getComboData',params)
                this.$modal.show('combo_info')
            }, 500);
        },
        async getComboById(){
            try {
                let params = {
                    _id:this.$route.query.id
                }
                let res = await this.$http.post('/combos/get_combo_by_id',params)
                if(res.data.status_id == 1){
                    this.details = res.data.combo
                }else{
                    this.$swal({
                        title: res.data.reason,
                        text: '',
                        type: 'warning'
                    })
                    this.$router.push({
                        name: 'ComboManage'
                    })
                }
                
            } catch (reason) {
                
            }
        },
        cancel(){
            this.$router.push({
                name: 'ComboManage'
            })
        },
        editComboDetails(id){
            this.$router.push({
                name: 'EditCombo',
                query: { id: id },
                params:{
                    view:true
                }
            })
        }
    },
    mounted(){
        this.getComboById()
    }
}
</script>
<style scoped>
.body-sections {
  margin-left: 20px;
  margin-right: 20px;
}
.body-sections-head {
  background-color: #00448b;
  padding: 15px;
}
.body-sections-header {
  color: #ffffff;
  margin: 0;
  font-size: 15px;
}
.input-head {
  color: #4e4e4e;
  font-weight: bold;
  font-size: 14px;
}
.inputContact {
  padding: 12px 10px 2px 0px !important;
  border-bottom: 1px solid #4e4e4e !important;
}
.inputContact::placeholder {
  color: #4e4e4e !important;
  font-weight: normal !important;
}
.combos-product-table th,
td {
  font-size: 12px !important;
  font-weight: bold;
}
#quantity-input {
  background-color: transparent;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #a9aaad;
  line-height: 25px;
  padding-top: 7px;
}
#quantity-input input[type=number]::placeholder {
  color: #303031 !important;
}
.add-th,
.add-td {
  background-color: #ffffff;
  border-bottom: 0 !important;
}
.input-prev {
  width: 20px;
  text-align: center;
  color: #303031;
  height: 25px;
  background-color: #cdd2da !important;
  padding: 0 !important;
}
.input-after {
  height: 25px;
  padding: 0 !important;
  width: 60%;
}
.borderTop{
    border-top: 1px solid #303031;
}
.payment .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.payment .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
.payment-currency .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.payment-currency .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: 27px !important;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    text-align: left;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.payment-currency.input-group {
    position: relative;
    display: -webkit-box;
    height: 27px !important;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

}
.payment-currency.input-group .input-group-prepend {
    margin-right: 0rem !important;
}
</style>