<template>
  <div>
    <modal :name="modal_product_type_information" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span
            class="v-modal-dialog-title v-modal-title"
            v-if="productTypes.length > 1"
          >PRODUCT TYPES</span>
          <span class="v-modal-dialog-title v-modal-title" v-else>PRODUCT TYPE</span>
        </div>
        <div class="v-modal-body pr-1">
          <div
            class="v-modal-layout pt-2 pr-3"
            style="max-height: 410px !important; overflow-x: hidden !important;overflow-y: scroll !important"
          >
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary pl-4"
                  v-if="productTypes.length > 1"
                >{{ totalProducType }} Product Types</div>
                <div
                  v-else
                  class="list-title text-secondary pl-4"
                >{{ totalProducType }} Product Type</div>
              </div>
              <div class="group mb-3 pl-4 pr-4">
                <input
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  @input="getAllProductType('search')"
                  v-model="search"
                />
              </div>
              <div v-if="totalProducType" style="text-align: right;">
                <span
                  class="page-info px-2 hidden-md-down"
                >{{start}} - {{end}} of {{totalProducType}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button
                    :disabled="count <=0"
                    :class="{'disabled':ajaxCallInProgress}"
                    v-on:click="getAllProductType('prev')"
                    type="button"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-left"></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button
                    :disabled="end >= totalProducType"
                    :class="{'disabled':ajaxCallInProgress}"
                    type="button"
                    v-on:click="getAllProductType('next')"
                    class="btn btn-icon d-none d-md-inline el-tooltip item"
                  >
                    <i class="icon icon-chevron-right"></i>
                  </button>
                </el-tooltip>
              </div>
            </div>
            <div class style="border: 1px solid #a8adb4 !important;border-radius: 7.5px;">
              <table class="table productTable table-scroll">
                <thead class="products-variants-table">
                  <tr>
                    <th
                      style="width: 1%;border-radius: 7.5px 0px 0px 0px;border-collapse: separate;flex:0 0 55px !important"
                    ></th>
                    <th>Name</th>
                    <th>No. of Products</th>
                    <th>Status</th>
                    <th style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;">Admin</th>
                  </tr>
                </thead>
                <tbody v-if="ajaxCallInProgress">
                  <tr style="height:20px!important;" class="mt-5">
                    <td colspan="6" class="text-center justify-content-center">
                      <div class="digi-table-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="productTypes.length == 0 && !ajaxCallInProgress">
                  <tr v-if="!search">
                    <td colspan="6" class="text-center justify-content-center">
                      <strong>No Product Type</strong>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="6" class="text-center justify-content-center">
                      <strong>No Product Type Found</strong>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="productType in productTypes" :key="productType._id">
                    <td style="width:1%;flex:0 0 55px !important">
                      <img
                        v-if="productType.image_url"
                        class="avatar avatar-trans mx-2"
                        :src="productType.image_url"
                      />
                      <div v-else>
                        <avatar
                          class="mx-2"
                          :username="productType.name.replace(/ .*/,'')"
                          :src="productType.image_url"
                          :size="40"
                          :rounded="true"
                          color="#fff"
                        ></avatar>
                      </div>
                    </td>
                    <td
                      class="mt-2"
                      style="width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"
                    >{{productType.name}}</td>
                    <td>{{productType.number_of_products}}</td>
                    <td>
                      <el-switch
                        v-model="productType.status"
                        @change="productTypeStatus(productType.status,productType._id)"
                      ></el-switch>
                    </td>
                    <td>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Edit Product Type"
                        placement="top"
                      >
                        <button
                          @click="showEditProductType(productType._id)"
                          class="btn btn-icon companyactions actionicons"
                          style="padding: 0.4rem .2rem;"
                        >
                          <i class="icon icon-pencil"></i>
                        </button>
                      </el-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <button
              type="submit"
              class="btn btn-secondary btn-smm"
              @click="hideProductTypeInformation()"
            >CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
    <edit-product-type
      modal_edit_product_type="edit-product-type"
      :productTypeById="productTypeById"
      :productTypesById="productTypesById"
      :source="'fromCategory'"
      @saved="getAllProductType()"
    ></edit-product-type>
  </div>
</template>
<script>
import EditProductType from './EditProductType'
import Avatar from 'vue-avatar'
import { EventBus } from './eventBus/nav-bar-event.js'
function initialState() {
  return {
    status: true,
    ajaxCallInProgress: false,
    search: '',
    productTypes: [],
    productTypeById: '',
    source: '',
    count: 0,
    limit: 10,
    skip: 0,
    start: 1,
    end: 0,
    totalProducType: 0
  }
}
export default {
  props: ['modal_product_type_information', 'productTypesById'],
  data() {
    return initialState()
  },
  methods: {
    hideProductTypeInformation() {
      this.productTypes = []
      this.search = ''
      this.count = 0
      this.limit = 10
      this.skip = 0
      this.start = 1
      this.end = 0
      this.$modal.hide('product-type-information')
    },
    getAllProductType(param) {
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'search') {
        this.count = 0
      }
      this.ajaxCallInProgress = true
      this.productTypes = []
      this.$http
        .post('/categories/get_product_types_for_sub_categories', {
          search: this.search,
          sub_category_id: this.productTypesById,
          skip: this.count * this.limit,
          limit: this.limit
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.productTypes = response.data.product_types
          this.totalProducType = response.data.total_product_types_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalProducType
              ? this.count * this.limit + this.limit
              : this.totalProducType
        })
    },
    productTypeStatus(status, product_type_id) {
      this.$http
        .post('/products/edit_product_type_status', {
          product_type_id: product_type_id,
          status: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(
              'Product Type Status Updated Successfully'
            )
          } else {
            this.$toasted.global.error('Cannot Change Product Type Status')
          }
        })
    },
    showEditProductType(productTypeById) {
      // this.productTypeById = productTyeByID
      this.search = ''
      EventBus.$emit('editProductTypeBySb', productTypeById)
      // this.$modal.hide('product-type-information')
      this.showEditProductTypeModal()
    },
    showEditProductTypeModal() {
      this.$modal.show('edit-product-type')
    }
  },
  components: {
    EditProductType,
    Avatar
  },
  mounted() {
    EventBus.$on('getProductTypesById', productTypesById => {
      this.ajaxCallInProgress = true
      this.productTypes = []
      this.$http
        .post('/categories/get_product_types_for_sub_categories', {
          search: this.search,
          sub_category_id: productTypesById,
          limit: 10
        })
        .then(response => {
          this.ajaxCallInProgress = false
          this.productTypes = response.data.product_types
          this.totalProducType = response.data.total_product_types_count
          this.start = this.count * this.limit + 1
          this.end =
            this.count * this.limit + this.limit < this.totalProducType
              ? this.count * this.limit + this.limit
              : this.totalProducType
        })
    })
  }
}
</script>
<style>
.table td {
  padding: 1rem 1rem !important;
  font-weight: 600;
}
table.productTable tbody tr {
  border-bottom: 1px solid #a8adb4;
}
table.productTable tbody {
  max-height: 250px;
}
table.productTable tbody tr:last-child {
  border-bottom: none !important;
}
thead.products-variants-table th {
  color: #fff !important;
  font-weight: 600 !important;
  padding: 8px 10px !important;
}
thead.products-variants-table {
  background-color: #00448b;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
</style>