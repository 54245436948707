<template>
  <div>
    <modal
      :name="modal_name"
      transition="nice-modal-fade"
      class="create-customer-modal final-modal"
      @close="search = ''"
    >
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">PRODUCTS</span>
        </div>
        <div class="v-modal-body pr-1">
          <div
            class="v-modal-layout pt-2 pr-3"
            style="max-height: 410px !important; overflow-x: hidden !important;overflow-y: scroll !important"
          >
            <div class="contacts-list-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div
                  class="list-title text-secondary pl-4"
                  v-if="products.length > 1"
                >{{ products.length }} Products</div>
                <div v-else class="list-title text-secondary pl-4">{{ products.length }} Product</div>
                <!-- <button type="button" class="btn btn-link mr-10" @click="AddMemberDialog = true; addNewMembers(editMembership.agents); checked = false; toAddSearch =''" style="text-transform:none!important;font-weight:500!important;">+ Add new member</button> -->
              </div>
              <div class="group mb-3 pl-4 pr-4">
                <input
                  v-if="$route.path != '/brandmanage'"
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  v-model="searchProduct"
                  @input="searchProductsForCategory(categoryId)"
                />
                <input
                  v-else
                  class="form-control mb-2 mb-sm-0"
                  type="text"
                  placeholder="Search..."
                  v-model="searchProduct"
                  @input="searchProductsForBrand(brandId)"
                />
              </div>
            </div>
            <div class style="border: 1px solid #a8adb4 !important;border-radius: 7.5px;">
              <table class="table productTable table-scroll">
                <thead class="products-variants-table">
                  <th
                    style="flex:0 0 75px !important;width:1%;border-radius: 7.5px 0px 0px 0px;border-collapse: separate;"
                  ></th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;">Price</th>
                  <th
                    style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;"
                    v-if="$route.path != '/brandmanage'"
                  >Status</th>
                  <th
                    style="border-radius: 0px 7.5px 0px 0px;border-collapse: separate;"
                    v-if="$route.path != '/brandmanage'"
                  >Admin</th>
                </thead>
                <tbody v-if="products.length == 0">
                  <tr>
                    <td colspan="6" class="text-center">
                      <strong>No Products</strong>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template v-for="p in products">
                    <tr :key="p._id">
                      <td style="flex:0 0 75px !important;width:1%;">
                        <div class="d-flex">
                          <i
                            :class="{ 'hide-down-arrow': !p.variants,'rotate-down-arrow':p.show }"
                            class="icon-menu-right icon-right d-inline-block pt-2"
                            style="font-size: 16px !important;"
                            @click="p.show = !p.show"
                          ></i>
                          <img v-if="p.image" class="avatar avatar-trans" :src="p.image" />
                          <avatar
                            v-else
                            class="modalavatar"
                            :username="p.name.replace(/ .*/,'')"
                            :src="p.image"
                            :size="40"
                            :rounded="true"
                            color="#fff"
                          ></avatar>
                        </div>
                      </td>
                      <td
                        style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                        :title="p.name"
                      >{{ p.name }}</td>
                      <td>{{ p.quantity || '-' }}</td>
                      <td>{{ p.price || '-'}}</td>
                      <td v-if="$route.path != '/brandmanage'">
                        <el-switch v-model="p.status" v-on:change="changestatus(p.status, p._id)"></el-switch>
                      </td>
                      <td v-if="$route.path != '/brandmanage'">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit Product"
                          placement="top"
                        >
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="editproductmodal(p._id)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                    <template v-for="(v, i) in p.variants" v-if="p.variants">
                      <tr
                        :key="i"
                        style="height: 44px!important;background-color:transparent;border-bottom:1px solid #a8adb4!important;border-top:1px solid #a8adb4!important;"
                        v-show="p.show"
                      >
                        <td style="flex:0 0 75px !important;width:1%;"></td>
                        <td
                          style="max-width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                          :title="v.variant_name"
                        >{{ v.variant_name }}</td>
                        <td>{{ v.variant_quantity || '-' }}</td>
                        <td>{{ v.variant_price || '-' }}</td>
                        <td v-if="$route.path != '/brandmanage'">
                          <el-switch
                            v-model="v.status"
                            v-on:change="change_variant_status(v.status, v._id, v.id)"
                          ></el-switch>
                        </td>
                        <td v-if="$route.path != '/brandmanage'">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="Edit Product"
                            placement="top"
                          >
                            <button
                              class="btn btn-icon companyactions actionicons"
                              @click="editproductmodal(v._id)"
                              style="padding: 0.4rem .2rem;"
                            >
                              <i class="icon icon-pencil"></i>
                            </button>
                          </el-tooltip>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <button
              type="submit"
              class="btn btn-secondary btn-smm"
              @click="hideProductVariants()"
            >CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
    <category-edit-product modal_name="category-product-edit"></category-edit-product>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
import CategoryEditProduct from './CategoryEditProduct'
import productServices from './mixins/products'
import { EventBus } from './eventBus/nav-bar-event.js'
import CategoryServices from './mixins/categories.js'
import BrandServices from './mixins/brands'

export default {
  props: [
    'modal_name',
    'search',
    'categoryId',
    'products',
    'brandId',
    'isBrandManage'
  ],
  mixins: [productServices, CategoryServices, BrandServices],
  data() {
    return {
      products: []
    }
  },
  methods: {
    formatPrice(value) {
      if (value) {
        let val = value.toString().replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else return 0
    },
    showVariantstbody() {
      this.showVarinats = !this.showVarinats
    },
    hideProductVariants() {
      this.searchProduct = ''
      this.$modal.hide('product-details')
    },
    editproductmodal(product_id) {
      this.$modal.hide('product-details')
      EventBus.$emit('editProductDetails', product_id)
      this.$modal.show('category-product-edit')
    },
    getProductsByCategory(id, searchData) {
      this.$http
        .get('categories/get/' + id + '/products', {
          params: { search: searchData }
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.products = res.data.message
            this.$modal.show('product-details')
          }
        })
    },
    getProductsByBrand(id, searchData) {
      this.$http
        .get('brands/get/' + id + '/products', {
          params: { search: searchData }
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.products = res.data.message
            this.$modal.show('product-details')
          }
        })
    },
    async showProduct(id) {
      this.searchProduct = ''
      this.products = await this.getProductsByCategory(id, this.searchProduct)
      this.categoryId = id
    }
  },
  mounted() {
    if (this.isBrandManage == true) {
      EventBus.$on('loadProducts', res => {
        this.getProductsByBrand(res, '')
      })
    } else {
      EventBus.$on('loadProducts', res => {
        this.getProductsByCategory(res, '')
      })

      // this.getProductsByCategory(this.categoryId)
      EventBus.$on('showProductByCategory', value => {
        this.$modal.hide('category-product-edit')
        this.getProductsByCategory(this.categoryId, '')
      })

      EventBus.$on('showProductDetails', value => {
        this.getProductsByCategory(this.categoryId, '')
        this.$modal.show('product-details')
      })
    }
  },
  created() {
    // this.getProductsByCategory(this.categoryId)
  },
  computed: {
    searchProduct: {
      get() {
        return this.search
      },
      set(newValue) {
        this.$emit('updateSearchProduct', newValue)
      }
    }
  },
  components: {
    CategoryEditProduct,
    Avatar
  }
}
</script>
<style scoped>
.variant-name {
  color: #00448b !important;
  font-weight: 600;
}
.vue-avatar--wrapper.modalavatar {
  display: inline-block !important;
}
.avatarleft {
  margin-left: 2.8rem !important;
}
.hide-down-arrow {
  visibility: hidden;
}
.rotate-down-arrow {
  transform: rotate(90deg) !important;
}
.table td {
  font-weight: 600;
}
table.productTable tbody tr {
  border-bottom: 1px solid #a8adb4;
}
table.productTable tbody tr:last-child {
  border-bottom: none !important;
}
.table.productTable tbody {
  max-height: 250px !important;
  height: 250px !important;
}
.table.productTable thead {
  background-color: #00448b !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.table.productTable thead th {
  color: #fff !important;
}
</style>
