<template>
    <div class="reports-dashboard-div mb-10">
        <!-- <div class=" ml-5 mt-2" v-if="mobile">
            <button class="btn btn-checkout" @click="$router.go(-1)"> Back
                <i class="icon-arrow-left"></i>
            </button>
        </div> -->
        <div class="row" style="padding: 16px;">
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col" v-if="mobile">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/dashboard-white.svg">
                            </span>
                        </div>
                        <div class="bottomBorder mt-3 pointer"></div>
                        <router-link to="/reportmanage?mobile=true" style="text-decoration: none;">
                            <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Dashboard (Aggregate)</span>
                        </router-link>
                        <div class="bottomBorder mt-3 pointer"></div>
                        <router-link to="/dashboardLocation?mobile=true" style="text-decoration: none;">
                            <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Dashboard Location Breakdown</span>
                        </router-link>
                        <div class="bottomBorder mt-3 pointer"></div>
                    </div>
                    <div class="col" v-else>
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/dashboard-white.svg">
                            </span>
                        </div>
                        <div class="bottomBorder mt-3 pointer"></div>
                        <router-link to="/reportmanage" style="text-decoration: none;">
                            <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Dashboard (Aggregate)</span>
                        </router-link>
                        <div class="bottomBorder mt-3 pointer"></div>
                        <router-link to="/dashboardLocation" style="text-decoration: none;">
                            <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Dashboard Location Breakdown</span>
                        </router-link>
                        <div class="bottomBorder mt-3 pointer"></div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #03bfd5, #00cdac 96%)!important;background-size: contain;" v-if="mobile">
                    <h6 class="bottomText">Dashboard</h6>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #03bfd5, #00cdac 96%)!important;background-size: contain;" v-else>
                    <h6 class="bottomText">Dashboard</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/tax-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll" v-if="mobile">
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/outputTax" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Output Tax</span>
                            </router-link>     -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Output Tax</span>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/taxInvoice?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Tax Invoice</span>
                            </router-link>
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/dashboardLocation" style="text-decoration: none;"> -->
                                <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Sub Tax Invoice</span>
                            <!-- </router-link>     -->
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Sub Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/dashboardLocation" style="text-decoration: none;"> -->
                                <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Full Tax Invoice</span>
                            <!-- </router-link>     -->
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Full Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                        </div>
                        <div class="hoverScroll" v-else>
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/outputTax" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Output Tax</span>
                            </router-link>     -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Output Tax</span>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/taxInvoice" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Tax Invoice</span>
                            </router-link>
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/dashboardLocation" style="text-decoration: none;"> -->
                                <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Sub Tax Invoice</span>
                            <!-- </router-link>     -->
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Sub Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/dashboardLocation" style="text-decoration: none;"> -->
                                <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">Full Tax Invoice</span>
                            <!-- </router-link>     -->
                            <!-- <span class="fs-14 font-weight-bold text-center d-block mt-2">Full Tax Invoice</span> -->
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #ff512f, #dd2476, #667eea)!important;background-size: contain;">
                    <h6 class="bottomText">Tax</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/sales-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll" v-if="mobile">
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/payReport?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Pay Report</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesCategory?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Category</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesSubCategory?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Sub-Category</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesProduct?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Product</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Date (Drawer)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Date (Summary)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Month (Summary)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Bill</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Bill Detail</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                        <div class="hoverScroll" v-else>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/payReport" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Pay Report</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesCategory" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Category</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesSubCategory" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Sub-Category</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/salesProduct" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">By Product</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Date (Drawer)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Date (Summary)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Month (Summary)</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Bill</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff!important;">By Bill Detail</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(91deg, #f79256 0%, #d66826 100%)!important;background-size: contain;">
                    <h6 class="bottomText">Sales</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/menu-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3" v-if="mobile">
                            <div class="bottomBorder"></div>
                            <!-- <router-link to="/costproduct" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Cost By Menu Report</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Cost By Menu Report</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Intermediate</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Ingredient</span>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/orderReport?mobile=true" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Order Report</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Option Report</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                        <div class="hoverScroll mt-3" v-else>
                            <div class="bottomBorder"></div>
                            <!-- <router-link to="/costproduct" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Cost By Menu Report</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Cost By Menu Report</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Intermediate</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Ingredient</span>
                            <div class="bottomBorder mt-3"></div>
                            <router-link to="/orderReport" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Order Report</span>
                            </router-link>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Option Report</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to left, #41e975 98%, #2999ad 51%, #41e975 3%)!important;background-size: contain;">
                    <h6 class="bottomText">Menu</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/reservation.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Table And Reservation</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(91deg, #5c2774 0%, #d3208b 49%, #fda000 100%)!important;background-size: contain;">
                    <h6 class="bottomText">Tables</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-5">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/bills-white.svg" style="width: 45px;">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Voided Bills</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Refunded Bills</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Drawer Summary</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Paid in/out Detail</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(89deg, #1a9347 0%, #50bc00 100%)!important;background-size: contain;">
                    <h6 class="bottomText">Bills and Drawer</h6>
                </div>
            </div>
            <!-- <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-5">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/drawer.svg" style="width:50px;">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2">Drawer Summary</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2">Paid in/out Detail</span>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: linear-gradient(to bottom, #37ce6d, #14873d);">
                    <h6 class="bottomText">Drawer</h6>
                </div>
            </div> -->
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-5">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/inventroy-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll">
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Current Stock</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Inventory Used</span>
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/inventorystock" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Inventory Restock</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Inventory Restock</span>
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/checkstock" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover" style="cursor: not-allowed;">Check Stock</span>
                            </router-link> -->
                                <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Check Stock</span>
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/allactivity" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">All Activity</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">All Activity</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #b779e8, #9323ed)!important;background-size: contain;">
                    <h6 class="bottomText">Inventory</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-6">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/mm-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder" style="  background-image: linear-gradient(to bottom, #005dae, #1b6bbb);"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Menu</span>
                            <div class="bottomBorder mt-3"></div>
                            <!-- <router-link to="/promotion" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Promotion</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Promotion</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #363553, #903775 52%, #e8458b)!important;background-size: contain;">
                    <h6 class="bottomText">Menu Management</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-5">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/crm-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">CRM</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Information Summary</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Customer Review</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #f17b41, #c24f97 50%, #cd4bc9)!important;background-size: contain;">
                    <h6 class="bottomText">CRM</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-5">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/account-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">All Account</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">User Type</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #742763 2%, #c53364 50%, #fd8263)!important;background-size: contain;">
                    <h6 class="bottomText">Account Management</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-3">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/employees-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-3">
                            <div class="bottomBorder"></div>
                            <!-- <router-link to="/employeeactivity" style="text-decoration: none;">
                                <span class="fs-14 font-weight-bold text-center d-block mt-2 textColorHover">Employee Activity</span>
                            </router-link> -->
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Employee Activity</span>
                            <div class="bottomBorder mt-3"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Working Hour</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'), linear-gradient(89deg, #ea5a6f 0%, #de791e 51%, #fccf3a 100%)!important;background-size: contain;">
                    <h6 class="bottomText">Employee</h6>
                </div>
            </div>
            <div class="widget widget2 card text-auto report-grid">
                <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between">
                    <div class="col">
                        <div class="align-items-center mt-4">
                            <span class="d-flex justify-content-center">
                                <img src="https://cdn.digicollect.com/cdn/posv2/reportImages/business-white.svg">
                            </span>
                        </div>
                        <div class="hoverScroll mt-2">
                            <div class="bottomBorder"></div>
                            <span class="fs-14 font-weight-bold text-center d-block mt-2" style="cursor: not-allowed;color:#fff;">Business Intelligence</span>
                            <div class="bottomBorder mt-3"></div>
                        </div>
                    </div>
                </div>
                <div class="bottomButton" style="background-image: url('https://cdn.digicollect.com/cdn/posv2/reportImages/bg-img-reports.png'),linear-gradient(to right, #274b74, #8233c5 55%, #e963fd, #667eea)!important;background-size: contain;">
                    <h6 class="bottomText">Business Intelligence</h6>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from "./eventBus/nav-bar-event.js";
export default {
	data(){
		return{
			mobile:false
		}
	},
    mounted(){
        EventBus.$emit("customerdetailsLoad", false);
        EventBus.$emit("settingsLoad", false);
        EventBus.$emit("reportsLoad", false);
	},
	created(){
		if (this.$route.query.mobile == "true") {
			this.mobile=true
			EventBus.$emit("mobile", true)
		}
	},
	watch:{
		mobile(){
			EventBus.$emit("mobile", true)
		}
	}
}
</script>
<style scoped>
.report-grid{
    background: transparent;
    position: relative;
    -webkit-appearance: none;
    box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)!important;
    border-radius: 10px;
    max-height: 500px;
    height: 310px;
    width: 228px;
    max-width: 228px;
    margin-left: 18px;
    border: 1px solid #ffffff;
    margin-bottom: 30px;
}
.reports-dashboard-div{
    /* background-color: #00448b; */
    background: #00448b;
    margin: 12px;
    border-radius: 12px;
}
.bottomBorder{
    border-bottom: 1px solid #fff;
}
.bottomButton{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0 20px;
    margin-top: -54px;
    text-align: center;
    bottom: 0;
    transition: all .28s cubic-bezier(.4,0,.2,1);
    border-radius: 0 0 10px 10px;
    color: #fff;
}
.bottomText{
    margin: 0;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    font-weight: 600;
    top: 12px;
    position: relative;
}
.btn-checkout{
    border-radius: 23px;
    background-image: linear-gradient(to bottom, #ffb333, #e29005);
    color: #303031;
    padding: 0px 10px;
    margin: 0px;
    font-size: 14px!important;
    font-weight: 600;
    height: 3.4rem;
    line-height: 2.6rem;
    text-transform: capitalize;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)!important;
}
.btn-checkout i{
    color: #303031;
    font-size: 16px!important;
    font-weight: 600;
  padding-left: 16px;
}
.report-grid:hover .hoverScroll{
    overflow-y:auto !important;
    overflow-x: hidden;
}
.hoverScroll{
    padding-right: 9px;
    max-height: 184px;
    height: 184px;
    overflow: hidden;
}
.textColorHover{
    color: #fff !important;
}
.textColorHover:hover{
    color: #fff !important;
}
::-webkit-scrollbar {
    width: 3px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #94bef0;
    border: 4px solid transparent;
    background-clip: content-box;   /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #94bef0;
    border: 1px solid #94bef0;
}
.hoverScroll > .hoverScroll > .hoverScroll {
    min-height: 30px;
    max-height: 30px;
}
.hoverScroll > .hoverScroll > .hoverScroll {
    min-width: 30px;
    max-width: 30px;
}
</style>
