<template>
  <modal
    :name="modal_name"
    transition="nice-modal-fade"
    class="create-customer-modal final-modal popup-modal-all-width-height"
  >
    <div class="v-modal-content">
      <div class="v-modal-header">
        <span class="v-modal-dialog-title v-modal-title">ADD NEW CUSTOMER</span>
        <i class="icon icon-close text-white pointer" @click="hidecustomer"></i>
      </div>
      <div class="general-section" style="padding: 15px 0px;">
      <div class="v-modal-body">
        <div class="pt-3 pr-3">
          <div class="row first-middle-input">
            <div class="col-md-3 col-lg-3 col-sm-3" style="text-align: center;">
              <span class style="text-align:center;">
                <img v-if="customer.image" :src="customer.image" style="border-radius:100%;width:90px!important;height:90px!important;"/>
                <img v-else :src="imgDataUrl" style="width: 90px!important;height:90px!important;" />
              </span>
              <upload-picture @uploadImage="uploadImage"></upload-picture>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div class="preview-elements pb-2">
                <h6
                  style="font-weight: bold;color: #303031;font-size: 1.4rem!important;"
                >
                  Personal Information <sup>★</sup>
                </h6>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="salutation"
                      value="Mr"
                      v-model="customer.salutation"
                       v-validate="'required'"
                    />
                    <span class="radio-icon"></span>
                    <span class="form-check-description">Mr.</span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="salutation"
                      value="Mrs"
                      v-model="customer.salutation"
                       v-validate="'required'"
                       id="salutation_mrs"
                    />
                    <span class="radio-icon"></span>
                    <span class="form-check-description">Mrs.</span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="salutation"
                      value="Miss"
                      v-model="customer.salutation"
                       v-validate="'required'"
                       id="salutation_miss"
                    />
                    <span class="radio-icon"></span>
                    <span class="form-check-description">Miss</span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="salutation"
                      value="Others"
                      v-model="customer.salutation"
                       v-validate="'required'"
                       id="salutation_others"
                    />
                    <span class="radio-icon"></span>
                    <span class="form-check-description">Others</span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="salutation"
                      value="GenderNeutral"
                      v-model="customer.salutation"
                       v-validate="'required'"
                       id="salutation_general_neutral"
                    />
                    <span class="radio-icon"></span>
                    <span class="form-check-description">Gender Neutral</span>
                  </label>
                </div>
						    <span class="invalid-feedback-form text-danger" v-show="errors.has('salutation')">Please Select Salutation</span>
              </div>
              <div class="row pt-4">
                <div class="col-md-4">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text"
                      v-validate="'required'"
                      required
                      v-model="customer.first_name"
                      name="first_name" :maxlength="fnmax"
                       id="create_first_name"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">
                      First Name
                      <sup>★</sup>
                    </label>

                    <span
                      class="invalid-feedback-form text-danger"
                      v-show="errors.has('first_name') && submitted" style="display: contents;"
                      >{{ errors.first("first_name") }}</span
                    >
                  <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{fnmax - customer.first_name.length}} / {{fnmax}}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text"
                      required
                      v-model="customer.middle_name" :maxlength="mnmax"
                       id="create_middle_name"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Middle Name</label>
                  </div>
                    <p class="pull-right text-right text-grey font-600  fs-12 pt-1">{{mnmax - customer.middle_name.length}} / {{mnmax}}</p>
                </div>
                <div class="col-md-4">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text"
                      required
                      v-model="customer.last_name" :maxlength="lnmax"
                       id="create_last_name"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Last Name</label>
                  </div>
                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{lnmax - customer.last_name.length}} / {{lnmax}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-lg-12">
              <h6
                style="font-weight: bold;color: #303031;font-size: 1.4rem!important;"
              >
                Communication Information
              </h6>
              <div class="row pt-3 pb-2">
                <div class="col-lg-12 pt-2">
                  <!-- <vue-tel-input v-validate="'required'" type="number" @input="phone_number_exists=false" name="phone_number" v-model="customer.phone_number" placeholder="Enter Your Phone Number" @onInput="onInput">
                  </vue-tel-input>-->
                  <label
                    for="inputSattus"
                    class="col-form-label text-secondary"
                  >
                    Phone Number
                    <sup>★</sup>
                  </label>
                  <vue-tel-input 
                    v-validate="'required'"
                    :value="customer.phone_number"
                    name="phone_number"
                    v-model="customer.phone_number"
                    placeholder="Please enter your Phone Number"
                    :preferredCountries="['in', 'us', 'th']"
                    :defaultCountry="'us'"
                    @onInput="onInput"
                    :valid-characters-only="true"
                    id="create_phone_no"
                  ></vue-tel-input>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('phone_number')" v-if="customer.phone_number.length == 0"
                    >{{ errors.first("phone_number") }}</span
                  >
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-if="!validPhone" 
                    >Phone Number Should be Valid.</span
                  >
                  <div
                    v-show="
                      phone_number_exists &&
                        customer.phone_number.trim() != ''
                    "
                    class="text-danger"
                  >
                   Phone Number Already Exists
                  </div>
                  <!-- <span class="invalid-feedback-form text-danger" v-show="!checkForPhone">Phone Number should be Valid.</span> -->
                </div>
              </div>
              <div class="form-group contactGroup" style="padding-top: 0px;">
                <!-- <float-label label="Email*">
                  <input @input="email_exists=false" class="form-control" name="email" type="email" placeholder="Please enter your Email ID*" v-model="customer.email" v-validate="'email'">
                  <span class="text-danger fs-13" v-show="errors.has('email') && ifSubmitted">Email Field is Required</span>
                </float-label>-->
                <label
                  for="inputSattus"
                  class="col-form-label text-secondary fs-14"
                  style="font-size:14px!important;"
                  >Email</label
                >

                <div class="input-group email-group">
                  <input
                    @input="email_exists = false"
                    type="text"
                    v-validate="{regex:/^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w{2,4}$/}"
                    v-model="customer.email"
                    class="form-control modal-bg"
                    placeholder="Please enter your Email ID"
                    name="email"
                    :maxlength="eimax"
                    id="create_email_id"
                  />
                </div>
                <span
                  class="invalid-feedback-form text-danger fs-13"
                  v-show="errors.has('email') && submitted" style="display:contents;"
                  >{{ errors.first("email") }}</span
                >
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{eimax - customer.email.length}} / {{eimax}}</p>
                <div
                  v-show="email_exists && customer.email.trim() != ''"
                  class="text-danger fs-13"
                >
                Already Exists
                </div>
              </div>
            </div>
          </div>
          <div class=row>
            <div class="col-lg-12">
                <div class="d-flex align-items-center pointer" @click="showHideCompanyAddress">
                    <div class="">
                        <h6
                          style="font-weight: bold;color: #00448b;font-size: 1.4rem!important;white-space: nowrap;"
                        >
                          Company Address
                        </h6>
                    </div>
                    <hr class="ml-3 addon-border w-100" style="bottom: -5px;">
                    <div class="ml-0 pl-2" style="width: auto;">
                        <span class="collapse-addon">
                            <i v-if="is_company_address == true" class="icon-chevron-double-up text-secondary icon-style-bg-white "></i>
                            <i v-else class="icon-chevron-double-down text-secondary icon-style-bg-white"></i>
                        </span>
                        <!-- <span @click="expandAddons()" class="collapse-addon">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showAddons == true}" class="w-30 rotateIcon">
                        </span> -->
                    </div>
                </div>
              <div v-if="is_company_address == true" class="row">
                <div class="col-lg-12 pt-2">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text"
                      v-model="customer.company_name" required :maxlength="otcompanyname"
                       id="create_company_address_company_name"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Company Name</label>
                  </div>
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otcompanyname - customer.company_name.length}} / {{otcompanyname}}</p>
                </div>
              </div>
            </div>
          </div>
            <div v-if="is_company_address == true" class="row pt-4">
              <div class="col-lg-12">
                 <div class="form-group form-bottom">
                    <div class="group-setup">
                        <input v-model="customer.company_address.street" class="digi-form modal-bg" type="text" name="street"
                       id="create_company_address_address1"
                            :maxlength="castreet"  required>
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">Address 1</label>
                    </div>
                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{castreet - customer.company_address.street.length}} / {{castreet}}</p>
                 </div>
              </div>
               <div class="col-lg-12">
                 <div class="form-group form-bottom">
                    <div class="group-setup">
                        <input v-model="customer.company_address.street2" class="digi-form modal-bg" type="text" name="streetnew"
                        id="create_company_address_address2"
                            :maxlength="castreetnew"  required>
                        <span class="highlight digi-highlight"></span>
                        <span class="bar digi-bar"></span>
                        <label class="digi-label">Address 2</label>
                    </div>
                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{castreetnew - customer.company_address.street2.length}} / {{castreetnew}}</p>
                 </div>
              </div>
                <div class="col-lg-12">
                    <div class="form-group form-bottom row flex-nowrap">
                        <div class="col-lg-4">
                           <div class="group-setup">
                                <input v-model="customer.company_address.township" class="digi-form modal-bg" type="text" :maxlength="catownship"
                                id="create_company_address_township"
                                    name="state" required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Township</label>
                                <!-- <span class="text-danger fs-13"
                                    v-show="errors.has('state') && ifSubmitted">Township Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{catownship - customer.company_address.township.length}} / {{catownship}}</p>
                            <!-- <div class="group-setup">
                                <input v-model="customer.company_address.street" class="digi-form modal-bg" type="text" name="street"
                                    :maxlength="castreet"  required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Street</label>
                                <span class="text-danger fs-13"
                                    v-show="errors.has('street') && ifSubmitted">Street Name is Required</span>
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{castreet - customer.company_address.street.length}} / {{castreet}}</p> -->
                        </div>
                        <div class="col-lg-4">
                             <div class="group-setup">
                                    <input v-model="customer.company_address.city" class="digi-form modal-bg"  type="text" :maxlength="cacity"
                                id="create_company_address_city"
                                        name="city" required>
                                    <span class="highlight digi-highlight"></span>
                                    <span class="bar digi-bar"></span>
                                    <label class="digi-label">City</label>
                                    <!-- <span class="text-danger fs-13" v-show="errors.has('city') && ifSubmitted">City
                                        Name is Required</span> -->
                                </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{cacity - customer.company_address.city.length}} / {{cacity}}</p>
                        </div>
                        <div class="col-lg-4">
                              <div class="group-setup">
                                <input v-model="customer.company_address.state" class="digi-form modal-bg" type="text" :maxlength="castate"
                                id="create_company_address_state"
                                    name="state" required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">State</label>
                                <!-- <span class="text-danger fs-13"
                                    v-show="errors.has('state') && ifSubmitted">State Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{castate - customer.company_address.state.length}} / {{castate}}</p>
                        </div>                      
                    </div>
                    <div class="form-group form-top row flex-nowrap">
                        <div class="col-lg-4">
                             <div class="group-setup">
                                <input v-model="customer.company_address.zipcode" class="digi-form modal-bg" type="text" name="zipcode" :maxlength="cazipcode"
                                id="create_company_address_zipcode"
                                    required onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Zip Code</label>
                                <!-- <span class="text-danger fs-13"
                                    v-show="errors.has('street') && ifSubmitted">Street Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{cazipcode - customer.company_address.zipcode.length}} / {{cazipcode}}</p>
                        </div>
                        <div class="col-lg-8 selectlabels mt-2">
                            <float-label label="Select Country">
                                <select v-model="customer.company_address.country" class="form-control" placeholder="Select Country" name="country"

                                id="create_company_address_country"
                                    style="max-width: 100% !important;">
                                    <option disabled selected hidden style="display:none;">Select Country</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Island">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State
                                        of</option>
                                    <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and
                                        Saba</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean
                                        Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, the Democratic Republic of the">Congo, the Democratic
                                        Republic of the</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaçao">Curaçao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)
                                    </option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and McDonald Islands">Heard Island and McDonald
                                        Islands</option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)
                                    </option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic
                                        People's Republic of</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic
                                        Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the
                                        Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States
                                        of</option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestine, State of">Palestine, State of</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Réunion">Réunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Barthélemy">Saint Barthélemy</option>
                                    <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena,
                                        Ascension and Tristan da Cunha</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Martin (French part)">Saint Martin (French part)
                                    </option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the
                                        Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and the South Sandwich Islands">South Georgia
                                        and the South Sandwich Islands</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of
                                    </option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-Leste">Timor-Leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor
                                        Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian
                                        Republic of</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </float-label>
                            <!-- <span class="text-danger fs-13"
                                v-show="errors.has('country') && ifSubmitted">Country Name is Required</span> -->
                        </div>
                        <div class="col-lg-4 mt-1 selectlabels">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="is_company_address == true" class="row">
              <div class="col-lg-12 pt-2">
                <div
                  class="form-group contactGroup"
                  style="margin-top: -10px;"
                >
                  <input
                    class="inputContact modal-bg"
                    type="text" :maxlength="comapnaytaxcode"
                    v-model="customer.company_tax_code" required
                    id="create_company_address_tax_code"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Company Tax Code</label>
                </div>
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{comapnaytaxcode - customer.company_tax_code.length}} / {{comapnaytaxcode}}</p>
              </div>
            </div>
          <div class="row pt-3">
            <div class="col-lg-12">
              <div class="row pt-4">
                <div class="col-lg-12">
                    <h6 class="heading-details">Customer Address
                        <!-- <span class="select-map-span" @click="ModalMap()">
                            <i class="icon-open-in-new" style="font-size: 16px;"></i>
                            <span class="select-map">Select In Map</span>
                        </span> -->
                    </h6>
                    <div class="">
                      <div class="form-group form-bottom">
                          <div class="group-setup">
                              <input v-model="customer.personal_address.street" class="digi-form modal-bg" type="text" name="street"
                                id="create_customer_address_address1"
                                  :maxlength="otstreet"  required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">Address 1</label>
                          </div>
                          <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otstreet - customer.personal_address.street.length}} / {{otstreet}}</p>
                      </div>
                    </div>
                    <div class="pt-3">
                      <div class="form-group form-bottom">
                          <div class="group-setup">
                              <input v-model="customer.personal_address.street2" class="digi-form modal-bg" type="text" name="streetnew"
                              id="create_customer_address_address2"
                                  :maxlength="otstreetnew"  required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">Address 2</label>
                          </div>
                          <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otstreetnew - customer.personal_address.street2.length}} / {{otstreetnew}}</p>
                      </div>
                    </div>
                    <div class="form-group form-bottom row flex-nowrap mt-7">
                      <div class="col-lg-4">
                         <div class="group-setup">
                              <input v-model="customer.personal_address.township" class="digi-form modal-bg" type="text" :maxlength="ottownship"
                              id="create_customer_address_township"
                                  name="state" required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">Township</label>
                              <!-- <span class="text-danger fs-13"
                                  v-show="errors.has('state') && ifSubmitted">State Name is Required</span> -->
                          </div>
                          <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{ottownship - customer.personal_address.township.length}} / {{ottownship}}</p>
                            <!-- <div class="group-setup">
                                <input v-model="customer.personal_address.street" class="digi-form modal-bg" type="text" name="street" :maxlength="otstreet"
                                    required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Street</label>
                                <span class="text-danger fs-13"
                                    v-show="errors.has('street') && ifSubmitted">Street Name is Required</span>
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otstreet - customer.personal_address.street.length}} / {{otstreet}}</p> -->
                        </div>
                        <div class="col-lg-4">
                            <div class="group-setup">
                                <input v-model="customer.personal_address.city" class="digi-form modal-bg" type="text" :maxlength="otcity"
                                id="create_customer_address_city"
                                    name="city" required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">City </label>
                                <!-- <span class="text-danger fs-13" v-show="errors.has('city') && ifSubmitted">City
                                    Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otcity - customer.personal_address.city.length}} / {{otcity}}</p>
                        </div>
                        <div class="col-lg-4">
                             <div class="group-setup">
                                <input v-model="customer.personal_address.state" class="digi-form modal-bg" type="text" :maxlength="otstate"
                                id="create_customer_address_state"
                                    name="state"  required>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">State</label>
                                <!-- <span class="text-danger fs-13"
                                    v-show="errors.has('state') && ifSubmitted">State Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otstate - customer.personal_address.state.length}} / {{otstate}}</p>
                        </div>                        
                    </div>
                    <div class="form-group form-top row flex-nowrap">
                      <div class="col-lg-4">
                           <div class="group-setup">
                                <input v-model="customer.personal_address.zipcode" class="digi-form modal-bg" type="text"  name="p_zipcode" :maxlength="otzipcode"
                                id="create_customer_address_zipcode"
                                    required onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                <span class="highlight digi-highlight"></span>
                                <span class="bar digi-bar"></span>
                                <label class="digi-label">Zip Code</label>
                                <!-- <span class="text-danger fs-13"
                                    v-show="errors.has('street') && ifSubmitted">Street Name is Required</span> -->
                            </div>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{otzipcode - customer.personal_address.zipcode.length}} / {{otzipcode}}</p>
                        </div>
                        <div class="col-lg-8 selectlabels mt-2">
                            <float-label label="Country">
                                <select v-model="customer.personal_address.country" class="form-control" placeholder="Select Country" name="country"
                                id="create_customer_address_country"
                                    style="max-width: 100% !important;">
                                    <option disabled selected hidden style="display:none;">Select Country</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Island">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State
                                        of</option>
                                    <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and
                                        Saba</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean
                                        Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, the Democratic Republic of the">Congo, the Democratic
                                        Republic of the</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaçao">Curaçao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)
                                    </option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and McDonald Islands">Heard Island and McDonald
                                        Islands</option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)
                                    </option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic
                                        People's Republic of</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic
                                        Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the
                                        Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States
                                        of</option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestine, State of">Palestine, State of</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Réunion">Réunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Barthélemy">Saint Barthélemy</option>
                                    <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena,
                                        Ascension and Tristan da Cunha</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Martin (French part)">Saint Martin (French part)
                                    </option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the
                                        Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and the South Sandwich Islands">South Georgia
                                        and the South Sandwich Islands</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of
                                    </option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-Leste">Timor-Leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor
                                        Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian
                                        Republic of</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </float-label>
                            <span class="text-danger fs-13"
                                v-show="errors.has('country') && ifSubmitted">Country is Required</span>
                        </div>
                        
                    </div>
                </div>
            </div>
              <div class="row">
                <div class="col-lg-12 pt-2">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text"
                      v-model="customer.tax_code" required :maxlength="customertaxcode"
                      id="create_customer_address_tax_code"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Customer Tax Code</label>
                  </div>
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{customertaxcode - customer.tax_code.length}} / {{customertaxcode}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-2">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input
                      class="inputContact modal-bg"
                      type="text" :maxlength="refferedby"
                      v-model="customer.referred_by" required
                      id="create_customer_address_reffered_by"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Referred by</label>
                  </div>
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{refferedby - customer.referred_by.length}} / {{refferedby}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-2">
                  <div
                    class="form-group contactGroup"
                    style="margin-top: -10px;"
                  >
                    <input 
                      class="inputContact modal-bg"
                      type="text" :maxlength="notesmax"
                      v-model="customer.notes" required
                      id="create_customer_address_notes"
                      
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Notes</label>
                  </div>
                <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{notesmax - customer.notes.length}} / {{notesmax}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-lg-12">
              <h6
                style="font-weight: bold;color: #303031;font-size: 1.4rem!important;"
              >
                Likes and Dislikes
              </h6>
              <div class="row">
                <div class="col-lg-12 pt-2">
                  <label
                    for="inputSattus"
                    class="col-form-label text-secondary fs-14"
                    style="font-size:14px!important;"
                    >Likes</label
                  >
                  <v-select
                    multiple
                    v-model="customer.liked_products"
                    :options="products"
                    label="product_name"
                    @search="fetchProductsForCustomer"
                    placeholder="Search and Select Product"
                    class="likes-vselect"
                      id="create_likes"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-2">
                  <label
                    for="inputSattus"
                    class="col-form-label text-secondary fs-14"
                    style="font-size:14px!important;"
                    >Dislikes</label
                  >
                  <v-select
                    multiple
                    v-model="customer.disliked_products"
                    :options="productsdislike"
                    placeholder="Search and Select Product"
                    @search="fetchProductsDislikeForCustomer"
                    label="product_name"
                      id="create_dislikes"
                  />
                </div>
              </div>
            </div>
          </div>
            <div class="pt-5 align-items-center" id="deposit_available_create">
                <span class="font-weight-bold pr-5">Deposit Available?</span>
                <el-switch v-model="customer.is_deposit_available" active-text="Yes" inactive-text="No" style="line-height: 16px !important;"></el-switch>
            </div>
            <div v-if="customer.is_deposit_available" class="mt-3">
                <div class="form-group form-bottom row flex-nowrap">
                    <div class="col-lg-4">
                        <div class="group-setup">
                            <input v-model="customer.balance" class="digi-form modal-bg" type="text" name="amount" 
                            id="deposit_amount" :maxlength="balance"
                            v-validate="'required'"
                                required onkeypress='return event.charCode >= 48 && event.charCode <= 57' autocomplete="off">
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Amount<sup>★</sup></label>
                        </div>
                    <span
                        class="invalid-feedback-form text-danger"
                        v-show="errors.has('amount') && submitted" style="display: contents;"
                        >Amount is Required</span>
                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{balance - customer.balance.length}} / {{balance}}</p>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup">
                            <input v-model="customer.issued_by" class="digi-form modal-bg" type="text" name="issuedBy" :maxlength="issuedBy"
                            id="deposit_issued_by"
                                required v-validate="{required:true,regex:/^[a-zA-Z ]+$/}" autocomplete="off">
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Issued by<sup>★</sup></label>
                        </div>
                        <span
                        class="invalid-feedback-form text-danger"
                        v-show="errors.has('issuedBy') && submitted" style="display: contents;"
                        >{{errors.first("issuedBy") }}</span
                        >
                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{issuedBy - customer.issued_by.length}} / {{issuedBy}}</p>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup" style="margin-top: -21px;">
                          <label class="fs-13 text-secondary" style="display: block;">Date Of Issue<sup>★</sup></label>
                            <date-picker                           
                                v-model="customer.date_of_issue" 
                                v-validate="'required'"
                                format="MM/DD/YYYY"
                                :first-day-of-week="7"  
                                :lang="lang"
                                :clearable="true"
                                confirm
                                :not-after="new Date()"
                                confirm-text="APPLY"
                                id="deposit_date"
                                name="issuedDate" class="modal-datepicker">
                                </date-picker>
                                <span
                        class="invalid-feedback-form text-danger"
                        v-show="errors.has('issuedDate') && submitted" style="display: block;"
                        >Date of Issue is Required</span
                        >
                        </div>
                    </div>
                </div>
                <div class="form-group form-bottom row flex-nowrap">
                    <div class="col-lg-4">
                        <div class="group-setup">
                            <input v-model="customer.bank_name" class="digi-form modal-bg" type="text" name="bankName" :maxlength="bankName"
                            id="deposit_bank_name"
                                required v-validate="{required:true,regex:/^[a-zA-Z ]+$/}" autocomplete="off">
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Bank Name<sup>★</sup></label>
                        </div>
                        <span
                        class="invalid-feedback-form text-danger"
                        v-show="errors.has('bankName') && submitted" style="display: contents;"
                        >{{errors.first("bankName") }}</span
                        >
                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{bankName - customer.bank_name.length}} / {{bankName}}</p>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup">
                            <input 
                                v-model="customer.cheque_number" class="digi-form modal-bg" type="text" name="transferNo" :maxlength="transferNo"
                            id="deposit_cheque"
                                required v-validate="'required|alpha_num'" autocomplete="off">
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Cheque/Transfer No<sup>★</sup></label>
                        </div>
                        <span
                            class="invalid-feedback-form text-danger"
                            v-show="errors.has('transferNo') && submitted"
                            style="display: contents;"
                          >{{errors.first("transferNo") }}</span>
                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{transferNo - customer.cheque_number.length}}/ {{transferNo}}</p>
                    </div>
                    <div class="col-lg-4">
                        <div class="group-setup">
                            <input v-model="customer.deposit_notes" class="digi-form modal-bg" type="text" name="depositNotes" :maxlength="depositNotes"
                            id="deposit_deposit_notes"
                                required autocomplete="off">
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Deposit Notes</label>
                        </div>
                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{depositNotes - customer.deposit_notes.length}} / {{depositNotes}}</p>
                    </div>
                </div>
            </div>
          <div class="row mt-5 mb-2">
            <div class="col-lg-12 ml-2" id="opt_create_promt">
              <el-checkbox v-model="customer.promotions"
                >Opt for Promotional Emails and Offers Email</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- <div class="v-modal-dialog-actions modal-bg text-center pt-5 pb-5">
          <a
            class="btn btn-link btn-smm"
            style="text-transform: uppercase !important;font-size: 12px !important;"
            @click="hidecustomer()"
            >CANCEL</a
          >
          <button
            type="submit"
            class="btn btn-secondary btn-smm"            
            @click="create()"
            :disabled="create_customer_api_inprogress"
          >
            SAVE
          </button>
        </div> -->
        <div class="v-modal-dialog-actions modal-bg text-center pt-10 pb-5">
          <button
            class="btn btn-link btn-smm"
            style="text-transform: uppercase !important;font-size: 12px !important;"
            @click="hidecustomer()" :disabled="create_customer_api_inprogress"
            >CANCEL</button>
          <button type="submit" class="btn btn-secondary btn-smm ml-5" @click="create()"
            :disabled="create_customer_api_inprogress">SAVE
              <half-circle-spinner :animation-duration="1000" :size="20" v-if="create_customer_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
            </button>
        </div>
    </div>
  </modal>
</template>
<script>
import UploadPicture from "./UploadPicture"
import customerServices from "./mixins/customer"
import { EventBus } from "./eventBus/nav-bar-event"
import productServices from "./mixins/products"
import { HalfCircleSpinner } from "epic-spinners";
function initialState (){
    return {
        customer: {
            first_name: '',
            last_name: '',
            middle_name: '',
            salutation: '',
            email: '',
            phone_number: '',
            promotions: false,
            image: '',
            liked_products: [],
            disliked_products: [],
            company_name: '',
            tax_code: '',
            referred_by: '',
            notes: '',
            personal_address:{
                street:"",
                street2:'',
                city:"",
                country:"",
                zipcode:"",
                state:"",
                township:""
            },
            company_address:{
                street:"",
                street2:'',
                city:"",
                country:"",
                zipcode:"",
                state:"",
                township:""

            },
            company_tax_code:"",
            is_deposit_available:false,
            balance:'',
            issued_by:'',
            date_of_issue:'',
            bank_name:'',
            cheque_number:'',
            deposit_notes:''
        },
        lang: {
            type: "en",
            days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
            ],
            pickers: [
            "next 7 days",
            "next 30 days",
            "previous 7 days",
            "previous 30 days"
            ],
            placeholder: {
                date: "Date of Issue *",
                dateRange: "Select Date Range"
            }
        },
        selected: '',
        validPhone: true,
        checkForPhone: true,
        count: 0,
        submitted: false,
        products: [],
        productsdislike:[],
        searchdata: '',
        imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg', // the datebase64 url of created image,
        enabledCountryCode: true,
        fnmax: 20,
        mnmax: 20,
        lnmax: 20,
        eimax: 230,
        castreet: 42,
        castreetnew: 42,
        cacity:21,
        catownship:21,
        cazipcode:12,
        comapnaytaxcode:20,
        otcompanyname:42,
        otstreet:42,
        otstreetnew:42,
        otcity:21,
        ottownship:21,
        otstate:30,
        otzipcode:12,
        customertaxcode: 20,
        castate:30,
        refferedby:30,
        notesmax:250,
        create_customer_api_inprogress: false,
        phone_number_exists: false,
        email_exists: false,
        balance: 10,
        issuedBy:10,
        bankName:10,
        transferNo:10,
        depositNotes:20,
        is_company_address: false,
        uniqueArray: []
    }
}
export default {
    props: ['modal_name'],
    mixins: [productServices, customerServices],
    data () {
        return initialState()
    },
    components: {
        UploadPicture,
        HalfCircleSpinner
    },
    mounted () {
        EventBus.$emit("settingsLoad", false)
        EventBus.$emit("customerdetailsLoad", false)
        EventBus.$emit("reportsLoad", false)
    },
    methods: {
        showHideCompanyAddress(){
            this.is_company_address = !this.is_company_address
        },
        hidecustomer () {
            this.reset()
            this.$emit('close', false)
        },
        uploadImage (imgData) {
            this.customer.image = imgData
        },
        reset () {
            Object.assign(this.$data, initialState())
        },
        async create () {
            this.submitted = true
            this.create_customer_api_inprogress = true
            let result = await this.$validator.validate()
            if (result && this.validPhone && !this.phone_number_exists && !this.email_exists) {
                this.createCustomer()
            } else {
                this.create_customer_api_inprogress = false
            }
        },
        async createCustomer() {
            try {
                this.create_customer_api_inprogress = true
                if(typeof(this.customer.phone_number) == "object"){
                    this.customer.phone_number = this.customer.phone_number['e164']
                }
                if(this.customer.is_deposit_available == false){
                  this.balance = 0
                  this.issued_by = '',
                  this.date_of_issue = '',
                  this.bank_name = '',
                  this.cheque_number = '',
                  this.deposit_notes = ''
                }
                let params = this.customer
                let res = await this.addCustomer(params)
                EventBus.$emit('createdCustomerId', res.message._id)
                // this.$swal('Created', 'Successfully Created Customer', 'success')
                this.$swal({
                    type: 'success',
                    title: 'Created',
                    text: 'Successfully Created Customer',
                    showConfirmButton: false,
                    timer: 2000
                })
                this.reset()
                this.$emit('close', true)
                this.create_customer_api_inprogress = false
            } catch (error) {
                if (error.response && error.response == 'duplicate') {
                if (error.value == 'phone_number') this.phone_number_exists = true
                    else this.email_exists = true
                } else {
                    // this.$swal('reason', error.response, 'warning')
                    this.$swal({
                        type: 'warning',
                        title: 'Warning',
                        text: error.reason,
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    })
                }
            }
            this.create_customer_api_inprogress = false
        },
        onInput ({ number, isValid, country }) {
            this.phone_number_exists = false
            this.validPhone = isValid
        },
        onlyNumber (event) {
            this.phone_number_exists = false
            this.customer.phone_number = event
            if (event.match(".*[a-z].*")) {
                event.preventDefault()
            }
        },
        async fetchProductsForCustomer (searchdata) {
            try {
                let params = { searchdata }
                let res = await this.getProductsForCustomer(params)
                this.products = res.response.all_products
            } catch (reason) {
                // TODO: Error Handling
            }
        },
        async fetchProductsDislikeForCustomer (searchdata) {
            try {
                let params = { searchdata }
                let res = await this.getProductsForCustomer(params)
                this.productsdislike = res.response.all_products
                // this.productsdislike = this.productsdislike.filter(v => !(this.gathered_ids.some(e => e
                //     .product_name === v.product_name)))
                const find_dup_categories = this.productsdislike.map(o => o.product_name)
                const filteredupcategory = this.productsdislike.filter(({product_name}, index) => !find_dup_categories.includes(product_name, index + 1))
                this.productsdislike = filteredupcategory
            } catch (reason) {
                // TODO: Error Handling
            }
        }
    },
    mounted(){
      EventBus.$on('createcustnew',()=>{
        this.fetchProductsForCustomer()
        this.fetchProductsDislikeForCustomer()
      })     
    },
    created () {
        const dict = {
            custom: {
                first_name: {
                    required: () => "First Name is Required"
                },
                phone_number: {
                    required: () => "Phone Number is Required"
                },
                email: {
                    // required: () => "Email ID is Required",
                    regex: () =>"Enter Valid Email ID"
                },
                issuedBy:{
                    required: () => "Issued By is Required",
                    regex: () => "Enter Valid Name"
                },
                bankName:{
                    required: () => "Bank Name is Required",
                    regex: () => "Enter Valid Bank Name"
                },
                transferNo:{
                    required: () => 'Cheque/Transfer No. is Required',
                    alpha_num: () => 'Enter Valid Cheque/Transfer No.'
                }
            }
        }
        this.$validator.localize("en", dict)
    }
}

</script>
<style>
.dc-menu-item .nav-link.menulink.custmer.router-link-active:before {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin: 0 0px;
  position: absolute;
  right: 0;
  background-color: #00448b;
}
.heading-details {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0.7px;
    text-align: left;
    color: #00448b;
    padding-top: 15px;
}
.final-modal .inputContact {
    border-bottom: solid 1px #a9aaad !important;
}
.final-modal .digi-form.modal-bg{
    border-bottom: solid 1px #a9aaad !important;
}
.final-modal .form-control {
    border-bottom: 1px solid #a9aaad !important;
}
.final-modal .vue-tel-input  {
    border: 1px solid transparent !important;
    border-bottom: 1px solid #a9aaad !important;
}
.final-modal .vue-tel-input input {
    background-color: transparent;
}
.final-modal .v-select .dropdown-toggle {
    border-bottom: solid 1px #a9aaad !important;
}
.create-customer-modal .group-setup {
    position: relative;
    margin-bottom: 0px!important;
}

.select-map-span {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 10px;
}
.create-customer-modal .dropdown.v-select input {
    position: relative!important;
}
.heading-details-black {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    color: #303031;
    padding-top: 20px;
    margin-bottom: 4px;
}
.v-modal-layout {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.create-customer-modal .input-group.email-group {
  border-bottom: 1px solid #cdd2da !important;
}

.image-upload {
  padding-left: 26px;
}

span.image-upload img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 100%;
  border: 1px solid #eee;
}

.v-select.likes-vselect.searchable .dropdown-toggle {
  display: flex !important;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
</style>
