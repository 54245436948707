<template>
    <div id="lazy-load-orders" class="col-lg-12 text-center">
        <div v-if="ajaxCallInProgress" class="digi-table-loader d-flex justify-content-center"
            style="margin: 0 auto;margin-top: 50px !important;">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="page-content-card py-5 px-3" v-for="(order,index) in orderListView" :key="order._id+''+index">
            <div >
                <div class="row ml-0">
                    <div class="col-md-2 col-lg-2 col-sm-2" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div class="col text-left fw-600 fs-14 text-color text-uppercase">TABLE NO -
                                {{order.table_number}}</div>
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 col-sm-2" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div class="col text-left fw-600 fs-14 text-order-color text-uppercase">ORDER ID :
                                {{order.order_id}}</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div class="col text-left fw-600 fs-14 text-uppercase">CUSTOMER NAME :
                                {{order.customer_name}}</div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2 col-sm-2" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div class="col text-left fw-600 fs-14 text-uppercase">NOTE : {{order.order_note}}</div>
                        </div>
                    </div>
                </div>
                <div class="row ml-1 float-right ">
                    <span class="pointer mr-5" @click="showTableData(order)">
                        <img v-if="order.toggle" class="" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chef-arrow.svg"
                            style="width: 30px" />
                        <img v-else class="" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chef-arrow-down.svg" style="width: 30px" />
                    </span>
                </div>
                <div class="row">
                    <div class="col-md-2 col-lg-2 col-sm-2" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div class="col text-left fw-600 fs-14 text-color text-uppercase">SERIAL NO :
                                {{order.order_number}}</div>
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 col-sm-2" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3 pl-1 pl-4">
                            <i class="icon icon-clock text-order-color fs-16" style="margin-top:-2px"></i>
                            <span class="text-order-color">{{order.start_time}} </span>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3 ml-1 float-left" v-if="order.showPrepared">
                            <span class="col text-left fw-600 fs-14 ml-4">FINISH ORDER </span>
                            <span v-if="order.canPrepare">
                                <el-switch style="display: block" :disabled="!order.canPrepare"
                                    v-model="order.isPrepared" @change="prepareOrder(order.isPrepared,order._id)">
                                </el-switch>
                            </span>
                            <span v-else>
                                <el-switch style="display: block" v-model="order.isPrepared"
                                    @change="prepareOrder(order.isPrepared,order._id)"></el-switch>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3" style="border-right: 1px solid #fff;">
                        <div class="d-flex pb-3">
                            <div></div>
                        </div>
                    </div>
                </div>

                <table class="table" v-if="order.toggle">
                    <thead>
                        <tr class="table-head">
                            <th colspan="1" class="text-nowrap">ITEMS</th>
                            <th colspan="1" class="text-nowrap">QTY</th>
                            <th colspan="2" class="text-nowrap">MODIFIERS</th>
                            <!-- <th colspan="2" class="text-nowrap">MODIFIERS</th> -->
                            <th colspan="1" class="text-nowrap">NOTE</th>
                            <th colspan="1" class="text-nowrap">FINISHING</th>
                            <th colspan="1" class="text-nowrap">STATUS</th>

                        </tr>
                    </thead>
                    <template>
                        <tbody v-if="ajaxCallInProgress" :key="order._id">
                            <tr>
                                <td colspan="8" class="text-center">
                                    <div class="digi-table-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="order.order_items_count <= 0" :key="order._id">
                            <tr>
                                <td colspan="8" class="text-center">
                                    <img
                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_data_view_order.svg" />
                                    <br />
                                    <span class="text-secondary font-600" style="font-size:18px!important;">
                                        All Items are Deleted or Moved
                                        <br />to Another Table
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else :key="order._id">
                            <template v-for="(course,cindex) in order.order_items">
                                <tr :key="'A'+ cindex">
                                    <td colspan="1" class="font-600 text-left">
                                        <span style="color:#ef6d17">Course {{course.course}}</span>
                                    </td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="2"></td>
                                    <td colspan="1" class="font-600 text-left" style="color: #303031;">
                                        <div v-if="order.isHold" class="float-left">
                                            <el-tooltip content="On Hold" effect="dark" placement="top">
                                                <span class="ml-3">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"
                                                        style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                                </span>
                                            </el-tooltip>
                                        </div>
                                        <div v-else class="float-left">
                                            <el-switch v-if="order.showFire" :disabled="order.isFired && order.canFire"
                                                @change="courseFire(order.isFired,order.course,order._id)"
                                                class="orderPrepared" v-model="order.isFired">
                                            </el-switch>
                                        </div>
                                        <div v-if="order.showPrepared" class="float-left">
                                            <el-switch
                                                :disabled="!order.isFired || order.isDelivered || loggedInUser.role == 'default_order_taker' || order.isHold"
                                                class="orderPrepared"
                                                @change="coursePrepare(order.isPrepared,order._id,course.course)"
                                                v-model="course.isPrepared"></el-switch>
                                        </div>
                                    </td>
                                    <td colspan="1" class="font-600 ">
                                        <span class="float-left">Total: {{course.no_of_items}}</span>
                                    </td>
                                </tr>
                                <template v-for="(itemDetails,tindex) in course.item_details">
                                    <tr :key="'B'+ tindex">
                                        <td colspan="1" class="text-left">
                                            <el-tooltip v-if="itemDetails.product_type == 'Non-Veg'" content="Non-Veg"
                                                placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                    style="margin-top: -4px;width: 11px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="itemDetails.product_type == 'Veg'" content="Veg"
                                                placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                    style="margin-top: -4px;width: 11px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="itemDetails.product_type == 'Egg'" content="Egg"
                                                placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                    style="margin-top: -4px;width: 11px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="itemDetails.product_type == 'Pescatarian'"
                                                content="Pescatarian" placement="top">
                                                <img style="width:11px;margin-top: -4px;"
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme//https://cdn.digicollect.com/cdn/posv2/psec.svg" />
                                            </el-tooltip>
                                            <span> {{itemDetails.product_name}}</span>
                                        </td>
                                        <td colspan="1" class="text-left">
                                            <span>
                                                <span> {{itemDetails.selected_quantity}}</span>
                                            </span>
                                        </td>
                                        <td colspan="2" class="text-left" v-if="itemDetails.modifiers">
                                            <div class="text-blue fs-14 font-600 pt-1">
                                                        <span>{{itemDetails.variant_name}}</span>
                                                    </div>
                                                    <div class="d-block w-100 pb-1" v-for="(modifier,mindex) in itemDetails.modifiers.for_secondary"  :key="'C1'+ mindex">
                                                        <!-- <div>
                                                            <span class="fw-700 text-secondary fs-14 fw-600">{{modifier.m_name}}</span> 
                                                        </div> -->
                                                        <div>
                                                            <span class="fw-700">{{modifier.m_item_name}}</span> 
                                                            <span class="text-green ml-1 fw-700">X
                                                                {{modifier.quantity}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-block w-100 pt-1" v-for="(modifier,mindex) in itemDetails.modifiers.for_primary"  :key="'C'+ mindex">
                                                        <div class="text-blue font-600">
                                                            <span>{{modifier.modifier_name}}</span>
                                                        </div>
                                                        <div v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                            <div>
                                                                <span class="fw-700">{{modifier_item.m_item_name}}</span> 
                                                                <span class="text-green ml-1 fw-700">X
                                                                    {{modifier_item.quantity}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </td>
                                        <!-- <td colspan="2" class="text-left" v-if="itemDetails.addons.length > 0">
                                            <div class="text-blue font-600">
                                                <span>Size : </span>
                                                <span>Small</span>
                                            </div>
                                            <div class="text-black font-600 pt-1">
                                                Modifiers :
                                            </div>
                                            <div class="text-no-wrap" v-for="(addon,aindex) in itemDetails.addons" :key="'C'+ aindex">
                                                <el-tooltip v-if="addon.type == 'Non-Veg'" content="Non-Veg"
                                                placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                        style="margin-top: -4px;width: 11px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type == 'Veg'" content="Veg"
                                                    placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                        style="margin-top: -4px;width: 11px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type == 'Egg'" content="Egg"
                                                    placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                        style="margin-top: -4px;width: 11px;" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type == 'Pescatarian'"
                                                    content="Pescatarian" placement="top">
                                                    <img style="width:11px;margin-top: -4px;"
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme//https://cdn.digicollect.com/cdn/posv2/psec.svg" />
                                                </el-tooltip>
                                                <span class="font-weight-bold"
                                                    :class="{'ml-1':addon.type != ''}">{{addon.name}}</span>
                                                <span
                                                    class="text-green ml-1 font-weight-bold">X{{addon.quantity}}</span>
                                                <span class="text-green font-weight-bold"
                                                    v-if="aindex != addon - 1">,</span>
                                            </div>
                                        </td>
                                        <td colspan="2" class="text-left" v-else>
                                            <div class="font-600">
                                                -
                                            </div>
                                        </td> -->
                                        <!-- <td colspan="2" class="text-left" v-if="itemDetails.modifiers.length>0">
                                            <div class="text-green font-600">
                                                Modifier :
                                            </div>
                                            <div v-for="(modifier,modifier_index) in itemDetails.modifiers" :key="'Cmodifier_index'+ modifier_index">
                                                <div class="ml-0 pl-0">
                                                    <span class="fw-bold text-capitalize" style="color: #000 !important; word-break: break-all;">{{modifier.modifier_name}}</span>
                                                    <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                                    <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 text-capitalize"
                                                        style="word-break: break-all;">
                                                        {{modifier.modifier_list[modifier_item_index]}}
                                                        <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                                        </span>
                                                </div>
                                            </div>
                                        </td> -->
                                        <!-- <td colspan="2" class="text-left" v-else>
                                            <div class="font-600">
                                                -
                                            </div>
                                        </td> -->
                                        <td colspan="1" v-if="itemDetails.item_note !=''"
                                            class="text-left font-weight-bold">
                                            <span>{{itemDetails.item_note}}</span>
                                        </td>
                                        <td colspan="1" class="text-left" v-else>
                                            <span>-</span>
                                        </td>
                                        <td v-if="itemDetails.isHold" style="padding:0.2rem 1.2rem;">
                                            <el-tooltip content="On Hold" effect="dark" placement="top">
                                                <span class="ml-3">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"
                                                        style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                                </span>
                                            </el-tooltip>
                                        </td>
                                        <td v-if="itemDetails.unAvailable" style="padding:0.2rem 1.2rem;">
                                            <el-tooltip class="item" effect="dark" content="Item Unavailable"
                                                placement="top-start">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/item_unavailable.svg"
                                                    style="width: 34px!important;height: 19px!important;" />
                                            </el-tooltip>
                                        </td>

                                        <td colspan="1" class="text-left" style="padding:0.4rem 1.2rem;">
                                            <el-switch
                                                :disabled="!itemDetails.isFired || loggedInUser.role == 'default_order_taker' || itemDetails.unAvailable || itemDetails.isHold"
                                                class="orderPrepared" @change="itemPreparded(itemDetails,order._id)"
                                                v-model="itemDetails.isPrepared"></el-switch>
                                        </td>
                                        <td colspan="1" v-if="itemDetails.isHold" style="padding:0.2rem 1.2rem;">
                                            <el-tooltip content="On Hold" effect="dark" placement="top">
                                                <span class="ml-3">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"
                                                        style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                                </span>
                                            </el-tooltip>
                                        </td>
                                        <td v-if="itemDetails.unAvailable" style="padding:0.2rem 1.2rem;">
                                            <el-tooltip class="item" effect="dark" content="Item Unavailable"
                                                placement="top-start">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/item_unavailable.svg"
                                                    style="width: 34px!important;height: 19px!important;" />
                                            </el-tooltip>
                                        </td>
                                        <td colspan="1" class="text-center" v-if="orderItem_display">
                                            <div class="view-vizard-small d-flex" v-if="itemDetails.item_status">
                                                <div class="stepwizard">
                                                    <div class="stepwizard-row setup-panel">
                                                        <div class="stepwizard-step">
                                                            <div class="wizardSteps pointer"
                                                                :class="{ 'greenCircle': itemDetails.isFired,'redCircle':!itemDetails.isFired}">
                                                                <a class="btn-circle"
                                                                    :class="{ 'greenCircle':itemDetails.isFired,'redCircle':!itemDetails.isFired}">S</a>
                                                            </div>
                                                        </div>
                                                        <div class="stepwizard-step" style="left:25% !important;">
                                                            <div class="wizardSteps pointer"
                                                                :class="{ 'greenCircle': itemDetails.isPrepared,'redCircle':!itemDetails.isPrepared}">
                                                                <a class="btn-circle"
                                                                    :class="{ 'greenCircle': itemDetails.isPrepared,'redCircle':!itemDetails.isPrepared}">F</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import ManageOrderService from './mixins/manage_order.js'
    import orderServices from './mixins/orders'
    import PayBill from './PayBill'
    import DeleteItem from './DeleteItem'
    const MoveItems = () => import('./MoveItems.vue')

    export default {
        mixins: [ManageOrderService, orderServices],
        props: ['searchdata', 'type'],
        data() {
            return {
                hold_order: '',
                hold_course: '',
                showPreparedCard: false,
                infinite_scroll_reached_end: false,
                isPrepared: false,
                isDelivered: false,
                isHold: false,
                unAvailable: false,
                isFired: true,
                orderType: null,
                hold_Status: false,
                order: '',
                isPopUp: false,
                order_id: '',
                delete_quantity: '',
                item_id: '',
                product_name: '',
                product_quantity: '',
                DeleteOrderDiv: false,
                temp_order_id: '',
                selectedOrderId: '',
                moveItemsModal: false,
                start_time: '',
                order_id: null,
                orderCourse_display: false,
                orderItem_display: false,
                disable_course: false,
                disable_item: false,
                inProgress: false,
                deletevieworder: false,
                showbillpay: false,
                ajaxCallInProgress: false,
                storeorderId: '',
                orderTableData: [],
                courseDetails: [],
                orderListDetails: [],
                orderListView: []
            }
        },
        components: {
            PayBill,
            DeleteItem,
            MoveItems
        },
        methods: {
            showTableData(order) {
                order.toggle = !order.toggle
                // if (!this.orderTableData.includes(index)) {
                //     // this.orderTableData.push(index);
                //     this.orderTableData.splice(index, 0, index);  
                // } else { 
                //     setTimeout(() => {
                //         console.log("splice")
                //         this.orderTableData.splice(index, 1);   
                //     }, 500);   
                // }
                this.$forceUpdate()
            },
            showViewOrder(orderId) {
                this.$router.push({
                    name: 'ViewOrderChef',
                    params: {
                        id: orderId
                    }
                })
            },
            async itemPreparded(item, order_id) {
                try {
                    let params = {
                        isPrepared: item.isPrepared,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: order_id
                    }
                    let res = await this.itemPreparedApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res, params.item_id)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Order Operations
            async prepareOrder(isprepared, orderId) {
                try {
                    let params = {
                        order_id: orderId,
                        isPrepared: isprepared,
                        get_details: true
                    }
                    let res = await this.orderPrepareApi(params)
                    if (res.status_id == 1) {
                        this.$toasted.global.success(res.response.message)
                        this.handelResponse(res.response.order_details)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },

            infinteScroll() {
                var scrollTop = document.getElementById('lazy-load-orders').scrollTop
                var scrollHeight = document.getElementById('lazy-load-orders').scrollHeight
                var offsetHeight = document.getElementById('lazy-load-orders').offsetHeight
                var clientHeight = document.getElementById('lazy-load-orders').clientHeight
                var contentHeight = scrollHeight - offsetHeight
                if (contentHeight == scrollTop) {
                    if (!this.infinite_scroll_reached_end) {
                        this.infinite_scroll()
                    }
                }
            },
            async coursePrepare(prepareCourse, order_id, courseno) {
                try {
                    let params = {
                        order_id: order_id,
                        isPrepared: prepareCourse,
                        course: courseno
                    }
                    let res = await this.coursePrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    this.courseDetails.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.courseDetails[cindex].isPrepared = false
                        }
                    })
                    this.$toasted.global.error(reason)
                }
            },

            stateMaintain(res) {
                let orderData = res.orders
                orderData.forEach((element, cindex) => {
                    if (element.display_status) {
                        this.disable_course = element.display_status.courses
                        this.disable_item = element.display_status.items
                        this.orderCourse_display = element.display_status.courses
                        this.orderItem_display = element.display_status.items
                    }
                })
            },
            infinite_scroll() {
                let payload = {
                    searchdata: this.searchdata,
                    skip: this.count,
                    limit: 10,
                    order_type: this.temp_showfilterData.order_type,
                    delivery_companies: [...new Set(this.temp_showfilterData.delivery_companies.map(x => x.name))],
                    online_companies: [...new Set(this.temp_showfilterData.online_companies.map(x => x.name))],
                    payment_status: this.temp_showfilterData.payment_status
                }
                this.loading = true
                this.$http.post('/orders/manage_orders', payload).then(res => {
                        let result = res.data.response
                        if (res.data.response.skip == this.count) {
                            let tempFiles = res.data.response.orders
                            tempFiles.forEach((element, index) => {
                                element['toggle'] = true
                            }),
                            tempFiles.forEach(el => {
                                this.orderListView.push(el)
                            })
                            this.count += res.data.response.orders.length
                            this.loading = false
                        }
                        if (res.data.response.orders.length < 10) {
                            this.infinite_scroll_reached_end = true
                            document.getElementById('spinner').style.display = 'none'
                            this.loading = false
                        }
                        this.maintainOrderState(result)

                    })
                    .catch(error => {
                        this.loading = false
                    })
            },
            async orderFire(fireOrder, order_id) {
                try {
                    let params = {
                        order_id: order_id,
                        target: 'ORDER',
                        action: fireOrder,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async courseFire(fireCourse, courseno, order_id) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'COURSE',
                        action: fireCourse,
                        course: courseno,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemFire(item, order_id) {
                try {
                    let params = {
                        order_id: order_id,
                        target: 'ITEM',
                        action: item.isFired,
                        course: item.course,
                        item_id: item.item_id,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Hold and Unhold For Order,Course and Item
            // async holdOrderApiCall(ishold) {
            //     try {
            //         let params = {
            //             order_id: this.$route.params.id,
            //             isHold: ishold,
            //             get_details: true
            //         }
            //         let res = await this.holdWholeOrder(params)
            //         if (res.status_id == 1) {
            //             this.maintainOrderState(res)
            //             this.$toasted.global.success(res.response.message)
            //         }
            //     } catch (reason) {
            //         setTimeout(() => {
            //             this.order.isOrderOnHold = false
            //         }, 500)
            //         this.$toasted.global.error(reason)
            //     }
            // },
            async getmanageorderApi(params) {
                return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/orders/manage_orders', params)
                    let res = response.data.response
                    if (this.loggedInUser.role == 'default_order_receiver') {
                        this.stateMaintain(res)
                        this.maintainOrderState(res)
                    }
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                   
                })
            },
            async getManagerDetails() {
                try {
                    this.ajaxCallInProgress = true
                    let temp_res = {
                        orders: []
                    }
                    EventBus.$emit('UpdateCard', temp_res, this.showfilterData, this.ajaxCallInProgress)
                    let params = {
                        skip: this.count,
                        limit: 10,
                        searchdata: this.searchdata,
                        order_type: this.filterData.order_type,
                        delivery_companies: this.filterData.delivery_companies,
                        online_companies: this.filterData.online_companies,
                        payment_status: this.filterData.payment_status
                    }
                    let res = await this.getmanageorderApi(params)
                    this.ajaxCallInProgress = false;
                    EventBus.$emit('UpdateCard', res.response, this.showfilterData, this.ajaxCallInProgress)
                } catch (reason) {
                    this.ajaxCallInProgress = false;
                    this.noSearchResult = false
                }
            },
            handelResponse(details) {
                this.orderListView.forEach((el, index) => {
                    if (el._id == details._id) {
                        el = details
                    }
                })
            },
             maintainOrderState(res,item_id) {
                this.orderListDetails = res.orders
                if (this.orderListDetails == '' || this.orderListDetails == undefined || this.orderListDetails == null) {
                    this.courseDetails = res.response.course_details
                    for (var course_index in this.courseDetails) {
                        this.orderTableData.push(course_index)
                        for (var item_index in this.courseDetails[course_index].item_details) {
                            if(this.courseDetails[course_index].item_details[item_index].item_id == item_id){
                               
                                if (this.courseDetails[course_index].item_details[item_index].item_status == 'prepared') { 
                                    this.courseDetails[course_index].item_details[item_index].preparedCount++
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = true
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                } else if (this.courseDetails[course_index].item_details[item_index].item_status ==
                                    'placed') { 
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = false
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                } else if (
                                    this.courseDetails[course_index].item_details[item_index]
                                    .item_status == 'preparing'
                                ) { 
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = true
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                } else if (
                                    this.orderListDetails[course_index].item_details[item_index].item_status == 'delivered'
                                ) {
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = true
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = true
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = true
                                } else if (
                                    this.orderListDetails[course_index].item_details[item_index].item_status == 'hold'
                                ) {
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                    this.courseDetails[course_index].item_details[item_index].isHold = true
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = true
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                } else if (
                                    this.orderListDetails[course_index].item_details[item_index].item_status == 'unavailable'
                                ) {
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = true
                                    this.courseDetails[course_index].item_details[item_index].isFired = true
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                } else {
                                    this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                    this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                    this.courseDetails[course_index].item_details[item_index].isHold = false
                                    this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                    this.courseDetails[course_index].item_details[item_index].isFired = false
                                    this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                }
                            }
                        }
                    } 
                } else {
                    
                    this.orderListDetails.forEach((element, cdindex) => {
                        if(this.orderListView.length < 10){
                            this.orderTableData.push(cdindex)
                        } else{
                            this.orderTableData.push(cdindex+(this.orderListView.length-this.orderListDetails.length))
                        }
                        element.order_items.forEach((order_item, tiindex) => {
                            order_item.item_details.forEach((item, otindex) => {
                                if (item.item_status == 'prepared') {
                                    item.preparedCount++
                                    item.isPrepared = true
                                    item.isHold = false
                                    item.unAvailable = false
                                    item.isFired = true
                                    item.isDelivered = false
                                } else if (item.item_status ==
                                    'placed') {
                                    item.isDelivered = false
                                    item.isPrepared = false
                                    item.isHold = false
                                    item.unAvailable = false
                                    item.isFired = false
                                    item.isDelivered = false
                                } else if (item.item_status == 'preparing') {
                                    item.isDelivered = false
                                    item.isPrepared = false
                                    item.isHold = false
                                    item.unAvailable = false
                                    item.isFired = true
                                    item.isDelivered = false
                                } else if (item.item_status == 'delivered' ) {
                                    item.isDelivered = true
                                    item.isPrepared = true
                                    item.isHold = false
                                    item.unAvailable = false
                                    item.isFired = true
                                } else if ( item.item_status == 'hold' ) {
                                    item.isPrepared = false
                                    item.isHold = true
                                    item.unAvailable = false
                                    item.isFired = true
                                } else if ( item.item_status == 'unavailable' ) {
                                    item.isDelivered = false
                                    item.isPrepared = false
                                    item.isHold = false
                                    item.unAvailable = true
                                    item.isFired = true
                                } else {
                                    item.isDelivered = false
                                    item.isPrepared = false
                                    item.isHold = false
                                    item.unAvailable = false
                                    item.isFired = false
                                }
                            })
                        })
                    })
                }
                this.$forceUpdate();
            },
        },
        mounted() {
            this.getManagerDetails()
            document.getElementById('lazy-load-orders').addEventListener('scroll', this.infinteScroll,
                false)
            EventBus.$on('UpdateCard', (res, details, call) => {
                this.ajaxCallInProgress = false
                this.orderListView = res.orders
                this.orderListView.forEach((element, index) => {
                    element['toggle'] = true
                }),
                this.count = res.orders.length
                this.temp_showfilterData = details
                this.ajaxCallInProgress = call
            });
            EventBus.$on("update_order",() => {
                this.getManagerDetails()
            }) 
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }  
        }
    }
</script>

<style scoped>
    #lazy-load-orders {
        height: 690px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 30px;
        -ms-overflow-style: none;
        /* IE 11 */
        scrollbar-width: none;
        /* Firefox 64 */
    }

    #lazy-load-orders::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .top-view-order {
        border-radius: 5px;
        background-image: linear-gradient(278deg, #3a3985 36%, #00448b 74%);
    }

    .text-area-manage {
        border-radius: 5px;
        background: transparent;
        color: red;
        border: 1px solid #fff;
        width: 100%;
        padding: 5px;
        height: 140px;
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
    }

    .round input[type='checkbox'] {
        visibility: hidden;
    }

    .round input[type='checkbox']:checked+label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }

    .page-content-card {
        border-radius: 12px;
        border: solid 2.5px #f6f6f6;
        background-color: #fff;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .addon-view {
        border-radius: 6px;
        border: 2px solid #eee;
    }

    .view-vizard-big {
        background-color: #fff;
        border-radius: 35px;
        padding: 8px;
    }

    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }

    .stepwizard-row {
        display: table-row;
    }

    .stepwizard-row:before {
        top: 16px;
        bottom: 0;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: 0;
    }

    .stepwizard-step {
        display: table-cell;
        text-align: center;
        position: relative;
    }

    .wizardSteps {
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        color: #94bef0 !important;
        text-align: center;
        display: initial;
        padding: 6px 0;
        font-size: 13px;
        font-weight: 600;
        line-height: 2.5;
        border-radius: 15px;
        text-decoration: none;
    }

    .view-vizard-big .stepwizard-step {
        left: 8% !important;
    }

    .view-vizard-big .btn-circle {
        line-height: 2.7 !important;
    }

    .view-vizard-small .stepwizard-step {
        left: 0% !important;
    }

    .view-vizard-small .wizardSteps {
        width: 26px !important;
        height: 26px !important;
    }

    .view-vizard-small .stepwizard-row:before {
        width: 80% !important;
        top: 14px !important;
    }

    .view-vizard-small .btn-circle {
        line-height: 2 !important;
    }

    .wizardSteps {
        border: none !important;
    }

    .greenCircle {
        background: #1a9347 !important;
        color: #fff !important;
    }

    .redCircle {
        background: #e82828 !important;
        color: #fff !important;
    }

    .orangeCircle {
        background: #f5a623 !important;
        color: #fff !important;
    }

    .btn-circle {
        padding: 5px 0 !important;
        line-height: 2.6 !important;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    .pointerEventsNone {
        pointer-events: none !important;
    }

    .maxstepwizard:before {
        width: 80% !important;
    }

    .onholddiv {
        position: absolute;
        width: 97.5%;
        text-align: center;
        height: 40px;
        background-color: #606266;
        opacity: 1;
        top: 40%;
        right: 0px;
        left: 0px;
        margin-left: 15px;
        z-index: 999 !important;
        pointer-events: all !important;
    }

    .OnHoldClass {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }

    .onholddiv .btn-checkout {
        border-radius: 23px;
        top: 3px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031;
        padding: 0px 10px;
        margin: 0px;
        font-size: 14px !important;
        font-weight: 600;
        height: 3.4rem;
        line-height: 2.6rem;
        text-transform: capitalize;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .view-cour-comp .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__inner {
        border-radius: 50% !important;
    }

    .table-head {
        background-color: #005dae;
        color: #fff !important;
        padding: 2px !important;
    }

    .table-head>th,
    .thead>td {
        text-align: left;
        color: #fff !important;
    }

    .text-color {
        color: #f28741
    }

    .text-order-color {
        color: #0962af
    }
</style>