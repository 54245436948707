import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            count: 0,
            limit: 10,
            skip: 0,
            start: 1,
            end: 0,
            passed_key: {
                key: 'name',
                value: 1
            },
            warning_modal: '',
            modal_msg: '',
            ajaxCallInProgress: false,
            totalProductType: 0,
            showIllustrator: false
        }
    },
    methods: {
        saveProductType() {
            this.productByType.status = true
            this.$http
                .post('/products/create_product_type', this.productByType)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$modal.hide('create-product-type')
                        this.$emit('saving')
                        this.$swal({
                            title: 'Product Type Added Successfully',
                            text: '',
                            type: 'success'
                        })
                        this.productByType = {
                            name: '',
                            description: '',
                            image_url: '',
                            category_id: '',
                            sub_category_id: ''
                        }
                        this.temp_category_id = ''
                        this.temp_sub_category_id = ''
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
        },
        updateProductType(details) {
            this.$http
                .post('/products/edit_product_type', details)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$modal.hide('edit-product-type')
                        if (this.source == 'fromCategory') {
                            this.$emit('saved')
                        } else {
                            this.$emit('savingEdited')
                        }
                        this.$swal({
                            title: 'Product Type Updated Successfully',
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllProductTypes()
        },
        getAllProductTypes(param) {
            if (param == 'prev') this.count--
            else if (param == 'next') this.count++
            else if (param == 'init') {
                this.count = 0
            } else if (param == 'searchdata') {
                this.count = 0
            }
            this.ajaxCallInProgress = true
            this.allProductType = []
            this.$http
                .post('/products/manage_product_type', {
                    skip: this.count * this.limit,
                    limit: this.limit,
                    searchdata: this.searchdata.trim(),
                    sort_key: this.passed_key.key,
                    sort_value: this.passed_key.value
                })
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.allProductType =
                            response.data.response.all_product_types
                        this.totalProductType =
                            response.data.response.all_product_count
                        this.start = this.count * this.limit + 1
                        this.end =
                            this.count * this.limit + this.limit <
                            this.totalProductType
                                ? this.count * this.limit + this.limit
                                : this.totalProductType
                    }
                    this.ajaxCallInProgress = false
                    if (
                        this.allProductType.length == 0 &&
                        !this.ajaxCallInProgress &&
                        !this.searchdata
                    ) {
                        this.showIllustrator = true
                    } else {
                        this.showIllustrator = false
                    }
                })
        },
        confirmDelete(productTye) {
            Swal({
                title: 'Are you sure?',
                text:
                    'Are you sure To delete the Product Type ' +
                    productTye.name +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.deleteProductType(productTye)
                }
            })
        },
        deleteProductType(productTye) {
            this.$http
                .post('/products/delete_product_type', {
                    product_type_id: productTye.product_type_id
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        Swal(
                            'Deleted!',
                            productTye.name + ' Product Type has been deleted.',
                            'success'
                        )
                        this.checkPageCount()
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response
                    this.$refs.warning_modal.open()
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        changeCategory(newValue) {
            this.edit_productByType.sub_category_id = ''
            this.edit_productByType.product_type_id = ''
        },
        checkPageCount() {
            if (this.count >= 1) {
                if (this.totalProductType <= this.count * this.limit + 1) {
                    this.count--
                }
            }
            this.getAllProductTypes()
        }
    },
    computed: {
        ...mapGetters({
            loggedInUser: 'loggedInUser'
        })
    }
}
