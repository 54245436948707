<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="outlet-select-modal popup-modal-all-width-height-height-370 popup-modal-all-width-height-height-select-outlet">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">SELECT OUTLET</span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">
                    <div class="">
                        <div class="d-flex searchInput">
                            <i class="icon icon-magnify"></i>
                            <input type="text" class="form-control" v-debounce:200ms="getOutlets" debounce-events="input" v-model="search_key"
                                placeholder="Search Outlets.." aria-label="Search.." />
                        </div>

                        <!-- <div
                            style="overflow: auto;max-height: 400px;padding-bottom: 48px;"
                        >
                            <div
                                class="all-outlets-select col-md-12 row pt-3 pl-8"
                                v-if="filter_outlets.length != 0"
                            >
                                <div class="col-lg-3 col-md-3 col-sm-3 col-sm-3 col-xs-3" v-if="
                                        'All Outlets'.match(
                                            new RegExp(
                                                this.search_key.trim(),
                                                'gi'
                                            )
                                        )
                                    "
                                >
                                    <div class="user-info flex-column row flex-lg-row align-items-center">
                                        <i v-if="!loggedInUser.outlet"
                                            class="status text-white icon-check s-4 selected-card"
                                        ></i>
                                        <img
                                            class="profile-image avatar huge"
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/all-outlets.svg"
                                        />
                                        <div v-on:click="
                                                changeOutlet({
                                                    outlet_id: null
                                                })
                                            "
                                            class="name my-4 text-truncate text-center"
                                        >
                                            All Outlets
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-lg-3 col-md-3 col-sm-3 col-sm-3 col-xs-3"
                                    :key="outlet._id"
                                    v-for="outlet in filter_outlets"
                                >
                                    <div
                                        class="user-info flex-column row flex-lg-row align-items-center"
                                        style="text-transform: capitalize;"
                                    >
                                        <i
                                            v-if="
                                                loggedInUser.outlet ==
                                                    outlet.name
                                            "
                                            class="status text-white icon-check s-4 selected-card"
                                        ></i>
                                        <img
                                            class="profile-image avatar-outlet"
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/outlet-location.svg"
                                        />
                                        <div
                                            v-on:click="
                                                changeOutlet({
                                                    outlet_id: outlet._id
                                                })
                                            "
                                            class="name my-4 text-truncate pl-3"
                                        >
                                            {{ outlet.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 pt-8 pb-8 text-center" v-else>
                                <strong>No Outlets Found</strong>
                            </div>
                        </div> -->
                        <div v-if="search_api_call_in_progress" class="d-flex align-items-center justify-content-center position-absolute" style="bottom: -25px;left: 50%;transform: translateX(-50%);">
                            <div class="digi-table-loader inverse">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div v-else-if="!search_api_call_in_progress && outlets.length <=0" class="my-3 text-center">
                            <span>Outlets Not Found</span>
                        </div>
                        <div v-else>
                        <div @scroll="scrollToEndOutletList" class="row" v-if="outlets.length != 0"
                            style="overflow: auto;max-height: 250px;padding-bottom: 48px;padding:0 12px">
                            <div class="col-lg-3 col-md-3 col-sm-3 mt-1" v-if="('All Outlets'.match(new RegExp(this.search_key.trim(),'gi'))) && loggedInUser.role == 'default_admin'">
                                <div class="user-info">
                                    <i v-if="!loggedInUser.outlet"
                                        class="status text-white icon-check s-4 selected-card"></i>
                                    <img @click="
                                                changeOutlet({
                                                    outlet_id: null
                                                })
                                            " class="profile-image avatar-outlet m-auto display-block pointer"
                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/all-outlets.svg" />
                                    <div @click="
                                                changeOutlet({
                                                    outlet_id: null
                                                })
                                            " class="name text-center pointer font-weight-600">
                                        All Outlets
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 mt-1" :key="outlet._id" v-for="outlet in outlets">
                                <div style="text-transform: capitalize;">
                                    <i v-if="
                                                loggedInUser.outlet ==
                                                    outlet.name
                                            " class="status text-white icon-check s-4 selected-outlet-card"></i>
                                    <img @click="
                                        changeOutlet({
                                            outlet_id: outlet._id
                                        })
                                    " class="profile-image avatar-outlet m-auto display-block pointer"
                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/outlet-location.svg" />
                                    <div @click="
                                                changeOutlet({
                                                    outlet_id: outlet._id
                                                })
                                            " class="name text-center pointer font-weight-600 text-truncate" :title="outlet.name">
                                        {{ outlet.name }}
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-lg-3 col-md-3 col-sm-3"
                                    :key="outlet._id"
                                    v-for="outlet in filter_outlets">
                                    <div style="text-transform: capitalize;">
                                        <i v-if="loggedInUser.outlet == outlet.name"
                                            class="status text-white icon-check s-4 selected-card"
                                        ></i>
                                        <img
                                            class="profile-image avatar-outlet m-auto display-block"
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/outlet-location.svg"
                                        />
                                        <div v-on:click="changeOutlet({
                                                    outlet_id: outlet._id
                                                })
                                            " class="name my-4"
                                        >
                                            {{ outlet.name }}
                                        </div>
                                    </div>
                                </div> -->
                            <div v-if="api_call_in_progress" class="d-flex align-items-center justify-content-center position-absolute" style="bottom: -25px;left: 50%;transform: translateX(-50%);">
                                <div class="digi-table-loader inverse">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-footer text-center pb-5">
                    <button type="submit" class="btn btn-secondary btn-smm" @click="hideoutlets()">
                        CLOSE
                    </button>
                </div>
            </div>
        </modal>
        <div v-if="outlet_change_api_inprogress" class="full-page-loader">
            <div class="d-flex align-items-center justify-content-center">
                <div class="digi-table-loader inverse">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
    </div>
</template>
<style>
    .span-label {
        font-weight: 600;
        color: #9e9e9e;
        padding-left: 20px;
    }

    .search-bar .input-group {
        display: -webkit-box !important;
        border-radius: 50px;
        background-color: #f2f2f2;
        border: 1px solid #eee;
    }

    .search-bar .input-group .form-control {
        border-bottom: 0px solid #cdd2da !important;
        height: 39px !important;
    }

    .search-bar .input-group .btn:not(:disabled):not(.disabled) {
        padding-top: 9px;
        padding-bottom: 4px;
    }

    .all-outlets-select .avatar.huge {
        width: 40px;
        min-width: 40px;
        line-height: 30px;
        height: 74px !important;
        background-color: transparent !important;
    }

    .avatar-outlet {
        width: 40px;
        min-width: 40px;
        line-height: 30px;
        height: 74px !important;
        background-color: transparent !important;
    }

    .all-outlets-select .user-info {
        display: -webkit-inline-box !important;
        cursor: pointer;
    }

    .all-outlets-select .user-info:hover .name {
        color: #00448b !important;
    }

    .all-outlets-select .name {
        color: #303031 !important;
        font-weight: 600;
        font-size: 13px;
        width: 100px;
    }

    .all-outlets-select .avatar {
        border-radius: 0% !important;
    }

    .outlet-select-modal .selected-card,
    .outlet-select-modal .selected-wallet {
        position: absolute;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-width: 12px;
        font-size: 13px !important;
        margin-top: -6px;
        z-index: 99999;
        border-radius: 100px;
        padding: 0 5px;
        text-align: center;
        background-color: #4caf50 !important;
    }

    .outlet-select-modal .selected-card {
        /* left: 43px; */
        top: 20px;
    }

    .outlet-select-modal .selected-outlet-card {
        position: absolute;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-width: 12px;
        font-size: 13px !important;
        margin-top: -6px;
        z-index: 99999;
        border-radius: 100px;
        padding: 0 5px;
        text-align: center;
        background-color: #4caf50 !important;
    }

    .outlet-select-modal .selected-outlet-card {
        left: 90px;
        top: 20px;
    }

    @media screen and (max-width: 767.5px) {
        .outlet-select-modal .selected-outlet-card {
            left: 317px !important;
            top: 20px;
        }
    }

    .search-bar .input-group .input-group-btn:first-child {
        margin-left: 7px !important;
        margin-right: 2px !important;
    }

    .searchInput {
        border-radius: 50px;
        background-color: #f2f2f2;
        border: 1px solid #eee;
        height: 40px;
        align-items: center;
        margin-left: 5px;
        margin-top: 5px;
        margin-right: 5px;
    }

    .searchInput i {
        line-height: 2.8rem !important;
        padding-left: 10px !important;
    }

    .searchInput .form-control {
        border-bottom: none !important;
        margin-left: 20px !important;
    }

    .font-weight-600 {
        font-weight: 600
    }
</style>
<script>
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import {
        setTimeout
    } from 'timers'

    export default {
        data() {
            return {
                outlets: [],
                modal_msg: '',
                search_key: '',
                // filter_outlets: [],
                api_call_in_progress: false,
                search_api_call_in_progress: false,
                outlet_change_api_inprogress: false
            }
        },
        created() {
        },
        components: {
            SweetModal
        },
        props: ['modal_name'],
        methods: {
            hideoutlets() {
                this.search_key = ''
                // this.filter_outlets = this.outlets
                this.$modal.hide('outlet')
            },
            getOutlets() {
                this.outlets = [];
                // this.filter_outlets = [];
                this.outlet_count = 0;
                this.search_api_call_in_progress = true;
                this.$http.post('/outlet/get', {
                    switcher: true,
                    skip: 0,
                    search_key: this.search_key,
                    limit: 10,
                }).then(res => {
                    if (res.data.status_id == 1) {
                        this.outlets = res.data.outlets
                        // this.filter_outlets = res.data.outlets,
                        this.outlet_count = res.data.outlets.length
                    }
                    this.search_api_call_in_progress = false;
                }).catch(error=>{
                    this.search_api_call_in_progress = false;
                });
            },
            async scrollToEndOutletList(e) {
                const {target} = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so
                    //you could do an api call here to implement lazy loading
                    try {
                        this.api_call_in_progress = true
                        let params = {
                            switcher: true,
                            skip: this.outlet_count,
                            limit: 10,
                            search_key: this.search_key,
                        }
                        let result = await this.$http.post('/outlet/get', params);
                        if (result.data.status_id == 1) {
                            if (result.data.payload.skip == this.outlet_count) {
                                let tempFiles = result.data.outlets
                                tempFiles.forEach(el => {
                                    // this.outlets.push(el)
                                    this.outlets.push(el)
                                })
                                this.outlet_count += result.data.outlets.length;
                                this.api_call_in_progress = false
                            }
                            this.api_call_in_progress = false
                        }
                    } catch (reason) {
                        this.api_call_in_progress = false
                    }
                    // this.bottom = true;
                }
            },
            async changeOutletApi(params) {
                return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post('/change_outlet', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                })
            },
            setCookie(days, token) {
                var expires = "";
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toUTCString();
                }
                document.cookie = "Token" + "=" + (token) + expires + ";domain=.digicollect.com; path=/";
            },
            async changeOutlet(outlet) {
                console.log(outlet)
                var vueinstance = this
                this.outlet_change_api_inprogress = true;
                this.search_key = ''
                // this.filter_outlets = this.outlets
                try {
                    let response = await this.changeOutletApi(outlet)
                    if (response.status_id == 1) {
                        // this.$cookie.set('Token', response.Authorization, {
                        //     // expires: 1,
                        //     domain: window.location.hostname
                        // })
                        // this.$cookie.set('Token', response.Authorization, {
                        //     // expires: 1,
                        //     domain: 'localhost'
                        // })
                        this.$cookie.delete("Token");
                        this.$cookie.delete('Token', {
                            domain: 'digicollect.com'
                        });
                        this.$cookie.delete('Token', {
                            domain: window.location.hostname
                        });
                        
                        this.setCookie(7, response.Authorization)
                        if (outlet.outlet_id == null) {
                            this.$router.push({
                                name: 'CustomerManage'
                            })
                        } else {
                            this.$router.push('/home')
                        }

                        setTimeout(() => {
                            this.outlet_change_api_inprogress = false;
                            window.location.reload()
                        }, 1000)
                    } else {
                        this.modal_msg = 'Cannot Change Outlet'
                        this.outlet_change_api_inprogress = false;
                    }
                } catch (error) {
                    this.modal_msg = 'Cannot Change Outlet'
                        this.outlet_change_api_inprogress = false;
                }
                // this.$http.post('/change_outlet', outlet).then(res => {
                //     if (res.data.status_id == 1) {
                //         console.log(res.data)
                //         this.$cookie.set('Token', res.data.Authorization, {
                //             // expires: 1,
                //             domain: window.location.hostname
                //         })
                //         this.$cookie.set('Token', res.data.Authorization, {
                //             // expires: 1,
                //             domain: 'localhost'
                //         })
                //         this.$router.push({ name: 'Homenew' })
                //         setTimeout(() => {
                //             window.location.reload()
                //         }, 1000)
                //     } else {
                //         this.modal_msg = 'Cannot Change Outlet'
                //     }
                // })
                this.$modal.hide('outlet')
            },
            navigateToHome() {
                this.loggedInUser.outlet = null
                this.$router.push({
                    name: 'Homenew'
                })
                this.$modal.hide('outlet')
            },
            // filterOutlets() {
            //     this.filter_outlets = []
            //     for (var i = 0; i <= this.outlets.length; i++) {
            //         if (
            //             this.outlets[i] &&
            //             this.outlets[i].name.match(
            //                 new RegExp(this.search_key.trim(), 'gi')
            //             )
            //         ) {
            //             this.filter_outlets.push(this.outlets[i])
            //         }
            //     }
            //     if (this.search_key.trim().length == 0)
            //         this.filter_outlets = this.outlets
            // }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser
            }
        },
        mounted() {
            this.getOutlets()
            EventBus.$on('updateOutlets', value => {
                this.getOutlets()
            })
            EventBus.$on('outletSwitcher', value => {
                this.search_key = ''
                this.getOutlets()
            })
        }
    }
</script>