<template>
    <div class="page-layout blank report-manage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-3 mt-1 backButtonAlign">
                        <button class="btn btn-checkout" @click="$router.go(-1)">Back<i class="icon-arrow-left"></i></button>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <date-picker v-model="dateRange" @confirm="onDateChange()" format="MM/DD/YYYY" :first-day-of-week="7" range :shortcuts="shortcuts" :lang="lang" :clearable="true" confirm confirm-text="APPLY"></date-picker>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2">
                        <button type="submit" @click="selected = {'_id': null, 'name': 'All Outlets'};dateRange=[null, null];onDateChange();" class="btn btn-secondary btn-smm">RESET</button>
                    </div>
                </div>
            </h2>
            <div class="row no-gutters pt-3">
                <div class="col-lg-4 col-md-2 col-sm-3">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg" class="pb-1 mr-2" style="width:12px;" />Export As
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">CSV</a>
                            <a class="dropdown-item" href="#">Excel</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4">
                    <h6 class="defaultBlue font-weight-bold d-block text-center mt-2 headingCategory">ACTIVITY REPORT
                    </h6>
                </div>
                <div class="col-md-4 col-md-4 col-sm-5" style="text-align: right;padding-top: 6px;">
                    <!-- <span class="item-per-page">Item Per Page</span>
                    <select class="pagination-select">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>-->
                    <span class="pageone">Page 1 -10 of 17</span>
                    <ul class="pager titleMargin">
                        <li>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <a style="margin-right: 20px;">
                                    <i class="icon icon-chevron-left"></i>
                                </a>
                            </el-tooltip>
                        </li>
                        <li>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <a>
                                    <i class="icon icon-chevron-right"></i>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                </div>
                <div class="col-md-12">
                    <div class="summaryBreakdown mt-4">
                        <table class="table table-striped table-fixed">
                            <thead>
                                <tr>
                                    <th>
                                        <span class="sorting-icon">
                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                        </span>
                                        <!-- <span style="font-size: 16px !important;" class="sorting-icon">
                                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>-->
                                        TIME
                                    </th>
                                    <th>
                                        <span class="sorting-icon">
                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                        </span>
                                        ACTIVITY
                                    </th>
                                    <th>
                                        <span class="sorting-icon">
                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                        </span>
                                        STAFF RECORD
                                    </th>
                                    <th>
                                        <span class="sorting-icon">
                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                        </span>
                                        REMARKS
                                    </th>
                                    <th>
                                        <span class="sorting-icon">
                                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                        </span>
                                        OUTLET
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="padding: 0 27px!important;">14:27</td>
                                    <td style="padding: 0 27px!important;">ACTIVITY1</td>
                                    <td style="padding: 0 27px!important;">
                                        <span class="badge badge-success">New</span>
                                    </td>
                                    <td style="padding: 0 27px!important;">
                                        <star-rating :star-size="20"></star-rating>
                                    </td>
                                    <td style="padding: 0 27px!important;">$ 836.93</td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 27px!important;">14:27</td>
                                    <td style="padding: 0 27px!important;">ACTIVITY1</td>
                                    <td style="padding: 0 27px!important;">
                                        <span class="badge badge-success">New</span>
                                    </td>
                                    <td style="padding: 0 27px!important;">
                                        <star-rating :star-size="20"></star-rating>
                                    </td>
                                    <td style="padding: 0 27px!important;">$ 836.93</td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 27px!important;">14:27</td>
                                    <td style="padding: 0 27px!important;">ACTIVITY1</td>
                                    <td style="padding: 0 27px!important;">
                                        <span class="badge badge-success">New</span>
                                    </td>
                                    <td style="padding: 0 27px!important;">
                                        <star-rating :star-size="20"></star-rating>
                                    </td>
                                    <td style="padding: 0 27px!important;">$ 836.93</td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 27px!important;">14:27</td>
                                    <td style="padding: 0 27px!important;">ACTIVITY1</td>
                                    <td style="padding: 0 27px!important;">
                                        <span class="badge badge-success">New</span>
                                    </td>
                                    <td style="padding: 0 27px!important;">
                                        <star-rating :star-size="20"></star-rating>
                                    </td>
                                    <td style="padding: 0 27px!important;">$ 836.93</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr style="background-image: linear-gradient(to bottom, #ffb333, #e29005);">
                                    <td style="padding: 0 23px!important;" class="font-weight-bold text-black">TOTAL
                                    </td>
                                    <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                                    <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                                    <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                                    <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import DatePicker from "vue2-datepicker";
    import vSelect from "vue-select";
    import StarRating from 'vue-star-rating'
    export default {
        data() {
            return {
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: new Date(new Date().getFullYear() + 1, 0, 1),
                dateRange: [],
                lang: {
                    type: "en",
                    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    months: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    pickers: [
                        "next 7 days",
                        "next 30 days",
                        "previous 7 days",
                        "previous 30 days"
                    ],
                    placeholder: {
                        date: "Select Date",
                        dateRange: "Select Date Range"
                    }
                },
                confirmText: {
                    type: String,
                    default: "APPLY"
                },
                shortcuts: [{
                        text: "Today",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate()
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate, this.endDate];
                        }
                    },
                    {
                        text: "This Week",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - new Date().getDay()
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate, this.endDate];
                        }
                    },
                    {
                        text: "This Month",
                        onClick: () => {
                            this.startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                            );
                            this.endDate = new Date();
                            this.dateRange = [this.startDate, this.endDate];
                        }
                    },
                    {
                        text: "This Year",
                        onClick: () => {
                            this.startDate = new Date(new Date().getFullYear(), 0, 1);
                            this.endDate = new Date();
                            this.dateRange = [this.startDate, this.endDate];
                        }
                    }
                ]
            };
        },
        components: {
            DatePicker,
            vSelect,
            StarRating
        },
        mounted() {
            // this.dateRange = [this.startDate,this.endDate];
            EventBus.$emit("customerdetailsLoad", false);
            EventBus.$emit("settingsLoad", false);
            EventBus.$emit("reportsLoad", true);
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        }
    };

</script>
<style scoped>
    select:focus {
        outline: 0px !important;
    }

    ul.pager li a {
        cursor: pointer;
        text-decoration: none !important;
    }

    .pagination-select {
        background: transparent;
        border: none;
        margin-left: 10px;
        border-bottom: 1px solid #00448b;
        border-radius: 0px !important;
        padding-right: 15px !important;
        background-position: right 5px center !important;
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-repeat: no-repeat;
    }

    .pager {
        padding-left: 28px;
        list-style: none;
        display: inline-flex;
    }

    .pageone {
        margin-left: 30px;
    }

    .summaryBreakdown {
        border: solid 1px #94bef0;
        border-radius: 10px;
    }

    .btn-checkout {
        border-radius: 23px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031;
        padding: 0px 10px;
        margin: 0px;
        font-size: 14px !important;
        font-weight: 600;
        height: 3.4rem;
        line-height: 2.6rem;
        text-transform: capitalize;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .btn-checkout i {
        color: #303031;
        font-size: 16px !important;
        font-weight: 600;
        padding-left: 16px;
    }

    .borderTableRadius {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .table-fixed tbody {
        display: block;
        width: 100%;
        height: 380px;
        max-height: 390px;
        overflow-y: auto;
    }

    .table-fixed thead,
    .table-fixed tr,
    .table-fixed td,
    .table-fixed th {
        display: flex;
        flex: 1 1 0%;
    }

    .table-fixed tbody tr,
    .table-fixed tbody td {
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .table-fixed thead th {
        align-items: center;
    }

    .table-fixed tfoot {
        line-height: 52px;
    }

    .hide-arrow {
        visibility: hidden;
    }

    th:hover .sorting-icon {
        visibility: visible !important;
        cursor: pointer;
    }

    .summaryBreakdown {
        overflow-x: auto;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #94bef0;
        border: 4px solid transparent;
        background-clip: content-box;
        /* THIS IS IMPORTANT */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 9px solid #00448b;
    }

    @media screen and (max-width: 617px) {
        .titleMargin {
            padding-left: 10px !important;
        }

        .headingCategory {
            padding-left: 45px;
            white-space: nowrap;
        }

        .backButtonAlign {
            padding-left: 1px;
        }
    }

</style>
