<template>
    <div class="password_container">
        <p v-bind:class="{ first: first }" style="background: linear-gradient(to right, #00AD7C 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ second: second }" style="background: linear-gradient(to right, #00AD7C 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ third: third }" style="background: linear-gradient(to right, #00AD7C 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ fourth: fourth }" style="background: linear-gradient(to right, #00AD7C 50%, #eee 50%);background-size: 201% 100%;background-position: right;" class="mr-0"></p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            first: false,
            second: false,
            third: false,
            fourth: false,
        }
    },
    props: ["pwd"],
    watch: {
        pwd: function() {
            if (this.pwd) {
                if (/[A-Z]/.test(this.pwd) || /\d/.test(this.pwd) || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.pwd) || this.pwd.length > 7) {
                    this.first = true;
                }
                if (/[A-Z]/.test(this.pwd) && /\d/.test(this.pwd) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.pwd) && this.pwd.length > 7) {
                    this.first = true;
                    this.second = true;
                    this.third = true;
                    this.fourth = true;
                }
            } else {
                this.first = false;
                this.second = false;
                this.third = false;
                this.fourth = false;
            }
        }
    }
}
</script>

<style scoped>
    .password_container {
        margin: 10px auto;
        display: flex;
    }

    .password_container p {
        width: 100%;
        height: 6px;
        margin: 0px 10px 0px 0px;
        border-radius: 2px;
        -webkit-transition: background .3s;
        transition: background .3s;
    }

    .first,
    .second,
    .third,
    .fourth {
        background-position: left !important;
    }
</style>