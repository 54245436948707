<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal-loyalty promotions-crate popup-modal-all-width-height">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title" style="color:#303031 !important;">ADD NEW PROMOTION</span>
                <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                    <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                </span>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="v-modal-body">
                <div class="">
                    <div class="gsection">
                        <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                            <span class="font-600" style="color:#fff !important;">General Section</span>
                        </div>
                        <span class="text-secondary font-600">Promotion for<sup>*</sup></span>
                        <div class="row pb-5">
                            <div class="col-lg-12">
                                <div class="d-flex flex-wrap">
                                    <el-checkbox v-model="create.promotion_for.dine_in">Dine In</el-checkbox>
                                    <el-checkbox v-model="create.promotion_for.take_away">Take Away</el-checkbox>
                                    <el-checkbox v-model="create.promotion_for.deliveries" @change="clearTaxAddValues('Deliveries')">Deliveries</el-checkbox>
                                    <el-checkbox v-model="create.promotion_for.online" @change="clearTaxAddValues('Online')">Online</el-checkbox>
                                </div>
                                <span v-if="promotionRequired" class="invalid-feedback-form text-danger">Promotion For is required</span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-5" v-if="create.promotion_for.deliveries">
                                <label class="text-secondary fs-14">Select Delivery Companies<sup>*</sup></label>
                                <multiselect
                                    v-model="create.company_deliveries"
                                    class="digi-multiselect category-multiple-addon"
                                    id="select_order_type"
                                    placeholder="Select Delivery Companies"
                                    :options="delivery_company_list"
                                    label="name"
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :options-limit="40"
                                    :max-height="200"
                                    :show-no-results="true"
                                    :hide-selected="false"
                                    name="tax_order_type"
                                    :limit-text="limitTextDelivery"
                                    @remove="removeDelivery"
                                    :blockKeys="['Delete']"
                                    v-validate="'required'"
                                    track-by="_id"
                                    @open="getDeliveryData('Deliveries')"
                                >
                                <template slot="tag" slot-scope="{ option }">
                                    <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                        <span class="custom__remove" @click="removeDelivery(option)">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                        </span>
                                    </span>
                                </template>
                                </multiselect>
                                <span
                                class="invalid-feedback-form text-danger fs-13"
                                v-show="errors.has('tax_order_type')"
                                >Please Select Delivery Company</span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-5" v-if="create.promotion_for.online">
                                <label class="text-secondary fs-14">Select Online Delivery Companies<sup>*</sup></label>
                                <multiselect
                                    v-model="create.company_online"
                                    class="digi-multiselect category-multiple-addon"
                                    id="select_order_type"
                                    placeholder="Select Online Delivery Companies"
                                    :options="delivery_company_list"
                                    label="name"
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :options-limit="40"
                                    :max-height="200"
                                    :show-no-results="true"
                                    :hide-selected="false"
                                    name="tax_online_co"
                                    :limit-text="limitTextOnline"
                                    @remove="removeOnline"
                                    :blockKeys="['Delete']"
                                    v-validate="'required'"
                                    track-by="_id"
                                    @open="getDeliveryData('Online')"
                                >
                                <template slot="tag" slot-scope="{ option }">
                                    <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                        <span class="custom__remove" @click="removeOnline(option)">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                        </span>
                                    </span>
                                </template>
                                </multiselect>
                                <span
                                class="invalid-feedback-form text-danger fs-13"
                                v-show="errors.has('tax_online_co')"
                                >Please Select Online Company</span>
                            </div>     
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group contactGroup" style="margin-top: -21px;">
                                    <label class="text-secondary font-600" style="font-size: 14px;">
                                        Promotion Type<sup>*</sup>
                                    </label>
                                    <multiselect class="digi-multiselect" :options="promotion_name" v-model="create.promotion_type" :searchable="true" :close-on-select="true" :show-labels="false" :preselect-first="true" placeholder="Select Promotion" v-validate="'required'" name="promotionsSelect"></multiselect>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('promotionsSelect')">Please Select Promotion</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group contactGroup" style="margin-top: -6px;">
                                    <input style="background:transparent !important;" class="inputContact" v-validate="'required'" placeholder="Enter Promotion Name" type="text" :maxlength="promotion_names" name="promotionname" v-model="create.name"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Promotion Name<sup>*</sup></label>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{promotion_names - create.name.length}} / {{promotion_names}}</p>
                                    <span class="invalid-feedback-form text-danger mt-1" v-show="errors.has('promotionname')">{{errors.first('promotionname')}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <div class="form-group contactGroup" style="margin-top: -18px;">
                                    <input style="background:transparent !important;text-transform:uppercase !important;" required class="inputContact" v-validate="'required|alpha_num'" placeholder="" type="text" :maxlength="promotion_code" v-model="create.code" name="promotioncode"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact" style="color:#303031 !important">Promotion Code<sup>*</sup></label>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{promotion_code - create.code.length}} / {{promotion_code}}</p>
                                </div>
                                <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('promotioncode')">{{errors.first('promotioncode')}}</span>
                            </div>
                            <div class="col-md-6">
                                <label class="text-secondary fs-14">Outlet<sup>*</sup></label>
                                <multiselect
                                    v-model="create.outlet_id"
                                    class="digi-multiselect category-multiple-addon"
                                    id="select_order_type"
                                    placeholder="Select Outlet"
                                    :options="outlets"
                                    label="name"
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :options-limit="40"
                                    :max-height="200"
                                    :show-no-results="true"
                                    :hide-selected="false"
                                    name="outlet_id"
                                    :limit-text="limitTextOutletType"
                                    @remove="removeOutlet"
                                    :blockKeys="['Delete']"
                                    v-validate="'required'"
                                    track-by="_id"
                                    @open="getOutlets()"
                                    @search-change="searchOutlets"
                                >
                                <template slot="tag" slot-scope="{ option }">
                                    <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                        <span class="custom__remove" @click="removeOutlet(option)">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                        </span>
                                    </span>
                                </template>
                                </multiselect>
                                <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('outlet_id')">Select Outlet</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group contactGroup" style="margin-top: -15px;">
                                    <input style="background:transparent !important;" class="inputContact" v-validate="'required'" placeholder="Enter Description" type="text" :maxlength="promotion_description" name="promotionDescription" v-model="create.description"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Description<sup>*</sup></label>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{promotion_description - create.description.length}} / {{promotion_description}}</p>
                                </div>
                                <span class="invalid-feedback-form text-danger mt-1" v-show="errors.has('promotionDescription')">Description is required</span>
                            </div>
                        </div>
                        <div v-if="!showXandY">
                            <div class="col-md-12 row justify-content-center no-gutters mb-3" style="background:#ffea92 !important;padding:10px !important;">
                                <span class="font-600" style="color:#303031 !important;">Flat Discount</span>
                            </div>
                            <div class="row no-gutters">
                                <div class="col-lg-12">
                                <span class="font-600">Inventory Type<sup>*</sup></span>
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <div class="col-lg-12 pt-1">
                                    <el-radio-group name="type" v-model="create.flat_details.applicable_for" @change="flatApplicable()" v-validate="'required'">
                                        <el-radio label="category">Category</el-radio>
                                        <el-radio label="sub_category">Sub-Category</el-radio>
                                        <el-radio label="product_type">Product Type</el-radio>
                                        <el-radio label="products">Products</el-radio>
                                    </el-radio-group>
                                    <span v-show="errors.has('type')" class="invalid-feedback-form text-danger">Inventory type is required</span>
                                </div>
                            </div>
                            <div class="row pt-3" v-if="create.flat_details.applicable_for == 'category'">
                                <div class="col-lg-12">
                                    <label class="font-600">Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_category" @change="selectAll()" class="pl-5">Select all Category</el-checkbox>
                                    <multiselect v-if="select_category"
                                        :disabled="true"
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax1"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removeCategory"
                                        name="typeFlat"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="removeCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax1"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removeCategory"
                                        v-validate="'required'"
                                        name="typeFlat"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="removeCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('typeFlat')" class="invalid-feedback-form text-danger">Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-3" v-if="create.flat_details.applicable_for == 'sub_category'">
                                <div class="col-lg-12">
                                    <label class="font-600">Sub Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_subcategory" @change="selectAll()" class="pl-5">Select all Sub Category</el-checkbox>
                                    <multiselect v-if="select_subcategory"
                                        :disabled="true"
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax2"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingsubCategory"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub Category"
                                        @remove="removeSubCategory"
                                        name="typeFlat"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.sub_category_name }}</span>
                                            <span class="custom__remove" @click="removeSubCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax2"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingsubCategory"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub Category"
                                        @remove="removeSubCategory"
                                        v-validate="'required'"
                                        name="typeFlat"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.sub_category_name }}</span>
                                            <span class="custom__remove" @click="removeSubCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('typeFlat')" class="invalid-feedback-form text-danger">Sub Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-3" v-if="create.flat_details.applicable_for == 'product_type'">
                                <div class="col-lg-12">
                                    <label class="font-600">Product Type<sup>*</sup></label>
                                    <el-checkbox v-model="select_productType" @change="selectAll()" class="pl-5">Select all Product Type</el-checkbox>
                                    <multiselect v-if="select_productType"
                                        :disabled="true"
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax3"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingproductType"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removeProductType"
                                        name="typeFlat"
                                    >
                                    <template slot="tag" slot-scope="{ option }">
                                    <span class="custom__tag">
                                        <span>{{ option.product_type_name }}</span>
                                        <span class="custom__remove" @click="removeProductType(option)">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                        </span>
                                    </span>
                                    </template>
                                    <template slot="afterList">
                                    <div v-observe-visibility="reachedEndOfListProductType" />
                                    </template>
                                    <span
                                    slot="noResult"
                                    class="font-weight-bold text-center d-block"
                                    >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.flat_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax3"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingproductType"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removeProductType"
                                        v-validate="'required'"
                                        name="typeFlat"
                                    >
                                    <template slot="tag" slot-scope="{ option }">
                                    <span class="custom__tag">
                                        <span>{{ option.product_type_name }}</span>
                                        <span class="custom__remove" @click="removeProductType(option)">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                        </span>
                                    </span>
                                    </template>
                                    <template slot="afterList">
                                    <div v-observe-visibility="reachedEndOfListProductType" />
                                    </template>
                                    <span
                                    slot="noResult"
                                    class="font-weight-bold text-center d-block"
                                    >No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('typeFlat')" class="invalid-feedback-form text-danger">Product Type is required</span>
                                </div>
                            </div>
                            <div class="pt-1" v-if="create.flat_details.applicable_for == 'products'">
                                <div class="row mt-3" v-for="(p,i) in create.flat_details.product_id" :key="i">
                                    <div class="col-lg-6">
                                        <label class="font-600">Product<sup>*</sup></label>
                                        <el-checkbox v-model="select_product" v-if="i == 0" @change="selectAll()" class="pl-5">Select all Product</el-checkbox>
                                        <div class="d-flex">
                                            <multiselect v-if="select_product"
                                                :disabled="true"
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removeProduct"
                                                @close="updateVariant(i,p.product_id)"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removeProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <multiselect v-else
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removeProduct"
                                                @close="updateVariant(i,p.product_id)"
                                                v-validate="'required'"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removeProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <span @click="addProduct()" v-if="i+1==create.flat_details.product_id.length" :class="{'pointerEventsNone':select_product != ''}">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-button-inside-black-circle.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                            <span @click="removeAddProduct(i)" class="ml-2" v-if="i!=0">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus_red.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                        </div>
                                        <span v-show="errors.has('product_name'+i)" class="invalid-feedback-form text-danger">Product is required</span>
                                    </div>
                                    <div class="col-lg-6" v-if="p.variant">
                                        <label class="font-600">Variant<sup>*</sup></label>
                                        <multiselect
                                            v-model="p.variant_id"
                                            class="digi-multiselect multiple"
                                            id="ajax13"
                                            label="variant_name"
                                            :options="variants"
                                            @search-change="searchVariant(p.product_id,search)"
                                            :multiple="false"
                                            :searchable="true"
                                            :loading="isLoadingvariant"
                                            :internal-search="true"
                                            :clear-on-select="false"
                                            :close-on-select="true"
                                            :max-height="200"
                                            :show-no-results="true"
                                            :hide-selected="false"
                                            track-by="variant_id"
                                            :blockKeys="['Delete']"
                                            placeholder="Select Variant"
                                            @open="getVariant(p.product_id)"
                                            v-validate="'required'" :name="'variant_name'+i">
                                            <span
                                            slot="noResult"
                                            class="font-weight-bold text-center d-block"
                                            >No Results Found</span>
                                        </multiselect>
                                        <span v-show="errors.has('variant_name'+i)" class="invalid-feedback-form text-danger">Variant Name is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">
                                        <input
                                        style="background:transparent !important;"
                                        class="inputContact"
                                        v-validate="'required'"
                                        placeholder="Enter Max Quantity Limit"
                                        type="text"
                                        :maxlength="15"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                        v-model.number="create.flat_details.flat_max_quantity"
                                        name="qty_limitvalid"
                                        />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">
                                        Max Quantity Limit<sup>*</sup>
                                        </label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('qty_limitvalid')">Max Quantity Limit is required</span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="text-secondary font-600">Type<sup>*</sup></span><br />
                                    <el-radio-group class="pt-3" name="flatType" @input="percentageCheck" v-model="create.flat_details.flat_type" v-validate="'required'">
                                        <el-radio label="amount">Amount</el-radio>
                                        <el-radio label="percentage">Percentage</el-radio>
                                    </el-radio-group>
                                    <span v-show="errors.has('flatType')" class="invalid-feedback-form text-danger">Type is required</span>
                                </div>
                            </div>
                            <div class="row" v-if="create.flat_details.flat_type == 'amount'">
                                <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Amount"
                                    type="text"
                                    :maxlength="15"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.flat_details.flat_value"
                                    name="fValue"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Amount<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('fValue')"
                                >Amount is required</span>
                                </div>
                            </div>
                            <div class="row" v-if="create.flat_details.flat_type == 'percentage'">
                                <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Percentage"
                                    type="text"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.flat_details.flat_value"
                                    @input="checkPercentage(create.flat_details.flat_value)"
                                    name="fValue"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Percentage<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('fValue')"
                                >Percentage is required</span>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Max Limit of Amount"
                                    type="text"
                                    :maxlength="15"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.flat_details.max_limit_amount"
                                    name="maxamt"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Max Limit of Amount<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('maxamt')"
                                >Max Limit of Amount is required</span>
                                </div>
                                <!-- <div class="col-md-4">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Min Limit of Amount"
                                    type="number"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.flat_details.min_limit_amount"
                                    name="minamount"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Min Limit of Amount *
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('minamount')"
                                >Min Limit of Amount is required</span>
                                </div> -->
                            </div>
                            <div class="col-lg-12 font-600 pl-0 pr-0" v-if="create.flat_details.flat_type == 'amount'">
                                <span>
                                <img
                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_promotions_dark.svg"
                                    class="mr-1"
                                    style="margin-top: -3px"
                                />
                                If product price is less than amount, product will be considered as free.
                                </span>
                            </div>
                        </div>
                        <div v-if="showXandY">
                            <div class="col-md-12 row justify-content-center no-gutters mb-3" style="background:#ffea92 !important;padding:10px !important;">
                                <span class="font-600" style="color:#303031 !important;">Buy X</span>
                            </div>
                            <div class="row no-gutters">
                                <div class="col-lg-12">
                                <span class="font-600">Inventory Type<sup>*</sup></span>
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <div class="col-lg-12 pt-1">
                                    <el-radio-group name="type" v-model="create.promotion_details.buy_details.applicable_for" v-validate="'required'" @change="checkBuyDetails()">
                                        <el-radio label="category">Category</el-radio>
                                        <el-radio label="sub_category">Sub-Category</el-radio>
                                        <el-radio label="product_type">Product Type</el-radio>
                                        <el-radio label="products">Products</el-radio>
                                    </el-radio-group>
                                    <span v-show="errors.has('type')" class="invalid-feedback-form text-danger">Inventory type is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.buy_details.applicable_for == 'category'">
                                <div class="col-lg-12">
                                    <label class="font-600">Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_buycategory" @change="selectAll()" class="pl-5">Select all Category</el-checkbox>
                                    <multiselect v-if="select_buycategory"
                                        :disabled="true"
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax5"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removebuyCategory"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="removebuyCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax5"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removebuyCategory"
                                        v-validate="'required'"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="removebuyCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('flatX')" class="invalid-feedback-form text-danger">Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.buy_details.applicable_for == 'sub_category'">
                                <div class="col-lg-12" >
                                    <label class="font-600">Sub Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_buysubcategory" @change="selectAll()" class="pl-5">Select all Sub Category</el-checkbox>
                                    <multiselect v-if="select_buysubcategory"
                                        :disabled="true"
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax6"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        :loading="isLoadingsubCategory"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub Category"
                                        @remove="removebuySubCategory"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.sub_category_name }}</span>
                                            <span class="custom__remove" @click="removebuySubCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax6"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        :loading="isLoadingsubCategory"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub Category"
                                        @remove="removebuySubCategory"
                                        v-validate="'required'"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.sub_category_name }}</span>
                                            <span class="custom__remove" @click="removebuySubCategory(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('flatX')" class="invalid-feedback-form text-danger">Sub Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.buy_details.applicable_for == 'product_type'">
                                <div class="col-lg-12">
                                    <label class="font-600">Product Type<sup>*</sup></label>
                                    <el-checkbox v-model="select_buyproductType" @change="selectAll()" class="pl-5">Select all Product Type</el-checkbox>
                                    <multiselect v-if="select_buyproductType"
                                        :disabled="true"
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax7"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :loading="isLoadingproductType"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removebuyProductType"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.product_type_name }}</span>
                                            <span class="custom__remove" @click="removebuyProductType(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListProductType" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.buy_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax7"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :loading="isLoadingproductType"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removebuyProductType"
                                        v-validate="'required'"
                                        name="flatX"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                        <span class="custom__tag">
                                            <span>{{ option.product_type_name }}</span>
                                            <span class="custom__remove" @click="removebuyProductType(option)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                            </span>
                                        </span>
                                        </template>
                                        <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListProductType" />
                                        </template>
                                        <span
                                        slot="noResult"
                                        class="font-weight-bold text-center d-block"
                                        >No Results Found</span>
                                    </multiselect>
                                    <span
                                        v-show="errors.has('flatX')"
                                        class="invalid-feedback-form text-danger"
                                    >Product Type is required</span>
                                </div>
                            </div>
                            <div v-if="create.promotion_details.buy_details.applicable_for == 'products'">
                                <div class="row mt-3" v-for="(p,i) in create.promotion_details.buy_details.product_id" :key="i">
                                    <div class="col-lg-6">
                                        <label class="font-600">Product<sup>*</sup></label>
                                        <el-checkbox v-model="select_buyproduct" @change="selectAll()" v-if="i == 0" class="pl-5">Select all Product</el-checkbox>
                                        <div class="d-flex">
                                            <multiselect v-if="select_buyproduct"
                                                :disabled="true"
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removebuyProduct"
                                                @close="updateVariantBuy(i,p.product_id)"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removebuyProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <multiselect v-else
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removebuyProduct"
                                                @close="updateVariantBuy(i,p.product_id)"
                                                v-validate="'required'"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removebuyProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <span @click="addProductBuy()" v-if="i+1==create.promotion_details.buy_details.product_id.length" :class="{'pointerEventsNone':select_buyproduct != ''}">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-button-inside-black-circle.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                            <span @click="removeAddProductBuy(i)" class="ml-2" v-if="i!=0">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus_red.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                        </div>
                                        <span v-show="errors.has('product_name'+i)" class="invalid-feedback-form text-danger">Product is required</span>
                                    </div>
                                    <div class="col-lg-6" v-if="p.variant">
                                        <label class="font-600">Variant<sup>*</sup></label>
                                        <multiselect
                                            v-model="p.variant_id"
                                            class="digi-multiselect multiple"
                                            id="ajax13"
                                            label="variant_name"
                                            :options="variants"
                                            @search-change="searchVariant(p.product_id,search)"
                                            :multiple="false"
                                            :searchable="true"
                                            :loading="isLoadingvariant"
                                            :internal-search="true"
                                            :clear-on-select="false"
                                            :close-on-select="true"
                                            :max-height="200"
                                            :show-no-results="true"
                                            :hide-selected="false"
                                            track-by="variant_id"
                                            :blockKeys="['Delete']"
                                            placeholder="Select Variant"
                                            @open="getVariant(p.product_id)"
                                            v-validate="'required'" :name="'variant_name'+i">
                                            <span
                                            slot="noResult"
                                            class="font-weight-bold text-center d-block"
                                            >No Results Found</span>
                                        </multiselect>
                                        <span v-show="errors.has('variant_name'+i)" class="invalid-feedback-form text-danger">Variant Name is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">
                                        <input style="background:transparent !important;" :maxlength="15" class="inputContact" v-validate="'required'" placeholder="Enter Max Quantity Limit" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model.number="create.promotion_details.buy_details.buy_max_quantity" name="max_qty_limit"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Max Quantity Limit<sup>*</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('max_qty_limit')">Max Quantity Limit is required</span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="text-secondary font-600">Type<sup>*</sup></span><br />
                                    <el-radio-group class="pt-3" name="buyType" v-model="create.promotion_details.buy_details.buy_type" v-validate="'required'">
                                        <el-radio label="amount">Amount</el-radio>
                                        <el-radio label="quantity">Quantity</el-radio>
                                    </el-radio-group>
                                    <span v-show="errors.has('buyType')" class="invalid-feedback-form text-danger">Type is required</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" v-if="create.promotion_details.buy_details.buy_type == 'amount'">
                                    <div class="form-group contactGroup">
                                        <input style="background:transparent !important;" :maxlength="15" class="inputContact" v-validate="'required'" placeholder="Enter Amount" type="text" v-on:keypress="isNumber($event)" v-model.number="create.promotion_details.buy_details.buy_value" name="buyxamt"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact"> Amount<sup>*</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('buyxamt')">Amount is required</span>
                                </div>
                                <div class="col-md-6" v-if="create.promotion_details.buy_details.buy_type == 'quantity'">
                                    <div class="form-group contactGroup">
                                        <input style="background:transparent !important;" :maxlength="15" class="inputContact" v-validate="'required'" placeholder="Enter Quantity" type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model.number="create.promotion_details.buy_details.buy_value" name="buyxamt"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact"> Quantity<sup>*</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger pb-3 mt-1" v-show="errors.has('buyxamt')">Quantity is required</span>
                                </div>
                            </div>
                            <div class="col-lg-12 font-600 pl-0 pr-0" v-if="create.promotion_details.buy_details.buy_type == 'amount'">
                                <span>
                                <img
                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_promotions_dark.svg"
                                    class="mr-1"
                                    style="margin-top: -3px"
                                />
                                If product price is less than amount, product will be considered as free.
                                </span>
                            </div>
                            <div
                                class="col-md-12 row justify-content-center no-gutters mb-3 mt-3"
                                style="background:#ffea92 !important;padding:10px !important;">
                                <span class="font-600" style="color:#303031 !important;">Get Y</span>
                            </div>
                            <div class="row no-gutters">
                                <div class="col-lg-12">
                                <span class="font-600">Inventory Type<sup>*</sup></span>
                                </div>
                            </div>
                            <div class="row mt-2 mb-2">
                                <div class="col-lg-12 pt-1">
                                <el-radio-group
                                    name="type"
                                    v-model="create.promotion_details.get_details.applicable_for"
                                    v-validate="'required'" @change="checkGetDetails()"
                                >
                                    <el-radio label="category">Category</el-radio>
                                    <el-radio label="sub_category">Sub-Category</el-radio>
                                    <el-radio label="product_type">Product Type</el-radio>
                                    <el-radio label="products">Products</el-radio>
                                </el-radio-group>
                                <span
                                    v-show="errors.has('type')"
                                    class="invalid-feedback-form text-danger"
                                >Inventory type is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.get_details.applicable_for == 'category'">
                                <div class="col-lg-12" >
                                    <label class="font-600">Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_getcategory" @change="selectAll()" class="pl-5">Select all Category</el-checkbox>
                                    <multiselect v-if="select_getcategory"
                                        :disabled="true"
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax9"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removegetCategory"
                                        name="flatY"
                                        >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.name }}</span>
                                                <span class="custom__remove" @click="removegetCategory(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax9"
                                        label="name"
                                        :options="categories"
                                        :multiple="true"
                                        :searchable="true"
                                        :internal-search="true"
                                        :loading="isLoading"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="category_id"
                                        @search-change="loadCategorySearch"
                                        :limit-text="limitText"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Category"
                                        @remove="removegetCategory"
                                        v-validate="'required'"
                                        name="flatY"
                                        >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.name }}</span>
                                                <span class="custom__remove" @click="removegetCategory(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfList" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('flatY')" class="invalid-feedback-form text-danger">Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.get_details.applicable_for == 'sub_category'">
                                <div class="col-lg-12" >
                                    <label class="font-600">Sub Category<sup>*</sup></label>
                                    <el-checkbox v-model="select_getsubcategory" @change="selectAll()" class="pl-5">Select all Sub Category</el-checkbox>
                                    <multiselect v-if="select_getsubcategory"
                                        :disabled="true"
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax10"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :loading="isLoadingsubCategory"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub category"
                                        @remove="removegetSubCategory"
                                        name="flatY"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.sub_category_name }}</span>
                                                <span class="custom__remove" @click="removegetSubCategory(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax10"
                                        label="sub_category_name"
                                        :options="subCategories"
                                        :multiple="true"
                                        :loading="isLoadingsubCategory"
                                        :searchable="true"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="sub_category_id"
                                        @search-change="loadSubCategorySearch"
                                        :limit-text="limitTextSubcategory"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Sub category"
                                        @remove="removegetSubCategory"
                                        v-validate="'required'"
                                        name="flatY"
                                    >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.sub_category_name }}</span>
                                                <span class="custom__remove" @click="removegetSubCategory(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -4px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListSubCategory" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                <span v-show="errors.has('flatY')" class="invalid-feedback-form text-danger">Sub Category is required</span>
                                </div>
                            </div>
                            <div class="row pt-2" v-if="create.promotion_details.get_details.applicable_for == 'product_type'">
                                <div class="col-lg-12">
                                    <label class="font-600">Product Type<sup>*</sup></label>
                                    <el-checkbox v-model="select_getproductType" @change="selectAll()" class="pl-5">Select all Product Type</el-checkbox>
                                    <multiselect v-if="select_getproductType"
                                        :disabled="true"
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax11"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingproductType"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removegetProductType"
                                        name="flatY"
                                        >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.product_type_name }}</span>
                                                <span class="custom__remove" @click="removegetProductType(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListProductType" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                    <multiselect v-else
                                        v-model="create.promotion_details.get_details.id"
                                        class="digi-multiselect multiple"
                                        id="ajax11"
                                        label="product_type_name"
                                        :options="productTypes"
                                        :multiple="true"
                                        :searchable="true"
                                        :loading="isLoadingproductType"
                                        :internal-search="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :limit="3"
                                        track-by="product_type_id"
                                        :limit-text="limitTextProductType"
                                        :blockKeys="['Delete']"
                                        placeholder="Select Product Type"
                                        @search-change="loadProductTypeSearch"
                                        @remove="removegetProductType"
                                        v-validate="'required'"
                                        name="flatY"
                                        >
                                        <template slot="tag" slot-scope="{ option }">
                                            <span class="custom__tag">
                                                <span>{{ option.product_type_name }}</span>
                                                <span class="custom__remove" @click="removegetProductType(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                </span>
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListProductType" />
                                        </template>
                                        <span slot="noResult" class="font-weight-bold text-center d-block">No Results Found</span>
                                    </multiselect>
                                    <span v-show="errors.has('flatY')" class="invalid-feedback-form text-danger">Product Type is required</span>
                                </div>
                            </div>
                            <div v-if="create.promotion_details.get_details.applicable_for == 'products'">
                                <div class="row mt-3" v-for="(p,i) in create.promotion_details.get_details.product_id" :key="i">
                                    <div class="col-lg-6">
                                        <label class="font-600">Product<sup>*</sup></label>
                                        <el-checkbox v-model="select_getproduct" @change="selectAll()" v-if="i == 0" class="pl-5">Select all Product</el-checkbox>
                                        <div class="d-flex">
                                            <multiselect v-if="select_getproduct"
                                                :disabled="true"
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removegetProduct"
                                                @close="updateVariantGet(i,p.product_id)"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removegetProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <multiselect v-else
                                                v-model="p.product_id"
                                                class="digi-multiselect multiple"
                                                id="ajax4"
                                                label="name"
                                                :options="products"
                                                :multiple="false"
                                                :searchable="true"
                                                :loading="isLoadingproduct"
                                                :internal-search="true"
                                                :clear-on-select="false"
                                                :close-on-select="true"
                                                :max-height="200"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :limit="3"
                                                track-by="product_id"
                                                :limit-text="limitTextProduct"
                                                :blockKeys="['Delete']"
                                                placeholder="Select Product"
                                                @search-change="loadProductSearch"
                                                @remove="removegetProduct"
                                                @close="updateVariantGet(i,p.product_id)"
                                                v-validate="'required'"
                                                :name="'product_name'+i"
                                            >
                                                <template slot="tag" slot-scope="{ option }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="removegetProduct(option)">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                                    </span>
                                                </span>
                                                </template>
                                                <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListProduct" />
                                                </template>
                                                <span
                                                slot="noResult"
                                                class="font-weight-bold text-center d-block"
                                                >No Results Found</span>
                                            </multiselect>
                                            <span @click="addProductGet()" v-if="i+1==create.promotion_details.get_details.product_id.length" :class="{'pointerEventsNone':select_getproduct != ''}">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-button-inside-black-circle.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                            <span @click="removeAddProductGet(i)" class="ml-2" v-if="i!=0">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus_red.svg" style="width:20px;cursor:pointer;" />
                                            </span>
                                        </div>
                                        <span v-show="errors.has('product_name'+i)" class="invalid-feedback-form text-danger">Product is required</span>
                                    </div>
                                    <div class="col-lg-6" v-if="p.variant">
                                        <label class="font-600">Variant<sup>*</sup></label>
                                        <multiselect
                                            v-model="p.variant_id"
                                            class="digi-multiselect multiple"
                                            id="ajax13"
                                            label="variant_name"
                                            :options="variants"
                                            @search-change="searchVariant(p.product_id,search)"
                                            :multiple="false"
                                            :searchable="true"
                                            :loading="isLoadingvariant"
                                            :internal-search="true"
                                            :clear-on-select="false"
                                            :close-on-select="true"
                                            :max-height="200"
                                            :show-no-results="true"
                                            :hide-selected="false"
                                            track-by="variant_id"
                                            :blockKeys="['Delete']"
                                            placeholder="Select Variant"
                                            @open="getVariant(p.product_id)"
                                            v-validate="'required'" :name="'variant_name'+i">
                                            <span
                                            slot="noResult"
                                            class="font-weight-bold text-center d-block"
                                            >No Results Found</span>
                                        </multiselect>
                                        <span v-show="errors.has('variant_name'+i)" class="invalid-feedback-form text-danger">Variant Name is required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                <span class="text-secondary font-600">
                                    Type<sup>*</sup>
                                </span>
                                <br />
                                <el-radio-group
                                    class="pt-3"
                                    name="getType"
                                    v-model="create.promotion_details.get_details.get_type"
                                    v-validate="'required'"
                                    @change="getPercentage()"
                                >
                                    <el-radio label="amount">Amount</el-radio>
                                    <el-radio label="percentage">Percentage</el-radio>
                                    <el-radio label="quantity">Quantity</el-radio>
                                </el-radio-group>
                                <span
                                    v-show="errors.has('getType')"
                                    class="invalid-feedback-form text-danger"
                                >Type is required</span>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                class="col-md-6"
                                v-if="create.promotion_details.get_details.get_type == 'amount'"
                                >
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Amount"
                                    type="text"
                                    :maxlength="15"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.promotion_details.get_details.get_value"
                                    name="getxamt"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Amount<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('getxamt')"
                                >Amount is required</span>
                                </div>
                                <div
                                class="col-md-6"
                                v-if="create.promotion_details.get_details.get_type == 'quantity'"
                                >
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Quantity"
                                    type="text"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    v-model.number="create.promotion_details.get_details.get_value"
                                    name="getqxamt"
                                    :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Quantity<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('getqxamt')"
                                >Quantity is required</span>
                                </div>
                            </div>
                            <div class="row" v-if="create.promotion_details.get_details.get_type == 'percentage'">
                                <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Percentage"
                                    type="text"
                                    @input="checkGetPercentage(create.promotion_details.get_details.get_value)"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.promotion_details.get_details.get_value"
                                    name="getqxamt"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Percentage<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('getqxamt')"
                                >Percentage is required</span>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Max Limit of Amount"
                                    type="text"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.promotion_details.get_details.max_limit_amount"
                                    name="maxamt"
                                    :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Max Limit of Amount<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('maxamt')"
                                >Max Limit of Amount is required</span>
                                </div>
                                <!-- <div class="col-md-4">
                                <div class="form-group contactGroup">
                                    <input
                                    style="background:transparent !important;"
                                    class="inputContact"
                                    v-validate="'required'"
                                    placeholder="Enter Min Limit of Amount"
                                    type="number"
                                    v-on:keypress="isNumber($event)"
                                    v-model.number="create.promotion_details.get_details.min_limit_amount"
                                    name="gminamt"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">
                                    Min Limit of Amount<sup>*</sup>
                                    </label>
                                </div>
                                <span
                                    class="invalid-feedback-form text-danger pb-3 mt-1"
                                    v-show="errors.has('gminamt')"
                                >Min Limit of Amount is required</span>
                                </div> -->
                            </div>
                            <div class="col-lg-12 font-600 pl-0 pr-0" v-if="create.promotion_details.get_details.get_type == 'amount'">
                                <span>
                                <img
                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_promotions_dark.svg"
                                    class="mr-1"
                                    style="margin-top: -3px"
                                />
                                If product price is less than amount, product will be considered as free.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dsection mt-3">
                        <div
                        class="col-md-12 mb-5"
                        style="background:#00448b !important;padding:10px !important;"
                        >
                        <span class="font-600" style="color:#fff !important;">Date Selection</span>
                        </div>
                        <div class="d-flex">
                        <span class="font-200 pr-3">All Day?</span>
                        <el-switch v-model="create.validity.all_day" active-text="Yes" inactive-text="No"></el-switch>
                        </div>
                        <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="group-setup" style="margin-top: 2px;">
                            <label class="font-600" style="color: #303031;margin-bottom: 0px;">
                                Validity Start Date<sup>*</sup>
                            </label>
                            <date-picker
                                v-model="create.validity.validity_start_date"
                                format="MM/DD/YYYY"
                                :first-day-of-week="7"
                                value="alldays"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                @confirm="checkEndDate()"
                                :not-before="new Date()"
                                confirm-text="APPLY"
                                name="startDate"
                                style="width:100%;"
                                v-validate="'required'"
                                class="promotiondate"
                            ></date-picker>
                            <span
                                v-show="errors.has('startDate')"
                                class="invalid-feedback-form text-danger"
                            >Start date is required</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="group-setup" style="margin-top: 2px;">
                            <label class="font-600" style="color: #303031;margin-bottom: 0px;">
                                Validity End Date<sup>*</sup>
                            </label>
                            <date-picker
                                v-if="create.validity.validity_start_date == ''"
                                disabled
                                format="MM/DD/YYYY"
                                :first-day-of-week="7"
                                value="alldays"
                                :lang="lang"
                                :not-before="new Date()"
                                :clearable="true"
                                confirm
                                confirm-text="APPLY"
                                style="width:100%;"
                            ></date-picker>
                            <date-picker
                                v-else
                                v-model="create.validity.validity_end_date"
                                format="MM/DD/YYYY"
                                :first-day-of-week="7"
                                value="alldays"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                @confirm="checkRepeatEndDate()"
                                :not-before="new Date(this.create.validity.validity_start_date)"
                                confirm-text="APPLY"
                                name="endDate"
                                style="width:100%;"
                                v-validate="'required'"
                            ></date-picker>
                            <span
                                v-show="errors.has('endDate')"
                                class="invalid-feedback-form text-danger"
                            >End date is required</span>
                            </div>
                        </div>
                        </div>
                        <div class="row mt-3" v-if="!create.validity.all_day">
                        <div class="col-md-6">
                            <div class="group-setup" style="margin-top: 2px;">
                            <label class="font-600" style="color: #303031;margin-bottom: 0px;">
                                Validity Start Time<sup>*</sup>
                            </label>
                            <date-picker
                                v-model="create.validity.validity_start_time"
                                format="HH:mm"
                                type="time"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                :minute-step="1"
                                confirm-text="APPLY"
                                placeholder="Select Time"
                                name="startTime"
                                style="width:100%;"
                                v-validate="'required'"
                            >
                            <i slot="calendar-icon">
                                <i class="icon icon-clock"></i>
                            </i>
                            </date-picker>
                            <span
                                v-show="errors.has('startTime')"
                                class="invalid-feedback-form text-danger"
                            >Start time is required</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="group-setup" style="margin-top: 2px;">
                            <label class="font-600" style="color: #303031;margin-bottom: 0px;">
                                Validity End Time<sup>*</sup>
                            </label>
                            <date-picker
                                v-if="create.validity.validity_start_time == ''"
                                disabled
                                format="HH:mm"
                                type="time"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                :minute-step="1"
                                confirm-text="APPLY"
                                placeholder="Select Time"
                                name="endTime"
                                style="width:100%;"
                            >
                            <i slot="calendar-icon">
                                <i class="icon icon-clock"></i>
                            </i>
                            </date-picker>
                            <date-picker
                                v-else
                                v-model="create.validity.validity_end_time"
                                format="HH:mm"
                                type="time"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                :minute-step="1"
                                :not-before="new Date(this.create.validity.validity_start_time)"
                                confirm-text="APPLY"
                                placeholder="Select Time"
                                name="endTime"
                                style="width:100%;"
                                v-validate="'required'"
                            >
                            <i slot="calendar-icon">
                                <i class="icon icon-clock"></i>
                            </i>
                            </date-picker>
                            <span
                                v-show="errors.has('endTime')"
                                class="invalid-feedback-form text-danger"
                            >End time is required</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="rsection mt-3">
                        <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                            <span class="font-600" style="color:#fff !important;">Repeat Section</span>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group contactGroup" style="margin-top: -23px;">
                                    <label class="text-secondary font-600" style="font-size: 14px;">Repeat Option<sup>*</sup></label>
                                    <multiselect
                                        :options="repeat_option"
                                        v-model="create.validity.repeat"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        placeholder="Select Option"
                                        v-validate="'required'"
                                        name="repeatselect"
                                        class="digi-multiselect"
                                    ></multiselect>
                                    <span
                                        v-show="errors.has('repeatselect')"
                                        class="invalid-feedback-form text-danger"
                                    >Repeat option is required</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="create.validity.repeat != '' && create.validity.repeat != 'never' && create.validity.repeat !='weekly'">
                                <span class="text-secondary font-600">End Date<sup>*</sup> </span><br />
                                <el-radio-group class="pt-3" v-model="repeat_on_type">
                                    <el-radio label="Never">Never</el-radio>
                                    <el-radio label="Select_date">Select Date</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="row mt-3" v-if="repeat_on_type =='Select_date'">
                            <div class="col-md-6" v-if="create.validity.repeat != 'never' && create.validity.repeat != 'weekly'">
                                <div class="group-setup" style="margin-top: 2px;">
                                    <label class="font-600" style="color: #00448b;margin-bottom: 0px;">
                                        Select End Date<sup>*</sup>
                                    </label>
                                    <date-picker
                                        v-model="create.validity.repeat_end_date"
                                        format="DD-MM-YYYY "
                                        :first-day-of-week="7"
                                        value="alldays"
                                        :lang="lang"
                                        :clearable="true"
                                        confirm
                                        :not-before="new Date(this.create.validity.validity_end_date)"
                                        confirm-text="APPLY"
                                        name="endDate"
                                        style="width:100%;"
                                        v-validate="'required'"
                                    ></date-picker>
                                    <span
                                        v-show="errors.has('endDate')"
                                        class="invalid-feedback-form text-danger"
                                    >End Date is required</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="create.validity.repeat == 'weekly'">
                            <div class="row mt-3">
                                <div class="col-lg-12">
                                    <el-checkbox-group v-model="create.validity.options">
                                        <el-checkbox
                                        v-for="(day,index) in weeksjson"
                                        :key="index"
                                        :label="day.id"
                                        >{{day.value}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="text-secondary font-600">End Date<sup>*</sup> </span><br />
                                    <el-radio-group class="pt-3" v-model="repeat_on_type">
                                        <el-radio label="Never">Never</el-radio>
                                        <el-radio label="Select_date">Select Date</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="col-md-6 mt-3" v-if="repeat_on_type == 'Select_date'">
                                    <div class="group-setup" style="margin-top: 2px;">
                                        <label class="font-600" style="color: #00448b;margin-bottom: 0px;">
                                            Select End Date<sup>*</sup>
                                        </label>
                                        <date-picker
                                            v-model="create.validity.repeat_end_date"
                                            format="DD-MM-YYYY "
                                            :first-day-of-week="7"
                                            value="alldays"
                                            :lang="lang"
                                            :clearable="true"
                                            confirm
                                            :not-before="new Date(this.create.validity.validity_end_date)"
                                            confirm-text="APPLY"
                                            name="endDate"
                                            style="width:100%;"
                                            v-validate="'required'"
                                        ></date-picker>
                                        <span
                                            v-show="errors.has('endDate')"
                                            class="invalid-feedback-form text-danger"
                                        >End Date is required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="csection mt-3">
                        <div
                        class="col-md-12 mb-5"
                        style="background:#00448b !important;padding:10px !important;"
                        >
                        <span class="font-600" style="color:#fff !important;">Customer Limit Section</span>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                        style="background:transparent !important;"
                                        class="inputContact"
                                        placeholder="Enter Daily Value Limit"
                                        type="text"
                                        v-on:keypress="isNumber($event)"
                                        v-model.number="create.limits.customer.daily.value"
                                        :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Daily Value Limit ($)</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                        style="background:transparent !important;"
                                        class="inputContact"
                                        placeholder="Enter Daily Usage Count"
                                        type="text"
                                        v-on:keypress="isNumber($event)"
                                        v-model.number="create.limits.customer.daily.usage_count"
                                        :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Daily Usage Count</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                        style="background:transparent !important;"
                                        class="inputContact"
                                        placeholder="Enter Weekly Value Limit"
                                        type="text"
                                        v-on:keypress="isNumber($event)"
                                        v-model.number="create.limits.customer.weekly.value"
                                        :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Weekly Value Limit ($)</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group contactGroup">
                                    <input
                                        style="background:transparent !important;"
                                        class="inputContact"
                                        placeholder="Enter Weekly Usage Count"
                                        type="text"
                                        v-on:keypress="isNumber($event)"
                                        v-model.number="create.limits.customer.weekly.usage_count"
                                        :maxlength="15"
                                    />
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Weekly Usage Count</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Monthly Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.customer.monthly.value"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Monthly Value Limit ($)</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Monthly Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.customer.monthly.usage_count"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Monthly Usage Count</label>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Yearly Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.customer.yearly.value"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Yearly Value Limit ($)</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Yearly Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.customer.yearly.usage_count"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Yearly Usage Count</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="cosection mt-3">
                        <div
                        class="col-md-12 mb-5"
                        style="background:#00448b !important;padding:10px !important;"
                        >
                        <span class="font-600" style="color:#fff !important;">Outlet Limit Section</span>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Daily Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.daily.value"
                                v-validate="'required'"
                                name="vcdvl"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Daily Value Limit ($)<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcdvl')"
                            >Daily Value Limit is required</span>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Daily Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.daily.usage_count"
                                v-validate="'required'"
                                name="vcduc"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Daily Usage Count<sup>*</sup></label>
                        </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcduc')"
                            >Daily Usage Count is required</span>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Weekly Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.weekly.value"
                                v-validate="'required'"
                                name="vcwvl"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Weekly Value Limit ($)<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcwvl')"
                            >Weekly Value Limit is required</span>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Weekly Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.weekly.usage_count"
                                v-validate="'required'"
                                name="vcwuc"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Weekly Usage Count<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcwuc')"
                            >Weekly Usage Count is required</span>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Monthly Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.monthly.value"
                                v-validate="'required'"
                                name="vcmvl"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Monthly Value Limit ($)<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcmvl')"
                            >Monthly Value Limit is required</span>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Monthly Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.monthly.usage_count"
                                v-validate="'required'"
                                name="vcmuc"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Monthly Usage Count<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcmuc')"
                            >Monthly Usage Count is required</span>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Yearly Value Limit"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.yearly.value"
                                v-validate="'required'"
                                name="vcyvl"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Yearly Value Limit ($)<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcyvl')"
                            >Yearly Value Limit is required</span>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                placeholder="Enter Yearly Usage Count"
                                type="text"
                                v-on:keypress="isNumber($event)"
                                v-model.number="create.limits.outlet.yearly.usage_count"
                                v-validate="'required'"
                                name="vcyuc"
                                :maxlength="15"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Yearly Usage Count<sup>*</sup></label>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('vcyuc')"
                            >Yearly Usage Count is required</span>
                        </div>
                        </div>
                    </div>
                    <div class="accsection mt-3">
                        <div
                        class="col-md-12 mb-5"
                        style="background:#00448b !important;padding:10px !important;"
                        >
                        <span class="font-600" style="color:#fff !important;">Account Section</span>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group contactGroup">
                            <input
                                style="background:transparent !important;"
                                class="inputContact"
                                v-validate="'alpha_num'"
                                placeholder="Enter Account Code"
                                type="text"
                                name="accountcode"
                                :maxlength="acccode"
                                v-model="create.account_code"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Account Code</label>
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >{{acccode - create.account_code.length}} / {{acccode}}</p>
                            </div>
                            <span
                            class="invalid-feedback-form text-danger pb-3 mt-1"
                            v-show="errors.has('accountcode')"
                            >{{errors.first('accountcode')}}</span>
                        </div>
                        </div>
                    </div>
                    <div class="usection mt-3">
                        <div
                        class="col-md-12 mb-5"
                        style="background:#00448b !important;padding:10px !important;"
                        >
                        <span class="font-600" style="color:#fff !important;">User Section</span>
                        </div>
                        <span class="font-600">Assign Promotions to</span>
                        <div v-if="create.assign_promotions_to.customers" class="mb-2">
                        <el-radio-group class="pt-3" v-model="select_customer" @change="selectCustomer()">
                            <el-radio label="all">All Customer</el-radio>
                            <el-radio label="selected">Specific Customer</el-radio>
                        </el-radio-group>
                        <div class="col-lg-12 pl-0 pr-0 mt-5" v-if="select_customer == 'selected'">
                            <label class="font-600">Specific Customer<sup>*</sup></label>
                            <multiselect
                            v-model="create.customer_list"
                            class="digi-multiselect multiple"
                            id="ajax2"
                            label="name"
                            :options="customers"
                            :multiple="true"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :max-height="200"
                            :show-no-results="true"
                            :hide-selected="false"
                            :limit="3"
                            :loading="isLoadingCustomer"
                            track-by="_id"
                            :limit-text="limitTextCustomer"
                            :blockKeys="['Delete']"
                            placeholder="Type to Search"
                            @search-change="loadCustomerSearch"
                            v-validate="'required'"
                            name="customer"
                            >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="removecustomer(option)">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg" style="margin-top: -3px;" />
                                </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfListCustomer" />
                            </template>
                            <span
                                slot="noResult"
                                class="font-weight-bold text-center d-block"
                            >No Results Found</span>
                            </multiselect>
                            <span
                            v-show="errors.has('customer')"
                            class="invalid-feedback-form text-danger"
                            >Customer is required</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-footer text-center pt-3 pb-3">
                <button class="btn btn-secondary btn-smm text-uppercase" :disabled="save_promotion_api_call_inprogress" @click="savePromotions">SAVE
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="save_promotion_api_call_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
                <button class="btn btn-secondary btn-smm text-uppercase ml-2" :disabled="save_promotion_api_call_inprogress" style="background:#1a9347 !important;" @click="savePromotions">SAVE & SEND
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="save_promotion_api_call_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
    </modal>
</template>
<script>
import global from '../global.js';
import Promotionmixin from '../mixins/promotion.js'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    props: ['modal_name'],
    mixins: [Promotionmixin],
    data() {
        return {
            promotionRequired:false,
            delivery_company_list:[],
            allOrderType:[],
            select_category:'',
            select_subcategory:'',
            select_productType:'',
            select_product:'',
            select_buycategory:'',
            select_buysubcategory:'',
            select_buyproductType:'',
            select_buyproduct:'',
            select_getcategory:'',
            select_getsubcategory:'',
            select_getproductType:'',
            select_getproduct:'',
            showXandY:false,
			modal_msg:'',
            search:'',
            lang: {
                type: 'en',
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ],
                pickers: [
                    'next 7 days',
                    'next 30 days',
                    'previous 7 days',
                    'previous 30 days'
                ],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range'
                }
            },
            promotion_names: 45,
            promotion_code: 15,
            promotion_description: 200,
            acccode: 15,
            promotion_name: ['Buy X Get Y', 'Flat Discount'],
            repeat_option: [
                'daily',
                'monthly',
                'never',
                'weekly',
                'yearly'
            ],
            weeksjson: [
                { id: 0, value: 'Mon' },
                { id: 1, value: 'Tue' },
                { id: 2, value: 'Wed' },
                { id: 3, value: 'Thu' },
                { id: 4, value: 'Fri' },
                { id: 5, value: 'Sat' },
                { id: 6, value: 'Sun' }
            ],
            repeat_on_type: 'Never',
            // Select Options
            categories: [],
            temp_categories: [],
            subCategories: [],
            temp_subCategories: [],
            productTypes: [],
            temp_productTypes: [],
            products: [],
            temp_products: [],
            customers: [],
            companys: [],
            temp_companys:[],
            employees: [],
            temp_customers: [],
            skip: 0,
            limit: 10,
            count: 0,
            search_key: '',
            variants:[],
            isLoading: false,
            scrollableCategory: false,
            isVisible: false,
            scrollableSubCategory: false,
            isLoadingsubCategory: false,
            isLoadingproductType: false,
            isLoadingproduct:false,
            isLoadingvariant:false,
            scrollableProduct:false,
            isLoadingCustomer: false,
            isLoadingCompany: false,
            scrollableProductType: false,
            scrollableCustomer: false,
            scrollableCompany:false,
            select_customer: 'all',
            select_company: 'all',
            select_employee: 'all',
            // Request Json
            outlets:[],
            create: {
                promotion_for: {
                    dine_in:false,
                    deliveries:false,
                    online:false,
                    take_away:false
                },
                company_deliveries:[],
                company_online:[],
                all_outlet:false,
                outlet_id:[],
                promotion_type: '',
                name: '',
                code: '',
                description: '',
                validity: {
                    validity_start_date: '',
                    validity_end_date: '',
                    all_day: false,
                    validity_start_time: '',
                    validity_end_time: '',
                    repeat: '',
                    options: [],
                    repeat_end_date: ''
                },
                limits: {
                    customer: {
                        daily: {
                            value: '',
                            usage_count: ''
                        },
                        weekly: {
                            value: '',
                            usage_count: ''
                        },
                        monthly: {
                            value: '',
                            usage_count: ''
                        },
                        yearly: {
                            value: '',
                            usage_count: ''
                        }
                    },
                    outlet: {
                        daily: {
                            value: '',
                            usage_count: ''
                        },
                        weekly: {
                            value: '',
                            usage_count: ''
                        },
                        monthly: {
                            value: '',
                            usage_count: ''
                        },
                        yearly: {
                            value: '',
                            usage_count: ''
                        }
                    }
                },
                account_code: '',
                assign_promotions_to: {
                    company: false,
                    customers: true
                },
                customer_list: [],
                flat_details: {
                    flat_type: 'amount',
                    flat_value: '',
                    flat_max_quantity: '',
                    applicable_for: 'products',
                    id: [],
                    product_id:[
                        {
                            product_id:'',
                            variant_id:'',
                            variant:false
                        }
                    ],
                    max_limit_amount: '',
                },
                promotion_details: {
                    buy_details: {
                        buy_type: 'amount',
                        buy_value: '',
                        buy_max_quantity: '',
                        applicable_for: 'products',
                        id: [],
                        product_id:[
                            {
                                product_id:'',
                                variant_id:'',
                                variant:false
                            }
                        ],
                    },
                    get_details: {
                        get_type: 'amount',
                        get_value: '',
                        applicable_for: 'products',
                        id: [],
                        product_id:[
                            {
                                product_id:'',
                                variant_id:'',
                                variant:false
                            }
                        ],
                        max_limit_amount: ''
                    },
                },
            },
            save_promotion_api_call_inprogress: false
        }
    },
    components: {
        HalfCircleSpinner
    },
  methods: {
        handelCategory(){
            const result = [];
            const map = new Map();
            for (const item of this.categories) {
                if(!map.has(item.category_id)){
                    map.set(item.category_id, true);  
                    result.push({
                        category_id: item.category_id,
                        name:item.name
                    });
                }
            }
            this.categories = result
        },
        handelSubcategory(){
            const result = [];
            const map = new Map();
            for (const item of this.subCategories) {
                if(!map.has(item.sub_category_id)){
                    map.set(item.sub_category_id, true);  
                    result.push({
                        sub_category_id: item.sub_category_id,
                        sub_category_name:item.sub_category_name
                    });
                }
            }
            this.subCategories = result
        },
        handelProductType(){
            const result = [];
            const map = new Map();
            for (const item of this.productTypes) {
                if(!map.has(item.product_type_id)){
                    map.set(item.product_type_id, true);  
                    result.push({
                        product_type_id: item.product_type_id,
                        product_type_name:item.product_type_name
                    });
                }
            }
            this.productTypes = result
        },
        percentageCheck(){
            this.create.flat_details.flat_value = 0
        },
        checkPercentage(value){
            if(value > 100){
                this.create.flat_details.flat_value = 100
            }
        },
        getPercentage(){
            this.create.promotion_details.get_details.get_value = 0
        },
        checkGetPercentage(value){
            if(value > 100){
                this.create.promotion_details.get_details.get_value = 100
            }
        },
        addProduct(){
            this.create.flat_details.product_id.push(
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            )
            this.$forceUpdate();
        },
        addProductBuy(){
            this.create.promotion_details.buy_details.product_id.push(
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            )
            this.$forceUpdate();
        },
        addProductGet(){
            this.create.promotion_details.get_details.product_id.push(
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            )
            this.$forceUpdate();
        },
        removeAddProduct(i){
            this.create.flat_details.product_id.splice(i,1)
            this.$forceUpdate();
        },
        removeAddProductBuy(i){
            this.create.promotion_details.buy_details.product_id.splice(i,1)
            this.$forceUpdate();
        },
        removeAddProductGet(i){
            this.create.promotion_details.get_details.product_id.splice(i,1)
            this.$forceUpdate();
        },
        updateVariant(i,id){
            if(id == ''){

            }else{
                this.create.flat_details.product_id[i].variant_id = ''
                this.create.flat_details.product_id[i].variant = id.variant
                this.variants = []
            }
        },
        updateVariantBuy(i,id){
            if(id == ''){

            }else{
                this.create.promotion_details.buy_details.product_id[i].variant_id = ''
                this.create.promotion_details.buy_details.product_id[i].variant = id.variant
                this.variants = []
            }
        },
        updateVariantGet(i,id){
            if(id == ''){

            }else{
                this.create.promotion_details.get_details.product_id[i].variant_id = ''
                this.create.promotion_details.get_details.product_id[i].variant = id.variant
                this.variants = []
            }
        },
        async getVariant(id){
            // this.variants = []
            try {
                let params ={
                    skip:this.skip,
                    limit:this.limit,
                    search:this.search,
                    product_id:id.product_id
                }
               let res = await this.$http.post('products/get_variants_for_dropdown',params) 
               this.variants = res.data.products
            } catch (reason) {
                
            }
        },
        async searchVariant(id,search){
            // this.variants = []
            try {
                let params ={
                    skip:0,
                    limit:this.limit,
                    search:search,
                    product_id:id.product_id
                }
               let res = await this.$http.post('products/get_variants_for_dropdown',params) 
               this.variants = res.data.products
            } catch (reason) {
                
            }
        },
    limitText(count) {
        return `and ${count} Categories`
    },
    limitTextOutletType(count) {
        return `and ${count} Outlet Type`
    },
    limitTextDelivery(count){
        return `and ${count} Delivery Companies`
    },
    limitTextOnline(count){
        return `and ${count} Online Companies`
    },
    limitTextOrderType(count){
        return `and ${count} Order type`
    },
    limitTextSubcategory(count) {
        return `and ${count} Sub-Categories`
    },
    limitTextProductType(count) {
        return `and ${count} Product-Types`
    },
    limitTextProduct(count){
        return `and ${count} Products`
    },
    limitTextCustomer(count) {
        return `and ${count} Customer`
    },
    limitTextCompany(count) {
        return `and ${count} Company`
    },
    checkBuyDetails(){
        this.select_category = ''
        this.select_subcategory=''
        this.select_productType=''
        this.select_product=''
        this.select_buycategory=''
        this.select_buysubcategory=''
        this.select_buyproductType=''
        this.select_buyproduct=''
        this.create.promotion_details.buy_details.id = []
        this.create.promotion_details.buy_details.product_id= [
           {
                product_id:'',
                variant_id:'',
                variant:false
            } 
        ]
    },
    checkGetDetails(){
        this.select_category = ''
        this.select_subcategory=''
        this.select_productType=''
        this.select_product=''
        this.select_getcategory=''
        this.select_getsubcategory=''
        this.select_getproductType=''
        this.select_getproduct=''
        this.create.promotion_details.get_details.id = []
        this.create.promotion_details.get_details.product_id= [
           {
                product_id:'',
                variant_id:'',
                variant:false
            } 
        ]
    },
    clearAllData(){
        this.select_category = ''
        this.select_subcategory=''
        this.select_productType=''
        this.select_product=''
        this.select_buycategory=''
        this.select_buysubcategory=''
        this.select_buyproductType=''
        this.select_buyproduct=''
        this.select_getcategory=''
        this.select_getsubcategory=''
        this.select_getproductType=''
        this.select_getproduct=''
        this.showXandY=false
        this.create = {
            promotion_for: {
                dine_in:false,
                deliveries:false,
                online:false,
                take_away:false
            },
            company_deliveries:[],
            company_online:[],
            promotion_type: '',
            name: '',
            code: '',
            description: '',
            validity: {
                validity_start_date: '',
                validity_end_date: '',
                all_day: false,
                validity_start_time: '',
                validity_end_time: '',
                repeat: '',
                options: [],
                repeat_end_date: ''
            },
            limits: {
                customer: {
                    daily: {
                        value: '',
                        usage_count: ''
                    },
                    weekly: {
                        value: '',
                        usage_count: ''
                    },
                    monthly: {
                        value: '',
                        usage_count: ''
                    },
                    yearly: {
                        value: '',
                        usage_count: ''
                    }
                },
                outlet: {
                    daily: {
                        value: '',
                        usage_count: ''
                    },
                    weekly: {
                        value: '',
                        usage_count: ''
                    },
                    monthly: {
                        value: '',
                        usage_count: ''
                    },
                    yearly: {
                        value: '',
                        usage_count: ''
                    }
                }
            },
            account_code: '',
            assign_promotions_to: {
                company: false,
                customers: true
            },
            customer_list: [],
            flat_details: {
                flat_type: 'amount',
                flat_value: '',
                flat_max_quantity: '',
                applicable_for: 'products',
                id: [],
                product_id:[
                    {
                        product_id:'',
                        variant_id:'',
                        variant:false
                    }
                ],
                max_limit_amount: ''
            },
            promotion_details: {
                buy_details: {
                    buy_type: 'amount',
                    buy_value: '',
                    buy_max_quantity: '',
                    applicable_for: 'products',
                    id: [],
                    product_id:[
                        {
                            product_id:'',
                            variant_id:'',
                            variant:false
                        }
                    ],
                },
                get_details: {
                    get_type: 'amount',
                    get_value: '',
                    applicable_for: 'products',
                    id: [],
                    product_id:[
                        {
                            product_id:'',
                            variant_id:'',
                            variant:false
                        }
                    ],
                    max_limit_amount: ''
                },
            },
        }
    },
    /**
     * This method or function is used to search Cagegories
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async loadCategorySearch(search_key) {
        this.isLoading = true
        let response = await this.$http.post('/scm/category_filter', {
            search_key: search_key,
            skip: 0,
            limit: 10
        })
        this.categories = response.data.response
        this.count = this.temp_categories.length
        this.isLoading = false
        this.scrollableCategory = false
        this.handelCategory()
    },
    /**
     * This method or function is used to fetch Cagegories next 10 entries
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async reachedEndOfList(reached) {
        this.isVisible = reached
        if (reached) {
            if (this.scrollableCategory == false) {
                this.isLoading = true
                let response = await this.$http.post('/scm/category_filter', {
                    search_key: this.search_key,
                    skip: this.count,
                    limit: 10
                })
                this.temp_categories = response.data.response.length
                if (response.data.skip == this.count) {
                    if (this.temp_categories > 0) {
                        let tempFiles = response.data.response
                        tempFiles.forEach(el => {
                            this.categories.push(el)
                        })
                        this.count += response.data.response.length
                        this.isLoading = false
                    } else {
                        this.scrollableCategory = true
                        this.isLoading = false
                    }
                }
                this.isLoading = false
            }
        }
        this.handelCategory()
    },
    /**
     * This method or function is used to fetch Cagegories
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async getCategory() {
        let response = await this.$http.post('/scm/category_filter', {
            search_key: this.search_key,
            limit: 10,
            skip: 0
        })
        this.count = 0
        if (response.data.status_id == 1) {
            this.temp_categories = []
            this.categories = []
            this.isLoading = false
        }
        this.handelCategory()
    },
    removeCategory(option) {
        this.create.flat_details.id.forEach((category, index) => {
            if (
            this.create.flat_details.id[index].category_id == option.category_id
            ) {
            this.create.flat_details.id.splice(index, 1)
            }
        })
    },
    removebuyCategory(option) {
        this.create.promotion_details.buy_details.id.forEach(
            (category, index) => {
            if (
                this.create.promotion_details.buy_details.id[index].category_id ==
                option.category_id
            ) {
                this.create.promotion_details.buy_details.id.splice(index, 1)
            }
            }
        )
    },
    removegetCategory(option) {
        this.create.promotion_details.get_details.id.forEach(
            (category, index) => {
            if (
                this.create.promotion_details.get_details.id[index].category_id ==
                option.category_id
            ) {
                this.create.promotion_details.get_details.id.splice(index, 1)
            }
            }
        )
    },
    // Category End

    flatApplicable(){
        this.select_category = ''
        this.select_subcategory=''
        this.select_productType=''
        this.select_product=''
        this.select_buycategory=''
        this.select_buysubcategory=''
        this.select_buyproductType=''
        this.select_buyproduct=''
        this.select_getcategory=''
        this.select_getsubcategory=''
        this.select_getproductType=''
        this.select_getproduct=''
        this.create.flat_details.id = []
        this.create.flat_details.product_id = [
            {
                product_id:'',
                variant_id:'',
                variant:false
            }
        ]
    },
    checkEndDate(){
        this.create.validity.validity_end_date = ''
        this.create.validity.repeat_end_date = ''
    },
    checkRepeatEndDate(){
        this.create.validity.repeat_end_date = ''
    },
    /**
     * This method or function is used to search Sub Categories
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async loadSubCategorySearch(search_key) {
        this.isLoadingsubCategory = true
        let response = await this.$http.post('/scm/sub_category_filter', {
            search_key: search_key,
            skip: 0,
            limit: 10
        })
        this.subCategories = response.data.response
        this.count = this.temp_subCategories.length
        this.isLoadingsubCategory = false
        this.scrollableSubCategory = false
        this.handelSubcategory()
    },
    /**
     * This method or function is used to fetch Sub Categories next 10 entries
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async reachedEndOfListSubCategory(reached) {
        this.isVisible = reached
        if (reached) {
            if (this.scrollableSubCategory == false) {
                this.isLoadingsubCategory = true
                let response = await this.$http.post('/scm/sub_category_filter', {
                    search_key: this.search_key,
                    skip: this.count,
                    limit: 10
                })
                this.temp_subCategories = response.data.response.length
                if (response.data.skip == this.count) {
                    if (this.temp_subCategories > 0) {
                        let tempFiles = response.data.response
                        tempFiles.forEach(el => {
                            this.subCategories.push(el)
                        })
                        this.count += response.data.response.length
                        this.isLoadingsubCategory = false
                    } else {
                        this.scrollableSubCategory = true
                        this.isLoadingsubCategory = false
                    }
                }
                this.isLoadingsubCategory = false
            }
        }
        this.handelSubcategory()
    },
    /**
     * This method or function is used to fetch Sub Category
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async getSubCategory() {
        let response = await this.$http.post('/scm/sub_category_filter', {
            search_key: this.search_key,
            limit: 10,
            skip: 0
        })
        this.count = 0
        if (response.data.status_id == 1) {
            this.temp_subCategories = []
            this.subCategories = []
            this.isLoadingsubCategory = false
        }
        this.handelSubcategory()
    },
    
    // Remove Selected
    removeSubCategory(option) {
        this.create.flat_details.id.forEach((subcategory, index) => {
            if (
            this.create.flat_details.id[index].sub_category_id ==
            option.sub_category_id
            ) {
            this.create.flat_details.id.splice(index, 1)
            }
        })
    },
    removebuySubCategory(option) {
        this.create.promotion_details.buy_details.id.forEach(
            (subcategory, index) => {
            if (
                this.create.promotion_details.buy_details.id[index]
                .sub_category_id == option.sub_category_id
            ) {
                this.create.promotion_details.buy_details.id.splice(index, 1)
            }
            }
        )
    },
    removegetSubCategory(option) {
        this.create.promotion_details.get_details.id.forEach(
            (subcategory, index) => {
            if (
                this.create.promotion_details.get_details.id[index]
                .sub_category_id == option.sub_category_id
            ) {
                this.create.promotion_details.get_details.id.splice(index, 1)
            }
            }
        )
    },
    // Sub Category End


   /**
     * This method or function is used to search products
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async loadProductSearch(search){
        this.isLoadingproduct = true
        let response = await this.$http.post(global.POS_API_INVENTORY + '/products/get_products_for_dropdown', {
            search: search,
            skip: 0,
            limit: 10
        })
        this.products = response.data.products
        this.count = this.temp_productTypes.length
        this.isLoadingproduct = false
        this.scrollableProduct = false
    },
    /**
     * This method or function is used to fetch products next 10 entries
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async reachedEndOfListProduct(reached) {
        this.isVisible = reached
        if (reached) {
            if (this.scrollableProduct == false) {
            this.isLoadingproduct = true
            let response = await this.$http.post(global.POS_API_INVENTORY + '/products/get_products_for_dropdown', {
                search: this.search,
                skip: this.count,
                limit: 10
            })
            this.temp_products = response.data.products.length
            if (response.data.skip == this.count) {
                if (this.temp_products > 0) {
                    let tempFiles = response.data.products
                    tempFiles.forEach(el => {
                        this.products.push(el)
                    })
                    this.count += response.data.products.length
                    this.isLoadingproduct = false
                } else {
                    this.scrollableProduct = true
                    this.isLoadingproduct = false
                }
            }
                this.isLoadingproduct = false
            }
        }
    },

    /**
     * This method or function is used to search product types
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async loadProductTypeSearch(search_key) {
        this.isLoadingproductType = true
        let response = await this.$http.post('/scm/product_filter', {
            search_key: search_key,
            skip: 0,
            limit: 10
        })
        this.productTypes = response.data.response
        this.count = this.temp_productTypes.length
        this.isLoadingproductType = false
        this.scrollableProductType = false
        this.handelProductType()
    },
    /**
     * This method or function is used to fetch product types next 10 entries
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async reachedEndOfListProductType(reached) {
        this.isVisible = reached
        if (reached) {
            if (this.scrollableProductType == false) {
            this.isLoadingproductType = true
            let response = await this.$http.post('/scm/product_filter', {
                search_key: this.search_key,
                skip: this.count,
                limit: 10
            })
            this.temp_productTypes = response.data.response.length
            if (response.data.skip == this.count) {
                if (this.temp_productTypes > 0) {
                    let tempFiles = response.data.response
                    tempFiles.forEach(el => {
                        this.productTypes.push(el)
                    })
                    this.count += response.data.response.length
                    this.isLoadingproductType = false
                } else {
                    this.scrollableProductType = true
                    this.isLoadingproductType = false
                }
            }
            this.isLoadingproductType = false
            }
        }
        this.handelProductType()
    },
    /**
     * This method or function is used to fetch product types
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async getProductTypes() {
        let response = await this.$http.post('/scm/product_filter', {
            search_key: this.search_key,
            limit: 10,
            skip: 0
        })
        this.count = 0
        if (response.data.status_id == 1) {
            this.temp_productTypes = []
            this.productTypes = []
            this.isLoadingproductType = false
        }
        this.handelProductType()
    },

    // Remove Selected
    removeProductType(option) {
        this.create.flat_details.id.forEach((producttype, index) => {
            if (
            this.create.flat_details.id[index].product_type_id ==
            option.product_type_id
            ) {
            this.create.flat_details.id.splice(index, 1)
            }
        })
    },
    removeProduct(option) {
        this.create.flat_details.id.forEach((product, index) => {
            if (
            this.create.flat_details.id[index]._id ==
            option._id
            ) {
            this.create.flat_details.id.splice(index, 1)
            }
        })
    },
    removebuyProduct(option) {
        this.create.promotion_details.buy_details.id.forEach(
            (producttype, index) => {
            if (
                this.create.promotion_details.buy_details.id[index]
                ._id == option._id
            ) {
                this.create.promotion_details.buy_details.id.splice(index, 1)
            }
            }
        )
    },
    removegetProduct(option) {
        this.create.promotion_details.get_details.id.forEach(
            (producttype, index) => {
            if (
                this.create.promotion_details.get_details.id[index]
                ._id == option._id
            ) {
                this.create.promotion_details.get_details.id.splice(index, 1)
            }
            }
        )
    },
    removebuyProductType(option) {
        this.create.promotion_details.buy_details.id.forEach(
            (producttype, index) => {
            if (
                this.create.promotion_details.buy_details.id[index]
                .product_type_id == option.product_type_id
            ) {
                this.create.promotion_details.buy_details.id.splice(index, 1)
            }
            }
        )
    },
    removegetProductType(option) {
      this.create.promotion_details.get_details.id.forEach(
        (producttype, index) => {
          if (
            this.create.promotion_details.get_details.id[index]
              .product_type_id == option.product_type_id
          ) {
            this.create.promotion_details.get_details.id.splice(index, 1)
          }
        }
      )
    },
    // ProductType End

    // Customer Start
    removecustomer(option) {
      this.create.customer_list.forEach((customer, index) => {
        if (
          this.create.customer_list[index]._id ==
          option._id
        ) {
          this.create.customer_list.splice(index, 1)
        }
      })
    },
    /**
     * This method or function is used to search customers
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async loadCustomerSearch(search_key) {
        this.isLoadingCustomer = true
        let response = await this.$http.post('/mobile/orders/get_customers', {
            search_key: search_key,
            skip: 0,
            limit: 10
        })
        this.customers = response.data.response.customers
        this.count = this.temp_customers.length
        this.isLoadingCustomer = false
        this.scrollableCustomer = false
    },
    /**
     * This method or function is used to fetch customers next 10 entries
     * @param {search_key} - search_key is the String
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async reachedEndOfListCustomer(reached) {
      this.isVisible = reached
      if (reached) {
        if (this.scrollableCustomer == false) {
          this.isLoadingCustomer = true
          let response = await this.$http.post('/mobile/orders/get_customers', {
            search_key: this.search_key,
            skip: this.count,
            limit: 10
          })
          this.temp_customers = response.data.response.customers.length
          if (response.data.response.payload.skip == this.count) {
            if (this.temp_customers > 0) {
              let tempFiles = response.data.response.customers
              tempFiles.forEach(el => {
                this.customers.push(el)
              })
              this.count += response.data.response.customers.length
              this.isLoadingCustomer = false
            } else {
              this.scrollableCustomer = true
              this.isLoadingCustomer = false
            }
          }
          this.isLoadingCustomer = false
        }
      }
    },
    // Customer End

    cancel() {
      this.$modal.hide(this.modal_name)
      this.clearAllData()
    },
    /**
     * This method or function is used to convert date
     */
    convertDate(str) {
        var month, day, year, hours, minutes, seconds;
        var date = new Date(str);
        month = ("0" + (date.getMonth() + 1)).slice(-2);
        day = ("0" + date.getDate()).slice(-2);
        hours = ("0" + date.getHours()).slice(-2);
        minutes = ("0" + date.getMinutes()).slice(-2);
        seconds = ("0" + date.getSeconds()).slice(-2);

		var mySQLDate = [date.getFullYear(),month,day].join("-");
        return [mySQLDate].join(" ");
    },
    /**
     * This method or function is used to convert time
     */
    convertTime(str) {
        var month, day, year, hours, minutes, seconds;
        var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
        hours = ("0" + date.getHours()).slice(-2);
        minutes = ("0" + date.getMinutes()).slice(-2);
        seconds = ("0" + date.getSeconds()).slice(-2);
        var mySQLTime = [hours, minutes,seconds].join(":");
        return [mySQLTime].join(" ");
    },
    /**
     * This method or function is used to save the promotion
     */
    savePromotions() {

        if (this.create.promotion_type == 'Buy X Get Y'){
            if(this.create.flat_details.flat_value == ''){
                this.create.flat_details.flat_value = 0
            }
            if(this.create.flat_details.flat_max_quantity == ''){
                this.create.flat_details.flat_max_quantity = 0
            }
        }
        if(this.create.promotion_details.get_details.get_value == ''){
            this.create.promotion_details.get_details.get_value = 0
        }
        if(!this.create.promotion_for.deliveries && !this.create.promotion_for.dine_in && !this.create.promotion_for.online && !this.create.promotion_for.take_away){
            this.promotionRequired = true
        }else {
            this.promotionRequired = false
        }
        this.$validator.validateAll().then(result => {
            if (result && !this.promotionRequired) {
                this.checkNameExists()
            }
        })
    },
    /**
     * This method or function is used to check if the promo code exists or not
     * then call the save method
     * else show the message
     */
    async checkNameExists(){
        let params = {
            name:this.create.name,
            edit:false,
            code:this.create.code
        }
        let res = await this.$http.post('/promotions/check_if_promotion_name_exist',params)
        if(res.data.status_id == 1){
            this.$swal({
                title: 'Promotion Name or Code Already Exists',
                text: '',
                type: 'warning'
            })
        }else{
            this.confirmSavePromotions(this.create)
        }
    },
    /**
     * This method or function is used to save the promotion details
     */
    async confirmSavePromotions(params) {
        try {
            this.save_promotion_api_call_inprogress = true;
            if(params.promotion_type == 'Flat Discount'){
                if(params.flat_details.applicable_for == 'products'){
                    const result = [];
                    const map = new Map();
                    for (const item of params.flat_details.product_id) {
                        if(!map.has(item.product_id.product_id)){
                            map.set(item.product_id.product_id && item.variant_id.variant_id, true);  
                            result.push({
                                product_id: item.product_id.product_id,
                                variant_id:item.variant_id.variant_id || '',
                                variant:item.product_id.variant
                            });
                        }
                    }
                    params.flat_details.product_id = result
                }
            }else if (params.promotion_type == 'Buy X Get Y') {
                if(params.promotion_details.get_details.applicable_for == 'products'){
                    const result = [];
                    const map = new Map();
                    for (const item of params.promotion_details.get_details.product_id) {
                        if(!map.has(item.product_id.product_id)){
                            map.set(item.product_id.product_id && item.variant_id.variant_id , true);  
                            result.push({
                                product_id: item.product_id.product_id,
                                variant_id:item.variant_id.variant_id || '',
                                variant:item.product_id.variant
                            });
                        }
                    }
                    params.promotion_details.get_details.product_id = result
                }
                if(params.promotion_details.buy_details.applicable_for == 'products'){
                    const result = [];
                    const map = new Map();
                    for (const item of params.promotion_details.buy_details.product_id) {
                        if(!map.has(item.product_id.product_id)){
                            map.set(item.product_id.product_id && item.variant_id.variant_id , true);  
                            result.push({
                                product_id: item.product_id.product_id,
                                variant_id:item.variant_id.variant_id || '',
                                variant:item.product_id.variant
                            });
                        }
                    }
                    params.promotion_details.buy_details.product_id = result
                }
            }
            if(params.validity.validity_start_date != ''){
                params.validity.validity_start_date =  this.convertDate(params.validity.validity_start_date)
            }
            if(params.validity.validity_end_date != ''){
                params.validity.validity_end_date = this.convertDate(params.validity.validity_end_date)
            }
            if(params.validity.validity_start_time != ''){
                params.validity.validity_start_time =  this.convertTime(params.validity.validity_start_time)
            }
            if(params.validity.validity_end_time != ''){
                params.validity.validity_end_time =  this.convertTime(params.validity.validity_end_time)
            }
            if(params.validity.repeat_end_date != ''){
                params.validity.repeat_end_date =  this.convertDate(params.validity.repeat_end_date)
            }
            if(params.limits.customer.daily.value == ''){
                params.limits.customer.daily.value = 0
            }
            if(params.limits.customer.weekly.value == ''){
                params.limits.customer.weekly.value = 0
            }
            if(params.limits.customer.monthly.value == ''){
                params.limits.customer.monthly.value = 0
            }
            if(params.limits.customer.yearly.value == ''){
                params.limits.customer.yearly.value = 0
            }
            if(params.limits.customer.daily.usage_count == ''){
                params.limits.customer.daily.usage_count = 0
            }
            if(params.limits.customer.weekly.usage_count == ''){
                params.limits.customer.weekly.usage_count = 0
            }
            if(params.limits.customer.monthly.usage_count == ''){
                params.limits.customer.monthly.usage_count = 0
            }
            if(params.limits.customer.yearly.usage_count == ''){
                params.limits.customer.yearly.usage_count = 0
            }
            if(params.promotion_type == 'Flat Discount' ){
                const result = [];
                const map = new Map();
                for (const item of params.flat_details.id) {
                    if(params.flat_details.applicable_for == 'category'){
                        if(!map.has(item.category_id)){
                            map.set(item.category_id, true);
                            result.push({
                                id: item.category_id
                            });
                        }
                    }else if(params.flat_details.applicable_for == 'sub_category'){
                        if(!map.has(item.sub_category_id)){
                            map.set(item.sub_category_id, true);
                            result.push({
                                id: item.sub_category_id
                            });
                        }
                    }
                    else if(params.flat_details.applicable_for == 'product_type'){
                        if(!map.has(item.product_type_id)){
                            map.set(item.product_type_id, true);
                            result.push({
                                id: item.product_type_id
                            });
                        }
                    }
                }
                params.flat_details.id = result
            }else if(params.promotion_type == 'Buy X Get Y'){
                const result = [];
                const result1 = [];
                const map = new Map();
                for (const item of params.promotion_details.get_details.id) {
                    if(params.promotion_details.get_details.applicable_for == 'category'){
                        if(!map.has(item.category_id)){
                            map.set(item.category_id, true);
                            result.push({
                                id: item.category_id
                            });
                        }
                    }else if(params.promotion_details.get_details.applicable_for == 'sub_category'){
                        if(!map.has(item.sub_category_id)){
                            map.set(item.sub_category_id, true);
                            result.push({
                                id: item.sub_category_id
                            });
                        }
                    }
                    else if(params.promotion_details.get_details.applicable_for == 'product_type'){
                        if(!map.has(item.product_type_id)){
                            map.set(item.product_type_id, true);
                            result.push({
                                id: item.product_type_id
                            });
                        }
                    }
                }
                params.promotion_details.get_details.id = result
                for (const item of params.promotion_details.buy_details.id) {
                    if(params.promotion_details.buy_details.applicable_for == 'category'){
                        if(!map.has(item.category_id)){
                            map.set(item.category_id, true);
                            result1.push({
                                id: item.category_id
                            });
                        }
                    }else if(params.promotion_details.buy_details.applicable_for == 'sub_category'){
                        if(!map.has(item.sub_category_id)){
                            map.set(item.sub_category_id, true);
                            result1.push({
                                id: item.sub_category_id
                            });
                        }
                    }
                    else if(params.promotion_details.buy_details.applicable_for == 'product_type'){
                        if(!map.has(item.product_type_id)){
                            map.set(item.product_type_id, true);
                            result1.push({
                                id: item.product_type_id
                            });
                        }
                    }
                }
                params.promotion_details.buy_details.id = result1
            }
            if(params.promotion_type == 'Flat Discount'){
                if(params.flat_details.flat_type == 'amount'){
                    delete params.flat_details.max_limit_amount
                    // delete params.flat_details.min_limit_amount
                }
            }
            if(params.promotion_type == 'Buy X Get Y'){
                // if(params.promotion_details.buy_details.buy_type != 'amount'){
                //     delete params.promotion_details.buy_details.buy_value
                // }
                // if(params.promotion_details.buy_details.buy_type != 'quantity'){
                //     delete params.promotion_details.buy_details.buy_max_quantity
                // }
                if(params.promotion_details.get_details.get_type == 'amount'){
                    // delete params.promotion_details.get_details.min_limit_amount
                    delete params.promotion_details.get_details.max_limit_amount
                    // delete params.promotion_details.get_details.get_max_quantity
                }
                if(params.promotion_details.get_details.get_type == 'quantity'){
                    // delete params.promotion_details.get_details.min_limit_amount
                    delete params.promotion_details.get_details.max_limit_amount
                    // delete params.promotion_details.get_details.get_value
                }
                if(params.promotion_details.get_details.get_type == 'percentage'){
                    // delete params.promotion_details.get_details.get_max_quantity
                }
            }  
            if(params.promotion_type == 'Flat Discount' ){
                if(params.flat_details.applicable_for == 'products'){
                    delete params.flat_details.id
                } else{
                    delete params.flat_details.product_id
                }
            }else if(params.promotion_type == 'Buy X Get Y'){
                if(params.promotion_details.get_details.applicable_for == 'products'){
                    delete params.promotion_details.get_details.id
                } else{
                    delete params.promotion_details.get_details.product_id
                }
                if(params.promotion_details.buy_details.applicable_for == 'products'){
                    delete params.promotion_details.buy_details.id
                } else{
                    delete params.promotion_details.buy_details.product_id
                }
            }
            if (params.promotion_type == 'Flat Discount') {
                delete params.promotion_details
            } else if (params.promotion_type == 'Buy X Get Y') {
                delete params.flat_details
            }
            if(params.validity.repeat == 'never' || this.repeat_on_type == 'Never'){
                    params.validity.repeat_end_date = ''
                }
            params.code = params.code.toUpperCase();
            if(this.select_category){
                params.flat_details.id = 'all'
            }
            if(this.select_subcategory){
                params.flat_details.id = 'all'
            }
            if(this.select_productType){
                params.flat_details.id = 'all'
            } 
            if(this.select_product){
                params.flat_details.product_id = 'all'
            }
            if(this.select_buycategory){
                params.promotion_details.buy_details.id = 'all'
            }
            if(this.select_buysubcategory){
                params.promotion_details.buy_details.id = 'all'
            }
            if(this.select_buyproductType){
                params.promotion_details.buy_details.id = 'all'
            }
            if(this.select_buyproduct){
                params.promotion_details.buy_details.product_id = 'all'
            }
            if(this.select_getcategory){
                params.promotion_details.get_details.id = 'all'
            }
            if(this.select_getsubcategory){
                params.promotion_details.get_details.id = 'all'
            }
            if(this.select_getproductType){
                params.promotion_details.get_details.id = 'all'
            }
            if(this.select_getproduct){
                params.promotion_details.get_details.product_id = 'all'
            }
            params.company_deliveries = [...new Set(params.company_deliveries.map(x =>x._id))] 
            params.company_online = [...new Set(params.company_online.map(x =>x._id))] 
            params.outlet_id = [...new Set(params.outlet_id.map(x =>x._id))] 
            params.customer_list = [...new Set(params.customer_list.map(x =>x._id))] 
            let res = await this.storePromotions(params)
            this.$swal({
                title: 'Promotion Created Successfully',
                text: '',
                type: 'success'
            })
            this.$emit('saved')
            this.clearAllData()
            this.$modal.hide('promotion-create')
            this.save_promotion_api_call_inprogress = false;
        } catch (reason) {
            this.$swal({
                title: reason,
                text: '',
                type: 'warning'
            })
            this.save_promotion_api_call_inprogress = false;
        }
    },
    isNumber: function(evt, index) {
        evt = evt ? evt : window.event
        var charCode = evt.which ? evt.which : evt.keyCode
        if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46
        ) {
            evt.preventDefault()
        } else {
            return true
        }
    },
    selectCustomer(){
        if(this.select_customer == 'all'){
            this.customers = []
            this.count = 0
            this.create.customer_list = []
        }  
    },
    selectAll(){
        if(this.select_category){
            this.create.flat_details.id = []
        }else if(this.select_subcategory){
            this.create.flat_details.id = []
        }else if(this.select_productType){
            this.create.flat_details.id = []
        }else if(this.select_product){
            this.create.flat_details.product_id = [
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            ]
        }else if(this.select_buycategory){
            this.create.promotion_details.buy_details.id = []
        }else if(this.select_buysubcategory){
            this.create.promotion_details.buy_details.id = []
        }else if(this.select_buyproductType){
            this.create.promotion_details.buy_details.id = []
        }else if(this.select_buyproduct){
            this.create.promotion_details.buy_details.product_id = [
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            ] 
        }else if(this.select_getcategory){
            this.create.promotion_details.get_details.id = []
        }else if(this.select_getsubcategory){
            this.create.promotion_details.get_details.id = []
        }else if(this.select_getproductType){
            this.create.promotion_details.get_details.id = []
        }else if(this.select_getproduct){
            this.create.promotion_details.get_details.product_id = [
                {
                    product_id:'',
                    variant_id:'',
                    variant:false
                }
            ] 
        }
    },
    /**
     * This method or function is used to fetch order types
     * @param {skip} - skip is the Integer
     * @param {limit} - limit is the Integer
     * @returns {response} - gets the Result
     */
    async getAllOrderType(){
        try {
            let params = {
                skip:0,
                limit:100
            }
            let res = await this.$http.post('/order_type/get_all_order_types',params)
            this.allOrderType = res.data.response.order_types
        } catch (reason) {
            
        }
    },
    clearTaxAddValues(key){
        if(key == 'Deliveries'){
            this.create.company_deliveries = []
        }else if(key == 'Online'){
            this.create.company_online = []
        }
    },
    getDeliveryData(key){
        this.allOrderType.forEach((el,index)=>{
            if(el.order_type == key){
                this.apigetDeliveryCo(el._id)
            }
        })
    },
    removeOnline(option){
        this.create.company_online.forEach((el,index)=>{
            if(el._id == option._id){
                this.create.company_online.splice(index,1)
            }
        })
    },
    removeDelivery(option){
        this.create.company_deliveries.forEach((el,index)=>{
            if(el._id == option._id){
                this.create.company_deliveries.splice(index,1)
            }
        })
    },
    /**
     * This method or function is used to fetch Delivery Companies
     * @param {order_type} - order_type is the String
     * @param {search_key} - search_key is the String
     * @param {required_id} - required_id is the Integer
     * @returns {response} - gets the Result
     */
    async apigetDeliveryCo(type){
        try {
            let params = {
                order_type:type,
                search_key:'',
                required_id:true
            }
            this.delivery_company_list = []
            let res = await this.getDeliveryByOrder(params)
            this.delivery_company_list = res.response.delivery_companies
        } catch (reason) {
            
        }
    },
    removeOutlet(){
        this.create.outlet_id.forEach((el,index)=>{
           if(el._id == option._id){
               this.create.outlet_id.splice(index,1)
           }
        }) 
    },
    /**
     * This method or function is used to fetch Outlets List
     * @param {search_key} - search_key is the String
     * @returns {response} - gets the Result
     */
    async getOutlets(){
        let res = await this.$http.post('/outlet/get_all_outlets',{search_key:this.search_key})
        this.outlets = res.data.outlets
    },
    /**
     * This method or function is used to search Outlets
     * @param {search_key} - search_key is the String
     * @returns {response} - gets the Result
     */
    async searchOutlets(search_key){
        let res = await this.$http.post('/outlet/get_all_outlets',{search_key:search_key})
        this.outlets = res.data.outlets
    },
  },
  created() {
    const dict = {
      custom: {
        promotionname: {
          required: () => 'Promotion Name is Required',
          alpha_num: () => 'Enter a Valid Promotion Name'
        },
        promotioncode: {
          required: () => 'Promotion Code is Required',
          alpha_num: () => 'Enter a Valid Promotion Code'
        },
        accountcode: {
          alpha_num: () => 'Enter a Valid Account Code'
        }
      }
    }
    this.$validator.localize('en', dict)
  },
    watch:{
        'create.promotion_type'(newValue,oldValue){
            if(newValue){
                this.$validator.reset();
                this.create.promotion_type = newValue
                if(this.create.promotion_type == 'Buy X Get Y'){
                    this.showXandY = true
                }else{
                    this.showXandY = false
                }
            }
        }
    },
    mounted(){
        this.getAllOrderType()
    }
}
</script>
<style  scoped>
.final-modal-loyalty .v-modal-header {
  background: #bccce0 !important;
}
.final-modal-loyalty .v-modal-body {
  background: #eff1f4 !important;
}
.gsection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.dsection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.rsection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.csection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.cosection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.accsection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.usection {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.promotions-crate .inputContact:valid~label {
    color: #303031!important;
}
.promotions-crate .text-secondary{
    color:#303031!important;
}
.pointerEventsNone{
    pointer-events: none !important;
}
.category-multiple-addon .custom__tag {
    background: #00448b;
    color: #fff;
}
.category-multiple-addon .multiselect__strong {
    line-height: 26px!important;
}

.general-section .gsection sup {
    color: #F44336 !important;
    top: -0.2em !important;
    font-size: 13px !important;
    padding-left: 1.2px;
}
sup {
    color: #F44336 !important;
    top: -0.2em !important;
    font-size: 13px !important;
    padding-left: 1.2px;
}
</style>