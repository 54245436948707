import Axios from "axios"
import global from "../global"

export default {
    data() {
        return {
            cashRegisters: [],
            cashiers: [],
            cashiersList: [],
            non_cashiers_ist: [],
            non_cashier_list_data: [],
        }
    },
    methods: {
        getAllCashRegisters() {
            this.ajaxCallInProgress = true
            this.$http.post("/outlet/get_all_cash_registers", {
                outlet_id: this.$route.query.outletId
            }).then(res => {
                this.ajaxCallInProgress = false
                if (res.data.status_id == 1) {
                    if (res.data.response.hasOwnProperty('cash_registers')) {
                        this.cashRegisters = res.data.response.cash_registers;
                    }
                    if (res.data.response.hasOwnProperty('cashier_ids')) {
                        this.cashiers = res.data.response.cashier_ids;
                        localStorage.setItem('cashier_ids', JSON.stringify(this.cashiers));
                    }
                    if (res.data.response.hasOwnProperty('non_cashier_list')) {
                        this.non_cashier_list_data = res.data.response.non_cashier_list;
                        this.non_cashier_list_data.forEach((non_cashier, index) => {
                            non_cashier.user_ids.forEach((user_id, index) => {
                                user_id['full_name'] = user_id.name;
                                delete user_id.name;
                            })
                        })
                        localStorage.setItem('non_cashier_list_data', JSON.stringify(this.non_cashier_list_data));
                    }
                }
            }).catch((err)=>{
                // console.log()
            })
        },
        deleteCashRegister(uuid) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then(result => {
                if (result.value) {
                    this.$http.post("/outlet/delete_cash_register", {
                            outlet_id: this.$route.query.outletId,
                            register_uuid: uuid
                        })
                        .then(res => {
                            if (res.data.status_id == 1) {
                                this.modal_msg = "Cash Register Deleted Successfully"
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                }, 2000);
                                this.getAllCashRegisters();
                            }
                        }).catch((err)=>{
                            // console.log()
                        })
                }
            })
        },
        getEmployeesForCashier(param, loading) {
            this.edit_assign_cashier = true
            var data = {
                search_key: param
            }
            this.$http.post("/employee/get_employees_cashier", data).then(res => {
                if (res.data.status_id == 1) {
                    this.cashiersList = res.data.employees;
                }
            })
        },
        getEmployeesForNonCashier(search_key) {
            this.edit_assign_non_cashier = true
            var data = {
                outlet_id: this.$route.query.outletId,
                for_cashier: true,
                limit: 10,
                search_key: search_key || '',
                skip: 0,
                sort: {
                    full_name: 1
                }
            }
            Axios.post(global.POS_API+"/employee/get_employees", data).then(res => {
                if (res.data.status_id == 1) {
                    this.non_cashiers_ist = res.data.employees;
                }
            }).catch((error) => {
                if(error.response.status == 404 || error.response.status == 500){
                    this.modal_msg = 'Something went wrong. Please try again later.'
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.$router.push('/outletmanage')
                    }, 2000);
                }
            })
        },
        updateCashiers() {
            this.$http.post("/outlet/update_cashiers", {
                outlet_id: this.$route.query.outletId,
                cashier_ids: this.cashiers
            }).then(res => {
                if (res.data.status_id == 1) {
                    this.modal_msg = "Cashiers Updated Successfully"
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.getAllCashRegisters();
                        localStorage.removeItem('cashier_ids');
                    }, 2000);
                }

            }).catch((err)=>{
                // console.log()
            })
        },
        updateNonCashiers(register_id, user_ids) {
            let user_ids_array = JSON.parse(JSON.stringify(user_ids));
            try {
                let params = {
                    outlet_id: this.$route.query.outletId,
                    non_cashier_data: {
                        register_id: register_id,
                        user_ids: user_ids_array
                    }
                }
                params.non_cashier_data.user_ids.forEach((user_id, index) => {
                    params.non_cashier_data.user_ids[index] = user_id.user_id ? user_id.user_id : user_id._id ;
                });
                this.$http.post("/outlet/update_cashiers", params).then(res => {
                    if (res.data.status_id == 1) {
                        this.modal_msg = "Cashiers Updated Successfully"
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getAllCashRegisters();
                            // localStorage.removeItem('no_cashier_edit_user_ids');
                            localStorage.removeItem('non_cashier_list_data');
                        }, 2000);
                    }
                })
            }
            catch (e) {
                this.modal_msg = e
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    // localStorage.removeItem('no_cashier_edit_user_ids');
                    localStorage.removeItem('non_cashier_list_data');
                }, 2000);
            }
        },
    },
    mounted() {
        const dict = {
            custom: {
                server_url: {
                    required: () => "Server IP Address is Required",
                    ip: () => "Enter Valid IP Address"
                },
                cash_register_id: {
                    required: () => "Cash Register ID is Required",
                    alpha_dash: () => "Enter a Valid Cash Register ID"
                },
            }
        };
        this.$validator.localize("en", dict);
    }
}
