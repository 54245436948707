<template>
    <div class="page-layout" id="takeorderdiv">
        <div v-if="isLoading" class="d-flex align-items-center justify-content-center">
            <div class="digi-table-loader inverse">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div style="background-color:#f5f6f9;height:96%" v-else-if="!isLoading && orders.length <= 0">
            <div class="text-auto pt-1 h-100" style="right: 15px;left: 15px;">
                <div class="h-100">
                    <div class="row pt-20">
                        <img src="https://cdn.digicollect.com/cdn/posv2/pending_order.svg" class="pr-2 img-style">
                    </div>
                    <div class="text-style text-center mb-3">No Orders Yet !!!</div>
                </div>
            </div>
        </div>
        <div style= "background-color:#f5f6f9" v-else>
            <div class="text-auto pt-1" style="right: 15px;left: 15px;">
                <div class="d-flex flex-column justify-content-between">
                    <div class="row pt-5 pb-2">
                        <div class="col-md-6 col-lg-6 col-sm-12 mb-5" v-for="(order, index) in orders" :key="index">
                            <div class="order-card p-5">
                                <div class="row d-flex flex-nowrap py-2">
                                    <div class="col-md-6 pl-3">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/table-icon.svg" class="ml-1 pl-3 icon-style">
                                        <span class="pl-3 text-color">{{order.table_id}}</span>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <span class="bg-grey text-white p-1 text-border">{{order.creation_date}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 pl-3 py-2">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="span-add-customer text-black">Order Start Time {{order.order_start_time}}</span>
                                        <span class="span-add-customer text-black fw-500">
                                            <img src="/static/images/payment_status_icon.svg" />
                                            Payment Status: <span style="color: #f5a639;">{{order.payment_status}}</span></span>
                                    </div>
                                </div> -->
                                <div class="d-flex flex-nowrap py-2 pt-3">
                                    <div class="col-md-6 pl-3">
                                        <span class="span-add-customer text-black">Order Start Time {{order.order_start_time}}</span>
                                    </div>
                                    <div class="col-md-6 pl-3" >
                                        <span class="span-add-customer text-black fw-500">
                                            <img src="/static/images/payment_status_icon.svg" />
                                            Payment Status: <span style="color: #f5a639;">{{order.payment_status}}</span></span>
                                    </div>
                                </div>
                                <div class="d-flex flex-nowrap py-2 pt-3" >
                                    <div class="col-md-6 pl-3">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" class="pr-2 icon-style">
                                        <span class="span-add-customer text-black">{{order.guest_name}}</span>
                                    </div>
                                    <div class="col-md-6 pl-3" >
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/orders-grey.svg" class="pr-2 icon-style">
                                        <span class="span-add-customer">Order Value:</span>
                                        <span class="span-add-customer">{{order.order_value}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 pl-3 py-2" >
                                    <span class="text-black fw-500">Confirmation Pending</span>
                                </div>
                                <div class="row justify-content-between no-gutters align-items-center  py-2 ">
                                    <button class="btn btn-style col-md-3 col-lg-3 col-sm-3 mr-3 btn-secondary  " @click="showItemSummaryModal(order._id)">View Order</button>
                                    <button class="btn btn-style col-md-3 col-lg-3 col-sm-3 ml-3 btn-danger " @click="rejectOrderList(order._id,order.is_update_order)">Reject </button>
                                    <button class="btn  btn-style col-md-3 col-lg-3 col-sm-3 ml-3 btn-success" @click="acceptOrderList(order._id,order.is_update_order)" >Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pending-item-summary  @hideItemsSummary="hideItemsSummary" @showItemSummaryModal="showItemSummaryModal" :orderId="this.order_id" v-if="showItemSummary"  modal_name="pending-item-summary"></pending-item-summary>
        <reject-success-modal  @hideDeleteModal="hideDeleteModal" @showDeleteConfirmModal="showDeleteConfirmModal" v-if="showDeleteModal" modal_name="reject-success-modal" @getPendingOrderList="getPendingOrderList()"></reject-success-modal>
        <accept-confirm-modal  @hideAcceptModal="hideAcceptModal" @showAcceptConfirmModal="showAcceptConfirmModal" v-if="showAcceptModal" modal_name="accept-confirm-modal"></accept-confirm-modal>
        <reject-confirm-modal  @hideConfirmModal="hideConfirmModal" :confirm_order_id="confirm_order_id" :confirm_status="confirm_status" @showDeleteSuccessModal="showDeleteSuccessModal" v-if="show_confirm_modal" modal_name="reject-confirm-modal"></reject-confirm-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
const PendingItemSummary = () => import('./PendingItemSummary')
const RejectSuccessModal = () => import('./RejectSuccessModal')
const RejectConfirmModal = () => import('./RejectConfirmModal')
const AcceptConfirmModal = () => import('./AcceptConfirmModal')
import orderService from './mixins/orders'
import printers from './mixins/printers';
export default {
    mixins: [orderService, printers],
    data() {
        return {
            showItemSummary: false,
            showDeleteModal: false,
            showAcceptModal: false,
            isLoading : false,
            skip : 0,
            limit: 10,
            search_key:'',
            orders: [],
            order_id: '',
            placing_order_in_progress: false,
            show_confirm_modal: false,
            confirm_order_id: '',
            confirm_status: false
        }
    },
    components: {
        PendingItemSummary,
        SweetModal,
        RejectSuccessModal,
        RejectConfirmModal,
        AcceptConfirmModal
    },
    methods: {
        hideItemsSummary(){
            this.showItemSummary = false;
        },
        hideDeleteModal() {
            this.showDeleteModal = false;
            setTimeout(() => {
                this.$modal.show('reject-success-modal')
            }, 500);
        },
        hideAcceptModal() {
            this.showAcceptModal = false;
        },
        showDeleteConfirmModal() {
            this.showDeleteModal = true;
            setTimeout(() => {
                this.$modal.show('reject-success-modal')
            }, 500);
        },
        showAcceptConfirmModal() {
            this.showAcceptModal = true;
            setTimeout(() => {
                this.$modal.show('accept-confirm-modal')
            }, 500);
        },
        showItemSummaryModal(id) {
            this.order_id = id
            this.showItemSummary = true;
            setTimeout(() => {
                this.$modal.show('pending-item-summary')
            }, 500);
        },
        /**
        * This method or function is used to fetch the pending order's list
        * @param {search_key} - search_key is the String
        * @param {skip} - skip is the Integer
        * @param {limit} - limit is the Integer
        * @returns {response} - Get the result
        */
        async getPendingOrderList() {
            this.orders = {}
            this.isLoading = true;
            try {
                let params = {
                    search_key: this.search_key,
                    skip: this.skip,
                    limit: this.limit
                }
                let res= await this.getPendingOrder(params)
                this.orders = res.orders_list
                this.orders.forEach((order, index) => {
                    order.creation_date = order.creation_date.split("/")[1] + "/" + order.creation_date.split("/")[0] + "/" + order.creation_date.split("/")[2]
                });
                this.isLoading = false;
            }
            catch (reason) {
                this.isLoading = false;
            }
        },
        rejectOrderList(order_id, status) {
            this.confirm_order_id = order_id;
            this.confirm_status = status
            this.show_confirm_modal = true;
            setTimeout(() => {
                this.$modal.show('reject-confirm-modal')
            }, 500);
        },
        hideConfirmModal() {
            this.show_confirm_modal = false;
            setTimeout(() => {
                this.$modal.hide('reject-confirm-modal')
            }, 500);
        },
        showDeleteSuccessModal() {
            this.hideConfirmModal();
            setTimeout(() => {
                this.getPendingOrderList();
                this.showDeleteConfirmModal();
            }, 500);
        },
        /**
        * This method or function is used to print the ticket/receipt
        * @param {ticket_data} - ticket_data is the Array
        * @returns {response} - Get the result
        */
        movePage(ticket_data) {
            // this.$toasted.global.success(this.responseMessage);
            if(this.loggedInUser.print_receipt_by_default) {
                this.printTicket(ticket_data)
                this.$emit("close", true);
                this.placing_order_in_progress = false
            } else {
                Swal({
                    title: 'Print Tickets',
                    text: 'Do You Want to Print Ticket ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.printTicket(ticket_data)
                        this.$emit("close", true);
                        this.placing_order_in_progress = false
                    } else {
                        this.$emit("close", true);
                        this.placing_order_in_progress = false
                    }
                })
            }
        },
        /**
        * This method or function is used to accept the order
        * @param {id} - id is the String
        * @param {status} - status is the String
        * @returns {response} - Get the result
        */
        async acceptOrderList(id,status) {
            let update_status = status;
            try {
                let params = {
                    order_id: id
                }
                let params1 = {
                    orderId: id
                }
                if(update_status == false) {
                    let res= await this.orderMobilePlace(params)
                    console.log(res)
                    let status = res.status_id
                    if(status == 1) {
                        this.showAcceptConfirmModal()
                        setTimeout(() => {
                            this.getPendingOrderList()
                        }, 500);

                        if(res.response.ticket_details.length > 0){
                            if(res.response.ticket_details.length == 1){
                                res.response.ticket_details.forEach(z => {
                                    // if(x.category_id == z.category_id){
                                        this.store_ticket_data = z
                                        this.movePage(z);
                                    // }
                                })
                            }
                            else{
                                if(this.loggedInUser.print_receipt_by_default) {
                                    res.response.ticket_details.forEach(z => {
                                        this.store_ticket_data = z
                                        this.printTicket(z)
                                    })
                                } else {
                                    Swal({
                                        title: 'Print Tickets',
                                        text: 'Do You Want to Print Tickets ?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#00448b',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'Continue Without Printing',
                                        allowOutsideClick: false
                                    }).then(result => {
                                        if (result.value) {
                                            res.response.ticket_details.forEach(z => {
                                                this.store_ticket_data = z
                                                this.printTicket(z)
                                            })
                                        } else {
                                            this.$emit("close", true);
                                            this.placing_order_in_progress = false
                                        }
                                    })
                                }
                            }
                        }
                    }

                } else {
                    let res= await this.orderUpdateOrder(params1)
                    let status = res.status_id
                    if(status == 1) {
                        this.showAcceptConfirmModal()
                        setTimeout(() => {
                            this.getPendingOrderList()
                        }, 500);
                    }
                }

            } catch (reason) {

            }
        }
    },
    mounted() {
        this.getPendingOrderList();
    }
}
</script>
<style>

    @media (max-width: 1030px) {
        .span-order {
            display: none;
        }
    }
    .span-order {
        color: #00448b;
        font-weight: 600;
        padding-right: 12px;
    }
    .order-card {
        box-shadow: 0 0px 1px 0 #000;
        max-width: 550px;
        margin: auto;
        text-align: left;
        background-color: #fff !important;
    }
    .btn-style  {
        border-radius: 8px !important;
        width:120px !important;
        color : #fff !important;
        font-weight: 500;
    }
    .text-color {
        color: #f5a623
    }
    .text-border {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px
    }
    .icon-style {
       height: 12px;
       margin-bottom: 3px;
    }
    .btn-secondary {
        background-color: #00448b !important;
        border-color: #00448b !important;
        box-shadow: none !important;

    }
    .img-style {
        height: 330px;
        width: 250px;
        justify-content: center;
        margin: auto
    }
    .text-style {
        font-size:20px;
        font-weight:bold;
        color: #00448b !important;
    }
</style>
