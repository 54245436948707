<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-payment-qr-code">
        <div class="v-modal-content" style="background-color: rgba(255,255,255,0.9) !important;">
            <!-- <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">QR CODE</span>
            </div> -->
            <div class="general-section">
            <div class="v-modal-body my-10" style="background-color: transparent !important;">
                <h1 class="fs-20 text-center text-danger font-bold">Session Timed out!</h1>
                <div class="text-center pt-8 pb-6">
                    <img src="https://cdn.digicollect.com/cdn/posv2/session-time-out-info-icon.svg" width="150" />
                </div>
                <h1 class="fs-16 text-center mb-5">
                    Transaction failed!
                </h1>
                <!-- <div class="contentCenter width-fit mt-4">
                    <span class="fw-500 fs-18">{{qr_code_data}}</span>
                </div> -->
            </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name'],
    data(){
        return{
        }
    },
    mixins:[],
    methods:{
    },
    mounted() {
        setTimeout(() => {
            this.$emit('hidePaymentQrCodeSessionTimeOut')
        }, 5000);
    }
}
</script>
<style scoped>
</style>